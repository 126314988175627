<div class="table-body mt-5">
    
    <div class="table-body-head mb-3"> 
     <div>
         <label class="table-title">Clientes Cafler</label>
     </div>
     <tui-hosted-dropdown
     tuiDropdownLimitWidth="auto"
     [content]="download_options"
     [(open)]="open"
 >
  <label class="table-title-filter">Filter ({{filterCount}})</label>
 <button  tuiHostedDropdownHost class="export-button">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (.csv, xls..)
 </button>
 </tui-hosted-dropdown>
    </div> 
    <div class="table-body-options mb-3">
     <div class="search-bar">
         <img src="../../../assets/icons/search- icon.svg" alt="Search Icon" class="search-icon" />
         <input #searchInput  [(ngModel)]="searchTerm" type="text" placeholder="Buscar por nombre de cliente..." class="search-bar-input custom_search_table_input" />
     </div>
     <button (click)="filterDataBySelect()" appearance="primary-button" size="s" tuiButton>
        <tui-svg src="tuiIconSearch"></tui-svg>
        Buscar
      </button>
       <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedDate" >
         <option  selected value="#">Fecha</option>
         <!-- <option *ngFor="let country of countrys" [value]="country">{{ country }}</option> -->
       </select>
     	<!-- <select class="form-select table-option-select" aria-label="zone" [(ngModel)]="selectedZone" > -->
        <!-- <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedDate" (ngModelChange)="filterDataBySelect()">
         <option  selected value="#">Fecha</option>
       </select> -->
       <select class="form-select table-option-select" aria-label="zone" [(ngModel)]="selectedZone" (change)="filterDataBySelect()">
         <option  selected value="#">Zona</option>
         <option *ngFor="let zone of zones" [value]="zone">{{ zone }}</option>
       </select>
       <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedTypeClient" (change)="filterDataBySelect()" >
         <option  selected value="#">Cliente</option>
         <option *ngFor="let type of types" [value]="type">{{ type }}</option>
       </select>

       <!-- <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedProduct" > -->
       <!-- <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedProduct" (ngModelChange)="filterDataBySelect()">
         <option  selected value="#">Producto</option>
       </select> -->
     

     
    </div>
    <div class="overflow-auto">
     <table
     tuiTable
     size="l"
     style="width: 100%;"
     [columns]="columns"
 >
     <thead>
         <tr tuiThGroup>
 
             <th class="th-style" *tuiHead="'client_name'"
             tuiTh
             (click)="setSortColumn('client_name')" 
             [sticky]="true">
             	Nombre de cliente
             </th>
             <th [sticky]="true" *tuiHead="'billing_name'"    (click)="setSortColumn('billing_name')"  tuiTh>Nombre de facturación</th>
             <th [sticky]="true" *tuiHead="'client_id'"   (click)="setSortColumn('client_id')"  tuiTh>ClientID</th>
             <th [sticky]="true" *tuiHead="'country'"    (click)="setSortColumn('country')"  tuiTh>País</th>
             <th [sticky]="true" *tuiHead="'zone'"    (click)="setSortColumn('zone')"  tuiTh>Zona operativa</th>
             <th [sticky]="true" *tuiHead="'status'"    (click)="setSortColumn('status')"  tuiTh>Estado</th>
             <th [sticky]="true" *tuiHead="'email'"    (click)="setSortColumn('email')"  tuiTh>Correo electrónico</th>
             <th [sticky]="true" *tuiHead="'phone'"    (click)="setSortColumn('phone')"  tuiTh>Teléfono</th>
             <th [sticky]="true" *tuiHead="'prices'"    (click)="setSortColumn('prices')"  tuiTh>Descuentos</th>
             <th [sticky]="true" *tuiHead="'actions'"    tuiTh>
             	Acciones
             </th>
         </tr>
     </thead>
     <tbody tuiTbody>
        <tr tuiTr *ngFor="let item of paginatedData">
            <td    *tuiCell="'client_name'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                   		{{ item.client_name }}   
                    </span>
                </div>
            </td>
            <td  *tuiCell="'billing_name'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ item.billing_name }}
                    </span>
                </div>
            </td>
            <td  *tuiCell="'client_id'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ item.client_id }}
                    </span>
                </div>
            </td>
            <td  *tuiCell="'country'" tuiTd>
                <span tuiTitle>
                    {{ item.country }}
                 </span>
            </td>
            <td  *tuiCell="'zone'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                       {{ item.zone }}
                    </span>
                </div>
            </td>
            <td  *tuiCell="'status'" tuiTd>
                <span tuiTitle>
                    <!-- {{ item.status }} -->
                    <tui-badge status="success" value="Activo" class="tui-space_right-2 tui-space_bottom-2 ml-5" *ngIf="item.status == 'activo'"></tui-badge>
                    <tui-badge status="error" value="Inactivo" class="tui-space_right-2 tui-space_bottom-2 ml-5" *ngIf="item.status == 'inactivo'"></tui-badge>
                </span>
            </td>
            <td  *tuiCell="'email'" tuiTd>
                <span tuiTitle>
                	<a href="mailto:{{ item.email }}" class="mail_table_link">{{ item.email }}</a>
                </span>
            </td>
            <td  *tuiCell="'phone'" tuiTd>
                <span tuiTitle>
                    {{ item.phone }}
                </span>
            </td>
            <td  *tuiCell="'prices'" tuiTd>
                <button  appearance="primary-button" size="s" tuiButton (click)="goPrices(item)">
                    <tui-svg src="tuiIconPlusCircle"></tui-svg>
                    0 
                </button>
            </td>
            <td  *tuiCell="'actions'" tuiTd>
                <span tuiStatus>
                    <button
                        appearance="link"
                        icon="tuiIconEdit2"
                        size="xs"
                        tuiAppearance="link"
                        tuiIconButton
                        (click)="openModalEditClient(item)"
                    >
                        Edit
                    </button>

                   
                </span>
            </td>
         </tr>
     </tbody>
 </table>
</div>  
 
 <div class="table-pagination  mt-5">
 
  
 <tui-pagination
 
     [index]="index"
     [length]="length"
     (indexChange)="goToPage($event)"
 ></tui-pagination>
 </div>
 
 </div>
 <ng-template
 #download_options
 let-activeZone
>
<div class="download-menu">
 <label class="mb-2 export-title ">Exportar listado</label>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.xsl)</label>
 </div>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.csv)</label>
 </div>
 <button  class="export-button-menu mb-2 mt-1">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (2)
 </button>
</div>


</ng-template>

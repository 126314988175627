import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapaServiceService {
    url = environment.url;

    constructor(
        private http: HttpClient
    ) {
        
    }

    getZones() {
        return this.http.get(`${this.url}/zones`);
    }

    getDrivers() {
        return this.http.get(`${this.url}/drivers?nameFilterQuery=cesar`);
    }
    
    getServices(data:any){
        return this.http.post(`${this.url}/services-map/services-in-zone`, data);
    }

    getDriver(driverId:string){
        return this.http.get(`${this.url}/drivers?driverIdFilter=${driverId}`);
    }

    getMapDrivers(data:any){
        return this.http.post(`${this.url}/services-map/get-drivers`, data);
    }

    assignDriver(data:any){
        return this.http.post(`${this.url}/services/assign-driver`, data);
    }
}

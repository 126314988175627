<div class="modal-all">
  <div class="modal-header">
    <h4
      *ngIf="this.type_info != 'ed'; else editBlock"
      class="modal-title pull-left"
    >
      Nuevo proveedor
    </h4>
    <ng-template #editBlock>
      <h4 class="modal-title pull-left">Editar proveedor</h4>
    </ng-template>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="closeCanvas()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="modal_add_providers">{{ content }}</div>
    <form
      [formGroup]="providerForm"
      (ngSubmit)="saveProvider()"
      class="d-flex flex-column gap-4"
    >
      <div class="row">
        <div class="form-group">
          <b>Información de cliente</b>
        </div>
        <div class="form-group">
          <br />
          <label class="label_form">ID Cliente (Autogenerado)</label>
          <p>{{ idNewProvider }}</p>
          <br />
        </div>
        <div class="form-group">
          <label class="label_form">Nombre del proveedor*</label>
          <tui-input
            formControlName="name"
            class="input_form"
            [tuiTextfieldLabelOutside]="true"
          >
            Nombre del proveedor
            <input tuiTextfield type="text" />
          </tui-input>
        </div>
        <div class="form-group form-group-select">
          <div class="col-12">
            <label class="label_form">Zona operativa*</label>
            <tui-select
              tuiTextfieldSize="s"
              formControlName="operativeZone"
              class="input_form"
              (click)="onSelectClick($event)"
              (focusedChange)="onSelectBlur($event)"
              nativeId="select_zone"
              [tuiTextfieldLabelOutside]="true"
            >
              Selecciona zona operativa
              <tui-data-list-wrapper
                *tuiDataList
                [items]="operatives"
              ></tui-data-list-wrapper>
            </tui-select>
          </div>
        </div>
        <br />
        <div class="form-group">
          <label class="label_form">Dirección del proveedor*</label>
          <!--                     <tui-input  formControlName="address" class="input_form" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconMapPin">
                        Escribe la dirección del proveedor
                        <input
                        #originAddress
                            tuiTextfield
                            type="text"
                        />
                    </tui-input> -->
          <input
            #originAddress
            formControlName="address"
            placeholder="C/ Larga nombre extenso, 56, 28022, Madrid"
            type="text"
            class="form-control input_form"
            (input)="onAddressInput()"
            (blur)="onAddressBlur()"
          />
          <input type="hidden" formControlName="addressGeopointLt" />
          <input type="hidden" formControlName="addressGeopointLn" />
        </div>
        <div class="form-group">
          <label class="label_form">Correo Electrónico*</label>
          <tui-input
            formControlName="email"
            class="input_form"
            [tuiTextfieldLabelOutside]="true"
          >
            tucorreo@ejemplo.es
            <input tuiTextfield type="text" />
          </tui-input>
        </div>
        <div class="form-group">
          <div class="col-12">
            <label class="label_form">Teléfono de contacto*</label>
            <tui-input-phone-international
              formControlName="PhoneNumber"
              [countries]="countriesPhone"
              [(countryIsoCode)]="countryIsoCode"
              class="input_form2"
              (click)="onSelectClick($event)"
              (focusedChange)="onSelectBlur($event)"
              nativeId="select_phone"
              [tuiTextfieldLabelOutside]="true"
            ></tui-input-phone-international>
          </div>
        </div>
        <div class="form-group">
          <label class="label_form">CIF*</label>
          <tui-input
            formControlName="cif"
            class="input_form"
            [tuiTextfieldLabelOutside]="true"
          >
            CIF de facturación del cliente Ejlo: (B-XXXXXXXXX)
            <input tuiTextfield type="text" />
          </tui-input>
        </div>
        <div class="form-group">
          <b>Información de horarios</b>
        </div>
        <div class="form-group">
          <br />
          <div class="col check_div">
            <tui-checkbox
              class="tui-space_bottom-3 mr_10"
              formControlName="sameSchedule"
              size="m"
            ></tui-checkbox>
            Mismo horario de Lunes a Viernes
          </div>
          <div
            *ngIf="!this.providerForm.get('sameSchedule')?.value"
            formArrayName="selectedDays"
          >
            <div
              *ngFor="
                let day of daysOfWeek;
                let i = index;
                trackBy: trackByIndex
              "
              [formGroupName]="i"
            >
              <div class="col check_div">
                <tui-checkbox
                  class="tui-space_bottom-3 mr_10"
                  formControlName="checked"
                  (checkedChange)="onDaySelected(i, $event)"
                ></tui-checkbox>
                {{ day }}
              </div>

              <div
                class="row mb-3"
                *ngIf="selectedDaysFormArray.at(i).get('checked')?.value"
              >
                <div class="col">
                  <br />
                  <label class="label_form">Hora inicio de servicio</label>
                  <tui-input-time
                  [itemSize]="'s'"
                  [tuiTextfieldSize]="'s'"
                    formControlName="startHour"
                    class="input_form"
                    [tuiTextfieldLabelOutside]="true"
                    [items]="hours"
                    (click)="onSelectClick($event)"
                    (change)="createTimeSlotEnd(i)"
                    (focusedChange)="onSelectBlur($event)"
                    [nativeId]="'start_hour' + i"
                  >
                  </tui-input-time>
                </div>

                <div class="col">
                  <br />
                  <label class="label_form">Hora fin de servicio</label>
                  <tui-input-time
                  [itemSize]="'s'"
                  [tuiTextfieldSize]="'s'"
                    formControlName="endHour"
                    class="input_form"
                    [tuiTextfieldLabelOutside]="true"
                    [items]="arrayTime[i]"
                    (click)="onSelectClick($event)"
                    (focusedChange)="onSelectBlur($event)"
                    [nativeId]="'end_hour' + i"
                  >
                  </tui-input-time>
                </div>

                <div class="col">
                  <br />
                  <label class="label_form">Horario inicio de pausa</label>
                  <tui-input-time
                  [itemSize]="'s'"
                  [tuiTextfieldSize]="'s'"
                    formControlName="pauseHour"
                    class="input_form"
                    [tuiTextfieldLabelOutside]="true"
                    [items]="arrayTimePause[i]"
                    (click)="onSelectClick($event)"
                    (focusedChange)="onSelectBlur($event)"
                    [nativeId]="'paused_hour' + i"
                  >
                  </tui-input-time>
                </div>
                <div class="col">
                  <br />
                  <label class="label_form">Horario fin de pausa</label>
                  <tui-input-time
                  [itemSize]="'s'"
                  [tuiTextfieldSize]="'s'"
                    formControlName="pauseHourEnd"
                    class="input_form"
                    [tuiTextfieldLabelOutside]="true"
                    [items]="arrayTimePauseEnd[i]"
                    (click)="onSelectClick($event)"
                    (focusedChange)="onSelectBlur($event)"
                    [nativeId]="'paused_hour_end' + i"
                  >
                  </tui-input-time>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="this.providerForm.get('sameSchedule')?.value">
            <div class="col">
              <br />
              <label class="label_form">Hora inicio de servicio</label>
              <tui-input-time
                [itemSize]="'s'"
                [tuiTextfieldSize]="'s'"
                formControlName="startTime"
                class="input_form"
                [tuiTextfieldLabelOutside]="true"
                [items]="hours"
                (click)="onSelectClick($event)"
                (focusedChange)="onSelectBlur($event)"
                nativeId="select_start"
              >
              </tui-input-time>
            </div>
            <div class="col">
              <br />
              <label class="label_form">Hora fin de servicio</label>
              <tui-input-time
                [itemSize]="'s'"
                [tuiTextfieldSize]="'s'"
                formControlName="endTime"
                class="input_form"
                [tuiTextfieldLabelOutside]="true"
                [items]="hours2"
                (click)="onSelectClick($event)"
                (focusedChange)="onSelectBlur($event)"
                nativeId="select_end"
              >
              </tui-input-time>
            </div>
            <div class="col">
              <br />
              <label class="label_form">Horario inicio de pausa</label>
              <tui-input-time
                [itemSize]="'s'"
                [tuiTextfieldSize]="'s'"
                formControlName="pauseTime"
                class="input_form"
                [tuiTextfieldLabelOutside]="true"
                [items]="pausedHours"
                (click)="onSelectClick($event)"
                (focusedChange)="onSelectBlur($event)"
                nativeId="select_paused"
              >
              </tui-input-time>
            </div>
            <div class="col">
              <br />
              <label class="label_form">Horario fin de pausa</label>
              <tui-input-time
                [itemSize]="'s'"
                [tuiTextfieldSize]="'s'"
                formControlName="pauseTimeEnd"
                class="input_form"
                [tuiTextfieldLabelOutside]="true"
                [items]="pausedHours2"
                (click)="onSelectClick($event)"
                (focusedChange)="onSelectBlur($event)"
                nativeId="select_paused_end"
              >
              </tui-input-time>
            </div>
          </div>
        </div>
        <div class="form-group">
          <br />
          <b>Información de los productos</b>
        </div>
        <div class="form-group">
          <b>Productos</b>
        </div>
        <div formGroupName="checkboxes">
          <!-- Grupo Revisiones -->
          <div formGroupName="revisiones" class="form-group pdiv">
            <div class="d-flex export-body-label ml-15">
              <tui-checkbox
                class="tui-space_bottom-3 mr-5"
                formControlName="parent"
                (change)="toggleAllChildren('revisiones')"
              >
              </tui-checkbox>
              <label>Revisiones</label>
            </div>
            <div class="product_detail_div" formGroupName="children">
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="itv"
                  (change)="updateParentCheck('revisiones')"
                >
                </tui-checkbox>
                <label>ITV</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="preItv"
                  (change)="updateParentCheck('revisiones')"
                >
                </tui-checkbox>
                <label>Pre-ITV</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="itvHomologacion"
                  (change)="updateParentCheck('revisiones')"
                >
                </tui-checkbox>
                <label>ITV Homologación</label>
              </div>
            </div>
          </div>

          <!-- Grupo Lavado -->
          <div formGroupName="lavado" class="form-group pdiv">
            <div class="d-flex export-body-label ml-15">
              <tui-checkbox
                class="tui-space_bottom-3 mr-5"
                formControlName="parent"
                (change)="toggleAllChildren('lavado')"
              >
              </tui-checkbox>
              <label>Lavado</label>
            </div>
            <div class="product_detail_div" formGroupName="children">
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="centrosEspecializados"
                  (change)="updateParentCheck('lavado')"
                >
                </tui-checkbox>
                <label>Centros especializados</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="tapicerias"
                  (change)="updateParentCheck('lavado')"
                >
                </tui-checkbox>
                <label>Tapicerías</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="integral"
                  (change)="updateParentCheck('lavado')"
                >
                </tui-checkbox>
                <label>Integral</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="pulidoFaros"
                  (change)="updateParentCheck('lavado')"
                >
                </tui-checkbox>
                <label>Pulido de faros</label>
              </div>
            </div>
          </div>

          <!-- Grupo Mantenimiento -->
          <div formGroupName="mantenimiento" class="form-group pdiv">
            <div class="d-flex export-body-label ml-15">
              <tui-checkbox
                class="tui-space_bottom-3 mr-5"
                formControlName="parent"
                (change)="toggleAllChildren('mantenimiento')"
              >
              </tui-checkbox>
              <label>Mantenimiento</label>
            </div>
            <div class="product_detail_div" formGroupName="children">
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="packsMantenimiento"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Packs mantenimiento</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="repostaje"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Repostaje</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="preItv"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Pre-ITV</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="preItvItv"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Pre-ITV + ITV</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="diagnostico"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Diagnóstico</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="neumaticos"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Neumáticos</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="puestaPunto"
                  (change)="updateParentCheck('mantenimiento')"
                >
                </tui-checkbox>
                <label>Puesta a punto</label>
              </div>
            </div>
          </div>

          <!-- Grupo Aparcamos tu vehículo -->
          <div formGroupName="aparcamosVehiculo" class="form-group pdiv">
            <div class="d-flex export-body-label ml-15">
              <tui-checkbox
                class="tui-space_bottom-3 mr-5"
                formControlName="parent"
                (change)="toggleAllChildren('aparcamosVehiculo')"
              >
              </tui-checkbox>
              <label>Aparcamos tu vehículo</label>
            </div>
            <div class="product_detail_div" formGroupName="children">
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="parking"
                  (change)="updateParentCheck('aparcamosVehiculo')"
                >
                </tui-checkbox>
                <label>Parking</label>
              </div>
              <div class="d-flex export-body-label ml-15">
                <tui-checkbox
                  class="tui-space_bottom-3 mr-5"
                  formControlName="valet"
                  (change)="updateParentCheck('aparcamosVehiculo')"
                >
                </tui-checkbox>
                <label>Valet</label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="d-flex export-body-label ml-15 mt-15">
              <tui-checkbox
                class="tui-space_bottom-3 mr-5"
                formControlName="cambioNombre"
              ></tui-checkbox>
              <label>Trámites - Cambio de nombre</label>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex export-body-label ml-15 mt-15">
              <tui-checkbox
                class="tui-space_bottom-3 mr-5"
                formControlName="grua"
              ></tui-checkbox>
              <label>Asistencia - Grúa</label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <br />
          <b>Información de los productos</b>
        </div>
        <div class="form-group form-group-select">
          <label class="label_form">Forma de pago*</label> <br />
          <tui-select
            tuiTextfieldSize="s"
            formControlName="payment"
            class="input_form"
            [tuiTextfieldLabelOutside]="true"
            (click)="onSelectClick($event)"
            (focusedChange)="onSelectBlur($event)"
            nativeId="select_payment"
          >
            Selecciona zona operativa
            <tui-data-list-wrapper
              *tuiDataList
              [items]="payments"
            ></tui-data-list-wrapper>
          </tui-select>
        </div>
        <div class="form-group">
          <div class="col-12">
            <label class="label_form">Información bancaria</label>
            <textarea
              formControlName="bank_info"
              rows="10"
              id="bank_info"
              class="form-control form_textarea"
              placeholder="Escribe aquí los detalles"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer"
      size="m"
      appearance="secondary"
      (click)="closeCanvas()"
    >
      Descartar
    </button>
    <div *ngIf="this.type_info != 'ed'; else editBlock2">
      <button
        tuiButton
        type="button"
        (click)="saveProvider()"
        class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m"
        appearance="primary-button"
        icon="tuiIconPlusCircleLarge"
        [class.disabled]="!providerForm.valid && validateDays()"
        
        [disabled]="providerForm.valid == true  && validateDays() == false ? false : true"
      
      >
        Crear proveedor
      </button>
    </div>

    <ng-template #editBlock2>
      <button
        tuiButton
        type="button"
        (click)="saveProvider()"
        class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m"
        appearance="primary-button"
        icon="tuiIconPlusCircleLarge"
        [class.disabled]="!providerForm.valid && validateDays()"
        
        [disabled]="providerForm.valid == true  && validateDays() == false ? false : true"
      >
        Editar proveedor
      </button>
    </ng-template>
  </div>
</div>

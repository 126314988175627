<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="action == 'ad'">Nuevo cliente</h4>
        <h4 class="modal-title pull-left" *ngIf="action == 'ed'">Editar cliente</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="modal_add_client"></div>
        <div id="modal_add_client_alert"></div>
        <form [formGroup]="clientForm" (ngSubmit)="saveClient()" class="d-flex flex-column gap-4">
            <div class="row">
            	<div class="form-group">
                	<b>Información de cliente</b>
                </div>
                <!-- <div class="form-group">
                	<br>
                	<label class="label_form">ID Cliente (Autogenerado)</label>
                	<p>{{ idNewClient }}</p>
                	<br>
                </div> -->
                <div class="form-group form-group-select">
                    <label class="label_form">Zona Operativa*</label>
                    <!-- <tui-select
                        tuiTextfieldSize="s"
                        formControlName="zone"
                        class="input_form"
                        [tuiTextfieldLabelOutside]="true"
                        (click)="onSelectClick($event)"
                        (focusedChange)="onSelectBlur($event)"
                        nativeId="select_zo"
                    >
                        Selecciona zona operativa
                        <tui-data-list-wrapper
                            *tuiDataList
                            [items]="operatives"
                        ></tui-data-list-wrapper>
                    </tui-select> -->
                    <ng-select
                        formControlName="zone"
                        [items]="operatives"
                        bindLabel="zoneName"
                        bindValue="zoneId"
                        [multiple]="false"
                        class="custom_select custom_select_single input_form"
                        placeholder="Zona operativa"
                    >
                    </ng-select>
                </div>
            	<div class="form-group">
                    <label class="label_form">Nombre de la empresa*</label>
                    <tui-input formControlName="company_name" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre de la empresa
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Descripción</label>
                    <tui-input formControlName="company_description" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Escribe una descripción sobre la empresa
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <!-- <div class="form-group">
                    <label class="label_form">Contraseña (Generada automáticamente)</label>
                    <tui-input-password formControlName="password_client" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Contraseña
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input-password>
                    <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc2" size="m" appearance="secondary" (click)="generateNewPassword()">
                        <tui-icon icon="tuiIconAlertTriangle" class="alert_icon"></tui-icon> Generar nueva contraseña
                    </button>
                </div> -->
                <div class="form-group">
                    <label class="label_form">Key Account Manager*</label>
                    <tui-input formControlName="key_account" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre del Key Account Manager
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group form-group-select">
                    <label class="label_form">Estado del cliente*</label>
                    <tui-select
                        tuiTextfieldSize="s"
                        formControlName="status"
                        class="input_form"
                        [tuiTextfieldLabelOutside]="true"
                        (click)="onSelectClick($event)"
                        (focusedChange)="onSelectBlur($event)"
                        nativeId="select_st"
                    >
                        Estado del cliente
                        <tui-data-list-wrapper
                            *tuiDataList
                            [items]="status_list"
                        ></tui-data-list-wrapper>
                    </tui-select>
                </div>
                <div class="form-group form-group-select">
                    <label class="label_form">Categoría de cliente*</label>
                    <tui-select
                        tuiTextfieldSize="s"
                        formControlName="perfil"
                        class="input_form"
                        [tuiTextfieldLabelOutside]="true"
                        (click)="onSelectClick($event)"
                        (focusedChange)="onSelectBlur($event)"
                        nativeId="select_tp"
                    >
                        Categoría de cliente
                        <tui-data-list-wrapper
                            *tuiDataList
                            [items]="perfils"
                        ></tui-data-list-wrapper>
                    </tui-select>
                </div>
                <div class="form-group form-group-select">
                    <label class="label_form">Tipo de cliente*</label>
                    <tui-select
                        tuiTextfieldSize="s"
                        formControlName="type"
                        class="input_form"
                        [tuiTextfieldLabelOutside]="true"
                        (click)="onSelectClick($event)"
                        (focusedChange)="onSelectBlur($event)"
                        nativeId="select_tp"
                    >
                        Tipo de cliente
                        <tui-data-list-wrapper
                            *tuiDataList
                            [items]="types"
                        ></tui-data-list-wrapper>
                    </tui-select>
                </div>
               <!--  <div class="form-group">
                    <label class="label_form">Categoría*</label>
                    <tui-input formControlName="category" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Categoría de la empresa
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div> -->
                <div class="form-group">
                    <label class="label_form">Sector de actividad</label>
                    <tui-input formControlName="activity_sector" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Sector de actividad del cliente
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Grupo</label>
                    <tui-input formControlName="group" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Grupo de cliente
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Subgrupo</label>
                    <tui-input formControlName="sub_group" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Subgrupo del cliente
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                	<b>Dirección de cliente</b>
                </div>
                <div class="form-group">
                    <label class="label_form">Calle*</label>
                    <input #client_address  formControlName="address" placeholder="C/ Larga nombre extenso, 56, 28022, Madrid"  type="text" class="form-control input_form">
                    <input type="hidden" formControlName="addressGeopointLt">
                    <input type="hidden" formControlName="addressGeopointLn">
                </div>
                <div class="form-group">
                    <label class="label_form">Código Postal*</label>
                    <tui-input formControlName="postal_code" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Código Postal
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Ciudad</label>
                    <tui-input formControlName="city" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Ciudad
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Estado Provincia</label>
                    <tui-input formControlName="province" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Estado Provincia
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">País</label>
                    <tui-input formControlName="country" class="input_form" [tuiTextfieldLabelOutside]="true">
                        País
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <br>
                <div class="form-group">
                	<b>Información de Contacto del Cliente</b>
                </div>
                <div class="form-group">
                    <label class="label_form">Nombre</label>
                    <tui-input formControlName="name_client" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Apellido</label>
                    <tui-input formControlName="lastname_client" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Apellido
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">Teléfono de oficina*</label>
                        <tui-input-phone-international
                            formControlName="officePhone"
                            [countries]="countriesPhone"
                            [(countryIsoCode)]="countryIsoCode"
                            class="input_form2"
                            [tuiTextfieldLabelOutside]="true"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_op"
                          ></tui-input-phone-international>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label_form">Correo electrónico corporativa*</label>
                    <tui-input formControlName="email" class="input_form" [tuiTextfieldLabelOutside]="true">
                        tucorreo@ejemplo.es
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                	<b>Personas de Contacto</b> <tui-icon class="ml_10 cu_po" icon="tuiIconPlusCircleLarge" (click)="addItem()"></tui-icon>
                </div>
                <!-- <div class="form-group">
                    <b>Personas de Contacto</b> <tui-icon class="ml_10 cu_po" icon="tuiIconPlusCircleLarge" (click)="addCp()"></tui-icon>
                </div> -->
                <div formArrayName="contacts">
                    <div *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
                        <div class="form-group">
                            <b>Persona {{ i+ 1 }}</b> <tui-icon class="icon_delete" icon="tuiIconTrash2" (click)="removeItem(i)"></tui-icon>
                        </div>
                        <div class="form-group">
                            <label class="label_form">Nombre</label>
                            <tui-input formControlName="firstName" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Nombre de persona de contacto de facturación
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="form-group">
                            <label class="label_form">Apellidos</label>
                            <tui-input formControlName="lastName" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Apellido de persona de contacto de facturación
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="form-group">
                            <label class="label_form">Dirección de correo electrónico</label>
                            <tui-input formControlName="emailAddress" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Dirección de correo electrónico
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="form-group">
                            <label class="label_form">Departamento</label>
                            <tui-input formControlName="department" class="input_form" [tuiTextfieldLabelOutside]="true">
                                Departamento
                                <input
                                    tuiTextfield
                                    type="text"
                                />
                            </tui-input>
                        </div>
                        <div class="form-group">
                            <div class="col-12">
                                <label class="label_form">Teléfono*</label>
                                <tui-input-phone-international
                                    formControlName="phoneNumber"
                                    [countries]="countriesPhone"
                                    [(countryIsoCode)]="countryIsoCode"
                                    class="input_form2"
                                    [tuiTextfieldLabelOutside]="true"
                                    (click)="onSelectClick($event)"
                                    (focusedChange)="onSelectBlur($event)"
                                    nativeId="select_ppc"
                                  ></tui-input-phone-international>
                            </div>
                        </div>
                        <div class="form-group">
                            <hr>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                	<b>Facturación</b>
                </div>
                <div class="form-group">
                	<b>Datos del Cliente para Facturación *</b>
                </div>
                <div class="form-group">
                    <label class="label_form">Nombre de facturación*</label>
                    <tui-input formControlName="billing" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre del cliente asociado a la facturación
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">CIF*</label>
                    <tui-input formControlName="billing_cif" class="input_form" [tuiTextfieldLabelOutside]="true">
                        CIF de facturación del cliente Ejlo: (B-XXXXXXXXX)
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                	<b>Dirección de Facturación *</b>
                </div>
                <div class="form-group">
                    <label class="label_form">Calle*</label>
                    <input #billing_address  formControlName="address_billing" placeholder="C/ Larga nombre extenso, 56, 28022, Madrid"  type="text" class="form-control input_form">
                    <input type="hidden" formControlName="addressBillingGeopointLt">
                    <input type="hidden" formControlName="addressBillingGeopointLn">
                </div>
                <div class="form-group">
                    <label class="label_form">Código Postal*</label>
                    <tui-input formControlName="postal_code_billing" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Código postal
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Ciudad*</label>
                    <tui-input formControlName="city_billing" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Ciudad
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Estado Provincia*</label>
                    <tui-input formControlName="province_billing" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Estado Provincia
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">País*</label> <br>
                    <tui-input formControlName="country_billing" class="input_form" [tuiTextfieldLabelOutside]="true">
                        País
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Detalles adicionales</label> <br>
                    <tui-input formControlName="billingAddressAdditionalDetails" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Detalles adicionales
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                	<b>Información Bancaria*</b>
                </div>
                <div class="form-group">
                    <label class="label_form">IBAN*</label>
                    <tui-input formControlName="iban" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Ejlo: ESXX XXXX XXXX XXXX XXXX XXXX
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Cuenta bancaria*</label>
                    <tui-input formControlName="bank_account" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Escriba la cuenta bancaria del cliente
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group form-group-select">
                    <label class="label_form">Método de pago*</label>
                    <tui-select
                        tuiTextfieldSize="s"
                        formControlName="payment_type"
                        class="input_form"
                        [tuiTextfieldLabelOutside]="true"
                        (click)="onSelectClick($event)"
                        (focusedChange)="onSelectBlur($event)"
                        nativeId="select_pt"
                    >
                        Método de pago
                        <tui-data-list-wrapper
                            *tuiDataList
                            [items]="payments"
                        ></tui-data-list-wrapper>
                    </tui-select>
                </div>
                <div class="form-group">
                    <label class="label_form">Tiempos de cobro</label>
                    <tui-input formControlName="payment_day" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Día del mes de cobro del cliente
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                	<b>Contacto de Facturación *</b>
                </div>
                <div class="form-group">
                    <label class="label_form">Nombre</label>
                    <tui-input formControlName="payment_contact_name" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre de persona de contacto de facturación
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Apellidos</label>
                    <tui-input formControlName="payment_contact_last_name" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Apellido de persona de contacto de facturación
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Dirección de correo electrónico de facturación</label>
                    <tui-input formControlName="payment_contact_email" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Dirección de correo electrónico de facturación
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <label class="label_form">Departamento</label>
                    <tui-input formControlName="payment_contact_department" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Departamento
                        <input
                            tuiTextfield
                            type="text"
                        />
                    </tui-input>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">Teléfono*</label>
                        <tui-input-phone-international
                            formControlName="payment_contact_phone"
                            [countries]="countriesPhone"
                            [(countryIsoCode)]="countryIsoCode"
                            class="input_form2"
                            [tuiTextfieldLabelOutside]="true"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_cbp"
                          ></tui-input-phone-international>
                    </div>
                </div>
                <div class="form-group">
                	<b>Información adicional</b>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">Detalles u observaciones</label>
                        <textarea  formControlName="aditional_info" rows="10" id="bank_info" class="form-control form_textarea" placeholder="Escribe aquí los detalles"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            Descartar
        </button>
        <button tuiButton type="button" (click)="saveClient()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [disabled]="!clientForm.valid">
            {{ action == 'edit' ? 'Editar' : 'Crear' }} cliente
        </button>
        <div class="loader_inside_modal_button">
            <img src="/assets/logo-loading.gif" class="img_loader_inside_modal_button" alt="">
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';
@Component({
  selector: 'app-edit-client-price-modal',
  templateUrl: './edit-client-price-modal.component.html',
  styleUrls: ['./edit-client-price-modal.component.css'],
})
export class EditClientPriceModalComponent {
  @Input() bsModalRef!: any;
  @Input() zones: any;
  @Input() subproductData: any;
  @Output() sendSubproductData = new EventEmitter<any>();
  readonly maskOptions1: MaskitoOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  };

  subproductForm!: FormGroup;

  disponibility = new FormGroup({
    isVailable: new FormControl(true),
  });
  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    this.subproductForm = this.fb.group({
      clientProviderTotal: [
        this.formatPrice(
          this.subproductData?.clientProviderPrice.units,
          this.subproductData?.clientProviderPrice.nanos
        ),
        Validators.required,
      ],
      clientMobilityTotal: [
        this.formatPrice(
          this.subproductData?.clientMobilityPrice.units,
          this.subproductData?.clientMobilityPrice.nanos
        ),
        Validators.required,
      ],
      available: [this.subproductData.available],
    });
  }
  formatPrice(units: number, nanos: number): string {
    const nanosStr = nanos.toString().padStart(9, '0');
    const decimalPart = nanosStr.substring(0, 2);
    return `${units}.${decimalPart}`;
  }

  onSubmitUpdateSubproduct() {
    if (this.subproductForm.invalid) {
      return;
    }
    const body = {
      zoneId: '',
      clientId: '',
      planType: '',
      productToUpdate: {
        verticalName: this.subproductData.verticalName,
        productData: {
          productKey: this.subproductData.productKey,
          generalMobilityPrice: this.subproductData.generalMobilityPrice,
          generalProviderPrice: this.subproductData.generalProviderPrice,
          clientMobilityPrice: {
            units: '',
            nanos: 0,
          },
          clientProviderPrice: {
            units: '',
            nanos: 0,
          },
          available: this.subproductForm.get('available')?.value,
        },
      },
    };
    let totalProvider = this.subproductForm.get('clientProviderTotal')?.value;
    let totalMovility = this.subproductForm.get('clientMobilityTotal')?.value;

    if (totalProvider && /^\d+\.$/.test(totalProvider)) {
      totalProvider = parseFloat(totalProvider).toFixed(2);
      this.subproductForm
        .get('clientProviderTotal')
        ?.setValue(totalProvider, { emitEvent: false });
    }

    if (totalMovility && /^\d+\.$/.test(totalMovility)) {
      totalMovility = parseFloat(totalMovility).toFixed(2);
      this.subproductForm
        .get('clientMobilityTotal')
        ?.setValue(totalMovility, { emitEvent: false });
    }

    if (totalProvider) {
      const [units, nanos] = totalProvider.split('.');
      const nanosValue = (parseInt(nanos, 10) * Math.pow(10, 7))
        .toString()
        .padEnd(9, '0');
      body.productToUpdate.productData.clientProviderPrice.units = units;
      body.productToUpdate.productData.clientProviderPrice.nanos = Number.isNaN(
        +nanosValue
      )
        ? 0
        : +nanosValue;
    }

    if (totalMovility) {
      const [units, nanos] = totalMovility.split('.');
      const nanosValue = (parseInt(nanos, 10) * Math.pow(10, 7))
        .toString()
        .padEnd(9, '0');
      body.productToUpdate.productData.clientMobilityPrice.units = units;
      body.productToUpdate.productData.clientMobilityPrice.nanos = Number.isNaN(
        +nanosValue
      )
        ? 0
        : +nanosValue;
    }

    this.sendSubproductData.emit(body);
  }
}

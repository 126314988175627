<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left">Información de contacto</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="modal_edit_contact_info"></div>
        <form [formGroup]="contactForm" (ngSubmit)="saveContactInfo()" class="d-flex flex-column gap-4">
            <div class="row origin_contact">
            	<div class="form-group">
            		<p class="fs_15">
            			Contacto en origen
            		</p>
            		<!-- <tui-icon icon="tuiIconChevronUp" class="icon_right mtn" *ngIf="openOriginContact"></tui-icon> 
                    <tui-icon icon="tuiIconChevronDown" class="icon_right mtn" *ngIf="!openOriginContact"></tui-icon> 
 -->                </div>
                <div class="row">
                    <div class="col check_div">
                        <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="isOriginContactExternal" size="m"></tui-checkbox>Contacto externo
                    </div>
                </div>
            	<div class="form-group">
                    <label class="label_form">Nombre y apellidos*</label>
                    <tui-input formControlName="originContactName" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre y apellidos
                    </tui-input>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">Teléfono de contacto*</label>
                        <tui-input-phone-international
                            formControlName="origingContactPhoneNumber"
                            [countries]="countriesPhone"
                            [(countryIsoCode)]="countryIsoCode"
                            class="input_form2"
                            (click)="searchDiv('origingContactPhoneNumber')"
                            [tuiTextfieldLabelOutside]="true"
                          ></tui-input-phone-international>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label_form">Correo electrónico*</label>
                    <tui-input formControlName="originContactEmailAdddress" class="input_form" [tuiTextfieldLabelOutside]="true">
                        tucorreo@ejemplo.es
                    </tui-input>
                </div>
            </div>
            <div class="row destiny_contact">
            	<div class="form-group">
            		<p class="fs_15">
            			Contacto en destino
            		</p>
            		<!-- <tui-icon icon="tuiIconChevronUp" class="icon_right mtn" *ngIf="openDestinyContact"></tui-icon> 
                    <tui-icon icon="tuiIconChevronDown" class="icon_right mtn" *ngIf="!openDestinyContact"></tui-icon>  -->
                </div>
                <div class="row">
                    <div class="col check_div">
                        <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="sameContactOriginDestiny" size="m"></tui-checkbox> Mismo contacto que en origen
                    </div>
                </div>
                <div class="row">
                    <div class="col check_div">
                        <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="isDestinationContactExternal" size="m"></tui-checkbox>Contacto externo
                    </div>
                </div>
            	<div class="form-group">
                    <label class="label_form">Nombre y apellidos*</label>
                    <tui-input formControlName="destinationContactName" class="input_form" [tuiTextfieldLabelOutside]="true">
                        Nombre y apellidos
                    </tui-input>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">Teléfono de contacto*</label>
                        <tui-input-phone-international
                            formControlName="destinationContactPhoneNumber"
                            [countries]="countriesPhone"
                            [(countryIsoCode)]="countryIsoCode"
                            class="input_form2"
                            (click)="searchDiv('destinationContactPhoneNumber')"
                            [tuiTextfieldLabelOutside]="true"
                          ></tui-input-phone-international>
                    </div>
                </div>
                <div class="form-group">
                    <label class="label_form">Correo electrónico*</label>
                    <tui-input formControlName="destinationContactEmailAddress" class="input_form" [tuiTextfieldLabelOutside]="true">
                        tucorreo@ejemplo.es
                    </tui-input>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            Descartar
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!contactForm.valid" [attr.disabled]="!contactForm.valid ? true : null" *ngIf="!contactForm.valid">
                Generar asignación
        </button>
        <button tuiButton type="button" (click)="saveContactInfo()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m" appearance="primary-button" *ngIf="contactForm.valid">
            Guardar
        </button>
    </div>
</div>
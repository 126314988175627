import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://96683ccd6313fc013f9b6c033cb9f118@o970615.ingest.us.sentry.io/4508087503945728',
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracePropagationTargets: ["localhost", /^https:\/\/logistics\.development\.cafler\.com/]
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';

import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl } from '@angular/forms';
import {tuiCreateTimePeriods} from '@taiga-ui/kit';
import {TuiCountryIsoCode} from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';

@Component({
  selector: 'app-edit-detail-contact-service',
  templateUrl: './edit-detail-contact-service.component.html',
  styleUrls: ['./edit-detail-contact-service.component.css']
})
export class EditDetailContactServiceComponent {
    @Input() origin_info: any;
    @Input() destination_info: any;
    @Input() orderHash: any;
    same_contact = false;
    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas,
        private hs: HistoryServicesService,
    ) {}

    contactForm = this.fb.group({
        originContactName:['', Validators.required],
        originContactEmailAdddress:['', Validators.required],
        origingContactPhoneNumber:['', Validators.required],
        destinationContactName:['', Validators.required],
        destinationContactEmailAddress:['', Validators.required],
        destinationContactPhoneNumber:['', Validators.required],
        isOriginContactExternal:[false, Validators.required],
        isDestinationContactExternal:[false, Validators.required],
        sameContactOriginDestiny:false,
        orderHash:'',
    });

    openOriginContact = true;
    openDestinyContact = true;

    readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
        TuiCountryIsoCode.ES,
        TuiCountryIsoCode.GB,
        TuiCountryIsoCode.FR,
    ];

    countryIsoCode = TuiCountryIsoCode.ES;
    openElement = '';

    ngOnInit(){
        if(this.origin_info.name === this.destination_info.name && 
            this.origin_info.emailAddress === this.destination_info.emailAddress &&
            this.origin_info.phoneNumber === this.destination_info.phoneNumber && 
            this.origin_info.isExternalContact === this.destination_info.isExternalContact){
            this.same_contact = true;

            this.contactForm.patchValue(
                {
                    sameContactOriginDestiny:true,
                },{emitEvent: false}
            );
        }else{
            this.same_contact = false;
            this.contactForm.patchValue(
                {
                    sameContactOriginDestiny:false,
                },{emitEvent: false}
            );
        }

        this.contactForm.patchValue(
            {
                originContactName:this.origin_info.name,
                originContactEmailAdddress:this.origin_info.emailAddress,
                origingContactPhoneNumber:this.origin_info.phoneNumber,
                destinationContactName:this.destination_info.name,
                destinationContactEmailAddress:this.destination_info.emailAddress,
                destinationContactPhoneNumber:this.destination_info.phoneNumber,
                isOriginContactExternal:this.origin_info.isExternalContact,
                isDestinationContactExternal:this.destination_info.isExternalContact,
                orderHash:this.orderHash
            },{emitEvent: false}
        );

        this.contactForm.get('sameContactOriginDestiny')?.valueChanges.subscribe(value => {
            if(value){
                this.contactForm.patchValue(
                    {
                        destinationContactName:this.contactForm.value.originContactName,
                        destinationContactEmailAddress:this.contactForm.value.originContactEmailAdddress,
                        destinationContactPhoneNumber:this.contactForm.value.origingContactPhoneNumber,
                        isDestinationContactExternal:this.contactForm.value.isOriginContactExternal,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('isOriginContactExternal')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.sameContactOriginDestiny){
                this.contactForm.patchValue(
                    {
                        isDestinationContactExternal:value,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('originContactName')?.valueChanges.subscribe(value => {
            console.log(this.contactForm.value.sameContactOriginDestiny);
            if(this.contactForm.value.sameContactOriginDestiny){
                this.contactForm.patchValue(
                    {
                        destinationContactName:value,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('originContactEmailAdddress')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.sameContactOriginDestiny){
                this.contactForm.patchValue(
                    {
                        destinationContactEmailAddress:value,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('origingContactPhoneNumber')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.sameContactOriginDestiny){
                this.contactForm.patchValue(
                    {
                        destinationContactPhoneNumber:value,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('isDestinationContactExternal')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.originContactName === this.contactForm.value.destinationContactName && 
                this.contactForm.value.originContactEmailAdddress === this.contactForm.value.destinationContactEmailAddress &&
                this.contactForm.value.origingContactPhoneNumber === this.contactForm.value.destinationContactPhoneNumber && 
                this.contactForm.value.isOriginContactExternal === value){
                this.same_contact = true;

                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:true,
                    },{emitEvent: false}
                );
            }else{
                this.same_contact = false;
                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:false,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('destinationContactName')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.originContactName === value && 
                this.contactForm.value.originContactEmailAdddress === this.contactForm.value.destinationContactEmailAddress &&
                this.contactForm.value.origingContactPhoneNumber === this.contactForm.value.destinationContactPhoneNumber && 
                this.contactForm.value.isOriginContactExternal === this.contactForm.value.isDestinationContactExternal){
                this.same_contact = true;

                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:true,
                    },{emitEvent: false}
                );
            }else{
                this.same_contact = false;
                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:false,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('destinationContactPhoneNumber')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.originContactName === this.contactForm.value.destinationContactName && 
                this.contactForm.value.originContactEmailAdddress === this.contactForm.value.destinationContactEmailAddress &&
                this.contactForm.value.origingContactPhoneNumber === value && 
                this.contactForm.value.isOriginContactExternal === this.contactForm.value.isDestinationContactExternal){
                this.same_contact = true;

                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:true,
                    },{emitEvent: false}
                );
            }else{
                this.same_contact = false;
                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:false,
                    },{emitEvent: false}
                );
            }
        });

        this.contactForm.get('destinationContactEmailAddress')?.valueChanges.subscribe(value => {
            if(this.contactForm.value.originContactName === this.contactForm.value.destinationContactName && 
                this.contactForm.value.originContactEmailAdddress === value &&
                this.contactForm.value.origingContactPhoneNumber === this.contactForm.value.destinationContactPhoneNumber && 
                this.contactForm.value.isOriginContactExternal === this.contactForm.value.isDestinationContactExternal){
                this.same_contact = true;

                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:true,
                    },{emitEvent: false}
                );
            }else{
                this.same_contact = false;
                this.contactForm.patchValue(
                    {
                        sameContactOriginDestiny:false,
                    },{emitEvent: false}
                );
            }
        });
    }

    searchDiv(element:any){
        if(element != this.openElement){
            let div = document.getElementsByTagName('tui-dropdown');
            if(div[0] != undefined){
                console.log(div[0]);
                let content_div = document.getElementById('modal_edit_contact_info');
                let cdiv = content_div as HTMLElement;
                cdiv.innerHTML = '';
                cdiv.append(div[0]);
            }
            this.openElement = element;
        }else{
            this.openElement = '';
        }
        // console.log(element, this.openElement);
    }

    closeCanvas(){
        this.offcanvasService.dismiss();
    }

    saveContactInfo(){
        this.hs.editContactData(this.contactForm.value).subscribe((zones:any)=>{
            this.hs.publish('updateDetailsService');
            this.offcanvasService.dismiss();
            this.showDepositAlert();
        });
    }

    showDepositAlert(): void {
        this.alerts.open('Se ha actualizado de forma correcta.', {
            label: 'Se ha actualizado la información de contacto',
            status: 'success',
            autoClose: true,
        }).subscribe();
    }
}

import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Permission } from 'src/app/enum/permission.enum';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';

export const permissionGuard: CanMatchFn = async (
  route: Route,
  segments: UrlSegment[]
) => {
  const permissionsService = inject(PermissionsService);
  const router = inject(Router);

  // Convertimos el array de strings a Permission[]
  const requiredPermissions: Permission[] =
    (route.data?.['permissions'] as Permission[]) || [];

  try {
    //  Convertimos el observable a una promesa
    const userPermissions = await firstValueFrom(
      permissionsService.getPermissions()
    );

    //  Comprobamos si el usuario tiene permisos para la ruta
    const hasPermission = requiredPermissions.every((perm) =>
      userPermissions.includes(perm)
    );

    if (!hasPermission) {
      console.warn(
        `Access denied - Missing permissions: ${requiredPermissions}`
      );

      // Devolver al usuario a la ruta anterior
      const previousUrl = router.url;
      await router.navigateByUrl(previousUrl);

      return false;
    }

    return true;
  } catch (error) {
    console.error('Error fetching permissions:', error);

    // Si hay un error, devolvemos al usuario a la ruta anterior
    const previousUrl = router.url;
    await router.navigateByUrl(previousUrl);

    return false;
  }
};

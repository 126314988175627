import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  NgControl,
} from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { AssignerService } from 'src/app/services/assigner/assigner.service';

@Component({
  selector: 'app-modal-add-block',
  templateUrl: './modal-add-block.component.html',
  styleUrls: ['./modal-add-block.component.css'],
})
export class ModalAddBlockComponent {
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private assignerService: AssignerService
  ) {}

  countries = [
    {
      iso: 'es',
      country: 'España',
    },
    {
      iso: 'uk',
      country: 'UK',
    },
    {
      iso: 'fr',
      country: 'Francia',
    },
  ];

  operatives = ['Madrid', 'Barcelona', 'Sevilla'];

  events = [
    {
      date: '09/08/24',
      hour: '14:00',
      user: 'Plataforma',
      event: 'Se creó el servicio',
    },
    {
      date: '09/08/24',
      hour: '15:00',
      user: 'Plataforma',
      event: 'El Servicio se etiquetó como pendiente',
    },
    {
      date: '09/08/24',
      hour: '15:40',
      user: 'tjfer@cafler.com',
      event: 'Se aprobó el servicio',
    },
  ];

  assignamentForm = this.fb.group({
    operativeZone: [null],
    date: [''],
    general_margen: [''],
    provider_extra_time: [''],
    topping_time: [''],
    max_walk_time: [''],
    max_public_transport: [''],
    radio_km: [''],
    max_time_escoba: [''],
    startAdress: [''],
    allDay: true,
    revisiones: false,
    lavado: false,
    mantenimiento: false,
    aparcar: false,
    tramites: false,
    grua: false,
  });

  openRevisiones = false;
  openLavado = false;
  openMant = false;
  openApar = false;

  openElement = '';
  readonly columns = ['date', 'hour', 'user', 'event'];
  hours = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);
  ngOnInit() {
    let div = document.getElementsByTagName('tui-hints-host');
    if (div[0]) {
      let cdiv = div[0] as HTMLElement;
      cdiv.style.zIndex = '2000';
    }

    let divhint = document.getElementsByTagName('tui-hint');
    if (divhint[0]) {
      let cdivhint = divhint[0] as HTMLElement;
      cdivhint.style.background = '#0F1113 !important';
      cdivhint.style.color = '#fff !important';
    }

    this.assignamentForm
      .get('operativeZone')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.openElement = '';
        }
      });
  }

  searchDiv(element: any) {
    if (element != this.openElement) {
      let div = document.getElementsByTagName('tui-dropdown');
      if (div[0] != undefined) {
        let content_div = document.getElementById('modal_add_block');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
      this.openElement = element;
    } else {
      this.openElement = '';
    }
  }

  searchDiv2() {
    setTimeout(() => {
      let div = document.getElementsByClassName(
        'ng-trigger-tuiDropdownAnimation'
      );
      if (div.length > 0) {
        let content_div = document.getElementById('modal_add_block');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
    }, 200);
  }

  showDepositAlert(): void {
    this.alerts
      .open('Este bloqueo se ha registrado correctamente.', {
        label: 'Se ha creado un nuevo bloqueo',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  saveAssignament() {
    this.offcanvasService.dismiss();
    this.showDepositAlert();
  }

  closeCanvas() {
    this.offcanvasService.dismiss();
  }

  toggleDiv(ele: any) {
    if (ele == 'revisiones') {
      this.openRevisiones = !this.openRevisiones;
    }

    if (ele == 'lavado') {
      this.openLavado = !this.openLavado;
    }

    if (ele == 'mant') {
      this.openMant = !this.openMant;
    }

    if (ele == 'apar') {
      this.openApar = !this.openApar;
    }
  }
}

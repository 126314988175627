<div class="page-header">

    <label class="page-header-title">Incidencias</label>

</div>


<div class="table-body mt-5">
    
    <div class="table-body-head mb-3"> 
     <div>
         <label class="table-title">Incidencias</label>
     </div>
     <tui-hosted-dropdown
     tuiDropdownLimitWidth="auto"
     [content]="download_options"
     [(open)]="open"
 >
 <button  tuiHostedDropdownHost class="export-button">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (.csv, xls..)
 </button>
 </tui-hosted-dropdown>
    </div> 
    <div class="status-menu">
        <div class="status-option" [ngClass]="{'active': selectedStatus === 'pending'}" (click)="applyFilter('pending')">
          Pendientes <span class="status-count">{{ pendingCount }}</span>
        </div>
        <div class="status-option" [ngClass]="{'active': selectedStatus === 'resolved'}" (click)="applyFilter('resolved')" >
          Resueltas <span class="status-count">{{ resolvedCount }}</span>
        </div>
      </div>
    <div class="table-body-options mb-3">

     <div class="search-bar">
         <img src="../../../assets/icons/search- icon.svg" alt="Search Icon" class="search-icon" />
         <input #searchInput  (input)="filterData(searchInput.value)" type="text" placeholder="Buscar por order hash o matrícula..." class="search-bar-input" />
     </div>

  

     <tui-input-date
   
     tuiTextfieldSize="m"
     [(ngModel)]="selectedDate"
     (ngModelChange)="filterDataByDate()"
     style="margin-top: -8px;"

    
   > Selecciona una fecha</tui-input-date>
    

       <select class="form-select table-option-select" aria-label="status" [(ngModel)]="selectedZone" (ngModelChange)="filterDataBySelect()">
        <option  selected value="#">Zona</option>
        <option *ngFor="let zone of zones" [value]="zone">{{ zone }}</option>
      </select>
    

      <button (click)="clearDate()"  class="export-button-menu mb-2 mt-1">
        
        Limpiar fecha
    </button>

    </div>
    <div class="overflow-auto">
        <table
        tuiTable
        size="l"
        style="width: 100%;"
        [columns]="columns"
    >
        <thead>
            <tr tuiThGroup>
                <th [sticky]="true" *tuiHead="'incident'"  tuiTh>Incidencias</th>
                <th class="th-style" *tuiHead="'licensePlate'"
                tuiTh
                [sticky]="true">
                Mátricula
                </th>
                <th [tuiCell]="'l'" [sticky]="true" *tuiHead="'time'"  tuiTh>Hora</th>
                <th [sticky]="true" *tuiHead="'order-hash'" tuiTh>Order Hash</th>
                <th [sticky]="true" *tuiHead="'zone'" tuiTh>Zona</th>
                <th  *tuiHead="'date'"
                tuiTh
                [sticky]="true">
                Fecha
                </th>
               
                <th [sticky]="true" *tuiHead="'product'"  tuiTh>Producto</th>
                <th [sticky]="true" *tuiHead="'topping'"  tuiTh>Toppings</th>
                <th [sticky]="true" *tuiHead="'client'"  tuiTh>Clientes</th>
                <th [sticky]="true" *tuiHead="'service-status'"  tuiTh>Estado Servicio</th>
                <th [sticky]="true" *tuiHead="'pickupAddress'"  tuiTh>Dirección de Recogida</th>
                <th [sticky]="true" *tuiHead="'deliveryAddress'"  tuiTh>Dirección de Entrega</th>
                <th [sticky]="true" *tuiHead="'provider'"  tuiTh>Proveedores</th>
                <th [sticky]="true" *tuiHead="'comment'"  tuiTh>Comentarios</th>
                
            </tr>
        </thead>
        <tbody tuiTbody>
            <tr tuiTr *ngFor="let item of paginatedData">
                <td  *tuiCell="'incident'" tuiTd>
                    <span tuiTitle>
                        {{item.comments}}
                      </span>
                </td>
                <td *tuiCell="'licensePlate'" tuiTd>
                    <div class="vertical-align">
                        <span tuiTitle>
                            {{item.licensePlate}}
                        </span>
                        <span tuiSubtitle>
                            {{item.orderNumber}}
                        </span>
                    </div>
                </td>
   
                <td  *tuiCell="'time'" tuiTd>
                    <div [tuiCell]="size">
                        <span tuiTitle>
                       {{item.time}}   
                        </span>
                    </div>
                </td>
                <td  *tuiCell="'order-hash'" tuiTd>
                    <div [tuiCell]="size">
                        <span tuiTitle>
                       {{item.orderHash}}   
                        </span>
                    </div>
               </td>
               <td    *tuiCell="'zone'" tuiTd>
                   <div [tuiCell]="size">
                       <span tuiTitle>
                      {{item.zone}}   
                       </span>
                   </div>
               </td>
               <td    *tuiCell="'date'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                   {{item.date}}   
                    </span>
                </div>
            </td>
    
                <td  *tuiCell="'product'" tuiTd>
    
                   <span tuiTitle>
                       {{item.product}}
                    </span>
   
    
                </td>
                <td  *tuiCell="'topping'" tuiTd>
                        <span tuiTitle>
                          {{item.toppings}}
                       </span>          
                </td>
                <td  *tuiCell="'client'" tuiTd>
                        <span tuiTitle>
                          {{item.client}}
                       </span> 
                </td>
                <td  *tuiCell="'service-status'" tuiTd>
                    <div *ngIf="item.status == 'resolved'; else pending">
                        <tui-badge
                        status="success"
                        value="Resuelto"
                        class="tui-space_right-2 tui-space_bottom-2"
                    ></tui-badge>
                      </div>
                      
                      <ng-template #pending>
                        <tui-badge
                        status="warning"
                        value="Pendiente"
                        class="tui-space_right-2 tui-space_bottom-2"
                    ></tui-badge>
                      </ng-template>
               </td>

               <td *tuiCell="'pickupAddress'" tuiTd>
                <div tuiCell="l" class="address-cell"> 
                    <span tuiTitle>
                        {{item.pickupAddress}}
                    </span>
                </div>
            </td>
            <td *tuiCell="'deliveryAddress'" tuiTd>
                <div tuiCell="l" class="address-cell"> 
                    <span tuiTitle>
                        {{item.deliveryAddress}}
                    </span>
                </div>
            </td>
            <td  *tuiCell="'provider'" tuiTd>
                <span tuiTitle>
                    {{item.provider}}
                  </span>
            </td>
            <td  *tuiCell="'comment'" tuiTd>
                <span tuiTitle>
                    {{item.comments}}
                  </span>
            </td>

            </tr>
        </tbody>
    </table>
    </div>

    
 
 <div class="table-pagination  mt-5">
 
  
 <tui-pagination
 
     [index]="index"
     [length]="length"
     (indexChange)="goToPage($event)"
 ></tui-pagination>
 </div>
 
 </div>
 <ng-template
 #download_options
 let-activeZone
>
<div class="download-menu">
 <label class="mb-2 export-title ">Exportar listado</label>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.xsl)</label>
 </div>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.csv)</label>
 </div>
 <button  class="export-button-menu mb-2 mt-1">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (2)
 </button>
</div>


</ng-template>

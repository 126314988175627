<!-- Modal -->
<div id="myModal" class="modal-content">
  <div class="modal-header-custom d-flex justify-content-between">
    <div>
      <div class="service-data">
        <span class="service-name"></span>
        <span class="service-name">
          Editar
          {{
            hs.translate2(
              translate.currentLang,
              subproductData.productKey.split(" ")[0]
            )
          }}
          {{ subproductData.productKey.split(" ")[1] }}
        </span>

        <div class="display-contents">
          <span class="service-hash"
            >Ajusta el precio base del Pool para esta franja. El margen se
            recalculará automáticamente.
          </span>
        </div>
      </div>
    </div>
    <div></div>
    <button
      type="button"
      class="btn-close"
      (click)="bsModalRef?.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body-custom">
    <form [formGroup]="subproductForm" (ngSubmit)="onSubmitUpdateSubproduct()">
      <div class="client-data align-items-center">
        <div class="mt-2">
          <div class="d-flex gap-1">
            <p class="font-weight-bolder">Precio Base Pool (€)</p>
          </div>
          <p>
            <tui-input formControlName="clientMobilityTotal">
              Precio Base Pool (€)
              <input inputmode="numeric" tuiTextfield />
            </tui-input>
          </p>
        </div>
        <div class="mt-4 gap-1">
          <div>
            <div class="d-flex gap-1">
              <p class="font-weight-bolder">Premium</p>
            </div>

            <div style="justify-content: space-between" class="d-flex">
              <p class="p-price">€ Precio: 13€</p>
              <p class="p-price">% Margen: 5%</p>
            </div>
          </div>
          <div>
            <div class="d-flex gap-1">
              <p class="font-weight-bolder">Hybrid</p>
            </div>

            <div style="justify-content: space-between" class="d-flex">
              <p class="p-price">€ Precio: 13€</p>
              <p class="p-price">% Margen: 5%</p>
            </div>
          </div>
          <div>
            <div class="d-flex gap-1">
              <p class="font-weight-bolder">B2B</p>
            </div>

            <div style="justify-content: space-between" class="d-flex">
              <p class="p-price">€ Precio: 13€</p>
              <p class="p-price">% Margen: 5%</p>
            </div>
          </div>
          <div>
            <div class="d-flex gap-1">
              <p class="font-weight-bolder">B2C</p>
            </div>

            <div style="justify-content: space-between" class="d-flex">
              <p class="p-price">€ Precio: 13€</p>
              <p class="p-price">% Margen: 5%</p>
            </div>
          </div>
        </div>
      </div>
      <tui-notification size="m" status="warning" class="tui-space_vertical-4">
        La oferta está por debajo del margen de rentabilidad recomendado en
        [B2C]. Aceptarla podría afectar la rentabilidad del servicio.
      </tui-notification>
      <div class="modal-footer-custom">
        <button
          (click)="bsModalRef?.hide()"
          type="button"
          class="cancel-button-menu mt-3"
        >
          Salir sin guardar
        </button>
        <button
          (click)="bsModalRef?.hide()"
          type="submit"
          class="export-button-menu mt-3"
        >
          Guardar
        </button>
      </div>
    </form>
  </div>
</div>

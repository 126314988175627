<div class="page-header">
  <label class="page-header-title">{{ 'service_manager.history_service.title' | translate }}</label>
</div>
<div class="table-body mt-5  overflow-auto">
  <div class="table-body-head mb-3">
    <div>
      <label class="table-title">{{ 'service_manager.history_service.title_list' | translate }}</label>
    </div>
    <tui-hosted-dropdown
      tuiDropdownLimitWidth="auto"
      [content]="download_options"
      [(open)]="open"
    >

    <label class="table-title-filter">Filter ({{filterCount}})</label>
      <button tuiHostedDropdownHost class="export-button">
        <img
          class="img-icon"
          src="../../../assets/icons/download-Icon.svg"
          alt=""
        />
        {{ 'service_manager.history_service.export_text' | translate }} (.csv, xls..)
      </button>
    </tui-hosted-dropdown>
  </div>
  <div >
    <div class="table-body-options mb-3">
      <div class="search-bar">
        <img
          src="../../../assets/icons/search- icon.svg"
          alt="Search Icon"
          class="search-icon"
        />
        <input
          #searchInput
          [disabled]="filterDisabled"
          id="searchInput"
          [(ngModel)]="searchTerm"
          (input)="funcionKeyUp(searchInput.value)"
          type="text"
          placeholder="{{ 'service_manager.history_service.search_text' | translate }}"
          class="search-bar-input"
        />
      </div>
      <button
        [disabled]="filterDisabled || incidentServices.length < 1"
        (click)="filterDataBySelect()"
        appearance="primary-button"
        size="s"
        tuiButton
      >
        <tui-svg src="tuiIconSearch"></tui-svg>
        {{ 'service_manager.history_service.search_list_text' | translate }}
      </button>

      <div class="col-2">
        <tui-input-date-range
          [disabled]="filterDisabled"
          [(ngModel)]="selectedDate"
          (ngModelChange)="filterDataByDate()"
          tuiTextfieldSize="s"
          [tuiTextfieldCleaner]="true"
          [tuiTextfieldLabelOutside]="true"
        >
          {{ 'service_manager.history_service.date_input' | translate }}
          <input placeholder="desde - hasta" tuiTextfield />
        </tui-input-date-range>
      </div>

<!--       <select
        [disabled]="filterDisabled"
        (change)="filterDataByDate()"
        class="form-select table-option-select"
        aria-label="zone"
        [(ngModel)]="selectedZone"
      >
        <option selected value="#">Zona</option>
        <option *ngFor="let zone of zones" [value]="zone.zoneId">
          {{ zone.zoneName }}
        </option>
      </select> -->
      <ng-select
      [items]="zones"
      bindLabel="zoneName"
      bindValue="zoneId"
      [multiple]="false"
      [(ngModel)]="selectedZone"
      [disabled]="filterDisabled"
      class="custom_select"
      (close)="filterDataByDate()"
      placeholder="{{ 'service_manager.history_service.zone_input' | translate }}"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>

      <!--      <select
        [disabled]="filterDisabled"
        class="form-select table-option-select"
        id="select_product"
        [(ngModel)]="selectedClient"
        (ngModelChange)="filterDataBySelect()"
      >
        <option selected value="#">Cliente</option>
        <option *ngFor="let cli of clients" [value]="cli">{{ cli }}</option>
      </select> -->

      <ng-select
        [items]="all_clients"
        bindLabel="businessName"
        bindValue="userId"
        [multiple]="true"
        [(ngModel)]="selectedClient"
        [disabled]="filterDisabled"
        class="custom_select"
        (change)="filterDataBySelect()"
        placeholder="{{ 'service_manager.history_service.clients_input' | translate }}"
        [dropdownPosition]="'bottom'"
      >
      </ng-select>

      <!--             <select [disabled]="filterDisabled" class="form-select table-option-select" id="select_product" [(ngModel)]="selectedOrderStatus" (ngModelChange)="filterDataBySelect()">
                <option selected value="#">Estado</option>
                <option *ngFor="let status of orderStatus" [value]="status.orderStatus">{{ status.statusName }}</option>
            </select> -->

      <ng-select
        [items]="orderStatus"
        bindLabel="statusName"
        bindValue="orderStatus"
        [multiple]="true"
        [(ngModel)]="selectedOrderStatus"
        placeholder="{{ 'service_manager.history_service.status_input' | translate }}"
        class="custom_select"
        (change)="filterDataBySelect()"
        [dropdownPosition]="'bottom'"
      >
      </ng-select>

      <!--       <select
        [disabled]="filterDisabled"
        class="form-select table-option-select"
        id="select_product"
        [(ngModel)]="selectedProduct"
        (ngModelChange)="filterDataBySelect()"
      >
        <option selected value="#">Producto</option>
        <option *ngFor="let pro of products" [value]="pro">{{ pro }}</option>
      </select> -->
      <ng-select
        [items]="products"
        bindLabel="product"
        [multiple]="true"
        [(ngModel)]="selectedProduct"
        [disabled]="filterDisabled"
        class="custom_select"
        (change)="filterDataBySelect()"
        placeholder="{{ 'service_manager.history_service.products_input' | translate }}"
        [dropdownPosition]="'bottom'"
      >
      </ng-select>

      <!--       <select
        [disabled]="filterDisabled"
        class="form-select table-option-select"
        id="select_product"
        [(ngModel)]="selectedVertical"
        (ngModelChange)="filterDataBySelect()"
      >
        <option selected value="#">Vertical</option>
        <option *ngFor="let vertical of verticals" [value]="vertical.type">
          {{ vertical.name }}
        </option>
      </select> -->

      <ng-select
        [items]="verticals"
        bindLabel="name"
        bindValue="type"
        [multiple]="true"
        [(ngModel)]="selectedVertical"
        [disabled]="filterDisabled"
        (change)="filterDataBySelect()"
        class="custom_select"
        placeholder="{{ 'service_manager.history_service.vertical_input' | translate }}"
        [dropdownPosition]="'bottom'"
      >
      </ng-select>

      <!-- <div class="col-2">
            <select class="form-select table-option-select" id="select_product" [(ngModel)]="selectedProduct" (ngModelChange)="filterDataBySelect()">
                <option selected value="#">Proveedores</option>
                <option *ngFor="let pro of products" [value]="pro">{{ pro }}</option>
            </select>
        </div> -->
    </div>
    <div class="table-body-options mb-3">
      <!-- <input #searchInput (input)="filterData(searchInput.value)" type="text" placeholder="Buscar por order hash o matrícula..." class="search-bar-input" /> -->
      <!-- <button (click)="clearDate()"  class="export-button-menu mb-2 mt-1">
            Limpiar fecha
        </button> -->
    </div>

    <table
      tuiTable
      size="l"
      style="width: 100%"
      [columns]="columns"
      (directionChange)="onSort($event)"
    >
      <thead>
        <tr tuiThGroup>
          <th
            class="th-style"
            *tuiHead="'licensePlate'"
            tuiTh
            (click)="setSortColumn('licensePlate')"
          >
            {{ 'service_manager.history_service.plate_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'order-hash'"
            tuiTh
            (click)="setSortColumn('orderHash')"
          >
            {{ 'service_manager.history_service.hash_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'service-status'"
            (click)="setSortColumn('orderStatus')"
            tuiTh
          >
            {{ 'service_manager.history_service.status_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'date'"
            (click)="setSortColumn('formattedDate')"
            tuiTh
          >
            {{ 'service_manager.history_service.date_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'time'"
            (click)="setSortColumn('hour')"
            tuiTh
          >
            {{ 'service_manager.history_service.hour_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'zone'"
            (click)="setSortColumn('zoneName')"
            tuiTh
          >
            {{ 'service_manager.history_service.zone_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'vertical'"
            (click)="setSortColumn('vertical')"
            tuiTh
          >
            {{ 'service_manager.history_service.vertical_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'product'"
            (click)="setSortColumn('mainProductName')"
            tuiTh
          >
            {{ 'service_manager.history_service.product_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'client'"
            (click)="setSortColumn('clientName')"
            tuiTh
          >
            {{ 'service_manager.history_service.client_input_table' | translate }}
          </th>
          <!--             <th [sticky]="true" *tuiHead="'incident'"  tuiTh>
                    Incidencia
                </th> -->
          <th
            [sticky]="true"
            *tuiHead="'pickupAddress'"
            (click)="setSortColumn('servicePickupAddress')"
            tuiTh
          >
            {{ 'service_manager.history_service.pickup_address_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'deliveryAddress'"
            (click)="setSortColumn('serviceDeliveryAddress')"
            tuiTh
          >
            {{ 'service_manager.history_service.delivery_input_table' | translate }}
          </th>
          <!--                 <th [sticky]="true" *tuiHead="'providers'" tuiTh>
                    Proveedores
                </th> -->
          <th
            [sticky]="true"
            *tuiHead="'comment'"
            (click)="setSortColumn('comment')"
            tuiTh
          >
            {{ 'service_manager.history_service.comments_input_table' | translate }}
          </th>
          <th [sticky]="true" *tuiHead="'actions'" tuiTh>{{ 'service_manager.history_service.actions_input_table' | translate }}</th>
        </tr>
      </thead>
      <tbody tuiTbody>
        <tr tuiTr *ngFor="let item of paginatedData">
          <td *tuiCell="'licensePlate'" tuiTd>
            <div class="vertical-align">
              <span tuiTitle>
                {{ item.licensePlate }}
              </span>
            </div>
          </td>
          <td *tuiCell="'order-hash'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.orderHash }}
              </span>
            </div>
          </td>
          <td *tuiCell="'service-status'" tuiTd>
            <div *ngIf="item.orderStatus == 'INITIALIZED'">
              <tui-badge
                status="neutral"
                value="Inicializado"
                class="tui-space_right-2 tui-space_bottom-2 custom_initalized"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'PENDING'">
              <tui-badge
                status="neutral"
                value="Pendiente"
                class="tui-space_right-2 tui-space_bottom-2 custom_pending"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'CONFIRMED'">
              <tui-badge
                status="success"
                value="Confirmado"
                class="tui-space_right-2 tui-space_bottom-2 custom_confirmed"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'PICKING_UP'">
              <tui-badge
                status="success"
                value="Recogiendo"
                class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'IN_PROGRESS'">
              <tui-badge
                status="success"
                value="En proceso"
                class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'DELIVERING'">
              <tui-badge
                status="success"
                value="Entregando"
                class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'FINISHED'">
              <tui-badge
                status="success"
                value="Finalizado"
                class="tui-space_right-2 tui-space_bottom-2 custom_finished"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'FAILED'">
              <tui-badge
                status="error"
                value="Fallado"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'CANCELLED_BY_CAFLER'">
              <tui-badge
                status="error"
                value="Cancelado por Cafler"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
            <div *ngIf="item.orderStatus == 'CANCELLED_BY_CLIENT'">
              <tui-badge
                status="error"
                value="Cancelado por cliente"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
          </td>
          <td *tuiCell="'date'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.formattedDate }}
              </span>
            </div>
          </td>
          <td *tuiCell="'time'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.hour }}
              </span>
            </div>
          </td>
          <td *tuiCell="'zone'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.zoneName }}
              </span>
            </div>
          </td>
          <td *tuiCell="'vertical'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.vertical }}
              </span>
            </div>
          </td>
          <td *tuiCell="'product'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.mainProductName }}
              </span>
            </div>
          </td>
          <td *tuiCell="'client'" tuiTd>
            <span tuiTitle>
              {{
                getClientById(item.clientId)
                  ? getClientById(item.clientId)
                  : item.clientName
              }}
            </span>
          </td>
          <!--                 <td *tuiCell="'incident'" tuiTd>
                    <span tuiTitle>
                       {{ item.isPriority }}
                    </span>
                </td> -->
          <td *tuiCell="'pickupAddress'" tuiTd>
            <div tuiCell="l" class="address-cell">
              <span tuiTitle>
                {{ item.servicePickupAddress }}
              </span>
            </div>
          </td>
          <td *tuiCell="'deliveryAddress'" tuiTd>
            <div tuiCell="l" class="address-cell">
              <span tuiTitle>
                {{ item.serviceDeliveryAddress }}
              </span>
            </div>
          </td>
          <!--                 <td  *tuiCell="'providers'" tuiTd>
                    <span tuiTitle>
                        <span [innerHTML]="item.providerNames.join('<br>')"></span>
                    </span>          
                </td> -->
          <td *tuiCell="'comment'" tuiTd>
            <span tuiTitle>
              <label>{{ item.comment }}</label>
              <label *ngIf="item.comment.length == 0">Sin Comentarios</label>
            </span>
          </td>
          <td *tuiCell="'actions'" tuiTd>
            <span class="seeAllIcon" tuiStatus>
              <button
                class="seeAllIcon"
                appearance="link"
                icon="tuiIconEye"
                size="xs"
                tuiAppearance="link"
                tuiIconButton
                (click)="viewDetail(item)"
              >
                <span>Ver resultados</span>
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-pagination mt-5">
    <tui-pagination
      [index]="index"
      [length]="length"
      (indexChange)="goToPage($event)"
    ></tui-pagination>
  </div>
</div>
<ng-template #download_options let-activeZone>
  <div class="download-menu">
    <label class="mb-2 export-title">{{ 'service_manager.history_service.complement_export_text' | translate }}</label>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isXlsxSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.xlsx)</label>
    </div>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isCsvSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.csv)</label>
    </div>

    <button (click)="exportTo()" class="export-button-menu mb-2 mt-1">
      <img
        class="img-icon"
        src="../../../assets/icons/download-Icon.svg"
        alt=""
      />
      {{ 'service_manager.history_service.export_text' | translate }} ({{ exportCount }})
    </button>
  </div>
</ng-template>

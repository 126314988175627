import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { TuiAlertService } from '@taiga-ui/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { ServicesService } from 'src/app/services/service/services.service';
import { ErrorItem, GanttTask } from '../gantt/interfaces/Gant.interface';
import { Zone } from 'src/app/models/zone/zone.model';
import { Verticals } from 'src/app/models/service/service.model';
import { formatTime } from 'src/app/utils';

declare var MarkerClusterer: any;
declare var moment: any;
moment.locale('es');

@Component({
  selector: 'app-service-data-modal',
  templateUrl: './service-data-modal.component.html',
  styleUrls: ['./service-data-modal.component.css'],
})
export class ServiceDataModalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() bsModalRef?: BsModalRef;
  @Input() serviceData!: GanttTask;
  @Input() zoneId: string = '';
  @Input() zones!: Zone[];
  @Input() dateService!: Date | string;
  @Output() updateGantt = new EventEmitter<boolean>();

  @ViewChild('littleGmapContainer', { static: false }) gmap?: ElementRef;

  isNodeLockedForDriverAssignation: boolean = true;

  public lat: number = 40.4165;
  public lng: number = -3.70256;
  public zoom_level: number = 6;

  public open: boolean = false;
  public map!: google.maps.Map;

  customMarkers: google.maps.Marker[] = [];
  directionsRenderers: google.maps.DirectionsRenderer[] = [];
  coordinates = new google.maps.LatLng(this.lat, this.lng);
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 6,
    disableDefaultUI: true,
    zoomControl: true,
  };

  public directionsRenderer!: google.maps.DirectionsRenderer;

  markerCluster: any; // Pending to know whay type is
  public type_driver_start: string = 'service';
  public icon_driver_start: string = '';

  modalInstance: BsModalRef | undefined;

  public verticals: Verticals[] = [];
  public errors: ErrorItem[] = [
    {
      show: "Generic error code. It also marks that something internal failed, and we don't wanna expose internal code.",
      code: 'LMP000000',
      error: 'UnknownError',
    },
    {
      show: 'The requested zone is invalid.',
      code: 'LMP000001',
      error: 'InvalidZone',
    },
    {
      show: "The requested zone doesn't have any products.",
      code: 'LMP000002',
      error: 'NoProductsInZone',
    },
    {
      show: 'The requested product is invalid.',
      code: 'LMP000003',
      error: 'InvalidProduct',
    },
    {
      show: 'The requested product in the specified zone context is invalid.',
      code: 'LMP000004',
      error: 'InvalidProductInZone',
    },
    {
      show: 'There were no products in the product creation attempt.',
      code: 'LMP000005',
      error: 'NoProductsInProductCreationAttempt',
    },
    {
      show: 'A specified product configuration is invalid.',
      code: 'LMP000006',
      error: 'InvalidProductConfiguration',
    },
    {
      show: 'The requested valet station does not exists.',
      code: 'LMP000007',
      error: 'ValetStationDoesNotExist',
    },
    {
      show: 'The origin address point is invalid.',
      code: 'LMP000008',
      error: 'InvalidOriginAddressPoint',
    },
    {
      show: 'The destination address point is invalid.',
      code: 'LMP000009',
      error: 'InvalidDestinationAddressPoint',
    },
    {
      show: 'The vehicle availability requested for a replacement vehicle is invalid.',
      code: 'LMP0000010',
      error: 'InvalidVehicleAvailabilityForReplacementVehicle',
    },
    {
      show: 'Depending on the context of the request, the endpoint used is not the correct one.',
      code: 'LMP000011',
      error: 'InvalidEndpointForRequestContext',
    },
    {
      show: 'Some products in the order are missing a provider.',
      code: 'LMP0000012',
      error: 'ProvidersNotAssigned',
    },
    {
      show: 'The requested order does not exist.',
      code: 'LMP0000013',
      error: 'OrderDoesNotExists',
    },
    {
      show: 'The requested provider does not exist.',
      code: 'LMP0000014',
      error: 'ProviderDoesNotExist',
    },
    {
      show: 'The order hash provider is non-compliant with known formats.',
      code: 'LMP0000015',
      error: 'InvalidOrderHash',
    },
    {
      show: 'The specified driver id is invalid or malformed.',
      code: 'LMP0000016',
      error: 'InvalidDriverId',
    },
    {
      show: 'The requested driver does not exist.',
      code: 'LMP000017',
      error: 'DriverDoesNotExists',
    },
    {
      show: 'A cancellation of a cancelled order cannot be performed.',
      code: 'LMP000018',
      error: 'CannotCancelACancelledOrder',
    },
    {
      show: 'A cancellation of an invalid order cannot be performed.',
      code: 'LMP000019',
      error: 'CannotCancelAnInvalidOrder',
    },
    {
      show: 'Assigning drivers to a service without nodes is not allowed.',
      code: 'LMP0000021',
      error: 'CannotAssignDriverToServiceWithoutNodes',
    },
    {
      show: "Due to the status of the order, we don't allow the requested operation.",
      code: 'LMP0000022',
      error: 'OrderStatusDoesNotAllowsOperation',
    },
  ];

  constructor(
    private hs: HistoryServicesService,
    private router: Router,
    private servicesSvc: ServicesService,
    private readonly alerts: TuiAlertService
  ) {}

  ngOnInit(): void {
    this.getVerticals()
  }

  getVerticals(): void {
    this.verticals = this.servicesSvc.verticals;
  }

  // [ER - 02/04/2025] - Old code, commented if we need in a future
  // mapInitializer() {
  //   this.map = new google.maps.Map(this.gmap?.nativeElement, this.mapOptions);
  // }

  private mapInitializer(): void {
    if (this.gmap) {
      this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    }
  }

  // [ER - 02/04/2025] - Old code, commented if we need in a future
  // async ngAfterViewInit() {
  //   this.mapInitializer();
  //   await this.drawRoutes();
  //   this.isNodeLockedForDriverAssignation =
  //     this.serviceData.isNodeLockedForDriverAssignation;
  // }

  async ngAfterViewInit(): Promise<void> {
    this.mapInitializer();
    await this.drawRoutes();
    this.isNodeLockedForDriverAssignation =
      this.serviceData.isNodeLockedForDriverAssignation;
  }

  public getFormattedTime(seconds: string): string {
    return formatTime(seconds);
  }

  //  [ER - 02/04/2025] - Old code, commented if we need in a future and also de frontend doesnt have to transformate the date
  // formatTime(date: any) {
  //   // console.log(this.zoneId);
  //   const zone = this.zones.find((zone: any) => zone.zoneId === this.zoneId);
  //   const dateInClientZone = moment(this.dateService).tz(zone.timeZone);

  //   let isCh = dateInClientZone.isDST();

  //   const newDate = new Date(parseInt(date) * 1000);
  //   let hours = String(newDate.getUTCHours()).padStart(2, '0');
  //   if (isCh) {
  //     hours = String(newDate.getUTCHours() - 1).padStart(2, '0');
  //   }
  //   const minutes = String(newDate.getUTCMinutes()).padStart(2, '0');

  //   return `${hours}:${minutes}`;
  //   // return dateInClientZone.format('HH:mm');
  // }

  // [ER - 02/04/2025] - Old code, commented if we need in a future and also de frontend doesnt have to transformate the date
  // viewDetail(data: any) {
  //   this.closeModal();
  //   const datos = { service_info: data };

  //   this.hs.setData(datos);

  //   const relativeUrl = this.router
  //     .createUrlTree(['/service-manager/detail-service'], {
  //       queryParams: { orderHash: data.orderHash },
  //     })
  //     .toString();

  //   const absoluteUrl = window.location.origin + '/#/' + relativeUrl;

  //   window.open(absoluteUrl, '_self');
  // }

  viewDetail(data: GanttTask): void {
    this.closeModal();
    const datos = { service_info: data };
    this.hs.setData(datos);
    const relativeUrl: UrlTree = this.router.createUrlTree(
      ['/service-manager/detail-service'],
      {
        queryParams: { orderHash: data.orderHash },
      }
    );
    const absoluteUrl: string = `${
      window.location.origin
    }/#/${relativeUrl.toString()}`;
    window.open(absoluteUrl, '_self');
  }

  closeModal(): void {
    this.bsModalRef?.hide();
  }

  // [ER - 02/04/2024] - OLD CODE
  // closeModal() {
  //   if (this.bsModalRef) {
  //     this.bsModalRef.hide();
  //   }
  // }

  // [ER - 02/04/2024] - OLD CODE
  // getVertical(verticalType: string) {
  //   const selectedVertical = this.verticals.find(
  //     (vertical) => vertical.type === verticalType
  //   );

  //   return selectedVertical.name;
  // }

  getVertical(verticalType: string): string | undefined {
    return this.verticals.find((vertical) => vertical.type === verticalType)
      ?.name;
  }

  // [ER 02/04/2025] - OLD CODE
  // async drawRoutes() {
  //   const directionsService = new google.maps.DirectionsService();
  //   this.directionsRenderer = new google.maps.DirectionsRenderer({
  //     suppressMarkers: true,
  //   });
  //   let waypts: google.maps.DirectionsWaypoint[] = [];
  //   let start = {
  //     lat: this.serviceData.startLatitude,
  //     lng: this.serviceData.startLongitude,
  //   };
  //   let end = {
  //     lat: this.serviceData.endLatitude,
  //     lng: this.serviceData.endLongitude,
  //   };

  //   this.directionsRenderer.setMap(this.map);

  //   directionsService.route(
  //     {
  //       origin: start,
  //       destination: end,
  //       waypoints: waypts,
  //       optimizeWaypoints: true,
  //       travelMode: google.maps.TravelMode.DRIVING,
  //     },
  //     (response) => {
  //       this.directionsRenderer.setDirections(response);
  //       this.directionsRenderers.push(this.directionsRenderer);
  //       this.addCustomMarkers(response);
  //     }
  //   );
  // }

  async drawRoutes(): Promise<void> {
    const directionsService: google.maps.DirectionsService =
      new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    const waypts: google.maps.DirectionsWaypoint[] = [];
    const start = {
      lat: this.serviceData.startLatitude,
      lng: this.serviceData.startLongitude,
    };
    const end = {
      lat: this.serviceData.endLatitude,
      lng: this.serviceData.endLongitude,
    };

    this.directionsRenderer.setMap(this.map);

    directionsService.route(
      {
        origin: start,
        destination: end,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (
        response: google.maps.DirectionsResult,
        status: google.maps.DirectionsStatus
      ) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.directionsRenderer.setDirections(response);
          this.directionsRenderers.push(this.directionsRenderer);
          this.addCustomMarkers(response);
        }
      }
    );
  }

  // addCustomMarkers(response: any): void {
  //   const route = response.routes[0];

  //   const legs = route?.legs;

  //   this.icon_driver_start = this.getInitial('Cesar Farfan');
  //   console.log(legs, 'ROUTE');
  //   for (let i = 0; i < legs.length; i++) {
  //     const leg = legs[i];

  //     if (leg.via_waypoints && leg.via_waypoints.length > 0) {
  //       leg.via_waypoints.forEach((waypoint: any, index: any) => {
  //         let cc = new google.maps.Marker({
  //           position: waypoint, // Cada via_waypoint
  //           map: this.map,
  //           icon: {
  //             url: 'assets/img/icon_service3.png', // Ruta a tu ícono personalizado
  //             scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
  //           },
  //           title: '',
  //         });

  //         this.customMarkers.push(cc);
  //       });
  //     }
  //     if (i === 0) {
  //       if (this.type_driver_start == 'service') {
  //         let pi = new google.maps.Marker({
  //           position: leg.start_location, // Punto de inicio del primer leg
  //           map: this.map,
  //           icon: {
  //             url: 'assets/img/icon_service.png', // Ruta a tu ícono personalizado
  //             scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
  //           },
  //           title: leg.start_address,
  //         });
  //         this.customMarkers.push(pi);
  //       } else {
  //         let pi = new google.maps.Marker({
  //           position: leg.start_location, // Punto de inicio del primer leg
  //           map: this.map,
  //           icon: {
  //             url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
  //               this.icon_driver_start
  //             )}`,
  //             scaledSize: new google.maps.Size(30, 30),
  //           },
  //           title: '',
  //         });
  //         this.customMarkers.push(pi);
  //       }
  //     }

  //     // Marcar el punto final del leg
  //     if (i === legs.length - 1) {
  //       let pf = new google.maps.Marker({
  //         position: leg.end_location, // Punto final del último leg
  //         map: this.map,
  //         icon: {
  //           url: 'assets/img/icon_service2.png', // Ruta a tu ícono personalizado
  //           scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
  //         },
  //         title: leg.end_address,
  //       });
  //       this.customMarkers.push(pf);
  //     } else {
  //       let pw = new google.maps.Marker({
  //         position: leg.end_location,
  //         map: this.map,
  //         icon: {
  //           url: 'assets/img/icon_service3.png', // Ruta a tu ícono personalizado
  //           scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
  //         },
  //         title: leg.end_address,
  //       });
  //       this.customMarkers.push(pw);
  //     }
  //   }

  //   let cmarkers = this.customMarkers;
  //   let map = this.map;
  //   const markerClusterNew = new MarkerClusterer(map, cmarkers, {
  //     styles: [
  //       {
  //         width: 30,
  //         height: 30,
  //         className: 'custom-clustericon-1',
  //       },
  //       {
  //         width: 30,
  //         height: 30,
  //         className: 'custom-clustericon-2',
  //       },
  //       {
  //         width: 30,
  //         height: 30,
  //         className: 'custom-clustericon-3',
  //       },
  //     ],
  //     clusterClass: 'custom-clustericon',
  //     zoomOnClick: false,
  //   });
  //   this.markerCluster = markerClusterNew;
  //   let infoWindow = new google.maps.InfoWindow({
  //     pixelOffset: new google.maps.Size(0, 0),
  //   });

  //   google.maps.event.addListener(markerClusterNew, 'click', (cluster) => {
  //     const markers = cluster.getMarkers();
  //     let content =
  //       '\
  //           <table class="table table-bordered" style="width:700px">\
  //               <thead>\
  //               </thead>\
  //               <tbody>';
  //     markers.forEach((marker: any) => {
  //       let icon = marker.getIcon();
  //       if (marker.getTitle() != '') {
  //         content += `<tr><td><img src="${
  //           icon.url
  //         }" width="30" /> ${marker.getTitle()}</td><tr>`;
  //       }
  //     });
  //     content += '</tbody></table>';

  //     infoWindow.setContent(content);
  //     infoWindow.setPosition(cluster.getCenter());
  //     infoWindow.open(map);
  //   });
  // }

  private addCustomMarkers(response: google.maps.DirectionsResult): void {
    const route = response.routes[0];
    const legs = route?.legs;
    this.icon_driver_start = this.getInitial('Cesar Farfan');

    if (legs) {
      legs.forEach((leg: google.maps.DirectionsLeg, i: number) => {
        // Si existen waypoints, añade marcadores para cada uno
        if (leg.via_waypoints && leg.via_waypoints.length > 0) {
          leg.via_waypoints.forEach(
            (waypoint: google.maps.LatLng, index: number) => {
              const marker: google.maps.Marker = new google.maps.Marker({
                position: waypoint,
                map: this.map,
                icon: {
                  url: 'assets/img/icon_service3.png',
                  scaledSize: new google.maps.Size(30, 30),
                },
                title: '',
              });
              this.customMarkers.push(marker);
            }
          );
        }
        // Marcador de inicio (solo en el primer leg)
        if (i === 0) {
          const markerIcon: string =
            this.type_driver_start === 'service'
              ? 'assets/img/icon_service.png'
              : `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                  this.icon_driver_start
                )}`;
          const marker: google.maps.Marker = new google.maps.Marker({
            position: leg.start_location,
            map: this.map,
            icon: { url: markerIcon, scaledSize: new google.maps.Size(30, 30) },
            title:
              this.type_driver_start === 'service' ? leg.start_address : '',
          });
          this.customMarkers.push(marker);
        }
        // Marcador final o intermedio
        const marker: google.maps.Marker = new google.maps.Marker({
          position: leg.end_location,
          map: this.map,
          icon: {
            url:
              i === legs.length - 1
                ? 'assets/img/icon_service2.png'
                : 'assets/img/icon_service3.png',
            scaledSize: new google.maps.Size(30, 30),
          },
          title: leg.end_address,
        });
        this.customMarkers.push(marker);
      });
    }
    // Inicializa MarkerClusterer
    this.markerCluster = new MarkerClusterer(this.map, this.customMarkers, {
      styles: [
        { width: 30, height: 30, className: 'custom-clustericon-1' },
        { width: 30, height: 30, className: 'custom-clustericon-2' },
        { width: 30, height: 30, className: 'custom-clustericon-3' },
      ],
      clusterClass: 'custom-clustericon',
      zoomOnClick: false,
    });

    const infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, 0),
    });

    google.maps.event.addListener(
      this.markerCluster,
      'click',
      (cluster: any) => {
        const markers: google.maps.Marker[] = cluster.getMarkers();
        let content: string = `<table class="table table-bordered" style="width:700px"><tbody>`;

        markers.forEach((marker: google.maps.Marker) => {
          const icon = marker.getIcon();
          let iconUrl: string = '';

          if (typeof icon === 'string') {
            iconUrl = icon;
          } else if (icon && 'url' in icon && typeof icon.url === 'string') {
            iconUrl = icon.url;
          }

          if (marker.getTitle() !== '' && iconUrl) {
            content += `<tr><td><img src="${iconUrl}" width="30" /> ${marker.getTitle()}</td></tr>`;
          }
        });

        content += `</tbody></table>`;
        infoWindow.setContent(content);
        infoWindow.setPosition(cluster.getCenter());
        infoWindow.open(this.map);
      }
    );
  }

  // [ER - 02/04/2025 - OLD CODE]
  // updateNodeStatus() {
  //   const body = {
  //     orderHash: this.serviceData.orderHash,
  //     nodeId: this.serviceData.nodeId,
  //     desiredBlockState: this.isNodeLockedForDriverAssignation,
  //   };
  //   this.servicesSvc.updateNodeStatus(body).subscribe({
  //     next: (response) => {
  //       this.bsModalRef.hide();
  //       this.updateGantt.emit(true);
  //       console.log(response);
  //     },
  //     error: (error) => {
  //       this.showErrorCode(error.statusCode);
  //       console.log(error);
  //     },
  //   });

  //   console.log(body);
  // }

  updateNodeStatus(): void {
    if (!this.serviceData) {
      console.error('serviceData is undefined');
      return;
    }
    const body = {
      orderHash: this.serviceData.orderHash,
      nodeId: this.serviceData.nodeId,
      desiredBlockState: this.isNodeLockedForDriverAssignation,
    };
    this.servicesSvc.updateNodeStatus(body).subscribe({
      next: (response) => {
        if (this.bsModalRef) {
          this.bsModalRef.hide();
        }
        this.updateGantt.emit(true);
      },
      error: (error) => {
        this.showErrorCode(error.statusCode);
        console.error(error);
      },
    });
  }

  // [ER - 02/04/2025] - OLD CODE
  // getInitial(name: any) {
  //   name = name.split(' ');
  //   return `
  //       <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
  //           <circle cx="15" cy="15" r="15" fill="#59F89C"/>
  //           <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="17" font-family="Arial">${
  //             name[0][0] + name[1][0]
  //           }</text>
  //       </svg>`;
  // }

  getInitial(name: string): string {
    const names: string[] = name.split(' ');
    const initials: string = names[0][0] + (names[1] ? names[1][0] : '');
    return `
      <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="#59F89C"/>
        <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="17" font-family="Arial">
          ${initials}
        </text>
      </svg>`;
  }

  // [ER - 02/04/2025] - OLD CODE
  //   showErrorCode(code: any) {
  //     let error = this.errors.find((x: any) => x.code == code);
  //     let show = 'Unknown Error';
  //     if (error) {
  //       show = error.show;
  //     }
  //     this.alerts
  //       .open(show, {
  //         label: 'Ha ocurrido un error',
  //         status: 'error',
  //         autoClose: true,
  //       })
  //       .subscribe();
  //   }
  // }//

  private showErrorCode(code: string): void {
    const errorItem: ErrorItem | undefined = this.errors.find(
      (x) => x.code === code
    );
    const message: string = errorItem ? errorItem.show : 'Unknown Error';
    this.alerts
      .open(message, {
        label: 'Ha ocurrido un error',
        status: 'error',
        autoClose: true,
      })
      .subscribe();
  }

  ngOnDestroy(): void {
    this.servicesSvc.ganttDataParams = {
      zoneId: this.zoneId,
      searchTerm: '', // Se puede tipar y asignar según sea necesario
      date: this.dateService,
    };
  }
}

import {
  Component,
  HostListener,
  AfterViewChecked,
  Input,
  TemplateRef,
  Renderer2,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ServicesService } from 'src/app/services/services.service';
import { firstValueFrom } from 'rxjs';
declare var moment: any;
moment.locale('es');
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css'],
})
export class GanttComponent implements AfterViewChecked {
  @ViewChild('gantt_container') ganttContainer!: ElementRef;
  lastScrollTop = 0;
  lastScrollLeft = 0;
  scrollClass = '';
  ganttStartTime: number = 6;
  ganttEndTime: number = 21;
  rangeWidth = 100;
  dates!: string[];
  selectedDate: any = null;
  selectedZone!: any;
  zones: any[] = [];
  bsModalRef?: BsModalRef;
  actualDate: any = moment().locale('es').format('D [de] MMMM [de] YYYY');
  currentTimeStyle: boolean = true;
  driversData: any = [];
  date: any = Math.floor(new Date('2024-08-14').getTime() / 1000);
  serviceData: any;
  ganttDataOriginal: any = [];
  hours: string[] = this.getHoursOfDay(this.ganttStartTime, this.ganttEndTime);
  verticals: any[] = [
    { name: 'Desconocido', type: 'UNKNOWN' },
    { name: 'Transfer', type: 'TRANSFER' },
    { name: 'Taller', type: 'MECHANICAL_INSPECTION' },
    { name: 'Lavado', type: 'VEHICLE_WASH' },
    { name: 'Repostaje', type: 'REFUELING' },
    { name: 'Pre-ITV', type: 'PRE_TECHNICAL_INSPECTION' },
    { name: 'Transfer Larga Distancia', type: 'LONG_DISTANCE_TRANSFER' },
    { name: 'ITV', type: 'TECHNICAL_INSPECTION' },
    { name: 'Seguro', type: 'VEHICLE_INSURANCE' },
    { name: 'Cafler Fresh', type: 'ACCESSORY' },
    { name: 'Valet', type: 'VALET' },
    { name: 'Neumáticos', type: 'TYRES' },
    { name: 'Trámites', type: 'FORMALITIES' },
    { name: 'Parking', type: 'PARKING' },
    { name: 'Vehículo de sustitución', type: 'REPLACEMENT_VEHICLE' },
    { name: 'Grúa', type: 'TOW_TRUCK' },
    { name: 'Pide lo que quieras', type: 'WHATEVER_YOU_WANT' },
  ];
  searchTerm: string = '';
  /* @Input() ganttData: IGanttData = {rows:[]};  */
  ganttData: any = [];

  @ViewChild('padre') padre!: ElementRef;
  scrollPosition = 0;
  scrollPositionY = 0;
  constructor(
    private modalService: BsModalService,
    private servicesSvc: ServicesService,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {
    this.servicesSvc.getZones().subscribe((res: any) => {
      this.zones = res.zones;
    });

    this.selectedDate = new Date();
  }

  ngOnInit() {
    const oldParams = this.servicesSvc.ganttDataParams;
    if (Object.keys(oldParams).length > 0) {
      this.selectedZone = oldParams.zoneId;
      this.searchTerm = oldParams.searchTerm;
      this.selectedDate = oldParams.date;

      this.filterDataByDate();
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    this.scrollPosition = target.scrollLeft;
    this.scrollPositionY = target.scrollTop;
    console.log(this.scrollPosition);
  }

  ngAfterViewInit() {
    this.padre.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );
  }

  ngOnDestroy() {
    this.servicesSvc.ganttDataParams = {
      zoneId: this.selectedZone,

      searchTerm: this.searchTerm,
      date: this.selectedDate,
    };

    console.log(this.servicesSvc.ganttDataParams, 'DATA ON DESTROY');
  }

  ngAfterViewChecked() {
    if (this.ganttData.length) {
      if (this.currentTimeStyle) {
        const div = document.querySelector(
          '.gantt-main-container'
        ) as HTMLDivElement;
        if (div) {
          const containerWidth = div.offsetWidth ?? 500;
          div.scrollLeft =
            this.getCurrentTimeScrollingWidth() - containerWidth / 2;
        }
        this.currentTimeStyle = false;
      }
    } else {
      this.currentTimeStyle = true;
    }

    /*   const x = this.getCurrentTimeStyle()
    this.currentTimeStyle = {...x} ; */
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {}

  /**Data functions */
  getServiceType(id?: number) {
    /*       if (!id) return 'Desconocido';
      return this.getValuesService.getServiceTypeString(id); */
  }

  getHoursOfDay(startHour: number, endHour: number) {
    let times: string[] = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = i < 10 ? '0' + i : i.toString();
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
    }
    return times;
  }

  getCurrentTimeScrollingWidth(): number {
    const mainContainer = document.querySelector(
      '.gantt-main-container'
    ) as HTMLDivElement;

    let currentTime = this.getCurrentTimeInDecimal();
    if (currentTime > this.ganttEndTime) {
      currentTime = this.ganttEndTime;
    }

    let startTimeDiff = currentTime - this.ganttStartTime;

    return startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2;
  }

  /**Logic functions */
  getCurrentTimeInDecimal() {
    const now = new Date();
    return now.getHours() + now.getMinutes() / 60;
  }

  getStatusStyle(task: any) {
    switch (task.orderStatus) {
      case 'PENDING':
        return 'gantt-task-pending';
      case 'CONFIRMED':
        return 'gantt-task-confirmed';
      case 'DELIVERING':
        return 'gantt-task-inProgress';
      case 'IN_PROGRESS':
      case 4:
      case 5:
        return 'gantt-task-inProgress';
      case 'PICKing_UP':
      case 4:
      case 5:
        return 'gantt-task-inProgress';
      case 'FINISHED':
        return 'gantt-task-finished';
      case 'FAILED':
      case 'CANCELLED_BY_CAFLER':
      case 'CANCELLED_BY_CLIENT':
        return 'gantt-task-cancelled';
      default:
        return 'gantt-task-confirmed';
    }
  }

  getIcon(task: any) {
    switch (task) {
      case 'TRANSFER':
        return '../../../assets/icons/gantt-icons/Type=Transfer.svg';
      case 'MECHANICAL_INSPECTION':
        return '../../../assets/icons/gantt-icons/Type=Falta.svg';
      case 'VEHICLE_WASH':
        return '../../../assets/icons/gantt-icons/Type=Lavados.svg';
      case 'REFUELING':
        return '../../../assets/icons/gantt-icons/Type=Repostaje.svg';
      case 'PRE_TECHNICAL_INSPECTION':
        return '../../../assets/icons/gantt-icons/Type=Pre_ITV.svg';
      case 'LONG_DISTANCE_TRANSFER':
        return '../../../assets/icons/gantt-icons/Type=Media_y_larga_distancia.svg';
      case 'TECHNICAL_INSPECTION':
        return '../../../assets/icons/gantt-icons/Type=ITV.svg';
      case 'VEHICLE_INSURANCE':
        return '../../../assets/icons/gantt-icons/Type=Seguros.svg';
      case 'ACCESSORY':
        return '../../../assets/icons/gantt-icons/Type=Accesorio.svg';
      case 'VALET':
        return '../../../assets/icons/gantt-icons/Type=Valet.svg';
      case 'TYRES':
        return '../../../assets/icons/gantt-icons/Type=Neumaticos.svg';
      case 'FORMALITIES':
        return '../../../assets/icons/gantt-icons/Type=Tramites.svg';
      case 'PARKING':
        return '../../../assets/icons/gantt-icons/Type=Parking.svg';
      case 'REPLACEMENT_VEHICLE':
        return '../../../assets/icons/gantt-icons/Type=Vehiculo_sustitucion.svg';
      case 'TOW_TRUCK':
        return '../../../assets/icons/gantt-icons/Type=Grua.svg';
      case 'WHATEVER_YOU_WANT':
        return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
      default:
        return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
    }
  }

  getPositionStyle(task: any): { [key: string]: string } {
    const mainContainer = document.querySelector(
      '.gantt-main-container'
    ) as HTMLDivElement;

    let startTimeDiff =
      this.convertToHourFormat(task.serviceStartDate) - this.ganttStartTime;
    let endTimeDiff =
      this.convertToHourFormat(task.serviceEndDate) -
      this.convertToHourFormat(task.serviceStartDate);

    let left =
      startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2 + 'px';
    let width = endTimeDiff * (this.rangeWidth * 2) + 'px';
    let minWidth = width;

    return {
      left,
      width,
      minWidth,
    };
  }

  getCurrentTimeStyle(): { [key: string]: string } {
    const left = this.getCurrentTimeScrollingWidth() + 'px';
    return {
      left,
    };
  }

  openModal(serviceDataModal: TemplateRef<void>, serviceData: any) {
    console.log(serviceData);
    this.serviceData = serviceData;
    this.bsModalRef = this.modalService.show(serviceDataModal);
  }

  filterData() {
    if (this.searchTerm) {
      const ganttCopy = [...this.ganttDataOriginal];

      const driverName = this.searchTerm.toLowerCase();

      this.ganttData = ganttCopy.filter((element: any) => {
        const driver = element.driver[0];
        return (
          driver.fullName && driver.fullName.toLowerCase().includes(driverName)
        );
      });
    } else {
      this.ganttData = this.ganttDataOriginal;
    }
  }

  async filterDataBySelect() {
    if (this.selectedZone) {
      const selectDate = new Date(this.selectedDate);

      const day = String(selectDate.getDate()).padStart(2, '0');
      const month = String(selectDate.getMonth() + 1).padStart(2, '0');
      const year = String(selectDate.getFullYear());

      const formattedDate = `${day}/${month}/${year}`;
      const unix = this.dateToUnix(formattedDate);
      const serviceData = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };

      try {
        const res: any = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        this.getDrivers();
        console.log(this.ganttData, 'RES');
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }
  }

  convertToHourFormat(seconds: any) {
    const convertSeconds = parseInt(seconds.seconds, 10);

    const hour = convertSeconds / 3600;

    return parseFloat(hour.toFixed(1));
  }

  dateToUnix(dateString: any) {
    return moment.utc(dateString, 'DD/MM/YYYY').startOf('day').unix();
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  async filterDataByDate() {
    this.showLoader();
    if (this.selectedDate && this.selectedZone) {
      this.selectedDate = new Date(this.selectedDate);
      const day = String(this.selectedDate.getDate()).padStart(2, '0');
      const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0');
      const year = String(this.selectedDate.getFullYear());
      const formattedDate = `${day}/${month}/${year}`;
      const unix = this.dateToUnix(formattedDate);

      const serviceData = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };
      try {
        const res: any = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        this.ganttDataOriginal = [];
        await this.getDrivers();
        console.log(this.ganttData, 'RES');
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }
    this.searchTerm = '';
    this.hideLoader();
  }

  unixToDate(unixTimestamp: any) {
    return moment.unix(unixTimestamp).utc().format('DD/MM/YYYY');
  }
  async getDrivers() {
    await this.ganttData.forEach((element: any) => {
      this.servicesSvc.getDriver(element.key).subscribe((res: any) => {
        console.log(res, 'drivers');

        const driverError = [
          { fullName: 'Conductor no asignado', operatorType: 'Sin tipo' },
        ];
        element.driver =
          res.drivers && res.drivers.length > 0 ? res.drivers : driverError;
      });

      this.ganttDataOriginal = [...this.ganttData];
    });
  }

  getVertical(verticalType: string) {
    const selectedVertical = this.verticals.find(
      (vertical) => vertical.type === verticalType
    );

    return selectedVertical.name;
  }

  formatTime(date: any) {
    const seconds = new Date(parseInt(date) * 1000);
    const hours = String(seconds.getUTCHours()).padStart(2, '0');
    const minutes = String(seconds.getUTCMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }
}

import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { TokenService } from './services/token/token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: MsalService,
    private tokenService: TokenService
  ) {}

  actualAccount!: AccountInfo;

  // [ER - 24/03/2025 - Commented if we need in a future]
  // acquireToken(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //           this.actualAccount = this.authService.instance.getAllAccounts()[0];
// =======
  //           this.authService.instance.acquireTokenSilent(request).then(response => {
  //               console.log("Token renovado", response);
  //
  //               localStorage.setItem("accessToken", response.accessToken);
  //               localStorage.setItem("idToken",response.idToken);
  //               localStorage.setItem("tokenTimestamp", Date.now().toString());
  //               resolve(response.accessToken);
  //           }).catch(error => {
  //               console.error("Error al renovar el token:", error);
  //               this.authService.instance.acquireTokenPopup(request).then(response => {
  //                   console.log("Token renovado mediante popup",response);
  //                   localStorage.setItem("accessToken", response.accessToken);
  //                   localStorage.setItem("idToken",response.idToken);
  //                   localStorage.setItem("tokenTimestamp", Date.now().toString());
  //                   resolve(response.accessToken);
  //               }).catch(error => {
  //                   console.error("Error al renovar el token mediante popup:", error);
  //                   reject(error);
  //               });
  //           });
  //       } else {
  //           reject("No accounts found");
  //       }
  //   });
  // }

  // [ER - 24/03/2025 - Commented if we need in a future]
  acquireToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const accounts = this.authService.instance.getAllAccounts();
      if (accounts.length) {
        this.actualAccount = accounts[0];
        const request = {
          scopes: ['api://cafler-logistics-api/Test.TestRead'],
          account: this.actualAccount,
        };

        this.authService.instance
          .acquireTokenSilent(request)
          .then((response: AuthenticationResult) => {
            // Almacena los tokens y el timestamp
            this.tokenService.setAccessToken(response.accessToken);
            this.tokenService.setIdToken(response.idToken);
            this.tokenService.setTokenTimestamp(Date.now());
            resolve(response.accessToken);
          })
          .catch((error) => {
            console.error('Error al renovar el token:', error);
            this.authService.instance
              .acquireTokenPopup(request)
              .then((response: AuthenticationResult) => {
                this.tokenService.setAccessToken(response.accessToken);
                this.tokenService.setIdToken(response.idToken);
                this.tokenService.setTokenTimestamp(Date.now());
                resolve(response.accessToken);
              })
              .catch((error) => {
                console.error(
                  'Error al renovar el token mediante popup:',
                  error
                );
                reject(error);
              });
          });
      } else {
        reject('No accounts found');
      }
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Recupera el token desde el servicio (ya limpio de comillas)
    const token = this.tokenService.getAccessToken();
    const tokenTimestamp = this.tokenService.getTokenTimestamp();
    const tenMinutes = 10 * 60 * 1000;

    if (token && tokenTimestamp && Date.now() - tokenTimestamp < tenMinutes) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });
      return next.handle(cloned);
    } else {
      return from(this.acquireToken()).pipe(
        switchMap((newToken) => {
          const cleanedToken = newToken.replace(/^"|"$/g, ''); // limpieza extra si fuera necesario
          const cloned = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${cleanedToken}`),
          });
          return next.handle(cloned);
        }),
        catchError((error) => {
          console.error('Error en el interceptor:', error);
          return next.handle(req);
        })
      );
    }
  }

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   const token = localStorage.getItem('accessToken');
  //   const tokenTimestamp = localStorage.getItem('tokenTimestamp');
  //   const tenMinutes = 10 * 60 * 1000;
  //
  //   if (
  //     token &&
  //     tokenTimestamp &&
  //     Date.now() - parseInt(tokenTimestamp) < tenMinutes
  //   ) {
  //     console.log('Dentro de los 10 minutos');
  //     const cloned = req.clone({
  //       headers: req.headers.set('Authorization', `Bearer ${token}`),
  //     });
  //     return next.handle(cloned);
  //   } else {
  //     return from(this.acquireToken()).pipe(
  //       switchMap((newToken) => {
  //         const cloned = req.clone({
  //           headers: req.headers.set('Authorization', `Bearer ${newToken}`),
  //         });
  //         return next.handle(cloned);
  //       }),
  //       catchError((error) => {
  //         console.error('Error en el interceptor:', error);
  //         return next.handle(req);
  //       })
  //     );
  //   }
  // }
}

import * as moment from 'moment';

export function formatDate(date: string): string {
  if (!date) {
    return '';
  }
  const cleanDate = date.split(' ')[0];
  return moment(cleanDate).format('DD/MM/YYYY');
}

export function formatHour(time: string): string {
  const [hour, minute] = time.split(':');
  return `${hour}:${minute}`;
}

// Método que convierte los segundos (como string) a una hora (formateada en HH:mm)
// *Nota:* El frontend simplemente convierte los segundos a la hora sin hacer ajustes adicionales.
export function formatTime(secondsStr: string): string {
  const seconds: number = parseInt(secondsStr, 10);
  return new Date(seconds * 1000).toISOString().slice(11, 16);
}

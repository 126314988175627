import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class PoolManagerService {
  url = environment.url;
  exampleData: any = {
    services: [
      {
        toppingNames: [],
        providerNames: [],
        orderHash: 'B-788F3534',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'Nerea',
        licensePlate: '2341KPD',
        serviceStartDate: {
          completeDate: '2025-03-04T16:15:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '16:15:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T16:30:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '16:30:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'VEHICLE_STORAGE',
        isExternalAndInternalContact: false,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Vehicle Storage',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          'Carrer de la Indústria, 1, Gràcia, 08037 Barcelona, España',
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: 'aaef076d-f2a8-4e42-accd-ca28eae8cd92',
        _clientId: 'clientId',
        externalContactPhoneNumber: '670595109',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: ['Petrol Car MOT'],
        providerNames: [],
        orderHash: 'B-6ACF3E8E',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'ana rosa',
        servicePickupAddress:
          'Carrer de la Indústria, 1, Carrer de la Indústria, 1, Gràcia, 08037 Barcelona, España',
        licensePlate: 'asdasd23',
        serviceStartDate: {
          completeDate: '2025-03-04T14:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '14:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T14:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '14:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'PRE_TECHNICAL_INSPECTION',
        isExternalAndInternalContact: false,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Pre-MOT',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          'Carrer de Balcells, 2, Carrer de Balcells, 2, Gràcia, 08024 Barcelona, España',
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: '0fca031c-8585-4a43-927c-0b7bdc7b16ef',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34666666666',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: ['Petrol Car MOT'],
        providerNames: [],
        orderHash: 'B-8EE8A4BD',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'ana rosa',
        servicePickupAddress:
          'Carrer de la Indústria, 2, Carrer de la Indústria, 2, Gràcia, 08037 Barcelona, España',
        licensePlate: '6666jjj',
        serviceStartDate: {
          completeDate: '2025-03-04T14:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '14:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T14:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '14:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'PRE_TECHNICAL_INSPECTION',
        isExternalAndInternalContact: false,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Pre-MOT',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          "Carrer de Balmes, 2, Carrer de Balmes, 2, L'Eixample, 08007 Barcelona, España",
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: 'aee88d34-350b-4501-a816-030b37c3cb99',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34666666666',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: [],
        providerNames: [],
        orderHash: 'B-9E0DACEE',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'Nerea Guerrero Rodriguez',
        servicePickupAddress:
          'Carrer de Guipúscoa, 177, Carrer de Guipúscoa, 177, 08020 Sant Adrià de Besòs, Barcelona, España',
        licensePlate: '0100TST',
        serviceStartDate: {
          completeDate: '2025-03-04T08:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '08:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T12:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '12:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'TRANSFER',
        isExternalAndInternalContact: true,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Transfer',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          "Carrer de la Ciutat d'Asuncion, 26, Carrer de la Ciutat d'Asuncion, 26, Sant Andreu, 08030 Barcelona, España",
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: '0fca031c-8585-4a43-927c-0b7bdc7b16ef',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34777777777',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: [],
        providerNames: [],
        orderHash: 'B-1B999497',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'Nerea Guerrero Rodriguez',
        servicePickupAddress:
          'Carrer de Guipúscoa, 177, Carrer de Guipúscoa, 177, 08020 Sant Adrià de Besòs, Barcelona, España',
        licensePlate: '0101TST',
        serviceStartDate: {
          completeDate: '2025-03-04T08:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '08:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T13:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '13:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'TRANSFER',
        isExternalAndInternalContact: true,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Transfer',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          "Carrer de la Ciutat d'Asuncion, 26, Carrer de la Ciutat d'Asuncion, 26, Sant Andreu, 08030 Barcelona, España",
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: '0fca031c-8585-4a43-927c-0b7bdc7b16ef',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34777777777',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: ['Petrol Car MOT'],
        providerNames: [],
        orderHash: 'B-7E6F04DB',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'Nerea Guerrero Rodriguez',
        servicePickupAddress:
          'Carrer de Mercè Rodoreda, 16, Carrer de Mercè Rodoreda, 16, 08172 Sant Cugat del Vallès, Barcelona, España',
        licensePlate: '0118TST',
        serviceStartDate: {
          completeDate: '2025-03-04T08:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '08:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T15:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '15:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'PRE_TECHNICAL_INSPECTION',
        isExternalAndInternalContact: true,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Pre-MOT',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          'Carrer de Mercè Rodoreda, 16, Carrer de Mercè Rodoreda, 16, 08172 Sant Cugat del Vallès, Barcelona, España',
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: '0fca031c-8585-4a43-927c-0b7bdc7b16ef',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34777777777',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: [],
        providerNames: [],
        orderHash: 'B-A0FBAF2C',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'Nerea Guerrero Rodriguez',
        servicePickupAddress:
          'Carrer de Guipúscoa, 177, Carrer de Guipúscoa, 177, 08020 Sant Adrià de Besòs, Barcelona, España',
        licensePlate: '0100TST',
        serviceStartDate: {
          completeDate: '2025-03-04T08:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '08:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T12:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '12:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'TRANSFER',
        isExternalAndInternalContact: true,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Transfer',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          "Carrer de la Ciutat d'Asuncion, 26, Carrer de la Ciutat d'Asuncion, 26, Sant Andreu, 08030 Barcelona, España",
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: '0fca031c-8585-4a43-927c-0b7bdc7b16ef',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34777777777',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
      {
        toppingNames: [],
        providerNames: [],
        orderHash: 'B-6078446F',
        zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
        clientName: 'Nerea Guerrero Rodriguez 3',
        servicePickupAddress:
          'Carrer de Guipúscoa, 177, Carrer de Guipúscoa, 177, 08020 Sant Adrià de Besòs, Barcelona, España',
        licensePlate: '0100TST',
        serviceStartDate: {
          completeDate: '2025-03-04T08:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '08:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        serviceEndDate: {
          completeDate: '2025-03-04T12:00:00+01 Europe/Madrid',
          _completeDate: 'completeDate',
          date: 'Tuesday, 04 March 2025',
          _date: 'date',
          time: '12:00:00',
          _time: 'time',
          offset: '+01',
          _offset: 'offset',
          timeZone: 'Europe/Madrid',
          _timeZone: 'timeZone',
        },
        isPriority: false,
        comment: '',
        orderStatus: 'PENDING',
        verticalType: 'TRANSFER',
        isExternalAndInternalContact: true,
        whatsapp_waiting_answer: false,
        whatsapp_error_sending: false,
        whatsapp_not_answered_in_time: false,
        whatsapp_answered: false,
        sms_sent: false,
        sms_error_sending: false,
        isMobile: false,
        eventlog_incidence: false,
        showCheckForSendingConfirmationMessage: false,
        mainProductName: 'Transfer',
        _mainProductName: 'mainProductName',
        serviceDeliveryAddress:
          "Carrer de la Ciutat d'Asuncion, 26, Carrer de la Ciutat d'Asuncion, 26, Sant Andreu, 08030 Barcelona, España",
        _serviceDeliveryAddress: 'serviceDeliveryAddress',
        clientId: '0fca031c-8585-4a43-927c-0b7bdc7b16ef',
        _clientId: 'clientId',
        externalContactPhoneNumber: '+34777777777',
        _externalContactPhoneNumber: 'externalContactPhoneNumber',
      },
    ],
  };
  constructor(private http: HttpClient) {}

  getZones(): any {
    return this.http.get(`${this.url}/zones`);
  }

  getPools(poolData: any) {
    // return this.http.post(`${this.url}/services`, serviceData);
    return this.exampleData;
  }
}

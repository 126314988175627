export enum LocalStorageKeys {
    LANGUAGE = 'language',
    TOKEN = 'token',
    ACCESSTOKEN = 'accessToken',
    IDTOKEN = 'idToken',
    OPENMODAL = 'open_modal',
    TOKENTIMESTAMP = 'tokenTimestamp',
    SELECTEDDATE = 'selectedDate',
    SELECTEDZONE = 'selectedZone'
}
  
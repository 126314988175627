<div class="table-body mt-5">
    
    <div class="table-body-head mb-3"> 
     <div>
         <label class="table-title">Bloqueo de la plataforma</label>
     </div>
     <tui-hosted-dropdown
     tuiDropdownLimitWidth="auto"
     [content]="download_options"
     [(open)]="open"
 >
 <button  tuiHostedDropdownHost class="export-button">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (.csv, xls..)
 </button>
 </tui-hosted-dropdown>
    </div> 

    <div class="table-body-options mb-3">



  

     <tui-input-date
   
     tuiTextfieldSize="m"
     [(ngModel)]="selectedDate"
     (ngModelChange)="filterDataByDate()"
     style="margin-top: -8px;"

    
   > Selecciona una fecha</tui-input-date>
    

       <select class="form-select table-option-select" aria-label="status" [(ngModel)]="selectedZone" (ngModelChange)="filterDataBySelect()">
        <option  selected value="#">Zona</option>
        <option *ngFor="let zone of zones" [value]="zone">{{ zone }}</option>
      </select>

      <select class="form-select table-option-select" aria-label="status" [(ngModel)]="selectedProduct" (ngModelChange)="filterDataBySelect()">
        <option  selected value="#">Producto</option>
        <option *ngFor="let product of products" [value]="product">{{ product }}</option>
      </select>
    

      <button (click)="clearDate()"  class="export-button-menu mb-2 mt-1">
        
        Limpiar fecha
    </button>

    </div>
    <div class="overflow-auto">
        <table
        tuiTable
        size="l"
        style="width: 100%;"
        [columns]="columns"
    >
        <thead>
            <tr tuiThGroup>
                <th [sticky]="true" *tuiHead="'block_id'"  tuiTh>Bloqueo ID</th>
                <th  *tuiHead="'date'"
                tuiTh
                [sticky]="true">
                Fecha
                </th>
                <th [tuiCell]="'l'" [sticky]="true" *tuiHead="'time'"  tuiTh>Hora</th>
                <th [sticky]="true" *tuiHead="'zone'" tuiTh>Zona</th>
                <th [sticky]="true" *tuiHead="'product'"  tuiTh>Producto</th>
                <th [sticky]="true" *tuiHead="'comment'"  tuiTh>Comentarios</th>
                <th [sticky]="true" *tuiHead="'status'"  tuiTh>Estado</th>
                <th [sticky]="true" *tuiHead="'actions'"  tuiTh></th>

               
                

               
                
            </tr>
        </thead>
        <tbody tuiTbody>
            <tr tuiTr *ngFor="let item of paginatedData">
                <td  *tuiCell="'block_id'" tuiTd>
                    <span tuiTitle>
                        {{item.blockId}}
                      </span>
                </td>
                <td    *tuiCell="'date'" tuiTd>
                    <div [tuiCell]="size">
                        <span tuiTitle>
                       {{item.date}}   
                        </span>
                    </div>
                </td>
   
                <td  *tuiCell="'time'" tuiTd>
                    <div [tuiCell]="size">
                        <span tuiTitle>
                       {{item.time}}   
                        </span>
                    </div>
                </td>
    
                <td  *tuiCell="'zone'" tuiTd>
                    <span tuiTitle>
                        {{item.zone}}
                      </span>
                </td>
   
    
                <td  *tuiCell="'product'" tuiTd>
    
                   <span tuiTitle>
                       {{item.product}}
                    </span>
   
    
                </td>
          
                <td  *tuiCell="'status'" tuiTd>
                    <div *ngIf="item.status == 'active'; else pending">
                        <tui-badge
                        status="success"
                        value="Activo"
                        class="tui-space_right-2 tui-space_bottom-2"
                    ></tui-badge>
                      </div>
                      
                      <ng-template #pending>
                        <tui-badge
                        status="error"
                        value="Inactivo"
                        class="tui-space_right-2 tui-space_bottom-2"
                    ></tui-badge>
                      </ng-template>
               </td>

 

            <td  *tuiCell="'comment'" tuiTd>
                <span tuiTitle>
                    {{item.comment}}
                  </span>
            </td>
            <td  *tuiCell="'actions'" tuiTd>
                <span tuiStatus>
                    <button
                        appearance="link"
                        icon="tuiIconEdit2"
                        size="xs"
                        tuiAppearance="link"
                        tuiIconButton
                      
                    >
                        Edit
                    </button>
                    <button
                        appearance="link"
                        icon="tuiIconMoreHorizontal"
                        size="xs"
                        tuiAppearance="link"
                        tuiIconButton
                    >
                        More
                    </button>
                </span>
            </td>

            </tr>
        </tbody>
    </table>
    </div>

    
 
 <div class="table-pagination  mt-5">
 
  
 <tui-pagination
 
     [index]="index"
     [length]="length"
     (indexChange)="goToPage($event)"
 ></tui-pagination>
 </div>
 
 </div>
 <ng-template
 #download_options
 let-activeZone
>
<div class="download-menu">
 <label class="mb-2 export-title ">Exportar listado</label>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.xsl)</label>
 </div>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.csv)</label>
 </div>
 <button  class="export-button-menu mb-2 mt-1">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (2)
 </button>
</div>


</ng-template>

import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-personal-modal',
  templateUrl: './add-personal-modal.component.html',
  styleUrls: ['./add-personal-modal.component.css', ]
})

export class AddPersonalModalComponent {
    @Input() driver_info: any;
    constructor(
        private offcanvasService: NgbOffcanvas
    ) {}

    closeCanvas(){
        this.offcanvasService.dismiss();
    }
}

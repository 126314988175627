export const ROUTES = {
  LOGIN: 'login',
  OPERATIONAL_STAFF: 'operational-staff',
  SERVICE_MANAGER: 'service-manager',
  POOL_MANAGER: 'pool-manager',
  HOME: 'home',
  ASSIGNER: 'assigner',
  PROVIDERS: 'providers',
  COMMERCIAL: 'comercial',
  PLATFORM_BLOCKER: 'platform-blocker',
  DETAIL_SERVICE: 'detail-service',
  TEST_BUTTONS: 'test-buttons',
  PRICES: 'prices',
  CLIENTS: 'clients',
};
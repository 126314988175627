import {  Component,  OnInit, ViewChild, TemplateRef } from '@angular/core';

import { TuiHostedDropdownComponent } from '@taiga-ui/core';
import {  MsalService,   } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @ViewChild(TuiHostedDropdownComponent)
    component?: TuiHostedDropdownComponent;
    open = false;
    modalRef?: BsModalRef;
    language:any;
    onClick(): void {
        this.open = false;
        this.component?.nativeFocusableElement?.focus();
    }
    constructor(
        private translate: TranslateService,
        private authService: MsalService,
        private router: Router,
        private modalService: BsModalService
    ) {
        let lang = localStorage.getItem('language');

        if (!lang) {
          lang = "es";
        }
        this.language = lang;
    }

    protected onClick2(): void {
        this.open = !this.open;
    }

      ngOnInit(): void {
      }
      logout(popup?: boolean) {
        if (popup) {
          this.authService.logoutPopup({
            mainWindowRedirectUri: "/"
          });
        } else {
          this.authService.logoutRedirect();
        }
    }

    openModalLogout(templateModalLogout: TemplateRef<void>) {
        this.modalRef = this.modalService.show(templateModalLogout, Object.assign({
            ignoreBackdropClick: true
        }, { class: 'gray modal-lg mt_20p' }));
    }


    redirect(route: string) {
/*       const id = localStorage.getItem('project');
      if (id) {
        this.router.navigate([`home/${route}/${id}`]);
      } */

        /* this.router.navigate([`${route}`]); */
    }

    selectLanguage(language:any){
        this.language = language;
      console.log(this.language);
      localStorage.setItem('language',this.language);
      this.translate.use(this.language);
      this.onClick2();
    }
}

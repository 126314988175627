import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, from, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonalServiceService {
    url = environment.url;
    constructor(
        private http: HttpClient
    ) {
        
    }

    private channels: { [key: string]: Subject<any>; } = {};
    publish(topic: string, data: any = null): void {
        const subject = this.channels[topic];
        if (!subject) {
            // Or you can create a new subject for future subscribers
            return;
        }

        subject.next(data);
    }

    subscribe(topic: string, observer: (_: any) => void): Subscription {
        if (!this.channels[topic]) {
            this.channels[topic] = new Subject<any>();
        }

        return this.channels[topic].subscribe(observer);
    }

    addpersonal(data:any){
        return this.http.post(`${this.url}/drivers/add-driver`, data);
    }

    updatePersonal(data:any){
        return this.http.post(`${this.url}/drivers/update-driver`, data);
    }

    

}

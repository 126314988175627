<div class="table-body mt-5">
    
    <div class="table-body-head mb-3"> 
     <div>
         <label class="table-title">Listado de proveedores</label>
     </div>
     <tui-hosted-dropdown
     tuiDropdownLimitWidth="auto"
     [content]="download_options"
     [(open)]="open"
 >
 <label class="table-title-filter">Filter ({{filterCount}})</label>
 <button  tuiHostedDropdownHost class="export-button">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (.csv, xls..)
 </button>
 </tui-hosted-dropdown>
    </div> 
    <div class="table-body-options mb-3">
     <div class="search-bar">
         <img src="../../../assets/icons/search- icon.svg" alt="Search Icon" class="search-icon" />
         <input #searchInput [(ngModel)]="searchTerm" type="text" placeholder="Buscar por nombre de negocio..." class="search-bar-input custom_search_table_input" />
     </div>
     <button (click)="filterData(searchTerm)" appearance="primary-button" size="s" tuiButton>
        <tui-svg src="tuiIconSearch"></tui-svg>
        Buscar
      </button>
       <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedBussnies" (change)="filterDataBySelect()">
         <option  selected value="#">Nombre de negocio</option>
         <!-- <option *ngFor="let country of countrys" [value]="country">{{ country }}</option> -->
       </select>
       <select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedHour" (change)="filterDataBySelect()" >
         <option  selected value="#">Horario</option>
         <!-- <option *ngFor="let country of countrys" [value]="country">{{ country }}</option> -->
       </select>
     <select class="form-select table-option-select" aria-label="zone" [(ngModel)]="selectedZone" (change)="filterDataBySelect()" >
         <option  selected value="#">Zona</option>
         <option *ngFor="let zone of zones" [value]="zone">{{ zone }}</option>
       </select>
     

     
    </div>
    <div class="overflow-auto">
     <table
     tuiTable
     size="l"
     style="width: 100%;"
     [columns]="columns"
     (directionChange)="onSort($event)"
 >
     <thead>
         <tr tuiThGroup>
 
             <th class="th-style" *tuiHead="'bussnies_name'"
             tuiTh
             (click)="setSortColumn('bussnies_name')" 
             [sticky]="true">
             Nombre de negocio
             </th>
             <th [sticky]="true" *tuiHead="'provider_id'"  (click)="setSortColumn('provider_id')"  tuiTh>Id del Proveedor</th>
           
             <th [sticky]="true" *tuiHead="'zone'"  tuiTh  (click)="setSortColumn('zone')">Zona</th>
             <th [sticky]="true" *tuiHead="'schedule'"  tuiTh  (click)="setSortColumn('schedule')">Horario</th>
             <th [sticky]="true" *tuiHead="'pause'"  tuiTh  (click)="setSortColumn('pause')">Pausa</th>
             <th [sticky]="true" *tuiHead="'status'"  tuiTh  (click)="setSortColumn('status')">Estado</th>
             <th [sticky]="true" *tuiHead="'email'"  tuiTh  (click)="setSortColumn('email')">Correo electrónico</th>
             <th [sticky]="true" *tuiHead="'phone'"  tuiTh  (click)="setSortColumn('phone')">Teléfono</th>
             <th  *tuiHead="'actions'" tuiTh  >
             	Acciones
             </th>
         </tr>
     </thead>
     <tbody tuiTbody>
        <tr tuiTr *ngFor="let item of paginatedData">
            <td    *tuiCell="'bussnies_name'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                   		{{ item.bussnies_name }}   
                    </span>
                </div>
            </td>
            <td  *tuiCell="'provider_id'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ item.provider_id }}
                    </span>
                </div>
            </td>

            <td  *tuiCell="'zone'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                       {{ item.zone }}
                    </span>
                </div>
            </td>
            <td  *tuiCell="'schedule'" tuiTd>
                <span tuiTitle>
                    {{ item.schedule_start }} - {{ item.schedule_end }}
                </span>
            </td>
            <td  *tuiCell="'pause'" tuiTd>
                <span tuiTitle>
                    {{ item.pause_start }} - {{ item.pause_end }}
                </span>
            </td>
            <td  *tuiCell="'status'" tuiTd>
                <span tuiTitle>
                    <!-- {{ item.status }} -->
                    <tui-badge status="success" value="Activo" class="tui-space_right-2 tui-space_bottom-2 ml-5" *ngIf="item.status == 'activo'"></tui-badge>
                    <tui-badge status="error" value="Inactivo" class="tui-space_right-2 tui-space_bottom-2 ml-5" *ngIf="item.status == 'inactivo'"></tui-badge>
                </span>
            </td>
            <td  *tuiCell="'email'" tuiTd>
                <span tuiTitle>
                	<a href="mailto:{{ item.email }}" class="mail_table_link">{{ item.email }}</a>
                </span>
            </td>
            <td  *tuiCell="'phone'" tuiTd>
                <span tuiTitle>
                    {{ item.phone }}
                </span>
            </td>
            <td  *tuiCell="'actions'" tuiTd>
                <span tuiStatus>
                    <button
                        appearance="link"
                        icon="tuiIconEdit2"
                        size="xs"
                        tuiAppearance="link"
                        tuiIconButton
                        (click)="openModal(item)"
                    >
                        Edit
                    </button>
                </span>
            </td>
         </tr>
     </tbody>
 </table>
</div>  
 
 <div class="table-pagination  mt-5">
 
  
 <tui-pagination
 
     [index]="index"
     [length]="length"
     (indexChange)="goToPage($event)"
 ></tui-pagination>
 </div>
 
 </div>
 <ng-template
 #download_options
 let-activeZone
>
<div class="download-menu">
 <label class="mb-2 export-title ">Exportar listado</label>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.xsl)</label>
 </div>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.csv)</label>
 </div>
 <button  class="export-button-menu mb-2 mt-1">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (2)
 </button>
</div>


</ng-template>

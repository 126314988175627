<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'modals.add_assignament.title' | translate }}</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
   <div class="modal-body">
        <div id="modal_add_assignament"></div>
        <form [formGroup]="assignamentForm" (ngSubmit)="saveAssignament()" class="d-flex flex-column gap-4">
            <div class="row">
                <div class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_assignament.zone_input' | translate }}*</label>
                        <!-- <tui-select
                            tuiTextfieldSize="s"
                            formControlName="zoneId"
                            class="input_form"
                            [tuiTextfieldLabelOutside]="true"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_zo"
                        >
                            Selecciona zona operativa
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="operatives"
                            ></tui-data-list-wrapper>
                        </tui-select> -->
                        <ng-select
                            formControlName="zoneId"
                            [items]="operatives"
                            bindLabel="zoneName"
                            bindValue="zoneId"
                            [multiple]="false"
                            class="custom_select custom_select_single input_form"
                            placeholder="{{ 'modals.add_assignament.zone_input' | translate }}"
                        >
                        </ng-select>
                    </div>
                </div>
                <br>
                <div class="form-group mb_custom">
                    <label class="label_form">{{ 'modals.add_assignament.date_input' | translate }}*</label>
                    <!-- <tui-input-date
                        formControlName="date"
                        tuiTextfieldSize="m" 
                        [tuiTextfieldLabelOutside]="true"
                        (focusedChange)="searchDiv2()"
                        [tuiTextfieldCleaner]="true"
                    >
                        Selecciona la fecha
                    </tui-input-date> -->
                    <input type="date" formControlName="targetDate" class="form-control" id="myDatePicker">
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.time_margen_input' | translate }}*</label>
                        <tui-input formControlName="generalTimeMargin" class="input_form" tuiHintContent="Tiempo extra que se asigna a cada servicio (al margen de movilidad, proveedores y toppings)" tuiHintDirection="bottom" tuiHintAppearance="onDark" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min 
                            <input
                            tuiTextfield
                            type="text"
                            (keypress)="allowOnlyNumbers($event)"
                          />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.time_margen_input_text' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.provider_time_margen_input' | translate }}*</label>
                        <tui-input formControlName="exterProviderTimeMargin" class="input_form" tuiHintContent="Tiempo extra que se añade por cada tarea en un proveedor, además del ya establecido por cada producto" tuiHintDirection="bottom" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min
                            <input
                                tuiTextfield
                                type="text"
                                (keypress)="allowOnlyNumbers($event)"
                            />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.provider_time_margen_input_text' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.topping_time_margen_input' | translate }}*</label>
                        <tui-input formControlName="extraToppingTimeMargin" class="input_form" tuiHintContent="Tiempo asignado para cada topping" tuiHintDirection="bottom" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min
                            <input
                                tuiTextfield
                                type="text"
                                (keypress)="allowOnlyNumbers($event)"
                            />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.topping_time_margen_input_text' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.walk_driver_time_margen_input' | translate }}*</label>
                        <tui-input formControlName="maxDriverWalkTime" class="input_form" tuiHintContent="Tiempo máximo a pie por conductor y viaje" tuiHintDirection="bottom" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min
                            <input
                                tuiTextfield
                                type="text"
                                (keypress)="allowOnlyNumbers($event)"
                            />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.walk_driver_time_margen_input_text' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.public_transport_time_margen_input' | translate }}*</label>
                        <tui-input formControlName="maxDriverPublicTransportTime" class="input_form" tuiHintContent="Tiempo en transporte público por viaje" tuiHintDirection="bottom" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min
                            <input
                                tuiTextfield
                                type="text"
                                (keypress)="allowOnlyNumbers($event)"
                            />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.public_transport_time_margen_input_text' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.auxiliar_range_margen_input' | translate }}*</label>
                        <tui-input formControlName="sweepCarRadiusInKilometers" class="input_form" tuiHintContent="Tiempo máximo entre cada parada del coche escoba" tuiHintDirection="bottom" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min
                            <input
                                tuiTextfield
                                type="text"
                                (keypress)="allowOnlyNumbers($event)"
                            />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.auxiliar_range_margen_input_text' | translate }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">   
                        <label class="label_form">{{ 'modals.add_assignament.auxiliar_time_margen_input' | translate }}*</label>
                        <tui-input formControlName="sweepCarMaxArrivalTime" class="input_form" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconClock">
                            00 min
                            <input
                                tuiTextfield
                                type="text"
                                (keypress)="allowOnlyNumbers($event)"
                            />
                        </tui-input>
                        <p class="sub_input_text">{{ 'modals.add_assignament.auxiliar_time_margen_input_text' | translate }}</p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            {{ 'modals.add_assignament.close_modal_text' | translate }}
        </button>
<!--         <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!assignamentForm.valid" [attr.disabled]="!assignamentForm.valid ? true : null" *ngIf="!assignamentForm.valid">
                Generar asignación
        </button> -->
        <button [disabled]="!assignamentForm.valid" tuiButton type="button" (click)="saveAssignament()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge">
            {{ 'modals.add_assignament.add_button_text' | translate }}
        </button>
    </div>
</div>
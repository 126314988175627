import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TuiHostedDropdownComponent } from '@taiga-ui/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'src/app/models/menu-item/menu-item.interface';
import { LanguageService } from 'src/app/services/language/language.service';
import { Language } from 'src/app/models/language/language.interface';
import { LanguageEnum } from 'src/app/enum/language.enum';
import { LocalStorageKeys } from 'src/app/enum/local-storage-keys.enum';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { Permission } from 'src/app/enum/permission.enum';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ROUTES } from 'src/app/constants/routes';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  @ViewChild(TuiHostedDropdownComponent) component?: TuiHostedDropdownComponent;

  open = false;
  modalRef?: BsModalRef;
  language: string;
  availableLanguages: Language[] = [];
  menuItems: MenuItem[] = [];

  private readonly BASE_MENU: MenuItem[] = [
    { label: 'menu.home', link: ROUTES.HOME },
    { label: 'menu.personal', link: ROUTES.OPERATIONAL_STAFF },
    { label: 'menu.assigner', link: ROUTES.ASSIGNER },
    { label: 'menu.service_manager', link: ROUTES.SERVICE_MANAGER },
    { label: 'menu.providers', link: ROUTES.PROVIDERS },
    // { label: 'menu.clients', link: ROUTES.CLIENTS }, [ER - 19/03/2025 - Esta pantalla de momento me han dicho de comentarla]
    { label: 'menu.commercial', link: ROUTES.COMMERCIAL },
  ];

  private readonly PERMISSION_MAP: Record<string, Permission> = {
    'menu.home': Permission.HOME,
    'menu.personal': Permission.PERSONAL,
    'menu.assigner': Permission.ASSIGNER,
    'menu.service_manager': Permission.SERVICE_MANAGER,
    'menu.providers': Permission.PROVIDERS,
    // 'menu.clients': Permission.CLIENTS, [ER - 19/03/2025 - Esta pantalla de momento me han dicho de comentarla]
    'menu.commercial': Permission.COMMERCIAL,
  };

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly modalService: BsModalService,
    private readonly languageService: LanguageService,
    private readonly localStorageService: LocalStorageService,
    private authService: AuthService,
    private permissionsService: PermissionsService
  ) {
    this.language =
      this.localStorageService.get<string>(LocalStorageKeys.LANGUAGE) ||
      LanguageEnum.DEFAULT;
  }

  ngOnInit(): void {
    this.setLanguage(this.language);
    this.availableLanguages = this.languageService.getLanguages();
    this.loadMenuPermissions();
  }

  private loadMenuPermissions(): void {
    this.permissionsService
      .arePermissionsLoaded()
      .subscribe((isPermissionsLoaded: boolean) => {
        if (isPermissionsLoaded) {
          this.permissionsService
            .getPermissions()
            .subscribe((permissions: Permission[]) => {
              this.configureMenu(permissions);
            });
        } else {
          console.warn('No se han cargado los permisos exitosamente', isPermissionsLoaded);
        }
      });
  }

  private configureMenu(permissions: Permission[]): void {
    this.menuItems = this.BASE_MENU.filter((item: MenuItem) => {
      const permission = this.PERMISSION_MAP[item.label];
      return permission && permissions.includes(permission);
    });
  }

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  toggleDropdown(): void {
    this.open = !this.open;
  }

  logout(usePopup = false): void {
    this.authService.logout(usePopup);
  }

  openModalLogout(template: TemplateRef<void>): void {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
      class: 'gray modal-lg mt_20p',
    });
  }

  redirect(route: string): void {
    this.router.navigate([route]);
  }

  selectLanguage(language: string): void {
    if (language !== this.language) {
      this.setLanguage(language);
      this.toggleDropdown();
    }
  }

  private setLanguage(language: string): void {
    this.language = language;
    this.localStorageService.set(LocalStorageKeys.LANGUAGE, language);
    this.translate.use(this.language);
  }
}

<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left">Asignar proveedor</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
   <div class="modal-body">
        <div id="modal_assign_provider_service"></div>
        <div id="modal_assign_provider_service_alert"></div>
        <form [formGroup]="assignForm" (ngSubmit)="assignProvider()" class="d-flex flex-column gap-4">
        	<p class="hid_p">
        		Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi, consequuntur impedit consequatur cumque commodi modi
        	</p>
            <div class="row">
                <div class="col" [formGroup]="filterForm">
                    <tui-input formControlName="nameFilter" class="input_form" id="" tuiTextfieldIconLeft="tuiIconSearch" [tuiTextfieldLabelOutside]="true" (input)="filterData($event)">
                        Buscar
                    </tui-input>
                </div>
            </div>
            <div class="form-group">
                <div class="div_drivers_scroll">
                    <div *ngFor="let provider of elements_providers" (click)="selectprovider(provider)" [class.active_option_provider]="assignForm.value.providerId == provider.id" class="item_pointer">
                        <div class="row row_provider">
                            <div class="col-12">
                                <p class="p_fs_15">
                                    <b>{{ provider.name }}</b>
                                </p>
                                <small>
                                    ({{ provider.address }})
                                </small>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            Descartar
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!assignForm.valid" [attr.disabled]="!assignForm.valid ? true : null" *ngIf="!assignForm.valid">
                Asignar
        </button>
        <button tuiButton type="button" (click)="assignProvider()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m"  icon="tuiIconPlusCircleLarge" appearance="primary-button" *ngIf="assignForm.valid">
            Asignar
        </button>
        <div class="loader_inside_modal_button">
            <img src="/assets/logo-loading.gif" class="img_loader_inside_modal_button" alt="">
        </div>
    </div>
    <br>
</div>
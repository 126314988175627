<table tuiTable size="l"  style="width: 100%;" [columns]="columns">
    <thead>
        <tr tuiThGroup>
            <th class="th-style" *tuiHead="'date'" tuiTh [sticky]="true">
                Fecha
            </th>
            <th [sticky]="true" *tuiHead="'hour'" tuiTh>Hora</th>
            <th [sticky]="true" *tuiHead="'user'" tuiTh>Usuario</th>
            <th [sticky]="true" *tuiHead="'event'"  tuiTh>Evento</th>
        </tr>
    </thead>
    <tbody tuiTbody>
        <tr tuiTr *ngFor="let eve of events">
            <td *tuiCell="'date'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ getDate(eve.eventDate.seconds) }}   
                    </span>
                </div>
            </td>
            <td  *tuiCell="'hour'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ getDateHour(eve.eventDate.seconds) }}
                    </span>
                </div>
            </td>
            <td  *tuiCell="'user'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ eve.executorName }}
                        <!-- {{ eve.actingUser }} -->
                    </span>
                </div>
            </td>
            <td  *tuiCell="'event'" tuiTd>
                <div [tuiCell]="size">
                    <span tuiTitle>
                        {{ eve.eventData }}
                    </span>
                </div>
            </td>
         </tr>
    </tbody>
</table>
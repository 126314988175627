import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  HostListener,
  ElementRef,
  NgZone,
} from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  NgControl,
  AbstractControl,
  ValidationErrors,
  FormArray,
  ValidatorFn,
} from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
declare var google: any;
@Component({
  selector: 'app-modal-add-provider',
  templateUrl: './modal-add-provider.component.html',
  styleUrls: ['./modal-add-provider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAddProviderComponent {
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    public hs: HistoryServicesService,
    private ngZone: NgZone
  ) {}
  @ViewChild('originAddress', { static: true }) originAddressInput!: ElementRef;
  @Input() provider_info: any;
  @Input() type_info: any;
  zones!: any[];
  payments:any = ['Tarjeta', 'Efectivo', 'Transferencia bancaria'];

  operatives = ['Madrid', 'Barcelona', 'Sevilla'];
  arrayTime = [[], [], [], [], []];

  arrayTimePause = [[], [], [], [], []];
  arrayTimePauseEnd = [[], [], [], [], []];
  addressSelected = false;
  checkboxGroups = [
    {
      parent: false,
      parentLabel: 'Revisiones',
      children: [
        { label: 'ITV', checked: false },
        { label: 'Pre-ITV', checked: false },
        { label: 'ITV Homologación', checked: false },
      ],
    },
    {
      parent: false,
      parentLabel: 'Lavado',
      children: [
        { label: 'Centros especializados', checked: false },
        { label: 'Tapicerías', checked: false },
        { label: 'Integral', checked: false },
        { label: 'Pulido de faros', checked: false },
      ],
    },
    {
      parent: false,
      parentLabel: 'Mantenimiento',
      children: [
        { label: 'Packs mantenimiento', checked: false },
        { label: 'Repostaje', checked: false },
        { label: 'Pre-ITV', checked: false },
        { label: 'Pre-ITV + ITV', checked: false },
        { label: 'Diagnóstico', checked: false },
        { label: 'Neumáticos', checked: false },
        { label: 'Puesta a punto', checked: false },
      ],
    },
    {
      parent: false,
      parentLabel: 'Aparcamos tu vehículo',
      children: [
        { label: 'Parking', checked: false },
        { label: 'Valet', checked: false },
      ],
    },
  ];
  content = '';
  filteredOperatives: any;
  daysOfWeek = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes'];
  providerForm = this.fb.group({
    id_provider: ['', Validators.required],
    operativeZone: [null, Validators.required],
    name: ['', Validators.required],
    address: ['', Validators.required],
    email: ['', Validators.required],
    cif: ['', Validators.required],
    payment: [null, Validators.required],
    bank_info: '',
    PhoneNumber: ['', Validators.required],
    startTime: [this.findTimeObject(0, 0)],
    endTime: [this.findTimeObject(23, 0)],
    pauseTime: [this.findTimeObject(22, 0)],
    pauseTimeEnd: [this.findTimeObject(22, 0)],
    addressGeopointLt: [null, Validators.required],
    addressGeopointLn: [null, Validators.required],
    sameSchedule: [true],
    selectedDays: this.fb.array(
      this.daysOfWeek.map((day) => this.createDayFormGroup())
    ),
    checkboxes: this.fb.group(
      {
        revisiones: this.fb.group({
          parent: [true],
          children: this.fb.group({
            itv: [true],
            preItv: [false],
            itvHomologacion: [false],
          }),
        }),
        lavado: this.fb.group({
          parent: [false],
          children: this.fb.group({
            centrosEspecializados: [false],
            tapicerias: [false],
            integral: [false],
            pulidoFaros: [false],
          }),
        }),
        mantenimiento: this.fb.group({
          parent: [false],
          children: this.fb.group({
            packsMantenimiento: [false],
            repostaje: [false],
            preItv: [false],
            preItvItv: [false],
            diagnostico: [false],
            neumaticos: [false],
            puestaPunto: [false],
          }),
        }),
        aparcamosVehiculo: this.fb.group({
          parent: [false],
          children: this.fb.group({
            parking: [false],
            valet: [false],
          }),
        }),
        cambioNombre: [false],
        grua: [false],
      },
      { validators: [this.atLeastOneCheckboxSelected, this.validGeopoint] }
    ),
  });

  openCountry = false;
  selectedCountry = 'Selecciona el país';

  openOperative = false;
  selectedOperative = 'Selecciona previamente País';

  openPayment = false;
  selectedPayment = 'Selecciona forma de pago';

  idNewProvider: any = '';
  openElement = '';
  openId = '';

  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];
  countryIsoCode = TuiCountryIsoCode.ES;
  hours = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
  hours2: any = [];
  pausedHours: any = [];
  pausedHours2: any = [];
  ngOnInit() {
    this.hs.getZones().subscribe((zones: any) => {
      this.operatives = zones.zones.map((zone: any) => zone.zoneName);
      this.zones = zones.zones;
    });

    this.providerForm.get('operativeZone')?.valueChanges.subscribe((value) => {
      if (value) {
        this.openElement = '';
      }
    });

    this.providerForm.get('startTime')?.valueChanges.subscribe((value) => {
      if (value) {
        const val: any = value;

        this.hours2 = tuiCreateTimePeriods(val.hours + 2, 24, [0, 15, 30, 45]);

        this.providerForm.patchValue({
          endTime: this.findTimeObject(val.hours + 2, 0),
          pauseTime: this.findTimeObject(val.hours + 1, 0),
          pauseTimeEnd: this.findTimeObject(val.hours + 2, 0),
        });
      }

      const val: any = value;

      const startHour: any = this.providerForm.get('endTime')?.value || 0;

      this.pausedHours = tuiCreateTimePeriods(
        val.hours,
        startHour.hours,
        [0, 15, 30, 45]
      );
    });

    this.providerForm.get('endTime')?.valueChanges.subscribe((value) => {
      if (value && this.providerForm.get('startTime')?.value) {
        const val: any = value;

        const startHour: any = this.providerForm.get('startTime')?.value || 0;

        this.pausedHours = tuiCreateTimePeriods(
          startHour.hours,
          val.hours,
          [0, 15, 30, 45]
        );
      }
    });

    this.providerForm.get('pauseTime')?.valueChanges.subscribe((value) => {
      if (value && this.providerForm.get('endTime')?.value) {
        const val: any = value;

        const startHour: any = this.providerForm.get('endTime')?.value || 0;

        this.providerForm.patchValue({
          pauseTimeEnd: this.findTimeObject(val.hours + 1, 0),
        });

        this.pausedHours2 = tuiCreateTimePeriods(
          val.hours,
          startHour.hours,

          [0, 15, 30, 45]
        );
      }
    });

    if (this.type_info == 'ed') {
      this.idNewProvider = this.provider_info.provider_id;
      console.log(this.provider_info.phone.substr(0, 5), 'CODIGO');
      switch (this.provider_info.phone.substr(0, 5)) {
        case '(+34)':
          this.countryIsoCode = TuiCountryIsoCode.ES;
          break;

        case '(+33)':
          this.countryIsoCode = TuiCountryIsoCode.FR;
          break;
        case '(+44)':
          this.countryIsoCode = TuiCountryIsoCode.GB;
          break;

        default:
          this.countryIsoCode = TuiCountryIsoCode.ES;
          break;
      }
      const geolat:any = 100000
      this.addressSelected = true;
     
      this.providerForm.patchValue(
        {
          id_provider: this.idNewProvider,
          operativeZone: this.provider_info.zone,
          name: this.provider_info.bussnies_name,
          address:
            'Carrer dels Caponata, 8, Sarrià-Sant Gervasi, 08034 Barcelona, España',
          email: this.provider_info.email,
          PhoneNumber: this.provider_info.phone.substr(5),
          cif: 'B-12345678',
          startTime: this.findTimeObject(10, 0),
          endTime: this.findTimeObject(18, 0),
          pauseTime: this.findTimeObject(13, 0),
          addressGeopointLn: geolat,
          addressGeopointLt: geolat,
          payment: this.payments[2]
        },
        { emitEvent: false }
      );
    } else {
      this.idNewProvider = '#' + Date.now() + this.getRandomId();
      this.providerForm.patchValue(
        { id_provider: this.idNewProvider },
        { emitEvent: false }
      );
    }


    this.providerForm.get('startHour')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('endHour')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('pauseHour')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('pauseHourEnd')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('startTime')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('endTime')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('pauseTime')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });

    this.providerForm.get('pauseTimeEnd')?.valueChanges.subscribe((value) => {
      if (value) {
          this.resetFormSelects();
      }
    });
  }

  ngAfterViewInit() {
    if (!google || !google.maps || !google.maps.places) {
      console.error('Google Maps API no está cargada correctamente.');
      return;
    }

    this.selectedDaysFormArray.controls.forEach((control, index) => {
      control.get('startHour')?.valueChanges.subscribe((startHour) => {
        const arr: any = tuiCreateTimePeriods(
          startHour.hours + 1,
          24,
          [0, 15, 30, 45]
        );
        console.log(startHour, 'S');
        this.arrayTime[index] = arr;
        this.selectedDaysFormArray
          .at(index)
          .patchValue({
            endHour: this.findTimeObject(startHour.hours + 2, 0),
            pauseHour: this.findTimeObject(startHour.hours + 1, 0),
            pauseHourEnd: this.findTimeObject(startHour.hours + 2, 0),
          });
      });
    });

    this.selectedDaysFormArray.controls.forEach((control, index) => {
      control.get('endHour')?.valueChanges.subscribe((endHour) => {
        if (
          endHour &&
          this.selectedDaysFormArray.at(index).get('startHour')?.value
        ) {
          const startTime: any = this.selectedDaysFormArray
            .at(index)
            .get('startHour')?.value;
          const arr: any = tuiCreateTimePeriods(
            startTime.hours,
            endHour.hours,
            [0, 15, 30, 45]
          );
          this.arrayTimePause[index] = arr;
        }

       
      });
    });

    this.selectedDaysFormArray.controls.forEach((control, index) => {
      control.get('pauseHour')?.valueChanges.subscribe((pauseHour) => {
        if (
          pauseHour &&
          this.selectedDaysFormArray.at(index).get('endHour')?.value
        ) {
          const endTime: any = this.selectedDaysFormArray
            .at(index)
            .get('endHour')?.value;
          const arr: any = tuiCreateTimePeriods(
            pauseHour.hours,
            endTime.hours,
          
           [0, 15, 30, 45]
          );
          this.arrayTimePauseEnd[index] = arr;
          this.selectedDaysFormArray
          .at(index)
          .patchValue({
            pauseHourEnd: this.findTimeObject(pauseHour.hours + 1, 0),
          
          });
        }

       
      });
    });

    const input = this.originAddressInput.nativeElement;

    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['address'],
    });

    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (!place.geometry) {
          console.error('No se encontró la geometría del lugar.');
          return;
        }

        this.addressSelected = true;
        const formattedAddress = place.formatted_address;
        if (place.geometry) {
          const lat:any = place.geometry.location.lat();
          const lng:any = place.geometry.location.lng();
          this.providerForm.patchValue({
            addressGeopointLt: lat,
            addressGeopointLn: lng,
            address: formattedAddress,
          });
        }
      });
    });
  }

  toggleAllChildren(groupName: string) {
    const group = this.providerForm.get(`checkboxes.${groupName}`) as any;
    const parentValue = group.get('parent').value;
    Object.keys(group.get('children').controls).forEach((child) => {
      group.get(`children.${child}`).setValue(parentValue);
    });
  }

  onAddressInput() {
    console.log("INPUT")
    this.addressSelected = false; 
  }

  
  onAddressBlur() {
    console.log("BLUER")
    if (!this.addressSelected) {
     
      this.providerForm.patchValue({
        addressGeopointLt: null,
        addressGeopointLn: null,
      });
    }
  }

  updateParentCheck(groupName: string) {
    const group = this.providerForm.get(`checkboxes.${groupName}`) as any;
    const children = group.get('children').controls;
    const isAnyChildChecked = Object.values(children).some(
      (control: any) => control.value
    );
    group.get('parent').setValue(isAnyChildChecked, { emitEvent: false });
  }

  searchDiv(element: any) {
    if (element != this.openElement) {
    
      let div = document.getElementsByTagName('tui-dropdown');
      if (div[0] != undefined) {
        let content_div = document.getElementById('modal_add_provider');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
      this.openElement = element;
    } else {
      console.log('dd2');
      this.openElement = '';
    }
  }

  searchDiv2() {
    setTimeout(() => {
      let div = document.getElementsByClassName(
        'ng-trigger-tuiDropdownAnimation'
      );
      if (div.length > 0) {
        let content_div = document.getElementById('modal_add_provider');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
    }, 200);
  }

  saveProvider() {
    // this.assignerService.saveAssignament(this.providerForm).subscribe((result:any)=> {
    // });
    // this.assignerService.publish('updateElementsAssign');
  this.showDepositAlert();
    this.offcanvasService.dismiss(); 

 /*    const selectedDaysArray = this.providerForm.get('selectedDays')?.value;
    if (selectedDaysArray) {
      const selectedDays = selectedDaysArray

        .map((dayGroup: any, index) => ({
          day: this.daysOfWeek[index],
          startHour: dayGroup.startHour,
          endHour: dayGroup.endHour,
          pauseHour: dayGroup.pauseHour,
          pauseHourEnd: dayGroup.pauseHour,
          checked: dayGroup.checked,
        }))
        .filter((dayGroup: any) => dayGroup.checked)
        .map((dayGroup: any, index) => ({
          day: dayGroup.day,
          startHour: dayGroup.startHour,
          endHour: dayGroup.endHour,
          pauseHour: dayGroup.pauseHour,
          pauseHourEnd: dayGroup.pauseHour,
        }));

      console.log('Días seleccionados:', selectedDays);
    }

    console.log(this.providerForm.value, 'VALUE FORM'); */
  }

  closeCanvas() {
    this.offcanvasService.dismiss();
  }

  showDepositAlert(): void {
    this.alerts
      .open('Se ha creado un nuevo proveedor de forma satisfactoria', {
        label: 'Se ha creado un nuevo proveedor',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  getRandomId() {
    return Math.floor(Math.random() * 100);
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    if ((target as HTMLInputElement).inputMode === 'numeric') {
      if (target.id != this.openId && this.openId != '') {
        document.getElementById(this.openId)!.blur();

        document.getElementById(target.id)!.focus();
      }

      this.openId = target.id;
    }
  }

  findTimeObject(hours: number, minutes: number): any {
    const hoursComplete = tuiCreateTimePeriods(0, 24, [0, 15, 30, 45]);
    return hoursComplete.find(
      (time) => time.hours === hours && time.minutes === minutes
    );
  }

  private clickCounts: { [key: string]: number } = {};
  onSelectClick(event: MouseEvent) {
    const selectElement = event.target as HTMLSelectElement;
    const selectId = selectElement.id;
    console.log(selectId, 44);

    if (!this.clickCounts[selectId]) {
      this.clickCounts[selectId] = 0;
    }
    this.clickCounts[selectId]++;

    if (this.clickCounts[selectId] === 1) {
      setTimeout(() => {
        const sourceElement = document.querySelector('tui-dropdown');
        const targetElement = document.getElementById('modal_add_providers');
        if (sourceElement && targetElement) {
          const clonedNode = sourceElement.cloneNode(true);
          // targetElement!.innerHTML = '';
          targetElement.appendChild(sourceElement);
        }
      }, 300);
    } else {
      if (this.clickCounts[selectId] === 2) {
        if (document.activeElement === selectElement) {
          selectElement.blur();
          console.log(`Focus quitado del select ${selectId} por segundo clic`);
        }
        this.clickCounts[selectId] = 0;
      }
    }
  }

  resetFormSelects() {
    const entries = Object.entries(this.clickCounts);
    for (const [selectId, count] of entries) {
      this.clickCounts[selectId] = 0;
    }
  }

  onSelectBlur(type: any) {
    if (!type) {
      this.resetFormSelects();
    }
  }
  atLeastOneCheckboxSelected(
    control: AbstractControl
  ): ValidationErrors | null {
    const checkboxesGroup = control.value;

    const isAnyCheckboxSelected = Object.keys(checkboxesGroup).some((key) => {
      const group = checkboxesGroup[key];
      if (typeof group === 'object' && group.children) {
        return Object.values(group.children).some((value: any) => value);
      }
      return group === true;
    });

    return isAnyCheckboxSelected ? null : { requiredCheckbox: true };
  }

  validGeopoint(control: AbstractControl): ValidationErrors | null {
    const lat = control.get('addressGeopointLt')?.value;
    const lng = control.get('addressGeopointLn')?.value;

    // Si lat o lng son 0, retorna un error
    const isValid = lat !== 0 && lng !== 0;

    return isValid ? null : { invalidGeopoint: true };
  }

  createDayFormGroup(): FormGroup {
    const dayFormGroup = this.fb.group({
      checked: [false],
      startHour: ['00:00'],
      endHour: ['00:00'],
      pauseHour: ['00:00'],
      pauseHourEnd: ['00:00'],
    });

    dayFormGroup.patchValue({
      startHour: this.findTimeObject(0, 0),
      endHour: this.findTimeObject(23, 0),
      pauseHour: this.findTimeObject(21, 0),
      pauseHourEnd: this.findTimeObject(22, 0),
    });

    return dayFormGroup;
  }

  get selectedDaysFormArray(): FormArray {
    return this.providerForm.get('selectedDays') as FormArray;
  }

  onDaySelected(index: number, isChecked: any) {
    const dayGroup = this.selectedDaysFormArray.at(index);
    dayGroup.get('checked')?.setValue(isChecked);
  }

  createTimeSlotEnd(index: number) {
  

    const day: any =
      this.providerForm.get('selectedDays')?.value[index]['startHour'];

    const array: any = tuiCreateTimePeriods(day.hours, 24, [0, 15, 30, 45]);
    this.arrayTime[index] = array;
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  validateDays(): boolean{

    if(this.providerForm.get('sameSchedule')?.value == false){
    
      const selectedDaysArray = this.providerForm.get('selectedDays')?.value;
      if(selectedDaysArray){
        const isAnyDayChecked = selectedDaysArray.some((day: any) => day.checked);

        return !isAnyDayChecked
      }
      return true
    }else{
      return false
    }
  }
  
}

export const SERVICES_TYPES = {
  DESCONOCIDO: 'UNKNOWN',
  TRANSFER: 'TRANSFER',
  TALLER: 'MECHANICAL_INSPECTION',
  LAVADO: 'VEHICLE_WASH',
  REPOSTAJE: 'REFUELING',
  PRE_ITV: 'PRE_TECHNICAL_INSPECTION',
  TRANSFER_LARGA_DISTANCIA: 'LONG_DISTANCE_TRANSFER',
  ITV: 'TECHNICAL_INSPECTION',
  SEGURO: 'VEHICLE_INSURANCE',
  CAFLER_FRESH: 'ACCESSORY',
  VALET: 'VALET',
  NEUMATICOS: 'TYRES',
  TRAMITES: 'FORMALITIES',
  PARKING: 'PARKING',
  VEHICULO_SUSTITUCION: 'REPLACEMENT_VEHICLE',
  GRUA: 'TOW_TRUCK',
  PIDE_LO_QUE_QUIERAS: 'WHATEVER_YOU_WANT',
  CAMPA: 'VEHICLE_STORAGE',
};

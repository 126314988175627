import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { TuiAlertService } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class HistoryServicesService {
  historyDataParams: any = {};
  pendingDataParams: any = {};
  url = environment.url;
  datos: any;
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private http: HttpClient
  ) {}

  private channels: { [key: string]: Subject<any> } = {};
  publish(topic: string, data: any = null): void {
    const subject = this.channels[topic];
    if (!subject) {
      // Or you can create a new subject for future subscribers
      return;
    }

    subject.next(data);
  }

  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }

    return this.channels[topic].subscribe(observer);
  }

  getZones() {
    return this.http.get(`${this.url}/zones`);
  }

  getToppings(data: any) {
    return this.http.get(
      `${this.url}/services/toppings?orderHash=${data.orderHash}`,
      {}
    );
  }

  getProducts(data: any) {
    // if(data.zoneId){
    //     return this.http.get(`${this.url}/products?zoneId=`+data.zoneId);
    // }else{
    //     return this.http.get(`${this.url}/products?zoneId=`+data);
    // }

    return this.http.post(`${this.url}/products`, data);
  }

  getProviders(data: any) {
    if (data.zoneId) {
      return this.http.get(
        `${this.url}/products/providers?zoneId=` + data.zoneId
      );
    } else {
      return this.http.get(`${this.url}/products/providers?zoneId=` + data);
    }
  }

  getServiceDetail(hash: any) {
    return this.http.get(`${this.url}/services/details?orderHash=` + hash);
  }

  getServices(data: any) {
    return this.http.post(`${this.url}/services`, data);
  }

  getClients() {
    return this.http.post(`${this.url}/clients`, {});
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.value;
  }

  editContactData(data: any) {
    return this.http.post(`${this.url}/services/update-contact`, data);
  }

  cancelService(data: any) {
    return this.http.post(`${this.url}/services/cancel`, data);
  }

  addService(data: any) {
    return this.http.post(`${this.url}/services/add`, data);
  }

  addInternalComments(data: any) {
    return this.http.post(`${this.url}/services/add-internal-comment`, data);
  }

  editPrice(data: any) {
    return this.http.post(`${this.url}/services/update-price`, data);
  }

  updateService(data: any) {
    return this.http.post(`${this.url}/services/update`, data);
  }

  updateServiceAssignedHour(data: any) {
    return this.http.post(
      `${this.url}/services/update-assigned-start-time`,
      data
    );
  }

  changeStatusService(data: any) {
    return this.http.post(`${this.url}/services/update-status`, data);
  }

  addPrice(data: any) {
    return this.http.post(`${this.url}/services/add-overdraft`, data);
  }

  addTopping(data: any) {
    return this.http.post(`${this.url}/services/add-topping`, data);
  }

  addClient(data: any) {
    return this.http.post(`${this.url}/clients/add`, data);
  }

  deleteTopping(data: any) {
    return this.http.post(`${this.url}/services/remove-topping`, data);
  }

  addProviderToProductInService(data: any) {
    return this.http.post(`${this.url}/services/assing-provider`, data);
  }

  private contentSource = new BehaviorSubject<string>('');
  currentContent = this.contentSource.asObservable();

  changeContent(content: string) {
    this.contentSource.next(content);
  }

  public traducciones: { [key: string]: string } = {
    'Basic Replacement Vehicle': 'Vehículo de sustitución',
    'Cafler Complete Atv Wash': 'Lavado Completo Cafler',
    'Cafler Complete Car Wash': 'Lavado Completo Cafler',
    'Cafler Complete Light Van Wash': 'Lavado Completo Cafler',
    'Cafler Complete Minivan Wash': 'Lavado Completo Cafler',
    'Cafler Complete Van Wash': 'Lavado Completo Cafler',
    'Cafler Exterior Atv Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Car Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Light Van Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Minivan Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Van Wash': 'Lavado Exterior Cafler',
    'Cafler Fresh ATV Topping': 'Cafler Fresh',
    'Cafler Fresh Car Topping': 'Cafler Fresh',
    'Cafler Fresh Lightvan Topping': 'Cafler Fresh',
    'Cafler Fresh Minivan Topping': 'Cafler Fresh',
    'Cafler Fresh Truck Topping': 'Cafler Fresh',
    'Cafler Fresh Van Topping': 'Cafler Fresh',
    'Cafler Interior Atv Wash': 'Lavado interior Cafler',
    'Cafler Interior Car Wash': 'Lavado interior Cafler',
    'Cafler Interior Light Van Wash': 'Lavado interior Cafler',
    'Cafler Interior Minivan Wash': 'Lavado interior Cafler',
    'Cafler Interior Van Wash': 'Lavado interior Cafler',
    'Cafler Ready': 'Puesta a punto',
    'Car Basic Maintenance': 'Mantenimiento básico Coche',
    'Car Complete Maintenance': 'Mantenimiento completo Coche',
    'Car Medium Maintenance': 'Mantenimiento medio Coche',
    'Car MOT': 'ITV Coche',
    'Checklist topping': 'Checklist',
    'Complete atv wash': 'Lavado completo Proveedor',
    'Complete car wash': 'Lavado completo Proveedor',
    'Complete light van wash': 'Lavado completo Proveedor',
    'Complete minivan wash': 'Lavado completo Proveedor',
    'Complete van wash': 'Lavado completo Proveedor',
    'Diesel Car MOT': 'ITV Coche Diesel',
    'Diesel Van MOT': 'ITV Furgoneta Diesel',
    'Electric Car MOT': 'ITV Coche Electrico',
    'Electric Motorbike +125 MOT': 'ITV Moto Eléctrica +125cc',
    'Electric Motorbike -125 MOT': 'ITV Moto Eléctrica -125cc',
    'Electric Van MOT': 'ITV Furgoneta Eléctrica',
    'Express Complete Atv Wash': 'Lavado a mano Express completo',
    'Express Complete Car Wash': 'Lavado a mano Express completo',
    'Express Complete Light Van Wash': 'Lavado a mano Express completo',
    'Express Complete Minivan Wash': 'Lavado a mano Express completo',
    'Express Complete Van Wash': 'Lavado a mano Express completo',
    'Express Exterior Atv Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Car Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Light Van Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Minivan Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Van Wash': 'Lavado a mano Express Exterior',
    'Express Interior Atv Wash': 'Lavado a mano Express Interior',
    'Express Interior Car Wash': 'Lavado a mano Express Interior',
    'Express Interior Light Van Wash': 'Lavado a mano Express Interior',
    'Express Interior Minivan Wash': 'Lavado a mano Express Interior',
    'Express Interior Van Wash': 'Lavado a mano Express Interior',
    'Exterior Atv Wash': 'Lavado exterior',
    'Exterior Car Wash': 'Lavado exterior',
    'Exterior Light Van Wash': 'Lavado exterior',
    'Exterior Minivan Wash': 'Lavado exterior',
    'Exterior Van Wash': 'Lavado exterior',
    'Gas Car MOT': 'ITV Coche Gas',
    'Gas Van MOT': 'ITV Furgoneta Gas',
    'Headlight Polishing': 'Pulido de faros',
    'Hybrid Car MOT': 'ITV',
    'Hybrid Van MOT': 'ITV',
    'Insurance Topping': 'Seguro por días',
    'Integral Washing': 'Lavado integral',
    'Interior atv wash': 'Lavado integral',
    'Interior car wash': 'Lavado integral',
    'Interior light van wash': 'Lavado integral',
    'Interior minivan wash': 'Lavado integral',
    'Interior van wash': 'Lavado integral',
    'Medium Long Distance': 'Media-Larga distancia',
    'MOT Homologation': 'ITV Homologación',
    'Motorbike +125 MOT': 'ITV Moto +125cc',
    'Motorbike -125 MOT': 'ITV Moto -125cc',
    'Motorbike Basic Maintenance': 'Mantenimiento básico',
    'Motorbike Complete Maintenance': 'Mantenimiento completo',
    'Motorbike MOT': 'ITV',
    'Motorbike wash': 'Lavado',
    'Ownership change': 'Cambio de nombre',
    Parking: 'Parking',
    'Petrol Car MOT': 'ITV',
    'Pre-MOT': 'Pre-ITV',
    'Pre-MOT + Diesel Car MOT': 'Pre-ITV + ITV Coche Diesel',
    'Pre-Mot + Diesel Van MOT': 'Pre-ITV + ITV Furgoneta Diesel',
    'Pre-MOT + Electric Car MOT': 'Pre-ITV + ITV Coche Electrico',
    'Pre-MOT + Electric Motorbike +125 MOT':
      'Pre-ITV + ITV Moto Eléctrica +125cc',
    'Pre-MOT + Electric Motorbike -125 MOT':
      'Pre-ITV + ITV Moto Eléctrica -125cc',
    'Pre-Mot + Electric Van MOT': 'Pre-ITV + ITV Furgoneta Eléctrica',
    'Pre-Mot + Gas Van MOT': 'Pre-ITV + ITV Furgoneta Gas',
    'Pre-MOT + Hybrid Car MOT': 'Pre-ITV + ITV Coche Híbrido',
    'Pre-Mot + Hybrid Van MOT': 'Pre-ITV + ITV Furgoneta Híbrida',
    'Pre-MOT + Motorbike +125 MOT': 'Pre-ITV + ITV Moto +125cc',
    'Pre-MOT + Motorbike -125 MOT': 'Pre-ITV + ITV Moto -125cc',
    'Pre-MOT + Petrol Car MOT': 'Pre-ITV + ITV Coche Gasolina',
    Refueling: 'Repostaje',
    'Refueling Atv': 'Repostaje',
    'Refueling Light Van': 'Repostaje',
    'Refueling Minivan': 'Repostaje',
    'Refueling motorbike': 'Repostaje',
    'Refueling scooter': 'Repostaje',
    'Refueling Van': 'Repostaje',
    'Tire Check': 'Comprobación de neumáticos',
    'Tow truck': 'Grúa',
    Transfer: 'Transfer',
    'Transfer and Replacement Vehicle': 'Transfer y vehículo de sustitución',
    'Tunnel Complete Car Wash': 'Lavado Proveedor Completo',
    'Tunnel Exterior Car Wash': 'Lavado Proveedor Exterior',
    'Tunnel Interior Car Wash': 'Lavado Proveedor Interior',
    'Tunnel Motorbike Wash': 'Lavado Proveedor Moto',
    'Tunnel wash': 'Lavado de Túnel',
    Tyres: 'Neumáticos',
    'Upholstery 5 Seats': 'Tapicería',
    'Valet Train Station': 'Valet',
    'Workshop at home': 'Diagnóstico',
  };

  public errors: any = [
    {
      show: "Generic error code. It also marks that something internal failed, and we don't wanna expose internal code.",
      code: 'LMP000000',
      error: 'UnknownError',
    },
    {
      show: 'The requested zone is invalid.',
      code: 'LMP000001',
      error: 'InvalidZone',
    },
    {
      show: "The requested zone doesn't have any products.",
      code: 'LMP000002',
      error: 'NoProductsInZone',
    },
    {
      show: 'The requested product is invalid.',
      code: 'LMP000003',
      error: 'InvalidProduct',
    },
    {
      show: 'The requested product in the specified zone context is invalid.',
      code: 'LMP000004',
      error: 'InvalidProductInZone',
    },
    {
      show: 'There were no products in the product creation attempt.',
      code: 'LMP000005',
      error: 'NoProductsInProductCreationAttempt',
    },
    {
      show: 'A specified product configuration is invalid.',
      code: 'LMP000006',
      error: 'InvalidProductConfiguration',
    },
    {
      show: 'The requested valet station does not exists.',
      code: 'LMP000007',
      error: 'ValetStationDoesNotExist',
    },
    {
      show: 'The origin address point is invalid.',
      code: 'LMP000008',
      error: 'InvalidOriginAddressPoint',
    },
    {
      show: 'The destination address point is invalid.',
      code: 'LMP000009',
      error: 'InvalidDestinationAddressPoint',
    },
    {
      show: 'The vehicle availability requested for a replacement vehicle is invalid.',
      code: 'LMP0000010',
      error: 'InvalidVehicleAvailabilityForReplacementVehicle',
    },
    {
      show: 'Depending on the context of the request, the endpoint used is not the correct one.',
      code: 'LMP000011',
      error: 'InvalidEndpointForRequestContext',
    },
    {
      show: 'Some products in the order are missing a provider.',
      code: 'LMP0000012',
      error: 'ProvidersNotAssigned',
    },
    {
      show: 'The requested order does not exist.',
      code: 'LMP0000013',
      error: 'OrderDoesNotExists',
    },
    {
      show: 'The requested provider does not exist.',
      code: 'LMP0000014',
      error: 'ProviderDoesNotExist',
    },
    {
      show: 'The order hash provider is non-compliant with known formats.',
      code: 'LMP0000015',
      error: 'InvalidOrderHash',
    },
    {
      show: 'The specified driver id is invalid or malformed.',
      code: 'LMP0000016',
      error: 'InvalidDriverId',
    },
    {
      show: 'The requested driver does not exist.',
      code: 'LMP000017',
      error: 'DriverDoesNotExists',
    },
    {
      show: 'A cancellation of a cancelled order cannot be performed.',
      code: 'LMP000018',
      error: 'CannotCancelACancelledOrder',
    },
    {
      show: 'A cancellation of an invalid order cannot be performed.',
      code: 'LMP000019',
      error: 'CannotCancelAnInvalidOrder',
    },
    {
      show: 'Assigning drivers to a service without nodes is not allowed.',
      code: 'LMP0000021',
      error: 'CannotAssignDriverToServiceWithoutNodes',
    },
    {
      show: "Due to the status of the order, we don't allow the requested operation.",
      code: 'LMP0000022',
      error: 'OrderStatusDoesNotAllowsOperation',
    },
  ];

    public productsCodes: { [lng: string]: { [code: string]: string } } = {
        'es':{
            '360-mot-diesel-car-es': 'ITV 360 Coche Diesel',
            '360-mot-diesel-van-es': 'ITV 360 Furgoneta Diesel',
            '360-mot-electric-car-es': 'ITV 360 Coche Eléctrico',
            '360-mot-electric-van-es': 'ITV 360 Furgoneta Eléctrica',
            '360-mot-fuel-car-es': 'ITV 360 Coche Gasolina',
            '360-mot-gas-van-es': 'ITV 360 Furgoneta Gas',
            '360-mot-hybrid-car-es': 'ITV 360 Coche Híbrido',
            '360-mot-hybrid-van-es': 'ITV 360 Furgoneta Híbrida',
            '360-mot-motorbike-125cc-es': 'ITV 360 Moto +125cc',
            '360-mot-motorbike-es': 'ITV 360 Moto -125cc',
            '360-motorbike-electric-mot': 'ITV 360 Moto -125cc Eléctrica',
            '360-motorbike-electric-mot-125cc': 'ITV 360 Moto +125cc Eléctrica',
            'caflerFresh-topping': 'Cafler Fresh',
            'car-basic-maintenance': 'Mantenimiento Básico Coche',
            'car-complete-maintenance': 'Mantenimiento Completo Coche',
            'car-medium-maintenance': 'Mantenimiento Medio Coche',
            'car-service': 'Taller a domicilio',
            'checklist-topping': 'Checklist',
            'collect': 'Recogida',
            'complete-car-wash-topping': 'Lavado Completo',
            'express-complete-wash-topping': 'Lavado Express Completo',
            'express-exterior-wash-topping': 'Lavado Express Exterior',
            'express-interior-wash-topping': 'Lavado Express Interior',
            'exterior-car-wash-topping': 'Lavado Exterior',
            'fine-tunning': 'Cafler Ready',
            'headlight-polishing': 'Pulido de faros',
            'insurance-topping': 'Seguro',
            'integral-washing': 'Lavado Integral',
            'interior-car-wash-topping': 'Lavado Interior',
            'medium-long-distance': 'Media/Larga Distancia',
            'mot-diesel-car-es': 'ITV Coche Diesel',
            'mot-diesel-car-fr': 'ITV Coche Diesel',
            'mot-diesel-van-es': 'ITV Furgoneta Diesel',
            'mot-electric-car-es': 'ITV Coche Eléctrico',
            'mot-electric-car-fr': 'ITV Coche Eléctrico',
            'mot-electric-van-es': 'ITV Furgoneta Eléctrica',
            'mot-fuel-car-es': 'ITV Coche Gasolina',
            'mot-fuel-car-fr': 'ITV Coche Gasolina',
            'mot-fuel-car-uk': 'ITV Coche Gasolina',
            'mot-gas-car-fr': 'ITV Coche Gas',
            'mot-gas-van-es': 'ITV Furgoneta Gas',
            'mot-homologation': 'ITV Homologación',
            'mot-hybrid-car-es': 'ITV Coche Híbrido',
            'mot-hybrid-car-fr': 'ITV Coche Híbrido',
            'mot-hybrid-van-es': 'ITV Furgoneta Híbrida',
            'mot-motorbike-125cc-es': 'ITV Moto -125cc',
            'mot-motorbike-es': 'ITV Moto +125cc',
            'mot-motorbike-uk': 'ITV Moto +125cc',
            'motorbike-basic-maintenance': 'Mantenimiento Básico Moto',
            'motorbike-complete-maintenance': 'Mantenimiento Completo Moto',
            'motorbike-electric-mot': 'ITV Moto -125cc Eléctrica',
            'motorbike-electric-mot-125cc': 'ITV Moto +125cc Eléctrica',
            'motorbike-wash-topping': 'Lavado Moto',
            'ownership-change': 'Cambio de nombre',
            'parking': 'Parking',
            'premium-complete-car-wash': 'Lavado Premium Completo',
            'premium-exterior-car-wash': 'Lavado Premium Exterior',
            'premium-interior-car-wash': 'Lavado Premium Interior',
            'pre-mot': 'Pre-ITV',
            'premot-mot-diesel-car-es': 'Pre-ITV + ITV Coche Diésel',
            'premot-mot-diesel-van-es': 'Pre-ITV + ITV Furgoneta Diésel',
            'premot-mot-electric-car-es': 'Pre-ITV + ITV Coche Eléctrico',
            'premot-mot-electric-van-es': 'Pre-ITV + ITV Furgoneta Eléctrico',
            'premot-mot-fuel-car-es': 'Pre-ITV + ITV Coche Gasolina',
            'premot-mot-gas-van-es': 'Pre-ITV + ITV Furgoneta Gas',
            'premot-mot-hybrid-car-es': 'Pre-ITV + ITV Coche Híbrido',
            'premot-mot-hybrid-van-es': 'Pre-ITV + ITV Furgoneta Híbrida',
            'premot-mot-motorbike-125cc-es': 'Pre-ITV + ITV Moto +125cc',
            'premot-mot-motorbike-es': 'Pre-ITV + ITV Moto -125cc',
            'premot-motorbike-electric-mot': 'Pre-ITV + ITV Moto -125cc Eléctrica',
            'premot-motorbike-electric-mot-125cc': 'Pre-ITV + ITV Moto +125cc Eléctrica',
            'refueling-car-topping': 'Repostaje',
            'refueling-topping': 'Repostaje',
            'replacement-vehicle-basic': 'Vehículo de Sustitución Básico',
            'return': 'Entrega',
            'tireInflation-topping': 'Inflado de neumáticos',
            'tow_truck': 'Grúa',
            'transfer': 'Transfer',
            'transfer-pack-replacement-vehicle': 'Transfer con vehículo de sustitución',
            'transfer-replacement-vehicle': 'Vehículo de sustitución',
            'transit': 'Tránsito',
            'tunnel-complete-car-wash': 'Lavado Completo Proveedor',
            'tunnel-exterior-car-wash': 'Lavado Exterior Proveedor',
            'tunnel-exterior-car-wash-topping': 'Lavado Exterior Proveedor',
            'tunnel-interior-car-wash': 'Lavado Interior Proveedor',
            'tunnel-motorbike-wash': 'Lavado Moto Proveedor',
            'tyres': 'Neumáticos',
            'upholstery-detail-wash-5-seats': 'Limpieza Tapicería',
            'valet-station': 'Valet Parking'
        },
        'en':{
            '360-mot-diesel-car-es': 'MOT 360 Diesel Car',
            '360-mot-diesel-van-es': 'MOT 360 Diesel Van',
            '360-mot-electric-car-es': 'MOT 360 Electric Car',
            '360-mot-electric-van-es': 'MOT 360 Electric Van',
            '360-mot-fuel-car-es': 'MOT 360 Gasoline Car',
            '360-mot-gas-van-es': 'MOT 360 Gas Van',
            '360-mot-hybrid-car-es': 'MOT 360 Hybrid Car',
            '360-mot-hybrid-van-es': 'MOT 360 Hybrid Van',
            '360-mot-motorbike-125cc-es': 'MOT 360 Motorcycle -125cc',
            '360-mot-motorbike-es': 'MOT 360 Motorcycle +125cc',
            '360-motorbike-electric-mot': 'MOT 360 Motorcycle +125cc Electric',
            '360-motorbike-electric-mot-125cc': 'MOT 360 Motorcycle -125cc Electric',
            'caflerFresh-topping': 'Cafler Fresh',
            'car-basic-maintenance': 'Basic Car Maintenance',
            'car-complete-maintenance': 'Complete Car Maintenance',
            'car-medium-maintenance': 'Medium Car Maintenance',
            'car-service': 'Home Workshop',
            'checklist-topping': 'Checklist',
            'collect': 'Pick up',
            'complete-car-wash-topping': 'Complete Wash',
            'express-complete-wash-topping': 'Complete Express Wash',
            'express-exterior-wash-topping': 'Exterior Express Wash',
            'express-interior-wash-topping': 'Indoor Express Wash',
            'exterior-car-wash-topping': 'Exterior Washing',
            'fine-tunning': 'Cafler Ready',
            'headlight-polishing': 'Headlight Polishing',
            'insurance-topping': 'Insurance',
            'integral-washing': 'Integral Wash',
            'interior-car-wash-topping': 'Interior Wash',
            'medium-long-distance': 'Medium/Long Distance',
            'mot-diesel-car-es': 'MOT Diesel Car',
            'mot-diesel-car-fr': 'MOT Diesel Car',
            'mot-diesel-van-es': 'MOT Diesel Van',
            'mot-electric-car-es': 'MOT Electric Car',
            'mot-electric-car-fr': 'MOT Electric Car',
            'mot-electric-van-es': 'MOT Electric Van',
            'mot-fuel-car-es': 'MOT Gasoline Car',
            'mot-fuel-car-fr': 'MOT Gasoline Car',
            'mot-fuel-car-uk': 'MOT Gasoline Car',
            'mot-gas-car-fr': 'MOT Gas Car',
            'mot-gas-van-es': 'MOT Gas Van',
            'mot-homologation': 'MOT Homologation',
            'mot-hybrid-car-es': 'MOT Hybrid Car',
            'mot-hybrid-car-fr': 'MOT Hybrid Car',
            'mot-hybrid-van-es': 'MOT Hybrid Van',
            'mot-motorbike-125cc-es': 'MOT Motorcycle -125cc',
            'mot-motorbike-es': 'MOT Motorcycle +125cc',
            'mot-motorbike-uk': 'MOT Motorcycle +125cc',
            'motorbike-basic-maintenance': 'Basic Motorcycle Maintenance',
            'motorbike-complete-maintenance': 'Complete Motorcycle Maintenance',
            'motorbike-electric-mot': 'MOT Motorcycle -125cc Electric',
            'motorbike-electric-mot-125cc': 'MOT Motorcycle +125cc Electric',
            'motorbike-wash-topping': 'Motorcycle Washing',
            'ownership-change': 'Name Change',
            'parking': 'Parking',
            'premium-complete-car-wash': 'Premium Complete Wash',
            'premium-exterior-car-wash': 'Exterior Premium Wash',
            'premium-interior-car-wash': 'Premium Interior Wash',
            'pre-mot': 'Pre-MOT',
            'premot-mot-diesel-car-es': 'Pre-MOT + MOT Diesel Car',
            'premot-mot-diesel-van-es': 'Pre-MOT + MOT Diesel Vans',
            'premot-mot-electric-car-es': 'Pre-MOT + MOT Electric Car',
            'premot-mot-electric-van-es': 'Pre-MOT + MOT Electric Van',
            'premot-mot-fuel-car-es': 'Pre-MOT + MOT Petrol Car',
            'premot-mot-gas-van-es': 'Pre-MOT + MOT Gas Vans',
            'premot-mot-hybrid-car-es': 'Pre-MOT + MOT Hybrid Car',
            'premot-mot-hybrid-van-es': 'Pre-MOT + MOT Hybrid Van',
            'premot-mot-motorbike-125cc-es': 'Pre-MOT + MOT Motorbike +125cc',
            'premot-mot-motorbike-es': 'Pre-MOT + MOT Motorbike -125cc',
            'premot-motorbike-electric-mot': 'Pre-MOT + MOT Motorbike +125cc Electric',
            'premot-motorbike-electric-mot-125cc': 'Pre-MOT + MOT Motorbike -125cc Electric',
            'refueling-car-topping': 'Refueling',
            'refueling-topping': 'Refueling',
            'replacement-vehicle-basic': 'Basic Replacement Vehicle',
            'return': 'Delivery',
            'tireInflation-topping': 'Tire inflation',
            'tow_truck': 'Crane',
            'transfer': 'Transfer',
            'transfer-pack-replacement-vehicle': 'Replacement vehicle',
            'transfer-replacement-vehicle': 'Transfer with replacement vehicle',
            'transit': 'Transit',
            'tunnel-complete-car-wash': 'Complete wash Supplier',
            'tunnel-exterior-car-wash': 'Exterior Washing Supplier',
            'tunnel-exterior-car-wash-topping': 'Exterior Washing Supplier',
            'tunnel-interior-car-wash': 'Interior Washing Supplier',
            'tunnel-motorbike-wash': 'Motorcycle Washing Supplier',
            'tyres': 'Tires',
            'upholstery-detail-wash-5-seats': 'Upholstery Cleaning',
            'valet-station': 'Valet Parking'
        },
        'fr':{
            '360-mot-diesel-car-es': 'CT 360 Voiture Diesel',
            '360-mot-diesel-van-es': 'CT 360 Fourgonnette Diesel',
            '360-mot-electric-car-es': 'CT 360 Voiture Électrique',
            '360-mot-electric-van-es': 'CT 360 Fourgonnette Électrique',
            '360-mot-fuel-car-es': 'CT 360 Voiture Essence',
            '360-mot-gas-van-es': 'CT 360 Fourgonnette Gaz',
            '360-mot-hybrid-car-es': 'CT 360 Voiture Hybride',
            '360-mot-hybrid-van-es': 'CT 360 Fourgonnette Hybride',
            '360-mot-motorbike-125cc-es': 'CT 360 Moto +125cc',
            '360-mot-motorbike-es': 'CT 360 Moto -125cc',
            '360-motorbike-electric-mot': 'CT 360 Moto -125cc Électrique',
            '360-motorbike-electric-mot-125cc': 'CT 360 Moto +125cc Électrique',
            'caflerFresh-topping': 'Cafler Fresh',
            'car-basic-maintenance': 'Entretien Basique Voiture',
            'car-complete-maintenance': 'Entretien Complet Voiture',
            'car-medium-maintenance': 'Entretien Moyen Voiture',
            'car-service': 'Atelier à Domicile',
            'checklist-topping': 'Checklist',
            'collect': 'Enlèvement',
            'complete-car-wash-topping': 'Lavage Complet',
            'express-complete-wash-topping': 'Lavage Express Complet',
            'express-exterior-wash-topping': 'Lavage Express Extérieur',
            'express-interior-wash-topping': 'Lavage Express Intérieur',
            'exterior-car-wash-topping': 'Lavage Extérieur',
            'fine-tunning': 'Cafler Ready',
            'headlight-polishing': 'Polissage des Phares',
            'insurance-topping': 'Assurance',
            'integral-washing': 'Lavage Intégral',
            'interior-car-wash-topping': 'Lavage Intérieur',
            'medium-long-distance': 'Moyenne/Longue Distance',
            'mot-diesel-car-es': 'CT Voiture Diesel',
            'mot-diesel-car-fr': 'CT Voiture Diesel',
            'mot-diesel-van-es': 'CT Fourgonnette Diesel',
            'mot-electric-car-es': 'CT Voiture Électrique',
            'mot-electric-car-fr': 'CT Voiture Électrique',
            'mot-electric-van-es': 'CT Fourgonnette Électrique',
            'mot-fuel-car-es': 'CT Voiture Essence',
            'mot-fuel-car-fr': 'CT Voiture Essence',
            'mot-fuel-car-uk': 'CT Voiture Essence',
            'mot-gas-car-fr': 'CT Voiture Gaz',
            'mot-gas-van-es': 'CT Fourgonnette Gaz',
            'mot-homologation': 'CT Homologation',
            'mot-hybrid-car-es': 'CT Voiture Hybride',
            'mot-hybrid-car-fr': 'CT Voiture Hybride',
            'mot-hybrid-van-es': 'CT Fourgonnette Hybride',
            'mot-motorbike-125cc-es': 'CT Moto -125cc',
            'mot-motorbike-es': 'CT Moto +125cc',
            'mot-motorbike-uk': 'CT Moto +125cc',
            'motorbike-basic-maintenance': 'Entretien Basique Moto',
            'motorbike-complete-maintenance': 'Entretien Complet Moto',
            'motorbike-electric-mot': 'CT Moto -125cc Électrique',
            'motorbike-electric-mot-125cc': 'CT Moto +125cc Électrique',
            'motorbike-wash-topping': 'Lavage Moto',
            'ownership-change': 'Changement de Nom',
            'parking': 'Parking',
            'premium-complete-car-wash': 'Lavage Premium Complet',
            'premium-exterior-car-wash': 'Lavage Premium Extérieur',
            'premium-interior-car-wash': 'Lavage Premium Intérieur',
            'pre-mot': 'Pré-CT',
            'premot-mot-diesel-car-es': 'Pré-CT + CT Voiture Diesel',
            'premot-mot-diesel-van-es': 'Pré-CT + CT Fourgonnette Diesel',
            'premot-mot-electric-car-es': 'Pré-CT + CT Voiture Électrique',
            'premot-mot-electric-van-es': 'Pré-CT + CT Fourgonnette Électrique',
            'premot-mot-fuel-car-es': 'Pré-CT + CT Voiture Essence',
            'premot-mot-gas-van-es': 'Pré-CT + CT Fourgonnette Gaz',
            'premot-mot-hybrid-car-es': 'Pré-CT + CT Voiture Hybride',
            'premot-mot-hybrid-van-es': 'Pré-CT + CT Fourgonnette Hybride',
            'premot-mot-motorbike-125cc-es': 'Pré-CT + CT Moto +125cc',
            'premot-mot-motorbike-es': 'Pré-CT + CT Moto -125cc',
            'premot-motorbike-electric-mot': 'Pré-CT + CT Moto -125cc Électrique',
            'premot-motorbike-electric-mot-125cc': 'Pré-CT + CT Moto +125cc Électrique',
            'refueling-car-topping': 'Ravitaillement',
            'refueling-topping': 'Ravitaillement',
            'replacement-vehicle-basic': 'Véhicule de Remplacement Basique',
            'return': 'Livraison',
            'tireInflation-topping': 'Gonflage des Pneus',
            'tow_truck': 'Dépannage',
            'transfer': 'Transfert',
            'transfer-pack-replacement-vehicle': 'Véhicule de Remplacement',
            'transfer-replacement-vehicle': 'Transfert avec Véhicule de Remplacement',
            'transit': 'Transit',
            'tunnel-complete-car-wash': 'Lavage Complet prestataire',
            'tunnel-exterior-car-wash': 'Lavage Extérieur prestataire',
            'tunnel-exterior-car-wash-topping': 'Lavage Extérieur prestataire',
            'tunnel-interior-car-wash': 'Lavage Intérieur prestataire',
            'tunnel-motorbike-wash': 'Lavage Moto prestataire',
            'tyres': 'Pneus',
            'upholstery-detail-wash-5-seats': 'Tapisserie',
            'valet-station': 'Valet Parking'
        }
    };

  showErrorCode(code: any) {
    let error = this.errors.find((x: any) => x.code == code);
    let show = 'Unknown Error';
    if (error) {
      show = error.show;
    }
    this.alerts
      .open(show, {
        label: 'Ha ocurrido un error',
        status: 'error',
        autoClose: true,
      })
      .subscribe();
  }

  translate(term: string): string {
    return this.traducciones[term] || term;
  }

    translate2(lng:string, code:string): string {
        return this.productsCodes[lng]?.[code] || 'Texto no encontrado';
    }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
declare var bootstrap: any
import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';
declare var moment: any;
@Component({
  selector: 'app-service-data-modal',
  templateUrl: './service-data-modal.component.html',
  styleUrls: ['./service-data-modal.component.css']
})
export class ServiceDataModalComponent {
  @Input() bsModalRef!: any
  @Input() serviceData: any;
  @Input() zoneId: any;
  @Input() zones: any;
  verticals: any[] = [
  
    { name: "Transfer", type: "TRANSFER" },
    { name: "Taller", type: "MECHANICAL_INSPECTION" },
    { name: "Lavado", type: "VEHICLE_WASH" },
    { name: "Repostaje", type: "REFUELING" },
    { name: "Pre-ITV", type: "PRE_TECHNICAL_INSPECTION" },
    { name: "Transfer Larga Distancia", type: "LONG_DISTANCE_TRANSFER" },
    { name: "ITV", type: "TECHNICAL_INSPECTION" },
    { name: "Seguro", type: "VEHICLE_INSURANCE" },
    { name: "Cafler Fresh", type: "ACCESSORY" },
    { name: "Valet", type: "VALET" },
    { name: "Neumáticos", type: "TYRES" },
    { name: "Trámites", type: "FORMALITIES" },
    { name: "Parking", type: "PARKING" },
    { name: "Vehículo de sustitución", type: "REPLACEMENT_VEHICLE" },
    { name: "Grúa", type: "TOW_TRUCK" },
    { name: "Pide lo que quieras", type: "WHATEVER_YOU_WANT" }
  ];
  modalInstance: any;
    constructor(     
        private hs:HistoryServicesService,
        private router:Router,
        private ms: MapaServiceService,
    ){}


formatTime(date: any) {
  // console.log(this.zoneId);
  const newDate = new Date(parseInt(date) * 1000);
  const hours = String(newDate.getUTCHours()).padStart(2, '0');
  const minutes = String(newDate.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;

    // const zone = this.zones.find((zone:any) => zone.zoneId === this.zoneId);
    // const dateInClientZone = moment.unix(newDate).tz(zone.timeZone);
    // return dateInClientZone.format('HH:mm');
}

viewDetail(data:any){
  this.closeModal()
  const datos = { service_info: data};

  this.hs.setData(datos);

  this.router.navigate(['service-manager//detail-service'], { queryParams: { orderHash: data.orderHash} }); 

}




closeModal() {
  if (this.bsModalRef) {
    this.bsModalRef.hide();
  }
}

getVertical(verticalType:string){

  const selectedVertical = this.verticals.find(vertical => vertical.type === verticalType);

  return selectedVertical.name

}



  
}

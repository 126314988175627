import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-new-provider-modal',
  templateUrl: './new-provider-modal.component.html',
  styleUrls: ['./new-provider-modal.component.css']
})
export class NewProviderModalComponent {
    @Input() provider_info: any;
    @Input() type_info:any;
    constructor(
        private offcanvasService: NgbOffcanvas
    ) {}

    closeCanvas(){
        this.offcanvasService.dismiss();
    }
}

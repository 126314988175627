<div class="table-body-options mb-3">
  <div class="search-bar">
    <img
      src="../../../assets/icons/search- icon.svg"
      alt="Search Icon"
      class="search-icon"
    />
    <input
      #searchInput
      [disabled]="filterDisabled"
      id="searchInput"
      [(ngModel)]="searchTerm"
      (input)="funcionKeyUp(searchInput.value)"
      type="text"
      placeholder="{{
        'service_manager.pending_service.search_text' | translate
      }}"
      class="search-bar-input"
    />
  </div>
  <button
    [disabled]="filterDisabled || poolServices.length < 1"
    (click)="filterDataBySelect()"
    appearance="primary-button"
    size="s"
    tuiButton
  >
    <tui-svg src="tuiIconSearch"></tui-svg>
    {{ "service_manager.pending_service.search_list_text" | translate }}
  </button>
  <tui-input-date-range
    [disabled]="filterDisabled"
    [(ngModel)]="selectedDate"
    (ngModelChange)="filterDataByDate()"
    tuiTextfieldSize="s"
    [tuiTextfieldCleaner]="true"
    [tuiTextfieldLabelOutside]="true"
    class="custom_select"
  >
    {{ "service_manager.history_service.date_input" | translate }}
    <input placeholder="desde - hasta" tuiTextfield />
  </tui-input-date-range>

  <ng-select
    [items]="zones"
    bindLabel="zoneName"
    bindValue="zoneId"
    [multiple]="false"
    [(ngModel)]="selectedZone"
    [disabled]="filterDisabled"
    class="custom_select"
    (close)="filterDataByDate()"
    placeholder="{{ 'service_manager.pending_service.zone_input' | translate }}"
    [dropdownPosition]="'bottom'"
  >
  </ng-select>

  <form style="display: contents" class="b-form" [formGroup]="hourForm">
    <tui-input-time
      style="margin-top: -8px"
      tuiTextfieldSize="m"
      class="tui-space_top-4"
      formControlName="startTime"
      [items]="startTimeItems"
    >
      Hora inicio
    </tui-input-time>

    <tui-input-time
      style="margin-top: -8px"
      tuiTextfieldSize="m"
      class="tui-space_top-4"
      formControlName="endTime"
      [items]="endTimeItems"
      [strict]="true"
    >
      Hora fin
    </tui-input-time>
  </form>

  <ng-select
    [items]="orderStatus"
    bindLabel="statusName"
    bindValue="orderStatus"
    [multiple]="true"
    [(ngModel)]="selectedOrderStatus"
    placeholder="{{
      'service_manager.history_service.status_input' | translate
    }}"
    class="custom_select"
    (change)="filterDataBySelect()"
    [dropdownPosition]="'bottom'"
  >
  </ng-select>

  <ng-select
    [items]="allClients"
    bindLabel="businessName"
    bindValue="userId"
    [multiple]="true"
    [(ngModel)]="selectedClient"
    [disabled]="filterDisabled"
    class="custom_select"
    (change)="filterDataBySelect()"
    placeholder="{{
      'service_manager.pending_service.clients_input' | translate
    }}"
    [dropdownPosition]="'bottom'"
  >
  </ng-select>

  <ng-select
    [items]="allDrivers"
    bindLabel="businessName"
    bindValue="userId"
    [multiple]="true"
    [(ngModel)]="selectedDriver"
    [disabled]="filterDisabled"
    class="custom_select"
    (change)="filterDataBySelect()"
    placeholder="Conductores"
    [dropdownPosition]="'bottom'"
  >
  </ng-select>

  <ng-select
    [items]="products"
    bindLabel="product"
    [multiple]="true"
    [(ngModel)]="selectedProduct"
    [disabled]="filterDisabled"
    class="custom_select"
    (change)="filterDataBySelect()"
    placeholder="{{
      'service_manager.pending_service.products_input' | translate
    }}"
    [dropdownPosition]="'bottom'"
  >
  </ng-select>
  <ng-select
    [items]="communication_states"
    bindLabel="name"
    bindValue="id"
    [multiple]="true"
    [(ngModel)]="selectedCommunication"
    [disabled]="filterDisabled"
    placeholder="Comunicación"
    (change)="filterDataBySelect()"
    [searchable]="false"
    [clearable]="false"
    class="custom_select"
    [dropdownPosition]="'bottom'"
    style="min-width: 300px"
  >
    <!-- Personalizar cada opción -->
    <ng-template ng-option-tmp let-item="item">
      <span
        style="border-top: 1px solid #e1e6ec; display: block; width: 100%"
        *ngIf="!item.color"
      ></span>
      <div class="option-item">
        <span
          [style.background]="item.color"
          style="width: 20px; height: 20px; border-radius: 7px"
          *ngIf="item.color"
        ></span>
        <span>{{ item.name }}</span>
        <span class="badge" style="margin-left: auto">{{ item.count }}</span>
      </div>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div [style.background]="item.color">
        <span
          class="ng-value-icon left"
          (click)="clear(item)"
          *ngIf="item.disabled !== true"
          >×</span
        >
        <span>{{ item.name }}</span>
      </div>
      <!-- <img height="15" width="15" [src]="item.avatar" /> -->
    </ng-template>
  </ng-select>
</div>
<div>
  <table
    tuiTable
    size="l"
    style="width: 125%"
    [columns]="columns"
    (directionChange)="onSort($event)"
  >
    <thead>
      <tr tuiThGroup>
        <th
          class="th-style"
          *tuiHead="'licensePlate'"
          tuiTh
          (click)="setSortColumn('licensePlate')"
          [sticky]="true"
        >
          {{ "service_manager.pending_service.plate_input_table" | translate }}
        </th>
        <th
          [sticky]="true"
          *tuiHead="'time'"
          tuiTh
          (click)="setSortColumn('startHour')"
        >
          {{ "service_manager.pending_service.hour_input_table" | translate }}
        </th>
        <th
          [sticky]="true"
          *tuiHead="'orderHash'"
          tuiTh
          (click)="setSortColumn('orderHash')"
        >
          {{ "service_manager.pending_service.hash_input_table" | translate }}
        </th>
        <th
          [sticky]="true"
          *tuiHead="'zone'"
          tuiTh
          (click)="setSortColumn('zoneName')"
        >
          {{ "service_manager.pending_service.zone_input_table" | translate }}
        </th>
        <th *tuiHead="'date'" tuiTh (click)="setSortColumn('formattedDate')">
          {{ "service_manager.pending_service.date_input_table" | translate }}
        </th>

        <th
          [sticky]="true"
          *tuiHead="'product'"
          tuiTh
          (click)="setSortColumn('mainProductName')"
        >
          {{
            "service_manager.pending_service.product_input_table" | translate
          }}
        </th>
        <th
          [sticky]="true"
          *tuiHead="'topping'"
          (click)="setSortColumn('toppingNames')"
          tuiTh
        >
          {{
            "service_manager.pending_service.toppings_input_table" | translate
          }}
        </th>
        <th
          [sticky]="true"
          *tuiHead="'status'"
          (click)="setSortColumn('status')"
          tuiTh
        >
          {{ "service_manager.history_service.status_input_table" | translate }}
        </th>
        <th
          [sticky]="true"
          *tuiHead="'client'"
          tuiTh
          (click)="setSortColumn('clientName')"
        >
          {{ "service_manager.pending_service.client_input_table" | translate }}
        </th>

        <th
          [sticky]="true"
          *tuiHead="'driver'"
          (click)="setSortColumn('driver')"
          tuiTh
        >
          <!-- {{
                "service_manager.pending_service.comments_input_table" | translate
              }} -->
          Conductor
        </th>
        <th
          [sticky]="true"
          *tuiHead="'price'"
          (click)="setSortColumn('price')"
          tuiTh
        >
          <!-- {{
              "service_manager.pending_service.comments_input_table" | translate
            }} -->
          Precio
        </th>
        <th
          [sticky]="true"
          *tuiHead="'activeBid'"
          (click)="setSortColumn('activeBid')"
          tuiTh
        >
          <!-- {{
            "service_manager.pending_service.comments_input_table" | translate
          }} -->
          Pujas activas
        </th>
        <th
          [sticky]="true"
          *tuiHead="'communication'"
          (click)="setSortColumn('communication')"
          tuiTh
        >
          <!-- {{
          "service_manager.pending_service.comments_input_table" | translate
        }} -->
          Comunicación
        </th>
        <th *tuiHead="'actions'" tuiTh>Acciones</th>
      </tr>
    </thead>
    <tbody tuiTbody>
      <tr tuiTr *ngFor="let item of paginatedData">
        <td *tuiCell="'licensePlate'" tuiTd>
          <div class="vertical-align">
            <span tuiTitle>
              {{ item.licensePlate }}
            </span>
            <span tuiSubtitle>
              {{ item.orderNumber }}
            </span>
          </div>
        </td>

        <td *tuiCell="'time'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle>
              {{ item.startHour + "-" + item.endHour }}
            </span>
          </div>
        </td>
        <td *tuiCell="'orderHash'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle>
              {{ item.orderHash }}
            </span>
          </div>
        </td>
        <td *tuiCell="'zone'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle>
              {{ item.zoneName }}
            </span>
          </div>
        </td>
        <td *tuiCell="'date'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle>
              {{ item.formattedDate }}
            </span>
          </div>
        </td>

        <td *tuiCell="'product'" tuiTd>
          <span tuiTitle>
            {{ historyServicesSvc.translate(item.mainProductName) }}
          </span>
        </td>
        <td *tuiCell="'topping'" tuiTd>
          <span tuiTitle>
            <label class="d-block" *ngFor="let topping of item.toppingNames">{{
              topping
            }}</label>
            <label *ngIf="item.toppingNames.length == 0">Sin Toppings </label>
          </span>
        </td>
        <td *tuiCell="'client'" tuiTd>
          <span tuiTitle>
            {{
              getClientById(item.clientId)
                ? getClientById(item.clientId)
                : item.clientName
            }}
          </span>
        </td>
        <td *tuiCell="'status'" tuiTd>
          <div *ngIf="item.orderStatus == 'INITIALIZED'">
            <tui-badge
              status="neutral"
              value="Inicializado"
              class="tui-space_right-2 tui-space_bottom-2 custom_initalized"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'PENDING'">
            <tui-badge
              status="neutral"
              value="Pendiente"
              class="tui-space_right-2 tui-space_bottom-2 custom_pending"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'CONFIRMED'">
            <tui-badge
              status="success"
              value="Confirmado"
              class="tui-space_right-2 tui-space_bottom-2 custom_confirmed"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'PICKING_UP'">
            <tui-badge
              status="success"
              value="Recogiendo"
              class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'IN_PROGRESS'">
            <tui-badge
              status="success"
              value="En proceso"
              class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'DELIVERING'">
            <tui-badge
              status="success"
              value="Entregando"
              class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'FINISHED'">
            <tui-badge
              status="success"
              value="Finalizado"
              class="tui-space_right-2 tui-space_bottom-2 custom_finished"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'FAILED'">
            <tui-badge
              status="error"
              value="Fallado"
              class="tui-space_right-2 tui-space_bottom-2"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'CANCELLED_BY_CAFLER'">
            <tui-badge
              status="error"
              value="Cancelado por Cafler"
              class="tui-space_right-2 tui-space_bottom-2"
            ></tui-badge>
          </div>
          <div *ngIf="item.orderStatus == 'CANCELLED_BY_CLIENT'">
            <tui-badge
              status="error"
              value="Cancelado por cliente"
              class="tui-space_right-2 tui-space_bottom-2"
            ></tui-badge>
          </div>
        </td>
        <td *tuiCell="'driver'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle *ngIf="item.driverName != null; else noDriver">
              {{ item.driverName }}
            </span>
            <ng-template #noDriver>
              <tui-tag
                class="tag"
                status="custom"
                [hoverable]="true"
                value="Sin asignar"
              />
            </ng-template>
          </div>
        </td>

        <td *tuiCell="'price'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle>
              {{ item.price ?? 1000 | currency : "EUR" }}
            </span>
          </div>
        </td>

        <td *tuiCell="'activeBid'" tuiTd>
          <div class="d-flex justify-center items-center" [tuiCell]="size">
            <button
              size="s"
              class="bind"
              tuiButton
              type="button"
              appearance="outline"
            >
              <img
                src="../../../assets/icons/gavel.svg"
                alt="Icono"
                width="20"
                height="20"
                style="margin-right: 5px"
              />
              {{ item.activeBids ?? 3 }}
            </button>
          </div>
        </td>

        <td *tuiCell="'communication'" tuiTd>
          <div [tuiCell]="size">
            <span tuiTitle>
              {{ item.communication ?? "Sin contactar" }}
            </span>
          </div>
        </td>

        <td *tuiCell="'actions'" tuiTd>
          <span tuiStatus>
            <button
              appearance="link"
              icon="tuiIconEdit2"
              size="xs"
              tuiAppearance="link"
              tuiIconButton
            >
              Edit
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="table-pagination mt-5">
  <tui-pagination
    [index]="index"
    [length]="length"
    (indexChange)="goToPage($event)"
  ></tui-pagination>
</div>

<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="!driver_info">{{ 'modals.add_personal.title' | translate }}</h4>
        <h4 class="modal-title pull-left" *ngIf="driver_info">Editar Personal Operativo</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div id="modal_add_personal"></div>
        <div id="modal_add_personal_alert"></div>
        <form [formGroup]="personalForm" (ngSubmit)="savePersonal()" class="d-flex flex-column gap-4">
            <div class="row">
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.name_input' | translate }}*</label>
                        <tui-input formControlName="firstName" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_personal.name_input_inside' | translate }}
                            <input
                                tuiTextfield
                                type="text"
                            />
                        </tui-input>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.lastname_input' | translate }}*</label>
                        <tui-input formControlName="lastName" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_personal.lastname_input_inside' | translate }}
                        </tui-input>
                    </div>
                </div>
                <div class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.zone_input' | translate }}*</label>
                        <!-- <tui-select
                            tuiTextfieldSize="s"
                            formControlName="zoneId"
                            class="input_form"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_zo"
                        >
                            Selecciona zona operativa
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="operatives"
                            ></tui-data-list-wrapper>
                        </tui-select> -->
                        <ng-select
                            formControlName="zoneId"
                            [items]="operatives"
                            bindLabel="zoneName"
                            bindValue="zoneId"
                            [multiple]="false"
                            class="custom_select custom_select_single input_form"
                            placeholder="{{ 'modals.add_personal.zone_input_inside' | translate }}"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.operator_input' | translate }}*</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="operatorType"
                            class="input_form"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_ot"
                        >
                            {{ 'modals.add_personal.operator_input' | translate }}
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="operators"
                                class="list_operators"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                </div>
                <div *ngIf="personalForm?.get('operatorType')?.value === 'Interno'" class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">Cargo de usuario interno*</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="role"
                            class="input_form"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_ro"
                        >
                            Selecciona el rol de usuario interno
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="roles"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.star_address_input' | translate }}*</label>
                        <input #startAddress  formControlName="startAddress" placeholder="{{ 'modals.add_personal.star_address_input_inside' | translate }}"  type="text" class="form-control input_form">
                        <input type="hidden" formControlName="startLatitude">
                        <input type="hidden" formControlName="startLongitude">   
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.star_address_info_input' | translate }}</label>
                        <tui-input formControlName="startAddressDetail" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_personal.star_address_info_input_inside' | translate }}
                        </tui-input>
                    </div>
                </div>
                <div class="form-group">
                    <h5>
                        {{ 'modals.add_personal.hour_section' | translate }}
                    </h5>
                  <!--   <div class="row">
                        <div class="col check_div">
                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="sameSchedule" size="m"></tui-checkbox> Mismo horario de Lunes a Viernes
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col">
                            <br>
                            <label class="label_form">{{ 'modals.add_personal.star_hour_input' | translate }}</label>
                            <!-- <tui-input-time
                                formControlName="workdayStartTime"
                                class="input_form"
                                [items]="hours"
                                [tuiTextfieldLabelOutside]="true"
                                (click)="onSelectClick($event)"
                                (focusedChange)="onSelectBlur($event)"
                                nativeId="select_ws"
                            >
                            </tui-input-time> -->
                            <input type="text" class="form-control" id="timeInput" formControlName="workdayStartTime" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('start')" />
                        </div>
                        <div class="col">
                            <br>
                            <label class="label_form">{{ 'modals.add_personal.end_hour_input' | translate }}</label>
                            <!-- <tui-input-time
                                formControlName="workdayEndTime"
                                class="input_form"
                                [items]="hours2"
                                [tuiTextfieldLabelOutside]="true"
                                (click)="onSelectClick($event)"
                                (focusedChange)="onSelectBlur($event)"
                                nativeId="select_we"
                            >
                            </tui-input-time> -->
                            <input type="text" class="form-control" id="timeInput" formControlName="workdayEndTime" placeholder="hh:mm" maxlength="5" (input)="onTimeInput('end')" />
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.moto_input' | translate }}*</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="allowedMotorcycleServiceType"
                            class="input_form"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_am"
                        >
                            {{ 'modals.add_personal.moto_input' | translate }}
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="services"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                </div>
                <div class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">Usuario activo*</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="isActiveUser"
                            class="input_form"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_ia"
                        >
                            Selecciona si el usuario esta activo o no
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="active_options"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                </div>
<!--                 <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">Domicilio Dirección*</label>
                        <tui-input
                            formControlName="domicilioAddress"
                            class="input_form"
                            [tuiTextfieldLabelOutside]="true"
                            tuiTextfieldIcon="tuiIconMapPin"
                            #domicilioInput
                            id="domicilioInput"
                        >
                            Escribe la dirección domicilio
                        </tui-input>
                    </div>
                </div> -->
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.domi_address_input' | translate }}*</label>
                        <input
                        type="text"
                        formControlName="domicilioAddress"
                        #domicilioInput
                        class="form-control input_form"
                        placeholder="{{ 'modals.add_personal.domi_address_input_inside' | translate }}"
                      />
                    </div>
                </div>
        
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.domi_address_info_input' | translate }}</label>
                        <tui-input formControlName="domicilioAddressExtraInfo" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_personal.domi_address_info_input_inside' | translate }}
                        </tui-input>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.email_input' | translate }}*</label>
                        <tui-input formControlName="mainEmail" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_personal.email_input_inside' | translate }}
                        </tui-input>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.extra_email_input' | translate }}</label>
                        <tui-input formControlName="alternativeEmail" class="input_form" [tuiTextfieldLabelOutside]="true">
                            {{ 'modals.add_personal.extra_email_input_inside' | translate }}
                        </tui-input>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.phone_input' | translate }}*</label>
                        <tui-input-phone-international
                            formControlName="phoneNumber"
                            [countries]="countriesPhone"
                            [(countryIsoCode)]="countryIsoCode"
                            class="input_form2"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_pn"
                            [tuiTextfieldLabelOutside]="true"
                          ></tui-input-phone-international>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">{{ 'modals.add_personal.extra_phone_input' | translate }}</label>
                        <tui-input-phone-international
                            formControlName="alternativePhoneNumber"
                            [countries]="countriesPhoneExtra"
                            [(countryIsoCode)]="countryIsoCodeExtra"
                            class="input_form2"
                            (click)="onSelectClick($event)"
                            (focusedChange)="onSelectBlur($event)"
                            nativeId="select_ap"
                            [tuiTextfieldLabelOutside]="true"
                          ></tui-input-phone-international>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc" size="m" appearance="secondary" (click)="closeCanvas()">
            {{ 'modals.add_personal.close_modal_text' | translate }}
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!personalForm.valid" [attr.disabled]="!personalForm.valid ? true : null" *ngIf="!personalForm.valid">
            <span *ngIf="!driver_info">
                {{ 'modals.add_personal.add_button_text' | translate }}
            </span>
            <span *ngIf="driver_info">
                {{ 'modals.add_personal.add_button_text' | translate }}
            </span>
        </button>
        <button tuiButton type="button" (click)="savePersonal()" class="tui-space_right-3 tui-space_bottom-3"
        size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge"  *ngIf="personalForm.valid">
            <span *ngIf="!driver_info">
                {{ 'modals.add_personal.add_button_text' | translate }}
            </span>
            <span *ngIf="driver_info">
                {{ 'modals.add_personal.add_button_text' | translate }}
            </span>
        </button>
        <div class="loader_inside_modal_button">
            <img src="/assets/logo-loading.gif" class="img_loader_inside_modal_button" alt="">
        </div>
    </div>
</div>
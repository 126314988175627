<div class="page-header">
  <label class="page-header-title">{{
    "pool_manager.menu.pool_management" | translate
  }}</label>
  <div class="user-options">
    <button
      type="button"
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3"
      size="m"
      appearance="primary-button"
      icon="tuiIconPlusCircleLarge"
    >
      {{ "service_manager.pending_service.button_new" | translate }}
    </button>
  </div>
</div>
<div class="table-body mt-5">
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid d-flex justify-content-start">
      <ul class="navbar-nav d-flex flex-row gap-3 mb-0 align-items-center">
        <li class="nav-item cursor-pointer">
          <a
            class="nav-link"
            [ngClass]="{ active: activeLink === 'pool-management' }"
            (click)="setActiveLink('pool-management')"
            >{{ "pool_manager.menu.services_not_started" | translate }}
            <tui-tag
              *ngIf="activeLink === 'pool-management'"
              class="tag"
              status="custom"
              [hoverable]="true"
              [value]="countOfServiceNotStarted.toLocaleString()"
            />
          </a>
        </li>
        <li class="nav-item cursor-pointer">
          <a
            class="nav-link"
            [ngClass]="{ active: activeLink === 'emergencies' }"
            (click)="setActiveLink('emergencies')"
            >{{ "pool_manager.menu.emergencies" | translate }}

            <tui-tag
              *ngIf="activeLink === 'emergencies'"
              class="tag"
              status="custom"
              [hoverable]="true"
              [value]="countOfServiceNotStarted.toLocaleString()"
            />
          </a>
        </li>
        <li class="nav-item cursor-pointer">
          <a
            class="nav-link"
            [ngClass]="{ active: activeLink === 'historical' }"
            (click)="setActiveLink('historical')"
            >{{ "pool_manager.menu.historical" | translate }}
            <tui-tag
              *ngIf="activeLink === 'historical'"
              class="tag"
              status="custom"
              [hoverable]="true"
              [value]="countOfServiceNotStarted.toLocaleString()"
            />
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <app-services-not-started-table
    *ngIf="activeLink === 'pool-management'"
    (countEvent)="getCountServicesNotStarted($event)"
  ></app-services-not-started-table>
  <app-emergencies-table
    *ngIf="activeLink === 'emergencies'"
    (countEvent)="getCountServicesNotStarted($event)"
  ></app-emergencies-table>
  <app-historical-table
    *ngIf="activeLink === 'historical'"
    (countEvent)="getCountServicesNotStarted($event)"
  ></app-historical-table>
</div>

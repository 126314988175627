import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular';
import { jwtDecode } from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() { }

  sendTracing(data:any)
  {
    const id = localStorage.getItem('idToken');
      const token = localStorage.getItem('accessToken');
      if (token && id) {
          const decodedToken: any = jwtDecode(id);

          console.log(decodedToken);

          Sentry.setUser({
              id: decodedToken.oid,
              username: decodedToken.name,
              email: decodedToken.preferred_username,
          });

          Sentry.setContext('token_info', {
              tennantId: decodedToken.tid,
              idtyp: "",
              in_corp: "",
              sid: decodedToken.sid,
              xms_pl: decodedToken.xms_pl,
          });

          Sentry.setContext('operation_details', {
              hash: data.orderHash,
              estado: data.status,
          });

          if (data.wasSuccessful) {
            Sentry.captureMessage('Servicio creado', 'info');
          }else{
            Sentry.captureMessage('Servicio no creado', 'error');
          }

      }
  }
}

import {
  Component,
  HostListener,
  AfterViewChecked,
  Input,
  TemplateRef,
  Renderer2,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ServicesService } from 'src/app/services/services.service';
import { firstValueFrom } from 'rxjs';
declare var moment: any;
moment.locale('es');
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css'],
})
export class GanttComponent implements AfterViewChecked {
  @ViewChild('gantt_container') ganttContainer!: ElementRef;
  lastScrollTop = 0;
  lastScrollLeft = 0;
  scrollClass = '';
  ganttStartTime: number = 6;
  ganttEndTime: number = 21;
  rangeWidth = 100;
  dates!: string[];
  selectedDate: any = null;
  selectedZone!: any;
  zones: any[] = [];
  bsModalRef?: BsModalRef;
  actualDate: any = moment().locale('es').format('D [de] MMMM [de] YYYY');
  currentTimeStyle: boolean = true;
  driversData: any = [];
  date: any = Math.floor(new Date('2024-08-14').getTime() / 1000);
  serviceData: any;
  ganttDataOriginal: any = [];
  hours: string[] = this.getHoursOfDay(this.ganttStartTime, this.ganttEndTime);
  verticals: any[] = [
    { name: 'Desconocido', type: 'UNKNOWN' },
    { name: 'Transfer', type: 'TRANSFER' },
    { name: 'Taller', type: 'MECHANICAL_INSPECTION' },
    { name: 'Lavado', type: 'VEHICLE_WASH' },
    { name: 'Repostaje', type: 'REFUELING' },
    { name: 'Pre-ITV', type: 'PRE_TECHNICAL_INSPECTION' },
    { name: 'Transfer Larga Distancia', type: 'LONG_DISTANCE_TRANSFER' },
    { name: 'ITV', type: 'TECHNICAL_INSPECTION' },
    { name: 'Seguro', type: 'VEHICLE_INSURANCE' },
    { name: 'Cafler Fresh', type: 'ACCESSORY' },
    { name: 'Valet', type: 'VALET' },
    { name: 'Neumáticos', type: 'TYRES' },
    { name: 'Trámites', type: 'FORMALITIES' },
    { name: 'Parking', type: 'PARKING' },
    { name: 'Vehículo de sustitución', type: 'REPLACEMENT_VEHICLE' },
    { name: 'Grúa', type: 'TOW_TRUCK' },
    { name: 'Pide lo que quieras', type: 'WHATEVER_YOU_WANT' },
  ];
  @ViewChildren('div1') driverArray!: QueryList<ElementRef>;
  @ViewChildren('div2') servicesArray!: QueryList<ElementRef>;
  searchTerm: string = '';
  /* @Input() ganttData: IGanttData = {rows:[]};  */
  ganttData: any = [];
  // ganttData: any = [
  //   {
  //     key: '333bf614-d9f2-4f2c-852d-78946e7de47c',
  //     value: {
  //       data: [
  //         [
  //           {
  //             orderHash: 'B-77464319',
  //             driverId: '333bf614-d9f2-4f2c-852d-78946e7de47c',
  //             verticalType: 'REFUELING',
  //             licensePlate: 'SI',
  //             serviceStartDate: {
  //               seconds: '57600',
  //               nanos: 0,
  //             },
  //             serviceEndDate: {
  //               seconds: '66600',
  //               nanos: 0,
  //             },
  //             vehicleChassis: 'SEDAN',
  //             orderStatus: 'CONFIRMED',
  //             serviceStartAddress:
  //               'Barcelona, Providencia, Región Metropolitana, Chile',
  //             serviceFinishAddress:
  //               'Barcelona, Providencia, Región Metropolitana, Chile',
  //             hasPriority: false,
  //             driverName: 'Cesar Gutierrez',
  //             _driverName: 'driverName',
  //             distance: '0',
  //             _distance: 'distance',
  //             clientName: 'Cesarf Cesar',
  //             _clientName: 'clientName',
  //             nodeType: 'OPERATIVE_NODE',
  //             _nodeType: 'nodeType',
  //             totalTime: '2:30h',
  //             _totalTime: 'totalTime',
  //           },
  //         ],
  //       ],
  //     },
  //     driver: [
  //       {
  //         isActive: true,
  //         fullName: 'Cesar Gutierrez',
  //         creationDate: {
  //           seconds: '1729555200',
  //           nanos: 0,
  //         },
  //         zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
  //         operatorType: 'REGULAR',
  //         workdayStartTime: {
  //           seconds: '36000',
  //           nanos: 0,
  //         },
  //         workdayEndTime: {
  //           seconds: '64800',
  //           nanos: 0,
  //         },
  //         profilePictureUrl: '',
  //         driverId: '333bf614-d9f2-4f2c-852d-78946e7de47c',
  //         isInternal: false,
  //         startAddress:
  //           'Plaça de Catalunya, 08940 Cornellà de Llobregat, Barcelona, España',
  //         startAddressDetail: '',
  //         startAddressPoint: {
  //           Longitude: 2.0785381,
  //           Latitude: 41.3593025,
  //           Srid: 4326,
  //           _Srid: 'Srid',
  //         },
  //         emailAddress: 'cess@email33.com',
  //         additionalEmailAddress: 'cess@email33.com',
  //         contactPhoneNumber: '+34722000000',
  //         additionalContactPhoneNumber: '+34722000000',
  //         allowedMotorcycleServiceType: 'NOT_ALLOWED',
  //         firstName: 'Cesar',
  //         lastName: 'Gutierrez',
  //       },
  //     ],
  //   },
  //   {
  //     key: '00000000-0000-0000-0000-000000000000',
  //     value: {
  //       data: [
  //         [
  //           {
  //             orderHash: 'B-B3B327FC',
  //             driverId: '00000000-0000-0000-0000-000000000000',
  //             verticalType: 'REFUELING',
  //             licensePlate: 'MON',
  //             serviceStartDate: {
  //               seconds: '36000',
  //               nanos: 0,
  //             },
  //             serviceEndDate: {
  //               seconds: '37800',
  //               nanos: 0,
  //             },
  //             vehicleChassis: 'SEDAN',
  //             orderStatus: 'CONFIRMED',
  //             serviceStartAddress: 'usa new york',
  //             serviceFinishAddress: 'usa new york',
  //             hasPriority: false,
  //             driverName: 'Unknown',
  //             _driverName: 'driverName',
  //             distance: '0',
  //             _distance: 'distance',
  //             clientName: 'Cesar Farfan',
  //             _clientName: 'clientName',
  //             nodeType: 'SWEEPER_NODE',
  //             _nodeType: 'nodeType',
  //             totalTime: '2:30h',
  //             _totalTime: 'totalTime',
  //           },
  //         ],
  //         [
  //           {
  //             orderHash: 'B-24F43804',
  //             driverId: '00000000-0000-0000-0000-000000000000',
  //             verticalType: 'PARKING',
  //             licensePlate: 'BBQ',
  //             serviceStartDate: {
  //               seconds: '41400',
  //               nanos: 0,
  //             },
  //             serviceEndDate: {
  //               seconds: '45000',
  //               nanos: 0,
  //             },
  //             vehicleChassis: 'SEDAN',
  //             orderStatus: 'CONFIRMED',
  //             serviceStartAddress: 'Barcelońska, 02-758 Warszawa, Polonia',
  //             serviceFinishAddress: 'Barcelońska, 02-758 Warszawa, Polonia',
  //             hasPriority: false,
  //             driverName: 'Unknown',
  //             _driverName: 'driverName',
  //             distance: '0',
  //             _distance: 'distance',
  //             clientName: 'Cesar Rafael',
  //             _clientName: 'clientName',
  //             nodeType: 'OPERATIVE_NODE',
  //             _nodeType: 'nodeType',
  //             totalTime: '1:0h',
  //             _totalTime: 'totalTime',
  //           },
  //         ],
  //         [
  //           {
  //             orderHash: 'B-24F43804',
  //             driverId: '00000000-0000-0000-0000-000000000000',
  //             verticalType: 'PARKING',
  //             licensePlate: 'BBQ',
  //             serviceStartDate: {
  //               seconds: '37800',
  //               nanos: 0,
  //             },
  //             serviceEndDate: {
  //               seconds: '41400',
  //               nanos: 0,
  //             },
  //             vehicleChassis: 'SEDAN',
  //             orderStatus: 'CONFIRMED',
  //             serviceStartAddress: 'Barcelońska, 02-758 Warszawa, Polonia',
  //             serviceFinishAddress: 'Barcelońska, 02-758 Warszawa, Polonia',
  //             hasPriority: false,
  //             driverName: 'Unknown',
  //             _driverName: 'driverName',
  //             distance: '0',
  //             _distance: 'distance',
  //             clientName: 'Cesar Rafael',
  //             _clientName: 'clientName',
  //             nodeType: 'TRANSPORT_NODE',
  //             _nodeType: 'nodeType',
  //             totalTime: '3:0h',
  //             _totalTime: 'totalTime',
  //           },
  //         ],
  //       ],
  //     },
  //     driver: [
  //       {
  //         fullName: 'Conductor no asignado',
  //         operatorType: 'Sin tipo',
  //       },
  //     ],
  //   },
  // ];

  @ViewChild('father') father!: ElementRef;
  scrollPosition = 0;
  scrollPositionY = 0;
  constructor(
    private modalService: BsModalService,
    private servicesSvc: ServicesService,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {
    this.servicesSvc.getZones().subscribe((res: any) => {
      this.zones = res.zones;
    });

    this.selectedDate = new Date();
  }

  ngOnInit() {
    const oldParams = this.servicesSvc.ganttDataParams;
    if (Object.keys(oldParams).length > 0) {
      this.selectedZone = oldParams.zoneId;
      this.searchTerm = oldParams.searchTerm;
      this.selectedDate = oldParams.date;

      this.filterDataByDate();
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    this.scrollPosition = target.scrollLeft;
    this.scrollPositionY = target.scrollTop;
  }

  ngAfterViewInit() {
    this.father.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );

    this.adjustHeights();
  }

  adjustHeights() {
    const div1Elements = this.driverArray.toArray();
    const div2Elements = this.servicesArray.toArray();

    div2Elements.forEach((servicesArray, index) => {
      const div1 = div1Elements[index];

      const div2Height = servicesArray.nativeElement.offsetHeight;
      this.renderer.setStyle(div1.nativeElement, 'height', `${div2Height}px`);
    });
  }

  ngOnDestroy() {
    this.servicesSvc.ganttDataParams = {
      zoneId: this.selectedZone,

      searchTerm: this.searchTerm,
      date: this.selectedDate,
    };
  }

  ngAfterViewChecked() {
    if (this.ganttData.length) {
      if (this.currentTimeStyle) {
        const div = document.querySelector(
          '.gantt-main-container'
        ) as HTMLDivElement;
        if (div) {
          const containerWidth = div.offsetWidth ?? 500;
          div.scrollLeft =
            this.getCurrentTimeScrollingWidth() - containerWidth / 2;
        }
        this.currentTimeStyle = false;
      }
      this.adjustHeights();
    } else {
      this.currentTimeStyle = true;
    }

    /*   const x = this.getCurrentTimeStyle()
    this.currentTimeStyle = {...x} ; */
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {}

  /**Data functions */
  getServiceType(id?: number) {
    /*       if (!id) return 'Desconocido';
      return this.getValuesService.getServiceTypeString(id); */
  }

  getHoursOfDay(startHour: number, endHour: number) {
    let times: string[] = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = i < 10 ? '0' + i : i.toString();
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
    }
    return times;
  }

  getCurrentTimeScrollingWidth(): number {
    const mainContainer = document.querySelector(
      '.gantt-main-container'
    ) as HTMLDivElement;

    let currentTime = this.getCurrentTimeInDecimal();
    if (currentTime > this.ganttEndTime) {
      currentTime = this.ganttEndTime;
    }

    let startTimeDiff = currentTime - this.ganttStartTime;

    return startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2;
  }

  /**Logic functions */
  getCurrentTimeInDecimal() {
    const now = new Date();
    return now.getHours() + now.getMinutes() / 60;
  }

  getStatusStyle(task: any, type?: any) {
    if (type != 'OPERATIVE_NODE') {
      return 'gantt-task-extra';
    } else {
      switch (task.orderStatus) {
        case 'PENDING':
          return 'gantt-task-pending';
        case 'CONFIRMED':
          return 'gantt-task-confirmed';
        case 'DELIVERING':
          return 'gantt-task-inProgress';
        case 'IN_PROGRESS':
        case 4:
        case 5:
          return 'gantt-task-inProgress';
        case 'PICKing_UP':
        case 4:
        case 5:
          return 'gantt-task-inProgress';
        case 'FINISHED':
          return 'gantt-task-finished';
        case 'FAILED':
        case 'CANCELLED_BY_CAFLER':
        case 'CANCELLED_BY_CLIENT':
          return 'gantt-task-cancelled';
        default:
          return 'gantt-task-confirmed';
      }
    }
  }

  getIcon(task: any, type?: any) {
    if (type != 'OPERATIVE_NODE') {
      switch (type) {
        case 'SWEEPER_NODE':
          return '../../../assets/icons/gantt-icons/Type=initial_trans.svg';

        case 'TRANSPORT_NODE':
          return '../../../assets/icons/gantt-icons/Type=public_trans.svg';
        default:
          return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
      }
    } else {
      switch (task) {
        case 'TRANSFER':
          return '../../../assets/icons/gantt-icons/Type=Transfer.svg';
        case 'MECHANICAL_INSPECTION':
          return '../../../assets/icons/gantt-icons/Type=Falta.svg';
        case 'VEHICLE_WASH':
          return '../../../assets/icons/gantt-icons/Type=Lavados.svg';
        case 'REFUELING':
          return '../../../assets/icons/gantt-icons/Type=Repostaje.svg';
        case 'PRE_TECHNICAL_INSPECTION':
          return '../../../assets/icons/gantt-icons/Type=Pre_ITV.svg';
        case 'LONG_DISTANCE_TRANSFER':
          return '../../../assets/icons/gantt-icons/Type=Media_y_larga_distancia.svg';
        case 'TECHNICAL_INSPECTION':
          return '../../../assets/icons/gantt-icons/Type=ITV.svg';
        case 'VEHICLE_INSURANCE':
          return '../../../assets/icons/gantt-icons/Type=Seguros.svg';
        case 'ACCESSORY':
          return '../../../assets/icons/gantt-icons/Type=Accesorio.svg';
        case 'VALET':
          return '../../../assets/icons/gantt-icons/Type=Valet.svg';
        case 'TYRES':
          return '../../../assets/icons/gantt-icons/Type=Neumaticos.svg';
        case 'FORMALITIES':
          return '../../../assets/icons/gantt-icons/Type=Tramites.svg';
        case 'PARKING':
          return '../../../assets/icons/gantt-icons/Type=Parking.svg';
        case 'REPLACEMENT_VEHICLE':
          return '../../../assets/icons/gantt-icons/Type=Vehiculo_sustitucion.svg';
        case 'TOW_TRUCK':
          return '../../../assets/icons/gantt-icons/Type=Grua.svg';
        case 'WHATEVER_YOU_WANT':
          return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
        default:
          return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
      }
    }
  }

  getPositionStyle(task: any, tasks?: any): { [key: string]: string } {
    const mainContainer = document.querySelector(
      '.gantt-main-container'
    ) as HTMLDivElement;

    let startTimeDiff =
      this.convertToHourFormat(task.serviceStartDate) - this.ganttStartTime;
    let endTimeDiff =
      this.convertToHourFormat(task.serviceEndDate) -
      this.convertToHourFormat(task.serviceStartDate);

    let left =
      startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2 + 'px';
    let width = endTimeDiff * (this.rangeWidth * 2) + 'px';
    let minWidth = width;
    if (tasks && tasks.length > 1) {
      let position = 'relative';
      return {
        left,
        width,
        minWidth,
        position,
      };
    } else {
      return {
        left,
        width,
        minWidth,
      };
    }
  }

  getCurrentTimeStyle(): { [key: string]: string } {
    const left = this.getCurrentTimeScrollingWidth() + 'px';
    return {
      left,
    };
  }

  openModal(serviceDataModal: TemplateRef<void>, serviceData: any) {
    console.log(serviceData);
    this.serviceData = serviceData;
    this.bsModalRef = this.modalService.show(serviceDataModal);
  }

  filterData() {
    if (this.searchTerm) {
      const ganttCopy = [...this.ganttDataOriginal];

      const driverName = this.searchTerm.toLowerCase();

      this.ganttData = ganttCopy.filter((element: any) => {
        const driver = element.driver[0];
        return (
          driver.fullName && driver.fullName.toLowerCase().includes(driverName)
        );
      });
    } else {
      this.ganttData = this.ganttDataOriginal;
    }
  }

  async filterDataBySelect() {
    if (this.selectedZone) {
      const selectDate = new Date(this.selectedDate);

      const day = String(selectDate.getDate()).padStart(2, '0');
      const month = String(selectDate.getMonth() + 1).padStart(2, '0');
      const year = String(selectDate.getFullYear());

      const formattedDate = `${day}/${month}/${year}`;
      const unix = this.dateToUnix(formattedDate);
      const serviceData = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };

      try {
        const res: any = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        this.getDrivers();
        console.log(this.ganttData, 'RES');
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }
  }

  convertToHourFormat(seconds: any) {
    const convertSeconds = parseInt(seconds.seconds, 10);

    const hour = convertSeconds / 3600;

    return parseFloat(hour.toFixed(1));
  }

  dateToUnix(dateString: any) {
    return moment.utc(dateString, 'DD/MM/YYYY').startOf('day').unix();
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  async filterDataByDate() {
    this.showLoader();
    if (this.selectedDate && this.selectedZone) {
      this.selectedDate = new Date(this.selectedDate);
      const day = String(this.selectedDate.getDate()).padStart(2, '0');
      const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0');
      const year = String(this.selectedDate.getFullYear());
      const formattedDate = `${day}/${month}/${year}`;
      const unix = this.dateToUnix(formattedDate);

      const serviceData = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };
      try {
        const res: any = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        this.ganttDataOriginal = [];
        await this.getDrivers();

        // console.log(this.ganttData, 'RES');
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }
    this.searchTerm = '';
    console.log(this.ganttData, 'node');
    this.ganttDataOriginal.forEach((node: any) => {
      node.value.data = this.agruparPorHorario2(node.value.data);
    });

    this.hideLoader();
  }

  unixToDate(unixTimestamp: any) {
    return moment.unix(unixTimestamp).utc().format('DD/MM/YYYY');
  }
  async getDrivers() {
    await this.ganttData.forEach((element: any) => {
      this.servicesSvc.getDriver(element.key).subscribe(async (res: any) => {
        const driverError = [
          { fullName: 'Conductor no asignado', operatorType: 'Sin tipo' },
        ];
        element.driver =
          res.drivers && res.drivers.length > 0 ? res.drivers : driverError;
        await element.value.data.forEach((service: any) => {
          service.tranformDateStart = this.formatTime(
            service.serviceStartDate.seconds
          );
          service.tranformDateEnd = this.formatTime(
            service.serviceEndDate.seconds
          );
        });
      });

      this.ganttDataOriginal = [...this.ganttData];
    });
  }

  // async convertData() {
  //   await this.ganttData.forEach((element: any) => {
  //     this.servicesSvc.getDriver(element.key).subscribe(async (res: any) => {

  //       await res.value.data.forEach((service: any) => {
  //         service.tranformDateStart = this.convertToHourFormat(
  //           service.serviceStartDate
  //         );
  //         service.tranformDateEnd = this.convertToHourFormat(
  //           service.serviceEndDate
  //         );
  //       });
  //     });

  //     this.ganttDataOriginal = [...this.ganttData];

  //     console.log(this.ganttDataOriginal, 'USA2');
  //   });
  // }

  getVertical(verticalType: string) {
    const selectedVertical = this.verticals.find(
      (vertical) => vertical.type === verticalType
    );

    return selectedVertical.name;
  }

  formatTime(seconds: any) {
    const date = new Date(parseInt(seconds) * 1000); // Convertir a milisegundos
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
      2,
      '0'
    )}`;
  }

  agruparPorHorario(data: any) {
    // Convertir el tiempo "HH:MM" a minutos desde el inicio del día
    function convertirTiempoAminutos(tiempo: any) {
      if (typeof tiempo !== 'string') {
        console.error('Tiempo no válido:', tiempo);
        return 0; // Si no es una cadena, devolvemos 0 minutos
      }

      const [horas, minutos] = tiempo.split(':').map(Number);
      return horas * 60 + minutos;
    }

    // Comparar si dos objetos se solapan en sus intervalos de tiempo
    const seSuperponen = (obj1: any, obj2: any) => {
      const inicio1 = convertirTiempoAminutos(
        this.formatTime(obj1.serviceStartDate.seconds)
      );
      const fin1 = convertirTiempoAminutos(
        this.formatTime(obj1.serviceEndDate.seconds)
      );
      const inicio2 = convertirTiempoAminutos(
        this.formatTime(obj2.serviceStartDate.seconds)
      );
      const fin2 = convertirTiempoAminutos(
        this.formatTime(obj2.serviceEndDate.seconds)
      );

      // Los intervalos se solapan si uno empieza antes de que termine el otro
      return inicio1 < fin2 && inicio2 < fin1;
    };

    // Array para almacenar los grupos
    const grupos = [];

    // Iterar sobre cada objeto en el array
    for (let i = 0; i < data.length; i++) {
      let agrupado = false;

      // Verificar si el objeto actual se solapa con algún grupo existente
      for (let j = 0; j < grupos.length; j++) {
        const grupo = grupos[j];
        if (grupo.some((obj) => seSuperponen(obj, data[i]))) {
          grupo.push(data[i]);
          agrupado = true;
          break;
        }
      }

      // Si no se ha agrupado, crear un nuevo grupo
      if (!agrupado) {
        grupos.push([data[i]]);
      }
    }

    return grupos;
  }

  agruparPorHorario2(data: any) {
    // Convertir el tiempo "HH:MM" a minutos desde el inicio del día
    function convertirTiempoAminutos(tiempo: any) {
      const [horas, minutos] = tiempo.split(':').map(Number);
      return horas * 60 + minutos;
    }

    // Función para convertir los segundos a formato "HH:MM"
    function formatTime(seconds: string) {
      const date = new Date(parseInt(seconds) * 1000); // Convertir a milisegundos
      let hours = date.getUTCHours();
      let minutes = date.getUTCMinutes();
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }

    // Compara si el rango de tiempo de dos objetos se solapan
    const seSolapan = (obj1: any, obj2: any) => {
      // Convertir los tiempos de los objetos en minutos desde el inicio del día
      const inicio1 = convertirTiempoAminutos(
        formatTime(obj1.serviceStartDate.seconds)
      );
      const fin1 = convertirTiempoAminutos(
        formatTime(obj1.serviceEndDate.seconds)
      );
      const inicio2 = convertirTiempoAminutos(
        formatTime(obj2.serviceStartDate.seconds)
      );
      const fin2 = convertirTiempoAminutos(
        formatTime(obj2.serviceEndDate.seconds)
      );

      // Los intervalos se solapan si el inicio o el fin de uno está dentro del rango del otro
      return (
        inicio1 < fin2 && fin1 > inicio2 // Solapamiento normal: uno comienza antes de que termine el otro
      );
    };

    // Array para almacenar los grupos
    const grupos = [];

    // Iterar sobre cada objeto en el array
    for (let i = 0; i < data.length; i++) {
      let agrupado = false;

      // Verificar si el objeto actual se solapa con algún grupo existente
      for (let j = 0; j < grupos.length; j++) {
        const grupo = grupos[j];

        // Verificamos si el objeto actual se solapa con algún objeto del grupo
        if (grupo.some((obj) => seSolapan(obj, data[i]))) {
          grupo.push(data[i]);
          agrupado = true;
          break; // Salimos del ciclo una vez agregado al grupo
        }
      }

      // Si no se ha agrupado, crear un nuevo grupo
      if (!agrupado) {
        grupos.push([data[i]]);
      }
    }

    return grupos;
  }
}

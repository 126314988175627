import { Component, ViewChild, Inject, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { NewProviderModalComponent } from 'src/app/pages/providers/new-provider-modal/new-provider-modal.component';

@Component({
  selector: 'app-providers-table',
  templateUrl: './providers-table.component.html',
  styleUrls: ['./providers-table.component.css'],
})
export class ProvidersTableComponent {
  countries = ['España', 'UK', 'Francia'];
  filterCount:number = 0
  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];

  searchTerm: string = '';

  assignerData: any = [
    {
      bussnies_name: 'Taller A',
      provider_id: 'taller_a_id',
      country: 'España',
      zone: 'Madrid',
      schedule_start: '09:00',
      schedule_end: '17:00',
      pause_start: '12:00',
      pause_end: '14:00',
      status: 'activo',
      email: 'tallera@email.com',
      phone: '(+34) 656 34 34 34',
    },
    {
      bussnies_name: 'Garage B',
      provider_id: 'garage_b_id',
      country: 'Francia',
      zone: 'París',
      schedule_start: '08:00',
      schedule_end: '16:00',
      pause_start: '11:00',
      pause_end: '13:00',
      status: 'activo',
      email: 'garageb@email.com',
      phone: '(+33) 1 40 34 34 34',
    },
    {
      bussnies_name: 'Workshop C',
      provider_id: 'workshop_c_id',
      country: 'UK',
      zone: 'Londres',
      schedule_start: '10:00',
      schedule_end: '18:00',
      pause_start: '13:00',
      pause_end: '15:00',
      status: 'activo',
      email: 'workshopc@email.com',
      phone: '(+44) 20 7946 0344',
    },
    {
      bussnies_name: 'Taller D',
      provider_id: 'taller_d_id',
      country: 'España',
      zone: 'Barcelona',
      schedule_start: '09:30',
      schedule_end: '17:30',
      pause_start: '12:30',
      pause_end: '14:30',
      status: 'activo',
      email: 'tallerd@email.com',
      phone: '(+34) 659 34 34 34',
    },
    {
      bussnies_name: 'Garage E',
      provider_id: 'garage_e_id',
      country: 'Francia',
      zone: 'Lyon',
      schedule_start: '08:30',
      schedule_end: '16:30',
      pause_start: '11:30',
      pause_end: '13:30',
      status: 'activo',
      email: 'garagee@email.com',
      phone: '(+33) 4 72 10 30 30',
    },
    {
      bussnies_name: 'Workshop F',
      provider_id: 'workshop_f_id',
      country: 'UK',
      zone: 'Manchester',
      schedule_start: '07:00',
      schedule_end: '15:00',
      pause_start: '10:00',
      pause_end: '12:00',
      status: 'activo',
      email: 'workshopf@email.com',
      phone: '(+44) 161 234 5000',
    },
    {
      bussnies_name: 'Taller G',
      provider_id: 'taller_g_id',
      country: 'España',
      zone: 'Valencia',
      schedule_start: '09:00',
      schedule_end: '17:00',
      pause_start: '12:00',
      pause_end: '14:00',
      status: 'activo',
      email: 'tallerg@email.com',
      phone: '(+34) 662 34 34 34',
    },
    {
      bussnies_name: 'Garage H',
      provider_id: 'garage_h_id',
      country: 'Francia',
      zone: 'Marsella',
      schedule_start: '08:00',
      schedule_end: '16:00',
      pause_start: '11:00',
      pause_end: '13:00',
      status: 'activo',
      email: 'garageh@email.com',
      phone: '(+33) 4 91 55 11 11',
    },
    {
      bussnies_name: 'Workshop I',
      provider_id: 'workshop_i_id',
      country: 'UK',
      zone: 'Birmingham',
      schedule_start: '10:00',
      schedule_end: '18:00',
      pause_start: '13:00',
      pause_end: '15:00',
      status: 'activo',
      email: 'workshopi@email.com',
      phone: '(+44) 121 675 2000',
    },
    {
      bussnies_name: 'Taller J',
      provider_id: 'taller_j_id',
      country: 'España',
      zone: 'Sevilla',
      schedule_start: '09:30',
      schedule_end: '17:30',
      pause_start: '12:30',
      pause_end: '14:30',
      status: 'activo',
      email: 'tallerj@email.com',
      phone: '(+34) 665 34 34 34',
    },
    {
      bussnies_name: 'Garage K',
      provider_id: 'garage_k_id',
      country: 'Francia',
      zone: 'Toulouse',
      schedule_start: '08:30',
      schedule_end: '16:30',
      pause_start: '11:30',
      pause_end: '13:30',
      status: 'activo',
      email: 'garagek@email.com',
      phone: '(+33) 5 62 27 10 10',
    },
    {
      bussnies_name: 'Workshop L',
      provider_id: 'workshop_l_id',
      country: 'UK',
      zone: 'Edimburgo',
      schedule_start: '07:00',
      schedule_end: '15:00',
      pause_start: '10:00',
      pause_end: '12:00',
      status: 'activo',
      email: 'workshopl@email.com',
      phone: '(+44) 131 200 2000',
    },
    {
      bussnies_name: 'Taller M',
      provider_id: 'taller_m_id',
      country: 'España',
      zone: 'Bilbao',
      schedule_start: '09:00',
      schedule_end: '17:00',
      pause_start: '12:00',
      pause_end: '14:00',
      status: 'activo',
      email: 'tallerm@email.com',
      phone: '(+34) 668 34 34 34',
    },
    {
      bussnies_name: 'Garage N',
      provider_id: 'garage_n_id',
      country: 'Francia',
      zone: 'Niza',
      schedule_start: '08:00',
      schedule_end: '16:00',
      pause_start: '11:00',
      pause_end: '13:00',
      status: 'activo',
      email: 'garagen@email.com',
      phone: '(+33) 4 93 13 13 13',
    },
    {
      bussnies_name: 'Workshop O',
      provider_id: 'workshop_o_id',
      country: 'UK',
      zone: 'Glasgow',
      schedule_start: '10:00',
      schedule_end: '18:00',
      pause_start: '13:00',
      pause_end: '15:00',
      status: 'activo',
      email: 'workshopo@email.com',
      phone: '(+44) 141 287 2000',
    },
    {
      bussnies_name: 'Taller P',
      provider_id: 'taller_p_id',
      country: 'España',
      zone: 'Zaragoza',
      schedule_start: '09:30',
      schedule_end: '17:30',
      pause_start: '12:30',
      pause_end: '14:30',
      status: 'activo',
      email: 'tallerp@email.com',
      phone: '(+34) 671 34 34 34',
    },
    {
      bussnies_name: 'Garage Q',
      provider_id: 'garage_q_id',
      country: 'Francia',
      zone: 'Bordeaux',
      schedule_start: '08:30',
      schedule_end: '16:30',
      pause_start: '11:30',
      pause_end: '13:30',
      status: 'activo',
      email: 'garageq@email.com',
      phone: '(+33) 5 56 00 66 00',
    },
    {
      bussnies_name: 'Workshop R',
      provider_id: 'workshop_r_id',
      country: 'UK',
      zone: 'Liverpool',
      schedule_start: '07:00',
      schedule_end: '15:00',
      pause_start: '10:00',
      pause_end: '12:00',
      status: 'activo',
      email: 'workshopr@email.com',
      phone: '(+44) 151 233 3000',
    },
    {
      bussnies_name: 'Taller S',
      provider_id: 'taller_s_id',
      country: 'España',
      zone: 'Málaga',
      schedule_start: '09:00',
      schedule_end: '17:00',
      pause_start: '12:00',
      pause_end: '14:00',
      status: 'activo',
      email: 'tallers@email.com',
      phone: '(+34) 674 34 34 34',
    },
    {
      bussnies_name: 'Garage T',
      provider_id: 'garage_t_id',
      country: 'Francia',
      zone: 'Strasbourg',
      schedule_start: '08:00',
      schedule_end: '16:00',
      pause_start: '11:00',
      pause_end: '13:00',
      status: 'activo',
      email: 'garaget@email.com',
      phone: '(+33) 3 88 60 00 00',
    },
  ];

  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  countrys!: any[];
  selectedBussnies: string = '#';
  selectedHour: string = '#';
  selectedZone: string = '#';
  sortedColumn: string = '';
  sortDirection!: any;
  index: number = 0;
  length!: number;
  pageSize: number = 6;
  readonly columns = [
    'bussnies_name',
    'provider_id',
    'country',
    'zone',
    'schedule',
    'pause',
    'status',
    'email',
    'phone',
    'actions',
  ];

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  open = false;
  selected = null;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.length = Math.ceil(this.assignerData.length / this.pageSize);
    this.paginatedDataOperation();
    this.zones = [...new Set(this.assignerData.map((item: any) => item.zone))];
    this.countrys = [
      ...new Set(this.assignerData.map((item: any) => item.country)),
    ];
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    this.filterCount = this.assignerData.length
    const start = this.index * this.pageSize;
    this.paginatedData = this.assignerData.slice(start, start + this.pageSize);
  }

  openModal(provider: any) {
    const offcanvasRef = this.offcanvasService.open(NewProviderModalComponent, {
      backdrop: 'static',
      // scroll: true,
      position: 'end',
    });
    offcanvasRef.componentInstance.name = 'World';
    offcanvasRef.componentInstance.provider_info = provider;
    offcanvasRef.componentInstance.type_info = 'ed';
    /*         offcanvasRef.componentInstance.base_info = this.base_info;
        offcanvasRef.componentInstance.type_info = 'du'; */
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  filterData(searchText: string) {
    this.index = 0;
    // this.showLoader();
    if (!searchText) {
      this.searchTerm = '';
      this.length = Math.ceil(this.assignerData.length / this.pageSize);
      this.paginatedData = this.assignerData;

      if (this.selectedBussnies != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.bussnies_name === this.selectedBussnies
        );
      }
      // if (this.selectedHour != '#') {
      //    this.paginatedData =  this.paginatedData.filter((item:any) => item.bussnies_name === this.selectedHour);
      // }
      if (this.selectedZone != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.zone === this.selectedZone
        );
      }
      this.filterCount = this.paginatedData.length
      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    } else {
      this.searchTerm = searchText;
      this.paginatedData = this.assignerData;
      if (this.selectedBussnies != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.bussnies_name === this.selectedBussnies
        );
      }
      // if (this.selectedHour != '#') {
      //    this.paginatedData =  this.paginatedData.filter((item:any) => item.bussnies_name === this.selectedHour);
      // }
      if (this.selectedZone != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.zone === this.selectedZone
        );
      }

      this.paginatedData = this.paginatedData.filter((item: any) => {
        const bussnies_na = item.bussnies_name.toLowerCase();
        const search = this.searchTerm.toLowerCase();
        return bussnies_na.includes(search);
      });
      this.filterCount = this.paginatedData.length
      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    }
    // setTimeout(()=>{
    //     this.hideLoader();
    // },3000);
  }

  filterDataBySelect(index?: any) {
    this.index = index ?? 0;
    this.paginatedData = this.assignerData;

    this.paginatedData = this.assignerData.filter((item: any) => {
      const businessName = item.bussnies_name.toLowerCase();
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

      const matchesSearch = !this.searchTerm || businessName.includes(search);

      const matchesBusiness =
        this.selectedBussnies === '#' ||
        item.bussnies_name === this.selectedBussnies;

      const matchesZone =
        this.selectedZone === '#' || item.zone === this.selectedZone;

      return matchesSearch && matchesBusiness && matchesZone;
    });
    this.sortByExec();
    this.applyPagination();
  }

  applyPagination() {
    this.filterCount = this.paginatedData.length
    const start = this.index * this.pageSize;
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

    if (this.length == 1) {
      this.index = 0;
    }
  }

  onSort(event: any): void {
    this.sortDirection = event;
  }

  sortData(): void {
    this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  setSortColumn(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }
}

import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.css'],
})
export class PricesTableComponent {
  countries = ['España', 'UK', 'Francia'];

  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];

  assignerData: any = [];

  searchTerm: string = '';

  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  products!: any[];
  types!: any[];
  selectedDate: string = '#';
  selectedTypeClient: string = '#';
  selectedZone: string = '#';
  selectedProduct: string = '#';

  index: number = 0;
  length!: number;
  pageSize: number = 50;
  openedDiv: any = '#';
  readonly columns = [
    'vertical',
    'movi_b2c',
    'prov_b2c',
    'movi_b2b',
    'prov_b2b',
    'movi_pre',
    'prov_pre',
    'movi_hyb',
    'prov_hyb',
    'actions',
  ];

  readonly columns_tarifas = ['fees', 'price', 'actions'];

  // verticalsTypes = [
  //   { value: 1, name: 'TRANSFER', realName: 'Transfer' },
  //   { value: 2, name: 'MECHANICAL_INSPECTION', realName: 'Taller' },
  //   { value: 3, name: 'VEHICLE_WASH', realName: 'Lavado' },
  //   { value: 4, name: 'REFUELING', realName: 'Repostaje' },
  //   { value: 5, name: 'PRE_TECHNICAL_INSPECTION', realName: 'Pre-ITV' },
  //   {
  //     value: 6,
  //     name: 'LONG_DISTANCE_TRANSFER',
  //     realName: 'Transfer Larga Distancia',
  //   },
  //   { value: 7, name: 'TECHNICAL_INSPECTION', realName: 'ITV' },
  //   { value: 8, name: 'VEHICLE_INSURANCE', realName: 'Seguro' },
  //   { value: 10, name: 'ACCESSORY', realName: 'Cafler Fresh' },
  //   { value: 11, name: 'VALET', realName: 'Valet' },
  //   { value: 12, name: 'TYRES', realName: 'Neumáticos' },
  //   { value: 13, name: 'FORMALITIES', realName: 'Trámites' },
  //   { value: 14, name: 'PARKING', realName: 'Parking' },
  //   {
  //     value: 15,
  //     name: 'REPLACEMENT_VEHICLE',
  //     realName: 'Vehículo de sustitución',
  //   },
  //   { value: 16, name: 'TOW_TRUCK', realName: 'Grúa' },
  //   { value: 17, name: 'WHATEVER_YOU_WANT', realName: 'Pide lo que quieras' },
  // ];

  verticalsTypes = [
    { value: 1, name: 'Transfer', realName: 'transfer' },
    {
      value: 2,
      name: 'MechanicalInspection',
      realName: 'mechanical_inspection',
    },
    { value: 3, name: 'VehicleWash', realName: 'vehicle_wash' },
    { value: 4, name: 'Refueling', realName: 'refueling' },
    {
      value: 5,
      name: 'PreTechnicalInspection',
      realName: 'pre_technical_inspection',
    },
    {
      value: 6,
      name: 'LongDistanceTransfer',
      realName: 'long_distance_transfer',
    },
    { value: 7, name: 'TechnicalInspection', realName: 'technical_inspection' },
    { value: 8, name: 'VehicleInsurance', realName: 'vehicle_insurance' },
    { value: 10, name: 'Accessory', realName: 'accessory' },
    { value: 11, name: 'Valet', realName: 'valet' },
    { value: 12, name: 'Tyres', realName: 'tyres' },
    { value: 13, name: 'Formalities', realName: 'formalities' },
    { value: 14, name: 'Parking', realName: 'parking' },
    { value: 15, name: 'ReplacementVehicle', realName: 'replacement_vehicle' },
    { value: 16, name: 'TowTruck', realName: 'tow_truck' },
    { value: 17, name: 'WhateverYouWant', realName: 'whatever_you_want' },
    { value: 18, name: 'VehicleStorage', realName: 'vehicle_storage' },
  ];

  feeds: { feeName: string; feePrice: { units: string; nanos: number } }[] = [];
  verticalDetails: any[] = [];

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  open = false;
  selected = null;
  activeZone: any;
  activeEditPrice: any;
  priceToEdit: any;
  activeEditProduct: any;
  priceToEditb2c1: any;
  priceToEditb2c2: any;
  priceToEditb2b1: any;
  priceToEditb2b2: any;
  priceToEditpre1: any;
  priceToEditpre2: any;
  priceToEdithyb1: any;
  priceToEdithyb2: any;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }
  bsModalRef?: BsModalRef;
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    public hs: HistoryServicesService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.hs.getZones().subscribe((res: any) => {
      this.zones = res.zones;
    });

    this.hs.subscribe('updateGeneralPrices', () => {
      this.filterDataByZone();
    });
  }

  filterDataByZone() {
    this.hs.getGeneralPrices(this.activeZone).subscribe((res: any) => {
      this.feeds = res.feesDetails;
      this.verticalDetails = res.verticalDetails;
    });
  }

  showDepositAlert(): void {
    this.alerts
      .open(
        'Se han guardado los cambios realizados en los precios aplciables!',
        {
          label: 'Se han guardado los cambios',
          status: 'success',
          autoClose: 5000,
        }
      )
      .subscribe();
  }

  getCountProducts(type: any): any {
    if (this.products) {
      let cp = this.products.find((pro) => pro.verticalType == type);
      if (cp != undefined) {
        return this.products.filter((pro) => pro.verticalType == type)?.length;
      }
      return 0;
    }
  }

  getVerticalProducts(type: any): any {
    if (this.products) {
      let cp = this.products.find((pro) => pro.verticalType == type);
      if (cp != undefined) {
        return this.products.filter((pro) => pro.verticalType == type);
      }
      return [];
    }
  }

  getTipoDeDato(valor: any): string {
    if (valor === null) {
      return 'null';
    }
    return typeof valor;
  }

  generateRandomValue(): string {
    const randomValue = (Math.random() * 50).toFixed(2);
    const [integer, decimal] = randomValue.split('.');
    const formattedInteger = integer.padStart(2, '0');
    return `${formattedInteger}.${decimal}`;
  }

  setOpenedDiv(value: any) {
    this.openedDiv = value;
  }

  closeOpenedDiv(value: any) {
    this.openedDiv = 0;
  }

  openModal(serviceDataModal: TemplateRef<void>, type: any, price: any) {
    this.activeEditPrice = type;
    this.priceToEdit = price;
    this.bsModalRef = this.modalService.show(serviceDataModal);
  }

  openModalProduct(editProductModal: TemplateRef<void>, product: any) {
    this.activeEditProduct = product.productKey;

    this.priceToEditb2c1 = this.formatFeePrice(product.b2cMobilityPrice);
    this.priceToEditb2c2 = this.formatFeePrice(product.b2cProviderPrice);
    this.priceToEditb2b1 = this.formatFeePrice(product.b2bMobilityPrice);
    this.priceToEditb2b2 = this.formatFeePrice(product.b2bProviderPrice);
    this.priceToEditpre1 = this.formatFeePrice(product.premiumMobilityPrice);
    this.priceToEditpre2 = this.formatFeePrice(product.premiumProviderPrice);
    this.priceToEdithyb1 = this.formatFeePrice(product.hybridMobilityPrice);
    this.priceToEdithyb2 = this.formatFeePrice(product.hybridProviderPrice);
    this.bsModalRef = this.modalService.show(editProductModal);
  }

  getVerticalRealName(name: any) {
    let vf = this.verticalsTypes.find((vr) => vr.name == name);
    return vf ? vf.realName : '';
  }

  convertToPriceFormat(price: any) {
    const numericPrice = Number(price);

    if (isNaN(numericPrice)) {
      return 'No se puede';
    }

    const units = Math.floor(numericPrice).toString();
    const nanos = Math.round((numericPrice - Math.floor(numericPrice)) * 1e9);

    return { units, nanos };
  }

  showLoaderModal(search: any) {
    const div = document.querySelector('.' + search) as HTMLElement;
    if (div) {
      div.style.display = 'flex';
    }
  }

  hideLoaderModal(search: any) {
    const div = document.querySelector('.' + search) as HTMLElement;
    if (div) {
      div.style.display = 'none';
    }
  }

  saveModalFeeds() {
    let data = {
      zoneId: this.activeZone,
      feeToUpdate: {
        feeName: this.activeEditPrice,
        newPrice: this.convertToPriceFormat(this.priceToEdit),
      },
    };
    this.showLoaderModal('div_inside_edit_general_price');
    this.hs.updateGeneralPrice(data).subscribe(
      (res: any) => {
        if (!res.wasSuccessful && res?.statusCode) {
          this.hs.showErrorCode(res.statusCode);
        } else {
          this.bsModalRef?.hide();
          this.showDepositAlert();
          this.hs.publish('updateGeneralPrices');
        }
        this.hideLoaderModal('.div_inside_edit_general_price');
      },
      (error2: any) => {
        this.hideLoaderModal('.div_inside_edit_general_price');
        if (error2.status === 500) {
          this.alerts
            .open('Problema en el servidor', {
              label: 'Error 500',
              status: 'error',
              autoClose: false,
            })
            .subscribe();
        } else {
          this.alerts
            .open(error2, {
              label: 'Otro error',
              status: 'error',
              autoClose: false,
            })
            .subscribe();
        }
      }
    );
    // this.bsModalRef?.hide();
    // this.showDepositAlert();
  }

  saveModalPrices() {
    let data = {
      zoneId: this.activeZone,
      productToUpdate: {
        verticalName: this.openedDiv,
        productData: {
          productKey: this.activeEditProduct,
          b2bMobilityPrice: this.convertToPriceFormat(this.priceToEditb2b1),
          b2bProviderPrice: this.convertToPriceFormat(this.priceToEditb2b2),
          b2cMobilityPrice: this.convertToPriceFormat(this.priceToEditb2c1),
          b2cProviderPrice: this.convertToPriceFormat(this.priceToEditb2c2),
          hybridMobilityPrice: this.convertToPriceFormat(this.priceToEdithyb1),
          hybridProviderPrice: this.convertToPriceFormat(this.priceToEdithyb2),
          premiumMobilityPrice: this.convertToPriceFormat(this.priceToEditpre1),
          premiumProviderPrice: this.convertToPriceFormat(this.priceToEditpre2),
        },
      },
    };
    console.log(data);
    this.showLoaderModal('div_inside_edit_product_prices');
    this.hs.updateGeneralProductPrice(data).subscribe(
      (res: any) => {
        if (!res.wasSuccessful && res?.statusCode) {
          this.hs.showErrorCode(res.statusCode);
        } else {
          this.bsModalRef?.hide();
          this.showDepositAlert();
          this.hs.publish('updateGeneralPrices');
        }
        this.hideLoaderModal('.div_inside_edit_product_prices');
      },
      (error2: any) => {
        this.hideLoaderModal('.div_inside_edit_product_prices');
        if (error2.status === 500) {
          this.alerts
            .open('Problema en el servidor', {
              label: 'Error 500',
              status: 'error',
              autoClose: false,
            })
            .subscribe();
        } else {
          this.alerts
            .open(error2, {
              label: 'Otro error',
              status: 'error',
              autoClose: false,
            })
            .subscribe();
        }
      }
    );
  }

  formatFeePrice(feePrice: { units: any; nanos: number }): string {
    if (!feePrice) return '0.00';
    const amount = Number(feePrice.units) + Number(feePrice.nanos) / 1e9;
    return amount.toFixed(2);
  }

  validateNumberInput(event: KeyboardEvent): void {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const inputChar = event.key;

    if (!allowedKeys.includes(inputChar)) {
      event.preventDefault();
    }

    // Evitar múltiples puntos decimales
    const inputElement = event.target as HTMLInputElement;
    if (inputChar === '.' && inputElement.value.includes('.')) {
      event.preventDefault();
    }
  }

  validatePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text') || '';
    if (!/^\d*\.?\d*$/.test(clipboardData)) {
      event.preventDefault();
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';

import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { AssignerService } from 'src/app/services/assigner/assigner.service';
import { ServicesService } from 'src/app/services/services.service';
import {TranslateService} from '@ngx-translate/core';
declare var moment: any;
@Component({
  selector: 'app-modal-add-assignament',
  templateUrl: './modal-add-assignament.component.html',
  styleUrls: ['./modal-add-assignament.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAddAssignamentComponent {
  constructor(
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private assignerService: AssignerService,
    private servicesSvc: ServicesService,
    private translate: TranslateService,
  ) {}

  operatives = [];

  assignamentForm = this.fb.group({
    zoneId: [null, Validators.required],
    targetDate: ['', Validators.required],
    generalTimeMargin: [20, Validators.required],
    exterProviderTimeMargin: [35, Validators.required],
    extraToppingTimeMargin: [12, Validators.required],
    maxDriverWalkTime: [30, Validators.required],
    maxDriverPublicTransportTime: [120, Validators.required],
    sweepCarRadiusInKilometers: [15, Validators.required],
    sweepCarMaxArrivalTime: [40, Validators.required],
  });
  zones!: any;

  openOperative = false;
  selectedOperative = 'Selecciona previamente País';

  openElement = '';
  readonly columns = ['date', 'hour', 'user', 'event'];
  ngOnInit() {
    this.servicesSvc.getZones().subscribe((res: any) => {
      this.operatives = res.zones.map((zone: any) => zone.zoneName);
      this.zones = res.zones;
    });
    let div = document.getElementsByTagName('tui-hints-host');
    if (div[0]) {
      let cdiv = div[0] as HTMLElement;
      cdiv.style.zIndex = '2000';
    }

    let divhint = document.getElementsByTagName('tui-hint');
    if (divhint[0]) {
      let cdivhint = divhint[0] as HTMLElement;
      cdivhint.style.background = '#0F1113 !important';
      cdivhint.style.color = '#fff !important';
    }

    this.assignamentForm.get('zoneId')?.valueChanges.subscribe((value) => {
      if (value) {
        this.openElement = '';
      }
    });
  }

  searchDiv(element: any) {
    if (element != this.openElement) {
      let div = document.getElementsByTagName('tui-dropdown');
      if (div[0] != undefined) {
        let content_div = document.getElementById('modal_add_assignament');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
      this.openElement = element;
    } else {
      this.openElement = '';
    }
  }
  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  searchDiv2() {
    setTimeout(() => {
      let div = document.getElementsByClassName(
        'ng-trigger-tuiDropdownAnimation'
      );
      if (div.length > 0) {
        let content_div = document.getElementById('modal_add_assignament');
        let cdiv = content_div as HTMLElement;
        cdiv.innerHTML = '';
        cdiv.append(div[0]);
      }
    }, 200);
  }

  async saveAssignament() {
    const currentTime = moment.utc();

    const assignament: any = { ...this.assignamentForm.value };
    const id = assignament.zoneId;

    assignament.zoneId = this.getZone(assignament.zoneId);

    assignament.generalTimeMargin = {
      seconds: this.dateToAddMinutes(assignament.generalTimeMargin),
    };

    assignament.exterProviderTimeMargin = {
      seconds: this.dateToAddMinutes(assignament.exterProviderTimeMargin),
    };

    assignament.extraToppingTimeMargin = {
      seconds: this.dateToAddMinutes(assignament.extraToppingTimeMargin),
    };

    assignament.maxDriverWalkTime = {
      seconds: this.dateToAddMinutes(assignament.maxDriverWalkTime),
    };

    assignament.maxDriverPublicTransportTime = {
      seconds: this.dateToAddMinutes(assignament.maxDriverPublicTransportTime),
    };

    assignament.sweepCarMaxArrivalTime = {
      seconds: this.dateToAddMinutes(assignament.sweepCarMaxArrivalTime),
    };

    /*     const combinedDateTime = moment.utc(assignament.targetDate).set({
      hour: currentTime.hour(),
      minute: currentTime.minute(),
      second: currentTime.second(),
      millisecond: 0,
    });
 */
    assignament.targetDate = {
      seconds: moment.utc(assignament.targetDate).unix(),
    };
    assignament.sweepCarRadiusInKilometers =
      +assignament.sweepCarRadiusInKilometers;

    const dataToAlert = this.getDateToShow(
      assignament.targetDate,
      assignament.zoneId
    );

    this.assignerService.publish('updateElementsAssign', dataToAlert);
    this.offcanvasService.dismiss();
    try {
      const result = await firstValueFrom(
        this.assignerService.addAssignment(assignament)
      );
      this.assignerService.publish('completeElementsAssign', dataToAlert);
      console.log(result);
    } catch (error) {
      console.error('Error al agregar la asignación:', error);
      this.assignerService.publish('errorElementsAssign', assignament);
      this.offcanvasService.dismiss();
    }
  }

  closeCanvas() {
    this.offcanvasService.dismiss();
  }

  private clickCounts: { [key: string]: number } = {};
  onSelectClick(event: MouseEvent) {
    const selectElement = event.target as HTMLSelectElement;
    const selectId = selectElement.id;
    console.log(event, 44);

    if (!this.clickCounts[selectId]) {
      this.clickCounts[selectId] = 0;
    }
    this.clickCounts[selectId]++;

    if (this.clickCounts[selectId] === 1) {
      setTimeout(() => {
        const sourceElement = document.querySelector('tui-dropdown');
        const targetElement = document.getElementById('modal_add_assignament');
        if (sourceElement && targetElement) {
          const clonedNode = sourceElement.cloneNode(true);
          // targetElement!.innerHTML = '';
          targetElement.appendChild(sourceElement);
        }
      }, 300);
    } else {
      if (this.clickCounts[selectId] === 2) {
        if (document.activeElement === selectElement) {
          selectElement.blur();
          console.log(`Focus quitado del select ${selectId} por segundo clic`);
        }
        this.clickCounts[selectId] = 0;
      }
    }
  }

  resetFormSelects() {
    const entries = Object.entries(this.clickCounts);
    for (const [selectId, count] of entries) {
      this.clickCounts[selectId] = 0;
    }
  }

  onSelectBlur(type: any) {
    if (!type) {
      this.resetFormSelects();
    }
  }
  getZone(zoneName: any) {
    const selectedZone = this.zones?.find(
      (zone: any) => zone.zoneName === zoneName
    );
    return selectedZone.zoneId;
  }
  dateToAddMinutes(minutes: any) {
    const minutesUnix = +minutes * 60;
    return minutesUnix;
  }

  getDateToShow(seconds: any, zoneId: any) {
    const zone = this.zones.find((zone: any) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds.seconds).tz(zone.timeZone);

    return {
      date: dateInClientZone.format('DD/MM/YYYY'),
      zone: '(' + zone.timeZone + ')',
      zoneName: zone.zoneName,
      unix: seconds,
    };
  }
}

import { Injectable } from '@angular/core';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private msalInstance!: PublicClientApplication;
  private tidSubject = new BehaviorSubject<string | null>(null);
  private authInitializedSubject = new BehaviorSubject<boolean>(false);
  private accountSubject = new BehaviorSubject<AccountInfo | null>(null);

  constructor() {
    //this.initMsalInstance();
  }

  public async initMsalInstance(): Promise<void> {
    try {
      this.msalInstance = new PublicClientApplication({
        auth: {
          clientId: '1649588b-cd93-42f9-bbb4-d8db8ce706d8',
          authority: 'https://login.microsoftonline.com/organizations',
          redirectUri: '/',
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true,
        },
      });

      await this.msalInstance.initialize();

      const response = await this.msalInstance.handleRedirectPromise();

      if (response) {
        this.msalInstance.setActiveAccount(response.account);
      } else {
        const accounts = this.msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          console.log('🔐 No hay cuenta activa. Redirigiendo a login...');
          //await this.msalInstance.loginRedirect();
        } else {
          this.loadUserData();
          // console.log(
          //   '✅ Hay cuentas activas:',
          //   this.msalInstance.getAllAccounts()
          // );
          this.msalInstance.setActiveAccount(accounts[0]);
        }
      }
    } catch (error) {
      console.error('Error al inicializar MSAL:', error);
      this.authInitializedSubject.next(false);
    }
  }

  private loadUserData(): void {
    const account = this.msalInstance.getActiveAccount();

    if (account && account.idToken) {
      try {
        const decodedToken: any = jwtDecode(account.idToken);
        const tid = decodedToken?.tid || null;
        this.authInitializedSubject.next(true);
        this.tidSubject.next(tid);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    } else {
      this.tidSubject.next(null);
    }
  }

  isAuthenticated(): boolean {
    const account = this.msalInstance.getActiveAccount();
    return !!account;
  }

  getTenantId(): Observable<string | null> {
    return this.tidSubject.asObservable();
  }

  onAuthInitialized(): Observable<boolean> {
    return this.authInitializedSubject.asObservable();
  }

  getUserProfile(): Observable<AccountInfo | null> {
    return this.accountSubject.asObservable();
  }

  async logout(popup = false): Promise<void> {
    try {
      const account = this.msalInstance.getActiveAccount();

      if (account) {
        if (popup) {
          await this.msalInstance.logoutPopup();
        } else {
          await this.msalInstance.logoutRedirect();
        }

        this.authInitializedSubject.next(false);
      }
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      this.authInitializedSubject.next(false);
    }
  }
}

<table tuiTable size="l" style="width: 100%" [columns]="columns">
  <thead>
    <tr tuiThGroup>
      <th class="th-style" *tuiHead="'check'" tuiTh [sticky]="true">
        <tui-checkbox
          [(ngModel)]="select_all_bids"
          class="tui-space_bottom-3"
        ></tui-checkbox>
      </th>
      <th [sticky]="true" *tuiHead="'price'" tuiTh>Precio</th>
      <th [sticky]="true" *tuiHead="'counterOffer'" tuiTh>Contraoferta</th>
      <th [sticky]="true" *tuiHead="'margen'" tuiTh>Margen</th>
      <th [sticky]="true" *tuiHead="'driver'" tuiTh>Conductor</th>
      <th [sticky]="true" *tuiHead="'date'" tuiTh>Fecha</th>
      <th [sticky]="true" *tuiHead="'hour'" tuiTh>Hora reco</th>
      <th [sticky]="true" *tuiHead="'status'" tuiTh>Estado</th>
      <th [sticky]="true" *tuiHead="'actions'" tuiTh>Acciones</th>
    </tr>
  </thead>
  <tbody tuiTbody>
    <tr tuiTr *ngFor="let da of pooldata">
      <td *tuiCell="'check'" tuiTd>
        <div [tuiCell]="size">
          <tui-checkbox
            [(ngModel)]="select_bid"
            class="tui-space_bottom-3 ml-25"
          ></tui-checkbox>
        </div>
      </td>
      <td *tuiCell="'price'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle> {{ da.price }}€ </span>
        </div>
      </td>
      <td *tuiCell="'counterOffer'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle> {{ da.counterOffer }}€ </span>
        </div>
      </td>
      <td *tuiCell="'margen'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle> {{ da.margen }}% </span>
        </div>
      </td>
      <td *tuiCell="'driver'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle>
            {{ da.driver }}
          </span>
        </div>
      </td>
      <td *tuiCell="'date'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle>
            {{ da.date }}
          </span>
        </div>
      </td>
      <td *tuiCell="'hour'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle>
            {{ da.hour }}
          </span>
        </div>
      </td>
      <td *tuiCell="'status'" tuiTd>
        <div [tuiCell]="size">
          <span tuiTitle *ngIf="da.status == 'pending'">
            <span tuiStatus="var(--tui-warning-fill)">Pendiente</span>
          </span>
          <span tuiTitle *ngIf="da.status == 'accepted'">
            <span tuiStatus="var(--tui-success-fill)">Aceptada</span>
          </span>
          <span tuiTitle *ngIf="da.status == 'rejected'">
            <span tuiStatus="var(--tui-error-fill)">Rechazada</span>
          </span>
        </div>
      </td>
      <td *tuiCell="'actions'" tuiTd>
        <div [tuiCell]="size">
          <tui-icon
            icon="tuiIconEdit2"
            class="icon_edit_assigned_hour"
          ></tui-icon>
        </div>
      </td>
    </tr>
  </tbody>
</table>

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';

import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';
declare var MarkerClusterer: any;
declare var moment: any;
moment.locale('es');
@Component({
  selector: 'app-service-data-modal',
  templateUrl: './service-data-modal.component.html',
  styleUrls: ['./service-data-modal.component.css'],
})
export class ServiceDataModalComponent {
  @Input() bsModalRef!: any;
  @Input() serviceData: any;
  @Input() zoneId: any;
  @Input() zones: any;
  @Input() dateService: any;
  @ViewChild('littleGmapContainer', { static: false }) gmap:
    | ElementRef
    | undefined;
  map: any = google.maps.Map;
  lat = 40.4165;
  lng = -3.70256;
  zoom_level = 6;
  directionsRenderer: any;
  markerCluster: any;
  customMarkers: google.maps.Marker[] = [];
  type_driver_start: any = 'service';
  icon_driver_start: any = '';
  directionsRenderers: google.maps.DirectionsRenderer[] = [];
  coordinates = new google.maps.LatLng(this.lat, this.lng);
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 6,
    disableDefaultUI: true,
    zoomControl: true,
  };
  open = false;
  verticals: any[] = [
    { name: 'Transfer', type: 'TRANSFER' },
    { name: 'Taller', type: 'MECHANICAL_INSPECTION' },
    { name: 'Lavado', type: 'VEHICLE_WASH' },
    { name: 'Repostaje', type: 'REFUELING' },
    { name: 'Pre-ITV', type: 'PRE_TECHNICAL_INSPECTION' },
    { name: 'Transfer Larga Distancia', type: 'LONG_DISTANCE_TRANSFER' },
    { name: 'ITV', type: 'TECHNICAL_INSPECTION' },
    { name: 'Seguro', type: 'VEHICLE_INSURANCE' },
    { name: 'Cafler Fresh', type: 'ACCESSORY' },
    { name: 'Valet', type: 'VALET' },
    { name: 'Neumáticos', type: 'TYRES' },
    { name: 'Trámites', type: 'FORMALITIES' },
    { name: 'Parking', type: 'PARKING' },
    { name: 'Vehículo de sustitución', type: 'REPLACEMENT_VEHICLE' },
    { name: 'Grúa', type: 'TOW_TRUCK' },
    { name: 'Pide lo que quieras', type: 'WHATEVER_YOU_WANT' },
  ];
  modalInstance: any;
  constructor(
    private hs: HistoryServicesService,
    private router: Router,
    private ms: MapaServiceService
  ) {}

  ngOnInit() {}
  mapInitializer() {
    this.map = new google.maps.Map(this.gmap?.nativeElement, this.mapOptions);
  }

  async ngAfterViewInit() {
    this.mapInitializer();
    await this.drawRoutes();
  }
  formatTime(date: any) {
    // console.log(this.zoneId);
    const zone = this.zones.find((zone:any) => zone.zoneId === this.zoneId);
    const dateInClientZone = moment(this.dateService).tz(zone.timeZone);

    let isCh = dateInClientZone.isDST();

    const newDate = new Date(parseInt(date) * 1000);
    let hours = String(newDate.getUTCHours()).padStart(2, '0');
    if(isCh){
        hours = String(newDate.getUTCHours() - 1).padStart(2, '0');
    }
    const minutes = String(newDate.getUTCMinutes()).padStart(2, '0');

        return `${hours}:${minutes}`;
    // return dateInClientZone.format('HH:mm');
  }

  viewDetail(data: any) {
    this.closeModal();
    const datos = { service_info: data };

    this.hs.setData(datos);

    this.router.navigate(['service-manager//detail-service'], {
      queryParams: { orderHash: data.orderHash },
    });
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  getVertical(verticalType: string) {
    const selectedVertical = this.verticals.find(
      (vertical) => vertical.type === verticalType
    );

    return selectedVertical.name;
  }

  async drawRoutes() {
    const directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    let waypts: google.maps.DirectionsWaypoint[] = [];
    let start = {
      lat: this.serviceData.startLatitude,
      lng: this.serviceData.startLongitude,
    };
    let end = {
      lat: this.serviceData.endLatitude,
      lng: this.serviceData.endLongitude,
    };

    this.directionsRenderer.setMap(this.map);

    directionsService.route(
      {
        origin: start,
        destination: end,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response) => {
        this.directionsRenderer.setDirections(response);
        this.directionsRenderers.push(this.directionsRenderer);
        this.addCustomMarkers(response);
      }
    );
  }

  addCustomMarkers(response: any): void {
    const route = response.routes[0];

    const legs = route?.legs;

    this.icon_driver_start = this.getInitial('Cesar Farfan');
    console.log(legs, 'ROUTE');
    for (let i = 0; i < legs.length; i++) {
      const leg = legs[i];

      if (leg.via_waypoints && leg.via_waypoints.length > 0) {
        leg.via_waypoints.forEach((waypoint: any, index: any) => {
          let cc = new google.maps.Marker({
            position: waypoint, // Cada via_waypoint
            map: this.map,
            icon: {
              url: 'assets/img/icon_service3.png', // Ruta a tu ícono personalizado
              scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
            },
            title: '',
          });

          this.customMarkers.push(cc);
        });
      }
      if (i === 0) {
        if (this.type_driver_start == 'service') {
          let pi = new google.maps.Marker({
            position: leg.start_location, // Punto de inicio del primer leg
            map: this.map,
            icon: {
              url: 'assets/img/icon_service.png', // Ruta a tu ícono personalizado
              scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
            },
            title: leg.start_address,
          });
          this.customMarkers.push(pi);
        } else {
          let pi = new google.maps.Marker({
            position: leg.start_location, // Punto de inicio del primer leg
            map: this.map,
            icon: {
              url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
                this.icon_driver_start
              )}`,
              scaledSize: new google.maps.Size(30, 30),
            },
            title: '',
          });
          this.customMarkers.push(pi);
        }
      }

      // Marcar el punto final del leg
      if (i === legs.length - 1) {
        let pf = new google.maps.Marker({
          position: leg.end_location, // Punto final del último leg
          map: this.map,
          icon: {
            url: 'assets/img/icon_service2.png', // Ruta a tu ícono personalizado
            scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
          },
          title: leg.end_address,
        });
        this.customMarkers.push(pf);
      } else {
        let pw = new google.maps.Marker({
          position: leg.end_location,
          map: this.map,
          icon: {
            url: 'assets/img/icon_service3.png', // Ruta a tu ícono personalizado
            scaledSize: new google.maps.Size(30, 30), // Ajusta el tamaño del icono
          },
          title: leg.end_address,
        });
        this.customMarkers.push(pw);
      }
    }

    let cmarkers = this.customMarkers;
    let map = this.map;
    const markerClusterNew = new MarkerClusterer(map, cmarkers, {
      styles: [
        {
          width: 30,
          height: 30,
          className: 'custom-clustericon-1',
        },
        {
          width: 30,
          height: 30,
          className: 'custom-clustericon-2',
        },
        {
          width: 30,
          height: 30,
          className: 'custom-clustericon-3',
        },
      ],
      clusterClass: 'custom-clustericon',
      zoomOnClick: false,
    });
    this.markerCluster = markerClusterNew;
    let infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, 0),
    });

    google.maps.event.addListener(markerClusterNew, 'click', (cluster) => {
      const markers = cluster.getMarkers();
      let content =
        '\
            <table class="table table-bordered" style="width:700px">\
                <thead>\
                </thead>\
                <tbody>';
      markers.forEach((marker: any) => {
        let icon = marker.getIcon();
        if (marker.getTitle() != '') {
          content += `<tr><td><img src="${
            icon.url
          }" width="30" /> ${marker.getTitle()}</td><tr>`;
        }
      });
      content += '</tbody></table>';

      infoWindow.setContent(content);
      infoWindow.setPosition(cluster.getCenter());
      infoWindow.open(map);
    });
  }
  getInitial(name: any) {
    name = name.split(' ');
    return `
        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#59F89C"/>
            <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="17" font-family="Arial">${
              name[0][0] + name[1][0]
            }</text>
        </svg>`;
  }
}

import { Component, Renderer2, TemplateRef } from '@angular/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { TuiAlertService } from '@taiga-ui/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from 'src/app/services/client/client.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-pool-parameters',
  templateUrl: './pool-parameters.component.html',
  styleUrls: ['./pool-parameters.component.css'],
})
export class PoolParametersComponent {
  detailClientData2: any = {
    feesDetails: [
      {
        feeName: 'Fixed Fee',
        generalFeePrice: {
          units: '6',
          nanos: 0,
        },
        discount: {
          units: '0',
          nanos: 0,
        },
        clientFeePrice: {
          units: '6',
          nanos: 0,
        },
      },
      {
        feeName: 'Urgency Fee',
        generalFeePrice: {
          units: '4',
          nanos: 0,
        },
        discount: {
          units: '0',
          nanos: 0,
        },
        clientFeePrice: {
          units: '4',
          nanos: 0,
        },
      },
    ],
    verticalDetails: [
      {
        productDetails: [
          {
            productKey: 'transfer-replacement-vehicle 0-15',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 15-30',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 30-50',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 50-80',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 80-100',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 100-150',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 150-200',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer-replacement-vehicle 200-20000',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 0-15',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 15-30',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 30-50',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 50-80',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 80-100',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 100-150',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 150-200',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'collect 200-20000',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transit 0-15',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transit 15-30',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 0-15',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 15-30',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 30-50',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 50-80',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 80-100',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 100-150',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 150-200',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'transfer 200-20000',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 0-15',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 15-30',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 30-50',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 50-80',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 80-100',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 100-150',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 150-200',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'return 200-20000',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
        ],
        verticalName: 'Transfer',
      },
      {
        productDetails: [
          {
            productKey: 'medium-long-distance 0-80',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'medium-long-distance 80-100',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'medium-long-distance 100-150',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'medium-long-distance 150-200',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'medium-long-distance 200-20000',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '0',
              nanos: 0,
            },
            available: false,
          },
        ],
        verticalName: 'LongDistanceTransfer',
      },
      {
        productDetails: [
          {
            productKey: 'upholstery-detail-wash-5-seats',
            generalProviderPrice: {
              units: '148',
              nanos: 760000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '148',
              nanos: 760000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'integral-washing',
            generalProviderPrice: {
              units: '383',
              nanos: 740000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '383',
              nanos: 740000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'tunnel-complete-car-wash',
            generalProviderPrice: {
              units: '75',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '75',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
          {
            productKey: 'tunnel-exterior-car-wash',
            generalProviderPrice: {
              units: '37',
              nanos: 190000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '37',
              nanos: 190000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'tunnel-interior-car-wash',
            generalProviderPrice: {
              units: '37',
              nanos: 190000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '37',
              nanos: 190000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premium-complete-car-wash',
            generalProviderPrice: {
              units: '75',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '75',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
          {
            productKey: 'premium-exterior-car-wash',
            generalProviderPrice: {
              units: '24',
              nanos: 790000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '24',
              nanos: 790000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premium-interior-car-wash',
            generalProviderPrice: {
              units: '20',
              nanos: 660000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '20',
              nanos: 660000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'tunnel-motorbike-wash',
            generalProviderPrice: {
              units: '37',
              nanos: 190000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '37',
              nanos: 190000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
          {
            productKey: 'headlight-polishing',
            generalProviderPrice: {
              units: '66',
              nanos: 110000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '66',
              nanos: 110000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
        ],
        verticalName: 'VehicleWash',
      },
      {
        productDetails: [
          {
            productKey: 'premot-motorbike-electric-mot',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-hybrid-car-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-motorbike-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: '360-pre-mot',
            generalProviderPrice: {
              units: '58',
              nanos: 360000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 360000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-gas-van-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-electric-van-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-fuel-car-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-motorbike-125cc-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-diesel-van-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-diesel-car-es',
            generalProviderPrice: {
              units: '58',
              nanos: 360000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 360000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-hybrid-van-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-mot-electric-car-es',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'premot-motorbike-electric-mot-125cc',
            generalProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
        ],
        verticalName: 'PreTechnicalInspection',
      },
      {
        productDetails: [
          {
            productKey: '360-mot-hybrid-van-es',
            generalProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-mot-diesel-car-es',
            generalProviderPrice: {
              units: '37',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '37',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'mot-hybrid-car-es',
            generalProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: '360-mot-electric-van-es',
            generalProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'mot-diesel-van-es',
            generalProviderPrice: {
              units: '45',
              nanos: 440000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '45',
              nanos: 440000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-electric-car-es',
            generalProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'motorbike-electric-mot-125cc',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'motorbike-electric-mot',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: '360-mot-hybrid-car-es',
            generalProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-mot-electric-car-es',
            generalProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-mot-diesel-van-es',
            generalProviderPrice: {
              units: '45',
              nanos: 440000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '45',
              nanos: 440000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'mot-motorbike-es',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-hybrid-van-es',
            generalProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: '360-mot-gas-van-es',
            generalProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-motorbike-electric-mot-125cc',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-mot-motorbike-125cc-es',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-motorbike-electric-mot',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-mot-motorbike-es',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: '360-mot-fuel-car-es',
            generalProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: false,
          },
          {
            productKey: 'mot-homologation',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-electric-van-es',
            generalProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-fuel-car-es',
            generalProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '33',
              nanos: 550000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-diesel-car-es',
            generalProviderPrice: {
              units: '37',
              nanos: 680000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '37',
              nanos: 680000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-motorbike-125cc-es',
            generalProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '17',
              nanos: 600000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
          {
            productKey: 'mot-gas-van-es',
            generalProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '40',
              nanos: 40000000,
            },
            generalMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '30',
              nanos: 0,
            },
            available: true,
          },
        ],
        verticalName: 'TechnicalInspection',
      },
      {
        productDetails: [
          {
            productKey: 'refueling-car-topping',
            generalProviderPrice: {
              units: '22',
              nanos: 910000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '22',
              nanos: 910000000,
            },
            generalMobilityPrice: {
              units: '4',
              nanos: 500000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '4',
              nanos: 500000000,
            },
            available: false,
          },
        ],
        verticalName: 'Refueling',
      },
      {
        productDetails: [
          {
            productKey: 'car-service',
            generalProviderPrice: {
              units: '29',
              nanos: 440000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '29',
              nanos: 440000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'motorbike-basic-maintenance',
            generalProviderPrice: {
              units: '72',
              nanos: 720000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '72',
              nanos: 720000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'fine-tunning',
            generalProviderPrice: {
              units: '58',
              nanos: 360000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '58',
              nanos: 360000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'motorbike-complete-maintenance',
            generalProviderPrice: {
              units: '95',
              nanos: 30000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '95',
              nanos: 30000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'car-complete-maintenance',
            generalProviderPrice: {
              units: '198',
              nanos: 340000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '198',
              nanos: 340000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'car-basic-maintenance',
            generalProviderPrice: {
              units: '78',
              nanos: 500000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '78',
              nanos: 500000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
          {
            productKey: 'car-medium-maintenance',
            generalProviderPrice: {
              units: '103',
              nanos: 300000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '103',
              nanos: 300000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
        ],
        verticalName: 'MechanicalInspection',
      },
      {
        productDetails: [
          {
            productKey: 'vehicle-storage',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
        ],
        verticalName: 'VehicleStorage',
      },
      {
        productDetails: [
          {
            productKey: 'tow_truck',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
        ],
        verticalName: 'TowTruck',
      },
      {
        productDetails: [
          {
            productKey: 'tyres',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
        ],
        verticalName: 'Tyres',
      },
      {
        productDetails: [
          {
            productKey: 'parking',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
        ],
        verticalName: 'Parking',
      },
      {
        productDetails: [
          {
            productKey: 'valet-station',
            generalProviderPrice: {
              units: '9',
              nanos: 990000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '9',
              nanos: 990000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
        ],
        verticalName: 'Valet',
      },
      {
        productDetails: [
          {
            productKey: 'replacement-vehicle-basic',
            generalProviderPrice: {
              units: '9',
              nanos: 990000000,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '9',
              nanos: 990000000,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: false,
          },
        ],
        verticalName: 'ReplacementVehicle',
      },
      {
        productDetails: [
          {
            productKey: 'ownership-change',
            generalProviderPrice: {
              units: '0',
              nanos: 0,
            },
            providerDiscount: {
              units: '0',
              nanos: 0,
            },
            clientProviderPrice: {
              units: '0',
              nanos: 0,
            },
            generalMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            mobilityDiscount: {
              units: '0',
              nanos: 0,
            },
            clientMobilityPrice: {
              units: '11',
              nanos: 979000000,
            },
            available: true,
          },
        ],
        verticalName: 'Formalities',
      },
    ],
    planType: 'Premium',
  };
  showAlert = false;
  togle: boolean = true;
  openChange: boolean = false;
  openAlert = true;
  zones!: any[];
  activeZone: any;
  products!: any[];
  openedDiv: any = '#';
  selectedPlan!: string;
  selectedPlanName!: string;
  bsModalRef?: BsModalRef;
  zoneId!: string;
  clientId: string = '';
  clientName: string = '';
  nextPlanName!: string;
  nextPlan!: string;
  subproductData: any;
  public errors: any = [
    {
      show: "Generic error code. It also marks that something internal failed, and we don't wanna expose internal code.",
      code: 'LMP000000',
      error: 'UnknownError',
    },
    {
      show: 'The requested zone is invalid.',
      code: 'LMP000001',
      error: 'InvalidZone',
    },
    {
      show: "The requested zone doesn't have any products.",
      code: 'LMP000002',
      error: 'NoProductsInZone',
    },
    {
      show: 'The requested product is invalid.',
      code: 'LMP000003',
      error: 'InvalidProduct',
    },
    {
      show: 'The requested product in the specified zone context is invalid.',
      code: 'LMP000004',
      error: 'InvalidProductInZone',
    },
    {
      show: 'There were no products in the product creation attempt.',
      code: 'LMP000005',
      error: 'NoProductsInProductCreationAttempt',
    },
    {
      show: 'A specified product configuration is invalid.',
      code: 'LMP000006',
      error: 'InvalidProductConfiguration',
    },
    {
      show: 'The requested valet station does not exists.',
      code: 'LMP000007',
      error: 'ValetStationDoesNotExist',
    },
    {
      show: 'The origin address point is invalid.',
      code: 'LMP000008',
      error: 'InvalidOriginAddressPoint',
    },
    {
      show: 'The destination address point is invalid.',
      code: 'LMP000009',
      error: 'InvalidDestinationAddressPoint',
    },
    {
      show: 'The vehicle availability requested for a replacement vehicle is invalid.',
      code: 'LMP0000010',
      error: 'InvalidVehicleAvailabilityForReplacementVehicle',
    },
    {
      show: 'Depending on the context of the request, the endpoint used is not the correct one.',
      code: 'LMP000011',
      error: 'InvalidEndpointForRequestContext',
    },
    {
      show: 'Some products in the order are missing a provider.',
      code: 'LMP0000012',
      error: 'ProvidersNotAssigned',
    },
    {
      show: 'The requested order does not exist.',
      code: 'LMP0000013',
      error: 'OrderDoesNotExists',
    },
    {
      show: 'The requested provider does not exist.',
      code: 'LMP0000014',
      error: 'ProviderDoesNotExist',
    },
    {
      show: 'The order hash provider is non-compliant with known formats.',
      code: 'LMP0000015',
      error: 'InvalidOrderHash',
    },
    {
      show: 'The specified driver id is invalid or malformed.',
      code: 'LMP0000016',
      error: 'InvalidDriverId',
    },
    {
      show: 'The requested driver does not exist.',
      code: 'LMP000017',
      error: 'DriverDoesNotExists',
    },
    {
      show: 'A cancellation of a cancelled order cannot be performed.',
      code: 'LMP000018',
      error: 'CannotCancelACancelledOrder',
    },
    {
      show: 'A cancellation of an invalid order cannot be performed.',
      code: 'LMP000019',
      error: 'CannotCancelAnInvalidOrder',
    },
    {
      show: 'Assigning drivers to a service without nodes is not allowed.',
      code: 'LMP0000021',
      error: 'CannotAssignDriverToServiceWithoutNodes',
    },
    {
      show: "Due to the status of the order, we don't allow the requested operation.",
      code: 'LMP0000022',
      error: 'OrderStatusDoesNotAllowsOperation',
    },
  ];
  plans: any = [
    {
      planType: 'Hybrid',
      toUpdate: 'HYBRID_PLAN',
      name: 'Hybrid Plan',
    },
    {
      planType: 'Premium',
      toUpdate: 'PREMIUM_PLAN',
      name: 'Premium Plan',
    },
    {
      planType: 'B2b',
      toUpdate: 'B2B_PLAN',
      name: 'B2B Plan',
    },
    {
      planType: 'B2c',
      toUpdate: 'B2C_PLAN',
      name: 'BC2 Plan',
    },
    {
      planType: 'Custom',
      toUpdate: 'CUSTOM_PLAN',
      name: 'Custom Plan',
    },
  ];

  verticalsTypes = [
    { value: 1, name: 'Transfer', realName: 'transfer' },
    {
      value: 2,
      name: 'MechanicalInspection',
      realName: 'mechanical_inspection',
    },
    { value: 3, name: 'VehicleWash', realName: 'vehicle_wash' },
    { value: 4, name: 'Refueling', realName: 'refueling' },
    {
      value: 5,
      name: 'PreTechnicalInspection',
      realName: 'pre_technical_inspection',
    },
    {
      value: 6,
      name: 'LongDistanceTransfer',
      realName: 'long_distance_transfer',
    },
    { value: 7, name: 'TechnicalInspection', realName: 'technical_inspection' },
    { value: 8, name: 'VehicleInsurance', realName: 'vehicle_insurance' },
    { value: 10, name: 'Accessory', realName: 'accessory' },
    { value: 11, name: 'Valet', realName: 'valet' },
    { value: 12, name: 'Tyres', realName: 'tyres' },
    { value: 13, name: 'Formalities', realName: 'formalities' },
    { value: 14, name: 'Parking', realName: 'parking' },
    { value: 15, name: 'ReplacementVehicle', realName: 'replacement_vehicle' },
    { value: 16, name: 'TowTruck', realName: 'tow_truck' },
    { value: 17, name: 'WhateverYouWant', realName: 'whatever_you_want' },
    { value: 18, name: 'VehicleStorage', realName: 'vehicle_storage' },
  ];

  readonly columns = [
    'vertical',
    'products',
    'general_price',
    'discount',
    'client_price',
    'fee_general_urgency',
    'discount_urgency',
    'fee_client_urgency',
    'general_fixed_hour',
  ];

  // readonly dinamyColums: any = [
  //   'sub_product',
  //   'fare_price',
  //   'discount',
  //   'client_price',
  //   'mobility_price',
  //   'mobility_discount',
  //   'mobility_client_price',
  //   'available_hiring',
  //   'actions',
  // ];
  readonly dinamyColums: any = [
    'kilometer_strips',
    'pool_price',
    'premium',
    'percent_premium',
    'hybrid',
    'percent_hybrid',
    'b2b',
    'percent_b2b',
    'b2c',
    'percent_b2c',
    'actions',
  ];

  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  readonly columns_tarifas = ['service_condition', 'time_limit', 'actions'];

  feeUpdateForm = this.fb.group({
    zoneId: ['', Validators.required],
    clientId: ['', Validators.required],
    feeName: ['', Validators.required],
    units: ['', [Validators.required]],
    nanos: [0, [Validators.required]],
    total: ['', [Validators.required]],
  });
  constructor(
    public historySvc: HistoryServicesService,
    private location: Location,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private readonly alerts: TuiAlertService,
    private renderer: Renderer2,
    public hs: HistoryServicesService,
    public translate: TranslateService,
    private clientSvc: ClientService
  ) {}
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.clientId = params.get('userId') || '';
      this.clientName = params.get('clientName') || '';
    });
    this.historySvc.getZones().subscribe((res: any) => {
      this.zones = res.zones;
    });
  }
  getVerticalRealName(name: any) {
    let vf = this.verticalsTypes.find((vr) => vr.name == name);
    return vf ? vf.realName : '';
  }

  async filterData() {
    if (this.activeZone) {
      const body = {
        zoneId: this.activeZone,
        planType: this.selectedPlan,
        clientId: this.clientId,
      };
      this.showLoader();
      try {
        await firstValueFrom(this.clientSvc.getPricesByZoneClient(body)).then(
          (res: any) => {
            console.log(res, 'RES FROM ENDPOINT');
            this.detailClientData2 = res;
            const plan = this.plans.find(
              (plan: any) => plan.planType == res.planType
            );
            this.selectPlan(plan?.planType, plan?.name);

            this.hideLoader();
          }
        );
      } catch (error) {
        console.log(error, 'ERROR');
        this.hideLoader();
      }

      console.log(body, 'BODY');
    }
  }

  getCountProducts(type: any): any {
    if (this.products) {
      let cp = this.products.find((pro) => pro.verticalType == type);
      if (cp != undefined) {
        return this.products.filter((pro) => pro.verticalType == type)?.length;
      }
      return 0;
    }
  }

  setOpenedDiv(value: any) {
    this.openedDiv = value;
  }

  closeOpenedDiv() {
    this.openedDiv = 0;
  }

  getVerticalProducts(type: any): any {
    if (this.products) {
      let cp = this.products.find((pro) => pro.verticalType == type);
      if (cp != undefined) {
        return this.products.filter((pro) => pro.verticalType == type);
      }
      return [];
    }
  }
  goBack() {
    this.location.back();
  }
  openModal(serviceDataModal: TemplateRef<void>, clientData: any) {
    console.log(clientData);
    this.feeUpdateForm.patchValue({
      zoneId: this.activeZone,
      clientId: this.clientId,
      feeName: clientData.feeName,
      total: this.formatPrice(
        clientData.clientFeePrice.units,
        clientData.clientFeePrice.nanos
      ),
    });
    this.bsModalRef = this.modalService.show(serviceDataModal);
  }
  openModalUpdateProduct(
    serviceDataModal: TemplateRef<void>,
    subproductData: any,
    verticalName: string
  ) {
    subproductData.plan = this.selectedPlanName;
    subproductData.verticalName = verticalName;
    console.log(subproductData);
    this.subproductData = subproductData;
    this.bsModalRef = this.modalService.show(serviceDataModal);
  }

  formatPrice(units: number, nanos: number): string {
    const nanosStr = nanos.toString().padStart(9, '0');
    const decimalPart = nanosStr.substring(0, 2);
    return `${units}.${decimalPart}`;
  }

  selectPlan(plan: string, planName: string) {
    this.selectedPlanName = planName;
    this.selectedPlan = plan;
    this.openChange = false;
    console.log(plan, planName);
    if (this.activeZone && this.selectedPlan != undefined) {
      const body = {
        zoneId: this.activeZone,
        planType: this.selectedPlan,
        clientId: this.clientId,
      };
    }
  }

  verifiedFee(plan: string, planName: string) {
    this.nextPlanName = planName;
    this.nextPlan = plan;
    console.log(this.selectedPlan, 'PLAN');
    if (this.detailClientData2 && this.selectedPlan != undefined) {
      this.showAlert = true;
      this.openChange = false;
    } else {
      this.selectPlan(plan, planName);
    }
  }

  async updateFeeData() {
    this.showAlert = false;
    this.selectedPlan = this.nextPlan;
    this.selectedPlanName = this.nextPlanName;
    const plan = this.plans.find(
      (plan: any) => plan.planType == this.selectedPlan
    );
    console.log(plan, 'PLAN');
    if (this.activeZone) {
      const body = {
        zoneId: this.activeZone,
        planType: plan?.toUpdate,
        clientId: this.clientId,
      };
      this.showLoader();
      try {
        await firstValueFrom(
          this.clientSvc.updatePricesProductByPlan(body)
        ).then((res: any) => {
          this.detailClientData2 = res;
          const plan = this.plans.find(
            (plan: any) => plan.planType == res.planType
          );
          this.selectPlan(plan?.planType, plan?.name);

          this.hideLoader();
        });
        this.hideLoader();
      } catch (error) {
        this.hideLoader();
      }

      console.log(body, 'BODY');
    }
  }

  closeAlert() {
    this.showAlert = false;
  }

  toggleAlert(ele: any) {
    if (ele == 'success') {
      this.openAlert = !this.openAlert;
    }
  }
  async onSubmitUpdateFee() {
    let totalValue = this.feeUpdateForm.get('total')?.value;

    if (totalValue && /^\d+\.$/.test(totalValue)) {
      totalValue = parseFloat(totalValue).toFixed(2);
      this.feeUpdateForm
        .get('total')
        ?.setValue(totalValue, { emitEvent: false });
    }

    if (totalValue) {
      const [units, nanos] = totalValue.split('.');
      const nanosValue = (parseInt(nanos, 10) * Math.pow(10, 7))
        .toString()
        .padEnd(9, '0');

      this.feeUpdateForm.get('units')?.setValue(units, { emitEvent: false });
      this.feeUpdateForm
        .get('nanos')
        ?.setValue(Number.isNaN(+nanosValue) ? 0 : +nanosValue, {
          emitEvent: false,
        });
    }
    this.showLoader();
    if (this.feeUpdateForm.valid) {
      const body = {
        zoneId: this.feeUpdateForm.get('zoneId')?.value,
        clientId: this.feeUpdateForm.get('clientId')?.value,
        feeToUpdate: {
          feeName: this.feeUpdateForm.get('feeName')?.value,
          newPrice: {
            units: this.feeUpdateForm.get('units')?.value,
            nanos: this.feeUpdateForm.get('nanos')?.value,
          },
        },
      };

      try {
        await firstValueFrom(
          this.clientSvc.updatePricesFeesByZoneClient(body)
        ).then((res: any) => {
          console.log(res, 'RES');
          this.detailClientData2 = res;
          const plan = this.plans.find(
            (plan: any) => plan.planType == res.planType
          );
          this.selectPlan(plan?.planType, plan?.name);
          this.hideLoader();
          this.showDepositAlert();
        });
      } catch (error: any) {
        this.hideLoader();
        console.log(error, 'ERROR');
        this.showErrorCode(error.error.code);
      }

      this.bsModalRef?.hide();
    }
  }
  showDepositAlert(): void {
    this.alerts
      .open('Se han guardado los cambios realizados en los precios!', {
        label: 'Se han guardado los cambios',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  onInput(event: any): void {
    const input = event.target.value;
    const pattern = /^\d+(\.\d{0,2})?$/;

    if (!pattern.test(input)) {
      event.target.value = input.slice(0, -1); // Remueve el último carácter ingresado
      this.feeUpdateForm
        .get('total')
        ?.setValue(input.slice(0, -1), { emitEvent: false });
    }
  }

  async receiveSubproductData(event: any) {
    const body = event;
    body.zoneId = this.activeZone;
    body.clientId = this.clientId;

    const plan = this.plans.find(
      (plan: any) => plan.planType == this.selectedPlan
    );
    body.planType = plan?.toUpdate;
    console.log(body, 'BODY READY TO SEND');

    this.showLoader();
    try {
      await firstValueFrom(
        this.clientSvc.updatePricesProductsByZoneClient(body)
      ).then((res: any) => {
        console.log(res, 'RES');
        this.detailClientData2 = res;
        const plan = this.plans.find(
          (plan: any) => plan.planType == res.planType
        );
        this.selectPlan(plan?.planType, plan?.name);
        this.hideLoader();
        this.showDepositAlert();
      });
    } catch (error: any) {
      console.log(error, 'ERROR');
      this.showErrorCode(error.error.code);
      this.hideLoader();
    }
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }
  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  showErrorCode(code: any) {
    let error = this.errors.find((x: any) => x.code == code);
    let show = 'Unknown Error';
    if (error) {
      show = error.show;
    }
    this.alerts
      .open(show, {
        label: 'Ha ocurrido un error',
        status: 'error',
        autoClose: true,
      })
      .subscribe();
  }
}

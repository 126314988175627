import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { SentryService } from 'src/app/services/sentry/sentry.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { LocalStorageKeys } from './enum/local-storage-keys.enum';
import { LanguageEnum } from './enum/language.enum';
import { AuthService } from './services/auth/auth.service';
import { ROUTES } from './constants/routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cafler';
  @ViewChild('div_dropdowns') div_dropdowns!: ElementRef;

  constructor(
    translate: TranslateService,
    private router: Router,
    private sentry: SentryService,
    private hs: HistoryServicesService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {
    const language: LanguageEnum =
      this.localStorageService.get<LanguageEnum>(LocalStorageKeys.LANGUAGE) ??
      LanguageEnum.DEFAULT;

    translate.setDefaultLang(language);
    translate.use(language);
  }

  shouldShowNavbar(): boolean {
    return this.router.url !== `/${ROUTES.LOGIN}`;
  }

  async ngOnInit(): Promise<void> {
    await this.authService.initMsalInstance();
    // [ER - 19/03/2025] - Is needed this?
    // this.sentry.sendTracing({wasSuccessful:true,orderHash:"123456",status:1});
  }
}

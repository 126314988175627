<!-- <div id="myModal" class="modal-content">
  <div class="modal-header-custom d-flex justify-content-between">
    <div>
      <div class="service-data">
        <span
          *ngIf="serviceData.nodeType == 'OPERATIVE_NODE'"
          class="service-name"
          >{{ serviceData.productName }}</span
        >
        <span
          *ngIf="serviceData.nodeType == 'SWEEPER_NODE'"
          class="service-name"
        >
          Coche Escoba
        </span>
        <span
          *ngIf="serviceData.nodeType == 'TRANSPORT_NODE'"
          class="service-name"
        >
          Transporte público
        </span>
        <div
          class="display-contents"
          *ngIf="serviceData.nodeType != 'TRANSPORT_NODE'"
        >
          <span class="service-hash"
            >Order hash {{ serviceData.orderHash }}</span
          >
          <span class="service-product">{{
            serviceData.licensePlate + "-" + serviceData.vehicleChassis
          }}</span>
        </div>
      </div>

      <div *ngIf="serviceData?.orderStatus == 'INITIALIZED'">
        <tui-badge
          status="neutral"
          value="Inicializado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_initalized"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'PENDING'">
        <tui-badge
          status="neutral"
          value="Pendiente"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_pending"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'CONFIRMED'">
        <tui-badge
          status="success"
          value="Confirmado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_confirmed"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'PICKING_UP'">
        <tui-badge
          status="success"
          value="Recogiendo"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'IN_PROGRESS'">
        <tui-badge
          status="success"
          value="En proceso"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'DELIVERING'">
        <tui-badge
          status="success"
          value="Entregando"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'FINISHED'">
        <tui-badge
          status="success"
          value="Finalizado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_finished"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'FAILED'">
        <tui-badge
          status="error"
          value="Fallado"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'CANCELLED_BY_CAFLER'">
        <tui-badge
          status="error"
          value="Cancelado por Cafler"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus == 'CANCELLED_BY_CLIENT'">
        <tui-badge
          status="error"
          value="Cancelado por cliente"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
    </div>
    <div>

    </div>
    <button
      type="button"
      class="btn-close"
      (click)="bsModalRef?.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body-custom mt-3">
    <div
      *ngIf="serviceData.nodeType != 'TRANSPORT_NODE'"
      class="client-data d-flex align-items-center mb-1"
    >
      <tui-svg src="tuiIconUser"></tui-svg>
      <div *ngIf="serviceData.nodeType == 'OPERATIVE_NODE'">
        <span class="fw-bold">Cliente:</span>
        <span>{{ serviceData.clientName }} </span>
      </div>
      <div *ngIf="serviceData.nodeType == 'SWEEPER_NODE'">
        <span class="fw-bold">Conductor:</span>
        <span>{{ serviceData.driverName }} </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconClock"></tui-svg>
      <div>
        <span class="fw-bold">Hora de Recogida:</span>
        <span> {{ formatTime(serviceData.serviceStartDate.seconds) }} </span>
      </div>
    </div>
    <div
      *ngIf="
        serviceData.nodeType == 'SWEEPER_NODE' ||
        serviceData.nodeType == 'TRANSPORT_NODE'
      "
      class="client-data d-flex align-items-center mb-1"
    >
      <tui-svg src="tuiIconClock"></tui-svg>
      <div>
        <span class="fw-bold">Hora de estimada de llegada:</span>
        <span> {{ formatTime(serviceData.serviceEndDate.seconds) }} </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconMapPin"></tui-svg>
      <div>
        <span class="fw-bold">Punto de Recogida:&nbsp;</span>
        <span> {{ serviceData.serviceStartAddress }} </span>
      </div>
    </div>

    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconMapPin"></tui-svg>
      <div>
        <span class="fw-bold">Punto de Entrega:&nbsp;</span>
        <span> {{ serviceData.serviceFinishAddress }} </span>
      </div>
    </div>
  </div>
  <div #littleGmapContainer id="map" class="map_modal mt-1"></div>
  <div
    class="mt-3"
    style="
      width: 100%;
      height: 105px;
      background-color: rgba(235, 239, 242, 1);
      border-radius: 10px;
      padding: 10px;
    "
  >
    <div class="d-flex gap-2 align-items-center mt-2">
      <tui-toggle
        [(ngModel)]="isNodeLockedForDriverAssignation"
        size="l"
      ></tui-toggle>
      <label class="service-hash">Nodo Bloqueado </label>
    </div>
    <div class="modal-footer-custom-block">
      <button
        (click)="bsModalRef?.hide()"
        class="cancel-button-menu-block mt-3"
      >
        Salir sin guardar
      </button>
      <button
        (click)="updateNodeStatus()"
        class="export-button-menu-block mt-3"
      >
        Guardar
      </button>
    </div>
  </div>

  <div
    *ngIf="serviceData.nodeType != 'TRANSPORT_NODE'"
    class="modal-footer-custom"
  >
    <button
      (click)="viewDetail(serviceData)"
      class="export-button-menu mb-2 mt-3"
    >
      Ver detalles del servicio
    </button>
  </div>
</div> -->

<!-- Modal -->
<div id="myModal" class="modal-content">
  <div class="modal-header-custom d-flex justify-content-between">
    <div>
      <div class="service-data">
        <!-- Se muestra el nombre del servicio según el tipo de nodo -->
        <span
          *ngIf="serviceData.nodeType === 'OPERATIVE_NODE'"
          class="service-name"
        >
          <!-- ER [03/04/2025] - Commented if we need in a future {{ getVertical(serviceData.verticalType) }} -->
          {{ serviceData.productName }}
        </span>
        <span
          *ngIf="serviceData.nodeType === 'SWEEPER_NODE'"
          class="service-name"
        >
          {{ "service_manager.modal.sweeper_car" | translate }}
        </span>
        <span
          *ngIf="serviceData.nodeType === 'TRANSPORT_NODE'"
          class="service-name"
        >
          {{ "service_manager.modal.public_transport" | translate }}
        </span>
        <div
          class="display-contents"
          *ngIf="serviceData.nodeType !== 'TRANSPORT_NODE'"
        >
          <span class="service-hash">
            Order hash {{ serviceData.orderHash }}
          </span>
          <span class="service-product">
            {{ serviceData.licensePlate + "-" + serviceData.vehicleChassis }}
          </span>
        </div>
      </div>
      <!-- Badges para el estado de la orden -->
      <div *ngIf="serviceData?.orderStatus === 'INITIALIZED'">
        <tui-badge
          status="neutral"
          [value]="'service_manager.modal.initialized' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_initalized"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'PENDING'">
        <tui-badge
          status="neutral"
          [value]="'service_manager.modal.pending' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_pending"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'CONFIRMED'">
        <tui-badge
          status="success"
          [value]="'service_manager.modal.confirmed' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_confirmed"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'PICKING_UP'">
        <tui-badge
          status="success"
          [value]="'service_manager.modal.picking_up' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'IN_PROGRESS'">
        <tui-badge
          status="success"
          [value]="'service_manager.modal.in_progress' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'DELIVERING'">
        <tui-badge
          status="success"
          [value]="'service_manager.modal.delivering' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_picking_up"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'FINISHED'">
        <tui-badge
          status="success"
          [value]="'service_manager.modal.finished' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2 custom_finished"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'FAILED'">
        <tui-badge
          status="error"
          [value]="'service_manager.modal.failed' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'CANCELLED_BY_CAFLER'">
        <tui-badge
          status="error"
          [value]="'service_manager.modal.cancelled_by_cafler' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
      <div *ngIf="serviceData?.orderStatus === 'CANCELLED_BY_CLIENT'">
        <tui-badge
          status="error"
          [value]="'service_manager.modal.cancelled_by_client' | translate"
          class="tui-space_right-2 mt-2 tui-space_bottom-2"
        ></tui-badge>
      </div>
    </div>
    <!-- <div>
    </div> -->
    <button
      type="button"
      class="btn-close"
      (click)="bsModalRef?.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body-custom mt-3">
    <div
      *ngIf="serviceData.nodeType !== 'TRANSPORT_NODE'"
      class="client-data d-flex align-items-center mb-1"
    >
      <tui-svg src="tuiIconUser"></tui-svg>
      <div *ngIf="serviceData.nodeType === 'OPERATIVE_NODE'">
        <span class="fw-bold"
          >{{ "service_manager.modal.client" | translate }}:</span
        >
        <span>{{ serviceData.clientName }} </span>
      </div>
      <div *ngIf="serviceData.nodeType === 'SWEEPER_NODE'">
        <span class="fw-bold"
          >{{ "service_manager.modal.driver" | translate }}:</span
        >
        <span>{{ serviceData.driverName }} </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconClock"></tui-svg>
      <div>
        <span class="fw-bold"
          >{{ "service_manager.modal.pickup_time" | translate }}:</span
        >
        <span>
          {{ getFormattedTime(serviceData.serviceStartDate.seconds) }}
        </span>
      </div>
    </div>
    <div
      *ngIf="
        serviceData.nodeType === 'SWEEPER_NODE' ||
        serviceData.nodeType === 'TRANSPORT_NODE'
      "
      class="client-data d-flex align-items-center mb-1"
    >
      <tui-svg src="tuiIconClock"></tui-svg>
      <div>
        <span class="fw-bold"
          >{{
            "service_manager.modal.estimated_arrival_time" | translate
          }}:</span
        >
        <span>
          {{ getFormattedTime(serviceData.serviceEndDate.seconds) }}
        </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconMapPin"></tui-svg>
      <div>
        <span class="fw-bold"
          >{{ "service_manager.modal.pickup_point" | translate }}:&nbsp;</span
        >
        <span> {{ serviceData.serviceStartAddress }} </span>
      </div>
    </div>
    <div class="client-data d-flex align-items-center mb-1">
      <tui-svg src="tuiIconMapPin"></tui-svg>
      <div>
        <span class="fw-bold"
          >{{ "service_manager.modal.dropoff_point" | translate }}:&nbsp;</span
        >
        <span> {{ serviceData.serviceFinishAddress }} </span>
      </div>
    </div>
  </div>
  <div #littleGmapContainer id="map" class="map_modal mt-1"></div>
  <div
    class="mt-3"
    style="
      width: 100%;
      height: 105px;
      background-color: rgba(235, 239, 242, 1);
      border-radius: 10px;
      padding: 10px;
    "
  >
    <div class="d-flex gap-2 align-items-center mt-2">
      <tui-toggle
        [(ngModel)]="isNodeLockedForDriverAssignation"
        size="l"
      ></tui-toggle>
      <label class="service-hash">{{
        "service_manager.modal.node_locked" | translate
      }}</label>
    </div>
    <div class="modal-footer-custom-block">
      <button
        (click)="bsModalRef?.hide()"
        class="cancel-button-menu-block mt-3"
      >
        {{ "service_manager.modal.exit_without_saving" | translate }}
      </button>
      <button
        (click)="updateNodeStatus()"
        class="export-button-menu-block mt-3"
      >
        {{ "service_manager.modal.save" | translate }}
      </button>
    </div>
  </div>
  <div
    *ngIf="serviceData.nodeType !== 'TRANSPORT_NODE'"
    class="modal-footer-custom"
  >
    <button
      (click)="viewDetail(serviceData)"
      class="export-button-menu mb-2 mt-3"
    >
      {{ "service_manager.modal.view_service_details" | translate }}
    </button>
  </div>
</div>

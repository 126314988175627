import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  Renderer2,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiTime } from '@taiga-ui/cdk';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { firstValueFrom } from 'rxjs';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { PoolManagerService } from 'src/app/services/pool-manager/pool-manager.service';
declare var moment: any;

@Component({
  selector: 'app-historical-table',

  templateUrl: './historical-table.component.html',
  styleUrls: ['./historical-table.component.css'],
})
export class HistoricalTableComponent {
  index: number = 0;
  length: number = 0;
  pageSize: number = 50;
  filterDisabled: boolean = false;
  searchTerm: string = '';
  poolServices: any = [];
  selectedDate: any = [
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 30)),
  ];
  zones!: any[];
  selectedZone!: any;
  products!: any[];
  selectedProduct: any = [];
  allClients: any[] = [];
  allDrivers: any;
  selectedDriver: any = [];
  startTimeItems: TuiTime[] = this.generateTimeItems();
  endTimeItems: TuiTime[] = [];
  communication_states: any[] = [
    { id: 1, name: 'Sin contactar', count: 0, color: '' },
    { id: 2, name: 'Incidencia llamada telefónica', count: 0 },
    { id: 3, name: 'SMS', count: 0 },
    { id: 4, name: 'SMS Enviado', count: 0, color: 'lightblue' },
    { id: 5, name: 'Error SMS', count: 0, color: 'lightpink' },
    { id: 6, name: 'Whatsapp', count: 0 },
    { id: 7, name: 'Pendiente de respuesta', count: 0, color: 'lightyellow' },
    { id: 8, name: 'Sí respuesta', count: 0, color: 'lightgreen' },
    { id: 9, name: 'No respuesta +60min', count: 0, color: 'lightorange' },
    { id: 10, name: 'Error envío', count: 0, color: 'lightcoral' },
  ];
  selectedClient: any = [];
  datesArray: any[] = [];
  selectedOrderStatus: any = [];
  selectedCommunication: any = [];

  orderStatus = [
    { statusName: 'Inicializado', orderStatus: 'INITIALIZED' },
    { statusName: 'Pendiente', orderStatus: 'PENDING' },
    { statusName: 'Confirmado', orderStatus: 'CONFIRMED' },
    { statusName: 'Recogiendo', orderStatus: 'PICKING_UP' },
    { statusName: 'En proceso', orderStatus: 'IN_PROGRESS' },
    { statusName: 'Entregando', orderStatus: 'DELIVERING' },
    { statusName: 'Finalizado', orderStatus: 'FINISHED' },
    { statusName: 'Fallado', orderStatus: 'FAILED' },
    { statusName: 'Cancelado por Cafler', orderStatus: 'CANCELLED_BY_CAFLER' },
    { statusName: 'Cancelado por cliente', orderStatus: 'CANCELLED_BY_CLIENT' },
  ];
  filterCount: number = 0;
  @Output() countEvent = new EventEmitter<string>();
  sortDirection!: any;
  sortedColumn: string = '';
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  paginatedData: any[] = [];
  readonly hourForm = this.fb.group({
    startTime: [null as TuiTime | null],
    endTime: [null as TuiTime | null],
  });

  div_loder = document.getElementById('loader');
  readonly columns = [
    'date',
    'time',
    'licensePlate',
    'orderHash',
    'zone',
    'product',
    'topping',
    'client',
    'status',
    'driver',
    'price',
    'actions',
  ];
  constructor(
    private poolManagerSvc: PoolManagerService,
    public historyServicesSvc: HistoryServicesService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<any> {
    const response: any = await firstValueFrom(this.poolManagerSvc.getZones());
    this.zones = response.zones;
    this.hourForm.get('startTime')?.valueChanges.subscribe((startTime: any) => {
      if (startTime) {
        this.endTimeItems = this.generateTimeItems().filter(
          (time) =>
            time.toAbsoluteMilliseconds() > startTime.toAbsoluteMilliseconds()
        );

        const endTime = this.endTimeItems[0] || null;
        this.hourForm.get('endTime')?.setValue(endTime, { emitEvent: false });
      } else {
        this.endTimeItems = this.generateTimeItems();
        this.hourForm.get('endTime')?.setValue(null, { emitEvent: false });
      }
    });
  }

  private generateTimeItems(): TuiTime[] {
    const items: TuiTime[] = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        items.push(new TuiTime(hours, minutes));
      }
    }
    return items;
  }

  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }

    setTimeout(() => {
      const closeDiv = document.querySelector('.t-close');
      if (closeDiv) {
        (closeDiv as HTMLElement).click();
      }
    }, 5000);
  }

  funcionKeyUp(searchText: string) {
    this.searchTerm = searchText;
  }
  async filterDataBySelect(index?: any) {
    this.paginatedData = this.poolServices;
    this.index = index ?? 0;
    this.products = [
      ...new Set(
        this.poolServices.map((item: any) =>
          this.historyServicesSvc.translate(item.mainProductName)
        )
      ),
    ];

    if (
      !this.searchTerm &&
      this.selectedClient.length === 0 &&
      this.selectedProduct.length === 0 &&
      this.selectedDriver.length === 0 &&
      this.selectedOrderStatus.length === 0 &&
      !this.hourForm.value.startTime &&
      !this.hourForm.value.endTime
    ) {
      this.sortByExec();
      this.applyPagination();
      return;
    }
    console.log(this.selectedClient, 'ITEM');

    const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';
    this.paginatedData = this.poolServices.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const clientName = item.clientName.toLowerCase();
      const driverName = item.driverName
        ? item.driverName.toLowerCase()
        : 'Sin asignar';

      const matchesSearch =
        !this.searchTerm ||
        license.includes(search) ||
        orderHash.includes(search) ||
        clientName.includes(search) ||
        driverName.includes(search);

      const matchesClient =
        this.selectedClient.length === 0 ||
        this.selectedClient.includes(item.clientName);

      const matchesStatus =
        this.selectedOrderStatus.length === 0 ||
        this.selectedOrderStatus.includes(item.orderStatus);

      const matchesProduct =
        this.selectedProduct.length === 0 ||
        this.selectedProduct.includes(
          this.historyServicesSvc.translate(item.mainProductName)
        );

      return matchesSearch && matchesClient && matchesProduct && matchesStatus;
    });

    console.log(
      this.hourForm.value.startTime,
      this.hourForm.value.endTime,
      'HOUR'
    );

    if (this.hourForm.value.startTime && this.hourForm.value.endTime) {
      const formattedStartTime = this.formatTimeToHHMM(
        this.hourForm.value.startTime
      );
      const formattedEndTime = this.formatTimeToHHMM(
        this.hourForm.value.endTime
      );
      console.log(formattedEndTime, formattedStartTime, 'HOUR');
      this.paginatedData = this.filterByTimeRange(
        this.paginatedData,
        formattedStartTime,
        formattedEndTime
      );
    }
    this.sortByExec();
    this.applyPagination();
  }

  applyPagination() {
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);

    if (this.length === 1) {
      this.index = 0;
    }
    this.filterCount = this.paginatedData.length;
    this.sendNumberOfServices(this.filterCount);
    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }

  async filterDataByDate() {
    this.cdr.detectChanges();
    if (!this.allClients) {
      this.historyServicesSvc.getClients().subscribe((res: any) => {
        this.allClients = res.businessUserData;
      });
    }
    if (this.selectedDate && this.selectedZone) {
      this.showLoader();
      this.poolServices = [];
      this.datesArray = [];
      const startDate = new Date(this.selectedDate[0]);
      const endDate = new Date(this.selectedDate[1]);

      const localStart = moment(startDate);
      const localEnd = moment(endDate);
      const day = String(this.selectedDate[0].getDate()).padStart(2, '0');
      const month = String(this.selectedDate[0].getMonth() + 1).padStart(
        2,
        '0'
      );
      const year = String(this.selectedDate[0].getFullYear());

      const formattedDate = `${day}/${month}/${year}`;

      // Ajustar las fechas a UTC pero manteniendo el mismo día y hora
      const start = moment.utc({
        year: localStart.year(),
        month: localStart.month(),
        date: localStart.date(),
        hour: localStart.hour(),
        minute: localStart.minute(),
        second: localStart.second(),
      });

      const end = moment.utc({
        year: localEnd.year(),
        month: localEnd.month(),
        date: localEnd.date(),
        hour: localEnd.hour(),
        minute: localEnd.minute(),
        second: localEnd.second(),
      });

      let currentDate = start;
      console.log(currentDate.unix());
      while (currentDate <= end) {
        this.datesArray.push(currentDate.unix());

        currentDate = currentDate.add(1, 'days');
      }

      // console.log(this.datesArray);

      /*             this.paginatedData = this.poolServices.filter((item:any) => {
                 const itemDate = new Date(item.serviceStartDate.seconds *1000);
                 console.log(itemDate, startDate, endDate, "A");
                 return itemDate >= startDate && itemDate <= endDate;
             }); */

      this.index = 0;
      this.filterDisabled = true;
      for (let i = 0; i < this.datesArray.length; i++) {
        const element = this.datesArray[i];
        const poolData = {
          date: { seconds: element },
          zoneId: this.selectedZone,
        };

        try {
          const res: any = this.poolManagerSvc.getPools(poolData);

          if (res.services) {
            const poolData = res.services;
            poolData.forEach((pool: any) => {
              let textDateStart = pool.serviceStartDate.time.split(':');
              let textDateEnd = pool.serviceEndDate.time.split(':');

              pool.startHour = textDateStart[0] + ':' + textDateStart[1];
              pool.endHour = textDateEnd[0] + ':' + textDateEnd[1];

              pool.zoneName = this.getZone(pool.zoneId);

              let textDateService =
                pool.serviceStartDate.completeDate.split('T');
              let dateService = moment(textDateService[0]);
              pool.formattedDate = dateService.format('DD/MM/YYYY');
              this.poolServices.push(pool);
            });
          }
        } catch (error) {
          this.hideLoader();
        }
      }
      const inputElement: any = document.getElementById('searchInput');
      inputElement.value = '';
      this.selectedClient = [];
      this.selectedProduct = [];

      this.selectedOrderStatus = [];
      this.searchTerm = '';
      this.paginatedData = this.poolServices;

      if (!this.searchTerm) {
        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.filterCount = this.paginatedData.length;
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      } else {
        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.filterCount = this.paginatedData.length;
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      }

      this.filterCount = this.poolServices.length;
      this.sendNumberOfServices(this.filterCount);

      this.filterDisabled = false;

      this.products = [
        ...new Set(
          this.poolServices.map((item: any) =>
            this.historyServicesSvc.translate(item.mainProductName)
          )
        ),
      ];
      this.allClients = [
        ...new Set(this.poolServices.map((item: any) => item.clientName)),
      ];
    }
    this.hideLoader();
  }

  setSortColumn(column: string): void {
    console.log(column);
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }
  sortData(): void {
    this.filterDataBySelect(this.index);
  }
  onSort(event: any): void {
    this.sortDirection = event;
  }
  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }
  getClientById(id: any) {
    let client_info = this.allClients.find((item: any) => item.userId === id);
    return client_info ? client_info.businessName : null;
  }
  getZone(zoneId: string) {
    const selectedZone = this.zones.find((zone) => zone.zoneId === zoneId);

    return selectedZone.zoneName;
  }

  sendNumberOfServices(number: number) {
    this.countEvent.emit(number.toString());
  }
  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  convertTimeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  filterByTimeRange(data: any, startTime: any, endTime: any) {
    const startMinutes = this.convertTimeToMinutes(startTime);
    const endMinutes = this.convertTimeToMinutes(endTime);

    return data.filter((item: any) => {
      const itemStartMinutes = this.convertTimeToMinutes(
        item.serviceStartDate.time.split(':').slice(0, 2).join(':')
      );
      const itemEndMinutes = this.convertTimeToMinutes(
        item.serviceEndDate.time.split(':').slice(0, 2).join(':')
      );

      return (
        (itemStartMinutes >= startMinutes && itemStartMinutes <= endMinutes) ||
        (itemEndMinutes >= startMinutes && itemEndMinutes <= endMinutes) ||
        (itemStartMinutes <= startMinutes && itemEndMinutes >= endMinutes)
      );
    });
  }

  formatTimeToHHMM(time: { hours: number; minutes: number }): string {
    const hours = String(time.hours).padStart(2, '0');
    const minutes = String(time.minutes).padStart(2, '0');

    return `${hours}:${minutes}`;
  }
}

import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {TuiCountryIsoCode} from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NewClientModalComponent } from 'src/app/pages/clients/new-client-modal/new-client-modal.component';
import { PricesComponent } from 'src/app/pages/clients/prices/prices.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.css']
})
export class PricesTableComponent {
    countries = [
        'España',
        'UK',
        'Francia',
    ];

    operativesZones = [
        'Madrid',
        'Barcelona',
        'Valencia'
    ];

    assignerData: any = [];

    searchTerm: string = '';

    paginatedData: any[] = [];
    readonly sizes = ['l', 'm', 's'] as const;
    size = this.sizes[0];
    zones!: any[];
    types!: any[];
    selectedDate: string = '#';
    selectedTypeClient: string = '#';
    selectedZone: string = '#';
    selectedProduct: string = '#';

    index: number = 0;
    length!: number;
    pageSize: number = 6;
    readonly columns = [
        'vertical',
        'products',
        'general_price',
        'discount',
        'client_price',
        'fee_general_urgency',
        'discount_urgency',
        'fee_client_urgency',
        'general_fixed_hour',
        'discount_fixed_hour',
        'client_fixed_hour' 
    ];

    verticalsTypes = [
        { value: 1, name: "TRANSFER", realName: "Transfer" },
        { value: 2, name: "MECHANICAL_INSPECTION", realName: "Taller" },
        { value: 3, name: "VEHICLE_WASH", realName: "Lavado" },
        { value: 4, name: "REFUELING", realName: "Repostaje" },
        { value: 5, name: "PRE_TECHNICAL_INSPECTION", realName: "Pre-ITV" },
        { value: 6, name: "LONG_DISTANCE_TRANSFER", realName: "Transfer Larga Distancia" },
        { value: 7, name: "TECHNICAL_INSPECTION", realName: "ITV" },
        { value: 8, name: "VEHICLE_INSURANCE", realName: "Seguro" },
        { value: 10, name: "ACCESSORY", realName: "Cafler Fresh" },
        { value: 11, name: "VALET", realName: "Valet" },
        { value: 12, name: "TYRES", realName: "Neumáticos" },
        { value: 13, name: "FORMALITIES", realName: "Trámites" },
        { value: 14, name: "PARKING", realName: "Parking" },
        { value: 15, name: "REPLACEMENT_VEHICLE", realName: "Vehículo de sustitución" },
        { value: 16, name: "TOW_TRUCK", realName: "Grúa" },
        { value: 17, name: "WHATEVER_YOU_WANT", realName: "Pide lo que quieras" }
    ];

    @ViewChild(TuiHostedDropdownComponent)
    component?: TuiHostedDropdownComponent;

    open = false;
    selected = null;

    onClick(): void {
        this.open = false;
        this.component?.nativeFocusableElement?.focus();
    }

    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private modalService: BsModalService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas,
        private router:Router,
    ) {}

    ngOnInit(): void {
        this.length = Math.ceil(this.assignerData.length / this.pageSize);
        this.paginatedDataOperation()
        this.zones = [...new Set(this.assignerData.map((item:any) => item.zone))];
        this.types = [...new Set(this.assignerData.map((item:any) => item.type_client))];
    }

    showDepositAlert(): void {
        this.alerts.open('El usuario se ha creado correctamente', 
            {
                label: 'Usuario creado',
                status: 'success',
                autoClose: true,
            }
        )
        .subscribe();
    }

    paginatedDataOperation() {
        const start = this.index * this.pageSize;
        this.paginatedData = this.assignerData.slice(start, start + this.pageSize);
    }

    goToPage(pageIndex: number) {
        this.index = pageIndex;
        /*  this.paginatedDataOperation()  */
        this.paginatedData = this.assignerData;
      
        // if (this.selectedDate != '#') {
        //    this.paginatedData =  this.paginatedData.filter((item:any) => item.date === this.selectedDate);
        // }
        if (this.selectedTypeClient != '#') {
           this.paginatedData =  this.paginatedData.filter((item:any) => item.type_client === this.selectedTypeClient);
        }
        if (this.selectedZone != '#') {
           this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
        }
        // if (this.selectedProduct != '#') {
        //    this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedProduct);
        // }
      

        if (!this.searchTerm) {
            this.searchTerm = ''
        } else {
            this.searchTerm = this.searchTerm
            this.paginatedData = this.paginatedData.filter((item:any) => {
                const client_na = item.client_name.toLowerCase();
                const search = this.searchTerm.toLowerCase();
                return client_na.includes(search);
            });
        }

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
    }

    filterData(searchText: string) {
        this.index = 0
        if (!searchText) {
            this.searchTerm = ''
            this.length = Math.ceil(this.assignerData.length / this.pageSize);
            this.paginatedData = this.assignerData;
  
            // if (this.selectedDate != '#') {
            //    this.paginatedData =  this.paginatedData.filter((item:any) => item.date === this.selectedDate);
            // }
            if (this.selectedTypeClient != '#') {
               this.paginatedData =  this.paginatedData.filter((item:any) => item.type_client === this.selectedTypeClient);
            }
            if (this.selectedZone != '#') {
               this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
            }
            // if (this.selectedProduct != '#') {
            //    this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedProduct);
            // }
 
            this.length = Math.ceil(this.paginatedData.length / this.pageSize);
            const start = this.index * this.pageSize;
            this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
        } else {
            this.searchTerm = searchText;
            // if (this.selectedDate != '#') {
            //    this.paginatedData =  this.paginatedData.filter((item:any) => item.date === this.selectedDate);
            // }
            if (this.selectedTypeClient != '#') {
               this.paginatedData =  this.paginatedData.filter((item:any) => item.type_client === this.selectedTypeClient);
            }
            if (this.selectedZone != '#') {
               this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
            }
            // if (this.selectedProduct != '#') {
            //    this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedProduct);
            // }

            this.paginatedData = this.paginatedData.filter((item:any) => {
                const client_na = item.client_name.toLowerCase();
                const search = this.searchTerm.toLowerCase();
                return client_na.includes(search);
            });

       

            this.length = Math.ceil(this.paginatedData.length / this.pageSize);
            const start = this.index * this.pageSize;
            this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
        }
    }


    filterDataBySelect() {
        let filteredData = this.assignerData;
        // console.log(this.searchTerm, "ASD");
        if (this.searchTerm) {
            filteredData = filteredData.filter((item:any) => {
                const client_na = item.client_name.toLowerCase();
                const search = this.searchTerm.toLowerCase();
                return client_na.includes(search);
            });
        } 

        // if (this.selectedDate != '#') {
        //    this.paginatedData =  this.paginatedData.filter((item:any) => item.date === this.selectedDate);
        // }
        if (this.selectedTypeClient != '#') {
           filteredData =  filteredData.filter((item:any) => item.type_client === this.selectedTypeClient);
        }
        if (this.selectedZone != '#') {
           filteredData =  filteredData.filter((item:any) => item.zone === this.selectedZone);
        }
        // if (this.selectedProduct != '#') {
        //    this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedProduct);
        // }


        this.length = Math.ceil(filteredData.length / this.pageSize);
        if(this.length == 1){
            this.index = 0
        }
        const start = this.index * this.pageSize;
        this.paginatedData = filteredData.slice(start, start + this.pageSize);
    }

    filterDataBySelectPaginate() {
        let filteredData = this.assignerData;

        // if (this.selectedDate != '#') {
        //    this.paginatedData =  this.paginatedData.filter((item:any) => item.date === this.selectedDate);
        // }
        if (this.selectedTypeClient != '#') {
           this.paginatedData =  this.paginatedData.filter((item:any) => item.type_client === this.selectedTypeClient);
        }
        if (this.selectedZone != '#') {
           this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
        }
        // if (this.selectedProduct != '#') {
        //    this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedProduct);
        // }

        this.length = Math.ceil(filteredData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = filteredData.slice(start, start + this.pageSize);
    }

    openModalEditClient(client:any) {
        const offcanvasRef = this.offcanvasService.open(NewClientModalComponent, { 
            backdrop: 'static',
            position: 'end'
        });
        offcanvasRef.componentInstance.action = 'ed';
        offcanvasRef.componentInstance.client = client;
    }

    goPrices(data:any){
        localStorage.setItem('active_client_prices', JSON.stringify(data));
        this.router.navigate(['/prices'], { queryParams: { client_id: data.client_id} });
    }
}

import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import {TuiCountryIsoCode} from '@taiga-ui/i18n';
import { TuiDay } from '@taiga-ui/cdk';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})
export class IncidentsComponent {


      //modal reference cesar-gutierrez
      bsModalRef?: BsModalRef;
      countries = [
      'España',
      'UK',
      'Francia',
  ];

  operativesZones = [
      'Madrid',
      'Barcelona',
      'Valencia'
  ];

  operatorsSelect = [
      'Interno',
      'Externo',
  ]

  rolesSelect = [
      'Gerente',
      'Supervisor de Mantenimiento',
      'Coordinador',
  ]

  services = [
      'Servicio 1',
      'Servicio 2',
      'Servicio 3',
  ]

  hours = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);
  hours2 = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);

  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
      TuiCountryIsoCode.ES,
      TuiCountryIsoCode.GB,
      TuiCountryIsoCode.FR,
  ];

  readonly countriesPhoneExtra: ReadonlyArray<TuiCountryIsoCode> = [
      TuiCountryIsoCode.ES,
      TuiCountryIsoCode.GB,
      TuiCountryIsoCode.FR,
  ];

  countryIsoCode = TuiCountryIsoCode.ES;
  countryIsoCodeExtra = TuiCountryIsoCode.ES;



searchTerm: string = '';




   incidentServices:any = [
    {
      date: "15/10/2023",
      time: "09:00-09:30",
      licensePlate: "ABC-1234",
      orderHash: "order-001",
      zone: "Madrid",
      product: "Pre-IVT",
      toppings: "None",
      client: "Maria Garcia",
      status: 'resolved',
      deliveryAddress: "Calle Gran Via, 2, Madrid",
      pickupAddress: "Calle Mayor, 1, Madrid",
      provider: "Provider A",
      comments: "Lorem ipsum dolor sit amet."
    },
    {
      date: "01/06/2022",
      time: "08:00-08:30",
      licensePlate: "DEF-5678",
      orderHash: "order-002",
      zone: "Barcelona",
      product: "Mantenimiento basico",
      toppings: "Oil change",
      client: "Juan Lopez",
      status: 'pending',
      deliveryAddress: "Calle Balmes, 6, Barcelona",
      pickupAddress: "Calle Aragon, 5, Barcelona",
      provider: "Provider B",
      comments: "Consectetur adipiscing elit."
    },
    {
      date: "20/03/2021",
      time: "10:00-10:30",
      licensePlate: "GHI-9101",
      orderHash: "order-003",
      zone: "Valencia",
      product: "Grua",
      toppings: "Tow",
      client: "Laura Martinez",
      status: 'resolved',
      deliveryAddress: "Plaza del Ayuntamiento, 4, Valencia",
      pickupAddress: "Avenida del Puerto, 3, Valencia",
      provider: "Provider C",
      comments: "Sed do eiusmod tempor incididunt."
    },
    {
      date: "12/11/2020",
      time: "07:00-07:30",
      licensePlate: "JKL-1121",
      orderHash: "order-004",
      zone: "Sevilla",
      product: "Valet",
      toppings: "Car wash",
      client: "Carlos Sanchez",
      status: 'pending',
      deliveryAddress: "Avenida de la Constitución, 8, Sevilla",
      pickupAddress: "Calle Sierpes, 7, Sevilla",
      provider: "Provider D",
      comments: "Ut labore et dolore magna aliqua."
    },
    {
      date: "05/05/2019",
      time: "11:00-11:30",
      licensePlate: "MNO-1314",
      orderHash: "order-005",
      zone: "Bilbao",
      product: "IVT",
      toppings: "Inspection",
      client: "Ana Perez",
      status: 'resolved',
      deliveryAddress: "Gran Via, 10, Bilbao",
      pickupAddress: "Calle Ercilla, 9, Bilbao",
      provider: "Provider E",
      comments: "Ut enim ad minim veniam."
    },
    {
      date: "22/08/2018",
      time: "06:00-06:30",
      licensePlate: "PQR-1516",
      orderHash: "order-006",
      zone: "Zaragoza",
      product: "Pre-IVT",
      toppings: "None",
      client: "Luis Ramirez",
      status: 'pending',
      deliveryAddress: "Avenida Independencia, 12, Zaragoza",
      pickupAddress: "Calle Alfonso I, 11, Zaragoza",
      provider: "Provider F",
      comments: "Quis nostrud exercitation ullamco."
    },
    {
      date: "10/01/2020",
      time: "12:00-12:30",
      licensePlate: "STU-1718",
      orderHash: "order-007",
      zone: "Malaga",
      product: "Mantenimiento basico",
      toppings: "Tire check",
      client: "Elena Torres",
      status: 'resolved',
      deliveryAddress: "Avenida de Andalucía, 14, Malaga",
      pickupAddress: "Calle Larios, 13, Malaga",
      provider: "Provider G",
      comments: "Laboris nisi ut aliquip ex ea."
    },
    {
      date: "25/07/2017",
      time: "13:00-13:30",
      licensePlate: "VWX-1920",
      orderHash: "order-008",
      zone: "Murcia",
      product: "Grua",
      toppings: "Tow",
      client: "Jorge Fernandez",
      status: 'pending',
      deliveryAddress: "Calle Trapería, 16, Murcia",
      pickupAddress: "Gran Via, 15, Murcia",
      provider: "Provider H",
      comments: "Commodo consequat."
    },
    {
      date: "14/09/2021",
      time: "14:00-14:30",
      licensePlate: "YZA-2122",
      orderHash: "order-009",
      zone: "Alicante",
      product: "Valet",
      toppings: "Detailing",
      client: "Isabel Gomez",
      status: 'resolved',
      deliveryAddress: "Avenida de la Estación, 18, Alicante",
      pickupAddress: "Calle San Francisco, 17, Alicante",
      provider: "Provider I",
      comments: "Duis aute irure dolor in reprehenderit."
    },
    {
      date: "07/02/2023",
      time: "15:00-15:30",
      licensePlate: "BCD-2324",
      orderHash: "order-010",
      zone: "Cordoba",
      product: "IVT",
      toppings: "Inspection",
      client: "Miguel Diaz",
      status: 'pending',
      deliveryAddress: "Avenida Gran Capitán, 20, Cordoba",
      pickupAddress: "Calle Cruz Conde, 19, Cordoba",
      provider: "Provider J",
      comments: "In voluptate velit esse cillum."
    },
    {
      date: "18/12/2019",
      time: "16:00-16:30",
      licensePlate: "EFG-2526",
      orderHash: "order-011",
      zone: "Valladolid",
      product: "Pre-IVT",
      toppings: "None",
      client: "Marta Ruiz",
      status: 'resolved',
      deliveryAddress: "Paseo Zorrilla, 22, Valladolid",
      pickupAddress: "Calle Santiago, 21, Valladolid",
      provider: "Provider K",
      comments: "Dolore eu fugiat nulla pariatur."
    },
    {
      date: "30/04/2020",
      time: "17:00-17:30",
      licensePlate: "HIJ-2728",
      orderHash: "order-012",
      zone: "Granada",
      product: "Mantenimiento basico",
      toppings: "Brake check",
      client: "Pablo Gutierrez",
      status: 'pending',
      deliveryAddress: "Gran Via, 24, Granada",
      pickupAddress: "Calle Reyes Católicos, 23, Granada",
      provider: "Provider L",
      comments: "Excepteur sint occaecat cupidatat."
    },
    {
      date: "23/11/2018",
      time: "18:00-18:30",
      licensePlate: "KLM-2930",
      orderHash: "order-013",
      zone: "Oviedo",
      product: "Grua",
      toppings: "Tow",
      client: "Cristina Alvarez",
      status: 'resolved',
      deliveryAddress: "Plaza del Ayuntamiento, 26, Oviedo",
      pickupAddress: "Calle Uria, 25, Oviedo",
      provider: "Provider M",
      comments: "Non proident, sunt in culpa."
    },
    {
      date: "15/03/2022",
      time: "19:00-19:30",
      licensePlate: "NOP-3132",
      orderHash: "order-014",
      zone: "Santander",
      product: "Valet",
      toppings: "Car wash",
      client: "David Herrera",
      status: 'pending',
      deliveryAddress: "Avenida de los Castros, 28, Santander",
      pickupAddress: "Calle Burgos, 27, Santander",
      provider: "Provider N",
      comments: "Qui officia deserunt mollit."
    },
    {
      date: "27/10/2021",
      time: "20:00-20:30",
      licensePlate: "QRS-3334",
      orderHash: "order-015",
      zone: "Salamanca",
      product: "IVT",
      toppings: "Inspection",
      client: "Sara Moreno",
      status: 'resolved',
      deliveryAddress: "Avenida de Mirat, 30, Salamanca",
      pickupAddress: "Calle Toro, 29, Salamanca",
      provider: "Provider O",
      comments: "Anim id est laborum."
    },
    {
      date: "12/01/2023",
      time: "10:00-10:30",
      licensePlate: "TUV-3536",
      orderHash: "order-016",
      zone: "New York",
      product: "Pre-IVT",
      toppings: "None",
      client: "John Doe",
      status: 'resolved',
      deliveryAddress: "456 Elm St, New York",
      pickupAddress: "123 Maple Ave, New York",
      provider: "Provider P",
      comments: "Lorem ipsum dolor sit amet."
    },
    {
      date: "03/03/2022",
      time: "11:00-11:30",
      licensePlate: "WXZ-3738",
      orderHash: "order-017",
      zone: "Paris",
      product: "Mantenimiento basico",
      toppings: "Oil change",
      client: "Jean Dupont",
      status: 'pending',
      deliveryAddress: "789 Champs-Elysees, Paris",
      pickupAddress: "101 Rue de Rivoli, Paris",
      provider: "Provider Q",
      comments: "Consectetur adipiscing elit."
    },
    {
      date: "19/05/2021",
      time: "12:00-12:30",
      licensePlate: "YZA-3930",
      orderHash: "order-018",
      zone: "Berlin",
      product: "Grua",
      toppings: "Tow",
      client: "Hans Müller",
      status: 'resolved',
      deliveryAddress: "12 Unter den Linden, Berlin",
      pickupAddress: "34 Friedrichstrasse, Berlin",
      provider: "Provider R",
      comments: "Sed do eiusmod tempor incididunt."
    }
  ];
  selectedDate: any = null;
paginatedData: any[] = []
readonly sizes = ['l', 'm', 's'] as const;
size = this.sizes[0];
zones!: any[];
clients!: any[];
products!: any[];
pendingCount!:number;
resolvedCount!:number;
countrys!: any[];
selectedZone: string = '#';
selectedClient: string = '#';
selectedProduct: string = '#';
selectedStatus: string = "pending"
readonly data = [
  {

      title: {
          icon: 'tuiIconFile',
          title: 'This is title',
          chip: 'Chip',
          subtitle: 'More information ・ Data',
      },
      cell: {
          name: 'John Cleese',
          email: 'silly@walk.uk',
      },
      status: {
          value: 'Success',
          color: 'var(--tui-success-fill)',
      },
      items: ['Some', 'items', 'displayed', 'here', 'and', 'can', 'overflow'],
      progress: 78,
      selected: false,
  },
  {

      title: {
          icon: 'tuiIconHeart',
          title: 'More info',
          chip: 'Chips can be here',
      },
      cell: {
          name: 'Eric Idle',
          email: 'cool@dude.com',
      },
      status: {
          value: 'Failure',
          color: 'var(--tui-error-fill)',
      },
      items: ['One', 'Item'],
      progress: 91,
      selected: false,
  },
  {

      title: {
          icon: 'tuiIconStar',
          title: 'Wow',
      },
      cell: {
          name: 'Michael Palin',
          email: 'its@man.com',
      },
      status: {
          value: 'Pending',
          color: 'var(--tui-warning-fill)',
      },
      items: [],
      progress: 32,
      selected: false,
  },
];
index: number = 0;
length!: number;
pageSize: number = 6;
selectedCountry:any = '#'


readonly columns = ['incident','date','time', 'licensePlate', 'order-hash', 'zone',  'product' , 'topping', 'client','service-status','pickupAddress','deliveryAddress','providers' ,'comment'];






@ViewChild(TuiHostedDropdownComponent)
component?: TuiHostedDropdownComponent;

readonly items = ['Edit', 'Download', 'Rename', 'Delete'];

readonly selectItems = ['Item 1', 'Item 2'];

open = false;

selected = null;

onClick(): void {
  this.open = false;
  this.component?.nativeFocusableElement?.focus();
}


constructor(
@Inject(TuiAlertService) private readonly alerts: TuiAlertService,

) {}



ngOnInit(): void {
  this.length = Math.ceil(this.incidentServices.length / this.pageSize);
  this.paginatedDataOperation()
   this.zones = [...new Set(this.incidentServices.map((item:any) => item.zone))];

  this.updateCounts()
  this.applyFilter(this.selectedStatus)
 
 
}



showDepositAlert(): void {
this.alerts
  .open('El usuario se ha creado correctamente', {
      label: 'Usuario creado',
      status: 'success',
      autoClose: true,
  })
  .subscribe();
}

paginatedDataOperation() {
  const start = this.index * this.pageSize;
  this.paginatedData = this.incidentServices.slice(start, start + this.pageSize);
  
}

goToPage(pageIndex: number) {
  this.index = pageIndex;
  /*  this.paginatedDataOperation()  */
   this.paginatedData = this.incidentServices.filter((item:any) => item.status === this.selectedStatus);

   if(this.selectedDate){
    const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
    const year = String(this.selectedDate.getFullYear()); 

    const formattedDate = `${day}/${month}/${year}`; 
  }
  

  if (this.selectedClient != '#' ) {
  
     this.paginatedData =  this.paginatedData.filter((item:any) => item.client === this.selectedClient);
  }

  if (this.selectedZone != '#') {
     this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
  }

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }
  

  if (!this.searchTerm) {
    this.searchTerm = ''


  } else {
    this.searchTerm = this.searchTerm
    this.paginatedData = this.paginatedData.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const client = item.client.toLowerCase();
      const search = this.searchTerm.toLowerCase();
    
      return license.includes(search) || orderHash.includes(search) || client.includes(search);
    });

  }

  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

     
}

filterData(searchText: string) {
  this.index = 0
  if (!searchText) {
    this.searchTerm = ''
      this.length = Math.ceil(this.incidentServices.length / this.pageSize);
     /* this.paginatedDataOperation() */
     this.paginatedData = this.incidentServices.filter((item:any) => item.status === this.selectedStatus);
     if(this.selectedDate){
      const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
      const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
      const year = String(this.selectedDate.getFullYear()); 
  
      const formattedDate = `${day}/${month}/${year}`; 
      
      this.paginatedData = this.paginatedData.filter((item:any) => item.date === formattedDate); 
    }

     if (this.selectedClient != '#' ) {
      const isActive = this.selectedClient === 'Active';
       this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
    }
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }
 
  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

  } else {
    this.searchTerm = searchText
   this.paginatedData = this.incidentServices.filter((item:any) => item.status === this.selectedStatus);

  
  
    if (this.selectedClient != '#' ) {
      const isActive = this.selectedClient === 'Active';
       this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
    }
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }

 this.paginatedData = this.paginatedData.filter((item: any) => {
  const license = item.licensePlate.toLowerCase();
  const orderHash = item.orderHash.toLowerCase();
  const client = item.client.toLowerCase();
  const search = searchText.toLowerCase();

  return license.includes(search) || orderHash.includes(search) || client.includes(search);
});

       

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }
  
}

filterDataBySelect() {
  this.paginatedData = this.incidentServices.filter((item:any) => item.status === this.selectedStatus);
  if(this.selectedDate){

    this.selectedDate = new Date(this.selectedDate)
   
    const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
    const year = String(this.selectedDate.getFullYear()); 

    const formattedDate = `${day}/${month}/${year}`;    
    
    this.paginatedData = this.paginatedData.filter((item:any) => item.date === formattedDate); 
  }

  if (this.searchTerm) {
    
    
    this.paginatedData = this.paginatedData.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const client = item.client.toLowerCase();
      const search = this.searchTerm.toLowerCase();
    
      return license.includes(search) || orderHash.includes(search) || client.includes(search);
    });


  } 
  
  if (this.selectedClient != '#' ) {
    const isActive = this.selectedClient === 'Active';
     this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
  }

  if (this.selectedZone != '#') {
     this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
  }


if (this.selectedProduct != '#') {
  this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
}
  


  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  if(this.length == 1){
      this.index = 0
  }
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

  
 
}





updateCounts() {
  this.pendingCount = this.incidentServices.filter((item:any) => item.status === 'pending').length;
  this.resolvedCount = this.incidentServices.filter((item:any) => item.status === 'resolved').length;
}

applyFilter(status: string) {
  this.selectedStatus = status;
  this.paginatedData = this.incidentServices.filter((item:any) => item.status === status);
  this.index = 0
  if (!this.searchTerm) {
    this.searchTerm = ''
      this.length = Math.ceil(this.incidentServices.length / this.pageSize);
     /* this.paginatedDataOperation() */
   

     if (this.selectedClient != '#' ) {
      const isActive = this.selectedClient === 'Active';
       this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
    }
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }
 
  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

  } else {
    this.searchTerm = this.searchTerm
    

  
  
    if (this.selectedClient != '#' ) {
      const isActive = this.selectedClient === 'Active';
       this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
    }
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }

 this.paginatedData = this.paginatedData.filter((item: any) => {
  const license = item.licensePlate.toLowerCase();
  const orderHash = item.orderHash.toLowerCase();
  const client = item.client.toLowerCase();
  const search = this.searchTerm.toLowerCase();

  return license.includes(search) || orderHash.includes(search) || client.includes(search);
});

       

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }
}

filterDataByDate() {
  if (this.selectedDate) {
    this.selectedDate = new Date(this.selectedDate)
   
    const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
    const year = String(this.selectedDate.getFullYear()); 

    const formattedDate = `${day}/${month}/${year}`; 
    
    
     this.paginatedData = this.incidentServices.filter((item:any) => item.date === formattedDate); 
     this.paginatedData = this.paginatedData.filter((item:any) => item.status === this.selectedStatus);
     
     this.index = 0
     if (!this.searchTerm) {
       this.searchTerm = ''
        
      
      
   
        if (this.selectedClient != '#' ) {
         const isActive = this.selectedClient === 'Active';
          this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
       }
     
       if (this.selectedZone != '#') {
          this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
       }
     
   
     if (this.selectedProduct != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
    }
    
     this.length = Math.ceil(this.paginatedData.length / this.pageSize);
     const start = this.index * this.pageSize;
     this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
   
     } else {
       this.searchTerm = this.searchTerm
         
     
       if (this.selectedClient != '#' ) {
         const isActive = this.selectedClient === 'Active';
          this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
       }
     
       if (this.selectedZone != '#') {
          this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
       }
     
   
     if (this.selectedProduct != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
    }
   
    this.paginatedData = this.paginatedData.filter((item: any) => {
     const license = item.licensePlate.toLowerCase();
     const orderHash = item.orderHash.toLowerCase();
     const client = item.client.toLowerCase();
     const search = this.searchTerm.toLowerCase();
   
     return license.includes(search) || orderHash.includes(search) || client.includes(search);
   });
   
          
   
           this.length = Math.ceil(this.paginatedData.length / this.pageSize);
           const start = this.index * this.pageSize;
           this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
     }
  } else {
    this.paginatedData = this.incidentServices.filter((item:any) => item.status === this.selectedStatus);
    
    this.index = 0
    if (!this.searchTerm) {
      this.searchTerm = ''
       
     
     
  
       if (this.selectedClient != '#' ) {
        const isActive = this.selectedClient === 'Active';
         this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
      }
    
      if (this.selectedZone != '#') {
         this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
      }
    
  
    if (this.selectedProduct != '#') {
      this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
   }
   
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  
    } else {
      this.searchTerm = this.searchTerm
        
    
      if (this.selectedClient != '#' ) {
        const isActive = this.selectedClient === 'Active';
         this.paginatedData =  this.paginatedData.filter((item:any) => item.client === isActive);
      }
    
      if (this.selectedZone != '#') {
         this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
      }
    
  
    if (this.selectedProduct != '#') {
      this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
   }
  
   this.paginatedData = this.paginatedData.filter((item: any) => {
    const license = item.licensePlate.toLowerCase();
    const orderHash = item.orderHash.toLowerCase();
    const client = item.client.toLowerCase();
    const search = this.searchTerm.toLowerCase();
  
    return license.includes(search) || orderHash.includes(search) || client.includes(search);
  });
  
         
  
          this.length = Math.ceil(this.paginatedData.length / this.pageSize);
          const start = this.index * this.pageSize;
          this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
    }
  }
}

clearDate() {
  this.selectedDate = null;
  this.filterDataByDate();
}


}

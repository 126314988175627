import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { ROUTES } from './constants/routes';
import { Permission } from './enum/permission.enum';
import { permissionGuard } from './guards/permissions/permissions.guard';

// ER [19/03/2025] - Its needed isIframe?
// const isIframe = window !== window.parent && !window.opener;
const routes: Routes = [
  { path: '', redirectTo: `/${ROUTES.LOGIN}`, pathMatch: 'full' },
  {
    path: ROUTES.LOGIN,
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ROUTES.OPERATIONAL_STAFF,
    loadChildren: () =>
      import('./pages/operational-staff/operational-staff.module').then(
        (m) => m.OperationalStaffModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.SERVICE_MANAGER,
    loadChildren: () =>
      import('./pages/service-manager/service-manager.module').then(
        (m) => m.ServiceManagerModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.POOL_MANAGER,
    loadChildren: () =>
      import('./pages/pool-manager/pool-manager.module').then(
        (m) => m.PoolManagerModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.HOME,
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.ASSIGNER,
    loadChildren: () =>
      import('./pages/assigner/assigner.module').then((m) => m.AssignerModule),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.PROVIDERS,
    loadChildren: () =>
      import('./pages/providers/providers.module').then(
        (m) => m.ProvidersModule
      ),
    canActivate: [MsalGuard],
    canMatch: [permissionGuard],
    data: {
      permissions: [Permission.PROVIDERS],
    },
  },
  {
    path: ROUTES.COMMERCIAL,
    loadChildren: () =>
      import('./pages/comercial/comercial.module').then(
        (m) => m.ComercialModule
      ),
    canActivate: [MsalGuard],
    canMatch: [permissionGuard],
    data: {
      permissions: [Permission.COMMERCIAL],
    },
  },
  {
    path: ROUTES.PLATFORM_BLOCKER,
    loadChildren: () =>
      import('./pages/platform-blocker/platform-blocker.module').then(
        (m) => m.PlatformBlockerModule
      ),
  },
  {
    path: ROUTES.DETAIL_SERVICE,
    loadChildren: () =>
      import('./pages/detail-service/detail-service.module').then(
        (m) => m.DetailServiceModule
      ),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.TEST_BUTTONS,
    loadChildren: () =>
      import(
        './pages/operational-staff/add-personal-modal/add-personal-modal.module'
      ).then((m) => m.AddPersonalModalModule),
  },
  {
    path: ROUTES.PRICES,
    loadChildren: () =>
      import('./pages/prices/prices.module').then((m) => m.PricesModule),
    canActivate: [MsalGuard],
  },
  {
    path: ROUTES.CLIENTS,
    loadChildren: () =>
      import('./pages/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [MsalGuard],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

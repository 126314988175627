import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  NgZone,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  NgControl,
} from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { TuiDay } from '@taiga-ui/cdk';
declare var moment: any;
declare var google: any;
import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';
import {
  GetProvidersResponse,
  Provider,
} from 'src/app/models/provider/provider.model';
import {
  AddProviderRequest,
  AddProviderResponse,
  GetServiceDetailProviderResponse,
  ProviderServiceDetail,
} from 'src/app/models/service-detail/service-detail.model';

@Component({
  selector: 'app-modal-assign-provider',
  templateUrl: './modal-assign-provider.component.html',
  styleUrls: ['./modal-assign-provider.component.css'],
})
export class ModalAssignProviderComponent {
  @Input() order_hash: any;
  @Input() service_info: any;
  @Input() product: any;
  @Input() idItv: any;
  active_provider: any;
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private hs: HistoryServicesService,
    private ngZone: NgZone,
    private ms: MapaServiceService,
    private renderer: Renderer2
  ) {}

  assignForm = this.fb.group({
    orderHash: ['', Validators.required],
    providerId: ['', Validators.required],
  });
  filterForm = this.fb.group({
    nameFilter: '',
  });
  elements_providers: ProviderServiceDetail[] = [];
  elements_providers2: ProviderServiceDetail[] = [];

  // [ER - 31/03/2025] - old code commented if we need in a future
  // ngOnInit(){
  //     console.log('Entras?')
  //     this.assignForm.patchValue({
  //         orderHash:this.order_hash
  //     });
  //     this.showLoader();
  //
  //     this.hs.getProviders(this.service_info?.zoneId).subscribe((providers: GetProvidersResponse) => {
  //         this.elements_providers2 = providers.providersInZone;
  //         if(this.product.productData.productLocalizationCode != '360-mot-fuel-car-es' && this.product.productData.productLocalizationCode != 'mot-homologation'){
  //             this.elements_providers = this.elements_providers2
  //             .filter((pro: any) =>
  //               pro.productIds.includes(this.product.productId)
  //             )
  //             .map((pro: any) => pro);
  //         }else{
  //             this.elements_providers = this.elements_providers2
  //             .filter((pro: any) =>
  //               pro.productIds.includes(this.idItv)
  //             )
  //             .map((pro: any) => pro);
  //         }
  //
  //
  //         this.hideLoader();
  //     });
  // }

  ngOnInit(): void {
    console.log('Entras?');
    // Se actualiza el formulario con el orderHash (se asume que order_hash ya está definido)
    this.assignForm.patchValue({
      orderHash: this.order_hash,
    });

    this.showLoader();

    // Llamada al servicio para obtener los proveedores basados en el zoneId de service_info.
    this.hs.getProviders(this.service_info?.zoneId).subscribe({
      next: (providers: GetServiceDetailProviderResponse) => {
        console.log('providers: ', providers);
        // Asignamos la lista de proveedores recibida.
        this.elements_providers2 = providers.providersInZone;

        // Verificamos si el producto actual tiene definido productData y productLocalizationCode
        if (
          this.product?.productData?.productLocalizationCode !==
            '360-mot-fuel-car-es' &&
          this.product?.productData?.productLocalizationCode !==
            'mot-homologation'
        ) {
          // Filtrar proveedores basados en el productId del producto actual.
          this.elements_providers = this.elements_providers2.filter(
            (pro: ProviderServiceDetail) =>
              pro.productIds.includes(this.product?.productId)
          );
        } else {
          // Si se trata de un producto específico (por ejemplo, ITV), se filtra usando idItv
          this.elements_providers = this.elements_providers2.filter(
            (pro: ProviderServiceDetail) => pro.productIds.includes(this.idItv)
          );
        }
        this.hideLoader();
      },
      error: (error) => {
        console.error('Error al obtener proveedores:', error);
        this.hideLoader();
      },
    });
  }

  filterData(event: Event): void {
    const query = (event.target as HTMLInputElement).value.toLowerCase();
    this.elements_providers = this.elements_providers2.filter(
      (item: any) =>
        item.name.toLowerCase().includes(query) &&
        item.productIds.includes(this.product.productId)
    );
  }

  closeCanvas() {
    this.offcanvasService.dismiss();
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }

    setTimeout(() => {
      const closeDiv = document.querySelector('.t-close');
      if (closeDiv) {
        (closeDiv as HTMLElement).click();
      }
    }, 5000);
  }

  showLoaderModal() {
    const div = document.querySelector(
      '.loader_inside_modal_button'
    ) as HTMLElement;
    if (div) {
      div.style.display = 'flex'; // Agrega display: flex al div
    }
  }

  hideLoaderModal() {
    const div = document.querySelector(
      '.loader_inside_modal_button'
    ) as HTMLElement;
    if (div) {
      div.style.display = 'none'; // Agrega display: flex al div
    }
  }

  showErrorMsj() {
    setTimeout(() => {
      const sourceElement = document.querySelector('tui-alert');
      const targetElement = document.getElementById(
        'modal_assign_provider_service_alert'
      );
      if (sourceElement && targetElement) {
        // console.log('replicar');
        const clonedNode = sourceElement.cloneNode(true);
        // targetElement!.innerHTML = '';
        targetElement.appendChild(sourceElement);
      }
    }, 300);
  }

  assignProvider(): void {
    this.showLoaderModal();

    const providerId = this.assignForm.value.providerId;

    if (!providerId) {
      this.hideLoaderModal();
      this.showErrorMsj();
      return;
    }

    const payload: AddProviderRequest = {
      orderHash: this.service_info.orderHash,
      productId: this.product.productId,
      providerId: providerId,
    };

    this.hs.addProviderToProductInService(payload).subscribe({
      next: (res: AddProviderResponse) => {
        this.hideLoaderModal();

        if (!res.wasSuccessful && res.statusCode) {
          this.hs.showErrorCode(res.statusCode);
          this.showErrorMsj();
          return;
        }

        this.offcanvasService.dismiss();
        this.hs.publish('updateDetailsService');
        this.showDepositAlert(
          '¡Éxito!',
          'Se ha asignado correctamente el proveedor'
        );
      },
      error: (error) => {
        this.hideLoaderModal();

        const message =
          error?.status === 500
            ? { label: 'Error 500', text: 'Problema en el servidor' }
            : {
                label: 'Otro error',
                text: error?.message || 'Error desconocido',
              };

        this.alerts
          .open(message.text, {
            label: message.label,
            status: 'error',
            autoClose: false,
          })
          .subscribe();

        this.showErrorMsj();
      },
    });
  }

  // [ER - 03/04/2025] - OLD CODE
  // assignProvider() {
  //   this.showLoaderModal();

  //   let data = {
  //     orderHash: this.service_info.orderHash,
  //     productId: this.product.productId,
  //     providerId: this.assignForm.value.providerId,
  //   };
  //   this.hs.addProviderToProductInService(data).subscribe(
  //     (res: any) => {
  //       this.hideLoaderModal();
  //       if (!res.wasSuccessful && res?.statusCode) {
  //         this.hs.showErrorCode(res.statusCode);
  //         this.showErrorMsj();
  //       } else {
  //         this.offcanvasService.dismiss();
  //         this.hs.publish('updateDetailsService');
  //         this.showDepositAlert(
  //           'Exito!',
  //           'Se ha asignado correctamente el proveedor'
  //         );
  //       }
  //     },
  //     (error) => {
  //       this.hideLoaderModal();
  //       if (error.status === 500) {
  //         this.alerts
  //           .open('Problema en el servidor', {
  //             label: 'Error 500',
  //             status: 'error',
  //             autoClose: false,
  //           })
  //           .subscribe();
  //         this.showErrorMsj();
  //       } else {
  //         this.alerts
  //           .open(error, {
  //             label: 'Otro error',
  //             status: 'error',
  //             autoClose: false,
  //           })
  //           .subscribe();
  //         this.showErrorMsj();
  //       }
  //     }
  //   );
  // }

  showDepositAlert(title: any, msj: any): void {
    this.alerts
      .open(msj, {
        label: title,
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  selectprovider(provider: any) {
    this.assignForm.patchValue({
      providerId: provider.id,
    });

    this.active_provider = provider.id;
  }
}

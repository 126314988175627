import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorHandlerService } from '../http-error-handler.service';
import { Provider } from 'src/app/models/provider/provider.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  providers: Provider[] = [
    {
      client_name: 'Cliente taller A',
      billing_name: 'Taller A C.A',
      client_id: '0254478412',
      country: 'España',
      zone: 'Madrid',
      status: 'pendiente',
      email: 'emailclientetaller1@email.com',
      phone: '(+34) 656 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage B',
      billing_name: 'Garage B SARL',
      client_id: '1298475613',
      country: 'Francia',
      zone: 'París',
      status: 'pendiente',
      email: 'emailclientgarage2@email.com',
      phone: '(+33) 1 40 34 34 34',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop C',
      billing_name: 'Workshop C Ltd',
      client_id: '9834756124',
      country: 'UK',
      zone: 'Londres',
      status: 'pendiente',
      email: 'emailclientworkshop3@email.com',
      phone: '(+44) 20 7946 0344',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller D',
      billing_name: 'Taller D C.A',
      client_id: '8437562135',
      country: 'España',
      zone: 'Barcelona',
      status: 'pendiente',
      email: 'emailclientetaller4@email.com',
      phone: '(+34) 659 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage E',
      billing_name: 'Garage E SARL',
      client_id: '2345612876',
      country: 'Francia',
      zone: 'Lyon',
      status: 'pendiente',
      email: 'emailclientgarage5@email.com',
      phone: '(+33) 4 72 10 30 30',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop F',
      billing_name: 'Workshop F Ltd',
      client_id: '1273465923',
      country: 'UK',
      zone: 'Manchester',
      status: 'pendiente',
      email: 'emailclientworkshop6@email.com',
      phone: '(+44) 161 234 5000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller G',
      billing_name: 'Taller G C.A',
      client_id: '9827346128',
      country: 'España',
      zone: 'Valencia',
      status: 'pendiente',
      email: 'emailclientetaller7@email.com',
      phone: '(+34) 662 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage H',
      billing_name: 'Garage H SARL',
      client_id: '3456729183',
      country: 'Francia',
      zone: 'Marsella',
      status: 'pendiente',
      email: 'emailclientgarage8@email.com',
      phone: '(+33) 4 91 55 11 11',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop I',
      billing_name: 'Workshop I Ltd',
      client_id: '8273645921',
      country: 'UK',
      zone: 'Birmingham',
      status: 'pendiente',
      email: 'emailclientworkshop9@email.com',
      phone: '(+44) 121 675 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller J',
      billing_name: 'Taller J C.A',
      client_id: '1827364592',
      country: 'España',
      zone: 'Sevilla',
      status: 'pendiente',
      email: 'emailclientetaller10@email.com',
      phone: '(+34) 665 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage K',
      billing_name: 'Garage K SARL',
      client_id: '7382916475',
      country: 'Francia',
      zone: 'Toulouse',
      status: 'pendiente',
      email: 'emailclientgarage11@email.com',
      phone: '(+33) 5 62 27 10 10',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop L',
      billing_name: 'Workshop L Ltd',
      client_id: '8932475612',
      country: 'UK',
      zone: 'Edimburgo',
      status: 'pendiente',
      email: 'emailclientworkshop12@email.com',
      phone: '(+44) 131 200 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller M',
      billing_name: 'Taller M C.A',
      client_id: '2346582719',
      country: 'España',
      zone: 'Bilbao',
      status: 'pendiente',
      email: 'emailclientetaller13@email.com',
      phone: '(+34) 668 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage N',
      billing_name: 'Garage N SARL',
      client_id: '4938271567',
      country: 'Francia',
      zone: 'Niza',
      status: 'activo',
      email: 'emailclientgarage14@email.com',
      phone: '(+33) 4 93 13 13 13',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop O',
      billing_name: 'Workshop O Ltd',
      client_id: '9823746152',
      country: 'UK',
      zone: 'Glasgow',
      status: 'activo',
      email: 'emailclientworkshop15@email.com',
      phone: '(+44) 141 287 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller P',
      billing_name: 'Taller P C.A',
      client_id: '2198734562',
      country: 'España',
      zone: 'Zaragoza',
      status: 'activo',
      email: 'emailclientetaller16@email.com',
      phone: '(+34) 671 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage Q',
      billing_name: 'Garage Q SARL',
      client_id: '9128346571',
      country: 'Francia',
      zone: 'Bordeaux',
      status: 'activo',
      email: 'emailclientgarage17@email.com',
      phone: '(+33) 5 56 00 66 00',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop R',
      billing_name: 'Workshop R Ltd',
      client_id: '3827164592',
      country: 'UK',
      zone: 'Liverpool',
      status: 'activo',
      email: 'emailclientworkshop18@email.com',
      phone: '(+44) 151 233 3000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller S',
      billing_name: 'Taller S C.A',
      client_id: '2387465129',
      country: 'España',
      zone: 'Málaga',
      status: 'activo',
      email: 'emailclientetaller19@email.com',
      phone: '(+34) 674 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage T',
      billing_name: 'Garage T SARL',
      client_id: '1827364593',
      country: 'Francia',
      zone: 'Strasbourg',
      status: 'activo',
      email: 'emailclientgarage20@email.com',
      phone: '(+33) 3 88 60 00 00',
      type_client: 'Silver',
    },
  ];

  constructor(
    private http: HttpClient,
    private errorHandler: HttpErrorHandlerService
  ) {}

  getProvidersHardcoded(): Observable<Provider[]> {
    return of<Provider[]>(this.providers);
  }
}

<div class="table-body">
  <div class="table-body-head mb-3">
    <div>
      <label class="table-title"
        >{{ "service_manager.gantt.title" | translate }} -
        <span class="table-subtitle">Hoy, {{ actualDate }} </span>
      </label>
    </div>
  </div>
  <div class="table-body-options mb-3">
    <div class="search-bar">
      <img
        src="../../../assets/icons/search- icon.svg"
        alt="Search Icon"
        class="search-icon"
      />
      <input
        #searchInput
        type="text"
        placeholder="{{ 'service_manager.gantt.search_text' | translate }}"
        class="search-bar-input"
        [(ngModel)]="searchTerm"
      />
    </div>

    <ng-select
      [items]="zones"
      bindLabel="zoneName"
      bindValue="zoneId"
      [multiple]="false"
      [(ngModel)]="selectedZone"
      class="custom_select"
      (close)="filterDataByDate()"
      placeholder="{{ 'service_manager.gantt.zone_input' | translate }}"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>

    <tui-input-date
      tuiTextfieldSize="m"
      [(ngModel)]="selectedDate"
      (ngModelChange)="filterDataByDate()"
      style="margin-top: -8px"
    >
      {{ "service_manager.gantt.date_input" | translate }}</tui-input-date
    >

    <button
      [disabled]="ganttDataOriginal.length < 1"
      (click)="filterData()"
      appearance="primary-button"
      size="s"
      tuiButton
    >
      <tui-svg src="tuiIconSearch"></tui-svg>
      {{ "service_manager.gantt.search_list_text" | translate }}
    </button>
  </div>

  <div class="gantt-main-container d-flex" #father>
    <div style="z-index: 100" class="gantt-main-container2">
      <div class="gantt-header">
        <div class="gantt-header-cell2">6:00</div>
      </div>
      <div
        #div1
        [ngStyle]="{ transform: 'translateX(' + scrollPosition + 'px)' }"
        class="gantt-row2 justify-content-center"
        *ngFor="let row of ganttData; let i = index"
        [ngClass]="{
          'white-background': i % 2 === 0,
          'gray-background': i % 2 !== 0
        }"
      >
        <div>
          <div class="operator-name">
            {{ row.driver[0].fullName }}
          </div>
          <div class="operator-role">
            <span>{{ row.driver[0].operatorType | translateEnum }}</span>
          </div>
          <div
            *ngIf="
              row.driver[0].workdayStartTime && row.driver[0].workdayEndTime
            "
            class="operator-role"
          >
            {{ formatTime(row.driver[0].workdayStartTime.seconds) }}
            -
            {{ formatTime(row.driver[0].workdayEndTime.seconds) }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div  class="gantt-main-container2">
      <div class="gantt-header">
        <div class="gantt-header-cell2">6:00</div>
      </div>
    </div> -->

    <div class="gantt-container" *ngIf="ganttData.length; else emptyGantt">
      <div class="gantt-time-grid">
        <div class="gantt-time-grid-cell" *ngFor="let hour of hours"></div>
      </div>

      <div class="current-time-container">
        <div class="current-time" [ngStyle]="getCurrentTimeStyle()"></div>
      </div>

      <div class="gantt-header">
        <div class="gantt-header-cell" *ngFor="let hour of hours">
          {{ hour }}
        </div>
      </div>

      <div class="gantt-body">
        <div
          #div2
          class="gantt-row"
          *ngFor="let row of ganttData; let i = index"
          [ngClass]="{
            'white-background': i % 2 === 0,
            'gray-background': i % 2 !== 0
          }"
        >
          <div
            *ngFor="let tasks of row.value.data"
            class="d-flex"
            style="flex-direction: column"
          >
            <div
              class="gantt-task"
              *ngFor="let task of tasks"
              [ngStyle]="getPositionStyle(task, tasks)"
              [ngClass]="getStatusStyle(task, task.nodeType)"
              title="{{ task.licensePlate }} - {{ task.orderHash }}"
            >
              <div
                (click)="openModal(serviceDataModal, task)"
                class="gantt-task-data"
              >
                <div class="gantt-task-products">
                  <div
                    class="gantt-task-product"
                    *ngFor="let productType of task.productTypes"
                  >
                    <span>{{ getServiceType(productType) }}</span>
                  </div>
                </div>
                <div class="gantt-task-info">
                  <img
                    style="height: 24px"
                    *ngIf="task.priority"
                    src="../../../assets/icons/priority-service.svg"
                    alt="icon"
                  />

                  <img
                    sstyle="height: 24px;"
                    [src]="getIcon(task.verticalType, task.nodeType)"
                    alt="icon"
                  />
                  <span
                    *ngIf="task.nodeType == 'OPERATIVE_NODE'"
                    class="gantt-task-data-service"
                    >{{ getVertical(task.verticalType) + " " }}</span
                  >
                  <span
                    *ngIf="task.nodeType == 'TRANSPORT_NODE'"
                    class="gantt-task-data-service"
                  >
                    Tte. público
                  </span>
                  <span
                    *ngIf="task.nodeType == 'SWEEPER_NODE'"
                    class="gantt-task-data-service"
                  >
                    Coche escoba
                  </span>
                </div>
                <div
                  *ngIf="task.nodeType == 'OPERATIVE_NODE'"
                  class="minutes gantt-task-info"
                >
                  {{ task.licensePlate + " · " + task.vehicleChassis }}
                </div>
                <div
                  *ngIf="task.nodeType == 'TRANSPORT_NODE'"
                  class="minutes gantt-task-info"
                >
                  {{ task.totalTime }}
                </div>
                <div
                  *ngIf="task.nodeType == 'SWEEPER_NODE'"
                  class="minutes gantt-task-info"
                >
                  {{ task.driverName + " · " + task.totalTime }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="gantt-task"
            *ngFor="let task of row.value.data"
            [ngStyle]="getPositionStyle(task)"
            [ngClass]="getStatusStyle(task)"
            title="{{ task.licensePlate }} - {{ task.orderHash }}"
          >
            <div
              (click)="openModal(serviceDataModal, task)"
              class="gantt-task-data"
            >
              <div class="gantt-task-products">
                <div
                  class="gantt-task-product"
                  *ngFor="let productType of task.productTypes"
                >
                  <span>{{ getServiceType(productType) }}</span>
                </div>
              </div>
              <div class="gantt-task-info">
                <img
                  style="height: 24px"
                  *ngIf="task.priority"
                  src="../../../assets/icons/priority-service.svg"
                  alt="icon"
                />

                <img
                  sstyle="height: 24px;"
                  [src]="getIcon(task.verticalType)"
                  alt="icon"
                />
                <span class="gantt-task-data-service">{{
                  getVertical(task.verticalType) + " "
                }}</span>
                <span class="gantt-task-data-lp">{{ task.licensePlate }}</span>
                <span class="gantt-task-data-ch"> · {{ task.caflerHash }}</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <ng-template #emptyGantt>
      <div
        class="d-flex flex-column align-items-center w-100 no-services-content"
      >
        <div class="empty-home-page d-flex flex-column align-items-center mt-4">
          <h4>{{ "service_manager.gantt.no_services_header" | translate }}</h4>
          <p class="text-center">
            {{ "service_manager.gantt.no_services_text" | translate }}
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #serviceDataModal>
  <app-service-data-modal
    [bsModalRef]="bsModalRef"
    [serviceData]="serviceData"
    [zoneId]="selectedZone"
    [zones]="zones"
  ></app-service-data-modal>
</ng-template>

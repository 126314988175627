import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
@Component({
  selector: 'app-temporal-assigner-data-table',
  templateUrl: './temporal-assigner-data-table.component.html',
  styleUrls: ['./temporal-assigner-data-table.component.css'],
})
export class TemporalAssignerDataTableComponent {
  //modal reference cesar-gutierrez
  bsModalRef?: BsModalRef;
  countries = ['España', 'UK', 'Francia'];

  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];

  operatorsSelect = ['Interno', 'Externo'];

  rolesSelect = ['Gerente', 'Supervisor de Mantenimiento', 'Coordinador'];

  services = ['Servicio 1', 'Servicio 2', 'Servicio 3'];

  hours = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);
  hours2 = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);

  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];

  readonly countriesPhoneExtra: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];

  countryIsoCode = TuiCountryIsoCode.ES;
  countryIsoCodeExtra = TuiCountryIsoCode.ES;
  sortDirection!: any;
  searchTerm: string = '';
  sortedColumn: string = '';
  assignerData: any = [
    {
      licensePlate: 'ABC-1234',
      orderNumber: '001',
      executedBy: 'Maria Garcia',
      active: true,
      date: '15/10/2023',
      startTime: '09:00',
      endTime: '18:00',
      operator: 'Externo',
      role: 'Conductor',
      motorcycle: true,
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Gran Via, 2, Madrid',
    },
    {
      licensePlate: 'DEF-5678',
      orderNumber: '002',
      executedBy: 'Juan Lopez',
      active: true,
      date: '01/06/2022',
      startTime: '08:00',
      endTime: '17:00',
      operator: 'Interno',
      role: 'Supervisor',
      motorcycle: false,
      pickupAddress: 'Calle Aragon, 5, Barcelona',
      deliveryAddress: 'Calle Balmes, 6, Barcelona',
    },
    {
      licensePlate: 'GHI-9101',
      orderNumber: '003',
      executedBy: 'Laura Martinez',
      active: false,
      date: '20/03/2021',
      startTime: '10:00',
      endTime: '19:00',
      operator: 'Externo',
      role: 'Conductor',
      motorcycle: true,
      pickupAddress: 'Avenida del Puerto, 3, Valencia',
      deliveryAddress: 'Plaza del Ayuntamiento, 4, Valencia',
    },
    {
      licensePlate: 'JKL-1121',
      orderNumber: '004',
      executedBy: 'Carlos Sanchez',
      active: true,
      date: '12/11/2020',
      startTime: '07:00',
      endTime: '16:00',
      operator: 'Interno',
      role: 'Operador',
      motorcycle: false,
      pickupAddress: 'Calle Sierpes, 7, Sevilla',
      deliveryAddress: 'Avenida de la Constitución, 8, Sevilla',
    },
    {
      licensePlate: 'MNO-1314',
      orderNumber: '005',
      executedBy: 'Ana Perez',
      active: true,
      date: '05/05/2019',
      startTime: '11:00',
      endTime: '20:00',
      operator: 'Externo',
      role: 'Conductor',
      motorcycle: true,
      pickupAddress: 'Calle Ercilla, 9, Bilbao',
      deliveryAddress: 'Gran Via, 10, Bilbao',
    },
    {
      licensePlate: 'PQR-1516',
      orderNumber: '006',
      executedBy: 'Luis Ramirez',
      active: false,
      date: '22/08/2018',
      startTime: '06:00',
      endTime: '15:00',
      operator: 'Interno',
      role: 'Supervisor',
      motorcycle: false,
      pickupAddress: 'Calle Alfonso I, 11, Zaragoza',
      deliveryAddress: 'Avenida Independencia, 12, Zaragoza',
    },
    {
      licensePlate: 'STU-1718',
      orderNumber: '007',
      executedBy: 'Elena Torres',
      active: true,
      date: '10/01/2020',
      startTime: '12:00',
      endTime: '21:00',
      operator: 'Externo',
      role: 'Operador',
      motorcycle: true,
      pickupAddress: 'Calle Larios, 13, Malaga',
      deliveryAddress: 'Avenida de Andalucía, 14, Malaga',
    },
    {
      licensePlate: 'VWX-1920',
      orderNumber: '008',
      executedBy: 'Jorge Fernandez',
      active: true,
      date: '25/07/2017',
      startTime: '13:00',
      endTime: '22:00',
      operator: 'Interno',
      role: 'Conductor',
      motorcycle: false,
      pickupAddress: 'Gran Via, 15, Murcia',
      deliveryAddress: 'Calle Trapería, 16, Murcia',
    },
    {
      licensePlate: 'YZA-2122',
      orderNumber: '009',
      executedBy: 'Isabel Gomez',
      active: true,
      date: '14/09/2021',
      startTime: '14:00',
      endTime: '23:00',
      operator: 'Externo',
      role: 'Supervisor',
      motorcycle: true,
      pickupAddress: 'Calle San Francisco, 17, Alicante',
      deliveryAddress: 'Avenida de la Estación, 18, Alicante',
    },
    {
      licensePlate: 'BCD-2324',
      orderNumber: '010',
      executedBy: 'Miguel Diaz',
      active: true,
      date: '07/02/2023',
      startTime: '15:00',
      endTime: '00:00',
      operator: 'Interno',
      role: 'Operador',
      motorcycle: false,
      pickupAddress: 'Calle Cruz Conde, 19, Cordoba',
      deliveryAddress: 'Avenida Gran Capitán, 20, Cordoba',
    },
    {
      licensePlate: 'EFG-2526',
      orderNumber: '011',
      executedBy: 'Marta Ruiz',
      active: true,
      date: '18/12/2019',
      startTime: '16:00',
      endTime: '01:00',
      operator: 'Externo',
      role: 'Conductor',
      motorcycle: true,
      pickupAddress: 'Calle Santiago, 21, Valladolid',
      deliveryAddress: 'Paseo Zorrilla, 22, Valladolid',
    },
    {
      licensePlate: 'HIJ-2728',
      orderNumber: '012',
      executedBy: 'Pablo Gutierrez',
      active: false,
      date: '30/04/2020',
      startTime: '17:00',
      endTime: '02:00',
      operator: 'Interno',
      role: 'Supervisor',
      motorcycle: false,
      pickupAddress: 'C/ Larga nombre extenso, 56, 28022, Madrid',
      deliveryAddress: 'C/ Larga nombre extenso, 56, 28022, Madrid',
    },
    {
      licensePlate: 'KLM-2930',
      orderNumber: '013',
      executedBy: 'Cristina Alvarez',
      active: true,
      date: '23/11/2018',
      startTime: '18:00',
      endTime: '03:00',
      operator: 'Externo',
      role: 'Operador',
      motorcycle: true,
      pickupAddress: 'Calle Uria, 25, Oviedo',
      deliveryAddress: 'Plaza del Ayuntamiento, 26, Oviedo',
    },
    {
      licensePlate: 'NOP-3132',
      orderNumber: '014',
      executedBy: 'David Herrera',
      active: true,
      date: '15/03/2022',
      startTime: '19:00',
      endTime: '04:00',
      operator: 'Interno',
      role: 'Conductor',
      motorcycle: false,
      pickupAddress: 'Calle Burgos, 27, Santander',
      deliveryAddress: 'Avenida de los Castros, 28, Santander',
    },
    {
      licensePlate: 'QRS-3334',
      orderNumber: '015',
      executedBy: 'Sara Moreno',
      active: true,
      date: '27/10/2021',
      startTime: '20:00',
      endTime: '05:00',
      operator: 'Externo',
      role: 'Supervisor',
      motorcycle: true,
      pickupAddress: 'Calle Toro, 29, Salamanca',
      deliveryAddress: 'Avenida de Mirat, 30, Salamanca',
    },
    {
      licensePlate: 'TUV-3536',
      orderNumber: '016',
      executedBy: 'John Doe',
      active: true,
      date: '12/01/2023',
      startTime: '10:00',
      endTime: '19:00',
      operator: 'Externo',
      role: 'Conductor',
      motorcycle: true,
      pickupAddress: '123 Main St, New York',
      deliveryAddress: '456 Elm St, New York',
    },
    {
      licensePlate: 'WXY-3738',
      orderNumber: '017',
      executedBy: 'Jane Smith',
      active: false,
      date: '05/06/2022',
      startTime: '09:00',
      endTime: '18:00',
      operator: 'Interno',
      role: 'Supervisor',
      motorcycle: false,
      pickupAddress: '1 Oxford St, London',
      deliveryAddress: '2 Baker St, London',
    },
    {
      licensePlate: 'ZAB-3940',
      orderNumber: '018',
      executedBy: 'Akira Yamada',
      active: true,
      date: '08/07/2021',
      startTime: '11:00',
      endTime: '20:00',
      operator: 'Externo',
      role: 'Conductor',
      motorcycle: true,
      pickupAddress: '3 Shibuya, Tokyo',
      deliveryAddress: '4 Roppongi, Tokyo',
    },
    {
      licensePlate: 'CDE-4142',
      orderNumber: '019',
      executedBy: 'Maria Rossi',
      active: true,
      date: '15/08/2020',
      startTime: '08:00',
      endTime: '17:00',
      operator: 'Interno',
      role: 'Operador',
      motorcycle: false,
      pickupAddress: '5 Via Roma, Rome',
      deliveryAddress: '6 Via Veneto, Rome',
    },
    {
      licensePlate: 'FGH-4344',
      orderNumber: '020',
      executedBy: 'Chen Wei',
      active: true,
      date: '22/09/2019',
      startTime: '07:00',
      endTime: '16:00',
      operator: 'Externo',
      role: 'Supervisor',
      motorcycle: true,
      pickupAddress: '7 Nanjing Rd, Shanghai',
      deliveryAddress: "8 People's Square, Shanghai",
    },
  ];
  paginatedData: any[] = [];
  filterCount: number = this.paginatedData.length;
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  statuses!: any[];
  operators!: any[];
  roles!: any[];
  countrys!: any[];
  selectedZone: string = '#';
  selectedStatus: any = [];
  filterDisabled: boolean = false;
  selectedOperator: any = [];
  selectedRole: any = [];
  readonly data = [
    {
      title: {
        icon: 'tuiIconFile',
        title: 'This is title',
        chip: 'Chip',
        subtitle: 'More information ・ Data',
      },
      cell: {
        name: 'John Cleese',
        email: 'silly@walk.uk',
      },
      status: {
        value: 'Success',
        color: 'var(--tui-success-fill)',
      },
      items: ['Some', 'items', 'displayed', 'here', 'and', 'can', 'overflow'],
      progress: 78,
      selected: false,
    },
    {
      title: {
        icon: 'tuiIconHeart',
        title: 'More info',
        chip: 'Chips can be here',
      },
      cell: {
        name: 'Eric Idle',
        email: 'cool@dude.com',
      },
      status: {
        value: 'Failure',
        color: 'var(--tui-error-fill)',
      },
      items: ['One', 'Item'],
      progress: 91,
      selected: false,
    },
    {
      title: {
        icon: 'tuiIconStar',
        title: 'Wow',
      },
      cell: {
        name: 'Michael Palin',
        email: 'its@man.com',
      },
      status: {
        value: 'Pending',
        color: 'var(--tui-warning-fill)',
      },
      items: [],
      progress: 32,
      selected: false,
    },
  ];
  index: number = 0;
  length!: number;
  pageSize: number = 6;
  selectedCountry: any = '#';

  readonly columns = [
    'registrationAndOrderNumber',
    'date',
    'executedBy',
    'active',
    'startTime',
    'endTime',
    'operator',
    'role',
    'motorcycle',
    'pickupAddress',
    'deliveryAddress',
    'actions',
  ];

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  readonly items = ['Edit', 'Download', 'Rename', 'Delete'];

  readonly selectItems = ['Item 1', 'Item 2'];

  open = false;

  selected = null;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private modalService: BsModalService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.length = Math.ceil(this.assignerData.length / this.pageSize);
    this.paginatedDataOperation();
    this.zones = [...new Set(this.assignerData.map((item: any) => item.zone))];
    this.statuses = [
      ...new Set(
        this.assignerData.map((item: any) =>
          item.active ? 'Active' : 'Inactive'
        )
      ),
    ];
    this.operators = [
      ...new Set(this.assignerData.map((item: any) => item.operator)),
    ];
    this.roles = [...new Set(this.assignerData.map((item: any) => item.role))];
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    const start = this.index * this.pageSize;
    this.paginatedData = this.assignerData.slice(start, start + this.pageSize);
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }

  filterData(searchText: string) {
    this.index = 0;
    if (!searchText) {
      this.searchTerm = '';
      this.length = Math.ceil(this.assignerData.length / this.pageSize);
      this.paginatedDataOperation();
      this.paginatedData = this.assignerData;

      if (this.selectedStatus != '#') {
        const isActive = this.selectedStatus === 'Active';
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.active === isActive
        );
      }

      if (this.selectedRole != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.role === this.selectedRole
        );
      }

      if (this.selectedOperator != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.operator === this.selectedOperator
        );
      }

      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    } else {
      this.searchTerm = searchText;
      this.paginatedData = this.assignerData;

      if (this.selectedStatus != '#') {
        const isActive = this.selectedStatus === 'Active';
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.active === isActive
        );
      }

      if (this.selectedRole != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.role === this.selectedRole
        );
      }

      if (this.selectedOperator != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.operator === this.selectedOperator
        );
      }

      this.paginatedData = this.paginatedData.filter((item: any) => {
        const fullName = item.executedBy.toLowerCase();
        const search = searchText.toLowerCase();
        return fullName.includes(search);
      });

      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    }
  }

  filterDataBySelect(index?: any) {
    this.paginatedData = this.assignerData;
    this.index = index ?? 0;
    console.log(this.selectedStatus, 'ASD');
    if (
      this.selectedOperator.length === 0 &&
      this.selectedRole.length === 0 &&
      this.selectedStatus.length === 0 &&
      !this.searchTerm
    ) {
      this.sortByExec();
      this.applyPagination();
      return;
    }

    // if (this.searchTerm) {
    //   this.paginatedData = this.paginatedData.filter((item: any) => {
    //     const fullName = item.executedBy.toLowerCase();
    //     const search = this.searchTerm.toLowerCase();
    //     return fullName.includes(search);
    //   });
    // }

    // if (this.selectedStatus != '#') {
    //   const isActive = this.selectedStatus === 'Active';
    //   this.paginatedData = this.paginatedData.filter(
    //     (item: any) => item.active === isActive
    //   );
    // }

    // if (this.selectedRole != '#') {
    //   this.paginatedData = this.paginatedData.filter(
    //     (item: any) => item.role === this.selectedRole
    //   );
    // }

    // if (this.selectedOperator != '#') {
    //   this.paginatedData = this.paginatedData.filter(
    //     (item: any) => item.operator === this.selectedOperator
    //   );
    // }

    // this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    // if (this.length == 1) {
    //   this.index = 0;
    // }
    // const start = this.index * this.pageSize;
    // this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
    console.log(this.paginatedData, 'AS');
    this.paginatedData = this.paginatedData.filter((item: any) => {
      const executedBy = item.executedBy.toLowerCase();

      const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

      const matchesStatus =
        this.selectedStatus.length === 0 ||
        this.selectedStatus.includes(
          item.active == true ? 'Active' : 'Inactive'
        );

      const matchesOperators =
        this.selectedOperator.length === 0 ||
        this.selectedOperator.includes(item.operator);

      const matchesRoles =
        this.selectedRole.length === 0 || this.selectedRole.includes(item.role);

      const matchesSearch = !this.searchTerm || executedBy.includes(search);

      return matchesStatus && matchesOperators && matchesRoles && matchesSearch;
    });

    this.sortByExec();
    this.applyPagination();
  }

  applyPagination() {
    this.filterCount = this.paginatedData.length;
    const start = this.index * this.pageSize;
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

    if (this.length == 1) {
      this.index = 0;
    }
  }

  filterDataBySelectPaginate() {
    let filteredData = this.assignerData;

    if (this.selectedStatus != '#') {
      const isActive = this.selectedStatus === 'Active';
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.active === isActive
      );
    }

    if (this.selectedRole != '#') {
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.role === this.selectedRole
      );
    }

    if (this.selectedOperator != '#') {
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.operator === this.selectedOperator
      );
    }

    this.length = Math.ceil(filteredData.length / this.pageSize);
    const start = this.index * this.pageSize;
    this.paginatedData = filteredData.slice(start, start + this.pageSize);
  }

  sortData(): void {
    // this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  setSortColumn(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }
}

import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild, TemplateRef, Input, NgZone, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl } from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { TuiDay  } from '@taiga-ui/cdk';
declare var  moment: any
declare var google: any;
import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';
import * as momentTz from 'moment-timezone';
@Component({
  selector: 'app-modal-gestion-node',
  templateUrl: './modal-gestion-node.component.html',
  styleUrls: ['./modal-gestion-node.component.css']
})
export class ModalGestionNodeComponent {
    @Input() order_hash: any;
    @Input() service_info: any;
    @Input() nodeInfo: any;
    active_provider:any;
    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas,
        private hs:HistoryServicesService,
        private ngZone: NgZone,
        private ms: MapaServiceService,
        private renderer: Renderer2
    ) {}

    assignForm = this.fb.group({
        orderHash:['', Validators.required],
        // nodeStatus:[null, Validators.required],
        nodeDate:['', Validators.required],
        nodeStartHour:['', Validators.required],
        nodeEndHour:['', Validators.required],
    });
    filterForm = this.fb.group({
        nameFilter:''
    });
    elements_providers:any;
    elements_providers2:any;
    zones_options:any;

    statusList = [
        { enum: 'INITIALIZED', name: 'Inicializado' },
        { enum: 'PENDING', name: 'Pendiente' },
        { enum: 'CONFIRMED', name: 'Confirmado' },
        { enum: 'PICKING_UP', name: 'Recogiendo' },
        { enum: 'IN_PROGRESS', name: 'En proceso' },
        { enum: 'DELIVERING', name: 'Entregando' },
        { enum: 'FINISHED', name: 'Finalizado' },
        { enum: 'FAILED', name: 'Fallido' },
        { enum: 'CANCELLED_BY_CAFLER', name: 'Cancelado por Cafler' },
        { enum: 'CANCELLED_BY_CLIENT', name: 'Cancelado por cliente' }
    ];
    pickupStartHour: any;
    pickupEndHour: any;
    ngOnInit(){
        this.hs.getZones().subscribe((zones: any) => {
            this.zones_options = zones.zones;

            let textPickupStartHour = this.service_info.serviceDates.servicePickupDateRangeStart.time.split(':');
            let textPickupEndHour = this.service_info.serviceDates.servicePickupDateRangeEnd.time.split(':');

            if(!this.nodeInfo.nodeStartHour){
                this.pickupStartHour = textPickupStartHour[0]+':'+textPickupStartHour[1];
            }else{
                this.pickupStartHour = this.formatTimeNodo(this.nodeInfo.nodeStartHour.completeDate);
                // this.pickupStartHour = this.getDateNodo(this.nodeInfo.nodeStartHour.time);
            }

            if(!this.nodeInfo.nodeEndHour){
                this.pickupEndHour = moment(this.pickupStartHour, 'HH:mm').add(30, 'minutes').format('HH:mm');
            }else{
                // this.pickupEndHour = this.getDateNodo(this.nodeInfo.nodeEndHour.time);
                this.pickupEndHour = this.formatTimeNodo(this.nodeInfo.nodeEndHour.completeDate);
            }

            this.assignForm.patchValue({
                orderHash:this.order_hash,
                nodeStartHour:this.pickupStartHour,
                nodeEndHour:this.pickupEndHour,
                nodeDate:this.formatDateNodo(this.nodeInfo.nodeStartHour.completeDate),
                // nodeDate:this.getDateFull(this.nodeInfo.nodeDate.seconds, this.service_info.zoneId),
                // nodeStatus:this.nodeInfo.nodeStatus
            });
        });

        this.showLoader();

        this.hs.getProviders(this.service_info?.zoneId).subscribe((providers: any) => {
            this.elements_providers2 = providers.providersInZone;

            this.elements_providers = this.elements_providers2
            .filter((pro: any) =>
              pro.productIds.includes(this.service_info.products[0].productId)
            )
            .map((pro: any) => pro);

            this.hideLoader();
        });
    }

    formatDateNodo(isoDate: string): string {
        return isoDate.split('T')[0].split('-').join('-');
    }

    formatTimeNodo(isoDate: string): string {
        return isoDate.split('T')[1].split(':').slice(0, 2).join(':');
    }

    filterData(event: Event): void {
        const query = (event.target as HTMLInputElement).value.toLowerCase();
        this.elements_providers = this.elements_providers2.filter((item:any) =>
            item.name.toLowerCase().includes(query) &&
            item.productIds.includes(this.service_info.products[0].productId)
        );
    }

    closeCanvas(){
        this.offcanvasService.dismiss();
    }

    div_loder = document.getElementById('loader');
    showLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'flex');
        }
    }

    hideLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'none');
        }

        setTimeout(()=>{
            const closeDiv = document.querySelector('.t-close');
            if (closeDiv) {
              (closeDiv as HTMLElement).click();
            }
        },5000);
    }

    showLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'flex'; // Agrega display: flex al div
        }
    }

    hideLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'none'; // Agrega display: flex al div
        }
    }

    showErrorMsj(){
        setTimeout(()=>{
            const sourceElement = document.querySelector('tui-alert');
            const targetElement = document.getElementById('modal_gestion_nodo_alert');
            if (sourceElement && targetElement) {
                // console.log('replicar');
                const clonedNode = sourceElement.cloneNode(true);
                // targetElement!.innerHTML = '';
                targetElement.appendChild(sourceElement);
            }
        },300);
    }

    assignProvider(){
        this.showLoaderModal();

        let tz = this.zones_options.find(
            (p: any) => p.zoneId === this.service_info.zoneId
        );

        // let dts  = momentTz(this.assignForm.value.nodeDate+' '+this.assignForm.value.nodeStartHour).tz(tz.timeZone);
        let dts  = momentTz.tz(momentTz(this.assignForm.value.nodeDate+' '+this.assignForm.value.nodeStartHour, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'), tz.timeZone).format();

        // let dte  = momentTz(this.assignForm.value.nodeDate+' '+this.assignForm.value.nodeEndHour).tz(tz.timeZone);
        let dte  = momentTz.tz(momentTz(this.assignForm.value.nodeDate+' '+this.assignForm.value.nodeEndHour, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss'), tz.timeZone).format();

        let ssd = `${dts} ${tz.timeZone}`;
        let sed = `${dte} ${tz.timeZone}`;

        let data = {
          desiredAssignedStartTime:{
            completeDate:ssd
          },
          desiredAssignedEndTime:{
            completeDate:sed
          },
          nodeId:this.nodeInfo.nodeId,
          orderHash:this.service_info.orderHash
        };

        this.hs.updateServiceAssignedHour(data).subscribe(
            (res: any) => {
                this.hideLoaderModal();
                if (!res.wasSuccessful && res?.statusCode) {
                    this.hs.showErrorCode(res.statusCode);
                    this.showErrorMsj();
                } else {
                    this.offcanvasService.dismiss();
                    this.hs.publish('updateDetailsService');
                    this.showDepositAlert(
                        'Exito!',
                        'Se ha editado correctamente el nodo'
                    );
                }
            },
            (error) => {
                this.hideLoaderModal();
                if (error.status === 500) {
                    this.alerts
                      .open('Problema en el servidor', {
                        label: 'Error 500',
                        status: 'error',
                        autoClose: false,
                      })
                      .subscribe();
                    this.showErrorMsj();
                } else {
                    this.alerts
                      .open(error, {
                        label: 'Otro error',
                        status: 'error',
                        autoClose: false,
                      })
                      .subscribe();
                    this.showErrorMsj();
                }
            }
        );

        // this.offcanvasService.dismiss();
        // this.hs.publish('updateDetailsService');
        // this.showDepositAlert(
        //     'Exito!',
        //     'Se ha editado correctamente el nodo'
        // );
    }

    showDepositAlert(title:any,msj:any): void {
        this.alerts.open(msj, {
            label: title,
            status: 'success',
            autoClose: true,
        }).subscribe();
    }

    selectprovider(provider:any){
        // this.assignForm.patchValue({
        //     providerId:provider.id
        // });

        // this.active_provider = provider.id;
    }

    getDateHour(seconds: any) {
        const date = new Date(seconds * 1000);
        let zoneId = this.service_info.zoneId;
        if(this.zones_options){
            console.log('2');
            const zone = this.zones_options.find((zone:any) => zone.zoneId === zoneId);
            const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
            const IsCh = dateInClientZone.isDST();
            if(IsCh){
                dateInClientZone.subtract(1, 'hour');
            }
            return dateInClientZone.format('HH:mm');
        }else{
            this.hs.getZones().subscribe((zones: any) => {
                let zon = zones.zones;
                const zone = zon.find((zone:any) => zone.zoneId === zoneId);
                const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
                const IsCh = dateInClientZone.isDST();
                if(IsCh){
                    dateInClientZone.subtract(1, 'hour');
                }
                return dateInClientZone.format('HH:mm');
            });
        }
    }

    getDateNodo(time:any){
        let textTime = time.split(':');
        return textTime[0]+':'+textTime[1];
    }

    getDateFull(seconds: any, zoneId: any) {
        const zone = this.zones_options.find((zone:any) => zone.zoneId === zoneId);
        const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
        return dateInClientZone.format('YYYY-MM-DD');
    }

    timeToNotUnix(h: any) {
        var hours = h;
        let today = moment(
          this.assignForm.value.nodeDate+' '+hours
        ).format('DD/MM/YYYY HH:mm:ss.SSS');
        
        const dateTimeString = `${today}`;
        return moment.utc(dateTimeString, 'DD/MM/YYYY HH:mm:ss.SSS').unix();
    }

    onTimeInput(input:any): void {
        let timeControl = this.assignForm.value.nodeStartHour;
        if(input == 'end'){
            timeControl = this.assignForm.value.nodeEndHour;
        }
        if (timeControl !== null && timeControl !== undefined) {
            let value = timeControl.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

            // Formatear el valor en HH:MM
            if (value.length >= 3) {
                value = `${value.slice(0, 2)}:${value.slice(2, 4)}`;
            }

            if(value.length >= 5){
                if (value && /^([01]\d|2[0-3]):([0-5]\d)$/.test(value)) {
                    const [hours, minutes] = value.split(':').map(Number);
                    if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
                        if(input == 'start'){
                            this.assignForm.patchValue({
                                nodeStartHour: value
                            });
                        }
                        if(input == 'end'){
                            this.assignForm.patchValue({
                                nodeEndHour: value
                            });
                        }
                    }else{
                        if(input == 'start'){
                            this.assignForm.patchValue({
                                nodeStartHour: null
                            });
                        }
                        if(input == 'end'){
                            this.assignForm.patchValue({
                                nodeEndHour: null
                            });
                        }
                    }
                }else{
                    if(input == 'start'){
                        this.assignForm.patchValue({
                            nodeStartHour: null
                        });
                    }
                    if(input == 'end'){
                        this.assignForm.patchValue({
                            nodeEndHour: null
                        });
                    }
                }
            }
        }
    }
}

<!-- 
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <div class="icon-container"  >
      <img src="../../../assets/Cafler_Logo.svg" alt="">
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul style="gap: 15px;" class="navbar-nav me-auto mb-2 mb-lg-0">
        <li  class="nav-item">
          <a class="nav-link" routerLinkActive="active"   (click)="redirect('home')" routerLink="home"   >Home</a>
        </li>
        <li  class="nav-item">
          <a class="nav-link" routerLinkActive="active"   (click)="redirect('operational-staff')" routerLink="operational-staff"  aria-current="page" >Personal</a>
        </li>
        <li   class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="assigner" >Asignador</a>
        </li>
        <li   class="nav-item">
          <a class="nav-link" routerLinkActive="active" (click)="redirect('service-manager')" routerLink="service-manager" >Service Manager</a>
        </li>
        <li   routerLinkActive="active" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="providers">Proveedores</a>
        </li>
        <li  class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="clients">Clientes</a>
        </li>
      </ul>
      <div class="icons-right-menu">
            <img src="../../../assets/icons/tui-button.svg" alt="">
            <img src="../../../assets/icons/avatar.svg" class="mr_10" alt="">
            <button  class="logout_button" (click)="openModalLogout(templateModalLogout)">
                <tui-svg src="tuiIconLogOut"></tui-svg> Salir
            </button>
      </div>
    </div>
  </div>
</nav> -->
<nav class="navbar bg-body-tertiary">
  <div class="container-fluid d-flex justify-content-between align-items-center">
    <div class="icon-container">
      <img src="../../../assets/Cafler_Logo.svg" alt="">
    </div>
    <ul class="navbar-nav d-flex flex-row gap-3 mb-0 me-auto">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active"  routerLink="home">{{ 'menu.home' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active"  routerLink="operational-staff" aria-current="page">{{ 'menu.personal' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="assigner">{{ 'menu.assigner' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="service-manager">{{ 'menu.service_manager' | translate }}</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="providers">{{ 'menu.providers' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="clients">{{ 'menu.clients' | translate }}</a>
      </li> -->
    </ul>
    <div class="icons-right-menu d-flex align-items-center gap-2">
     <!--  <img src="../../../assets/icons/tui-button.svg" alt="">
      <img src="../../../assets/icons/avatar.svg" class="mr_10" alt=""> -->

        <!-- <select (change)="selectLanguage()" [(ngModel)]="language" class="select_languaje">
            <option value="es">
                <img src="/assets/img/es.png" width="120" alt=""> ES
            </option>
            <option value="en">EN</option>
        </select> -->
        <button  class="languaje_menu" type="button" (click)="onClick2()" [tuiDropdown]="dropdownContent" [tuiDropdownManual]="open">
             <img [src]="'/assets/img/'+language+'.png'" width="20" alt=""> {{ language.toUpperCase() }}
            <ng-template #dropdownContent>
                <div class="dropdown">
                    <div class="dropdown-options" [class.active_lan]="language == 'es'"  (click)="selectLanguage('es')">
                        <img src="/assets/img/es.png" width="20"> ES
                    </div>
                    <div class="dropdown-options" [class.active_lan]="language == 'en'" (click)="selectLanguage('en')" >
                        <img src="/assets/img/en.png" width="22"> EN
                    </div>
                    <div class="dropdown-options" [class.active_lan]="language == 'fr'" (click)="selectLanguage('fr')" >
                        <img src="/assets/img/fr.jpg" width="20"> FR
                    </div>
                </div>
            </ng-template>
        </button>

      <button class="logout_button" (click)="openModalLogout(templateModalLogout)">
        <tui-svg src="tuiIconLogOut"></tui-svg> {{ 'menu.logout' | translate }}
      </button>
    </div>
  </div>
</nav>


<ng-template #templateModalLogout>
    <div class="modal-body">
        <p class="fs_28">
            {{ 'menu.confirm_logout' | translate }}
        </p>
        <p class="fs_15">
            {{ 'menu.complement_logout' | translate }}
        </p>
        <div class="right_buttons">
            <button class="close_modal_logout" (click)="modalRef?.hide()">
                {{ 'menu.go_back' | translate }}
            </button>
            <button class="logout_but" (click)="logout()">
                {{ 'menu.logout' | translate }}
            </button>
        </div>
    </div>
</ng-template>
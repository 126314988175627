<tui-root>
    <ng-container *ngIf="shouldShowNavbar()">
        <app-navbar></app-navbar>
    </ng-container>
    <router-outlet></router-outlet>
    <ng-container ngProjectAs="tuiOverContent">
        <!-- Content over app content -->
    </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over alerts -->
  </ng-container>
  <ng-container #div_dropdowns ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <div>
    
  </div>
  
  
</tui-root>


<div id="loader" #loader_all>
    <img src="/assets/logo-loading.gif" class="img_loader" alt="">
</div>


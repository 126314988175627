import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

const fetchUser = async (accessToken: string, userId: string) => {
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Error al obtener el usuario');
  }

  const user = await response.json();
  return user;
};

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  url = environment.url;
  ganttDataParams: any = {};
  public clients: any = [];

  actualAccount: any = null;

  constructor(private authService: MsalService, private http: HttpClient) {
    // this.acquireMSClient('df29dab1-ac1b-4aff-8579-f4d4f130df40');
  }

  getZones() {
    return this.http.get(`${this.url}/zones`);
  }

  getServices(serviceData: any) {
    return this.http.post(`${this.url}/services`, serviceData);
  }
  getGantt(ganttData: any) {
    return this.http.post(`${this.url}/gantt`, ganttData);
  }
  getDriver(driverId: string) {
    return this.http.get(`${this.url}/drivers?driverIdFilter=${driverId}`);
  }

  getDriverByName(driverId: string) {
    return this.http.get(`${this.url}/drivers?nameFilterQuery=${driverId}`);
  }

  acquireMSClient(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.authService.instance.getAllAccounts().length) {
        this.actualAccount = this.authService.instance.getAllAccounts()[0];

        const request = {
          scopes: ['User.Read.All'],
          account: this.actualAccount,
        };

        const existingClient = this.clients.find((x: any) => x.id === id);
        if (existingClient) {
          resolve(existingClient);
        } else {
          this.authService.instance
            .acquireTokenSilent(request)
            .then((response) => {
              fetchUser(response.accessToken, id)
                .then((user) => {
                  if (!this.clients.find((x: any) => x.id === id)) {
                    this.clients.push(user);
                  }
                  resolve(user);
                })
                .catch((error) => {
                  console.error('Error fetching user:', error);
                  reject(error);
                });
            })
            .catch((error) => {
              console.error('Error al renovar el token:', error);

              this.authService.instance
                .acquireTokenPopup(request)
                .then((response) => {
                  console.log('Token renovado mediante popup');
                  fetchUser(response.accessToken, id)
                    .then((user) => {
                      if (!this.clients.find((x: any) => x.id === id)) {
                        this.clients.push(user);
                      }
                      resolve(user);
                    })
                    .catch((error) => {
                      console.error('Error fetching user:', error);
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.error(
                    'Error al renovar el token mediante popup:',
                    error
                  );
                  reject(error);
                });
            });
        }
      } else {
        reject('No account found');
      }
    });
  }
}

import { Permission } from '../enum/permission.enum';

export const PERMISSIONS_BY_TID: Record<
  string,
  { visible: Permission[]; hidden: Permission[] }
> = Object.entries({
  // Transitalia -> Acceso completo
  '3c9528a9-1142-4f50-b66b-65b3530c6a66': {
    visible: [
      Permission.HOME,
      Permission.PERSONAL,
      Permission.ASSIGNER,
      Permission.SERVICE_MANAGER,
      Permission.PROVIDERS,
      Permission.CLIENTS,
      Permission.COMMERCIAL,
    ],
    hidden: [],
  },
  // Cafler -> Acceso completo
  '8A70B3EC-99A0-4AA9-8B6E-CDAFA3563A5F': {
    visible: [
      Permission.HOME,
      Permission.PERSONAL,
      Permission.ASSIGNER,
      Permission.PROVIDERS,
      Permission.COMMERCIAL,
      Permission.CLIENTS,
      Permission.SERVICE_MANAGER,
    ],
    hidden: [],
  },
  // Copec -> No tiene acceso a Provedores y COMMERCIAL
  '79ec8f0d-1881-4537-96ec-570c998ca881': {
    visible: [
      Permission.HOME,
      Permission.PERSONAL,
      Permission.ASSIGNER,
      Permission.SERVICE_MANAGER,
      Permission.CLIENTS,
    ],
    hidden: [Permission.COMMERCIAL, Permission.PROVIDERS],
  },
}).reduce((acc, [key, value]) => {
  acc[key.toLowerCase()] = value;
  return acc;
}, {} as Record<string, { visible: Permission[]; hidden: Permission[] }>);

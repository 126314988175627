<nav class="navbar bg-body-tertiary">
  <div
    class="container-fluid d-flex justify-content-between align-items-center"
  >
    <!-- Logo -->
    <div class="icon-container">
      <img src="../../../assets/Cafler_Logo.svg" alt="Cafler Logo" />
    </div>

    <!-- Menú de navegación -->
    <ul class="navbar-nav d-flex flex-row gap-3 mb-0 me-auto">
      <li class="nav-item" *ngFor="let item of menuItems">
        <a
          class="nav-link"
          routerLinkActive="active"
          [routerLink]="item.link"
          aria-current="page"
        >
          {{ item.label | translate }}
        </a>
      </li>
    </ul>

    <!-- Menú lateral derecho -->
    <div class="icons-right-menu d-flex align-items-center gap-2">
      <!-- Selección de idioma -->
      <button
        class="language-menu"
        type="button"
        (click)="toggleDropdown()"
        [tuiDropdown]="dropdownContent"
        [tuiDropdownManual]="open"
        aria-label="Select Language"
      >
        <img
          [src]="'/assets/img/' + language + '.png'"
          width="20"
          alt="Language icon"
        />
        {{ language.toUpperCase() }}
        <ng-template #dropdownContent>
          <div class="dropdown">
            <div
              *ngFor="let lang of availableLanguages"
              class="dropdown-options"
              [class.active]="language === lang.code"
              (click)="selectLanguage(lang.code)"
            >
              <img
                [src]="'/assets/img/' + lang.code + '.png'"
                width="20"
                alt="{{ lang.name }}"
              />
              {{ lang.name }}
            </div>
          </div>
        </ng-template>
      </button>

      <!-- Botón de cierre de sesión -->
      <button
        class="logout-button"
        (click)="openModalLogout(templateModalLogout)"
        aria-label="Logout"
      >
        <tui-svg src="tuiIconLogOut"></tui-svg>
        {{ "menu.logout" | translate }}
      </button>
    </div>
  </div>
</nav>

<!-- Modal de confirmación para cierre de sesión -->
<ng-template #templateModalLogout>
  <div class="modal-body">
    <p class="fs-28">{{ "menu.confirm_logout" | translate }}</p>
    <p class="fs-15">{{ "menu.complement_logout" | translate }}</p>
    <div class="right-buttons">
      <button class="close-modal-logout" (click)="modalRef?.hide()">
        {{ "menu.go_back" | translate }}
      </button>
      <button class="logout-button" (click)="logout()">
        {{ "menu.logout" | translate }}
      </button>
    </div>
  </div>
</ng-template>


<!-- 
  [ER - 17/03/2025 - I let this code commented because I don't know if we will need it in the future ]
  <li class="nav-item">
    <a class="nav-link" routerLinkActive="active" routerLink="pool-manager">
      Pool Manager
    </a>
  </li>
-->

<!-- 
  <img src="../../../assets/icons/tui-button.svg" alt="">
  <img src="../../../assets/icons/avatar.svg" class="mr_10" alt="">
-->

<!-- 
  <select (change)="selectLanguage()" [(ngModel)]="language" class="select_language">
    <option value="es">
      <img src="/assets/img/es.png" width="120" alt=""> ES
    </option>
    <option value="en">EN</option>
  </select>
-->

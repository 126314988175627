<div class="modal-all">    
    <div class="modal-header">
        <h4 class="modal-title pull-left">Nueva asignación</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeCanvas()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
   <div class="modal-body">
        <div id="modal_add_block"></div>
        <form [formGroup]="assignamentForm" (ngSubmit)="saveAssignament()" class="d-flex flex-column gap-4">
            <div class="row">
            	<div class="form-group">
            		<b>
                        Fecha y hora
                    </b>
                    <div class="row dates_list">
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconCalendar" class="ison_fs_16"></tui-icon>
                    	</div>
                    	<div class="col-10">
                    		09/08/24 de 09:00 -14:00
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconTrash" class="ison_fs_16"></tui-icon>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconCalendar" class="ison_fs_16"></tui-icon>
                    	</div>
                    	<div class="col-10">
                    		09/08/24 de 09:00 -18:00
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconTrash" class="ison_fs_16"></tui-icon>
                    	</div>
                    </div>
                    <div class="row">
                    	<div class="col">
                    		<button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_add_date" size="m" appearance="secondary" icon="tuiIconPlusCircle">
					            Añadir más fechas
					        </button>
                    	</div>
                    </div>	
                    <br>
            	</div>
            	<div class="form-group mb_custom">
                	<div class="col-12">
	                    <label class="label_form">Nueva fecha*</label>
	                    <tui-input-date
	                        formControlName="date"
	                        tuiTextfieldSize="m" 
	                        [tuiTextfieldLabelOutside]="true"
	                        (focusedChange)="searchDiv2()"
	                        [tuiTextfieldCleaner]="true"
	                    >
	                        Selecciona la fecha
	                    </tui-input-date>
                	</div>
                </div>
                <div class="form-group form-group-select">
                	<div class="col check_div">
                        <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="allDay" size="m"></tui-checkbox> Todo el dia
                    </div>
                	<div class="row">
                        <div class="col">
                            <br>
                            <label class="label_form">Hora inicio de bloqueo</label>
                            <tui-input-time
                                formControlName="startTime"
                                class="input_form"
                                [items]="hours"
                                [tuiTextfieldLabelOutside]="true"
                                (focusedChange)="searchDiv2()"
                            >
                            </tui-input-time>
                        </div>
                        <div class="col">
                            <br>
                            <label class="label_form">Hora fin de bloqueo</label>
                            <tui-input-time
                                formControlName="endTime"
                                class="input_form"
                                [items]="hours"
                                [tuiTextfieldLabelOutside]="true"
                                (focusedChange)="searchDiv2()"
                            >
                            </tui-input-time>
                        </div>
                    </div>
                    <div class="row">
                    	<div class="col-12 t_r">
                    		<button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_cancel_date" size="m" appearance="secondary">
					            Cancelar
					        </button>
					        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_save_date" size="m" appearance="secondary">
					            Guardar fecha
					        </button>
                    	</div>
                    </div>
                </div>
                <div class="form-group">
            		<b>
                        Zonas operativas
                    </b>
                    <div class="row dates_list">
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconMapPin" class="ison_fs_16"></tui-icon>
                    	</div>
                    	<div class="col-10">
                    		Barcelona
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconTrash" class="ison_fs_16"></tui-icon>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconMapPin" class="ison_fs_16"></tui-icon>
                    	</div>
                    	<div class="col-10">
                    		Madrid
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconTrash" class="ison_fs_16"></tui-icon>
                    	</div>
                    </div>
                    <div class="row">
                    	<div class="col">
                    		<button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_add_date" size="m" appearance="secondary" icon="tuiIconPlusCircle">
					            Añadir más zonas
					        </button>
                    	</div>
                    </div>	
                    <br>
            	</div>
                <div class="form-group form-group-select">
                    <div class="col-12">
                        <label class="label_form">Zona operativa*</label>
                        <tui-select
                            tuiTextfieldSize="s"
                            formControlName="operativeZone"
                            class="input_form"
                            (click)="searchDiv('zone')"
                            [tuiTextfieldLabelOutside]="true"
                        >
                            Selecciona zona operativa
                            <tui-data-list-wrapper
                                *tuiDataList
                                [items]="operatives"
                            ></tui-data-list-wrapper>
                        </tui-select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label class="label_form">Dirección de entrega*</label>
                        <tui-input formControlName="startAdress" class="input_form" [tuiTextfieldLabelOutside]="true" tuiTextfieldIcon="tuiIconMapPin">
                            Escribe la dirección de entrega*
                        </tui-input>
                    </div>
                    <div class="row">
                    	<div class="col-12 t_r">
                    		<button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_cancel_date" size="m" appearance="secondary">
					            Cancelar
					        </button>
					        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_save_date" size="m" appearance="secondary">
					            Guardar zona
					        </button>
                    	</div>
                    </div>
                </div>

                <div class="form-group">
                	<br>
            		<b>
                        Productos (0 productos)
                    </b>
                    <div class="row dates_list">
                    	<div class="col-11">
                    		<div class="col check_div">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="revisiones" size="m"></tui-checkbox> Revisiones
	                        </div>
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconChevronDown" class="ison_fs_16" *ngIf="!openRevisiones" (click)="toggleDiv('revisiones')"></tui-icon>
                    		<tui-icon icon="tuiIconChevronUp" class="ison_fs_16" *ngIf="openRevisiones" (click)="toggleDiv('revisiones')"></tui-icon>
                    	</div>
                    	<div class="col-12" *ngIf="openRevisiones">
                    		<div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> ITV
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Pre-ITV
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> ITV Homologación
	                        </div>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-11">
                    		<div class="col check_div">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="lavado" size="m"></tui-checkbox> Lavado
	                        </div>
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconChevronDown" class="ison_fs_16" *ngIf="!openLavado" (click)="toggleDiv('lavado')"></tui-icon>
                    		<tui-icon icon="tuiIconChevronUp" class="ison_fs_16" *ngIf="openLavado" (click)="toggleDiv('lavado')"></tui-icon>
                    	</div>
                    	<div class="col-12" *ngIf="openLavado">
                    		<div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Centros especializados
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Tapicerías
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Integral
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Pulido de faros
	                        </div>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-11">
                    		<div class="col check_div">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="mantenimiento" size="m"></tui-checkbox> Mantenimiento
	                        </div>
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconChevronDown" class="ison_fs_16" *ngIf="!openMant" (click)="toggleDiv('mant')"></tui-icon>
                    		<tui-icon icon="tuiIconChevronUp" class="ison_fs_16" *ngIf="openMant" (click)="toggleDiv('mant')"></tui-icon>
                    	</div>
                    	<div class="col-12" *ngIf="openMant">
                    		<div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Packs
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Repostaje
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Pre-ITV
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Pre-ITV + ITV
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Diagnóstico
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Neumáticos
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Puesta a punto
	                        </div>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-11">
                    		<div class="col check_div">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="aparcar" size="m"></tui-checkbox> Aparcamos tu vehículo
	                        </div>
                    	</div>
                    	<div class="col-1">
                    		<tui-icon icon="tuiIconChevronDown" class="ison_fs_16" *ngIf="!openApar" (click)="toggleDiv('apar')"></tui-icon>
                    		<tui-icon icon="tuiIconChevronUp" class="ison_fs_16" *ngIf="openApar" (click)="toggleDiv('apar')"></tui-icon>
                    	</div>
                    	<div class="col-12" *ngIf="openApar">
                    		<div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" size="m"></tui-checkbox> Parking
	                        </div>
	                        <div class="col check_div subcheck">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10"  size="m"></tui-checkbox> Valet
	                        </div>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-12">
                    		<div class="col check_div">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="tramites" size="m"></tui-checkbox> Trámites - Cambio de nombre
	                        </div>
                    	</div>
                    </div>
                    <div class="row dates_list">
                    	<div class="col-12">
                    		<div class="col check_div">
	                            <tui-checkbox class="tui-space_bottom-3 mr_10" formControlName="grua" size="m"></tui-checkbox> Asistencia - Grúa
	                        </div>
                    	</div>
                    </div>
                    <br>
            	</div>
                <br>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 btn_desc but_footer" size="m" appearance="secondary" (click)="closeCanvas()">
            Descartar
        </button>
        <button tuiButton type="button" class="tui-space_right-3 tui-space_bottom-3 but_footer"
            size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" [class.disabled]="!assignamentForm.valid" [attr.disabled]="!assignamentForm.valid ? true : null" *ngIf="!assignamentForm.valid">
                Generar asignación
        </button>
        <button tuiButton type="button" (click)="saveAssignament()" class="tui-space_right-3 tui-space_bottom-3 but_footer"
        size="m" appearance="primary-button" icon="tuiIconPlusCircleLarge" *ngIf="assignamentForm.valid">
            Generar asignación
        </button>
    </div>
</div>
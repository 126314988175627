import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';

import { SentryService } from 'src/app/services/sentry.service';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'cafler';
    @ViewChild('div_dropdowns') div_dropdowns!: ElementRef;

    constructor (
        translate: TranslateService,
        private router: Router,
        private sentry: SentryService,
        private hs:HistoryServicesService,
    ){
        let lang = localStorage.getItem('language');

        if (!lang) {
            lang = "es";
        }

        translate.setDefaultLang(lang);
        translate.use(lang);
    }
    
    shouldShowNavbar(): boolean {
        return this.router.url !== '/login';
    }

    ngOnInit(): void {
        // this.sentry.sendTracing({wasSuccessful:true,orderHash:"123456",status:1});
    }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://wapi-ceroideas-proxy-development-container-gvfdcmhnc2bpcxbq.francecentral-01.azurewebsites.net',
  // url: 'http://localhost:3000',
  // azure: {
  //   clientId: '1649588b-cd93-42f9-bbb4-d8db8ce706d8',
  //   authority: 'https://login.microsoftonline.com/',
  //   redirectUri: 'http://localhost:4200',
  //   // clientSecret: 'TU_CLIENT_SECRET',
  //   tenantId: 'common',
  //   postLogoutRedirectUri: ' http://localhost:4200/login',
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

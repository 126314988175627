import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  Type,
} from '@angular/core';
import { tuiSum } from '@taiga-ui/cdk';
import { TuiHostedDropdownComponent } from '@taiga-ui/core';
import { formatDate } from '@angular/common';
import { ServicesService } from 'src/app/services/service/services.service';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { AddPersonalModalComponent } from '../operational-staff/add-personal-modal/add-personal-modal.component';
import { ModalDuplicateServiceComponent } from 'src/app/shared/modal-duplicate-service/modal-duplicate-service.component';
import { ModalAddClientComponent } from 'src/app/shared/modal-add-client/modal-add-client.component';
import { ModalAddProviderComponent } from 'src/app/shared/modal-add-provider/modal-add-provider.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Client } from 'src/app/models/client/client.model';
import { ClientService } from 'src/app/services/client/client.service';
import { Service } from 'src/app/models/service/service.model';
import { Provider } from 'src/app/models/provider/provider.model';
import { ProviderService } from 'src/app/services/provider/provider.service';
import { Driver } from 'src/app/models/driver/driver.model';
import { DriverService } from 'src/app/services/driver/driver.service';
import { Zone, ZonesResponse } from 'src/app/models/zone/zone.model';
import { TuiDay } from '@taiga-ui/cdk';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { LocalStorageKeys } from 'src/app/enum/local-storage-keys.enum';

declare var Chart: any;
declare var moment: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  readonly value: number[] = [13769, 12367];
  readonly total = tuiSum(...this.value);

  @ViewChild(TuiHostedDropdownComponent) component?: TuiHostedDropdownComponent;
  @ViewChild('chartCanvas', { static: true }) chartCanvas!: ElementRef;

  clientsData: Client[] = [];
  servicesData: Service[] = [];
  providersData: Provider[] = [];
  driversData: Driver[] = [];

  servicesCount: [pendiente: number, activo: number] = [0, 0];
  servicesPercentage: string[] = [];

  clientsCount: [pendiente: number, activo: number] = [0, 0];
  clientsPercentage: string[] = [];

  driversCount: [pendiente: number, activo: number] = [0, 0];
  driversPercentage: string[] = [];

  providersCount: [pendiente: number, activo: number] = [0, 0];
  providersPercentage: string[] = [];

  selectedDate: TuiDay | null = null;
  selectedZone!: string;

  zones: Zone[] = [];

  labelChart: string[] = [];
  valueChart: number[] = [];

  cancelledValueChart: number[] = [];

  filterData: Service[] = [];

  // [ER - 20/03/2025 - Variable not used]
  //date: any = Math.floor(new Date('2024-08-14').getTime() / 1000);

  chart: any;
  // [ER - 20/03/2025 - Variable not used]
  //index = NaN;
  open: boolean = false;
  div_loder: HTMLElement | null = document.getElementById('loader');

  constructor(
    private servicesSvc: ServicesService,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    private renderer: Renderer2,
    private translate: TranslateService,
    private clientService: ClientService,
    private servicesService: ServicesService,
    private providerService: ProviderService,
    private driverService: DriverService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.calculateOperations();
    this.todayData();
  }

  private loadData(): void {
    this.loadZones();
    this.loadClients();
    this.loadServices();
    this.loadProviders();
    this.loadDrivers();
  }

  private calculateOperations(): void {
    this.serviceDataOperation();
    this.clientDataOperation();
    this.driverDataOperation();
    this.providerDataOperation();
  }

  protected onClick(): void {
    this.open = !this.open;
  }

  private loadZones(): void {
    this.servicesSvc.getZones().subscribe({
      next: (zones: ZonesResponse) => {
        this.zones = zones.zones;
      },
      error: (error) => {
        this.zones = [];
        console.log('error', error);
      },
    });
  }

  private loadDrivers(): void {
    this.driverService.getDriverDataHardcoded().subscribe({
      next: (drivers) => {
        this.driversData = drivers;
      },
      error: (error) => {
        this.driversData = [];
        console.error('Error loading drivers: ', error);
      },
    });
  }

  private loadProviders(): void {
    this.providerService.getProvidersHardcoded().subscribe({
      next: (providers) => {
        this.providersData = providers;
      },
      error: (error) => {
        this.providersData = [];
        console.error('Error loading provider: ', error);
      },
    });
  }

  private loadServices(): void {
    this.servicesService.getServicesHardcoded().subscribe({
      next: (services) => {
        this.servicesData = services;
        this.filterData = this.servicesData;
      },
      error: (error) => {
        this.servicesData = [];
        console.error('Error loading services: ', error);
      },
    });
  }

  private loadClients(): void {
    this.clientService.getClientsDataHardcoded().subscribe({
      next: (clients) => {
        this.clientsData = clients;
      },
      error: (error) => {
        this.clientsData = [];
        console.error('Error loading clients: ', error);
      },
    });
  }

  protected onActiveZone(active: boolean): void {
    this.open = active && this.open;
  }

  createChart(): void {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labelChart,
        datasets: [
          {
            label: 'Servicios',
            data: this.valueChart,
            backgroundColor: '#59F89C',
            borderColor: '#05f96b',
            borderWidth: 1,
          },
          {
            label: 'Servicios Cancelados',
            data: this.cancelledValueChart,
            backgroundColor: '#ff2d00',
            borderColor: '#ff2d00',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value: any) {
                return Number(value);
              },
              stepSize: 1,
            },
          },
        },
      },
    });
  }

  serviceDataOperation(): void {
    this.servicesCount = this.calculateStateCounts(this.servicesData);
    this.servicesPercentage = this.calculatePercentage(
      this.servicesCount,
      this.servicesData.length
    );

    // [ER - 20/03/2025 - I let this commented if we need in the future]
    // this.servicesCount = this.servicesData.reduce(
    //   (acc: any, service: any) => {
    //     if (service.status === 'pendiente') {
    //       acc[0]++;
    //     } else if (service.status === 'activo') {
    //       acc[1]++;
    //     }
    //     return acc;
    //   },
    //   [0, 0]
    // );
    // const total = this.servicesData.length;
    // const porcentajePendientes = Math.round(
    //   (this.servicesCount[0] / total) * 100
    // );
    // const porcentajeActivos = Math.round((this.servicesCount[1] / total) * 100);
    // this.servicesPercentage = [
    //   `Pendientes: ${porcentajePendientes}%`,
    //   `Activos: ${porcentajeActivos}%`,
    // ];
    // console.log('hola: ', this.servicesCount);
    //
    // console.log('total:', total);
    // console.log('porcentajePendientes', porcentajePendientes);
    // console.log('porcentajeActivos', porcentajeActivos);
    // console.log('servicesPercentage', this.servicesPercentage);

    // hola:  (2) [18, 15]
    //      home.component.ts:236 total: 34
    //      home.component.ts:237 porcentajePendientes 53
    //      home.component.ts:238 porcentajeActivos 44
    //      home.component.ts:239 servicesPercentage (2) ['Pendientes: 53%', 'Activos: 44%']
  }

  clientDataOperation(): void {
    this.clientsCount = this.calculateStateCounts(this.clientsData);
    this.clientsPercentage = this.calculatePercentage(
      this.clientsCount,
      this.clientsData.length
    );
  }

  driverDataOperation(): void {
    this.driversCount = this.calculateStateCounts(this.driversData);
    this.driversPercentage = this.calculatePercentage(
      this.driversCount,
      this.driversData.length
    );

    // [ER - 20/03/2025 - Commented for if we need in the future]
    //this.driversCount = this.driversData.reduce(
    //  (acc: any, driver: any) => {
    //    if (driver.status === 'pendiente') {
    //      acc[0]++;
    //    } else if (driver.status === 'activo') {
    //      acc[1]++;
    //    }
    //    return acc;
    //  },
    //  [0, 0]
    //);
    //
    //const total = this.driversData.length;
    //const porcentajePendientes = Math.round(
    //  (this.driversCount[0] / total) * 100
    //);
    //const porcentajeActivos = Math.round((this.driversCount[1] / total) * 100);
    //this.driversPercentage = [
    //  `Pendientes: ${porcentajePendientes}%`,
    //  `Activos: ${porcentajeActivos}%`,
    //];
    //
    //console.log('driversPercentage', this.driversPercentage);
    //console.log('total', total);
    //console.log('porcentajePendientes', porcentajePendientes);
    //console.log('porcentajeActivos', porcentajeActivos);
  }

  private calculateStateCounts<
    T extends { status: 'pendiente' | 'activo' | 'finished' }
  >(data: T[]): [number, number] {
    return data.reduce<[number, number]>(
      (acc, item) => {
        if (item.status === 'pendiente') {
          acc[0]++;
        } else if (item.status === 'activo') {
          acc[1]++;
        }
        return acc;
      },
      [0, 0]
    );
  }

  private calculatePercentage(
    count: [number, number],
    total: number
  ): string[] {
    if (total === 0) {
      return ['Pendientes: 0%', 'Activos: 0%'];
    }

    const porcentajePendientes = Math.round((count[0] / total) * 100);
    const porcentajeActivos = Math.round((count[1] / total) * 100);

    return [
      `Pendientes: ${porcentajePendientes}%`,
      `Activos: ${porcentajeActivos}%`,
    ];
  }

  providerDataOperation(): void {
    this.driversCount = this.calculateStateCounts(this.driversData);
    this.driversPercentage = this.calculatePercentage(
      this.driversCount,
      this.driversData.length
    );

    // [ER - 20/03/2025 - Old code, commented for if we need in the future]
    // this.providersCount = this.providersData.reduce(
    //   (acc: any, provider: any) => {
    //     if (provider.status === 'pendiente') {
    //       acc[0]++;
    //     } else if (provider.status === 'activo') {
    //       acc[1]++;
    //     }
    //     return acc;
    //   },
    //   [0, 0]
    // );

    // const total = this.providersData.length;
    // const porcentajePendientes = Math.round(
    //   (this.providersCount[0] / total) * 100
    // );
    // const porcentajeActivos = Math.round(
    //   (this.providersCount[1] / total) * 100
    // );
    // this.providersPercentage = [
    //   `Pendientes: ${porcentajePendientes}%`,
    //   `Activos: ${porcentajeActivos}%`,
    // ];
  }

  // [ER - 20/09/2025 - This method is not used currently ]
  //formatDate2(date: any) {
  //  const day = String(date.getDate()).padStart(2, '0');
  //  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
  //  const year = date.getFullYear();
  //  return `${year}-${month}-${day}`;
  //}

  showLoader(): void {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader(): void {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  async filterDataByDate(): Promise<void> {
    /*   this.filterData = this.servicesData */
    if (this.selectedZone && this.selectedDate) {
      this.showLoader();
      const date = moment.utc(this.selectedDate).unix();
      const formattedDate = moment(this.selectedDate).format('DD/MM/YYYY');
      const result = await this.getServicesDates(formattedDate);
      this.labelChart = result.dates;
      this.valueChart = result.serviceCounts;
      this.cancelledValueChart = result.cancelledServicesCount;
      if (this.chart) {
        this.chart.destroy();
        this.createChart();
      } else {
        this.createChart();
      }
      this.hideLoader();
      setTimeout(() => {
        const closeDiv = document.querySelector('.t-close');
        if (closeDiv) {
          (closeDiv as HTMLElement).click();
        }
      }, 5000);
    }
  }

  clearDate(): void {
    this.selectedDate = null;
    if (this.chart) {
      this.chart.destroy();
      this.todayData();
    } else {
      this.todayData();
    }
  }

  async filterDataBySelect(): Promise<void> {
    if (this.selectedZone && this.selectedDate) {
      this.showLoader();
      const formattedDate: string = moment(this.selectedDate).format(
        'DD/MM/YYYY'
      );
      const result = await this.getServicesDates(formattedDate);
      this.labelChart = result.dates;
      this.valueChart = result.serviceCounts;
      this.cancelledValueChart = result.cancelledServicesCount;
      if (this.chart) {
        this.chart.destroy();
        this.createChart();
      } else {
        this.createChart();
      }
      this.hideLoader();
      setTimeout(() => {
        const closeDiv = document.querySelector('.t-close');
        if (closeDiv) {
          (closeDiv as HTMLElement).click();
        }
      }, 5000);
    }
  }

  getServicesByDateRange(selectedDate: string): {
    dates: string[];
    serviceCounts: number[];
  } {
    const datesArray: string[] = [];
    const serviceCountsArray: number[] = [];
    const daysToConsider: number = 3;
    const selected = new Date(selectedDate.split('/').reverse().join('-'));
    let daysProcessed = 0;
    let offset = -daysToConsider;

    while (daysProcessed < 7) {
      const currentDate = new Date(selected);
      currentDate.setDate(selected.getDate() + offset);
      const dayOfWeek = currentDate.getDay();

      if (dayOfWeek === 6) {
        currentDate.setDate(currentDate.getDate() - 1);
      } else if (dayOfWeek === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const formattedDate = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

      if (!datesArray.includes(formattedDate)) {
        datesArray.push(formattedDate);
        const serviceCount = this.filterData.filter((service: Service) => {
          const serviceDate = service.date;
          return serviceDate === formattedDate;
        }).length;

        serviceCountsArray.push(serviceCount);
        daysProcessed++;
      }
      offset++;
    }

    return { dates: datesArray, serviceCounts: serviceCountsArray };
  }

  async getServicesDates(selectedDate: string) {
    const datesArray: string[] = [];
    const serviceCountsArray: number[] = [];
    const serviceCountsArrayCancelled: number[] = [];
    const daysToConsider = 3;

    const selected = moment.utc(selectedDate, 'DD/MM/YYYY');
    let daysProcessed = 0;
    let offset = -daysToConsider;

    while (daysProcessed < daysToConsider * 2 + 1) {
      const currentDate = moment.utc(selected).add(offset, 'days');
      const dayOfWeek = currentDate.day();

      if (dayOfWeek === 6) {
        currentDate.subtract(1, 'days');
      } else if (dayOfWeek === 0) {
        currentDate.add(1, 'days');
      }

      const formattedDate = currentDate.format('YYYY-MM-DD');

      if (!datesArray.includes(formattedDate)) {
        datesArray.push(formattedDate);
        const date = moment.utc(formattedDate).unix();
        const serviceData = {
          date: { seconds: date },
          zoneId: this.selectedZone,
        };
        const res: any = await firstValueFrom(
          this.servicesSvc.getServices(serviceData)
        );
        let confirmedCount = 0;
        let cancelledCount = 0;
        res.services.forEach((service: any) => {
          if (
            service.orderStatus != 'CANCELLED_BY_CAFLER' &&
            service.orderStatus != 'CANCELLED_BY_CLIENT' &&
            service.orderStatus != 'INITIALIZED'
          ) {
            confirmedCount++;
          } else if (
            service.orderStatus == 'CANCELLED_BY_CAFLER' ||
            service.orderStatus == 'CANCELLED_BY_CLIENT'
          ) {
            cancelledCount++;
          }
        });
        // const serviceCount = res.services ? res.services.length : 0;
        serviceCountsArray.push(confirmedCount);
        serviceCountsArrayCancelled.push(cancelledCount);
        daysProcessed++;
      }

      offset++;
    }
    return {
      dates: datesArray,
      serviceCounts: serviceCountsArray,
      cancelledServicesCount: serviceCountsArrayCancelled,
    };
  }

  todayData(): void {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const result = this.getServicesByDateRange(formattedDate);
    this.labelChart = result.dates;
    this.valueChart = result.serviceCounts;
    this.createChart();
  }

  redirect(route: string): void {
    this.localStorageService.set(LocalStorageKeys.OPENMODAL, '1');
    this.router.navigate([`${route}`]);
  }

  private openModal(component: Type<any>): void {
    this.offcanvasService.open(component, {
      backdrop: 'static',
      position: 'end',
    }).componentInstance.type_info = 'ad';
  }

  openModalAddOperator(): void {
    const offcanvasRef = this.offcanvasService.open(AddPersonalModalComponent, {
      backdrop: 'static',
      // scroll: true,
      position: 'end',
    });
    offcanvasRef.componentInstance.name = 'World';
  }

  openModalAddService(): void {
    this.openModal(ModalDuplicateServiceComponent);
    // [ER - 20/03/2025] - This is old code, commented for if we need in a future
    // const offcanvasRef = this.offcanvasService.open(
    //   ModalDuplicateServiceComponent,
    //   {
    //     backdrop: 'static',
    //     // scroll: true,
    //     position: 'end',
    //   }
    // );
    // offcanvasRef.componentInstance.type_info = 'ad';
  }

  openModalAddClient(): void {
    this.openModal(ModalAddClientComponent);
    // [ER - 20/03/2025] - This is old code, commented for if we need in a future
    // const offcanvasRef = this.offcanvasService.open(ModalAddClientComponent, {
    //   backdrop: 'static',
    //   // scroll: true,
    //   position: 'end',
    // });
    // offcanvasRef.componentInstance.type_info = 'ad';
  }

  openModalAddProvider(): void {
    // [ER - 20/03/2025] - This is old code, commented for if we need in a future
    this.openModal(ModalAddProviderComponent);
    // const offcanvasRef = this.offcanvasService.open(ModalAddProviderComponent, {
    //   backdrop: 'static',
    //   // scroll: true,
    //   position: 'end',
    // });
    // offcanvasRef.componentInstance.type_info = 'ad';
  }
}

<!-- Modal -->
<div id="myModal" class="modal-content">
  <div class="modal-header-custom d-flex justify-content-between">
    <div>
      <div class="service-data">
        <span class="service-name"></span>
        <span class="service-name">
          Editar {{ subproductData.productKey }}
        </span>

        <div class="display-contents">
          <span class="service-hash"
            >Modifica el precio cliente y la disponibilidad para contratación
          </span>
        </div>
      </div>
    </div>
    <div></div>
    <button
      type="button"
      class="btn-close"
      (click)="bsModalRef?.hide()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body-custom mt-4">
    <form [formGroup]="subproductForm" (ngSubmit)="onSubmitUpdateSubproduct()">
      <div class="client-data align-items-center mb-1">
        <div class="d-flex gap-2">
          <tui-toggle formControlName="available" size="l"></tui-toggle>
          <label class="service-hash">Disponible en contratación</label>
        </div>

        <div class="mt-4">
          <div class="d-flex gap-1">
            <p class="font-weight-bolder">Precio movilidad:</p>
            <span
              >{{
                this.formatPrice(
                  this.subproductData?.clientMobilityPrice.units,
                  this.subproductData?.clientMobilityPrice.nanos
                )
              }}€ ( {{ subproductData.plan }} )</span
            >
          </div>
          <p>
            <tui-input formControlName="clientMobilityTotal">
              Precio movilidad
              <input inputmode="numeric" tuiTextfield />
            </tui-input>
          </p>
          <span class="service-discount mt-1">
            {{ this.subproductData?.mobilityDiscount.units }}% de descuento
          </span>
        </div>
        <div class="mt-4">
          <div class="d-flex gap-1">
            <p class="font-weight-bolder">Precio proveedor:</p>
            <span
              >{{
                this.formatPrice(
                  this.subproductData?.clientProviderPrice.units,
                  this.subproductData?.clientProviderPrice.nanos
                )
              }}€ ( {{ subproductData.plan }} )</span
            >
          </div>
          <p>
            <tui-input formControlName="clientProviderTotal">
              Precio proveedor
              <input inputmode="numeric" tuiTextfield />
            </tui-input>
          </p>
          <span class="service-discount mt-1">
            {{ this.subproductData?.providerDiscount.units }}% de descuento
          </span>
        </div>
      </div>

      <div class="modal-footer-custom">
        <button
          (click)="bsModalRef?.hide()"
          type="button"
          class="cancel-button-menu mt-3"
        >
          Salir sin guardar
        </button>
        <button
          (click)="bsModalRef?.hide()"
          type="submit"
          class="export-button-menu mt-3"
        >
          Guardar
        </button>
      </div>
    </form>
  </div>
</div>

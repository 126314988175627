import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { TuiAlertService } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class HistoryServicesService {
  historyDataParams: any = {};
  pendingDataParams: any = {};
  url = environment.url;
  datos: any;
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private http: HttpClient
  ) {}

  private channels: { [key: string]: Subject<any> } = {};
  publish(topic: string, data: any = null): void {
    const subject = this.channels[topic];
    if (!subject) {
      // Or you can create a new subject for future subscribers
      return;
    }

    subject.next(data);
  }

  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }

    return this.channels[topic].subscribe(observer);
  }

  getZones() {
    return this.http.get(`${this.url}/zones`);
  }

  getToppings(data: any) {
    return this.http.get(
      `${this.url}/services/toppings?orderHash=${data.orderHash}`,
      {}
    );
  }

  getProducts(data: any) {
    // if(data.zoneId){
    //     return this.http.get(`${this.url}/products?zoneId=`+data.zoneId);
    // }else{
    //     return this.http.get(`${this.url}/products?zoneId=`+data);
    // }

    return this.http.post(`${this.url}/products`, data);
  }

  getProviders(data: any) {
    if (data.zoneId) {
      return this.http.get(
        `${this.url}/products/providers?zoneId=` + data.zoneId
      );
    } else {
      return this.http.get(`${this.url}/products/providers?zoneId=` + data);
    }
  }

  getServiceDetail(hash: any) {
    return this.http.get(`${this.url}/services/details?orderHash=` + hash);
  }

  getServices(data: any) {
    return this.http.post(`${this.url}/services`, data);
  }

  getClients() {
    return this.http.post(`${this.url}/clients`, {});
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.value;
  }

  editContactData(data: any) {
    return this.http.post(`${this.url}/services/update-contact`, data);
  }

  cancelService(data: any) {
    return this.http.post(`${this.url}/services/cancel`, data);
  }

  addService(data: any) {
    return this.http.post(`${this.url}/services/add`, data);
  }

  addInternalComments(data: any) {
    return this.http.post(`${this.url}/services/add-internal-comment`, data);
  }

  editPrice(data: any) {
    return this.http.post(`${this.url}/services/update-price`, data);
  }

  updateService(data: any) {
    return this.http.post(`${this.url}/services/update`, data);
  }

  updateServiceAssignedHour(data: any) {
    return this.http.post(
      `${this.url}/services/update-assigned-start-time`,
      data
    );
  }

  changeStatusService(data: any) {
    return this.http.post(`${this.url}/services/update-status`, data);
  }

  addPrice(data: any) {
    return this.http.post(`${this.url}/services/add-overdraft`, data);
  }

  addTopping(data: any) {
    return this.http.post(`${this.url}/services/add-topping`, data);
  }

  addClient(data: any) {
    return this.http.post(`${this.url}/clients/add`, data);
  }

  deleteTopping(data: any) {
    return this.http.post(`${this.url}/services/remove-topping`, data);
  }

  addProviderToProductInService(data: any) {
    return this.http.post(`${this.url}/services/assing-provider`, data);
  }

  private contentSource = new BehaviorSubject<string>('');
  currentContent = this.contentSource.asObservable();

  changeContent(content: string) {
    this.contentSource.next(content);
  }

  public traducciones: { [key: string]: string } = {
    'Basic Replacement Vehicle': 'Vehículo de sustitución',
    'Cafler Complete Atv Wash': 'Lavado Completo Cafler',
    'Cafler Complete Car Wash': 'Lavado Completo Cafler',
    'Cafler Complete Light Van Wash': 'Lavado Completo Cafler',
    'Cafler Complete Minivan Wash': 'Lavado Completo Cafler',
    'Cafler Complete Van Wash': 'Lavado Completo Cafler',
    'Cafler Exterior Atv Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Car Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Light Van Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Minivan Wash': 'Lavado Exterior Cafler',
    'Cafler Exterior Van Wash': 'Lavado Exterior Cafler',
    'Cafler Fresh ATV Topping': 'Cafler Fresh',
    'Cafler Fresh Car Topping': 'Cafler Fresh',
    'Cafler Fresh Lightvan Topping': 'Cafler Fresh',
    'Cafler Fresh Minivan Topping': 'Cafler Fresh',
    'Cafler Fresh Truck Topping': 'Cafler Fresh',
    'Cafler Fresh Van Topping': 'Cafler Fresh',
    'Cafler Interior Atv Wash': 'Lavado interior Cafler',
    'Cafler Interior Car Wash': 'Lavado interior Cafler',
    'Cafler Interior Light Van Wash': 'Lavado interior Cafler',
    'Cafler Interior Minivan Wash': 'Lavado interior Cafler',
    'Cafler Interior Van Wash': 'Lavado interior Cafler',
    'Cafler Ready': 'Puesta a punto',
    'Car Basic Maintenance': 'Mantenimiento básico Coche',
    'Car Complete Maintenance': 'Mantenimiento completo Coche',
    'Car Medium Maintenance': 'Mantenimiento medio Coche',
    'Car MOT': 'ITV Coche',
    'Checklist topping': 'Checklist',
    'Complete atv wash': 'Lavado completo Proveedor',
    'Complete car wash': 'Lavado completo Proveedor',
    'Complete light van wash': 'Lavado completo Proveedor',
    'Complete minivan wash': 'Lavado completo Proveedor',
    'Complete van wash': 'Lavado completo Proveedor',
    'Diesel Car MOT': 'ITV Coche Diesel',
    'Diesel Van MOT': 'ITV Furgoneta Diesel',
    'Electric Car MOT': 'ITV Coche Electrico',
    'Electric Motorbike +125 MOT': 'ITV Moto Eléctrica +125cc',
    'Electric Motorbike -125 MOT': 'ITV Moto Eléctrica -125cc',
    'Electric Van MOT': 'ITV Furgoneta Eléctrica',
    'Express Complete Atv Wash': 'Lavado a mano Express completo',
    'Express Complete Car Wash': 'Lavado a mano Express completo',
    'Express Complete Light Van Wash': 'Lavado a mano Express completo',
    'Express Complete Minivan Wash': 'Lavado a mano Express completo',
    'Express Complete Van Wash': 'Lavado a mano Express completo',
    'Express Exterior Atv Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Car Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Light Van Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Minivan Wash': 'Lavado a mano Express Exterior',
    'Express Exterior Van Wash': 'Lavado a mano Express Exterior',
    'Express Interior Atv Wash': 'Lavado a mano Express Interior',
    'Express Interior Car Wash': 'Lavado a mano Express Interior',
    'Express Interior Light Van Wash': 'Lavado a mano Express Interior',
    'Express Interior Minivan Wash': 'Lavado a mano Express Interior',
    'Express Interior Van Wash': 'Lavado a mano Express Interior',
    'Exterior Atv Wash': 'Lavado exterior',
    'Exterior Car Wash': 'Lavado exterior',
    'Exterior Light Van Wash': 'Lavado exterior',
    'Exterior Minivan Wash': 'Lavado exterior',
    'Exterior Van Wash': 'Lavado exterior',
    'Gas Car MOT': 'ITV Coche Gas',
    'Gas Van MOT': 'ITV Furgoneta Gas',
    'Headlight Polishing': 'Pulido de faros',
    'Hybrid Car MOT': 'ITV',
    'Hybrid Van MOT': 'ITV',
    'Insurance Topping': 'Seguro por días',
    'Integral Washing': 'Lavado integral',
    'Interior atv wash': 'Lavado integral',
    'Interior car wash': 'Lavado integral',
    'Interior light van wash': 'Lavado integral',
    'Interior minivan wash': 'Lavado integral',
    'Interior van wash': 'Lavado integral',
    'Medium Long Distance': 'Media-Larga distancia',
    'MOT Homologation': 'ITV Homologación',
    'Motorbike +125 MOT': 'ITV Moto +125cc',
    'Motorbike -125 MOT': 'ITV Moto -125cc',
    'Motorbike Basic Maintenance': 'Mantenimiento básico',
    'Motorbike Complete Maintenance': 'Mantenimiento completo',
    'Motorbike MOT': 'ITV',
    'Motorbike wash': 'Lavado',
    'Ownership change': 'Cambio de nombre',
    Parking: 'Parking',
    'Petrol Car MOT': 'ITV',
    'Pre-MOT': 'Pre-ITV',
    'Pre-MOT + Diesel Car MOT': 'Pre-ITV + ITV Coche Diesel',
    'Pre-Mot + Diesel Van MOT': 'Pre-ITV + ITV Furgoneta Diesel',
    'Pre-MOT + Electric Car MOT': 'Pre-ITV + ITV Coche Electrico',
    'Pre-MOT + Electric Motorbike +125 MOT':
      'Pre-ITV + ITV Moto Eléctrica +125cc',
    'Pre-MOT + Electric Motorbike -125 MOT':
      'Pre-ITV + ITV Moto Eléctrica -125cc',
    'Pre-Mot + Electric Van MOT': 'Pre-ITV + ITV Furgoneta Eléctrica',
    'Pre-Mot + Gas Van MOT': 'Pre-ITV + ITV Furgoneta Gas',
    'Pre-MOT + Hybrid Car MOT': 'Pre-ITV + ITV Coche Híbrido',
    'Pre-Mot + Hybrid Van MOT': 'Pre-ITV + ITV Furgoneta Híbrida',
    'Pre-MOT + Motorbike +125 MOT': 'Pre-ITV + ITV Moto +125cc',
    'Pre-MOT + Motorbike -125 MOT': 'Pre-ITV + ITV Moto -125cc',
    'Pre-MOT + Petrol Car MOT': 'Pre-ITV + ITV Coche Gasolina',
    Refueling: 'Repostaje',
    'Refueling Atv': 'Repostaje',
    'Refueling Light Van': 'Repostaje',
    'Refueling Minivan': 'Repostaje',
    'Refueling motorbike': 'Repostaje',
    'Refueling scooter': 'Repostaje',
    'Refueling Van': 'Repostaje',
    'Tire Check': 'Comprobación de neumáticos',
    'Tow truck': 'Grúa',
    Transfer: 'Transfer',
    'Transfer and Replacement Vehicle': 'Transfer y vehículo de sustitución',
    'Tunnel Complete Car Wash': 'Lavado Proveedor Completo',
    'Tunnel Exterior Car Wash': 'Lavado Proveedor Exterior',
    'Tunnel Interior Car Wash': 'Lavado Proveedor Interior',
    'Tunnel Motorbike Wash': 'Lavado Proveedor Moto',
    'Tunnel wash': 'Lavado de Túnel',
    Tyres: 'Neumáticos',
    'Upholstery 5 Seats': 'Tapicería',
    'Valet Train Station': 'Valet',
    'Workshop at home': 'Diagnóstico',
  };

  public errors: any = [
    {
      show: "Generic error code. It also marks that something internal failed, and we don't wanna expose internal code.",
      code: 'LMP000000',
      error: 'UnknownError',
    },
    {
      show: 'The requested zone is invalid.',
      code: 'LMP000001',
      error: 'InvalidZone',
    },
    {
      show: "The requested zone doesn't have any products.",
      code: 'LMP000002',
      error: 'NoProductsInZone',
    },
    {
      show: 'The requested product is invalid.',
      code: 'LMP000003',
      error: 'InvalidProduct',
    },
    {
      show: 'The requested product in the specified zone context is invalid.',
      code: 'LMP000004',
      error: 'InvalidProductInZone',
    },
    {
      show: 'There were no products in the product creation attempt.',
      code: 'LMP000005',
      error: 'NoProductsInProductCreationAttempt',
    },
    {
      show: 'A specified product configuration is invalid.',
      code: 'LMP000006',
      error: 'InvalidProductConfiguration',
    },
    {
      show: 'The requested valet station does not exists.',
      code: 'LMP000007',
      error: 'ValetStationDoesNotExist',
    },
    {
      show: 'The origin address point is invalid.',
      code: 'LMP000008',
      error: 'InvalidOriginAddressPoint',
    },
    {
      show: 'The destination address point is invalid.',
      code: 'LMP000009',
      error: 'InvalidDestinationAddressPoint',
    },
    {
      show: 'The vehicle availability requested for a replacement vehicle is invalid.',
      code: 'LMP0000010',
      error: 'InvalidVehicleAvailabilityForReplacementVehicle',
    },
    {
      show: 'Depending on the context of the request, the endpoint used is not the correct one.',
      code: 'LMP000011',
      error: 'InvalidEndpointForRequestContext',
    },
    {
      show: 'Some products in the order are missing a provider.',
      code: 'LMP0000012',
      error: 'ProvidersNotAssigned',
    },
    {
      show: 'The requested order does not exist.',
      code: 'LMP0000013',
      error: 'OrderDoesNotExists',
    },
    {
      show: 'The requested provider does not exist.',
      code: 'LMP0000014',
      error: 'ProviderDoesNotExist',
    },
    {
      show: 'The order hash provider is non-compliant with known formats.',
      code: 'LMP0000015',
      error: 'InvalidOrderHash',
    },
    {
      show: 'The specified driver id is invalid or malformed.',
      code: 'LMP0000016',
      error: 'InvalidDriverId',
    },
    {
      show: 'The requested driver does not exist.',
      code: 'LMP000017',
      error: 'DriverDoesNotExists',
    },
    {
      show: 'A cancellation of a cancelled order cannot be performed.',
      code: 'LMP000018',
      error: 'CannotCancelACancelledOrder',
    },
    {
      show: 'A cancellation of an invalid order cannot be performed.',
      code: 'LMP000019',
      error: 'CannotCancelAnInvalidOrder',
    },
    {
      show: 'Assigning drivers to a service without nodes is not allowed.',
      code: 'LMP0000021',
      error: 'CannotAssignDriverToServiceWithoutNodes',
    },
    {
      show: "Due to the status of the order, we don't allow the requested operation.",
      code: 'LMP0000022',
      error: 'OrderStatusDoesNotAllowsOperation',
    },
  ];

  showErrorCode(code: any) {
    let error = this.errors.find((x: any) => x.code == code);
    let show = 'Unknown Error';
    if (error) {
      show = error.show;
    }
    this.alerts
      .open(show, {
        label: 'Ha ocurrido un error',
        status: 'error',
        autoClose: true,
      })
      .subscribe();
  }

  translate(term: string): string {
    return this.traducciones[term] || term;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import {
  TuiButtonModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeComponent } from './home.component';
import { TuiRingChartModule } from '@taiga-ui/addon-charts';
import { TuiBadgeModule, TuiInputDateModule } from '@taiga-ui/kit';
import { TuiGroupModule } from '@taiga-ui/core';
import { TuiActiveZoneModule, TuiObscuredModule } from '@taiga-ui/cdk';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiRingChartModule,
    TuiBadgeModule,
    TuiGroupModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiInputDateModule,
    TuiTextfieldControllerModule,
    TuiActiveZoneModule,
    TuiObscuredModule,
    SharedModule,
    NgSelectModule,
  ],
})
export class HomeModule {}

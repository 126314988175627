import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import {TuiCountryIsoCode} from '@taiga-ui/i18n';


@Component({
  selector: 'app-platform-blocker-table',
  templateUrl: './platform-blocker-table.component.html',
  styleUrls: ['./platform-blocker-table.component.css']
})
export class PlatformBlockerTableComponent {



      //modal reference cesar-gutierrez
      bsModalRef?: BsModalRef;
      countries = [
      'España',
      'UK',
      'Francia',
  ];

  operativesZones = [
      'Madrid',
      'Barcelona',
      'Valencia'
  ];

  operatorsSelect = [
      'Interno',
      'Externo',
  ]

  rolesSelect = [
      'Gerente',
      'Supervisor de Mantenimiento',
      'Coordinador',
  ]

  services = [
      'Servicio 1',
      'Servicio 2',
      'Servicio 3',
  ]

  hours = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);
  hours2 = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);

  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
      TuiCountryIsoCode.ES,
      TuiCountryIsoCode.GB,
      TuiCountryIsoCode.FR,
  ];

  readonly countriesPhoneExtra: ReadonlyArray<TuiCountryIsoCode> = [
      TuiCountryIsoCode.ES,
      TuiCountryIsoCode.GB,
      TuiCountryIsoCode.FR,
  ];

  countryIsoCode = TuiCountryIsoCode.ES;
  countryIsoCodeExtra = TuiCountryIsoCode.ES;



searchTerm: string = '';




   incidentServices:any = [
    {
      blockId: 'BLK001',
      date: '20/08/2024',
      time: '09:00-10:00',
      zone: 'Madrid',
      product: 'IVT',
      comment: 'Block due to maintenance.',
      status: 'active'
    },
    {
      blockId: 'BLK002',
      date: '20/08/2024',
      time: '11:00-12:00',
      zone: 'Barcelona',
      product: 'Mantenimiento',
      comment: 'Temporary block for repairs.',
      status: 'inactive'
    },
    {
      blockId: 'BLK003',
      date: '20/08/2024',
      time: '14:00-15:00',
      zone: 'Valencia',
      product: 'Mantenimiento',
      comment: 'Routine check.',
      status: 'active'
    },
    {
      blockId: 'BLK004',
      date: '20/08/2024',
      time: '16:00-17:00',
      zone: 'Sevilla',
      product: 'Lavado',
      comment: 'Block for cleaning service.',
      status: 'inactive'
    },
    {
      blockId: 'BLK005',
      date: '20/08/2024',
      time: '18:00-19:00',
      zone: 'Bilbao',
      product: 'Grua',
      comment: 'Emergency block.',
      status: 'active'
    },
    {
      blockId: 'BLK006',
      date: '20/08/2024',
      time: '10:00-11:00',
      zone: 'Zaragoza',
      product: 'IVT',
      comment: 'Block for inspection.',
      status: 'inactive'
    },
    {
      blockId: 'BLK007',
      date: '20/08/2024',
      time: '12:00-13:00',
      zone: 'Málaga',
      product: 'Mantenimiento',
      comment: 'Scheduled block for maintenance.',
      status: 'active'
    },
    {
      blockId: 'BLK008',
      date: '20/08/2024',
      time: '14:00-15:00',
      zone: 'Murcia',
      product: 'Lavado',
      comment: 'Block for washing service.',
      status: 'inactive'
    },
    {
      blockId: 'BLK009',
      date: '20/08/2024',
      time: '16:00-17:00',
      zone: 'Alicante',
      product: 'Grua',
      comment: 'Urgent block.',
      status: 'active'
    },
    {
      blockId: 'BLK010',
      date: '19/08/2024',
      time: '18:00-19:00',
      zone: 'Granada',
      product: 'IVT',
      comment: 'Routine inspection block.',
      status: 'inactive'
    },
    {
      blockId: 'BLK011',
      date: '20/08/2024',
      time: '09:00-10:00',
      zone: 'Córdoba',
      product: 'Mantenimiento',
      comment: 'Scheduled maintenance block.',
      status: 'active'
    },
    {
      blockId: 'BLK012',
      date: '21/08/2024',
      time: '11:00-12:00',
      zone: 'Valladolid',
      product: 'Lavado',
      comment: 'Block for vehicle cleaning.',
      status: 'inactive'
    },
    {
      blockId: 'BLK013',
      date: '22/08/2024',
      time: '13:00-14:00',
      zone: 'Oviedo',
      product: 'Grua',
      comment: 'Block due to emergency.',
      status: 'active'
    },
    {
      blockId: 'BLK014',
      date: '23/08/2024',
      time: '15:00-16:00',
      zone: 'Santander',
      product: 'Mantenimiento',
      comment: 'Block for workshop visit.',
      status: 'inactive'
    },
    {
      blockId: 'BLK015',
      date: '24/08/2024',
      time: '17:00-18:00',
      zone: 'Salamanca',
      product: 'IVT',
      comment: 'Inspection block.',
      status: 'active'
    },
    {
      blockId: 'BLK016',
      date: '25/08/2024',
      time: '19:00-20:00',
      zone: 'Pamplona',
      product: 'Mantenimiento',
      comment: 'Scheduled block.',
      status: 'inactive'
    },
    {
      blockId: 'BLK017',
      date: '26/08/2024',
      time: '09:00-10:00',
      zone: 'Logroño',
      product: 'Lavado',
      comment: 'Block for cleaning.',
      status: 'active'
    },
    {
      blockId: 'BLK018',
      date: '27/08/2024',
      time: '11:00-12:00',
      zone: 'Burgos',
      product: 'Grua',
      comment: 'Emergency block.',
      status: 'inactive'
    },
    {
      blockId: 'BLK019',
      date: '28/08/2024',
      time: '13:00-14:00',
      zone: 'Toledo',
      product: 'Mantenimiento',
      comment: 'Block for workshop.',
      status: 'active'
    },
    {
      blockId: 'BLK020',
      date: '29/08/2024',
      time: '15:00-16:00',
      zone: 'Almería',
      product: 'IVT',
      comment: 'Inspection block.',
      status: 'inactive'
    }
  ];
  selectedDate: any = null;
paginatedData: any[] = []
readonly sizes = ['l', 'm', 's'] as const;
size = this.sizes[0];
zones!: any[];
clients!: any[];
products!: any[];
pendingCount!:number;
resolvedCount!:number;
countrys!: any[];
selectedZone: string = '#';
selectedClient: string = '#';
selectedProduct: string = '#';
selectedStatus: string = "pending"

index: number = 0;
length!: number;
pageSize: number = 6;
selectedCountry:any = '#'


readonly columns = ['block_id','date','time', 'zone', 'product', 'comment',  'status' , 'actions'];






@ViewChild(TuiHostedDropdownComponent)
component?: TuiHostedDropdownComponent;

readonly items = ['Edit', 'Download', 'Rename', 'Delete'];

readonly selectItems = ['Item 1', 'Item 2'];

open = false;

selected = null;

onClick(): void {
  this.open = false;
  this.component?.nativeFocusableElement?.focus();
}


constructor(
@Inject(TuiAlertService) private readonly alerts: TuiAlertService,

) {}



ngOnInit(): void {
  this.length = Math.ceil(this.incidentServices.length / this.pageSize);
  this.paginatedDataOperation()
   this.zones = [...new Set(this.incidentServices.map((item:any) => item.zone))];
   this.products = [...new Set(this.incidentServices.map((item:any) => item.product))];

/*   this.updateCounts()
  this.applyFilter(this.selectedStatus)
  */
 
}



showDepositAlert(): void {
this.alerts
  .open('El usuario se ha creado correctamente', {
      label: 'Usuario creado',
      status: 'success',
      autoClose: true,
  })
  .subscribe();
}

paginatedDataOperation() {
  const start = this.index * this.pageSize;
  this.paginatedData = this.incidentServices.slice(start, start + this.pageSize);
  
}

goToPage(pageIndex: number) {
  this.index = pageIndex;
  /*  this.paginatedDataOperation()  */
   this.paginatedData = this.incidentServices;

   if(this.selectedDate){
    const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
    const year = String(this.selectedDate.getFullYear()); 

    const formattedDate = `${day}/${month}/${year}`; 
    this.paginatedData = this.paginatedData.filter((item:any) => item.date === formattedDate);
  }
  

 

  if (this.selectedZone != '#') {
     this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
  }

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }
  

  if (!this.searchTerm) {
    this.searchTerm = ''


  } else {
    this.searchTerm = this.searchTerm
    this.paginatedData = this.paginatedData.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const client = item.client.toLowerCase();
      const search = this.searchTerm.toLowerCase();
    
      return license.includes(search) || orderHash.includes(search) || client.includes(search);
    });

  }

  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

     
}

filterData(searchText: string) {
  this.index = 0
  if (!searchText) {
    this.searchTerm = ''
      this.length = Math.ceil(this.incidentServices.length / this.pageSize);
     /* this.paginatedDataOperation() */
     this.paginatedData = this.incidentServices;
     if(this.selectedDate){
      const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
      const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
      const year = String(this.selectedDate.getFullYear()); 
  
      const formattedDate = `${day}/${month}/${year}`; 
      
      this.paginatedData = this.paginatedData.filter((item:any) => item.date === formattedDate); 
    }

 
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }
 
  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

  } else {
    this.searchTerm = searchText
   this.paginatedData = this.incidentServices;

  
  
 
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }

 this.paginatedData = this.paginatedData.filter((item: any) => {
  const license = item.licensePlate.toLowerCase();
  const orderHash = item.orderHash.toLowerCase();
  const client = item.client.toLowerCase();
  const search = searchText.toLowerCase();

  return license.includes(search) || orderHash.includes(search) || client.includes(search);
});

       

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }
  
}

filterDataBySelect() {
  this.paginatedData = this.incidentServices
  if(this.selectedDate){

    this.selectedDate = new Date(this.selectedDate)
   
    const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
    const year = String(this.selectedDate.getFullYear()); 

    const formattedDate = `${day}/${month}/${year}`;   
     
    
    this.paginatedData = this.paginatedData.filter((item:any) => item.date === formattedDate); 
  }

  if (this.searchTerm) {
    
    
    this.paginatedData = this.paginatedData.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const client = item.client.toLowerCase();
      const search = this.searchTerm.toLowerCase();
    
      return license.includes(search) || orderHash.includes(search) || client.includes(search);
    });


  } 


  if (this.selectedZone != '#') {
     this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
  }


if (this.selectedProduct != '#') {
  this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
}
  


  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  if(this.length == 1){
      this.index = 0
  }
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

  
 
}





updateCounts() {
  this.pendingCount = this.incidentServices.filter((item:any) => item.status === 'pending').length;
  this.resolvedCount = this.incidentServices.filter((item:any) => item.status === 'resolved').length;
}

applyFilter(status: string) {
  this.selectedStatus = status;
  this.paginatedData = this.incidentServices;
  this.index = 0
  if (!this.searchTerm) {
    this.searchTerm = ''
      this.length = Math.ceil(this.incidentServices.length / this.pageSize);
     /* this.paginatedDataOperation() */
   

  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }
 
  this.length = Math.ceil(this.paginatedData.length / this.pageSize);
  const start = this.index * this.pageSize;
  this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

  } else {
    this.searchTerm = this.searchTerm
    

  
 
  
    if (this.selectedZone != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
    }
  

  if (this.selectedProduct != '#') {
    this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
 }

 this.paginatedData = this.paginatedData.filter((item: any) => {
  const license = item.licensePlate.toLowerCase();
  const orderHash = item.orderHash.toLowerCase();
  const client = item.client.toLowerCase();
  const search = this.searchTerm.toLowerCase();

  return license.includes(search) || orderHash.includes(search) || client.includes(search);
});

       

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }
}

filterDataByDate() {
  if (this.selectedDate) {
    this.selectedDate = new Date(this.selectedDate)
   
    const day = String(this.selectedDate.getDate()).padStart(2, '0'); 
    const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0'); 
    const year = String(this.selectedDate.getFullYear()); 

    const formattedDate = `${day}/${month}/${year}`; 
    
    
     this.paginatedData = this.incidentServices.filter((item:any) => item.date === formattedDate); 
     this.paginatedData = this.paginatedData;
     
     this.index = 0
     if (!this.searchTerm) {
       this.searchTerm = ''
        
      
      
   
  
       if (this.selectedZone != '#') {
          this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
       }
     
   
     if (this.selectedProduct != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
    }
    
     this.length = Math.ceil(this.paginatedData.length / this.pageSize);
     const start = this.index * this.pageSize;
     this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
   
     } else {
       this.searchTerm = this.searchTerm
         

     
       if (this.selectedZone != '#') {
          this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
       }
     
   
     if (this.selectedProduct != '#') {
       this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
    }
   
    this.paginatedData = this.paginatedData.filter((item: any) => {
     const license = item.licensePlate.toLowerCase();
     const orderHash = item.orderHash.toLowerCase();
     const client = item.client.toLowerCase();
     const search = this.searchTerm.toLowerCase();
   
     return license.includes(search) || orderHash.includes(search) || client.includes(search);
   });
   
          
   
           this.length = Math.ceil(this.paginatedData.length / this.pageSize);
           const start = this.index * this.pageSize;
           this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
     }
  } else {
    this.paginatedData = this.incidentServices;
    
    this.index = 0
    if (!this.searchTerm) {
      this.searchTerm = ''
       
     
     
  
 
    
      if (this.selectedZone != '#') {
         this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
      }
    
  
    if (this.selectedProduct != '#') {
      this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
   }
   
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  
    } else {
      this.searchTerm = this.searchTerm
        
    
  
    
      if (this.selectedZone != '#') {
         this.paginatedData =  this.paginatedData.filter((item:any) => item.zone === this.selectedZone);
      }
    
  
    if (this.selectedProduct != '#') {
      this.paginatedData =  this.paginatedData.filter((item:any) => item.product === this.selectedProduct);
   }
  
   this.paginatedData = this.paginatedData.filter((item: any) => {
    const license = item.licensePlate.toLowerCase();
    const orderHash = item.orderHash.toLowerCase();
    const client = item.client.toLowerCase();
    const search = this.searchTerm.toLowerCase();
  
    return license.includes(search) || orderHash.includes(search) || client.includes(search);
  });
  
         
  
          this.length = Math.ceil(this.paginatedData.length / this.pageSize);
          const start = this.index * this.pageSize;
          this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
    }
  }
}

clearDate() {
  this.selectedDate = null;
  this.filterDataByDate();
}



}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { Service, Verticals } from 'src/app/models/service/service.model';
import { SERVICES_TYPES } from 'src/app/constants/verticals';
import { AccountInfo } from '@azure/msal-browser';
import { ZonesResponse } from 'src/app/models/zone/zone.model';
import { GanttRequest, GetDriversGantResponse, GetGanttResponse } from 'src/app/shared/gantt/interfaces/Gant.interface';

const fetchUser = async (accessToken: string, userId: string) => {
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/users/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Error al obtener el usuario');
  }

  const user = await response.json();
  return user;
};

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  url = environment.url;
  // [ER - 20/03/2025 - Is not used]
  ganttDataParams: any = {};
  public clients: any = [];

  serviceData: Service[] = [
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'activo',
      date: '2024-07-22',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'pendiente',
      date: '2024-07-22',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },

    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-23',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1123-JKL',
      orderHash: 'hash1123',
      status: 'activo',
      date: '2024-07-23',
      time: '12:00-12:30',
      country: 'Italy',
      zone: 'Rome',
      vertical: 'Mantenimiento',
      product: 'Revisión general',
      client: 'Carlos Sanchez',
      issue: 'None',
      pickupAddress: 'Via del Corso, Rome',
      deliveryAddress: 'Piazza Navona, Rome',
      provider: 'Provider D',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1415-MNO',
      orderHash: 'hash1415',
      status: 'activo',
      date: '2024-07-23',
      time: '13:00-13:30',
      country: 'United Kingdom',
      zone: 'London',
      vertical: 'ITV',
      product: 'ITV coche de diésel',
      client: 'Ana Perez',
      issue: 'Provider issue',
      pickupAddress: 'Oxford Street, London',
      deliveryAddress: 'Piccadilly Circus, London',
      provider: 'Provider E',
      comment: 'Cancelled due to provider issue',
    },

    {
      licensePlate: '1617-PQR',
      orderHash: 'hash1617',
      status: 'activo',
      date: '2024-07-24',
      time: '14:00-14:30',
      country: 'USA',
      zone: 'New York',
      vertical: 'Lavado',
      product: 'Lavado y encerado',
      client: 'Luis Ramirez',
      issue: 'None',
      pickupAddress: 'Times Square, New York',
      deliveryAddress: 'Central Park, New York',
      provider: 'Provider F',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1819-STU',
      orderHash: 'hash1819',
      status: 'pendiente',
      date: '2024-07-24',
      time: '15:00-15:30',
      country: 'Canada',
      zone: 'Toronto',
      vertical: 'Mantenimiento',
      product: 'Cambio de aceite',
      client: 'Elena Torres',
      issue: 'Client rescheduled',
      pickupAddress: 'Queen Street, Toronto',
      deliveryAddress: 'Bay Street, Toronto',
      provider: 'Provider G',
      comment: 'Client requested to reschedule',
    },
    {
      licensePlate: '2021-VWX',
      orderHash: 'hash2021',
      status: 'pendiente',
      date: '2024-07-24',
      time: '16:00-16:30',
      country: 'Australia',
      zone: 'Sydney',
      vertical: 'Taller',
      product: 'Reparación de frenos',
      client: 'Jorge Fernandez',
      issue: 'Part not available',
      pickupAddress: 'George Street, Sydney',
      deliveryAddress: 'Pitt Street, Sydney',
      provider: 'Provider H',
      comment: 'pendiente due to part unavailability',
    },

    {
      licensePlate: '2223-YZA',
      orderHash: 'hash2223',
      status: 'activo',
      date: '2024-07-25',
      time: '17:00-17:30',
      country: 'Spain',
      zone: 'Barcelona',
      vertical: 'ITV',
      product: 'ITV coche eléctrico',
      client: 'Isabel Gomez',
      issue: 'None',
      pickupAddress: 'La Rambla, Barcelona',
      deliveryAddress: 'Sagrada Familia, Barcelona',
      provider: 'Provider I',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '2425-BCD',
      orderHash: 'hash2425',
      status: 'activo',
      date: '2024-07-25',
      time: '18:00-18:30',
      country: 'Mexico',
      zone: 'Mexico City',
      vertical: 'Lavado',
      product: 'Lavado interior',
      client: 'Miguel Diaz',
      issue: 'None',
      pickupAddress: 'Paseo de la Reforma, Mexico City',
      deliveryAddress: 'Zócalo, Mexico City',
      provider: 'Provider J',
      comment: 'Service completed successfully',
    },

    {
      licensePlate: '2627-EFG',
      orderHash: 'hash2627',
      status: 'finished',
      date: '2024-07-26',
      time: '19:00-19:30',
      country: 'Argentina',
      zone: 'Buenos Aires',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Santiago Alvarez',
      issue: 'None',
      pickupAddress: 'Avenida 9 de Julio, Buenos Aires',
      deliveryAddress: 'Caminito, Buenos Aires',
      provider: 'Provider K',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '2829-HIJ',
      orderHash: 'hash2829',
      status: 'pendiente',
      date: '2024-07-26',
      time: '20:00-20:30',
      country: 'Brazil',
      zone: 'Rio de Janeiro',
      vertical: 'Taller',
      product: 'Cambio de llantas',
      client: 'Roberto Lima',
      issue: 'Part not available',
      pickupAddress: 'Copacabana, Rio de Janeiro',
      deliveryAddress: 'Ipanema, Rio de Janeiro',
      provider: 'Provider L',
      comment: 'pendiente due to part unavailability',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },

    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1123-JKL',
      orderHash: 'hash1123',
      status: 'activo',
      date: '2024-07-04',
      time: '12:00-12:30',
      country: 'Italy',
      zone: 'Rome',
      vertical: 'Mantenimiento',
      product: 'Revisión general',
      client: 'Carlos Sanchez',
      issue: 'None',
      pickupAddress: 'Via del Corso, Rome',
      deliveryAddress: 'Piazza Navona, Rome',
      provider: 'Provider D',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1415-MNO',
      orderHash: 'hash1415',
      status: 'pendiente',
      date: '2024-07-05',
      time: '13:00-13:30',
      country: 'United Kingdom',
      zone: 'London',
      vertical: 'ITV',
      product: 'ITV coche de diésel',
      client: 'Ana Perez',
      issue: 'Provider issue',
      pickupAddress: 'Oxford Street, London',
      deliveryAddress: 'Piccadilly Circus, London',
      provider: 'Provider E',
      comment: 'Cancelled due to provider issue',
    },
    {
      licensePlate: '1617-PQR',
      orderHash: 'hash1617',
      status: 'activo',
      date: '2024-07-06',
      time: '14:00-14:30',
      country: 'USA',
      zone: 'New York',
      vertical: 'Lavado',
      product: 'Lavado y encerado',
      client: 'Luis Ramirez',
      issue: 'None',
      pickupAddress: 'Times Square, New York',
      deliveryAddress: 'Central Park, New York',
      provider: 'Provider F',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1819-STU',
      orderHash: 'hash1819',
      status: 'pendiente',
      date: '2024-07-07',
      time: '15:00-15:30',
      country: 'Canada',
      zone: 'Toronto',
      vertical: 'Mantenimiento',
      product: 'Cambio de aceite',
      client: 'Elena Torres',
      issue: 'Client rescheduled',
      pickupAddress: 'Queen Street, Toronto',
      deliveryAddress: 'Bay Street, Toronto',
      provider: 'Provider G',
      comment: 'Client requested to reschedule',
    },
    {
      licensePlate: '2021-VWX',
      orderHash: 'hash2021',
      status: 'activo',
      date: '2024-07-08',
      time: '16:00-16:30',
      country: 'Australia',
      zone: 'Sydney',
      vertical: 'Taller',
      product: 'Reparación de frenos',
      client: 'Jorge Fernandez',
      issue: 'Part not available',
      pickupAddress: 'George Street, Sydney',
      deliveryAddress: 'Pitt Street, Sydney',
      provider: 'Provider H',
      comment: 'pendiente due to part unavailability',
    },
    {
      licensePlate: '2223-YZA',
      orderHash: 'hash2223',
      status: 'pendiente',
      date: '2024-07-09',
      time: '17:00-17:30',
      country: 'Spain',
      zone: 'Barcelona',
      vertical: 'ITV',
      product: 'ITV coche eléctrico',
      client: 'Isabel Gomez',
      issue: 'None',
      pickupAddress: 'La Rambla, Barcelona',
      deliveryAddress: 'Sagrada Familia, Barcelona',
      provider: 'Provider I',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '2425-BCD',
      orderHash: 'hash2425',
      status: 'activo',
      date: '2024-07-10',
      time: '18:00-18:30',
      country: 'Mexico',
      zone: 'Mexico City',
      vertical: 'Lavado',
      product: 'Lavado interior',
      client: 'Miguel Diaz',
      issue: 'None',
      pickupAddress: 'Paseo de la Reforma, Mexico City',
      deliveryAddress: 'Zócalo, Mexico City',
      provider: 'Provider J',
      comment: 'Service completed successfully',
    },
  ];

  verticals: Verticals[] = [
    { name: 'Desconocido', type: SERVICES_TYPES.DESCONOCIDO },
    { name: 'Transfer', type: SERVICES_TYPES.TRANSFER },
    { name: 'Taller', type: SERVICES_TYPES.TALLER },
    { name: 'Lavado', type: SERVICES_TYPES.LAVADO },
    { name: 'Repostaje', type: SERVICES_TYPES.REPOSTAJE },
    { name: 'Pre-ITV', type: SERVICES_TYPES.PRE_ITV },
    {
      name: 'Transfer Larga Distancia',
      type: SERVICES_TYPES.TRANSFER_LARGA_DISTANCIA,
    },
    { name: 'ITV', type: SERVICES_TYPES.ITV },
    { name: 'Seguro', type: SERVICES_TYPES.SEGURO },
    { name: 'Cafler Fresh', type: SERVICES_TYPES.CAFLER_FRESH },
    { name: 'Valet', type: SERVICES_TYPES.VALET },
    { name: 'Neumáticos', type: SERVICES_TYPES.NEUMATICOS },
    { name: 'Trámites', type: SERVICES_TYPES.TRAMITES },
    { name: 'Parking', type: SERVICES_TYPES.PARKING },
    {
      name: 'Vehículo de sustitución',
      type: SERVICES_TYPES.VEHICULO_SUSTITUCION,
    },
    { name: 'Grúa', type: SERVICES_TYPES.GRUA },
    { name: 'Pide lo que quieras', type: SERVICES_TYPES.PIDE_LO_QUE_QUIERAS },
    { name: 'Campa' , type: SERVICES_TYPES.CAMPA}
  ];

  actualAccount!: AccountInfo;

  constructor(private authService: MsalService, private http: HttpClient) {
    // this.acquireMSClient('df29dab1-ac1b-4aff-8579-f4d4f130df40');
  }

  // [] - Pending to refactorize all this methoDS

  getServicesHardcoded(): Observable<Service[]> {
    return of<Service[]>(this.serviceData);
  }

  updateNodeStatus(nodeData: any) {
    return this.http.post(`${this.url}/services/block-node`, nodeData);
  }

  getZones(): Observable<ZonesResponse> {
    return this.http.get<ZonesResponse>(`${this.url}/zones`);
  }

  getServices(serviceData: any) {
    return this.http.post(`${this.url}/services`, serviceData);
  }

  sendMessage(messageData: any) {
    return this.http.post(
      `${this.url}/services/send-messages-for-confirm-services`,
      messageData
    );
  }

  addEventOrIncidence(eventData: any) {
    return this.http.post(`${this.url}/event`, eventData);
  }

  public getGantt(request: GanttRequest): Observable<GetGanttResponse> {
    return this.http.post<GetGanttResponse>(`${this.url}/gantt`, request);
  }

  getDriver(driverId: string): Observable<GetDriversGantResponse> {
    return this.http.get<GetDriversGantResponse>(`${this.url}/drivers?driverIdFilter=${driverId}`);
  }

  getDriverByName(driverId: string) {
    return this.http.get(`${this.url}/drivers?nameFilterQuery=${driverId}`);
  }

  acquireMSClient(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.authService.instance.getAllAccounts().length) {
        this.actualAccount = this.authService.instance.getAllAccounts()[0];

        const request = {
          scopes: ['User.Read.All'],
          account: this.actualAccount,
        };

        const existingClient = this.clients.find((x: any) => x.id === id);
        if (existingClient) {
          resolve(existingClient);
        } else {
          this.authService.instance
            .acquireTokenSilent(request)
            .then((response) => {
              fetchUser(response.accessToken, id)
                .then((user) => {
                  if (!this.clients.find((x: any) => x.id === id)) {
                    this.clients.push(user);
                  }
                  resolve(user);
                })
                .catch((error) => {
                  console.error('Error fetching user:', error);
                  reject(error);
                });
            })
            .catch((error) => {
              console.error('Error al renovar el token:', error);

              this.authService.instance
                .acquireTokenPopup(request)
                .then((response) => {
                  console.log('Token renovado mediante popup');
                  fetchUser(response.accessToken, id)
                    .then((user) => {
                      if (!this.clients.find((x: any) => x.id === id)) {
                        this.clients.push(user);
                      }
                      resolve(user);
                    })
                    .catch((error) => {
                      console.error('Error fetching user:', error);
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.error(
                    'Error al renovar el token mediante popup:',
                    error
                  );
                  reject(error);
                });
            });
        }
      } else {
        reject('No account found');
      }
    });
  }
}

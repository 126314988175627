import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, from, Subject, Subscription } from 'rxjs';
// import { AuthService } from 'src/app/auth/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssignerService {
  url = environment.url;
  constructor(private http: HttpClient) {}
  private channels: { [key: string]: Subject<any> } = {};

  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }

    return this.channels[topic].subscribe(observer);
  }

  publish(topic: string, data: any): void {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }

    subject.next(data);
  }

  saveAssignament(data: any): Observable<any> {
    return from(data);
  }

  getAssignments() {
    return this.http.get(`${this.url}/algorithms`);
  }

  addAssignment(assignment: any) {
    return this.http.post(`${this.url}/algorithms/add`, assignment);
  }

  applyAssignment(assignment: any) {
    return this.http.post(`${this.url}/algorithms/apply`, assignment);
  }
}

import { Component, Renderer2 } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { PoolManagerService } from 'src/app/services/pool-manager/pool-manager.service';
declare var moment: any;
@Component({
  selector: 'app-pool-management',
  templateUrl: './pool-management.component.html',
  styleUrls: ['./pool-management.component.css'],
})
export class PoolManagementComponent {
  activeLink: string = 'pool-management';

  countOfServiceNotStarted: number = 0;

  constructor(public historyServicesSvc: HistoryServicesService) {}

  async ngOnInit(): Promise<any> {}

  setActiveLink(link: string) {
    this.countOfServiceNotStarted = 0;
    this.activeLink = link;
  }

  getCountServicesNotStarted(event: any) {
    this.countOfServiceNotStarted = event;
  }
}

import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild, TemplateRef, Input, NgZone, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl } from '@angular/forms';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { TuiDay  } from '@taiga-ui/cdk';
declare var  moment: any
declare var google: any;
import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';

@Component({
  selector: 'app-modal-assign-provider',
  templateUrl: './modal-assign-provider.component.html',
  styleUrls: ['./modal-assign-provider.component.css']
})
export class ModalAssignProviderComponent {
    @Input() order_hash: any;
    @Input() service_info: any;
    @Input() product: any;
    @Input() idItv: any;
    active_provider:any;
    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas,
        private hs:HistoryServicesService,
        private ngZone: NgZone,
        private ms: MapaServiceService,
        private renderer: Renderer2
    ) {}

    assignForm = this.fb.group({
        orderHash:['', Validators.required],
        providerId:['', Validators.required]
    });
    filterForm = this.fb.group({
        nameFilter:''
    });
    elements_providers:any;
    elements_providers2:any;

    ngOnInit(){
        this.assignForm.patchValue({
            orderHash:this.order_hash
        });
        this.showLoader();

        this.hs.getProviders(this.service_info?.zoneId).subscribe((providers: any) => {
            this.elements_providers2 = providers.providersInZone;
            if(this.product.productData.productLocalizationCode != '360-mot-fuel-car-es' && this.product.productData.productLocalizationCode != 'mot-homologation'){
                this.elements_providers = this.elements_providers2
                .filter((pro: any) =>
                  pro.productIds.includes(this.product.productId)
                )
                .map((pro: any) => pro);
            }else{
                this.elements_providers = this.elements_providers2
                .filter((pro: any) =>
                  pro.productIds.includes(this.idItv)
                )
                .map((pro: any) => pro);
            }


            this.hideLoader();
        });
    }

    filterData(event: Event): void {
        const query = (event.target as HTMLInputElement).value.toLowerCase();
        this.elements_providers = this.elements_providers2.filter((item:any) =>
            item.name.toLowerCase().includes(query) &&
            item.productIds.includes(this.product.productId)
        );
    }

    closeCanvas(){
        this.offcanvasService.dismiss();
    }

    div_loder = document.getElementById('loader');
    showLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'flex');
        }
    }

    hideLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'none');
        }

        setTimeout(()=>{
            const closeDiv = document.querySelector('.t-close');
            if (closeDiv) {
              (closeDiv as HTMLElement).click();
            }
        },5000);
    }

    showLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'flex'; // Agrega display: flex al div
        }
    }

    hideLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'none'; // Agrega display: flex al div
        }
    }

    showErrorMsj(){
        setTimeout(()=>{
            const sourceElement = document.querySelector('tui-alert');
            const targetElement = document.getElementById('modal_assign_provider_service_alert');
            if (sourceElement && targetElement) {
                // console.log('replicar');
                const clonedNode = sourceElement.cloneNode(true);
                // targetElement!.innerHTML = '';
                targetElement.appendChild(sourceElement);
            }
        },300);
    }

    assignProvider(){
        this.showLoaderModal();

        let data = {
          orderHash:  this.service_info.orderHash,
          productId:  this.product.productId,
          providerId: this.assignForm.value.providerId,
        };
        this.hs.addProviderToProductInService(data).subscribe(
            (res: any) => {
                this.hideLoaderModal();
                if (!res.wasSuccessful && res?.statusCode) {
                    this.hs.showErrorCode(res.statusCode);
                    this.showErrorMsj();
                } else {
                    this.offcanvasService.dismiss();
                    this.hs.publish('updateDetailsService');
                    this.showDepositAlert(
                        'Exito!',
                        'Se ha asignado correctamente el proveedor'
                    );
                }
            },
            (error) => {
                this.hideLoaderModal();
                if (error.status === 500) {
                    this.alerts
                      .open('Problema en el servidor', {
                        label: 'Error 500',
                        status: 'error',
                        autoClose: false,
                      })
                      .subscribe();
                    this.showErrorMsj();
                } else {
                    this.alerts
                      .open(error, {
                        label: 'Otro error',
                        status: 'error',
                        autoClose: false,
                      })
                      .subscribe();
                    this.showErrorMsj();
                }
            }
        );
    }

    showDepositAlert(title:any,msj:any): void {
        this.alerts.open(msj, {
            label: title,
            status: 'success',
            autoClose: true,
        }).subscribe();
    }

    selectprovider(provider:any){
        this.assignForm.patchValue({
            providerId:provider.id
        });

        this.active_provider = provider.id;
    }
}  

import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-new-client-modal',
  templateUrl: './new-client-modal.component.html',
  styleUrls: ['./new-client-modal.component.css']
})
export class NewClientModalComponent {
    constructor(
        private offcanvasService: NgbOffcanvas
    ) {}
    @Input() action: any;
    @Input() client: any;

    closeCanvas(){
        this.offcanvasService.dismiss();
    }
}

<div class="custom-container ">
    <div class="page-header mb-5">

        <label class="page-header-title">{{ 'home.title' | translate }}</label>
        <div class="user-options">
          <div
          tuiGroup
          class="group"
          [collapsed]="true"
      >
          <button
              appearance="primary-button"
              tuiButton
              class="new-button"
              type="button"
              [tuiDropdown]="dropdownContent"
              [tuiDropdownManual]="open"
             
              (click)="onClick()"
              (tuiActiveZoneChange)="onActiveZone($event)"
          >
              {{ 'home.button_new' | translate }}
              <button
                  appearance="primary-button"
                  icon="tuiIconChevronDown"
                  title="A sample of icon-button in a group"
                  tuiIconButton
                  type="button"
                 
                  class="tui-group__auto-width-item w30"
              >
              <ng-template #dropdownContent>
                <div class="dropdown">
         
                    <div class="dropdown-options"  (click)="redirect('/operational-staff')">{{ 'home.new_personal' | translate }}</div>
                    <div class="dropdown-options" (click)="redirect('/service-manager/pending-services')" >{{ 'home.new_service' | translate }}</div>
                    <!-- <div class="dropdown-options"  (click)="redirect('/clients')">{{ 'home.new_client' | translate }}</div>
                    <div class="dropdown-options" (click)="redirect('/providers')">{{ 'home.new_provider' | translate }}</div> -->
                </div>
            </ng-template>
            
            </button>
          </button>
          
 
      </div>
        </div>
    </div>

<!-- 
<div  style="justify-content: space-between;" class="d-flex">
    <div class="grafic">
        <div class="d-flex grafic-title">
            <label class="title">Servicios</label>
    
          <button
          appearance="outline"
          icon="tuiIconArrowRight"
          tuiIconButton
          type="button"
          class="tui-space_right-3 tui-space_bottom-3"
    
      ></button>
        </div>
        <tui-ring-chart
        [value]="servicesCount"
        [activeItemIndex]="2"
    >
    <label class="title">{{servicesData.length}} </label>
        <div>Servicios</div>
        </tui-ring-chart>
        <div class="d-flex grafic-footer">
            <tui-badge
            status="custom"
            [value]="servicesPercentage[0]"
            class="tui-space_right-2 tui-space_bottom-2 support-14">
    
     
            </tui-badge>
            <tui-badge
            status="custom"
            [value]="servicesPercentage[1]"
            class="tui-space_right-2 tui-space_bottom-2 support-15">
    
     
            </tui-badge>
        </div>
    </div>
    <div class="grafic">
        <div class="d-flex grafic-title">
            <label class="title">Conductores</label>
    
          <button
          appearance="outline"
          icon="tuiIconArrowRight"
          tuiIconButton
          type="button"
          class="tui-space_right-3 tui-space_bottom-3"
    
      ></button>
        </div>
        <tui-ring-chart
        [value]="driversCount"
        [activeItemIndex]="3"
    >
    <label class="title">{{driversData.length}}</label>
        <div>Conductores</div>
        </tui-ring-chart>
        <div class="d-flex grafic-footer">
            <tui-badge
            status="custom"
            [value]="driversPercentage[0]"
            class="tui-space_right-2 tui-space_bottom-2 support-14">
    
     
            </tui-badge>
            <tui-badge
            status="custom"
            [value]="driversPercentage[1]"
            class="tui-space_right-2 tui-space_bottom-2 support-15">
    
     
            </tui-badge>
        </div>
    </div>
    <div class="grafic">
        <div class="d-flex grafic-title">
            <label class="title">Clientes</label>
    
          <button
          appearance="outline"
          icon="tuiIconArrowRight"
          tuiIconButton
          type="button"
          class="tui-space_right-3 tui-space_bottom-3"
    
      ></button>
        </div>
        <tui-ring-chart
        [value]="clientsCount"
        [activeItemIndex]="3"
    >
    <label class="title">{{clientsData.length}} </label>
        <div>Clientes</div>
        </tui-ring-chart>
        <div class="d-flex grafic-footer">
            <tui-badge
            status="custom"
            [value]="clientsPercentage[0]"
            class="tui-space_right-2 tui-space_bottom-2 support-14">
    
     
            </tui-badge>
            <tui-badge
            status="custom"
            [value]="clientsPercentage[1]"
            class="tui-space_right-2 tui-space_bottom-2 support-15">
    
     
            </tui-badge>
        </div>
    </div>
    <div class="grafic">
        <div class="d-flex grafic-title">
            <label class="title">Proveedores</label>
    
          <button
          appearance="outline"
          icon="tuiIconArrowRight"
          tuiIconButton
          type="button"
          class="tui-space_right-3 tui-space_bottom-3"
    
      ></button>
        </div>
        <tui-ring-chart
        [value]="providersCount"
        [activeItemIndex]="3"
    >
    <label class="title">{{providersData.length}}</label>
        <div>Proveedores</div>
        </tui-ring-chart>
        <div class="d-flex grafic-footer">
            <tui-badge
            status="custom"
     
            [value]="providersPercentage[0]"
            class="tui-space_right-2 tui-space_bottom-2 support-14">
    
     
            </tui-badge>
            <tui-badge
            status="custom"
            [value]="providersPercentage[1]"
            class="tui-space_right-2 tui-space_bottom-2 support-15">
    
     
            </tui-badge>
        </div>
    </div>










</div> -->

    <div class="chart-home">
        <div class="table-body-head d-flex mb-1 mt-2"> 
            <div class="table-body-options mb-3">
                <div>
                    <label class="table-title">{{ 'home.services_chart' | translate }}</label>
                </div>
                <tui-input-date tuiTextfieldSize="m" [(ngModel)]="selectedDate" (ngModelChange)="filterDataByDate()" style="margin-top: -8px;"> 
                    {{ 'home.date_input' | translate }}
                </tui-input-date>
                <!-- <select class="form-select table-option-select" aria-label="status" [(ngModel)]="selectedZone" (ngModelChange)="filterDataBySelect()">
                    <option  selected value="#">Zona</option>
                <select class="form-select table-option-select" aria-label="status" [(ngModel)]="selectedZone" (ngModelChange)="filterDataBySelect()">
                    <option  selected value="#">{{ 'home.zone_input' | translate }}</option>
                    <option *ngFor="let zone of zones" [value]="zone.zoneId">{{ zone.zoneName }}</option>
                </select> -->
                <ng-select
                [items]="zones"
                bindLabel="zoneName"
                bindValue="zoneId"
                [multiple]="false"
                [(ngModel)]="selectedZone"
                
                class="custom_select"
                (ngModelChange)="filterDataBySelect()"
                [placeholder]="'home.zone_input' | translate"
              >
              </ng-select>
         
                <button (click)="clearDate()"  class="export-button-menu mb-2 mt-1">
                    {{ 'home.clear_date' | translate }}
                </button>
            </div>
        </div> 
        <div class="chart-body">
            <canvas #chartCanvas></canvas>
        </div>
    </div>
</div>

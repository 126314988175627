import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from "@azure/msal-browser";
import { MsalGuard } from "@azure/msal-angular";

const isIframe = window !== window.parent && !window.opener;
const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login',
      loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule)
    },
    {
        path: 'operational-staff',
        loadChildren: () => import('./pages/operational-staff/operational-staff.module').then((m) => m.OperationalStaffModule),
        canActivate: [MsalGuard],
    },
    {
      path: 'service-manager',
      loadChildren: () => import('./pages/service-manager/service-manager.module').then((m) => m.ServiceManagerModule),
      canActivate: [MsalGuard],
    },
    {
      path: 'home',
      loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
      canActivate: [MsalGuard],
    },
    {
      path: 'assigner',
      loadChildren: () => import('./pages/assigner/assigner.module').then((m) => m.AssignerModule),
      canActivate: [MsalGuard],
    },
    {
      path: 'providers',
      loadChildren: () => import('./pages/providers/providers.module').then((m) => m.ProvidersModule),
      canActivate: [MsalGuard],
    },
    {
      path: 'clients',
      loadChildren: () => import('./pages/clients/clients.module').then((m) => m.ClientsModule),
      canActivate: [MsalGuard],
    },
    {
      path: 'platform-blocker',
      loadChildren: () => import('./pages/platform-blocker/platform-blocker.module').then((m) => m.PlatformBlockerModule),
    },
    {
      path: 'detail-service',
      loadChildren: () => import('./pages/detail-service/detail-service.module').then((m) => m.DetailServiceModule),
      canActivate: [MsalGuard],
    },
    {
        path: 'test-buttons',
        loadChildren: () => import('./pages/operational-staff/add-personal-modal/add-personal-modal.module').then((m) => m.AddPersonalModalModule),
    },
    {
        path: 'prices',
        loadChildren: () => import('./pages/prices/prices.module').then((m) => m.PricesModule),
        canActivate: [MsalGuard],
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { }

import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NewClientModalComponent } from 'src/app/pages/clients/new-client-modal/new-client-modal.component';
import { PricesComponent } from 'src/app/pages/clients/prices/prices.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.css'],
})
export class ClientsTableComponent {
  countries = ['España', 'UK', 'Francia'];

  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];
  filterCount:number = 0
  assignerData: any = [
    {
      client_name: 'Cliente taller A',
      billing_name: 'Taller A C.A',
      client_id: '0254478412',
      country: 'España',
      zone: 'Madrid',
      status: 'activo',
      email: 'emailclientetaller1@email.com',
      phone: '(+34) 656 34 34 34',
      type_client: 'Gold',
      company_description:
        'Especialistas en mantenimiento de vehículos de lujo',
      key_account: 'Key manager',
      category: 'turismo',
      activity_sector: 'Informática',
      group: 'VIP',
      sub_group: 'Técnicos',
      address: 'C. de Puerto Serrano, Arganzuela, 28045 Madrid, España',
      postal_code: '28045',
      city: 'Madrid',
      province: 'Madrid',
      officePhone: '+34548788455',
      billing_cif: '2342423',
      address_billing: 'C. de Puerto Serrano, Arganzuela, 28045 Madrid, España',
      postal_code_billing: '28045',
      city_billing: 'Madrid',
      province_billing: 'Madrid',
      country_billing: 'España',
      iban: '4558781211548511511212151512',
      bank_account: '2342342342334276887',
      payment_type: 'Transferencia bancaria',
      payment_day: '28',
      payment_contact_name: 'Peter',
      payment_contact_last_name: 'Perez',
      payment_contact_email: 'peter@email.com',
      aditional_info:
        'Ofrecemos paquetes personalizados para flotas corporativas',
    },
    {
      client_name: 'Client garage B',
      billing_name: 'Garage B SARL',
      client_id: '1298475613',
      country: 'Francia',
      zone: 'París',
      status: 'activo',
      email: 'emailclientgarage2@email.com',
      phone: '(+34) 140343434',
      type_client: 'Silver',
      company_description: 'Servicios rápidos de mantenimiento automotriz',
      key_account: 'Garage Manager',
      category: 'automoción',
      activity_sector: 'Mecánica',
      group: 'Profesionales',
      sub_group: 'Talleres',
      address: '15 Rue de la Paix, 75002 Paris, Francia',
      postal_code: '75002',
      city: 'París',
      province: 'Île-de-France',
      officePhone: '+34140343434',
      billing_cif: 'FR1298475613',
      address_billing: '15 Rue de la Paix, 75002 Paris, Francia',
      postal_code_billing: '75002',
      city_billing: 'París',
      province_billing: 'Île-de-France',
      country_billing: 'Francia',
      iban: 'FR7630006000011234567890189',
      bank_account: '12345678901',
      payment_type: 'Cheque',
      payment_day: '15',
      payment_contact_name: 'Jean',
      payment_contact_last_name: 'Dupont',
      payment_contact_email: 'jean.dupont@email.com',
      aditional_info: 'Especializados en coches eléctricos y híbridos',
    },
    {
      client_name: 'Client garage B',
      billing_name: 'Garage B SARL',
      client_id: '1298475613',
      country: 'Francia',
      zone: 'París',
      status: 'activo',
      email: 'emailclientgarage2@email.com',
      phone: '(+34) 140343434',
      type_client: 'Silver',
      company_description: 'Servicios rápidos de mantenimiento automotriz',
      key_account: 'Garage Manager',
      category: 'automoción',
      activity_sector: 'Mecánica',
      group: 'Profesionales',
      sub_group: 'Talleres',
      address: '15 Rue de la Paix, 75002 Paris, Francia',
      postal_code: '75002',
      city: 'París',
      province: 'Île-de-France',
      officePhone: '+34140343434',
      billing_cif: 'FR1298475613',
      address_billing: '15 Rue de la Paix, 75002 Paris, Francia',
      postal_code_billing: '75002',
      city_billing: 'París',
      province_billing: 'Île-de-France',
      country_billing: 'Francia',
      iban: 'FR7630006000011234567890189',
      bank_account: '12345678901',
      payment_type: 'Cheque',
      payment_day: '15',
      payment_contact_name: 'Jean',
      payment_contact_last_name: 'Dupont',
      payment_contact_email: 'jean.dupont@email.com',
      aditional_info: 'Especializados en coches eléctricos y híbridos',
    },
    {
      client_name: 'Client workshop C',
      billing_name: 'Workshop C Ltd',
      client_id: '9834756124',
      country: 'UK',
      zone: 'Londres',
      status: 'activo',
      email: 'emailclientworkshop3@email.com',
      phone: '(+34) 207 946 034',
      type_client: 'Bronze',
      company_description:
        'Servicios técnicos en la industria automotriz en Londres',
      key_account: 'Operations Director',
      category: 'manufactura',
      activity_sector: 'Ingeniería',
      group: 'Pequeñas empresas',
      sub_group: 'Servicios automotrices',
      address: '32 High Holborn, London, WC1V 6PS, UK',
      postal_code: 'WC1V 6PS',
      city: 'Londres',
      province: 'Inglaterra',
      officePhone: '+342079460344',
      billing_cif: 'GB9834756124',
      address_billing: '32 High Holborn, London, WC1V 6PS, UK',
      postal_code_billing: 'WC1V 6PS',
      city_billing: 'Londres',
      province_billing: 'Inglaterra',
      country_billing: 'UK',
      iban: 'GB29BARC20000012345678',
      bank_account: '12345678',
      payment_type: 'Transferencia bancaria',
      payment_day: '30',
      payment_contact_name: 'John',
      payment_contact_last_name: 'Smith',
      payment_contact_email: 'john.smith@email.com',
      aditional_info: 'Expertos en vehículos híbridos y eléctricos',
    },
    {
      client_name: 'Cliente taller D',
      billing_name: 'Taller D C.A',
      client_id: '8437562135',
      country: 'España',
      zone: 'Barcelona',
      status: 'activo',
      email: 'emailclientetaller4@email.com',
      phone: '(+34) 659 34 34 34',
      type_client: 'Gold',
      company_description:
        'Reparaciones especializadas en vehículos de alta gama',
      key_account: 'Gerente General',
      category: 'turismo',
      activity_sector: 'Automotriz',
      group: 'Elite',
      sub_group: 'Reparaciones',
      address: 'Carrer de Provença, 08037 Barcelona, España',
      postal_code: '08037',
      city: 'Barcelona',
      province: 'Barcelona',
      officePhone: '+34934555555',
      billing_cif: 'B8437562135',
      address_billing: 'Carrer de Provença, 08037 Barcelona, España',
      postal_code_billing: '08037',
      city_billing: 'Barcelona',
      province_billing: 'Barcelona',
      country_billing: 'España',
      iban: 'ES7921000813610123456789',
      bank_account: 'ES6100491810910123456789',
      payment_type: 'Transferencia bancaria',
      payment_day: '25',
      payment_contact_name: 'Carlos',
      payment_contact_last_name: 'Ramirez',
      payment_contact_email: 'carlos.ramirez@email.com',
      aditional_info:
        'Clientes VIP incluyen famosos y personalidades del mundo automovilístico',
    },
    {
      client_name: 'Client garage E',
      billing_name: 'Garage E SARL',
      client_id: '2345612876',
      country: 'Francia',
      zone: 'Lyon',
      status: 'activo',
      email: 'emailclientgarage5@email.com',
      phone: '(+34) 472103030',
      type_client: 'Silver',
      company_description: 'Centre de réparation rapide et fiable',
      key_account: 'Client Manager',
      category: 'automoción',
      activity_sector: 'Transporte',
      group: 'Servicios',
      sub_group: 'Mecánica rápida',
      address: 'Rue de la République, 69002 Lyon, Francia',
      postal_code: '69002',
      city: 'Lyon',
      province: 'Auvergne-Rhône-Alpes',
      officePhone: '+34472103030',
      billing_cif: 'FR2345612876',
      address_billing: 'Rue de la République, 69002 Lyon, Francia',
      postal_code_billing: '69002',
      city_billing: 'Lyon',
      province_billing: 'Auvergne-Rhône-Alpes',
      country_billing: 'Francia',
      iban: 'FR7630006000012345612876189',
      bank_account: '2345612876',
      payment_type: 'Cheque',
      payment_day: '10',
      payment_contact_name: 'Pierre',
      payment_contact_last_name: 'Legrand',
      payment_contact_email: 'pierre.legrand@email.com',
      aditional_info:
        'Spécialistes dans le diagnostic électronique des véhicules',
    },
    {
      client_name: 'Client workshop F',
      billing_name: 'Workshop F Ltd',
      client_id: '1273465923',
      country: 'UK',
      zone: 'Manchester',
      status: 'activo',
      email: 'emailclientworkshop6@email.com',
      phone: '(+34) 161 234 500',
      type_client: 'Bronze',
      company_description:
        'Complete vehicle maintenance services in the North West',
      key_account: 'Workshop Manager',
      category: 'industria',
      activity_sector: 'Ingeniería mecánica',
      group: 'Empresas locales',
      sub_group: 'Talleres independientes',
      address: '21 Portland St, Manchester M1 3HP, UK',
      postal_code: 'M1 3HP',
      city: 'Manchester',
      province: 'Inglaterra',
      officePhone: '+341612345000',
      billing_cif: 'GB1273465923',
      address_billing: '21 Portland St, Manchester M1 3HP, UK',
      postal_code_billing: 'M1 3HP',
      city_billing: 'Manchester',
      province_billing: 'Inglaterra',
      country_billing: 'UK',
      iban: 'GB29NWBK60161331926819',
      bank_account: '31926819',
      payment_type: 'Transferencia bancaria',
      payment_day: '20',
      payment_contact_name: 'Mark',
      payment_contact_last_name: 'Jones',
      payment_contact_email: 'mark.jones@email.com',
      aditional_info: 'High-performance vehicle specialists',
    },
  ];
  searchTerm: string = '';

  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  types!: any[];
  selectedDate: string = '#';
  selectedTypeClient: string = '#';
  selectedZone: string = '#';
  selectedProduct: string = '#';
  sortedColumn: string = '';
  sortDirection!: any;

  index: number = 0;
  length!: number;
  pageSize: number = 6;
  readonly columns = [
    'client_name',
    'billing_name',
    'client_id',
    'country',
    'zone',
    'status',
    'email',
    'phone',
    'prices',
    'actions',
  ];

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  open = false;
  selected = null;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private offcanvasService: NgbOffcanvas,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.length = Math.ceil(this.assignerData.length / this.pageSize);
    this.paginatedDataOperation();
    this.zones = [...new Set(this.assignerData.map((item: any) => item.zone))];
    this.types = [
      ...new Set(this.assignerData.map((item: any) => item.type_client)),
    ];
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    this.filterCount = this.assignerData.length
    const start = this.index * this.pageSize;
    this.paginatedData = this.assignerData.slice(start, start + this.pageSize);
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }

  filterDataBySelect(index?: any) {
    this.index = index ?? 0;

    this.paginatedData = this.assignerData.filter((item: any) => {
      const clientName = item.client_name.toLowerCase();
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

      const matchesSearch = !this.searchTerm || clientName.includes(search);

      const matchesTypeClient =
        this.selectedTypeClient === '#' ||
        item.type_client === this.selectedTypeClient;

      const matchesZone =
        this.selectedZone === '#' || item.zone === this.selectedZone;

      return matchesSearch && matchesTypeClient && matchesZone;
    });

    this.sortByExec();
    this.applyPagination();
  }

  openModalEditClient(client: any) {
    const offcanvasRef = this.offcanvasService.open(NewClientModalComponent, {
      backdrop: 'static',
      position: 'end',
    });
    offcanvasRef.componentInstance.action = 'ed';
    offcanvasRef.componentInstance.client = client;
  }

  goPrices(data: any) {
    localStorage.setItem('active_client_prices', JSON.stringify(data));
    this.router.navigate(['/prices'], {
      queryParams: { client_id: data.client_id },
    });
  }

  applyPagination() {
    this.filterCount = this.paginatedData.length
    const start = this.index * this.pageSize;
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

    if (this.length == 1) {
      this.index = 0;
    }
  }

  onSort(event: any): void {
    this.sortDirection = event;
  }

  sortData(): void {
    this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  setSortColumn(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-add-assignment',
  templateUrl: './modal-add-assignment.component.html',
  styleUrls: ['./modal-add-assignment.component.css']
})
export class ModalAddAssignmentComponent {

}

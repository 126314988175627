import { Injectable } from '@angular/core';
import { Language } from '../../models/language/language.interface';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  
  private readonly availableLanguages: Language[] = [
    { code: 'es', name: 'ES' },
    { code: 'en', name: 'EN' },
    { code: 'fr', name: 'FR' }
  ];

  constructor() { }

  getLanguages(): Language[] {
    return this.availableLanguages;
  }
  
}

<!-- <div class="notificatio-ui error" *ngIf="showAlert">
  <img
    class="img-icon up_icon"
    src="../../../assets/img/up_icon.png"
    width="24"
    (click)="toggleAlert('success')"
    *ngIf="openAlert"
  />
  <img
    class="img-icon up_icon"
    src="../../../assets/img/down_icon.png"
    width="24"
    (click)="toggleAlert('success')"
    *ngIf="!openAlert"
  />
  <p class="fs-15">
    <b>
      <img
        class="img-icon"
        src="../../../assets/img/error_notificatio.svg"
        width="20"
        class="mr_10"
      />
      La tarifa ha sido cambiada
    </b>
  </p>
  <p class="ml_35 fs-13" *ngIf="openAlert">
    Una vez cambiada al tarifa los cambios previos se perderan. ¿Desea cambiar a
    tarifa <strong> {{ nextPlanName }} </strong> ?.
  </p>
</div> -->
<div class="page-header">
  <label class="page-header-title">
    <label class="sub_label" (click)="goBack()">
      <tui-icon icon="tuiIconChevronLeft"></tui-icon>
      {{ "detail_service.back" | translate }}
    </label>
    {{ "pool_parameters.title" | translate }}
  </label>
  <div *ngIf="showAlert" class="user-options">
    <button
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3"
      size="m"
      appearance="white-button"
      (click)="closeAlert()"
    >
      {{ "Descartar" }}
    </button>
    <button
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3"
      size="m"
      appearance="primary-button"
      (click)="updateFeeData()"
    >
      {{ "Guardar cambios" }}
    </button>
  </div>
</div>
<div class="table-body mt-4">
  <div class="table-body-head"></div>
  <div class="table">
    <ng-select
      [multiple]="false"
      [items]="zones"
      bindLabel="zoneName"
      bindValue="zoneId"
      class="zone-select mt-3"
      placeholder="Zona operativa"
      [(ngModel)]="activeZone"
      (close)="filterData()"
    >
    </ng-select>
    <div class="row">
      <div>
        <br />
        <p class="p-17">
          <img src="/assets/img/tarifas.png" width="19" />
          {{ "pool_parameters.table_operative_zone.title" | translate }}
        </p>
      </div>

      <table tuiTable size="l" style="width: 100%" [columns]="columns_tarifas">
        <thead>
          <tr tuiThGroup>
            <th
              class="th-style"
              *tuiHead="'service_condition'"
              tuiTh
              [sticky]="true"
            >
              {{
                "pool_parameters.table_operative_zone.service_condition"
                  | translate
              }}
            </th>
            <th [sticky]="true" *tuiHead="'time_limit'" tuiTh>
              {{
                "pool_parameters.table_operative_zone.time_limit" | translate
              }}
            </th>
            <th [sticky]="true" *tuiHead="'actions'" tuiTh>Acciones</th>
          </tr>
        </thead>
        <tbody tuiTbody>
          <tr tuiTr *ngFor="let fare of detailClientData2.feesDetails">
            <td *tuiCell="'service_condition'" tuiTd>
              {{ fare.feeName }}
            </td>
            <td *tuiCell="'time_limit'" tuiTd>
              {{
                formatPrice(
                  fare.generalFeePrice.units,
                  fare.generalFeePrice.nanos
                )
              }}
            </td>

            <td *tuiCell="'actions'" tuiTd>
              <tui-icon
                (click)="openModal(editFeePriceModal, fare)"
                icon="tuiIconEdit2"
                class="pointer-item"
              ></tui-icon>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="products">
        <div class="col-12">
          <br /><br />
          <p class="p-17">
            <img src="/assets/img/productos.png" width="26" /> Productos
          </p>
          <hr />
        </div>
      </div>
      <div
        class="row"
        *ngFor="let product of detailClientData2.verticalDetails; let i = index"
      >
        <div class="col-12">
          <br />
          <p class="p-17">
            <!-- {{
                "enums.verticals." + (product.verticalName | lowercase)
                  | translate
              }} -->
            {{
              hs.translateVerticals(
                translate.currentLang,
                getVerticalRealName(product.verticalName)
              )
            }}
            ({{ product.productDetails.length }})

            <tui-badge
              status="success"
              value="Disponible en pool"
              class="tui-space_right-2 tui-space_bottom-2 custom_success"
            ></tui-badge>
            <tui-icon
              icon="tuiIconChevronRight"
              class="pointer-item"
              (click)="setOpenedDiv(i + 1)"
              *ngIf="openedDiv != i + 1"
            ></tui-icon>
            <tui-icon
              icon="tuiIconChevronDown"
              class="pointer_item"
              (click)="closeOpenedDiv()"
              *ngIf="openedDiv == i + 1"
            ></tui-icon>
          </p>
          <tui-toggle [(ngModel)]="togle" size="l"></tui-toggle>

          <label *ngIf="togle; else noDisponibility" class="label-pool">
            Disponible en el Pool</label
          >
          <ng-template #noDisponibility>
            <label class="label-pool"> No disponible en el Pool</label>
          </ng-template>
        </div>
        <div class="col-12" *ngIf="openedDiv == i + 1">
          <div class="overflow-auto">
            <table
              tuiTable
              size="l"
              style="width: 100%"
              [columns]="dinamyColums"
            >
              <thead>
                <tr tuiThGroup>
                  <ng-container *ngFor="let column of dinamyColums">
                    <th
                      class="th-style"
                      *tuiHead="column"
                      tuiTh
                      [sticky]="true"
                    >
                      {{
                        "pool_parameters.table_products." + column | translate
                      }}
                    </th>
                  </ng-container>
                </tr>
              </thead>

              <tbody tuiTbody>
                <tr tuiTr *ngFor="let productData of product.productDetails">
                  <td *tuiCell="'kilometer_strips'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          hs.translate2(
                            translate.currentLang,
                            productData.productKey.split(" ")[0]
                          )
                        }}
                        {{ productData.productKey.split(" ")[1] }}
                      </span>
                    </div>
                  </td>

                  <td *tuiCell="'pool_price'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.generalProviderPrice.units,
                            productData.generalProviderPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>

                  <td *tuiCell="'premium'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.providerDiscount.units,
                            productData.providerDiscount.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td *tuiCell="'percent_premium'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.clientProviderPrice.units,
                            productData.clientProviderPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td *tuiCell="'mobility_price'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.generalMobilityPrice.units,
                            productData.generalMobilityPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td *tuiCell="'hybrid'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.mobilityDiscount.units,
                            productData.mobilityDiscount.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td *tuiCell="'percent_hybrid'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.clientMobilityPrice.units,
                            productData.clientMobilityPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>

                  <td *tuiCell="'b2b'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.clientMobilityPrice.units,
                            productData.clientMobilityPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>

                  <td *tuiCell="'percent_b2b'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.clientMobilityPrice.units,
                            productData.clientMobilityPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td *tuiCell="'b2c'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.clientMobilityPrice.units,
                            productData.clientMobilityPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>

                  <td *tuiCell="'percent_b2c'" tuiTd>
                    <div [tuiCell]="size">
                      <span tuiTitle>
                        {{
                          formatPrice(
                            productData.clientMobilityPrice.units,
                            productData.clientMobilityPrice.nanos
                          )
                        }}
                      </span>
                    </div>
                  </td>

                  <td *tuiCell="'actions'" tuiTd>
                    <tui-icon
                      (click)="
                        openModalUpdateProduct(
                          editClientPriceModal,
                          productData,
                          product.verticalName
                        )
                      "
                      icon="tuiIconEdit2"
                      class="pointer-item"
                    ></tui-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <hr />
        </div>
      </div>
    </div>
  </div>
  <ng-template #change_status_options let-activeZone>
    <div class="download-menu menu-status">
      <label
        *ngFor="let plan of plans"
        class="mb-2 option-edit_list hover-link"
        (click)="verifiedFee(plan.planType, plan.name)"
      >
        {{ plan.name }}
      </label>
    </div>
  </ng-template>
</div>
<ng-template #editClientPriceModal>
  <app-edit-pool-price-modal
    [bsModalRef]="bsModalRef"
    [zones]="zones"
    [subproductData]="subproductData"
    (sendSubproductData)="receiveSubproductData($event)"
  >
  </app-edit-pool-price-modal>
</ng-template>
<ng-template #editFeePriceModal>
  <div class="modal-body">
    <form [formGroup]="feeUpdateForm" (ngSubmit)="onSubmitUpdateFee()">
      <div class="content_cancel_service">
        <br />
        <p class="fs_24">Editar {{ feeUpdateForm.get("feeName")?.value }}</p>
      </div>
      <div class="form-group content_cancel_service">
        <label>Tiempo limite (h)</label>
        <input
          formControlName="total"
          pattern="^\d+(\.\d{1,2})?$"
          type="text"
          class="form-control"
          (input)="onInput($event)"
        />
      </div>
      <div class="right_buttons">
        <br />
        <button
          class="close_modal_logout"
          type="button"
          (click)="bsModalRef?.hide()"
        >
          Salir sin guardar
        </button>
        <button class="add_topping_but" type="submit">Guardar</button>
        <br />
        <br />
        <div class="div_loader_modal div_inside_edit_assigned_hour">
          <img
            src="assets/logo-loading.gif"
            class="img_loader_inside_modal"
            alt=""
          />
        </div>
      </div>
    </form>
  </div>
</ng-template>

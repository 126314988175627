import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { tuiSum } from '@taiga-ui/cdk';
import { TuiHostedDropdownComponent } from '@taiga-ui/core';
import { formatDate } from '@angular/common';
import { ServicesService } from 'src/app/services/services.service';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { AddPersonalModalComponent } from '../operational-staff/add-personal-modal/add-personal-modal.component';
import { ModalDuplicateServiceComponent } from 'src/app/shared/modal-duplicate-service/modal-duplicate-service.component';
import { ModalAddClientComponent } from 'src/app/shared/modal-add-client/modal-add-client.component';
import { ModalAddProviderComponent } from 'src/app/shared/modal-add-provider/modal-add-provider.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
declare var Chart: any;
declare var moment: any;
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  readonly value = [13769, 12367];
  servicesCount: any[] = [];
  servicesPercentage: any[] = [];
  clientsCount: any[] = [];
  clientsPercentage: any[] = [];
  driversCount: any[] = [];
  driversPercentage: any[] = [];

  providersCount: any[] = [];
  providersPercentage: any[] = [];
  selectedDate!: any;
  selectedZone!: any;
  zones!: any[];
  labelChart: any[] = [];
  valueChart: any[] = [];
  cancelledValueChart: any[] = [];
  readonly total = tuiSum(...this.value);
  @ViewChild(TuiHostedDropdownComponent) component?: TuiHostedDropdownComponent;
  @ViewChild('chartCanvas', { static: true }) chartCanvas!: ElementRef;
  filterData: any[] = [];
  date: any = Math.floor(new Date('2024-08-14').getTime() / 1000);
  clientsData: any = [
    {
      client_name: 'Cliente taller A',
      billing_name: 'Taller A C.A',
      client_id: '0254478412',
      country: 'España',
      zone: 'Madrid',
      status: 'pendiente',
      email: 'emailclientetaller1@email.com',
      phone: '(+34) 656 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage B',
      billing_name: 'Garage B SARL',
      client_id: '1298475613',
      country: 'Francia',
      zone: 'París',
      status: 'pendiente',
      email: 'emailclientgarage2@email.com',
      phone: '(+33) 1 40 34 34 34',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop C',
      billing_name: 'Workshop C Ltd',
      client_id: '9834756124',
      country: 'UK',
      zone: 'Londres',
      status: 'pendiente',
      email: 'emailclientworkshop3@email.com',
      phone: '(+44) 20 7946 0344',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller D',
      billing_name: 'Taller D C.A',
      client_id: '8437562135',
      country: 'España',
      zone: 'Barcelona',
      status: 'pendiente',
      email: 'emailclientetaller4@email.com',
      phone: '(+34) 659 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage E',
      billing_name: 'Garage E SARL',
      client_id: '2345612876',
      country: 'Francia',
      zone: 'Lyon',
      status: 'pendiente',
      email: 'emailclientgarage5@email.com',
      phone: '(+33) 4 72 10 30 30',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop F',
      billing_name: 'Workshop F Ltd',
      client_id: '1273465923',
      country: 'UK',
      zone: 'Manchester',
      status: 'pendiente',
      email: 'emailclientworkshop6@email.com',
      phone: '(+44) 161 234 5000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller G',
      billing_name: 'Taller G C.A',
      client_id: '9827346128',
      country: 'España',
      zone: 'Valencia',
      status: 'pendiente',
      email: 'emailclientetaller7@email.com',
      phone: '(+34) 662 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage H',
      billing_name: 'Garage H SARL',
      client_id: '3456729183',
      country: 'Francia',
      zone: 'Marsella',
      status: 'activo',
      email: 'emailclientgarage8@email.com',
      phone: '(+33) 4 91 55 11 11',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop I',
      billing_name: 'Workshop I Ltd',
      client_id: '8273645921',
      country: 'UK',
      zone: 'Birmingham',
      status: 'activo',
      email: 'emailclientworkshop9@email.com',
      phone: '(+44) 121 675 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller J',
      billing_name: 'Taller J C.A',
      client_id: '1827364592',
      country: 'España',
      zone: 'Sevilla',
      status: 'activo',
      email: 'emailclientetaller10@email.com',
      phone: '(+34) 665 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage K',
      billing_name: 'Garage K SARL',
      client_id: '7382916475',
      country: 'Francia',
      zone: 'Toulouse',
      status: 'activo',
      email: 'emailclientgarage11@email.com',
      phone: '(+33) 5 62 27 10 10',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop L',
      billing_name: 'Workshop L Ltd',
      client_id: '8932475612',
      country: 'UK',
      zone: 'Edimburgo',
      status: 'activo',
      email: 'emailclientworkshop12@email.com',
      phone: '(+44) 131 200 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller M',
      billing_name: 'Taller M C.A',
      client_id: '2346582719',
      country: 'España',
      zone: 'Bilbao',
      status: 'activo',
      email: 'emailclientetaller13@email.com',
      phone: '(+34) 668 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage N',
      billing_name: 'Garage N SARL',
      client_id: '4938271567',
      country: 'Francia',
      zone: 'Niza',
      status: 'activo',
      email: 'emailclientgarage14@email.com',
      phone: '(+33) 4 93 13 13 13',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop O',
      billing_name: 'Workshop O Ltd',
      client_id: '9823746152',
      country: 'UK',
      zone: 'Glasgow',
      status: 'activo',
      email: 'emailclientworkshop15@email.com',
      phone: '(+44) 141 287 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller P',
      billing_name: 'Taller P C.A',
      client_id: '2198734562',
      country: 'España',
      zone: 'Zaragoza',
      status: 'activo',
      email: 'emailclientetaller16@email.com',
      phone: '(+34) 671 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage Q',
      billing_name: 'Garage Q SARL',
      client_id: '9128346571',
      country: 'Francia',
      zone: 'Bordeaux',
      status: 'activo',
      email: 'emailclientgarage17@email.com',
      phone: '(+33) 5 56 00 66 00',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop R',
      billing_name: 'Workshop R Ltd',
      client_id: '3827164592',
      country: 'UK',
      zone: 'Liverpool',
      status: 'activo',
      email: 'emailclientworkshop18@email.com',
      phone: '(+44) 151 233 3000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller S',
      billing_name: 'Taller S C.A',
      client_id: '2387465129',
      country: 'España',
      zone: 'Málaga',
      status: 'activo',
      email: 'emailclientetaller19@email.com',
      phone: '(+34) 674 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage T',
      billing_name: 'Garage T SARL',
      client_id: '1827364593',
      country: 'Francia',
      zone: 'Strasbourg',
      status: 'activo',
      email: 'emailclientgarage20@email.com',
      phone: '(+33) 3 88 60 00 00',
      type_client: 'Silver',
    },
  ];

  servicesData: any = [
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'activo',
      date: '2024-07-22',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'pendiente',
      date: '2024-07-22',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },

    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-23',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1123-JKL',
      orderHash: 'hash1123',
      status: 'activo',
      date: '2024-07-23',
      time: '12:00-12:30',
      country: 'Italy',
      zone: 'Rome',
      vertical: 'Mantenimiento',
      product: 'Revisión general',
      client: 'Carlos Sanchez',
      issue: 'None',
      pickupAddress: 'Via del Corso, Rome',
      deliveryAddress: 'Piazza Navona, Rome',
      provider: 'Provider D',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1415-MNO',
      orderHash: 'hash1415',
      status: 'activo',
      date: '2024-07-23',
      time: '13:00-13:30',
      country: 'United Kingdom',
      zone: 'London',
      vertical: 'ITV',
      product: 'ITV coche de diésel',
      client: 'Ana Perez',
      issue: 'Provider issue',
      pickupAddress: 'Oxford Street, London',
      deliveryAddress: 'Piccadilly Circus, London',
      provider: 'Provider E',
      comment: 'Cancelled due to provider issue',
    },

    {
      licensePlate: '1617-PQR',
      orderHash: 'hash1617',
      status: 'activo',
      date: '2024-07-24',
      time: '14:00-14:30',
      country: 'USA',
      zone: 'New York',
      vertical: 'Lavado',
      product: 'Lavado y encerado',
      client: 'Luis Ramirez',
      issue: 'None',
      pickupAddress: 'Times Square, New York',
      deliveryAddress: 'Central Park, New York',
      provider: 'Provider F',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1819-STU',
      orderHash: 'hash1819',
      status: 'pendiente',
      date: '2024-07-24',
      time: '15:00-15:30',
      country: 'Canada',
      zone: 'Toronto',
      vertical: 'Mantenimiento',
      product: 'Cambio de aceite',
      client: 'Elena Torres',
      issue: 'Client rescheduled',
      pickupAddress: 'Queen Street, Toronto',
      deliveryAddress: 'Bay Street, Toronto',
      provider: 'Provider G',
      comment: 'Client requested to reschedule',
    },
    {
      licensePlate: '2021-VWX',
      orderHash: 'hash2021',
      status: 'pendiente',
      date: '2024-07-24',
      time: '16:00-16:30',
      country: 'Australia',
      zone: 'Sydney',
      vertical: 'Taller',
      product: 'Reparación de frenos',
      client: 'Jorge Fernandez',
      issue: 'Part not available',
      pickupAddress: 'George Street, Sydney',
      deliveryAddress: 'Pitt Street, Sydney',
      provider: 'Provider H',
      comment: 'pendiente due to part unavailability',
    },

    {
      licensePlate: '2223-YZA',
      orderHash: 'hash2223',
      status: 'activo',
      date: '2024-07-25',
      time: '17:00-17:30',
      country: 'Spain',
      zone: 'Barcelona',
      vertical: 'ITV',
      product: 'ITV coche eléctrico',
      client: 'Isabel Gomez',
      issue: 'None',
      pickupAddress: 'La Rambla, Barcelona',
      deliveryAddress: 'Sagrada Familia, Barcelona',
      provider: 'Provider I',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '2425-BCD',
      orderHash: 'hash2425',
      status: 'activo',
      date: '2024-07-25',
      time: '18:00-18:30',
      country: 'Mexico',
      zone: 'Mexico City',
      vertical: 'Lavado',
      product: 'Lavado interior',
      client: 'Miguel Diaz',
      issue: 'None',
      pickupAddress: 'Paseo de la Reforma, Mexico City',
      deliveryAddress: 'Zócalo, Mexico City',
      provider: 'Provider J',
      comment: 'Service completed successfully',
    },

    {
      licensePlate: '2627-EFG',
      orderHash: 'hash2627',
      status: 'finished',
      date: '2024-07-26',
      time: '19:00-19:30',
      country: 'Argentina',
      zone: 'Buenos Aires',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Santiago Alvarez',
      issue: 'None',
      pickupAddress: 'Avenida 9 de Julio, Buenos Aires',
      deliveryAddress: 'Caminito, Buenos Aires',
      provider: 'Provider K',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '2829-HIJ',
      orderHash: 'hash2829',
      status: 'pendiente',
      date: '2024-07-26',
      time: '20:00-20:30',
      country: 'Brazil',
      zone: 'Rio de Janeiro',
      vertical: 'Taller',
      product: 'Cambio de llantas',
      client: 'Roberto Lima',
      issue: 'Part not available',
      pickupAddress: 'Copacabana, Rio de Janeiro',
      deliveryAddress: 'Ipanema, Rio de Janeiro',
      provider: 'Provider L',
      comment: 'pendiente due to part unavailability',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },

    {
      licensePlate: '1234-ABC',
      orderHash: 'hash1234',
      status: 'pendiente',
      date: '2024-07-01',
      time: '09:00-09:30',
      country: 'Spain',
      zone: 'Madrid',
      vertical: 'ITV',
      product: 'ITV coche de gasolina',
      client: 'Maria Garcia',
      issue: 'None',
      pickupAddress: 'Calle Mayor, 1, Madrid',
      deliveryAddress: 'Calle Serrano, 5, Madrid',
      provider: 'Provider A',
      comment: 'Ninguna incidencia',
    },
    {
      licensePlate: '5678-DEF',
      orderHash: 'hash5678',
      status: 'activo',
      date: '2024-07-02',
      time: '10:00-10:30',
      country: 'France',
      zone: 'Paris',
      vertical: 'Taller',
      product: 'Pack de mantenimiento',
      client: 'Juan Lopez',
      issue: 'Client not available',
      pickupAddress: 'Rue de Rivoli, Paris',
      deliveryAddress: 'Avenue des Champs-Élysées, Paris',
      provider: 'Provider B',
      comment: 'Client cancelled last minute',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '9101-GHI',
      orderHash: 'hash9101',
      status: 'pendiente',
      date: '2024-07-03',
      time: '11:00-11:30',
      country: 'Germany',
      zone: 'Berlin',
      vertical: 'Lavado',
      product: 'Lavado completo',
      client: 'Laura Martinez',
      issue: 'Machine malfunction',
      pickupAddress: 'Unter den Linden, Berlin',
      deliveryAddress: 'Potsdamer Platz, Berlin',
      provider: 'Provider C',
      comment: 'Machine broke down during service',
    },
    {
      licensePlate: '1123-JKL',
      orderHash: 'hash1123',
      status: 'activo',
      date: '2024-07-04',
      time: '12:00-12:30',
      country: 'Italy',
      zone: 'Rome',
      vertical: 'Mantenimiento',
      product: 'Revisión general',
      client: 'Carlos Sanchez',
      issue: 'None',
      pickupAddress: 'Via del Corso, Rome',
      deliveryAddress: 'Piazza Navona, Rome',
      provider: 'Provider D',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1415-MNO',
      orderHash: 'hash1415',
      status: 'pendiente',
      date: '2024-07-05',
      time: '13:00-13:30',
      country: 'United Kingdom',
      zone: 'London',
      vertical: 'ITV',
      product: 'ITV coche de diésel',
      client: 'Ana Perez',
      issue: 'Provider issue',
      pickupAddress: 'Oxford Street, London',
      deliveryAddress: 'Piccadilly Circus, London',
      provider: 'Provider E',
      comment: 'Cancelled due to provider issue',
    },
    {
      licensePlate: '1617-PQR',
      orderHash: 'hash1617',
      status: 'activo',
      date: '2024-07-06',
      time: '14:00-14:30',
      country: 'USA',
      zone: 'New York',
      vertical: 'Lavado',
      product: 'Lavado y encerado',
      client: 'Luis Ramirez',
      issue: 'None',
      pickupAddress: 'Times Square, New York',
      deliveryAddress: 'Central Park, New York',
      provider: 'Provider F',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '1819-STU',
      orderHash: 'hash1819',
      status: 'pendiente',
      date: '2024-07-07',
      time: '15:00-15:30',
      country: 'Canada',
      zone: 'Toronto',
      vertical: 'Mantenimiento',
      product: 'Cambio de aceite',
      client: 'Elena Torres',
      issue: 'Client rescheduled',
      pickupAddress: 'Queen Street, Toronto',
      deliveryAddress: 'Bay Street, Toronto',
      provider: 'Provider G',
      comment: 'Client requested to reschedule',
    },
    {
      licensePlate: '2021-VWX',
      orderHash: 'hash2021',
      status: 'activo',
      date: '2024-07-08',
      time: '16:00-16:30',
      country: 'Australia',
      zone: 'Sydney',
      vertical: 'Taller',
      product: 'Reparación de frenos',
      client: 'Jorge Fernandez',
      issue: 'Part not available',
      pickupAddress: 'George Street, Sydney',
      deliveryAddress: 'Pitt Street, Sydney',
      provider: 'Provider H',
      comment: 'pendiente due to part unavailability',
    },
    {
      licensePlate: '2223-YZA',
      orderHash: 'hash2223',
      status: 'pendiente',
      date: '2024-07-09',
      time: '17:00-17:30',
      country: 'Spain',
      zone: 'Barcelona',
      vertical: 'ITV',
      product: 'ITV coche eléctrico',
      client: 'Isabel Gomez',
      issue: 'None',
      pickupAddress: 'La Rambla, Barcelona',
      deliveryAddress: 'Sagrada Familia, Barcelona',
      provider: 'Provider I',
      comment: 'Service completed successfully',
    },
    {
      licensePlate: '2425-BCD',
      orderHash: 'hash2425',
      status: 'activo',
      date: '2024-07-10',
      time: '18:00-18:30',
      country: 'Mexico',
      zone: 'Mexico City',
      vertical: 'Lavado',
      product: 'Lavado interior',
      client: 'Miguel Diaz',
      issue: 'None',
      pickupAddress: 'Paseo de la Reforma, Mexico City',
      deliveryAddress: 'Zócalo, Mexico City',
      provider: 'Provider J',
      comment: 'Service completed successfully',
    },
  ];

  providersData: any = [
    {
      client_name: 'Cliente taller A',
      billing_name: 'Taller A C.A',
      client_id: '0254478412',
      country: 'España',
      zone: 'Madrid',
      status: 'pendiente',
      email: 'emailclientetaller1@email.com',
      phone: '(+34) 656 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage B',
      billing_name: 'Garage B SARL',
      client_id: '1298475613',
      country: 'Francia',
      zone: 'París',
      status: 'pendiente',
      email: 'emailclientgarage2@email.com',
      phone: '(+33) 1 40 34 34 34',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop C',
      billing_name: 'Workshop C Ltd',
      client_id: '9834756124',
      country: 'UK',
      zone: 'Londres',
      status: 'pendiente',
      email: 'emailclientworkshop3@email.com',
      phone: '(+44) 20 7946 0344',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller D',
      billing_name: 'Taller D C.A',
      client_id: '8437562135',
      country: 'España',
      zone: 'Barcelona',
      status: 'pendiente',
      email: 'emailclientetaller4@email.com',
      phone: '(+34) 659 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage E',
      billing_name: 'Garage E SARL',
      client_id: '2345612876',
      country: 'Francia',
      zone: 'Lyon',
      status: 'pendiente',
      email: 'emailclientgarage5@email.com',
      phone: '(+33) 4 72 10 30 30',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop F',
      billing_name: 'Workshop F Ltd',
      client_id: '1273465923',
      country: 'UK',
      zone: 'Manchester',
      status: 'pendiente',
      email: 'emailclientworkshop6@email.com',
      phone: '(+44) 161 234 5000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller G',
      billing_name: 'Taller G C.A',
      client_id: '9827346128',
      country: 'España',
      zone: 'Valencia',
      status: 'pendiente',
      email: 'emailclientetaller7@email.com',
      phone: '(+34) 662 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage H',
      billing_name: 'Garage H SARL',
      client_id: '3456729183',
      country: 'Francia',
      zone: 'Marsella',
      status: 'pendiente',
      email: 'emailclientgarage8@email.com',
      phone: '(+33) 4 91 55 11 11',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop I',
      billing_name: 'Workshop I Ltd',
      client_id: '8273645921',
      country: 'UK',
      zone: 'Birmingham',
      status: 'pendiente',
      email: 'emailclientworkshop9@email.com',
      phone: '(+44) 121 675 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller J',
      billing_name: 'Taller J C.A',
      client_id: '1827364592',
      country: 'España',
      zone: 'Sevilla',
      status: 'pendiente',
      email: 'emailclientetaller10@email.com',
      phone: '(+34) 665 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage K',
      billing_name: 'Garage K SARL',
      client_id: '7382916475',
      country: 'Francia',
      zone: 'Toulouse',
      status: 'pendiente',
      email: 'emailclientgarage11@email.com',
      phone: '(+33) 5 62 27 10 10',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop L',
      billing_name: 'Workshop L Ltd',
      client_id: '8932475612',
      country: 'UK',
      zone: 'Edimburgo',
      status: 'pendiente',
      email: 'emailclientworkshop12@email.com',
      phone: '(+44) 131 200 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller M',
      billing_name: 'Taller M C.A',
      client_id: '2346582719',
      country: 'España',
      zone: 'Bilbao',
      status: 'pendiente',
      email: 'emailclientetaller13@email.com',
      phone: '(+34) 668 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage N',
      billing_name: 'Garage N SARL',
      client_id: '4938271567',
      country: 'Francia',
      zone: 'Niza',
      status: 'activo',
      email: 'emailclientgarage14@email.com',
      phone: '(+33) 4 93 13 13 13',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop O',
      billing_name: 'Workshop O Ltd',
      client_id: '9823746152',
      country: 'UK',
      zone: 'Glasgow',
      status: 'activo',
      email: 'emailclientworkshop15@email.com',
      phone: '(+44) 141 287 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller P',
      billing_name: 'Taller P C.A',
      client_id: '2198734562',
      country: 'España',
      zone: 'Zaragoza',
      status: 'activo',
      email: 'emailclientetaller16@email.com',
      phone: '(+34) 671 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage Q',
      billing_name: 'Garage Q SARL',
      client_id: '9128346571',
      country: 'Francia',
      zone: 'Bordeaux',
      status: 'activo',
      email: 'emailclientgarage17@email.com',
      phone: '(+33) 5 56 00 66 00',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop R',
      billing_name: 'Workshop R Ltd',
      client_id: '3827164592',
      country: 'UK',
      zone: 'Liverpool',
      status: 'activo',
      email: 'emailclientworkshop18@email.com',
      phone: '(+44) 151 233 3000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller S',
      billing_name: 'Taller S C.A',
      client_id: '2387465129',
      country: 'España',
      zone: 'Málaga',
      status: 'activo',
      email: 'emailclientetaller19@email.com',
      phone: '(+34) 674 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage T',
      billing_name: 'Garage T SARL',
      client_id: '1827364593',
      country: 'Francia',
      zone: 'Strasbourg',
      status: 'activo',
      email: 'emailclientgarage20@email.com',
      phone: '(+33) 3 88 60 00 00',
      type_client: 'Silver',
    },
  ];

  driversData: any = [
    {
      client_name: 'Cliente taller A',
      billing_name: 'Taller A C.A',
      client_id: '0254478412',
      country: 'España',
      zone: 'Madrid',
      status: 'pendiente',
      email: 'emailclientetaller1@email.com',
      phone: '(+34) 656 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage B',
      billing_name: 'Garage B SARL',
      client_id: '1298475613',
      country: 'Francia',
      zone: 'París',
      status: 'pendiente',
      email: 'emailclientgarage2@email.com',
      phone: '(+33) 1 40 34 34 34',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop C',
      billing_name: 'Workshop C Ltd',
      client_id: '9834756124',
      country: 'UK',
      zone: 'Londres',
      status: 'pendiente',
      email: 'emailclientworkshop3@email.com',
      phone: '(+44) 20 7946 0344',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller D',
      billing_name: 'Taller D C.A',
      client_id: '8437562135',
      country: 'España',
      zone: 'Barcelona',
      status: 'pendiente',
      email: 'emailclientetaller4@email.com',
      phone: '(+34) 659 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage E',
      billing_name: 'Garage E SARL',
      client_id: '2345612876',
      country: 'Francia',
      zone: 'Lyon',
      status: 'pendiente',
      email: 'emailclientgarage5@email.com',
      phone: '(+33) 4 72 10 30 30',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop F',
      billing_name: 'Workshop F Ltd',
      client_id: '1273465923',
      country: 'UK',
      zone: 'Manchester',
      status: 'pendiente',
      email: 'emailclientworkshop6@email.com',
      phone: '(+44) 161 234 5000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller G',
      billing_name: 'Taller G C.A',
      client_id: '9827346128',
      country: 'España',
      zone: 'Valencia',
      status: 'activo',
      email: 'emailclientetaller7@email.com',
      phone: '(+34) 662 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage H',
      billing_name: 'Garage H SARL',
      client_id: '3456729183',
      country: 'Francia',
      zone: 'Marsella',
      status: 'activo',
      email: 'emailclientgarage8@email.com',
      phone: '(+33) 4 91 55 11 11',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop I',
      billing_name: 'Workshop I Ltd',
      client_id: '8273645921',
      country: 'UK',
      zone: 'Birmingham',
      status: 'activo',
      email: 'emailclientworkshop9@email.com',
      phone: '(+44) 121 675 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller J',
      billing_name: 'Taller J C.A',
      client_id: '1827364592',
      country: 'España',
      zone: 'Sevilla',
      status: 'activo',
      email: 'emailclientetaller10@email.com',
      phone: '(+34) 665 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage K',
      billing_name: 'Garage K SARL',
      client_id: '7382916475',
      country: 'Francia',
      zone: 'Toulouse',
      status: 'activo',
      email: 'emailclientgarage11@email.com',
      phone: '(+33) 5 62 27 10 10',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop L',
      billing_name: 'Workshop L Ltd',
      client_id: '8932475612',
      country: 'UK',
      zone: 'Edimburgo',
      status: 'activo',
      email: 'emailclientworkshop12@email.com',
      phone: '(+44) 131 200 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller M',
      billing_name: 'Taller M C.A',
      client_id: '2346582719',
      country: 'España',
      zone: 'Bilbao',
      status: 'activo',
      email: 'emailclientetaller13@email.com',
      phone: '(+34) 668 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage N',
      billing_name: 'Garage N SARL',
      client_id: '4938271567',
      country: 'Francia',
      zone: 'Niza',
      status: 'activo',
      email: 'emailclientgarage14@email.com',
      phone: '(+33) 4 93 13 13 13',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop O',
      billing_name: 'Workshop O Ltd',
      client_id: '9823746152',
      country: 'UK',
      zone: 'Glasgow',
      status: 'activo',
      email: 'emailclientworkshop15@email.com',
      phone: '(+44) 141 287 2000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller P',
      billing_name: 'Taller P C.A',
      client_id: '2198734562',
      country: 'España',
      zone: 'Zaragoza',
      status: 'activo',
      email: 'emailclientetaller16@email.com',
      phone: '(+34) 671 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage Q',
      billing_name: 'Garage Q SARL',
      client_id: '9128346571',
      country: 'Francia',
      zone: 'Bordeaux',
      status: 'activo',
      email: 'emailclientgarage17@email.com',
      phone: '(+33) 5 56 00 66 00',
      type_client: 'Silver',
    },
    {
      client_name: 'Client workshop R',
      billing_name: 'Workshop R Ltd',
      client_id: '3827164592',
      country: 'UK',
      zone: 'Liverpool',
      status: 'activo',
      email: 'emailclientworkshop18@email.com',
      phone: '(+44) 151 233 3000',
      type_client: 'Bronze',
    },
    {
      client_name: 'Cliente taller S',
      billing_name: 'Taller S C.A',
      client_id: '2387465129',
      country: 'España',
      zone: 'Málaga',
      status: 'activo',
      email: 'emailclientetaller19@email.com',
      phone: '(+34) 674 34 34 34',
      type_client: 'Gold',
    },
    {
      client_name: 'Client garage T',
      billing_name: 'Garage T SARL',
      client_id: '1827364593',
      country: 'Francia',
      zone: 'Strasbourg',
      status: 'activo',
      email: 'emailclientgarage20@email.com',
      phone: '(+33) 3 88 60 00 00',
      type_client: 'Silver',
    },
  ];

  chart: any;
  index = NaN;
  open = false;
  constructor(
    private servicesSvc: ServicesService,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}

  protected onClick(): void {
    this.open = !this.open;
  }

  ngOnInit(): void {
    this.filterData = this.servicesData;
    this.servicesSvc.getZones().subscribe((res: any) => {
      this.zones = res.zones;
    });
    this.serviceDataOperation();
    this.clientDataOperation();
    this.driverDataOperation();
    this.providerDataOperation();
    this.todayData();
  }

  protected onActiveZone(active: boolean): void {
    this.open = active && this.open;
  }

  createChart() {
    const ctx = this.chartCanvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labelChart,
        datasets: [
          {
            label: 'Servicios',
            data: this.valueChart,
            backgroundColor: '#59F89C',
            borderColor: '#05f96b',
            borderWidth: 1,
          },
          {
            label: 'Servicios Cancelados',
            data: this.cancelledValueChart,
            backgroundColor: '#ff2d00',
            borderColor: '#ff2d00',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value: any) {
                return Number(value);
              },
              stepSize: 1,
            },
          },
        },
      },
    });
  }

  serviceDataOperation() {
    this.servicesCount = this.servicesData.reduce(
      (acc: any, service: any) => {
        if (service.status === 'pendiente') {
          acc[0]++;
        } else if (service.status === 'activo') {
          acc[1]++;
        }
        return acc;
      },
      [0, 0]
    );
    const total = this.servicesData.length;
    const porcentajePendientes = Math.round(
      (this.servicesCount[0] / total) * 100
    );
    const porcentajeActivos = Math.round((this.servicesCount[1] / total) * 100);
    this.servicesPercentage = [
      `Pendientes: ${porcentajePendientes}%`,
      `Activos: ${porcentajeActivos}%`,
    ];
  }

  clientDataOperation() {
    this.clientsCount = this.clientsData.reduce(
      (acc: any, client: any) => {
        if (client.status === 'pendiente') {
          acc[0]++;
        } else if (client.status === 'activo') {
          acc[1]++;
        }
        return acc;
      },
      [0, 0]
    );

    const total = this.clientsData.length;
    const porcentajePendientes = Math.round(
      (this.clientsCount[0] / total) * 100
    );
    const porcentajeActivos = Math.round((this.clientsCount[1] / total) * 100);
    this.clientsPercentage = [
      `Pendientes: ${porcentajePendientes}%`,
      `Activos: ${porcentajeActivos}%`,
    ];
  }

  driverDataOperation() {
    this.driversCount = this.driversData.reduce(
      (acc: any, driver: any) => {
        if (driver.status === 'pendiente') {
          acc[0]++;
        } else if (driver.status === 'activo') {
          acc[1]++;
        }
        return acc;
      },
      [0, 0]
    );

    const total = this.driversData.length;
    const porcentajePendientes = Math.round(
      (this.driversCount[0] / total) * 100
    );
    const porcentajeActivos = Math.round((this.driversCount[1] / total) * 100);
    this.driversPercentage = [
      `Pendientes: ${porcentajePendientes}%`,
      `Activos: ${porcentajeActivos}%`,
    ];
  }

  providerDataOperation() {
    this.providersCount = this.providersData.reduce(
      (acc: any, provider: any) => {
        if (provider.status === 'pendiente') {
          acc[0]++;
        } else if (provider.status === 'activo') {
          acc[1]++;
        }
        return acc;
      },
      [0, 0]
    );

    const total = this.providersData.length;
    const porcentajePendientes = Math.round(
      (this.providersCount[0] / total) * 100
    );
    const porcentajeActivos = Math.round(
      (this.providersCount[1] / total) * 100
    );
    this.providersPercentage = [
      `Pendientes: ${porcentajePendientes}%`,
      `Activos: ${porcentajeActivos}%`,
    ];
  }

  formatDate2(date: any) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  async filterDataByDate() {
    /*   this.filterData = this.servicesData */
    if (this.selectedZone && this.selectedDate) {
      this.showLoader();
      const date = moment.utc(this.selectedDate).unix();
      const formattedDate = moment(this.selectedDate).format('DD/MM/YYYY');
      const result = await this.getServicesDates(formattedDate);
      this.labelChart = result.dates;
      this.valueChart = result.serviceCounts;
      this.cancelledValueChart = result.cancelledServicesCount;
      if (this.chart) {
        this.chart.destroy();
        this.createChart();
      } else {
        this.createChart();
      }
      this.hideLoader();
      setTimeout(() => {
        const closeDiv = document.querySelector('.t-close');
        if (closeDiv) {
          (closeDiv as HTMLElement).click();
        }
      }, 5000);
    }
  }

  clearDate() {
    this.selectedDate = null;
    if (this.chart) {
      this.chart.destroy();
      this.todayData();
    } else {
      this.todayData();
    }
  }

  async filterDataBySelect() {
    if (this.selectedZone && this.selectedDate) {
      this.showLoader();
      const formattedDate = moment(this.selectedDate).format('DD/MM/YYYY');
      const result = await this.getServicesDates(formattedDate);
      this.labelChart = result.dates;
      this.valueChart = result.serviceCounts;
      this.cancelledValueChart = result.cancelledServicesCount;
      if (this.chart) {
        this.chart.destroy();
        this.createChart();
      } else {
        this.createChart();
      }
      this.hideLoader();
      setTimeout(() => {
        const closeDiv = document.querySelector('.t-close');
        if (closeDiv) {
          (closeDiv as HTMLElement).click();
        }
      }, 5000);
    }
  }

  getServicesByDateRange(selectedDate: string): {
    dates: string[];
    serviceCounts: number[];
  } {
    const datesArray: string[] = [];
    const serviceCountsArray: number[] = [];
    const daysToConsider = 3;
    const selected = new Date(selectedDate.split('/').reverse().join('-'));
    let daysProcessed = 0;
    let offset = -daysToConsider;

    while (daysProcessed < 7) {
      const currentDate = new Date(selected);
      currentDate.setDate(selected.getDate() + offset);
      const dayOfWeek = currentDate.getDay();

      if (dayOfWeek === 6) {
        currentDate.setDate(currentDate.getDate() - 1);
      } else if (dayOfWeek === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      const formattedDate = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

      if (!datesArray.includes(formattedDate)) {
        datesArray.push(formattedDate);
        const serviceCount = this.filterData.filter((service: any) => {
          const serviceDate = service.date;
          return serviceDate === formattedDate;
        }).length;

        serviceCountsArray.push(serviceCount);
        daysProcessed++;
      }
      offset++;
    }

    return { dates: datesArray, serviceCounts: serviceCountsArray };
  }

  async getServicesDates(selectedDate: string) {
    const datesArray: string[] = [];
    const serviceCountsArray: number[] = [];
    const serviceCountsArrayCancelled: number[] = [];
    const daysToConsider = 3;

    const selected = moment.utc(selectedDate, 'DD/MM/YYYY');
    let daysProcessed = 0;
    let offset = -daysToConsider;

    console.log();

    while (daysProcessed < daysToConsider * 2 + 1) {
      const currentDate = moment.utc(selected).add(offset, 'days');
      const dayOfWeek = currentDate.day();

      if (dayOfWeek === 6) {
        currentDate.subtract(1, 'days');
      } else if (dayOfWeek === 0) {
        currentDate.add(1, 'days');
      }

      const formattedDate = currentDate.format('YYYY-MM-DD');

      if (!datesArray.includes(formattedDate)) {
        datesArray.push(formattedDate);
        const date = moment.utc(formattedDate).unix();
        console.log(date, 'date');
        const serviceData = {
          date: { seconds: date },
          zoneId: this.selectedZone,
        };
        const res: any = await firstValueFrom(
          this.servicesSvc.getServices(serviceData)
        );
        let confirmedCount = 0;
        let cancelledCount = 0;
        console.log(res, 'RES');

        res.services.forEach((service: any) => {
          if (
            service.orderStatus != 'CANCELLED_BY_CAFLER' &&
            service.orderStatus != 'CANCELLED_BY_CLIENT' &&
            service.orderStatus != 'INITIALIZED'
          ) {
            confirmedCount++;
          } else if (
            service.orderStatus == 'CANCELLED_BY_CAFLER' ||
            service.orderStatus == 'CANCELLED_BY_CLIENT'
          ) {
            cancelledCount++;
          }
        });
        console.log(confirmedCount, cancelledCount, 'SEIUS');
        // const serviceCount = res.services ? res.services.length : 0;
        serviceCountsArray.push(confirmedCount);
        serviceCountsArrayCancelled.push(cancelledCount);
        daysProcessed++;
      }

      offset++;
    }
    return {
      dates: datesArray,
      serviceCounts: serviceCountsArray,
      cancelledServicesCount: serviceCountsArrayCancelled,
    };
  }

  todayData() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const result = this.getServicesByDateRange(formattedDate);
    this.labelChart = result.dates;
    this.valueChart = result.serviceCounts;
    this.createChart();
  }

  redirect(route: string) {
    localStorage.setItem('open_modal', '1');
    this.router.navigate([`${route}`]);
  }

  openModalAddOperator() {
    const offcanvasRef = this.offcanvasService.open(AddPersonalModalComponent, {
      backdrop: 'static',
      // scroll: true,
      position: 'end',
    });
    offcanvasRef.componentInstance.name = 'World';
  }

  openModalAddService() {
    const offcanvasRef = this.offcanvasService.open(
      ModalDuplicateServiceComponent,
      {
        backdrop: 'static',
        // scroll: true,
        position: 'end',
      }
    );
    offcanvasRef.componentInstance.type_info = 'ad';
  }

  openModalAddClient() {
    const offcanvasRef = this.offcanvasService.open(ModalAddClientComponent, {
      backdrop: 'static',
      // scroll: true,
      position: 'end',
    });
    offcanvasRef.componentInstance.type_info = 'ad';
  }

  openModalAddProvider() {
    const offcanvasRef = this.offcanvasService.open(ModalAddProviderComponent, {
      backdrop: 'static',
      // scroll: true,
      position: 'end',
    });
    offcanvasRef.componentInstance.type_info = 'ad';
  }
}

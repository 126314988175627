import {
  Component,
  Inject,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NewClientModalComponent } from 'src/app/pages/clients/new-client-modal/new-client-modal.component';
import { PricesComponent } from 'src/app/pages/clients/prices/prices.component';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client/client.service';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.css'],
})
export class ClientsTableComponent {
  countries = ['España', 'UK', 'Francia'];

  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];
  filterCount: number = 0;
  clientsData: any = [];
  searchTerm: string = '';

  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  types!: any[];
  selectedDate: string = '#';
  selectedTypeClient: string = '#';
  selectedZone: string = '#';
  selectedProduct: string = '#';
  sortedColumn: string = '';
  sortDirection!: any;

  index: number = 0;
  length: number = 0;
  pageSize: number = 50;
  readonly columns = [
    'businessName',
    'billingBusinessName',
    'userId',
    'country',
    'zone',
    'isEnabled',
    'email',
    'phoneNumber',
    'prices',
    'actions',
  ];

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  open = false;
  selected = null;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private clientSvc: ClientService,
    private offcanvasService: NgbOffcanvas,
    private router: Router,
    private renderer: Renderer2
  ) {}

  async ngOnInit() {
    await this.getClients();
    this.clientSvc.clientCreated$.subscribe(async () => {
      await this.getClients();
    });
  }

  async getClients() {
    this.showLoader();
    this.clientsData = await firstValueFrom(this.clientSvc.getClients());
    this.clientsData = this.clientsData.businessUserData;
    console.log(this.clientsData, 'DATA');
    this.length = Math.ceil(this.clientsData.length / this.pageSize);
    this.paginatedDataOperation();

    this.hideLoader();
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    this.filterCount = this.clientsData.length;
    const start = this.index * this.pageSize;
    this.paginatedData = this.clientsData.slice(start, start + this.pageSize);
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }

  filterDataBySelect(index?: any) {
    this.index = index ?? 0;

    this.paginatedData = this.clientsData.filter((item: any) => {
      const clientName = item.businessName.toLowerCase();
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

      const matchesSearch = !this.searchTerm || clientName.includes(search);

      const matchesTypeClient =
        this.selectedTypeClient === '#' ||
        item.type_client === this.selectedTypeClient;

      const matchesZone =
        this.selectedZone === '#' || item.zone === this.selectedZone;

      return matchesSearch && matchesTypeClient && matchesZone;
    });

    this.sortByExec();
    this.applyPagination();
  }

  openModalEditClient(client: any) {
    const offcanvasRef = this.offcanvasService.open(NewClientModalComponent, {
      backdrop: 'static',
      position: 'end',
    });
    offcanvasRef.componentInstance.action = 'ed';
    offcanvasRef.componentInstance.client = client;
  }

  goPrices(data: any) {
    localStorage.setItem('active_client_prices', JSON.stringify(data));
    this.router.navigate(['/prices'], {
      queryParams: { userId: data.userId },
    });
  }
  goDetails(data: any) {
    this.router.navigate(['/comercial/detail-client'], {
      queryParams: { userId: data.userId, clientName: data.businessName },
    });
  }

  applyPagination() {
    this.filterCount = this.paginatedData.length;
    const start = this.index * this.pageSize;
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

    if (this.length == 1) {
      this.index = 0;
    }
  }

  onSort(event: any): void {
    this.sortDirection = event;
  }

  sortData(): void {
    this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  setSortColumn(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }
  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }
}

<div #gmapContainer id="map"></div>
<div id="left_div" [ngStyle]="{ 'width.px': leftDivStyles.x }" *ngIf="show_left_div != false">
	<div class="icons_div">
		<div class="div_icon_left">
			<tui-icon icon="tuiIconMapLarge" class="green_icon"></tui-icon>
			<p class="p_text">
				{{ 'service_manager.routing_map.title' | translate }}
			</p>
		</div>
		<div class="div_icon_right" *ngIf="expandDivLeft">
			<tui-icon icon="tuiIconChevronLeft" class="black_icon p_ele" (click)="hideLeftDiv()"></tui-icon>
			<span class="text_hide_icon" (click)="hideLeftDiv()">
				{{ 'service_manager.routing_map.hide_text' | translate }}
			</span>
		</div>
		<div class="div_icon_right" *ngIf="!expandDivLeft">
			<tui-icon icon="tuiIconChevronRight" class="black_icon p_ele" (click)="showLeftDiv()"></tui-icon>
		</div>
	</div>
	<div class="inputs_div" *ngIf="expandDivLeft">
		<div>
			<input type="date" [(ngModel)]="selectedDate" (change)="onDateChange()" (click)="resetDate()" class="form-control">
			<!-- <input type="date" formControlName="dateFilter" class="form-control"> -->
			<br>
		</div>
		<div class="div_input_left" [formGroup]="filterForm">
			<select name="" formControlName="zoneFilter" id="" class="form-control select_form" >
                <option value="#">{{ 'service_manager.routing_map.zone_input' | translate }}</option>
                <option *ngFor="let zone of zones" [value]="zone.zoneId">{{ zone.zoneName }}</option>
            </select>
		</div>
		<div class="div_input_right" [formGroup]="filterForm">
			<tui-input formControlName="mapfilter" class="input_form" id="" tuiTextfieldIconLeft="tuiIconSearch" [tuiTextfieldLabelOutside]="true" (input)="filterData($event)">
                {{ 'service_manager.routing_map.search_map_text' | translate }}
			</tui-input>
			<!-- <input type="text" formControlName="mapfilter" id="map_filter" class="form-control" (input)="filterData($event)"> -->
		</div>
	</div>
	<div class="buttons_div">
		<button tuiButton type="button" [class.active_item]="selectedItemFilter == 'services'" class="tui-space_right-3 tui-space_bottom-3 btn_item" size="m" appearance="secondary" (click)="changeSelectedItemFilter('services')">
			<span *ngIf="expandDivLeft">
        		{{ 'service_manager.routing_map.unassigned_services' | translate }} ({{ count_unsigned_services }})
			</span>
			<span *ngIf="!expandDivLeft">
                <tui-svg src="tuiIconGitCommit"></tui-svg> ({{ count_unsigned_services }})
			</span>
        </button>
        <button tuiButton type="button" [class.active_item]="selectedItemFilter == 'drivers'" class="tui-space_right-3 tui-space_bottom-3 btn_item" size="m" appearance="secondary" (click)="changeSelectedItemFilter('drivers')">
        	<span *ngIf="expandDivLeft">
	            {{ 'service_manager.routing_map.drivers_services' | translate }} ({{ count_drivers }})
	        </span>
			<span *ngIf="!expandDivLeft">
                <tui-svg src="tuiIconUser"></tui-svg> ({{ count_drivers }})
			</span>
        </button>
	</div>
	<hr>
	<div class="div_elements_body" *ngIf="expandDivLeft">
		<div id="list_services" class="g_div" *ngIf="selectedItemFilter == 'services'">
			<div class="item_list_service l_item" *ngFor="let service of filtered_services">
				<div class="top_left_info_service inf_ele pld">
					<p>
					    <b>{{ hs.translate(service.mainProductName) }}</b>
	                	<tui-svg src="tuiIconClock"></tui-svg> {{ service.FormatedHour }}
					</p>
				</div>
				<div class="top_right_info_service inf_ele prd">
					<p>
						{{ service.licensePlate }} {{ getTypeChassisByType(service.chassis) }}
					</p>
				</div>
				<div class="bottom_left_info_service inf_ele pld">
					<p>
						<tui-svg src="tuiIconMapPin"></tui-svg> {{ service.pickupAddress }}
					</p>
				</div>
				<div class="bottom_right_info_service inf_ele prd" (click)="setActiveService(service, undefined)">
					<p>
						<b>
							{{ 'service_manager.routing_map.more_info_text' | translate }} <tui-svg src="tuiIconChevronDown"></tui-svg> 
						</b>
					</p>
				</div>
			</div>
		</div>
		<div id="list_drivers" class="g_div" *ngIf="selectedItemFilter == 'drivers'">
			<div *ngFor="let driver of filtered_drivers">
				<div class="item_list_driver l_item" *ngIf="driver.general_info?.fullName && driver.general_info?.isActive">
					<div class="photo_user">
						<div class="avatar_user" [ngStyle]="driver.image?{'background-image':'url('+driver.image+')'} : {'background-color': '#999'}"></div>
					</div>
					<div class="info_user_driver">
						<div class="top_lef_info_driver">
							<p>
								{{ driver.general_info?.fullName }}
							</p>
						</div>
						<div class="top_right_info_driver prd">
							<p>
								<span>
									{{ getRoleInfo(driver.general_info?.operatorType) }}
								</span>
							</p>
						</div>
						<div class="bottom_left_info_driver">
							<p>
								{{ driver.placa }} 
								<tui-badge status="success" value="Activo" class="tui-space_right-2 tui-space_bottom-2 ml-5" *ngIf="driver.general_info?.isActive"></tui-badge>
								<tui-badge status="error" value="Inactivo" class="tui-space_right-2 tui-space_bottom-2 ml-5" *ngIf="!driver.general_info?.isActive"></tui-badge>
							</p>
						</div>
						<div class="bottom_right_info_driver">
							<p>
								Actualizado: <tui-svg src="tuiIconClock"></tui-svg> {{ driver.last_update }}
							</p>
						</div>
					</div>
					<div class="more_info_driver" *ngIf="checkAssignedServices(driver)">
						<tui-svg src="tuiIconChevronDown" class="icon_more_info_driver" (click)="setShowServices(driver)"></tui-svg> 
					</div>
					<div *ngIf="show_assign_services == driver.driverId" class="box_services_all">
						<div *ngIf="driver.assignedServices.length > 0">
							<div *ngFor="let ser of driver.assignedServices">
								<div class="box_services" (click)="setActiveService(ser, driver)" *ngIf="ser.orderStatus != 'FINISHED' &&  ser.orderStatus != 'CANCELLED_BY_CAFLER' && ser.orderStatus != 'CANCELLED_BY_CLIENT' && ser.orderStatus != 'FAILED'">
									<div class="box_services_name">
										<p>
											<b>{{ hs.translate(ser.mainProductName) }}</b> {{ ser.licensePlate }} <br>
											<tui-svg src="tuiIconClock"></tui-svg> {{ ser.FormatedHour }}
										</p>
									</div>
									<div class="box_services_status">
										<div *ngIf="ser?.orderStatus == 'INITIALIZED'">
		                                    <tui-badge status="neutral" value="Inicializado" class="tui-space_right-2 tui-space_bottom-2 custom_initalized"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'PENDING'">
		                                    <tui-badge status="neutral" value="Pendiente" class="tui-space_right-2 tui-space_bottom-2 custom_pending"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'CONFIRMED'">
		                                    <tui-badge status="success" value="Confirmado" class="tui-space_right-2 tui-space_bottom-2 custom_confirmed"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'PICKING_UP'">
		                                    <tui-badge status="success" value="Recogiendo" class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'IN_PROGRESS'">
		                                    <tui-badge status="success" value="En proceso" class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'DELIVERING'">
		                                    <tui-badge status="success" value="Entregando" class="tui-space_right-2 tui-space_bottom-2 custom_picking_up"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'FINISHED'">
		                                    <tui-badge status="success" value="Finalizado" class="tui-space_right-2 tui-space_bottom-2 custom_finished"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'FAILED'">
		                                    <tui-badge status="error" value="Fallado" class="tui-space_right-2 tui-space_bottom-2"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'CANCELLED_BY_CAFLER'">
		                                    <tui-badge status="error" value="Cancelado por Cafler" class="tui-space_right-2 tui-space_bottom-2"></tui-badge>
		                                </div>
		                                <div *ngIf="ser?.orderStatus == 'CANCELLED_BY_CLIENT'">
		                                    <tui-badge status="error" value="Cancelado por cliente" class="tui-space_right-2 tui-space_bottom-2"></tui-badge>
		                                </div>
									</div>
								</div>
							</div>
						</div>
						<div class="box_services" *ngIf="driver.assignedServices.length < 1">
							<p>
								Sin servicios asignados
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="left_div2" *ngIf="!show_left_div">
	<div class="icons_div">
		<div class="div_icon_left2">
			<tui-icon icon="tuiIconChevronLeft" class="black_icon p_ele" (click)="backToMap()"></tui-icon>
			<span class="text_hide_icon" (click)="backToMap()">
				{{ 'service_manager.routing_map.back_map' | translate }}
			</span>
		</div>
	</div>
	<div class="row p_25">
		<div class="col">
			<br>
			<h2 class="fs_24">
				{{ hs.translate(active_detail_service?.mainProductName) }}
			</h2>
			<p class="p_fs_13">
				{{ active_detail_service?.licensePlate }}
			</p>
			<p class="p_fs_13">
				Order hash {{ active_detail_service?.orderHash }}
			</p>
			<p class="p_fs_13">
				{{ active_detail_service?.licensePlate }} {{ getTypeChassisByType(active_detail_service?.chassis) }}
			</p>
		</div>
		<!-- <div class="col">
			<br><br>
            <tui-badge status="neutral" value="Inicializado" class="tui-space_right-2 tui-space_bottom-2 custom_initalized"></tui-badge>
		</div> -->
	</div>
	<div class="row p_25">
		<div class="col">
			<p class="p_fs_13 mb_5">
				<b>{{ 'service_manager.routing_map.detail_service_client' | translate }}:</b> {{ client_info ? client_info?.businessName : active_detail_service?.clientName }}
			</p>
			<!-- <p class="p_fs_13 mb_5">
				<b>Hora de recogida:</b> {{ getDateHour(active_detail_service?.pickupDate.seconds) }}
			</p> -->
			<p class="p_fs_13 mb_5">
				<b>{{ 'service_manager.routing_map.detail_service_pickup_hour' | translate }}:</b> {{ correct_hour ? correct_hour : active_detail_service?.FormatedHour }}
			</p>
			<p class="p_fs_13 mb_5">
				<b>{{ 'service_manager.routing_map.detail_service_pickup_ponit' | translate }}:</b> {{ active_detail_service?.pickupAddress }}
			</p>
			<p class="p_fs_13 mb_5" *ngIf="provider_address != ''">
				<b>Punto proveedor:</b> {{ provider_address }}
			</p>
			<p class="p_fs_13 mb_5">
				<b>{{ 'service_manager.routing_map.detail_service_delivery_ponit' | translate }}:</b> {{ active_detail_service?.destinationAddress }}
			</p>
		</div>
	</div>
	<div class="row p_25">
		<p class="p_fs_13">
			Hay {{ count_drivers_closets }} conductores disponibles para el servicio <b>{{ active_detail_service?.licensePlate }} {{ getTypeChassisByType(active_detail_service?.chassis) }}</b>, hoy a las {{ active_detail_service?.FormatedHour }}, {{ hs.translate(active_detail_service?.mainProductName) }}
		</p>
	</div>
	<div class="row p_25 row_bottom">
		<div class="col col_bottom">
			<div class="row">
				<div class="col div_pointer" (click)="changeInsideServiceactive('closets')" [class.filter_drivers_active]="active_inside_service_div == 'closets'">
					{{ 'service_manager.routing_map.closets_test' | translate }}
				</div>
				<div class="col div_pointer" (click)="changeInsideServiceactive('actives')" [class.filter_drivers_active]="active_inside_service_div == 'actives'">
					{{ 'service_manager.routing_map.actives_text' | translate }}
				</div>
			</div>
		</div>
	</div>
	<div class="row pb_15" [formGroup]="filterDriverServiceForm">
		<div class="col">
			<br>
			<tui-input formControlName="driverservicefilter" class="input_form" id="" tuiTextfieldIconLeft="tuiIconSearch" [tuiTextfieldLabelOutside]="true" (input)="filterDataDriverService($event)">
                {{ 'service_manager.routing_map.search_list_text' | translate }}
			</tui-input>
		</div>
	</div>
	<hr>
	<div class="div_drivers_scrol2l" *ngIf="active_inside_service_div == 'closets'">
		<div *ngIf="active_desasign_driver">
			<div class="row p_25">
				<div class="col">
					<div class="row">
						<div class="col-8">
							<p class="p_fs_15">
								<b>{{ active_desasign_driver.general_info?.fullName }}</b>
							</p>
						</div>
						<div class="col-3">
				            <button tuiButton type="button" (click)="openModalDesAssignDriver(templateModalDesAssignDriver, active_desasign_driver)" class="tui-space_right-3 tui-space_bottom-3 but_assign" size="m" appearance="primary-button">
						        {{ 'service_manager.routing_map.desassingn_text' | translate }}
						    </button>
						</div>
					</div>
				</div>
			</div>
			<hr>
		</div>
		<div *ngFor="let driver of drivers_for_service">
			<div class="row p_25">
				<div class="col">
					<div class="row">
						<div class="col-9">
							<p class="p_fs_15">
								<b>{{ driver.driverName }}</b> a {{ driver.distanceToService }}Km ({{ calculateJourneyTime(driver.distanceToService) }} aprox.)
							</p>
						</div>
						<div class="col-3">
				            <button tuiButton type="button" (click)="openModalAssignDriver(templateModalLAssignDriver, driver)" class="tui-space_right-3 tui-space_bottom-3 but_assign" size="m" appearance="primary-button">
						        {{ 'service_manager.routing_map.assign_text' | translate }}
						    </button>
						</div>
					</div>
				</div>
			</div>
			<hr>
		</div>
	</div>
	<div class="div_drivers_scrol2l" *ngIf="active_inside_service_div == 'actives'">
		<div *ngIf="active_desasign_driver">
			<div class="row p_25">
				<div class="col">
					<div class="row">
						<div class="col-8">
							<p class="p_fs_15">
								<b>{{ active_desasign_driver.general_info?.fullName }}</b>
							</p>
						</div>
						<div class="col-3">
				            <button tuiButton type="button" (click)="openModalDesAssignDriver(templateModalDesAssignDriver, active_desasign_driver)" class="tui-space_right-3 tui-space_bottom-3 but_assign" size="m" appearance="primary-button">
						        {{ 'service_manager.routing_map.desassingn_text' | translate }}
						    </button>
						</div>
					</div>
				</div>
			</div>
			<hr>
		</div>
		<div *ngFor="let driver of filtered_drivers">
			<div class="row p_25" *ngIf="driver.general_info?.fullName && driver.general_info?.isActive && driver.driverId != active_desasign_driver.driverId">
				<div class="col">
					<div class="row">
						<div class="col-9">
							<p class="p_fs_15">
								<b>{{ driver.general_info?.fullName }}</b>
							</p>
						</div>
						<div class="col-3">
				            <button tuiButton type="button" (click)="openModalAssignDriver(templateModalLAssignDriver, driver)" class="tui-space_right-3 tui-space_bottom-3 but_assign" size="m" appearance="primary-button">
						        {{ 'service_manager.routing_map.assign_text' | translate }}
						    </button>
						</div>
					</div>
				</div>
			</div>
			<hr *ngIf="driver.general_info?.fullName && driver.general_info?.isActive">
		</div>
	</div>
</div>
<div class="control_map_div">
	<div class="layer_div">
		<tui-hosted-dropdown
	        tuiDropdownLimitWidth="auto"
	        [content]="download_options"
	        [(open)]="open"
	    >
	    	<tui-icon icon="tuiIconLayers" class="icon_control_map p_ele"></tui-icon>
	    </tui-hosted-dropdown>
	    <ng-template
		        #download_options
		        let-activeZone
		    >
		    <div class="layer_map_menu">
		        <div class="d-flex export-body-label">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Conductores</label>
		        </div>
		        <br>
		        <div class="d-flex export-body-label">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Rutas</label>
		        </div>
		        <div class="d-flex export-body-label ml-15">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Puntos de recogida</label> 
		        </div>
		        <div class="d-flex export-body-label ml-15">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Puntos de entrega</label>
		        </div>
		        <div class="d-flex export-body-label ml-15">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Paradas en la ruta</label>
		        </div>
		        <div class="d-flex export-body-label ml-15">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Líneas de la ruta</label>
		        </div>
		        <br>
		        <div class="d-flex export-body-label">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Servicios</label>
		        </div>
		        <div class="d-flex export-body-label ml-15">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Asignados</label>
		        </div>
		        <div class="d-flex export-body-label ml-15">
		            <tui-checkbox class="tui-space_bottom-3 mr-5"></tui-checkbox>
		        	<label>Sin asignar</label>
		        </div>
		    </div>
		</ng-template>
	</div>
	<div class="geolocate_div">
		<tui-icon icon="tuiIconCrosshair" class="icon_control_map p_ele"></tui-icon>
	</div>
	<div class="zomm_controls">
		<tui-icon icon="tuiIconPlusLarge" class="icon_control_map p_ele" (click)="zoomInMap()"></tui-icon>
		<hr>
		<tui-icon icon="tuiIconMinusLarge" class="icon_control_map p_ele" (click)="zoomOutMap()"></tui-icon>
	</div>
</div>
<ng-template #templateModalLAssignDriver>
    <div class="modal-body">
        <div class="content_cancel_service">
            <br>
            <p class="fs_24">
                {{ 'service_manager.routing_map.assign_driver_modal_title' | translate }}
            </p>
            <p class="fs_13">
                Pulsando el boton, se asignara este servicio al conductor <b>{{ active_driver.general_info ? active_driver.general_info.fullName : active_driver?.driverName }}</b>
            </p>
            <div class="row">
				<div class="col">
					<h2 class="fs_24">
						{{ hs.translate(active_detail_service?.mainProductName) }}
					</h2>
					<p class="p_fs_13">
						{{ active_detail_service?.licensePlate }}
					</p>
					<p class="p_fs_13">
						Order hash {{ active_detail_service?.orderHash }}
					</p>
					<p class="p_fs_13">
						{{ active_detail_service?.licensePlate }} {{ getTypeChassisByType(active_detail_service?.chassis) }}
					</p>
				</div>
				<!-- <div class="col">
					<br><br>
		            <tui-badge status="neutral" value="Inicializado" class="tui-space_right-2 tui-space_bottom-2 custom_initalized"></tui-badge>
				</div> -->
			</div>
			<div class="row">
				<div class="col">
					<br>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_client' | translate }}:</b> {{ active_detail_service?.clientName }}
					</p>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_pickup_hour' | translate }}:</b> {{ active_detail_service?.FormatedHour }}
					</p>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_pickup_ponit' | translate }}:</b> {{ active_detail_service?.pickupAddress }}
					</p>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_delivery_ponit' | translate }}:</b> {{ active_detail_service?.destinationAddress }}
					</p>
				</div>
			</div>
			<hr>
        </div>
        <div class="right_buttons">
            <button class="close_modal_logout" (click)="modalRef?.hide()">
                {{ 'service_manager.routing_map.back_modal' | translate }}
            </button>
            <button class="add_assign_button" (click)="confirmAssign()">
                {{ 'service_manager.routing_map.confirm_assign' | translate }}
            </button>
            <br>
            <br>
            <div class="loader_inside_modal loader_inside_modal_confirm_assignation">
            	<img src="/assets/logo-loading.gif" class="img_loader_inside_modal" alt="">
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateModalDesAssignDriver>
    <div class="modal-body">
        <div class="content_cancel_service">
            <br>
            <p class="fs_24">
                {{ 'service_manager.routing_map.desassign_driver_modal_title' | translate }}
            </p>
            <p class="fs_13">
                Pulsando el boton, se desasignara este servicio al conductor <b>{{ active_desasign_driver.general_info?.fullName }}</b>
            </p>
            <div class="row">
				<div class="col">
					<h2 class="fs_24">
						{{ hs.translate(active_detail_service?.mainProductName) }}
					</h2>
					<p class="p_fs_13">
						{{ active_detail_service?.licensePlate }}
					</p>
					<p class="p_fs_13">
						Order hash {{ active_detail_service?.orderHash }}
					</p>
					<p class="p_fs_13">
						{{ active_detail_service?.licensePlate }} {{ getTypeChassisByType(active_detail_service?.chassis) }}
					</p>
				</div>
				<!-- <div class="col">
					<br><br>
		            <tui-badge status="neutral" value="Inicializado" class="tui-space_right-2 tui-space_bottom-2 custom_initalized"></tui-badge>
				</div> -->
			</div>
			<div class="row">
				<div class="col">
					<br>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_client' | translate }}:</b> {{ active_detail_service?.clientName }}
					</p>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_pickup_hour' | translate }}:</b> {{ active_detail_service?.FormatedHour }}
					</p>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_pickup_ponit' | translate }}:</b> {{ active_detail_service?.pickupAddress }}
					</p>
					<p class="p_fs_13 mb_5">
						<b>{{ 'service_manager.routing_map.detail_service_delivery_ponit' | translate }}:</b> {{ active_detail_service?.destinationAddress }}
					</p>
				</div>
			</div>
			<hr>
        </div>
        <div class="right_buttons">
            <button class="close_modal_logout" (click)="modalRef?.hide()">
                {{ 'service_manager.routing_map.back_modal' | translate }}
            </button>
            <button class="add_assign_button" (click)="confirmDesAssign()">
                {{ 'service_manager.routing_map.confirm_desassign' | translate }}
            </button>
            <br>
            <br>
            <div class="loader_inside_modal loader_inside_modal_confirm_desassignation">
            	<img src="/assets/logo-loading.gif" class="img_loader_inside_modal" alt="">
            </div>
        </div>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import { LocalStorageKeys } from 'src/app/enum/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  /**
   * Retorna el token de acceso almacenado en el localStorage.
   * Se limpia el token para eliminar comillas adicionales si existen.
   */
  getAccessToken(): string | null {
    const token = localStorage.getItem(LocalStorageKeys.ACCESSTOKEN);
    return token ? this.cleanToken(token) : null;
  }

  /**
   * Guarda el token de acceso en el localStorage.
   * Se asegura de guardar el token sin comillas adicionales.
   */
  setAccessToken(token: string): void {
    localStorage.setItem(LocalStorageKeys.ACCESSTOKEN, this.cleanToken(token));
  }

  /**
   * Retorna el idToken almacenado en el localStorage.
   * Se limpia el token para eliminar comillas adicionales si existen.
   */
  getIdToken(): string | null {
    const token = localStorage.getItem(LocalStorageKeys.IDTOKEN);
    return token ? this.cleanToken(token) : null;
  }

  /**
   * Guarda el idToken en el localStorage.
   */
  setIdToken(token: string): void {
    localStorage.setItem(LocalStorageKeys.IDTOKEN, this.cleanToken(token));
  }

  /**
   * Retorna el timestamp del token como número.
   */
  getTokenTimestamp(): number | null {
    const timestamp = localStorage.getItem(LocalStorageKeys.TOKENTIMESTAMP);
    return timestamp ? Number(timestamp) : null;
  }

  /**
   * Guarda el timestamp en el localStorage.
   */
  setTokenTimestamp(timestamp: number): void {
    localStorage.setItem(LocalStorageKeys.TOKENTIMESTAMP, timestamp.toString());
  }

  /**
   * Método privado para limpiar el token.
   * Elimina las comillas al inicio y al final si existen.
   */
  private cleanToken(token: string): string {
    return token.replace(/^"(.*)"$/, '$1');
  }
}

import { Component, Input, NgZone } from '@angular/core';
import { PoolBid } from 'src/app/models/pool/pool-data.model';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';

declare var moment: any;

@Component({
  selector: 'app-pool-manager-bids-table',
  standalone: false,
  templateUrl: './pool-manager-bids-table.component.html',
  styleUrls: ['./pool-manager-bids-table.component.css'],
})
export class PoolManagerBidsTableComponent {
  @Input() pooldata: PoolBid[] = [];

  readonly columns = [
    'check',
    'price',
    'counterOffer',
    'margen',
    'driver',
    'date',
    'hour',
    'status',
    'actions',
  ];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];

  select_all_bids: boolean = false;
  select_bid: boolean = false;

  constructor(
    public hs: HistoryServicesService,
  ) {}
}

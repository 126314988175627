import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MaskitoOptions } from '@maskito/core';
import { TranslateService } from '@ngx-translate/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';

@Component({
  selector: 'app-edit-pool-price-modal',
  templateUrl: './edit-pool-price-modal.component.html',
  styleUrls: ['./edit-pool-price-modal.component.css'],
})
export class EditPoolPriceModalComponent {
  @Input() bsModalRef!: any;
  @Input() zones: any;
  @Input() subproductData: any;
  @Output() sendSubproductData = new EventEmitter<any>();
  readonly maskOptions1: MaskitoOptions = {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  };

  subproductForm!: FormGroup;
  verticalsTypes = [
    { value: 1, name: 'Transfer', realName: 'transfer' },
    {
      value: 2,
      name: 'MechanicalInspection',
      realName: 'mechanical_inspection',
    },
    { value: 3, name: 'VehicleWash', realName: 'vehicle_wash' },
    { value: 4, name: 'Refueling', realName: 'refueling' },
    {
      value: 5,
      name: 'PreTechnicalInspection',
      realName: 'pre_technical_inspection',
    },
    {
      value: 6,
      name: 'LongDistanceTransfer',
      realName: 'long_distance_transfer',
    },
    { value: 7, name: 'TechnicalInspection', realName: 'technical_inspection' },
    { value: 8, name: 'VehicleInsurance', realName: 'vehicle_insurance' },
    { value: 10, name: 'Accessory', realName: 'accessory' },
    { value: 11, name: 'Valet', realName: 'valet' },
    { value: 12, name: 'Tyres', realName: 'tyres' },
    { value: 13, name: 'Formalities', realName: 'formalities' },
    { value: 14, name: 'Parking', realName: 'parking' },
    { value: 15, name: 'ReplacementVehicle', realName: 'replacement_vehicle' },
    { value: 16, name: 'TowTruck', realName: 'tow_truck' },
    { value: 17, name: 'WhateverYouWant', realName: 'whatever_you_want' },
    { value: 18, name: 'VehicleStorage', realName: 'vehicle_storage' },
  ];

  disponibility = new FormGroup({
    isVailable: new FormControl(true),
  });
  constructor(
    private fb: FormBuilder,
    public hs: HistoryServicesService,
    public translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.subproductForm = this.fb.group({
      clientProviderTotal: [
        this.formatPrice(
          this.subproductData?.clientProviderPrice.units,
          this.subproductData?.clientProviderPrice.nanos
        ),
        Validators.required,
      ],
      clientMobilityTotal: [
        this.formatPrice(
          this.subproductData?.clientMobilityPrice.units,
          this.subproductData?.clientMobilityPrice.nanos
        ),
        Validators.required,
      ],
      available: [this.subproductData.available],
    });
  }
  getVerticalRealName(name: any) {
    let vf = this.verticalsTypes.find((vr) => vr.name == name);
    return vf ? vf.realName : '';
  }
  formatPrice(units: number, nanos: number): string {
    const nanosStr = nanos.toString().padStart(9, '0');
    const decimalPart = nanosStr.substring(0, 2);
    return `${units}.${decimalPart}`;
  }

  onSubmitUpdateSubproduct() {
    if (this.subproductForm.invalid) {
      return;
    }
    const body = {
      zoneId: '',
      clientId: '',
      planType: '',
      productToUpdate: {
        verticalName: this.subproductData.verticalName,
        productData: {
          productKey: this.subproductData.productKey,
          generalMobilityPrice: this.subproductData.generalMobilityPrice,
          generalProviderPrice: this.subproductData.generalProviderPrice,
          clientMobilityPrice: {
            units: '',
            nanos: 0,
          },
          clientProviderPrice: {
            units: '',
            nanos: 0,
          },
          available: this.subproductForm.get('available')?.value,
        },
      },
    };
    let totalProvider = this.subproductForm.get('clientProviderTotal')?.value;
    let totalMovility = this.subproductForm.get('clientMobilityTotal')?.value;

    if (totalProvider && /^\d+\.$/.test(totalProvider)) {
      totalProvider = parseFloat(totalProvider).toFixed(2);
      this.subproductForm
        .get('clientProviderTotal')
        ?.setValue(totalProvider, { emitEvent: false });
    }

    if (totalMovility && /^\d+\.$/.test(totalMovility)) {
      totalMovility = parseFloat(totalMovility).toFixed(2);
      this.subproductForm
        .get('clientMobilityTotal')
        ?.setValue(totalMovility, { emitEvent: false });
    }

    if (totalProvider) {
      const [units, nanos] = totalProvider.split('.');
      const nanosValue = (parseInt(nanos, 10) * Math.pow(10, 7))
        .toString()
        .padEnd(9, '0');
      body.productToUpdate.productData.clientProviderPrice.units = units;
      body.productToUpdate.productData.clientProviderPrice.nanos = Number.isNaN(
        +nanosValue
      )
        ? 0
        : +nanosValue;
    }

    if (totalMovility) {
      const [units, nanos] = totalMovility.split('.');
      const nanosValue = (parseInt(nanos, 10) * Math.pow(10, 7))
        .toString()
        .padEnd(9, '0');
      body.productToUpdate.productData.clientMobilityPrice.units = units;
      body.productToUpdate.productData.clientMobilityPrice.nanos = Number.isNaN(
        +nanosValue
      )
        ? 0
        : +nanosValue;
    }

    this.sendSubproductData.emit(body);
  }
}

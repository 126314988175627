import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ClientService {
  url = environment.url;
  private clientCreatedSource = new Subject<void>();
  clientCreated$ = this.clientCreatedSource.asObservable();
  constructor(private http: HttpClient) {}

  notifyClientCreated() {
    this.clientCreatedSource.next();
  }
  getZones() {
    return this.http.get(`${this.url}/zones`);
  }

  getClients() {
    return this.http.post(`${this.url}/clients/get`, {});
  }
}

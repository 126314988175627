import {
  Component,
  HostListener,
  AfterViewChecked,
  TemplateRef,
  Renderer2,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ServicesService } from 'src/app/services/service/services.service';
import { firstValueFrom } from 'rxjs';

import { Zone, ZonesResponse } from 'src/app/models/zone/zone.model';
import {
  DriverGant,
  GanttDataItem,
  GanttRequest,
  GanttTask,
  GetDriversGantResponse,
  GetGanttResponse,
  TimeData,
} from './interfaces/Gant.interface';
import { Verticals } from 'src/app/models/service/service.model';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { LocalStorageKeys } from 'src/app/enum/local-storage-keys.enum';
import { formatTime } from 'src/app/utils';

declare var moment: any;
moment.locale('es');

@Component({
  selector: 'app-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css'],
})
export class GanttComponent implements AfterViewChecked, OnInit, OnDestroy {
  lastScrollTop: number = 0;
  lastScrollLeft: number = 0;
  ganttStartTime: number = 6;
  ganttEndTime: number = 21;
  rangeWidth: number = 100;
  scrollPosition: number = 0;
  scrollPositionY: number = 0;

  date: number = Math.floor(new Date('2024-08-14').getTime() / 1000);
  actualDate = moment().format('D [de] MMMM [de] YYYY');

  scrollClass: string = '';
  searchTerm: string = '';
  selectedZone: string = '';
  startTimeLabel: string = '6:00';

  selectedDate: Date | string = new Date();

  bsModalRef?: BsModalRef;
  currentTimeStyle: boolean = true;
  serviceData!: GanttTask;

  hours: string[] = this.getHoursOfDay(this.ganttStartTime, this.ganttEndTime);
  dates: string[] = [];
  verticals: Verticals[] = [];
  ganttData: GanttDataItem[] = [];
  ganttData2: GanttDataItem[] = [];
  ganttDataOriginal: GanttDataItem[] = [];
  zones: Zone[] = [];

  div_loder: HTMLElement | null = document.getElementById('loader');

  @ViewChildren('div1') driverArray!: QueryList<ElementRef>;
  @ViewChildren('div2') servicesArray!: QueryList<ElementRef>;
  @ViewChild('father') father!: ElementRef;

  constructor(
    private modalService: BsModalService,
    private servicesSvc: ServicesService,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService
  ) {}

  loadGant() {
    this.getZones();
    this.getVerticals();
    this.getLocalStorageInfo();
  }

  getZones(): void {
    this.servicesSvc.getZones().subscribe((res: ZonesResponse) => {
      this.zones = res.zones;
    });

    this.selectedDate = new Date();
  }

  getVerticals(): void {
    this.verticals = this.servicesSvc.verticals;
  }

  getLocalStorageInfo(): void {
    const savedDateRaw = this.localStorageService.get(
      LocalStorageKeys.SELECTEDDATE
    );
    const savedZone = this.localStorageService.get(
      LocalStorageKeys.SELECTEDZONE
    );

    // Si savedDateRaw es una cadena (por ejemplo, en formato ISO) o un JSON serializado:
    if (savedDateRaw && savedZone) {
      const savedDate = new Date(savedDateRaw as string);

      // Asigna los valores (puedes ajustar según tu lógica)
      this.selectedDate = savedDate;
      this.selectedZone = savedZone as string;
      this.filterDataByDate();
    }
  }

  ngOnInit() {
    this.loadGant();
    // OLD CODE COMMENTED IF WE NEED IN A FUTURE
    // const oldParams = this.servicesSvc.ganttDataParams;
    // if (Object.keys(oldParams).length > 0) {
    //   this.selectedZone = oldParams.zoneId;
    //   this.searchTerm = oldParams.searchTerm;
    //   this.selectedDate = oldParams.date;

    //   this.filterDataByDate();
    // }
  }

  normalizeTasks(tasks: GanttTask | GanttTask[]): GanttTask[] {
    return tasks instanceof Array ? tasks : [tasks];
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    this.scrollPosition = target.scrollLeft;
    this.scrollPositionY = target.scrollTop;
  }

  ngAfterViewInit() {
    this.father.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );

    this.adjustHeights();
  }

  adjustHeights(): void {
    const div1Elements = this.driverArray.toArray();
    const div2Elements = this.servicesArray.toArray();

    div2Elements.forEach((servicesArray, index) => {
      const div1 = div1Elements[index];
      const div2Height = servicesArray.nativeElement.offsetHeight;
      this.renderer.setStyle(div1.nativeElement, 'height', `${div2Height}px`);
    });
  }

  // [ER-01/04/2025] - oLD CODE COMMENTED IF WE NEED IN A FUTURE
  // ngAfterViewChecked(): void {
  //   if (this.ganttData.length) {
  //     console.log('gantData', this.ganttData);
  //     if (this.currentTimeStyle) {
  //       const div = document.querySelector(
  //         '.gantt-main-container'
  //       ) as HTMLDivElement;
  //       if (div) {
  //         const containerWidth = div.offsetWidth ?? 500;
  //         div.scrollLeft =
  //           this.getCurrentTimeScrollingWidth() - containerWidth / 2;
  //       }
  //       this.currentTimeStyle = false;
  //     }
  //     this.adjustHeights();
  //   } else {
  //     this.currentTimeStyle = true;
  //   }
  //
  //   /*   const x = this.getCurrentTimeStyle()
  //   this.currentTimeStyle = {...x} ; */
  // }

  ngAfterViewChecked(): void {
    if (this.ganttData.length) {
      if (this.currentTimeStyle) {
        const container = document.querySelector(
          '.gantt-main-container'
        ) as HTMLDivElement;
        if (container) {
          const containerWidth = container.offsetWidth || 500;
          container.scrollLeft =
            this.getCurrentTimeScrollingWidth() - containerWidth / 2;
        }
        this.currentTimeStyle = false;
      }
      this.adjustHeights();
    } else {
      this.currentTimeStyle = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {}

  ngOnDestroy(): void {
    this.servicesSvc.ganttDataParams = {
      zoneId: this.selectedZone,
      searchTerm: this.searchTerm,
      date: this.selectedDate,
    };
  }

  /**Data functions */
  // ER [01/04/2025] - old code -  Why there's a function in html that doesnt do nothing?
  getServiceType(id?: string) {
    /*       if (!id) return 'Desconocido';
      return this.getValuesService.getServiceTypeString(id); */
  }

  getHoursOfDay(startHour: number, endHour: number): string[] {
    const times: string[] = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = i < 10 ? `0${i}` : i.toString();
      times.push(`${hour}:00`);
      times.push(`${hour}:30`);
    }
    return times;
  }

  getCurrentTimeScrollingWidth(): number {
    const container = document.querySelector(
      '.gantt-main-container'
    ) as HTMLDivElement;
    let currentTime = this.getCurrentTimeInDecimal();
    if (currentTime > this.ganttEndTime) {
      currentTime = this.ganttEndTime;
    }
    const startTimeDiff = currentTime - this.ganttStartTime;
    return startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2;
  }

  getCurrentTimeInDecimal(): number {
    const now = new Date();
    return now.getHours() + now.getMinutes() / 60;
  }

  getStatusStyle(task: GanttTask, type?: string): string {
    if (!task) return 'gantt-task-confirmed';

    if (type && type !== 'OPERATIVE_NODE') {
      switch (type) {
        case 'TRANSPORT_NODE':
          return 'gantt-task-public';
        case 'SWEEPER_NODE':
          return 'gantt-task-sweeper';
        default:
          return 'gantt-task-pending';
      }
    }

    // Nos aseguramos de que orderStatus esté en mayúsculas por si acaso
    const status = String(task.orderStatus).toUpperCase();

    switch (status) {
      case 'PENDING':
        return 'gantt-task-pending';
      case 'CONFIRMED':
        return 'gantt-task-confirmed';
      case 'DELIVERING':
      case 'IN_PROGRESS':
      case 'PICKING_UP':
      case '4':
      case '5':
        return 'gantt-task-inProgress';
      case 'FINISHED':
        return 'gantt-task-finished';
      case 'FAILED':
      case 'CANCELLED_BY_CAFLER':
      case 'CANCELLED_BY_CLIENT':
        return 'gantt-task-cancelled';
      default:
        return 'gantt-task-confirmed';
    }
  }

  // getStatusStyle(task: GanttTask, type?: string): string {
  //   if (type !== 'OPERATIVE_NODE') {
  //     switch (type) {
  //       case 'TRANSPORT_NODE':
  //         return 'gantt-task-public';
  //       case 'SWEEPER_NODE':
  //         return 'gantt-task-sweeper';
  //       default:
  //         return 'gantt-task-pending';
  //     }
  //   } else {
  //     console.log('aqui??')
  //     switch (task.orderStatus) {
  //       case 'PENDING':
  //         return 'gantt-task-pending';
  //       case 'CONFIRMED':
  //         return 'gantt-task-confirmed';
  //       case 'DELIVERING':
  //       case 'IN_PROGRESS':
  //       case 'PICKING_UP':
  //       case "4":
  //       case "5":
  //         return 'gantt-task-inProgress';
  //       case 'FINISHED':
  //         return 'gantt-task-finished';
  //       case 'FAILED':
  //       case 'CANCELLED_BY_CAFLER':
  //       case 'CANCELLED_BY_CLIENT':
  //         return 'gantt-task-cancelled';
  //       default:
  //         return 'gantt-task-confirmed';
  //     }
  //   }
  // }

  getStatusBlock(block: boolean): string {
    return block ? 'gantt-task-block' : '';
  }

  // getIcon(task: any, type?: any) {
  //   if (type != 'OPERATIVE_NODE') {
  //     switch (type) {
  //       case 'SWEEPER_NODE':
  //         return '../../../assets/icons/gantt-icons/Type=initial_trans.svg';

  //       case 'TRANSPORT_NODE':
  //         return '../../../assets/icons/gantt-icons/Type=public_trans.svg';
  //       default:
  //         return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
  //     }
  //   } else {
  //     switch (task) {
  //       case 'TRANSFER':
  //         return '../../../assets/icons/gantt-icons/Type=Transfer.svg';
  //       case 'MECHANICAL_INSPECTION':
  //         return '../../../assets/icons/gantt-icons/Type=Falta.svg';
  //       case 'VEHICLE_WASH':
  //         return '../../../assets/icons/gantt-icons/Type=Lavados.svg';
  //       case 'REFUELING':
  //         return '../../../assets/icons/gantt-icons/Type=Repostaje.svg';
  //       case 'PRE_TECHNICAL_INSPECTION':
  //         return '../../../assets/icons/gantt-icons/Type=Pre_ITV.svg';
  //       case 'LONG_DISTANCE_TRANSFER':
  //         return '../../../assets/icons/gantt-icons/Type=Media_y_larga_distancia.svg';
  //       case 'TECHNICAL_INSPECTION':
  //         return '../../../assets/icons/gantt-icons/Type=ITV.svg';
  //       case 'VEHICLE_INSURANCE':
  //         return '../../../assets/icons/gantt-icons/Type=Seguros.svg';
  //       case 'ACCESSORY':
  //         return '../../../assets/icons/gantt-icons/Type=Accesorio.svg';
  //       case 'VALET':
  //         return '../../../assets/icons/gantt-icons/Type=Valet.svg';
  //       case 'TYRES':
  //         return '../../../assets/icons/gantt-icons/Type=Neumaticos.svg';
  //       case 'FORMALITIES':
  //         return '../../../assets/icons/gantt-icons/Type=Tramites.svg';
  //       case 'PARKING':
  //         return '../../../assets/icons/gantt-icons/Type=Parking.svg';
  //       case 'REPLACEMENT_VEHICLE':
  //         return '../../../assets/icons/gantt-icons/Type=Vehiculo_sustitucion.svg';
  //       case 'TOW_TRUCK':
  //         return '../../../assets/icons/gantt-icons/Type=Grua.svg';
  //       case 'WHATEVER_YOU_WANT':
  //         return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
  //       default:
  //         return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
  //     }
  //   }
  // }

  getIcon(taskVerticalType: string, type?: string): string {
    if (type !== 'OPERATIVE_NODE') {
      switch (type) {
        case 'SWEEPER_NODE':
          return '../../../assets/icons/gantt-icons/Type=initial_trans.svg';
        case 'TRANSPORT_NODE':
          return '../../../assets/icons/gantt-icons/Type=public_trans.svg';
        default:
          return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
      }
    } else {
      switch (taskVerticalType) {
        case 'TRANSFER':
          return '../../../assets/icons/gantt-icons/Type=Transfer.svg';
        case 'MECHANICAL_INSPECTION':
          return '../../../assets/icons/gantt-icons/Type=Falta.svg';
        case 'VEHICLE_WASH':
          return '../../../assets/icons/gantt-icons/Type=Lavados.svg';
        case 'REFUELING':
          return '../../../assets/icons/gantt-icons/Type=Repostaje.svg';
        case 'PRE_TECHNICAL_INSPECTION':
          return '../../../assets/icons/gantt-icons/Type=Pre_ITV.svg';
        case 'LONG_DISTANCE_TRANSFER':
          return '../../../assets/icons/gantt-icons/Type=Media_y_larga_distancia.svg';
        case 'TECHNICAL_INSPECTION':
          return '../../../assets/icons/gantt-icons/Type=ITV.svg';
        case 'VEHICLE_INSURANCE':
          return '../../../assets/icons/gantt-icons/Type=Seguros.svg';
        case 'ACCESSORY':
          return '../../../assets/icons/gantt-icons/Type=Accesorio.svg';
        case 'VALET':
          return '../../../assets/icons/gantt-icons/Type=Valet.svg';
        case 'TYRES':
          return '../../../assets/icons/gantt-icons/Type=Neumaticos.svg';
        case 'FORMALITIES':
          return '../../../assets/icons/gantt-icons/Type=Tramites.svg';
        case 'PARKING':
          return '../../../assets/icons/gantt-icons/Type=Parking.svg';
        case 'REPLACEMENT_VEHICLE':
          return '../../../assets/icons/gantt-icons/Type=Vehiculo_sustitucion.svg';
        case 'TOW_TRUCK':
          return '../../../assets/icons/gantt-icons/Type=Grua.svg';
        case 'WHATEVER_YOU_WANT':
          return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
        default:
          return '../../../assets/icons/gantt-icons/Type=Pide_lo_que_necesites.svg';
      }
    }
  }

  // [ER - 01/04/2025 ] - OLD CODE commented if we need it in a future
  // getPositionStyle(task: any, tasks?: any): { [key: string]: string } {
  //   const mainContainer = document.querySelector(
  //     '.gantt-main-container'
  //   ) as HTMLDivElement;
  //
  //   let startTimeDiff =
  //     this.convertToHourFormat(task.serviceStartDate) - this.ganttStartTime;
  //   let endTimeDiff =
  //     this.convertToHourFormat(task.serviceEndDate) -
  //     this.convertToHourFormat(task.serviceStartDate);
  //
  //   let left =
  //     startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2 + 'px';
  //   let width = endTimeDiff * (this.rangeWidth * 2) + 'px';
  //   let minWidth = width;
  //
  //   console.log(
  //     `getPositionStyle - orderHash: ${task.orderHash} | serviceStartDate.seconds: ${task.serviceStartDate.seconds} | serviceEndDate.seconds: ${task.serviceEndDate.seconds} | startTimeDiff: ${startTimeDiff} | duration: ${endTimeDiff} | Calculated style -> left: ${left}, width: ${width}, minWidth: ${minWidth}`
  //   );
  //
  //   if (tasks && tasks.length > 1) {
  //     let position = 'relative';
  //     return {
  //       left,
  //       width,
  //       minWidth,
  //       position,
  //     };
  //   } else {
  //     return {
  //       left,
  //       width,
  //       minWidth,
  //     };
  //   }
  // }

  getPositionStyle(
    task: GanttTask,
    tasks?: GanttTask[]
  ): { [key: string]: string } {
    const startTimeDiff =
      this.convertToHourFormat(task.serviceStartDate) - this.ganttStartTime;
    const duration =
      this.convertToHourFormat(task.serviceEndDate) -
      this.convertToHourFormat(task.serviceStartDate);

    const left =
      startTimeDiff * (this.rangeWidth * 2) + this.rangeWidth / 2 + 'px';
    const width = duration * (this.rangeWidth * 2) + 'px';
    const minWidth = width;

    // Log de una sola línea con toda la información
    // console.log(
    //   `getPositionStyle - orderHash: ${task.orderHash} | serviceStartDate.seconds: ${task.serviceStartDate.seconds} | serviceEndDate.seconds: ${task.serviceEndDate.seconds} | startTimeDiff: ${startTimeDiff} | duration: ${duration} | style -> left: ${left}, width: ${width}, minWidth: ${minWidth}`
    // );

    return tasks && tasks.length > 1
      ? { left, width, minWidth, position: 'relative' }
      : { left, width, minWidth };
  }

  getCurrentTimeStyle(): { [key: string]: string } {
    const left = this.getCurrentTimeScrollingWidth() + 'px';
    return {
      left,
    };
  }

  // [ER - 01/04/2025 - OLD CODE]
  // openModal(serviceDataModal: TemplateRef<void>, serviceData: any) {
  //   console.log(serviceData);
  //   this.serviceData = serviceData;
  //   this.bsModalRef = this.modalService.show(serviceDataModal);
  // }

  openModal(
    serviceDataModal: TemplateRef<unknown>,
    serviceData: GanttTask
  ): void {
    this.serviceData = serviceData;
    this.bsModalRef = this.modalService.show(serviceDataModal);
  }

  filterData(): void {
    if (this.searchTerm) {
      this.ganttData = this.ganttDataOriginal.filter(
        (element: GanttDataItem) => {
          const driver = element.driver?.[0];
          return (
            driver &&
            driver.fullName
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          );
        }
      );
    } else {
      this.ganttData = this.ganttDataOriginal;
    }
  }

  // [ER - 01/04/2025 ] - OLD CODE commented if we need in a future
  // filterData() {
  //   if (this.searchTerm) {
  //     const ganttCopy = [...this.ganttDataOriginal];
  //
  //     const driverName = this.searchTerm.toLowerCase();
  //
  //     this.ganttData = ganttCopy.filter((element: any) => {
  //       const driver = element.driver[0];
  //       return (
  //         driver.fullName && driver.fullName.toLowerCase().includes(driverName)
  //       );
  //     });
  //   } else {
  //     this.ganttData = this.ganttDataOriginal;
  //   }
  // }

  // [ER - 01/04/2025 ] - OLD CODE commented if we need in a future
  // async filterDataBySelect(): Promise<void> {
  //   if (this.selectedZone) {
  //     const selectDate = new Date(this.selectedDate);
  //
  //     const day = String(selectDate.getDate()).padStart(2, '0');
  //     const month = String(selectDate.getMonth() + 1).padStart(2, '0');
  //     const year = String(selectDate.getFullYear());
  //
  //     const formattedDate = `${day}/${month}/${year}`;
  //     const unix = this.dateToUnix(formattedDate);
  //     const serviceData = {
  //       zoneId: this.selectedZone,
  //       date: { seconds: unix },
  //       buffer_amount: 10,
  //     };
  //
  //     try {
  //       const res: any = await firstValueFrom(
  //         this.servicesSvc.getGantt(serviceData)
  //       );
  //       console.log('respuestA:', res)
  //       this.ganttData = res.ganttData ?? [];
  //       //this.ganttData = this.ganttData2;
  //       this.getDrivers();
  //       console.log(this.ganttData, 'RES');
  //     } catch (error) {
  //       console.error('Error fetching services', error);
  //     }
  //   }
  // }

  async filterDataBySelect(): Promise<void> {
    if (this.selectedZone) {
      const selectDate = new Date(this.selectedDate);
      const day = String(selectDate.getDate()).padStart(2, '0');
      const month = String(selectDate.getMonth() + 1).padStart(2, '0');
      const year = String(selectDate.getFullYear());
      const formattedDate = `${day}/${month}/${year}`;
      const unix = this.dateToUnix(formattedDate);
      const serviceData: GanttRequest = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };

      try {
        const res = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        await this.getDrivers();
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }
  }

  convertToHourFormat(seconds: TimeData): number {
    const convertSeconds = parseInt(seconds.seconds, 10);
    const hour = convertSeconds / 3600;
    return parseFloat(hour.toFixed(1));
  }

  // [ER] - 01/04/2025 - Commented if we need in a future
  // convertToHourFormat(seconds: any) {
  //   const convertSeconds = parseInt(seconds.seconds, 10);
  //   const hour = convertSeconds / 3600;

  //   let ds = this.selectedDate;
  //   let zs = this.selectedZone;
  //   let zh = this.zones.find((zone) => zone.zoneId === zs)?.timeZone;

  //   let dateSearch = moment(ds).tz(zh ? zh : 'Europe/Madrid');
  //   let isCh = dateSearch.isDST();

  //   console.log(
  //     `convertToHourFormat - Input seconds: ${
  //       seconds.seconds
  //     }, Calculated hour: ${parseFloat(hour.toFixed(1))}, DST: ${isCh}`
  //   );

  //   return parseFloat(hour.toFixed(1));
  // }

  // dateToUnix(dateString: any) {
  //   return moment.utc(dateString, 'DD/MM/YYYY').startOf('day').unix();
  // }

  dateToUnix(dateString: string): number {
    return moment.utc(dateString, 'DD/MM/YYYY').startOf('day').unix();
  }

  showLoader(): void {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader(): void {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }
  }

  /* 
  async filterDataByDate() {
    this.showLoader();
    if (this.selectedDate && this.selectedZone) {
      localStorage.setItem('selectedDate', this.selectedDate as string);
      localStorage.setItem('selectedZone', this.selectedZone);

      this.selectedDate = new Date(this.selectedDate);
      const day = String(this.selectedDate.getDate()).padStart(2, '0');
      const month = String(this.selectedDate.getMonth() + 1).padStart(2, '0');
      const year = String(this.selectedDate.getFullYear());
      const formattedDate = `${day}/${month}/${year}`;
      const unix = this.dateToUnix(formattedDate);

      const serviceData = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };
      try {
        const res: any = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        //console.log('gantData2: ', this.ganttData2);
        // this.ganttData = this.ganttData2;
        this.ganttDataOriginal = [];
        await this.getDrivers();
        this.ganttData = this.ganttData.sort((a: any, b: any) => {
          const fullNameA = a.driver[0].fullName.toLowerCase();
          const fullNameB = b.driver[0].fullName.toLowerCase();

          if (fullNameA === 'conductor no asignado') {
            return 1;
          }
          if (fullNameB === 'conductor no asignado') {
            return -1;
          }

          if (fullNameA < fullNameB) {
            return -1;
          }
          if (fullNameA > fullNameB) {
            return 1;
          }
          return 0;
        });
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }

    this.searchTerm = '';
    console.log(this.ganttData, 'node');

    this.ganttDataOriginal.forEach((node: any) => {
      node.value.data = this.agruparPorHorario2(node.value.data);
    });

    this.hideLoader();
  }
    */

  async filterDataByDate(): Promise<void> {
    this.showLoader();
    if (this.selectedDate && this.selectedZone) {
      this.localStorageService.set(
        LocalStorageKeys.SELECTEDDATE,
        this.selectedDate as string
      );
      this.localStorageService.set(
        LocalStorageKeys.SELECTEDZONE,
        this.selectedZone
      );

      // Convertir selectedDate a Date y generar la fecha formateada
      this.selectedDate = new Date(this.selectedDate);
      const day: string = String(this.selectedDate.getDate()).padStart(2, '0');
      const month: string = String(this.selectedDate.getMonth() + 1).padStart(
        2,
        '0'
      );
      const year: string = String(this.selectedDate.getFullYear());
      const formattedDate: string = `${day}/${month}/${year}`;
      const unix: number = this.dateToUnix(formattedDate);

      const serviceData = {
        zoneId: this.selectedZone,
        date: { seconds: unix },
        buffer_amount: 10,
      };

      try {
        const res: GetGanttResponse = await firstValueFrom(
          this.servicesSvc.getGantt(serviceData)
        );
        this.ganttData = res.ganttData ?? [];
        this.ganttDataOriginal = [];
        await this.getDrivers();

        // Ordenar los datos según el nombre del primer driver, utilizando un valor por defecto en caso de que no exista driver.
        this.ganttData = this.ganttData.sort(
          (a: GanttDataItem, b: GanttDataItem) => {
            const fullNameA: string =
              a.driver && a.driver.length > 0
                ? a.driver[0].fullName.toLowerCase()
                : 'conductor no asignado';
            const fullNameB: string =
              b.driver && b.driver.length > 0
                ? b.driver[0].fullName.toLowerCase()
                : 'conductor no asignado';

            if (fullNameA === 'conductor no asignado') {
              return 1;
            }
            if (fullNameB === 'conductor no asignado') {
              return -1;
            }
            return fullNameA < fullNameB ? -1 : fullNameA > fullNameB ? 1 : 0;
          }
        );
      } catch (error) {
        console.error('Error fetching services', error);
      }
    }

    this.searchTerm = '';

    // Agrupar las tareas en cada nodo
    this.ganttDataOriginal.forEach((node: any) => {
      node.value.data = this.agruparPorHorario2(node.value.data);
    });

    this.hideLoader();
  }

  // unixToDate(unixTimestamp: any) {
  //   return moment.unix(unixTimestamp).utc().format('DD/MM/YYYY');
  // }

  unixToDate(unixTimestamp: number): string {
    return moment.unix(unixTimestamp).utc().format('DD/MM/YYYY');
  }

  // [ER - 01/04/2025] - OLD CODE
  // async getDrivers() {
  //   const promises = this.ganttData.map(async (element: any) => {
  //     const res: any = await this.servicesSvc
  //       .getDriver(element.key)
  //       .toPromise();
  //     const driverError = [
  //       { fullName: 'Conductor no asignado', operatorType: 'Sin tipo' },
  //     ];

  //     console.log('drivers res:', res)
  //     element.driver =
  //       res.drivers && res.drivers.length > 0 ? res.drivers : driverError;

  //     element.value.data.forEach((service: any) => {
  //       service.tranformDateStart = this.formatTime(
  //         service.serviceStartDate.seconds
  //       );
  //       service.tranformDateEnd = this.formatTime(
  //         service.serviceEndDate.seconds
  //       );
  //     });
  //   });

  //   await Promise.all(promises);

  //   this.ganttDataOriginal = [...this.ganttData];
  // }

  // async getDrivers(): Promise<void> {
  //   const promises: Promise<void>[] = this.ganttData.map(async (element: GanttDataItem) => {
  //     // Usamos firstValueFrom para obtener el valor del observable
  //     const res = await firstValueFrom<GetDriverResponse>(
  //       this.servicesSvc.getDriver(element.key)
  //     );
  //
  //     // En caso de no tener drivers, se asigna un valor por defecto
  //     const driverError: Driver[] = [{
  //       isActive: false,
  //       fullName: 'Conductor no asignado',
  //       creationDate: { seconds: '0', nanos: 0 },
  //       additionalContactPhoneNumber: '',
  //       additionalEmailAddress: '',
  //       allowedMotorcycleServiceType: '',
  //       contactPhoneNumber: '',
  //       driverId: '',
  //       emailAddress: '',
  //       firstName: '',
  //       lastName: '',
  //       isInternal: false,
  //       operatorType: '',
  //       profilePictureUrl: '',
  //       startAddress: '',
  //       startAddressDetail: '',
  //       startAddressPoint: { Longitude: 0, Latitude: 0, Srid: 0, _Srid: '' },
  //       workdayEndTime: { seconds: '0', nanos: 0 },
  //       workdayStartTime: { seconds: '0', nanos: 0 },
  //       zoneId: ''
  //     }];
  //
  //     element.driver = (res.drivers && res.drivers.length > 0) ? res.drivers : driverError;
  //
  //     // Para cada servicio, calculamos las fechas transformadas
  //     element.value.data.forEach((service: GanttTask) => {
  //       service.tranformDateStart = this.formatTime(service.serviceStartDate.seconds);
  //       service.tranformDateEnd = this.formatTime(service.serviceEndDate.seconds);
  //     });
  //   });
  //
  //   await Promise.all(promises);
  //   this.ganttDataOriginal = [...this.ganttData];
  // }

  async getDrivers(): Promise<void> {
    // Valor por defecto para cuando no se reciben drivers
    const driverError: DriverGant[] = [
      {
        fullName: 'Conductor no asignado',
        operatorType: 'Sin tipo',
        isActive: false,
        creationDate: { seconds: '0', nanos: 0 },
        additionalContactPhoneNumber: '',
        additionalEmailAddress: '',
        allowedMotorcycleServiceType: '',
        contactPhoneNumber: '',
        driverId: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        isInternal: false,
        profilePictureUrl: '',
        startAddress: '',
        startAddressDetail: '',
        startAddressPoint: { Longitude: 0, Latitude: 0, Srid: 0, _Srid: '' },
        workdayEndTime: { seconds: '0', nanos: 0 },
        workdayStartTime: { seconds: '0', nanos: 0 },
        zoneId: '',
      },
    ];

    // Recorremos cada elemento de ganttData para obtener los drivers
    const promises: Promise<void>[] = this.ganttData.map(
      async (element: GanttDataItem) => {
        const res: GetDriversGantResponse = await firstValueFrom(
          this.servicesSvc.getDriver(element.key)
        );

        // Si no se reciben drivers, se asigna el valor por defecto
        element.driver =
          res.drivers && res.drivers.length > 0 ? res.drivers : driverError;

        // Para cada servicio en el nodo, se calculan las fechas formateadas
        element.value.data.forEach((service: GanttTask) => {
          service.tranformDateStart = formatTime(
            service.serviceStartDate.seconds
          );
          service.tranformDateEnd = formatTime(service.serviceEndDate.seconds);
        });
      }
    );
    await Promise.all(promises);
    this.ganttDataOriginal = [...this.ganttData];
  }

  // async convertData() {
  //   await this.ganttData.forEach((element: any) => {
  //     this.servicesSvc.getDriver(element.key).subscribe(async (res: any) => {

  //       await res.value.data.forEach((service: any) => {
  //         service.tranformDateStart = this.convertToHourFormat(
  //           service.serviceStartDate
  //         );
  //         service.tranformDateEnd = this.convertToHourFormat(
  //           service.serviceEndDate
  //         );
  //       });
  //     });

  //     this.ganttDataOriginal = [...this.ganttData];

  //     console.log(this.ganttDataOriginal, 'USA2');
  //   });
  // }

  // [OLD CODE - 02/04/2025] - I comment if we need in a future
  // getVertical(verticalType: string) {
  //   const selectedVertical = this.verticals.find(
  //     (vertical) => vertical.type === verticalType
  //   );
  //
  //   return selectedVertical?.name;
  // }

  getVertical(verticalType: string): string | undefined {
    return this.verticals.find(({ type }) => type === verticalType)?.name;
  }

  // ER - 02/04/2025 - Old code commented if we need in a future
  // formatTime(seconds: any) {
  //   const date = new Date(parseInt(seconds) * 1000); // Convertir a milisegundos
  //   let hours = date.getUTCHours();
  //   let minutes = date.getUTCMinutes();
  //
  //   let ds = this.selectedDate;
  //   let zs = this.selectedZone;
  //   let zh = this.zones.find((zone) => zone.zoneId === zs)?.timeZone;
  //
  //   let dateSearch = moment(ds).tz(zh ? zh : 'Europe/Madrid');
  //   let isCh = dateSearch.isDST();
  //
  //   if (isCh) {
  //     return `${String(hours - 1).padStart(2, '0')}:${String(minutes).padStart(
  //       2,
  //       '0'
  //     )}`;
  //   } else {
  //     return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
  //       2,
  //       '0'
  //     )}`;
  //   }
  // }

  // [ER] - OLD VERSIÓN, THE FRONTEND DOESNT HAVE TO MODIFY THE HOUR

  // formatTime(secondsStr: string): string {
  //   // Convertir los segundos (string) a número y luego a milisegundos
  //   const seconds = parseInt(secondsStr, 10);
  //   const date = new Date(seconds * 1000);
  //   const hours = date.getUTCHours();
  //   const minutes = date.getUTCMinutes();
  //
  //   // Obtener la zona horaria asociada a la zona seleccionada o usar 'Europe/Madrid' por defecto
  //   const selectedZone = this.selectedZone;
  //   const timeZone =
  //     this.zones.find((zone) => zone.zoneId === selectedZone)?.timeZone ||
  //     'Europe/Madrid';
  //
  //   // Determinar si la fecha seleccionada está en horario de verano (DST)
  //   const dateInZone = moment(this.selectedDate).tz(timeZone);
  //   const isDST = dateInZone.isDST();
  //
  //   // Si está en DST, se ajusta la hora restando 1
  //   const adjustedHours = isDST ? hours - 1 : hours;
  //
  //   return `${String(adjustedHours).padStart(2, '0')}:${String(
  //     minutes
  //   ).padStart(2, '0')}`;
  // }

  //formatTime(secondsStr: string): string {
  //  const seconds = parseInt(secondsStr, 10);
  //  const date = new Date(seconds * 1000);
  //  const hours = date.getUTCHours();
  //  const minutes = date.getUTCMinutes();
  //  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
  //    2,
  //    '0'
  //  )}`;
  //}

  // Se convierte a milisegundos y se crea la fecha en formato UTC,
  // luego se extrae la parte de hora y minutos del string ISO

  // formatTime(secondsStr: string): string {
  //   const seconds: number = parseInt(secondsStr, 10);

  //   return new Date(seconds * 1000).toISOString().slice(11, 16);
  // }

  public getFormattedTime(seconds: string): string {
    return formatTime(seconds);
  }

  agruparPorHorario(data: GanttTask[]): GanttTask[][] {
    // Función para convertir "HH:MM" a minutos
    const convertirTiempoAminutos = (tiempo: string): number => {
      if (typeof tiempo !== 'string') {
        console.error('Tiempo no válido:', tiempo);
        return 0; // Si no es una cadena, devolvemos 0 minutos
      }
      const [horas, minutos] = tiempo.split(':').map(Number);
      return horas * 60 + minutos;
    };

    // Función para determinar si dos tareas se solapan en el tiempo
    const seSuperponen = (obj1: GanttTask, obj2: GanttTask): boolean => {
      const inicio1 = convertirTiempoAminutos(
        formatTime(obj1.serviceStartDate.seconds)
      );
      const fin1 = convertirTiempoAminutos(
        formatTime(obj1.serviceEndDate.seconds)
      );
      const inicio2 = convertirTiempoAminutos(
        formatTime(obj2.serviceStartDate.seconds)
      );
      const fin2 = convertirTiempoAminutos(
        formatTime(obj2.serviceEndDate.seconds)
      );
      return inicio1 < fin2 && inicio2 < fin1;
    };

    // Creamos un arreglo de grupos (cada grupo es un arreglo de GanttTask)
    const grupos: GanttTask[][] = [];

    data.forEach((tarea) => {
      let agrupado = false;

      // Se recorre cada grupo para ver si la tarea se solapa con alguno
      for (const grupo of grupos) {
        if (grupo.some((item) => seSuperponen(item, tarea))) {
          grupo.push(tarea);
          agrupado = true;
          break;
        }
      }

      // Si la tarea no se solapa con ningún grupo, se crea uno nuevo
      if (!agrupado) {
        grupos.push([tarea]);
      }
    });

    return grupos;
  }

  // agruparPorHorario(data: any) {
  //   // Convertir el tiempo "HH:MM" a minutos desde el inicio del día
  //   function convertirTiempoAminutos(tiempo: any) {
  //     if (typeof tiempo !== 'string') {
  //       console.error('Tiempo no válido:', tiempo);
  //       return 0; // Si no es una cadena, devolvemos 0 minutos
  //     }

  //     const [horas, minutos] = tiempo.split(':').map(Number);
  //     return horas * 60 + minutos;
  //   }

  //   // Comparar si dos objetos se solapan en sus intervalos de tiempo
  //   const seSuperponen = (obj1: any, obj2: any) => {
  //     const inicio1 = convertirTiempoAminutos(
  //       this.formatTime(obj1.serviceStartDate.seconds)
  //     );
  //     const fin1 = convertirTiempoAminutos(
  //       this.formatTime(obj1.serviceEndDate.seconds)
  //     );
  //     const inicio2 = convertirTiempoAminutos(
  //       this.formatTime(obj2.serviceStartDate.seconds)
  //     );
  //     const fin2 = convertirTiempoAminutos(
  //       this.formatTime(obj2.serviceEndDate.seconds)
  //     );

  //     // Los intervalos se solapan si uno empieza antes de que termine el otro
  //     return inicio1 < fin2 && inicio2 < fin1;
  //   };

  //   // Array para almacenar los grupos
  //   const grupos = [];

  //   // Iterar sobre cada objeto en el array
  //   for (let i = 0; i < data.length; i++) {
  //     let agrupado = false;

  //     // Verificar si el objeto actual se solapa con algún grupo existente
  //     for (let j = 0; j < grupos.length; j++) {
  //       const grupo = grupos[j];
  //       if (grupo.some((obj) => seSuperponen(obj, data[i]))) {
  //         grupo.push(data[i]);
  //         agrupado = true;
  //         break;
  //       }
  //     }

  //     // Si no se ha agrupado, crear un nuevo grupo
  //     if (!agrupado) {
  //       grupos.push([data[i]]);
  //     }
  //   }

  //   return grupos;
  // }

  agruparPorHorario2(data: GanttTask[]): GanttTask[][] {
    const ds = this.selectedDate;
    const zs = this.selectedZone;
    const zh = this.zones.find((zone) => zone.zoneId === zs)?.timeZone;
    const dateSearch = moment(ds).tz(zh ? zh : 'Europe/Madrid');
    const isCh = dateSearch.isDST();

    function convertirTiempoAminutos(tiempo: string): number {
      const [horas, minutos] = tiempo.split(':').map(Number);
      return horas * 60 + minutos;
    }

    // [ER - 02/04/2025] - OLD CODE
    //function formatTime(seconds: string): string {
    //  const date = new Date(parseInt(seconds) * 1000);
    //  const hours = date.getUTCHours();
    //  const minutes = date.getUTCMinutes();
    //  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    //    2,
    //    '0'
    //  )}`;
    //}

    // [ER - 02/04/2025] - OLD CODE
    // function formatTime2(seconds: string): string {
    //   const date = new Date(parseInt(seconds) * 1000);
    //   const hours = date.getUTCHours();
    //   const minutes = date.getUTCMinutes();
    //   return `${String(hours - 1).padStart(2, '0')}:${String(minutes).padStart(
    //     2,
    //     '0'
    //   )}`;
    // }

    const seSolapan = (obj1: GanttTask, obj2: GanttTask): boolean => {
      if (!isCh) {
        const inicio1 = convertirTiempoAminutos(
          formatTime(obj1.serviceStartDate.seconds)
        );
        const fin1 = convertirTiempoAminutos(
          formatTime(obj1.serviceEndDate.seconds)
        );
        const inicio2 = convertirTiempoAminutos(
          formatTime(obj2.serviceStartDate.seconds)
        );
        const fin2 = convertirTiempoAminutos(
          formatTime(obj2.serviceEndDate.seconds)
        );
        return inicio1 < fin2 && fin1 > inicio2;
      } else {
        const inicio1 = convertirTiempoAminutos(
          formatTime(obj1.serviceStartDate.seconds)
        );
        const fin1 = convertirTiempoAminutos(
          formatTime(obj1.serviceEndDate.seconds)
        );
        const inicio2 = convertirTiempoAminutos(
          formatTime(obj2.serviceStartDate.seconds)
        );
        const fin2 = convertirTiempoAminutos(
          formatTime(obj2.serviceEndDate.seconds)
        );
        return inicio1 < fin2 && fin1 > inicio2;
      }
    };

    const grupos: GanttTask[][] = [];
    for (let i = 0; i < data.length; i++) {
      let agrupado = false;
      for (let j = 0; j < grupos.length; j++) {
        const grupo = grupos[j];
        if (grupo.some((obj) => seSolapan(obj, data[i]))) {
          grupo.push(data[i]);
          agrupado = true;
          break;
        }
      }
      if (!agrupado) {
        grupos.push([data[i]]);
      }
    }
    return grupos;
  }

  /* 
  agruparPorHorario2(data: any) {
    let ds = this.selectedDate;
    let zs = this.selectedZone;
    let zh = this.zones.find((zone) => zone.zoneId === zs)?.timeZone;

    let dateSearch = moment(ds).tz(zh ? zh : 'Europe/Madrid');
    let isCh = dateSearch.isDST();

    function convertirTiempoAminutos(tiempo: any) {
      const [horas, minutos] = tiempo.split(':').map(Number);
      return horas * 60 + minutos;
    }

    function formatTime(seconds: string) {
      const date = new Date(parseInt(seconds) * 1000); // Convertir a milisegundos
      let hours = date.getUTCHours();
      let minutes = date.getUTCMinutes();
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }

    function formatTime2(seconds: string) {
      const date = new Date(parseInt(seconds) * 1000); // Convertir a milisegundos
      let hours = date.getUTCHours();
      let minutes = date.getUTCMinutes();
      return `${String(hours - 1).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}`;
    }

    const seSolapan = (obj1: any, obj2: any) => {
      if (!isCh) {
        const inicio1 = convertirTiempoAminutos(
          formatTime(obj1.serviceStartDate.seconds)
        );
        const fin1 = convertirTiempoAminutos(
          formatTime(obj1.serviceEndDate.seconds)
        );
        const inicio2 = convertirTiempoAminutos(
          formatTime(obj2.serviceStartDate.seconds)
        );
        const fin2 = convertirTiempoAminutos(
          formatTime(obj2.serviceEndDate.seconds)
        );
        return inicio1 < fin2 && fin1 > inicio2;
      } else {
        const inicio1 = convertirTiempoAminutos(
          formatTime2(obj1.serviceStartDate.seconds)
        );
        const fin1 = convertirTiempoAminutos(
          formatTime2(obj1.serviceEndDate.seconds)
        );
        const inicio2 = convertirTiempoAminutos(
          formatTime2(obj2.serviceStartDate.seconds)
        );
        const fin2 = convertirTiempoAminutos(
          formatTime2(obj2.serviceEndDate.seconds)
        );
        return inicio1 < fin2 && fin1 > inicio2;
      }
    };

    // Array para almacenar los grupos
    const grupos = [];

    // Iterar sobre cada objeto en el array
    for (let i = 0; i < data.length; i++) {
      let agrupado = false;

      // Verificar si el objeto actual se solapa con algún grupo existente
      for (let j = 0; j < grupos.length; j++) {
        const grupo = grupos[j];

        // Verificamos si el objeto actual se solapa con algún objeto del grupo
        if (grupo.some((obj) => seSolapan(obj, data[i]))) {
          grupo.push(data[i]);
          agrupado = true;
          break; // Salimos del ciclo una vez agregado al grupo
        }
      }

      // Si no se ha agrupado, crear un nuevo grupo
      if (!agrupado) {
        grupos.push([data[i]]);
      }
    }
    return grupos;
  }

  */
}

import {
  Component,
  Inject,
  TemplateRef,
  ViewChild,
  Input,
  NgZone
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { ServicesService } from 'src/app/services/services.service';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
declare var moment: any;

@Component({
  selector: 'app-events-table',
  templateUrl: './events-table.component.html',
  styleUrls: ['./events-table.component.css'],
})
export class EventsTableComponent {
  readonly columns = ['date', 'hour', 'user', 'event'];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  @Input() events: any;
  @Input() timeZone: any;

  constructor(
    private servicesSvc: ServicesService,
    public hs: HistoryServicesService,
    private ngZone: NgZone
  ) {}

    async ngOnInit(){
        // this.events.forEach(async (eve:any)=>{
        //     const executor = await this.servicesSvc.acquireMSClient(
        //         eve.actingUser
        //     );
        //     console.log(eve);
        //     eve.executorName = executor ? executor.displayName : 'Nombre no disponible';
        // });
        console.log(this.events);
    }

  formatTimeUnit(unit: any) {
    return unit < 10 ? `0${unit}` : unit;
  }

  getDate(date: any) {
    // const dateInClientZone = moment.unix(seconds).tz(this.timeZone);

    // return dateInClientZone.format('DD/MM/YYYY');
    let textCreationDate = date.split('T');
    return moment(textCreationDate[0]).format('DD/MM/YYYY');
  }

  getDateHour(time: any) {
    // const date = new Date(seconds * 1000);

    // const hour = this.formatTimeUnit(date.getHours());
    // const min = this.formatTimeUnit(date.getMinutes());

    // return `${hour}:${min}`;
    let textTime = time.split(':');
    return textTime[0]+':'+textTime[1];
  }
  // events = [
  //     {
  //         'date':'09/08/24',
  //         'hour':'14:00',
  //         'user':'Plataforma',
  //         'event':'Se creó el servicio',
  //     },
  //     {
  //         'date':'09/08/24',
  //         'hour':'15:00',
  //         'user':'Plataforma',
  //         'event':'El Servicio se etiquetó como pendiente',
  //     },
  //     {
  //         'date':'09/08/24',
  //         'hour':'15:20',
  //         'user':'Plataforma',
  //         'event':'Se aprobó el servicio',
  //     }
  // ]
}

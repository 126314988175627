import { Component, Inject, ViewChild, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import {
  BehaviorSubject,
  firstValueFrom,
  map,
  Observable,
  throttleTime,
  timer,
} from 'rxjs';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

declare var moment: any;
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-service-history',
  templateUrl: './service-history.component.html',
  styleUrls: ['./service-history.component.css'],
})
export class ServiceHistoryComponent {
  searchTerm: string = '';
  incidentServices: any = [];
  selectedDate: any = [
    new Date(),
    new Date(new Date().setDate(new Date().getDate() + 30)),
  ];
  direction: number = 1; // 1 para ascendente, -1 para descendente
  sortBy: string = '';
  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  filterCount:number = this.paginatedData.length
  isXlsxSelected: boolean = false;
  isCsvSelected: boolean = false;
  exportCount: number = 0;
  zones!: any[];
  clients!: any[];
  products!: any[];
  filterDisabled: boolean = false;
  providers!: any[];
  datesArray: any[] = [];
  sortedColumn: string = '';
  sortDirection!: any;
  verticals: any[] = [
    { name: 'Transfer', type: 'TRANSFER' },
    { name: 'Taller', type: 'MECHANICAL_INSPECTION' },
    { name: 'Lavado', type: 'VEHICLE_WASH' },
    { name: 'Repostaje', type: 'REFUELING' },
    { name: 'Pre-ITV', type: 'PRE_TECHNICAL_INSPECTION' },
    { name: 'Transfer Larga Distancia', type: 'LONG_DISTANCE_TRANSFER' },
    { name: 'ITV', type: 'TECHNICAL_INSPECTION' },
    { name: 'Seguro', type: 'VEHICLE_INSURANCE' },
    { name: 'Cafler Fresh', type: 'ACCESSORY' },
    { name: 'Valet', type: 'VALET' },
    { name: 'Neumáticos', type: 'TYRES' },
    { name: 'Trámites', type: 'FORMALITIES' },
    { name: 'Parking', type: 'PARKING' },
    { name: 'Vehículo de sustitución', type: 'REPLACEMENT_VEHICLE' },
    { name: 'Grúa', type: 'TOW_TRUCK' },
    { name: 'Pide lo que quieras', type: 'WHATEVER_YOU_WANT' },
  ];

  orderStatus = [
    { statusName: 'Inicializado', orderStatus: 'INITIALIZED' },
    { statusName: 'Pendiente', orderStatus: 'PENDING' },
    { statusName: 'Confirmado', orderStatus: 'CONFIRMED' },
    { statusName: 'Recogiendo', orderStatus: 'PICKING_UP' },
    { statusName: 'En proceso', orderStatus: 'IN_PROGRESS' },
    { statusName: 'Entregando', orderStatus: 'DELIVERING' },
    { statusName: 'Finalizado', orderStatus: 'FINISHED' },
    { statusName: 'Fallado', orderStatus: 'FAILED' },
    { statusName: 'Cancelado por Cafler', orderStatus: 'CANCELLED_BY_CAFLER' },
    { statusName: 'Cancelado por cliente', orderStatus: 'CANCELLED_BY_CLIENT' },
  ];
  selectedOrderStatus: any = [];
  pendingCount!: number;
  resolvedCount!: number;
  countrys!: any[];
  selectedZone!: string;
  selectedClient: any = [];
  selectedProduct: any = [];
  selectedStatus: string = 'pending';
  selectedVertical: any = [];
  selectedProvider: string = '#';
  index: number = 0;
  length: number = 0;
  pageSize: number = 6;
  selectedCountry: any = '#';
  readonly columns = [
    'licensePlate',
    'order-hash',
    'service-status',
    'date',
    'time',
    'country',
    'zone',
    'vertical',
    'product',
    'client',
    'incident',
    'pickupAddress',
    'deliveryAddress',
    'providers',
    'comment',
    'actions',
  ];
  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  readonly items = ['Edit', 'Download', 'Rename', 'Delete'];
  readonly selectItems = ['Item 1', 'Item 2'];
  open = false;
  selected = null;
  private readonly size$ = new BehaviorSubject(10);
  private readonly page$ = new BehaviorSubject(0);
  readonly direction$ = new BehaviorSubject<-1 | 1>(-1);
  readonly sorter$ = new BehaviorSubject<any>('name');

  date: any = Math.floor(new Date('2024-08-14').getTime() / 1000);
  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private hs: HistoryServicesService,
    private router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.hs.getZones().subscribe((zones: any) => {
      this.zones = zones.zones;
    });
    const oldParams = this.hs.historyDataParams;
    if (Object.keys(oldParams).length === 0) {
      this.length = Math.ceil(this.incidentServices.length / this.pageSize);
      this.paginatedDataOperation();
    } else {
      this.selectedZone = oldParams.zoneId;
      this.selectedClient = oldParams.client;
      this.selectedOrderStatus = oldParams.status;
      this.selectedProduct = oldParams.product;
      this.selectedVertical = oldParams.vertical;
      this.searchTerm = oldParams.searchTerm;
      this.selectedDate = oldParams.date;
      this.datesArray = oldParams.dates;
      this.filterByOldParams();
    }
  }

  ngOnDestroy() {
    this.hs.historyDataParams = {
      zoneId: this.selectedZone,
      client: this.selectedClient,
      status: this.selectedOrderStatus,
      product: this.selectedProduct,
      vertical: this.selectedVertical,
      searchTerm: this.searchTerm,
      date: this.selectedDate,
      dates: this.datesArray,
    };

    console.log(this.hs.historyDataParams, 'DATA ON DESTROY');
  }

  getZoneName(zoneId: string): string {
    const zone = this.zones.find((z) => z.zoneId === zoneId);
    return zone ? zone.zoneName : 'Unknown';
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    console.log( "28.50")
    this.filterCount = this.paginatedData.length
    
    const start = this.index * this.pageSize;
    this.paginatedData = this.incidentServices.slice(
      start,
      start + this.pageSize
    );
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }
  funcionKeyUp(searchText: string) {
    this.searchTerm = searchText;
  }

  filterData(searchText: string) {
    this.index = 0;
    if (!searchText) {
      this.searchTerm = '';
      this.paginatedData = this.incidentServices;

      if (this.selectedProduct != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) =>
            this.hs.translate(item.mainProductName) === this.selectedProduct
        );
      }

      if (this.selectedOrderStatus != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.orderStatus === this.selectedOrderStatus
        );
      }

      if (this.selectedClient != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.clientName === this.selectedClient
        );
      }

      if (this.selectedVertical != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.verticalType === this.selectedVertical
        );
      }

      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    } else {
      this.searchTerm = searchText;
      this.paginatedData = this.incidentServices;

      if (this.selectedZone != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.zoneId === this.selectedZone
        );
      }

      if (this.selectedProduct != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) =>
            this.hs.translate(item.mainProductName) === this.selectedProduct
        );
      }

      if (this.selectedOrderStatus != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.orderStatus === this.selectedOrderStatus
        );
      }

      if (this.selectedClient != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.clientName === this.selectedClient
        );
      }
      if (this.selectedVertical != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.verticalType === this.selectedVertical
        );
      }

      this.paginatedData = this.paginatedData.filter((item: any) => {
        const license = item.licensePlate.toLowerCase();
        const orderHash = item.orderHash.toLowerCase();
        const search = searchText.toLowerCase();

        return license.includes(search) || orderHash.includes(search);
      });
      console.log(this.paginatedData);
      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    }
  }

  filterDataBySelect(index?: any) {
    console.log(this.selectedClient, 'ORDER');
    this.paginatedData = this.incidentServices;
    this.index = index ?? 0;

    if (
      this.selectedProduct.length === 0 &&
      this.selectedClient.length === 0 &&
      this.selectedOrderStatus.length === 0 &&
      this.selectedVertical.length === 0 &&
      !this.searchTerm
    ) {
      this.sortByExec();
      this.applyPagination();
      return;
    }

    this.paginatedData = this.paginatedData.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

      const matchesProduct =
        this.selectedProduct.length === 0 ||
        this.selectedProduct.includes(this.hs.translate(item.mainProductName));

      const matchesClient =
        this.selectedClient.length === 0 ||
        this.selectedClient.includes(item.clientId);

      const matchesOrderStatus =
        this.selectedOrderStatus.length === 0 ||
        this.selectedOrderStatus.includes(item.orderStatus);

      const matchesVertical =
        this.selectedVertical.length === 0 ||
        this.selectedVertical.includes(item.verticalType);

      const matchesSearch =
        !this.searchTerm ||
        license.includes(search) ||
        orderHash.includes(search);

      return (
        matchesProduct &&
        matchesClient &&
        matchesOrderStatus &&
        matchesVertical &&
        matchesSearch
      );
    });
    this.sortByExec();
    this.applyPagination();
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }

    setTimeout(() => {
      const closeDiv = document.querySelector('.t-close');
      if (closeDiv) {
        (closeDiv as HTMLElement).click();
      }
    }, 5000);

    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }

  formatTimeUnit(unit: any) {
    return unit < 10 ? `0${unit}` : unit;
  }

  getDate(seconds: any, zoneId: any) {
    const zone = this.zones.find((zone) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);

    return dateInClientZone.format('DD/MM/YYYY');
  }

  getDateHour(seconds: any, zoneId:any) {
    // console.log(this.zones);
    // const date = new Date(seconds * 1000);

    // const hour = this.formatTimeUnit(date.getHours());
    // const min = this.formatTimeUnit(date.getMinutes());

    // return `${hour}:${min}`;

    const zone = this.zones.find((zone:any) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);

    return dateInClientZone.format('HH:mm');
  }

  updateCounts() {
    this.pendingCount = this.incidentServices.filter(
      (item: any) => item.status === 'pending'
    ).length;
    this.resolvedCount = this.incidentServices.filter(
      (item: any) => item.status === 'resolved'
    ).length;
  }

  div_loder = document.getElementById('loader');
  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  all_clients: any;
  getClientById(id: any) {
    let client_info = this.all_clients.find((item: any) => item.userId === id);
    return client_info ? client_info.businessName : null;
  }
  async filterDataByDate() {
    this.cdr.detectChanges();
    if (!this.all_clients) {
      this.hs.getClients().subscribe((res: any) => {
        this.all_clients = res.businessUserData;
      });
    }
    if (this.selectedDate && this.selectedZone) {
      this.showLoader();
      this.incidentServices = [];
      this.datesArray = [];
      const startDate = new Date(this.selectedDate[0]);
      const endDate = new Date(this.selectedDate[1]);

      const localStart = moment(startDate);
      const localEnd = moment(endDate);
      const day = String(this.selectedDate[0].getDate()).padStart(2, '0');
      const month = String(this.selectedDate[0].getMonth() + 1).padStart(
        2,
        '0'
      );
      const year = String(this.selectedDate[0].getFullYear());

      const formattedDate = `${day}/${month}/${year}`;

      console.log(
        formattedDate,
        moment.utc(formattedDate, 'DD/MM/YYYY').startOf('day').unix()
      );

      // Ajustar las fechas a UTC pero manteniendo el mismo día y hora
      const start = moment.utc({
        year: localStart.year(),
        month: localStart.month(),
        date: localStart.date(),
        hour: localStart.hour(),
        minute: localStart.minute(),
        second: localStart.second(),
      });

      const end = moment.utc({
        year: localEnd.year(),
        month: localEnd.month(),
        date: localEnd.date(),
        hour: localEnd.hour(),
        minute: localEnd.minute(),
        second: localEnd.second(),
      });

      let currentDate = start;
      console.log(currentDate.unix());
      while (currentDate <= end) {
        this.datesArray.push(currentDate.unix());

        currentDate = currentDate.add(1, 'days');
      }

      // console.log(this.datesArray);

      /*             this.paginatedData = this.incidentServices.filter((item:any) => {
                const itemDate = new Date(item.serviceStartDate.seconds *1000);
                console.log(itemDate, startDate, endDate, "A");
                return itemDate >= startDate && itemDate <= endDate;
            }); */

      this.index = 0;
      this.filterDisabled = true;
      for (let i = 0; i < this.datesArray.length; i++) {
        const element = this.datesArray[i];
        const serviceData = {
          date: { seconds: element },
          zoneId: this.selectedZone,
        };

        try {
          const res: any = await firstValueFrom(
            this.hs.getServices(serviceData)
          );

          if (res.services) {
            res.services.forEach((res: any) => {
              res.zoneName = this.getZoneName(res.zoneId);
              res.formattedDate = this.getDate(
                res.serviceStartDate.seconds,
                res.zoneId
              );
              res.vertical = this.getVertical(res.verticalType);
              res.hour = this.getDateHour(res.serviceStartDate.seconds, res.zoneId);
              this.incidentServices.push(res);
            });
          }
        } catch (error) {
          this.hideLoader();
        }
      }
      const inputElement: any = document.getElementById('searchInput');
      inputElement.value = '';
      this.selectedClient = [];
      this.selectedProduct = [];
      this.selectedVertical = [];
      this.selectedOrderStatus = [];
      this.searchTerm = '';
      this.paginatedData = this.incidentServices;

      if (!this.searchTerm) {
        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.filterCount = this.paginatedData.length
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      } else {
        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.filterCount = this.paginatedData.length
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      }

      this.filterDisabled = false;

      this.products = [
        ...new Set(
          this.incidentServices.map((item: any) =>
            this.hs.translate(item.mainProductName)
          )
        ),
      ];
      this.clients = [
        ...new Set(this.incidentServices.map((item: any) => item.clientName)),
      ];
    } /* else {
      this.paginatedData = this.incidentServices;

      this.index = 0;
      if (!this.searchTerm) {
        this.searchTerm = '';
        if (this.selectedClient != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.clientName === this.selectedClient
          );
        }
        if (this.selectedProduct != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) =>
              this.hs.translate(item.mainProductName) === this.selectedProduct
          );
        }

        if (this.selectedOrderStatus != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.orderStatus === this.selectedOrderStatus
          );
        }

        if (this.selectedClient != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.clientName === this.selectedClient
          );
        }

        if (this.selectedVertical != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.verticalType === this.selectedVertical
          );
        }

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      } else {
        if (this.selectedClient != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.clientName === this.selectedClient
          );
        }
        if (this.selectedProduct != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) =>
              this.hs.translate(item.mainProductName) === this.selectedProduct
          );
        }
        if (this.selectedClient != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.clientName === this.selectedClient
          );
        }

        if (this.selectedOrderStatus != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.orderStatus === this.selectedOrderStatus
          );
        }

        if (this.selectedVertical != '#') {
          this.paginatedData = this.paginatedData.filter(
            (item: any) => item.verticalType === this.selectedVertical
          );
        }

        this.paginatedData = this.paginatedData.filter((item: any) => {
          const license = item.licensePlate.toLowerCase();
          const orderHash = item.orderHash.toLowerCase();
          const search = this.searchTerm.toLowerCase();

          return license.includes(search) || orderHash.includes(search);
        });

        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      }
    } */
    this.hideLoader();
  }

  viewDetail(data: any) {
    const dataToSend = {
      orderHash: data.orderHash,
    };
    this.hs.setData(dataToSend);

    this.router.navigate(['service-manager/detail-service'], {
      queryParams: { orderHash: data.orderHash },
    });
  }

  exportToCSV() {
    const csvRows = [];

    csvRows.push(
      [
        'Mátricula',
        'Hora',
        'Order Hash',
        'Zona',
        'Fecha',
        'Producto',
        'Toppings',
        'Clientes',
        'Prioritario',
        'Dirección de Recogida',
        'Dirección de Entrega',
        'Proveedores',
        'Comentarios',
      ]
        .map((header) => this.escapeCSV(header))
        .join(',')
    );

    let historyServices = [...this.incidentServices];

    if (this.selectedClient != '#') {
      historyServices = historyServices.filter(
        (item: any) => item.clientName === this.selectedClient
      );
    }

    if (this.selectedProduct != '#') {
      historyServices = historyServices.filter(
        (item: any) =>
          this.hs.translate(item.mainProductName) === this.selectedProduct
      );
    }
    if (this.selectedClient != '#') {
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.clientName === this.selectedClient
      );
    }
    if (this.selectedOrderStatus != '#') {
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.orderStatus === this.selectedOrderStatus
      );
    }

    if (this.selectedVertical != '#') {
      historyServices = historyServices.filter(
        (item: any) => item.verticalType === this.selectedVertical
      );
    }

    historyServices.forEach((item: any) => {
      csvRows.push(
        [
          this.escapeCSV(item.licensePlate),
          this.escapeCSV(this.getDateHour(item.serviceStartDate.seconds, item.zoneId)),
          this.escapeCSV(item.orderHash),
          this.escapeCSV(this.getZone(item.zoneId)),
          this.escapeCSV(
            this.getDate(item.serviceStartDate.seconds, item.zoneId)
          ),
          this.escapeCSV(item.mainProductName),
          this.escapeCSV(item.toppingNames.join(', ')),
          this.escapeCSV(item.clientName),
          this.escapeCSV(item.isPriority ? 'Sí' : 'No'),
          this.escapeCSV(item.servicePickupAddress),
          this.escapeCSV(item.serviceDeliveryAddress),
          this.escapeCSV(
            item.providerNames.length == 0 ? '' : item.providerNames.join(', ')
          ),
          this.escapeCSV(
            item.comment.length == 0 ? 'Sin comentarios' : item.comment
          ),
        ].join(',')
      );
    });

    const csvFile = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvFile);

    const a = document.createElement('a');
    a.href = csvUrl;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  exportToXLSX() {
    let historyServices = [...this.incidentServices];

    if (this.selectedClient != '#') {
      historyServices = historyServices.filter(
        (item: any) => item.clientName === this.selectedClient
      );
    }

    if (this.selectedProduct != '#') {
      historyServices = historyServices.filter(
        (item: any) =>
          this.hs.translate(item.mainProductName) === this.selectedProduct
      );
    }
    if (this.selectedClient != '#') {
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.clientName === this.selectedClient
      );
    }
    if (this.selectedOrderStatus != '#') {
      this.paginatedData = this.paginatedData.filter(
        (item: any) => item.orderStatus === this.selectedOrderStatus
      );
    }

    if (this.selectedVertical != '#') {
      historyServices = historyServices.filter(
        (item: any) => item.verticalType === this.selectedVertical
      );
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      historyServices.map((item) => ({
        Mátricula: item.licensePlate,
        Hora: this.getDateHour(item.serviceStartDate.seconds, item.zoneId),
        'Order Hash': item.orderHash,
        Zona: this.getZone(item.zoneId),
        Fecha: this.getDate(item.serviceStartDate.seconds, item.zoneId),
        Producto: item.mainProductName,
        Toppings: item.toppingNames.join(', '),
        Clientes: item.clientName,
        Prioritario: item.isPriority ? 'Sí' : 'No',
        'Dirección de Recogida': item.servicePickupAddress,
        'Dirección de Entrega': item.serviceDeliveryAddress,
        Proveedores: item.providerNames.join(', '),
        Comentarios:
          item.comment.length == 0 ? 'Sin comentarios' : item.comment,
      })),
      {
        header: [
          'Mátricula',
          'Hora',
          'Order Hash',
          'Zona',
          'Fecha',
          'Producto',
          'Toppings',
          'Clientes',
          'Prioritario',
          'Dirección de Recogida',
          'Dirección de Entrega',
          'Proveedores',
          'Comentarios',
        ],
      }
    );

    ws['!cols'] = [
      { wpx: 100 }, // Mátricula
      { wpx: 80 }, // Hora
      { wpx: 120 }, // Order Hash
      { wpx: 80 }, // Zona
      { wpx: 90 }, // Fecha
      { wpx: 120 }, // Producto
      { wpx: 150 }, // Toppings
      { wpx: 100 }, // Clientes
      { wpx: 80 }, // Prioritario
      { wpx: 200 }, // Dirección de Recogida
      { wpx: 200 }, // Dirección de Entrega
      { wpx: 150 }, // Proveedores
      { wpx: 150 }, // Comentarios
    ];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout: ArrayBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'data.xlsx'
    );
  }

  escapeCSV(value: any): string {
    if (value === undefined || value === null) {
      return '';
    }

    let stringValue = String(value);

    stringValue = stringValue.replace(/"/g, '""');

    if (
      stringValue.includes(',') ||
      stringValue.includes('\n') ||
      stringValue.includes('"')
    ) {
      stringValue = `"${stringValue}"`;
    }

    return stringValue;
  }

  getZone(zoneId: string) {
    const selectedZone = this.zones.find((zone) => zone.zoneId === zoneId);

    return selectedZone.zoneName;
  }

  getVertical(verticalType: string) {
    const selectedVertical = this.verticals.find(
      (vertical) => vertical.type === verticalType
    );

    return selectedVertical.name;
  }

  updateExportCount() {
    this.exportCount = 0;
    if (this.isXlsxSelected) this.exportCount++;
    if (this.isCsvSelected) this.exportCount++;
  }

  exportTo() {
    if (this.isXlsxSelected) {
      this.exportToXLSX();
    }
    if (this.isCsvSelected) {
      this.exportToCSV();
    }
  }

  applyPagination() {
    this.filterCount = this.paginatedData.length
    const start = this.index * this.pageSize;
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

    if (this.length == 1) {
      this.index = 0;
    }
  }

  onSort(event: any): void {
    this.sortDirection = event;
  }

  sortData(): void {
    this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  setSortColumn(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }

  async filterByOldParams() {
    this.index = 0;
    this.showLoader();
    this.cdr.detectChanges();
    if (!this.all_clients) {
      this.hs.getClients().subscribe((res: any) => {
        this.all_clients = res.businessUserData;
      });
    }
    for (let i = 0; i < this.datesArray.length; i++) {
      const element = this.datesArray[i];
      const serviceData = {
        date: { seconds: element },
        zoneId: this.selectedZone,
      };

      try {
        const res: any = await firstValueFrom(this.hs.getServices(serviceData));

        if (res.services) {
          res.services.forEach((res: any) => {
            res.zoneName = this.getZoneName(res.zoneId);
            res.formattedDate = this.getDate(
              res.serviceStartDate.seconds,
              res.zoneId
            );
            res.vertical = this.getVertical(res.verticalType);
            res.hour = this.getDateHour(res.serviceStartDate.seconds, res.zoneId);
            this.incidentServices.push(res);
          });
        }
      } catch (error) {
        this.hideLoader();
      }
    }

    this.products = [
      ...new Set(
        this.incidentServices.map((item: any) =>
          this.hs.translate(item.mainProductName)
        )
      ),
    ];
    this.clients = [
      ...new Set(this.incidentServices.map((item: any) => item.clientName)),
    ];

    this.index = 0;
    this.paginatedData = this.incidentServices;
    const applyFilters = () => {
      if (this.selectedClient.length > 0) {
        this.paginatedData = this.paginatedData.filter((item: any) =>
          this.selectedClient.includes(item.clientId)
        );
      }

      if (this.selectedProduct.length > 0) {
        this.paginatedData = this.paginatedData.filter((item: any) =>
          this.selectedProduct.includes(this.hs.translate(item.mainProductName))
        );
      }

      if (this.selectedOrderStatus.length > 0) {
        this.paginatedData = this.paginatedData.filter((item: any) =>
          this.selectedOrderStatus.includes(item.orderStatus)
        );
      }

      if (this.selectedVertical.length > 0) {
        this.paginatedData = this.paginatedData.filter((item: any) =>
          this.selectedVertical.includes(item.verticalType)
        );
      }
    };

    applyFilters();

    if (this.searchTerm) {
      const search = this.searchTerm.toLowerCase();
      this.paginatedData = this.paginatedData.filter((item: any) => {
        const license = item.licensePlate.toLowerCase();
        const orderHash = item.orderHash.toLowerCase();
        return license.includes(search) || orderHash.includes(search);
      });
    }

    this.length = Math.ceil(this.paginatedData.length / this.pageSize);

    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
    this.hideLoader();
  }
}

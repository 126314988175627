import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap,catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: MsalService) {}

  actualAccount:any = null;

  acquireToken(): Promise<any> {
    return new Promise((resolve, reject) => {
        if (this.authService.instance.getAllAccounts().length) {
            this.actualAccount = this.authService.instance.getAllAccounts()[0];

            const request = {
                scopes: ['api://cafler-logistics-api/Test.TestRead'],
                account: this.actualAccount
            };

            this.authService.instance.acquireTokenSilent(request).then(response => {
                console.log("Token renovado", response);
                localStorage.setItem("accessToken", response.accessToken);
                localStorage.setItem("idToken",response.idToken);
                localStorage.setItem("tokenTimestamp", Date.now().toString());
                resolve(response.accessToken);
            }).catch(error => {
                console.error("Error al renovar el token:", error);
                this.authService.instance.acquireTokenPopup(request).then(response => {
                    console.log("Token renovado mediante popup",response);
                    localStorage.setItem("accessToken", response.accessToken);
                    localStorage.setItem("idToken",response.idToken);
                    localStorage.setItem("tokenTimestamp", Date.now().toString());
                    resolve(response.accessToken);
                }).catch(error => {
                    console.error("Error al renovar el token mediante popup:", error);
                    reject(error);
                });
            });
        } else {
            reject("No accounts found");
        }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = localStorage.getItem('accessToken');
      const tokenTimestamp = localStorage.getItem('tokenTimestamp');
      const tenMinutes = 10 * 60 * 1000;

      if (token && tokenTimestamp && (Date.now() - parseInt(tokenTimestamp) < tenMinutes)) {
        console.log('Dentro de los 10 minutos');
          const cloned = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${token}`)
          });
          return next.handle(cloned);
      } else {
          return from(this.acquireToken()).pipe(
              switchMap(newToken => {
                  const cloned = req.clone({
                      headers: req.headers.set('Authorization', `Bearer ${newToken}`)
                  });
                  return next.handle(cloned);
              }),
              catchError(error => {
                  console.error("Error en el interceptor:", error);
                  return next.handle(req);
              })
          );
      }
  }

}

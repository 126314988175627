<div class="table-body mt-5">
    <div class="table-body-head mb-3"> 
     	<div>
         	<label class="table-title">Clientes Cafler</label>
     	</div>
     	<tui-hosted-dropdown
     		tuiDropdownLimitWidth="auto" [content]="download_options" [(open)]="open">
			 	<button  tuiHostedDropdownHost class="export-button">
			     	<img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
			     	Exportar (.csv, xls..)
			 	</button>
 		</tui-hosted-dropdown>
    </div> 
    <div class="table-body-options mb-3">
     	<div class="search-bar">
         	<img src="../../../assets/icons/search- icon.svg" alt="Search Icon" class="search-icon" />
         	<input #searchInput  [(ngModel)]="searchTerm" type="text" placeholder="Buscar por producto..." class="search-bar-input custom_search_table_input" />
     	</div>
       	<select class="form-select table-option-select" aria-label="zone" [(ngModel)]="selectedZone">
         	<option  selected value="#">Vertical</option>
        	<option *ngFor="let ver of verticalsTypes" [value]="ver.value">{{ ver.realName }}</option>
       	</select>
       	<select class="form-select table-option-select" aria-label="operate" [(ngModel)]="selectedTypeClient" >
         	<option  selected value="#">Producto</option>
         	<option *ngFor="let type of types" [value]="type">{{ type }}</option>
       	</select>
       	<button (click)="filterData(searchTerm)" appearance="primary-button" size="s" tuiButton>
        	<tui-svg src="tuiIconSearch"></tui-svg>
        	Buscar
      	</button>
    </div>
    <div class="overflow-auto">
     	<table tuiTable size="l" style="width: 100%;" [columns]="columns">
		    <thead>
		        <tr tuiThGroup>
		            <th class="th-style" *tuiHead="'vertical'" tuiTh [sticky]="true">Vertical</th>
		            <th [sticky]="true" *tuiHead="'products'" tuiTh>Producto</th>
		            <th [sticky]="true" *tuiHead="'general_price'" tuiTh>Precio General</th>
		            <th [sticky]="true" *tuiHead="'discount'"  tuiTh>Descuento</th>
		            <th [sticky]="true" *tuiHead="'client_price'"  tuiTh>Precio cliente</th>
		            <th [sticky]="true" *tuiHead="'fee_general_urgency'"  tuiTh>Fee urgencia General</th>
		            <th [sticky]="true" *tuiHead="'discount_urgency'"  tuiTh>Descuento Urgencia</th>
		            <th [sticky]="true" *tuiHead="'fee_client_urgency'"  tuiTh>Fee urgencia Cliente</th>
		            <th [sticky]="true" *tuiHead="'general_fixed_hour'"  tuiTh>Hora fija General</th>
		            <th [sticky]="true" *tuiHead="'discount_fixed_hour'"  tuiTh>Descuento Hora</th>
		            <th [sticky]="true" *tuiHead="'client_fixed_hour'"  tuiTh>Hora Fija cliente</th>
		        </tr>
		    </thead>
		    <tbody tuiTbody>
		        <tr tuiTr *ngFor="let item of verticalsTypes">
		            <td *tuiCell="'vertical'" tuiTd>
		                <div [tuiCell]="size">
		                	<tui-icon icon="tuiIconChevronDown"></tui-icon>
		                    <span tuiTitle>
		                   		{{ item.realName }}   
		                    </span>
		                </div>
		            </td>
		            <td  *tuiCell="'products'" tuiTd>
		                <div [tuiCell]="size">
		                    <span tuiTitle>
		                        {{ item.value }}
		                    </span>
		                </div>
		            </td>
		            <td  *tuiCell="'general_price'" tuiTd>
		                <div [tuiCell]="size">
		                    <span tuiTitle>
		                        
		                    </span>
		                </div>
		            </td>
		            <td  *tuiCell="'discount'" tuiTd>
		                <span tuiTitle>
		                    
		                 </span>
		            </td>
		            <td  *tuiCell="'client_price'" tuiTd>
		                <div [tuiCell]="size">
		                    <span tuiTitle>
		                       
		                    </span>
		                </div>
		            </td>
		            <td  *tuiCell="'fee_general_urgency'" tuiTd>
		                <div [tuiCell]="size">
		                    <span tuiTitle>
		                       
		                    </span>
		                </div>
		            </td>
		            <td  *tuiCell="'discount_urgency'" tuiTd>
		                <div [tuiCell]="size">
		                    <span tuiTitle>
		                       
		                    </span>
		                </div>
		            </td>
		            <td  *tuiCell="'fee_client_urgency'" tuiTd>
		                <span tuiTitle>
		                    
		                </span>
		            </td>
		            <td  *tuiCell="'general_fixed_hour'" tuiTd>
		                <span tuiTitle>
		                    
		                </span>
		            </td>
		            <td  *tuiCell="'discount_fixed_hour'" tuiTd>
		                <span tuiTitle>
		                    
		                </span>
		            </td>
		            <td *tuiCell="'client_fixed_hour'" tuiTd>
		                <span tuiTitle>
		                    
		                </span>
		            </td>
		         </tr>
		    </tbody>
 		</table>
	</div>  
 
 <div class="table-pagination  mt-5">
 
  
 <tui-pagination
 
     [index]="index"
     [length]="length"
     (indexChange)="goToPage($event)"
 ></tui-pagination>
 </div>
 
 </div>
 <ng-template
 #download_options
 let-activeZone
>
<div class="download-menu">
 <label class="mb-2 export-title ">Exportar listado</label>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.xsl)</label>
 </div>
 <div class="d-flex mb-2 export-body-label">
     <tui-checkbox
     class="tui-space_bottom-3"
 ></tui-checkbox>
 <label>Excel(.csv)</label>
 </div>
 <button  class="export-button-menu mb-2 mt-1">
     <img class="img-icon" src="../../../assets/icons/download-Icon.svg" alt="">
     Exportar (2)
 </button>
</div>


</ng-template>

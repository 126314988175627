import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Permission } from 'src/app/enum/permission.enum';
import { PERMISSIONS_BY_TID } from 'src/app/constants/menu-config';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissions: Permission[] = [];
  private permissionsSubject = new BehaviorSubject<Permission[]>([]);
  private isPermissionsLoaded = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {
    this.authService
      .onAuthInitialized()
      .pipe(
        switchMap((isInitialized) => {
          if (isInitialized) {
            return this.authService.getTenantId();
          }
          return of(null);
        }),
        tap((tid) => {
          if (tid) {
            this.loadPermissions(tid.toLowerCase());
          } else {
            console.warn('⚠️ No TID encontrado – reseteando permisos');
            this.resetPermissions();
          }
        }),
        catchError((error) => {
          console.error('❌ Error al cargar permisos:', error);
          this.resetPermissions();
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * ✅ Carga permisos desde la configuración por TID
   */
  private loadPermissions(tid: string): void {
    //sconsole.log('cargando permisos del tid; ', tid)
    const config = PERMISSIONS_BY_TID[tid];
    if (config) {
      const { visible } = config;
      this.permissions = visible;
    } else {
      console.warn(`No permissions configured for TID: ${tid}`);
      this.permissions = [];
    }

    this.permissionsSubject.next(this.permissions);
    this.isPermissionsLoaded.next(true);
  }

  /**
   *  Reset permissions if no TID found
   */
  private resetPermissions(): void {
    this.permissions = [];
    this.permissionsSubject.next([]);
    this.isPermissionsLoaded.next(false);
  }

  /**
   * Devuelve permisos como observable
   */
  getPermissions(): Observable<Permission[]> {
    return this.permissionsSubject.asObservable();
  }

  /**
   *  Verifica si los permisos están cargados
   */
  arePermissionsLoaded(): Observable<boolean> {
    return this.isPermissionsLoaded.asObservable();
  }

  /**
   *  Verifica si el usuario tiene un permiso específico
   */
  hasPermission(permission: Permission): boolean {
    return this.permissions.includes(permission);
  }

  /**
   *  Verifica si el usuario tiene alguno de los permisos especificados
   */
  hasAnyPermission(permissions: Permission[]): boolean {
    return permissions.some((permission) =>
      this.permissions.includes(permission)
    );
  }
}

import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild, TemplateRef, Input, NgZone, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl, FormArray } from '@angular/forms';
import {tuiCreateTimePeriods} from '@taiga-ui/kit';
import {TuiCountryIsoCode} from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
declare var  moment: any
declare var google: any;

@Component({
  selector: 'app-modal-add-client',
  templateUrl: './modal-add-client.component.html',
  styleUrls: ['./modal-add-client.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAddClientComponent {
    @ViewChild('client_address', { static: true }) client_address!: ElementRef;
    @ViewChild('billing_address', { static: true }) billing_address!: ElementRef;
    @Input() action: any; 
    @Input() client: any; 
    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas,
        private ngZone: NgZone,
        public hs: HistoryServicesService,
    ) {}

    payments = [
        'Tarjeta',
        'Efectivo',
        'Transferencia bancaria'
    ];

    status_list = [
        'Activo',
        'Inactivo',
    ];

    perfils = [
        'Bronze',
        'Silver',
        'Gold',
    ];

    types = [
        'Dealership',
        'Workshop',
        'Renting',
        'Rac',
        'Insurer',
        'Corporate',
    ];

    countries = [
        {
            'iso':'es',
            'country':'España'
        },
        {
            'iso':'uk',
            'country':'UK'
        },
        {
            'iso':'fr',
            'country':'Francia'
        }
    ];

    countries_billing = [
        {
            'iso':'es',
            'country':'España'
        },
        {
            'iso':'uk',
            'country':'UK'
        },
        {
            'iso':'fr',
            'country':'Francia'
        }
    ];

    operatives:any = [];
    zones:any = [];

    operatives_billing = [
        {
            'zone':'Madrid',
            'country':'España'
        },
        {
            'zone':'Barcelona',
            'country':'España'
        },
        {
            'zone':'Valencia',
            'country':'España'
        },
        {
            'zone':'Londres',
            'country':'UK'
        },
        {
            'zone':'Liverpool',
            'country':'UK'
        },
        {
            'zone':'Birmingham',
            'country':'UK'
        },
        {
            'zone':'París',
            'country':'Francia'
        },
        {
            'zone':'Marsella',
            'country':'Francia'
        },
        {
            'zone':'Monaco',
            'country':'Francia'
        },
    ];

    provinces = [
        {
            'zone':'Madrid',
            'province':'Madrid'
        },
        {
            'zone':'Barcelona',
            'province':'Barcelona'
        },
        {
            'zone':'Valencia',
            'province':'Valencia'
        },
        {
            'zone':'Londres',
            'province':'Londres'
        },
        {
            'zone':'Liverpool',
            'province':'Liverpool'
        },
        {
            'zone':'Birmingham',
            'province':'Birmingham'
        },
        {
            'zone':'París',
            'province':'París'
        },
        {
            'zone':'Marsella',
            'province':'Marsella'
        },
        {
            'zone':'Monaco',
            'province':'Monaco'
        },
    ];

    provinces_billing = [
        {
            'zone':'Madrid',
            'province':'Madrid'
        },
        {
            'zone':'Barcelona',
            'province':'Barcelona'
        },
        {
            'zone':'Valencia',
            'province':'Valencia'
        },
        {
            'zone':'Londres',
            'province':'Londres'
        },
        {
            'zone':'Liverpool',
            'province':'Liverpool'
        },
        {
            'zone':'Birmingham',
            'province':'Birmingham'
        },
        {
            'zone':'París',
            'province':'París'
        },
        {
            'zone':'Marsella',
            'province':'Marsella'
        },
        {
            'zone':'Monaco',
            'province':'Monaco'
        },
    ];

    filteredOperatives:any;
    filteredProvinces:any;

    nombre_pc:any = [];
    apellido_pc:any = [];
    email_pc:any = [];
    
    filteredOperativesBilling:any;
    filteredProvincesBilling:any;
    clientForm = this.fb.group({
        zone:['', Validators.required],
        company_name:['', Validators.required],
        company_description:[''],
        key_account:['', Validators.required],
        status:['', Validators.required],
        perfil:['', Validators.required],
        type:['', Validators.required],
        activity_sector:[''],
        group:[''],
        sub_group:[''],
        address: [''],
        addressGeopointLt:[null, Validators.required],
        addressGeopointLn:[null, Validators.required],
        postal_code: ['', Validators.required],
        city:'',
        province: ['', Validators.required],
        country: ['', Validators.required],
        name_client:['', Validators.required],
        lastname_client:['', Validators.required],
        officePhone:'',
        email: ['', Validators.required],
        billing: ['', Validators.required],
        billing_cif:'',
        address_billing: ['', Validators.required],
        addressBillingGeopointLt:[null, Validators.required],
        addressBillingGeopointLn:[null, Validators.required],
        postal_code_billing: ['', Validators.required],
        city_billing:'',
        province_billing: ['', Validators.required],
        country_billing: ['', Validators.required],
        billingAddressAdditionalDetails:'',
        iban: ['', Validators.required],
        bank_account: ['', Validators.required],
        payment_type:'',
        payment_day: [''],
        payment_contact_name: [''],
        payment_contact_last_name: [''],
        payment_contact_email: [''],
        payment_contact_department:'',
        payment_contact_phone:'',
        aditional_info: [''],
        contacts: this.fb.array([]),
        zone_id:[''],

        id_client:[''],
        operativeZone: [''],
        bank_info:'',
        password_client:'',
        nombre_pc:'',
        apellido_pc:'',
        email_pc:'',
        departament_pc:'',
        phone_pc:'',
    });

    openCountry = false;
    selectedCountry = 'Selecciona el país';

    openOperative = false;
    selectedOperative = 'Selecciona previamente País';

    openProvince = false;
    selectedProvince = 'Selecciona previamente ciudad';

    openCountryBilling = false;
    selectedCountryBilling = 'Selecciona el país';

    openOperativeBilling = false;
    selectedOperativeBilling = 'Selecciona previamente País';

    openProvinceBilling = false;
    selectedProvinceBilling = 'Selecciona previamente ciudad';

    openPayment = false;
    selectedPayment = 'Selecciona método de pago';

    openStatus = false;
    selectedStatus = 'Selecciona si el cliente está Activo o Inactivo';

    openType = false;
    selectedType = 'Selecciona si el cliente es Gold, Silver o Bronze';

    idNewClient:any = '';
    passwordNewClient:any = '';

    readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
        TuiCountryIsoCode.ES,
        TuiCountryIsoCode.GB,
        TuiCountryIsoCode.FR,
    ];

    countryIsoCode = TuiCountryIsoCode.ES;

    cp:any = [1];

    ngAfterViewInit(): void {
        if (!google || !google.maps || !google.maps.places) {
            console.error('Google Maps API no está cargada correctamente.');
            return;
        }

        const input = this.client_address.nativeElement;
        const input2 = this.billing_address.nativeElement;
          
        const autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['address']
        });
        const autocomplete2 = new google.maps.places.Autocomplete(input2, {
            types: ['address']
        });

        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                if (!place.geometry) {
                    console.error('No se encontró la geometría del lugar.');
                    return;
                }
                const formattedAddress = place.formatted_address;

                let pc = place.address_components?.find((item:any) => item.types.includes('postal_code'));
                let ci = place.address_components?.find((item:any) => item.types.includes('locality'));
                let pr = place.address_components?.find((item:any) => item.types.includes('administrative_area_level_2'));
                let ps = place.address_components?.find((item:any) => item.types.includes('country'));

                this.clientForm.patchValue(
                    {
                        city:ci?.long_name,
                        province:pr?.long_name,
                        country:ps?.long_name,
                        postal_code:pc?.long_name,
                        address:formattedAddress
                    },{ emitEvent: false }
                );

                if (place.geometry) {
                    const lat:any = place.geometry.location.lat();
                    const lng:any = place.geometry.location.lng();

                    this.clientForm.patchValue(
                        {
                            addressGeopointLt:lat,
                            addressGeopointLn:lng,
                        },{ emitEvent: false }
                    );
                }
            });
        });
     
        autocomplete2.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete2.getPlace();

                if (!place.geometry) {
                    console.error('No se encontró la geometría del lugar.');
                    return;
                }
                const formattedAddress = place.formatted_address;

                let pc = place.address_components?.find((item:any) => item.types.includes('postal_code'));
                let ci = place.address_components?.find((item:any) => item.types.includes('locality'));
                let pr = place.address_components?.find((item:any) => item.types.includes('administrative_area_level_2'));
                let ps = place.address_components?.find((item:any) => item.types.includes('country'));

                this.clientForm.patchValue(
                    {
                        city_billing:ci?.long_name,
                        province_billing:pr?.long_name,
                        country_billing:ps?.long_name,
                        postal_code_billing:pc?.long_name,
                        address_billing:formattedAddress
                    },{ emitEvent: false }
                );

                if (place.geometry) {
                    const lat:any = place.geometry.location.lat();
                    const lng:any = place.geometry.location.lng();

                    this.clientForm.patchValue(
                        {
                            addressBillingGeopointLt:lat,
                            addressBillingGeopointLn:lng,
                        },{ emitEvent: false }
                    );
                }
            });
        });
    }

    getZone(zoneName: any) {
        const selectedZone = this.zones?.find(
          (zone: any) => zone.zoneName === zoneName
        );
        return selectedZone;
    }

    get items(): FormArray {
        return this.clientForm.get('contacts') as FormArray;
    }

    addItem(): void {
        const itemForm = this.fb.group({
            firstName: '',
            lastName: '',
            emailAddress: '',
            department: '',
            phoneNumber: '',
        });
        this.items.push(itemForm);
    }

    removeItem(index: number): void {
        this.items.removeAt(index);
    }

    ngOnInit(){
        this.hs.getZones().subscribe((zones: any) => {
            this.operatives = zones.zones.map((zone: any) => zone.zoneName);
            this.zones = zones.zones;
        });
        this.idNewClient = !this.client ?  '#'+Date.now()+this.getRandomId() : this.client.client_id;
        this.clientForm.patchValue(
            {id_client:this.idNewClient},{emitEvent: false}
        );

        this.passwordNewClient = Date.now()+this.getRandomId();
        this.clientForm.patchValue(
            {password_client:this.passwordNewClient},{emitEvent: false}
        );
        const geo:any = 1000

        if(this.action == 'ed' && this.client){
            this.clientForm.patchValue(
                {
                    company_name:this.client.client_name,
                    company_description:this.client.company_description,
                    key_account:this.client.key_account,
                    status:this.client.status,
                    type:this.client.type_client,
                    perfil:this.client.category,
                    activity_sector:this.client.activity_sector,
                    group:this.client.group,
                    sub_group:this.client.sub_group,
                    address: this.client.address,
                    postal_code: this.client.postal_code,
                    country: this.client.country,
                    province: this.client.province,
                    email: this.client.email,
                    billing:this.client.billing_name,
                    // cif:"GB9834756124",
                    address_billing: this.client.address_billing,
                    postal_code_billing: this.client.postal_code_billing,
                    country_billing: this.client.country_billing,
                    province_billing: this.client.province_billing,
                    iban: this.client.iban,
                    bank_account: this.client.bank_account,
                    // payment:this.client.payment_type,
                    payment_day: this.client.payment_day,
                    payment_contact_name: this.client.payment_contact_name,
                    payment_contact_last_name: this.client.payment_contact_last_name,
                    payment_contact_email: this.client.payment_contact_email,
                    aditional_info: this.client.aditional_info,
                    addressGeopointLt: geo,
                    addressGeopointLn: geo,
                    city:this.client.city,
                    officePhone:this.client.phone,
                    addressBillingGeopointLt: geo,
                    addressBillingGeopointLn: geo,
                    city_billing:this.client.city_billing,
                    payment_type:this.client.payment_type,
                    billing_cif:this.client.billing_cif,
                },{emitEvent: false}
            );
        }

        if(this.action == 'ad'){
            this.addItem();
        }
    }

    generateNewPassword(){
        this.passwordNewClient = Date.now()+this.getRandomId();
        this.clientForm.patchValue(
            {password_client:this.passwordNewClient},{emitEvent: false}
        );
    }

    private clickCounts: { [key: string]: number } = {};
    onSelectClick(event: MouseEvent){
        const selectElement = event.target as HTMLSelectElement;
        const selectId = selectElement.id;
        console.log(event, 44);

        if (!this.clickCounts[selectId]) {
          this.clickCounts[selectId] = 0;
        }
        this.clickCounts[selectId]++;

        if (this.clickCounts[selectId] === 1) {
            setTimeout(()=>{
                const sourceElement = document.querySelector('tui-dropdown');
                const targetElement = document.getElementById('modal_add_client');
                if (sourceElement && targetElement) {
                    const clonedNode = sourceElement.cloneNode(true);
                    // targetElement!.innerHTML = '';
                    targetElement.appendChild(sourceElement);
                }
            },300);
        }else{
            if (this.clickCounts[selectId] === 2) {
                if (document.activeElement === selectElement) {
                    selectElement.blur();
                    console.log(`Focus quitado del select ${selectId} por segundo clic`);
                }
                this.clickCounts[selectId] = 0;
            }
        }
    }

    resetFormSelects(){
        const entries = Object.entries(this.clickCounts);
        for (const [selectId, count] of entries) {
            this.clickCounts[selectId] = 0;
        }
    }

    onSelectBlur(type:any) {
        if(!type){
            this.resetFormSelects();
        }
    }

    ccp = 1;
    addCp(){
        let cicp = this.ccp + 1;
        this.ccp = cicp;
        this.cp.push(cicp);

        console.log(cicp);
    }

    showErrorMsj() {
        setTimeout(() => {
            const sourceElement = document.querySelector('tui-alert');
            const targetElement = document.getElementById(
                'modal_add_client_alert'
            );
            if (sourceElement && targetElement) {
                // console.log('replicar')
                const clonedNode = sourceElement.cloneNode(true);
                // targetElement!.innerHTML = '';
                targetElement.appendChild(sourceElement);
            }
        }, 300);
    }

    showDepositAlert(): void {
        if (this.action == 'ad') {
            this.alerts
            .open('Se ha guardado de forma exitosa', {
              label: 'Se ha creado un nuevo cliente',
              status: 'success',
              autoClose: false,
            })
            .subscribe();
        }
    }

    hideLoaderModal() {
        const div = document.querySelector(
          '.loader_inside_modal_button'
        ) as HTMLElement;
        if (div) {
          div.style.display = 'none'; // Agrega display: flex al div
        }
    }

    showLoaderModal() {
        const div = document.querySelector(
          '.loader_inside_modal_button'
        ) as HTMLElement;
        if (div) {
          div.style.display = 'flex'; // Agrega display: flex al div
        }
    }

    saveClient(){
        let form = this.clientForm.value;
        if(this.action == 'ad'){
            let data = {
                "clientCompanyName": form.company_name,
                "companyDescription": form.company_description,
                "keyAccountManagerName": form.key_account,
                "isClientActive": form.status == 'Activo' ? true : false,
                "clientCategory": form.perfil ? form.perfil.toUpperCase() : 'BRONZE',
                "clientType": form.type ? form.type.toUpperCase() : 'CORPORATE',
                "activitySector": form.activity_sector,
                "clientGroup": form.group,
                "clientSubgroup": form.sub_group,
                "clientAddress": {
                    "address": form.address,
                    "addressGeopoint": {
                        "Longitude": form.addressGeopointLn,
                        "Latitude": form.addressGeopointLt,
                        "Srid": 4326
                    }
                },
                "clientIdentityData": {
                    "firstName": form.name_client,
                    "lastName": form.lastname_client,
                    "emailAddress": form.email,
                    "phoneNumber": form.officePhone
                },
                "additionalContacts": form.contacts ? form.contacts : null,
                "billingInformation": {
                    "billingName": form.billing,
                    "fiscalCode": form.billing_cif,
                    "billingAddress": form.address_billing,
                    "billingAddressStreet": form.address_billing,
                    "billingAddressCity": form.city_billing,
                    "billingAddressPostalCode": form.postal_code_billing,
                    "billingAddressState": form.province_billing,
                    "billingAddressCountry": form.country_billing,
                    "billingAddressAdditionalDetails": form.billingAddressAdditionalDetails,
                    "iban": form.iban,
                    "bankAccount": form.bank_account,
                    "paymentMethod": form.payment_type,
                    "billingPrefferedDate": {
                        "seconds": 1727265600
                    },
                    "billingContact": {
                        "firstName": form.payment_contact_name,
                        "lastName": form.payment_contact_last_name,
                        "emailAddress": form.payment_contact_email,
                        "department": form.payment_contact_department,
                        "phoneNumber": form.payment_contact_phone
                    }
                },
              "comments": form.aditional_info,
              "zoneId": this.getZone(form.zone) ? this.getZone(form.zone).zoneId : '990fdc23-543d-4840-90f6-3757cea5790d'
            }
            this.showLoaderModal();
            this.hs.addClient(data).subscribe(
                (res: any) => {
                    if (!res.wasSuccessful && res?.statusCode) {
                        this.hs.showErrorCode(res.statusCode);
                        this.showErrorMsj();
                    } else {
                        this.offcanvasService.dismiss();
                        this.showDepositAlert();
                    }
                    this.hideLoaderModal();
                },
                (error) => {
                    this.hideLoaderModal();
                    if (error.status === 500) {
                        this.alerts
                            .open('Problema en el servidor', {
                            label: 'Error 500',
                            status: 'error',
                            autoClose: false,
                        })
                        .subscribe();
                        this.showErrorMsj();
                    } else {
                        this.alerts
                            .open(error, {
                            label: 'Otro error',
                            status: 'error',
                            autoClose: false,
                        })
                        .subscribe();
                        this.showErrorMsj();
                    }
                }
            );
        }
    }

    closeCanvas(){
        this.offcanvasService.dismiss();
    }

    // showDepositAlert(title:any, msj:any): void {
    //     this.alerts.open(msj, {
    //         label: title,
    //         status: 'success',
    //         autoClose: true,
    //     }).subscribe();
    // }

    getRandomId(){
        return Math.floor(Math.random() * 100);
    }
}

<div class="table-body mt-5">
  <div class="table-body-head mb-3">
    <div>
      <label class="table-title">Borrador de asignación 09/08/24-09:00</label>
    </div>
    <tui-hosted-dropdown
      tuiDropdownLimitWidth="auto"
      [content]="download_options"
      [(open)]="open"
    >
      <button tuiHostedDropdownHost class="export-button">
        <img
          class="img-icon"
          src="../../../assets/icons/download-Icon.svg"
          alt=""
        />
        Exportar (.csv, xls..)
      </button>
    </tui-hosted-dropdown>
  </div>
  <div class="table-body-options mb-3">
    <div class="search-bar">
      <img
        src="../../../assets/icons/search- icon.svg"
        alt="Search Icon"
        class="search-icon"
      />
      <input
        #searchInput
        [(ngModel)]="searchTerm"
        type="text"
        placeholder="Buscar personal por nombre y apellidos..."
        class="search-bar-input"
      />
    </div>
    <button
      [disabled]="filterDisabled"
      (click)="filterDataBySelect()"
      appearance="primary-button"
      size="s"
      tuiButton
    >
      <tui-svg src="tuiIconSearch"></tui-svg>
      Buscar
    </button>
    <!-- <select
      class="form-select table-option-select"
      aria-label="status"
      [(ngModel)]="selectedStatus"
      (ngModelChange)="filterDataBySelect()"
    >
      <option selected value="#">Estatus</option>
      <option *ngFor="let status of statuses" [value]="status">
        {{ status }}
      </option>
    </select> -->

    <ng-select
      [items]="statuses"
      bindLabel="status"
      [multiple]="true"
      [(ngModel)]="selectedStatus"
      [disabled]="filterDisabled"
      class="custom_select"
      (change)="filterDataBySelect()"
      placeholder="Estatus"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>

    <!-- <select
      class="form-select table-option-select"
      aria-label="operate"
      [(ngModel)]="selectedOperator"
      (ngModelChange)="filterDataBySelect()"
    >
      <option selected value="#">Operador</option>
      <option *ngFor="let operator of operators" [value]="operator">
        {{ operator }}
      </option>
    </select> -->

    <ng-select
      [items]="operators"
      bindLabel="operator"
      [multiple]="true"
      [(ngModel)]="selectedOperator"
      [disabled]="filterDisabled"
      class="custom_select"
      (change)="filterDataBySelect()"
      placeholder="Operador"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>

    <!-- <select
      class="form-select table-option-select"
      aria-label="role"
      [(ngModel)]="selectedRole"
      (ngModelChange)="filterDataBySelect()"
    >
      <option selected value="#">Rol</option>
      <option *ngFor="let role of roles" [value]="role">{{ role }}</option>
    </select> -->

    <ng-select
      [items]="roles"
      bindLabel="role"
      [multiple]="true"
      [(ngModel)]="selectedRole"
      [disabled]="filterDisabled"
      class="custom_select"
      (change)="filterDataBySelect()"
      placeholder="Rol"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>
  </div>
  <div class="overflow-auto">
    <table tuiTable size="l" style="width: 100%" [columns]="columns">
      <thead>
        <tr tuiThGroup>
          <th
            class="th-style"
            *tuiHead="'registrationAndOrderNumber'"
            tuiTh
            [sticky]="true"
          >
            Mátricula y Nº orden
          </th>
          <th [sticky]="true" *tuiHead="'executedBy'" tuiTh>Ejecutado por</th>
          <th [sticky]="true" *tuiHead="'active'" tuiTh>Activo</th>
          <th *tuiHead="'date'" tuiTh [sticky]="true">Fecha</th>
          <th [sticky]="true" *tuiHead="'startTime'" tuiTh>Horario inicio</th>
          <th [sticky]="true" *tuiHead="'endTime'" tuiTh>Horario fin</th>
          <th [sticky]="true" *tuiHead="'operator'" tuiTh>Operador</th>
          <th [sticky]="true" *tuiHead="'role'" tuiTh>Cargo</th>
          <th [sticky]="true" *tuiHead="'motorcycle'" tuiTh>Moto</th>
          <th [sticky]="true" *tuiHead="'pickupAddress'" tuiTh>
            Dirección de Recogida
          </th>
          <th [sticky]="true" *tuiHead="'deliveryAddress'" tuiTh>
            Dirección de Entrega
          </th>
          <th *tuiHead="'actions'"></th>
        </tr>
      </thead>
      <tbody tuiTbody>
        <tr tuiTr *ngFor="let item of paginatedData">
          <td *tuiCell="'registrationAndOrderNumber'" tuiTd>
            <div class="vertical-align">
              <span tuiTitle>
                {{ item.licensePlate }}
              </span>
              <span tuiSubtitle>
                {{ item.orderNumber }}
              </span>
            </div>
          </td>

          <td *tuiCell="'executedBy'" tuiTd>
            <div [tuiCell]="size">
              <tui-avatar
                [src]="item.executedBy | tuiInitials"
                [style.background]="item.executedBy | tuiAutoColor"
              ></tui-avatar>
              <span tuiTitle>
                {{ item.executedBy }}
                <!--    <span tuiSubtitle>silly@walk.uk</span> -->
              </span>
            </div>
          </td>
          <td *tuiCell="'active'" tuiTd>
            <div *ngIf="item.active; else noActive">
              <tui-badge
                status="success"
                value="Activo"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>

            <ng-template #noActive>
              <tui-badge
                status="error"
                value="Inactivo"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </ng-template>
          </td>
          <td *tuiCell="'date'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.date }}
              </span>
            </div>
          </td>

          <td *tuiCell="'startTime'" tuiTd>
            <span tuiTitle>
              {{ item.startTime }}
            </span>
          </td>
          <td *tuiCell="'endTime'" tuiTd>
            <span tuiTitle>
              {{ item.endTime }}
            </span>
          </td>
          <td *tuiCell="'operator'" tuiTd>
            <tui-badge
              *ngIf="item.operator == 'Externo'; else internal"
              size="m"
              value="Externo"
              class="tui-space_right-2 badge-color-external"
            ></tui-badge>

            <ng-template #internal>
              <tui-badge
                size="m"
                value="Interno"
                class="tui-space_right-2 badge-color-internal"
              ></tui-badge>
            </ng-template>
          </td>
          <td *tuiCell="'role'" tuiTd>
            <span tuiTitle>
              {{ item.role }}
            </span>
          </td>
          <td *tuiCell="'motorcycle'" tuiTd>
            <tui-badge
              *ngIf="item.motorcycle; else internal"
              size="m"
              value="No"
              class="tui-space_right-2 badge-color-external"
            ></tui-badge>

            <ng-template #internal>
              <tui-badge
                size="m"
                value="Si"
                class="tui-space_right-2 badge-color-internal"
              ></tui-badge>
            </ng-template>
          </td>
          <td *tuiCell="'pickupAddress'" tuiTd>
            <div tuiCell="l" class="address-cell">
              <span tuiTitle>
                {{ item.pickupAddress }}
              </span>
            </div>
          </td>
          <td *tuiCell="'deliveryAddress'" tuiTd>
            <div tuiCell="l" class="address-cell">
              <span tuiTitle>
                {{ item.deliveryAddress }}
              </span>
            </div>
          </td>
          <td *tuiCell="'actions'" tuiTd>
            <span class="seeAllIcon" tuiStatus>
              <span>Ver resultados</span>
              <button
                class="seeAllIcon"
                appearance="link"
                icon="tuiIconEye"
                size="xs"
                tuiAppearance="link"
                tuiIconButton
              >
                More
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-pagination mt-5">
    <tui-pagination
      [index]="index"
      [length]="length"
      (indexChange)="goToPage($event)"
    ></tui-pagination>
  </div>
</div>
<ng-template #download_options let-activeZone>
  <div class="download-menu">
    <label class="mb-2 export-title">Exportar listado</label>
    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox class="tui-space_bottom-3"></tui-checkbox>
      <label>Excel(.xsl)</label>
    </div>
    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox class="tui-space_bottom-3"></tui-checkbox>
      <label>Excel(.csv)</label>
    </div>
    <button class="export-button-menu mb-2 mt-1">
      <img
        class="img-icon"
        src="../../../assets/icons/download-Icon.svg"
        alt=""
      />
      Exportar (2)
    </button>
  </div>
</ng-template>

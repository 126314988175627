import {
  Component,
  Inject,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Renderer2,
} from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDuplicateServiceComponent } from 'src/app/shared/modal-duplicate-service/modal-duplicate-service.component';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { ServicesService } from 'src/app/services/services.service';
import { firstValueFrom } from 'rxjs';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import { Router } from '@angular/router';
declare var moment: any;
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-pending-services',
  templateUrl: './pending-services.component.html',
  styleUrls: ['./pending-services.component.css'],
})
export class PendingServicesComponent {
  //modal reference cesar-gutierrez
  bsModalRef?: BsModalRef;
  selectedDate: any = new Date();
  isXlsxSelected: boolean = false;
  isCsvSelected: boolean = false;
  exportCount: number = 0;
  datesArray: any[] = [];
  filterDisabled: boolean = false;
  sortedColumn: string = '';
  sortDirection!: any;
  countries = ['España', 'UK', 'Francia'];

  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];

  operatorsSelect = ['Interno', 'Externo'];

  rolesSelect = ['Gerente', 'Supervisor de Mantenimiento', 'Coordinador'];

  services = ['Servicio 1', 'Servicio 2', 'Servicio 3'];

  hours = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);
  hours2 = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);

  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];

  readonly countriesPhoneExtra: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];

  countryIsoCode = TuiCountryIsoCode.ES;
  countryIsoCodeExtra = TuiCountryIsoCode.ES;

  searchTerm: string = '';
  verticals: any[] = [
    { name: 'Transfer', type: 'TRANSFER' },
    { name: 'Taller', type: 'MECHANICAL_INSPECTION' },
    { name: 'Lavado', type: 'VEHICLE_WASH' },
    { name: 'Repostaje', type: 'REFUELING' },
    { name: 'Pre-ITV', type: 'PRE_TECHNICAL_INSPECTION' },
    { name: 'Transfer Larga Distancia', type: 'LONG_DISTANCE_TRANSFER' },
    { name: 'ITV', type: 'TECHNICAL_INSPECTION' },
    { name: 'Seguro', type: 'VEHICLE_INSURANCE' },
    { name: 'Cafler Fresh', type: 'ACCESSORY' },
    { name: 'Valet', type: 'VALET' },
    { name: 'Neumáticos', type: 'TYRES' },
    { name: 'Trámites', type: 'FORMALITIES' },
    { name: 'Parking', type: 'PARKING' },
    { name: 'Vehículo de sustitución', type: 'REPLACEMENT_VEHICLE' },
    { name: 'Grúa', type: 'TOW_TRUCK' },
    { name: 'Pide lo que quieras', type: 'WHATEVER_YOU_WANT' },
  ];

  pendingServices: any = [];

  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any[];
  clients!: any[];
  products!: any[];
  filterCount:number = 0
  countrys!: any[];
  selectedZone!: any;
  selectedClient: any = [];
  selectedProduct: any = [];

  date: any = Math.floor(new Date().getTime() / 1000);
  index: number = 0;
  length: number = 0;
  pageSize: number = 6;

  readonly columns = [
    'date',
    'time',
    'licensePlate',
    'order-hash',
    'zone',
    'product',
    'topping',
    'client',
    'priority',
    'pickupAddress',
    'deliveryAddress',
    'providers',
    'comment',
    'actions',
  ];

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  readonly items = ['Edit', 'Download', 'Rename', 'Delete'];

  readonly selectItems = ['Item 1', 'Item 2'];

  open = false;

  selected = null;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private servicesSvc: ServicesService,
    private offcanvasService: NgbOffcanvas,
    public hs: HistoryServicesService,
    private router: Router,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {}

  open_modal: any;
  ngOnInit(): void {
    this.open_modal = localStorage.getItem('open_modal') ? true : false;
    if (localStorage.getItem('open_modal')) {
      this.openModalDuplicateService();
      localStorage.removeItem('open_modal');
    }
    this.servicesSvc.getZones().subscribe((res: any) => {
      this.zones = res.zones;
    });

    const oldParams = this.hs.pendingDataParams;
    if (Object.keys(oldParams).length === 0) {
      this.length = Math.ceil(this.pendingServices.length / this.pageSize);
      this.paginatedDataOperation();
    } else {
      this.selectedZone = oldParams.zoneId;
      this.selectedClient = oldParams.client;

      this.selectedProduct = oldParams.product;

      this.searchTerm = oldParams.searchTerm;
      this.selectedDate = oldParams.date;
      this.datesArray = oldParams.dates;
      this.filterByOldParams();
    }

    this.hs.subscribe('updateListPendingServices', () => {
      this.filterDataByDate();
    });
  }

  ngOnDestroy() {
    this.hs.pendingDataParams = {
      zoneId: this.selectedZone,
      client: this.selectedClient,

      product: this.selectedProduct,

      searchTerm: this.searchTerm,
      date: this.selectedDate,
      dates: this.datesArray,
    };

    console.log(this.hs.pendingDataParams, 'DATA ON DESTROY');
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    const start = this.index * this.pageSize;
    this.paginatedData = this.pendingServices.slice(
      start,
      start + this.pageSize
    );
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }

  div_loder = document.getElementById('loader');

  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  funcionKeyUp(searchText: string) {
    this.searchTerm = searchText;
  }

  filterData() {
    this.index = 0;
    if (!this.searchTerm) {
      this.searchTerm = '';
      this.paginatedData = this.pendingServices;

      if (this.selectedProduct != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) =>
            this.hs.translate(item.mainProductName) === this.selectedProduct
        );
      }

      if (this.selectedClient != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.clientName === this.selectedClient
        );
      }

      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    } else {
      this.paginatedData = this.pendingServices;

      if (this.selectedClient != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.clientName === this.selectedClient
        );
      }

      if (this.selectedProduct != '#') {
        this.paginatedData = this.paginatedData.filter(
          (item: any) =>
            this.hs.translate(item.mainProductName) === this.selectedProduct
        );
      }

      this.paginatedData = this.paginatedData.filter((item: any) => {
        const license = item.licensePlate.toLowerCase();
        const orderHash = item.orderHash.toLowerCase();
        const client = item.clientName.toLowerCase();
        const search = this.searchTerm.toLowerCase();

        return (
          license.includes(search) ||
          orderHash.includes(search) ||
          client.includes(search)
        );
      });

      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }

    setTimeout(() => {
      const closeDiv = document.querySelector('.t-close');
      if (closeDiv) {
        (closeDiv as HTMLElement).click();
      }
    }, 5000);
  }

  all_clients: any;
  getClientById(id: any) {
    let client_info = this.all_clients.find((item: any) => item.userId === id);
    return client_info ? client_info.businessName : null;
  }

  async filterDataByDate() {
    this.showLoader();
    if (!this.all_clients) {
      this.hs.getClients().subscribe((res: any) => {
        this.all_clients = res.businessUserData;
      });
    }
    if (this.selectedZone && this.selectedDate) {
      this.pendingServices = [];
      this.paginatedData = [];

      this.datesArray = [];

      const localDate = moment(this.selectedDate);

      const start = moment.utc({
        year: localDate.year(),
        month: localDate.month(),
        date: localDate.date(),
        hour: localDate.hour(),
        minute: localDate.minute(),
        second: localDate.second(),
      });
      let currentDate = start;

      while (this.datesArray.length < 30) {
        this.datesArray.push(currentDate.unix());
        currentDate = currentDate.add(1, 'days');
      }

      for (let i = 0; i < this.datesArray.length; i++) {
        this.filterDisabled = true;
        const element = this.datesArray[i];
        const serviceData = {
          date: { seconds: element },
          zoneId: this.selectedZone,
        };
        try {
          const res: any = await firstValueFrom(
            this.servicesSvc.getServices(serviceData)
          );
          if (res.services) {
            const pending = res.services.filter(
              (service: any) => service.orderStatus === 'PENDING'
            );
            pending.forEach((service: any) => {
              service.startHour = service.serviceStartDate.seconds
                ? this.formatTime(
                    service.serviceStartDate.seconds, service.zoneId
                  )
                : ' no definido';
              service.endHour = service.serviceEndDate
                ? this.formatTime(
                    service.serviceEndDate.seconds, service.zoneId
                  )
                : ' no definido';
              service.zoneName = this.getZone(service.zoneId);
              service.formattedDate = this.getDate(
                service.serviceStartDate.seconds,
                service.zoneId
              );
              this.pendingServices.push(service);
            });
          }
        } catch (error) {
          console.error('Error fetching services', error);
        }

        this.paginatedData = this.pendingServices;
        const inputElement: any = document.getElementById('searchInput');
        inputElement.value = '';
        this.searchTerm = '';
        this.selectedClient = [];
        this.selectedProduct = [];

        this.clients = [
          ...new Set(this.pendingServices.map((item: any) => item.clientName)),
        ];
        this.products = [
          ...new Set(
            this.pendingServices.map((item: any) =>
              this.hs.translate(item.mainProductName)
            )
          ),
        ];
        this.length = Math.ceil(this.paginatedData.length / this.pageSize);
        if (this.length == 1) {
          this.index = 0;
        }
        this.filterCount = this.paginatedData.length
        const start = this.index * this.pageSize;
        this.paginatedData = this.paginatedData.slice(
          start,
          start + this.pageSize
        );
      }
      this.filterDisabled = false;
    }
    this.hideLoader();
  }

  async filterDataBySelect(index?: any) {
    this.paginatedData = this.pendingServices;
    this.index = index ?? 0;
    this.clients = [
      ...new Set(this.pendingServices.map((item: any) => item.clientName)),
    ];
    this.products = [
      ...new Set(
        this.pendingServices.map((item: any) =>
          this.hs.translate(item.mainProductName)
        )
      ),
    ];

    if (
      !this.searchTerm &&
      this.selectedClient.length === 0 &&
      this.selectedProduct.length === 0
    ) {
      this.sortByExec();
      this.applyPagination();
      return;
    }

    const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

    this.paginatedData = this.pendingServices.filter((item: any) => {
      const license = item.licensePlate.toLowerCase();
      const orderHash = item.orderHash.toLowerCase();
      const clientName = item.clientName.toLowerCase();
      const productName = this.hs.translate(item.mainProductName).toLowerCase();

      const matchesSearch =
        !this.searchTerm ||
        license.includes(search) ||
        orderHash.includes(search) ||
        clientName.includes(search);

      const matchesClient =
        this.selectedClient.length === 0 ||
        this.selectedClient.includes(item.clientId);

      const matchesProduct =
        this.selectedProduct.length === 0 ||
        this.selectedProduct.includes(this.hs.translate(item.mainProductName));

      return matchesSearch && matchesClient && matchesProduct;
    });

    this.sortByExec();
    this.applyPagination();
  }

  applyPagination() {
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);

    if (this.length === 1) {
      this.index = 0;
    }
    this.filterCount = this.paginatedData.length
    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
  }

  getZone(zoneId: string) {
    const selectedZone = this.zones.find((zone) => zone.zoneId === zoneId);

    return selectedZone.zoneName;
  }

  getVertical(verticalType: string) {
    const selectedVertical = this.verticals.find(
      (vertical) => vertical.type === verticalType
    );

    return selectedVertical.name;
  }

  getDate(seconds: any, zoneId: any) {
    const zone = this.zones.find((zone) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);

    return dateInClientZone.format('DD/MM/YYYY');
  }

  formatDate(seconds: number): string {
    const date = new Date(seconds * 1000);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatDate2(date: any) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatTime(seconds: any, zoneId:any) {
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // return `${hours}:${minutes}`;
    const zone = this.zones.find((zone:any) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);

    return dateInClientZone.format('HH:mm');
  }

  exportToCSV() {
    const csvRows = [];

    csvRows.push(
      [
        'Mátricula',
        'Hora',
        'Order Hash',
        'Zona',
        'Fecha',
        'Producto',
        'Toppings',
        'Clientes',
        'Prioritario',
        'Dirección de Recogida',
        'Dirección de Entrega',

        'Comentarios',
      ]
        .map((header) => this.escapeCSV(header))
        .join(',')
    );

    let pendingServices = [...this.pendingServices];

    if (this.selectedClient != '#') {
      pendingServices = pendingServices.filter(
        (item: any) => item.clientName === this.selectedClient
      );
    }

    if (this.selectedProduct != '#') {
      pendingServices = pendingServices.filter(
        (item: any) =>
          this.hs.translate(item.mainProductName) === this.selectedProduct
      );
    }

    pendingServices.forEach((item: any) => {
      csvRows.push(
        [
          this.escapeCSV(item.licensePlate),
          this.escapeCSV(`${item.startHour} - ${item.endHour}`),
          this.escapeCSV(item.orderHash),
          this.escapeCSV(this.getZone(item.zoneId)),
          this.escapeCSV(item.date),
          this.escapeCSV(this.hs.translate(item.mainProductName)),
          this.escapeCSV(item.toppingNames.join(', ')),
          this.escapeCSV(item.clientName),
          this.escapeCSV(item.isPriority ? 'Sí' : 'No'),
          this.escapeCSV(item.servicePickupAddress),
          this.escapeCSV(item.serviceDeliveryAddress),

          this.escapeCSV(
            item.comment.length == 0 ? 'Sin comentarios' : item.comment
          ),
        ].join(',')
      );
    });

    const csvFile = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvFile);

    const a = document.createElement('a');
    a.href = csvUrl;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  exportToXLSX() {
    let pendingServices = [...this.pendingServices];

    if (this.selectedClient != '#') {
      pendingServices = pendingServices.filter(
        (item: any) => item.clientName === this.selectedClient
      );
    }

    if (this.selectedProduct != '#') {
      pendingServices = pendingServices.filter(
        (item: any) =>
          this.hs.translate(item.mainProductName) === this.selectedProduct
      );
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      pendingServices.map((item) => ({
        Mátricula: item.licensePlate,
        Hora: `${item.startHour} - ${item.endHour}`,
        'Order Hash': item.orderHash,
        Zona: this.getZone(item.zoneId),
        Fecha: item.date,
        Producto: this.hs.translate(item.mainProductName),
        Toppings: item.toppingNames.join(', '),
        Clientes: item.clientName,
        Prioritario: item.isPriority ? 'Sí' : 'No',
        'Dirección de Recogida': item.servicePickupAddress,
        'Dirección de Entrega': item.serviceDeliveryAddress,
        Comentarios:
          item.comment.length == 0 ? 'Sin comentarios' : item.comment,
      })),
      {
        header: [
          'Mátricula',
          'Hora',
          'Order Hash',
          'Zona',
          'Fecha',
          'Producto',
          'Toppings',
          'Clientes',
          'Prioritario',
          'Dirección de Recogida',
          'Dirección de Entrega',
          'Comentarios',
        ],
      }
    );

    // Ajustar automáticamente el ancho de las columnas
    const wscols = [
      { wch: 15 }, // Mátricula
      { wch: 10 }, // Hora
      { wch: 15 }, // Order Hash
      { wch: 20 }, // Zona
      { wch: 10 }, // Fecha
      { wch: 20 }, // Producto
      { wch: 20 }, // Toppings
      { wch: 20 }, // Clientes
      { wch: 10 }, // Prioritario
      { wch: 30 }, // Dirección de Recogida
      { wch: 30 }, // Dirección de Entrega
      { wch: 20 }, // Comentarios
    ];

    ws['!cols'] = wscols;

    // Aplicar ajuste de texto en celdas específicas
    for (const cell in ws) {
      if (cell.startsWith('J') || cell.startsWith('K')) {
        // Columnas de dirección
        if (ws[cell] && typeof ws[cell] === 'object') {
          ws[cell].s = { alignment: { wrapText: true } };
        }
      }
    }

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout: ArrayBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'data.xlsx'
    );
  }

  escapeCSV(value: string): string {
    if (value.includes('"')) {
      value = value.replace(/"/g, '""');
    }
    if (value.includes(',') || value.includes('\n') || value.includes('"')) {
      value = `"${value}"`;
    }
    return value;
  }

  sanitizeForExcel(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    return value.replace(/"/g, '""');
  }

  updateExportCount() {
    this.exportCount = 0;
    if (this.isXlsxSelected) this.exportCount++;
    if (this.isCsvSelected) this.exportCount++;
  }

  exportTo() {
    if (this.isXlsxSelected) {
      this.exportToXLSX();
    }
    if (this.isCsvSelected) {
      this.exportToCSV();
    }
  }

  openModalDuplicateService() {
    const offcanvasRef = this.offcanvasService.open(
      ModalDuplicateServiceComponent,
      {
        backdrop: 'static',
        position: 'end',
      }
    );
    offcanvasRef.componentInstance.type_info = 'ad';
  }

  openModalEditService(service: any) {
    const momentDate = moment(service.date, 'DD/MM/YYYY');
    const date = momentDate.toDate();

    const prefilledData = {
      date: date,
      hour: service.startHour,
      zone: this.getZone(service.zoneId),
      vertical: this.getVertical(service.verticalType),
      subproduct: 'Subproduct 1',
      product: service.mainProductName,
      origin_address: service.servicePickupAddress,
      delivery_address: service.serviceDeliveryAddress,
      client_contact: service.clientName,
      provider_contact: 'Provider Contact',
      orderHash: service.orderHash,
    };

    this.hs.getServiceDetail(service.orderHash).subscribe((response: any) => {
      const service_info = response;
      const offcanvasRef = this.offcanvasService.open(
        ModalDuplicateServiceComponent,
        {
          backdrop: 'static',
          position: 'end',
        }
      );
      console.log(service_info);
      offcanvasRef.componentInstance.formData = prefilledData;
      offcanvasRef.componentInstance.service_info = service_info;
      offcanvasRef.componentInstance.type_info = 'ed';
    });
  }

  viewDetail(data: any) {
    const dataToSend = {
      orderHash: data.orderHash,
    };
    this.hs.setData(dataToSend);

    this.router.navigate(['service-manager/detail-service'], {
      queryParams: { orderHash: data.orderHash },
    });
  }

  onSort(event: any): void {
    this.sortDirection = event;
  }

  sortData(): void {
    this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    console.log(this.paginatedData, 'DATA22');

    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });

    console.log(this.paginatedData, 'DATA1');
  }

  setSortColumn(column: string): void {
    console.log(column);
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }

  async filterByOldParams() {
    this.index = 0;
    this.showLoader();
    this.filterDisabled = true;
    if (!this.all_clients) {
      this.hs.getClients().subscribe((res: any) => {
        this.all_clients = res.businessUserData;
      });
    }
    for (let i = 0; i < this.datesArray.length; i++) {
      const element = this.datesArray[i];
      const serviceData = {
        date: { seconds: element },
        zoneId: this.selectedZone,
      };
      try {
        const res: any = await firstValueFrom(
          this.servicesSvc.getServices(serviceData)
        );
        if (res.services) {
          const pending = res.services.filter(
            (service: any) => service.orderStatus === 'PENDING'
          );
          pending.forEach((service: any) => {
            service.startHour = service.serviceStartDate.seconds
              ? this.formatTime(
                  service.serviceStartDate.seconds, service.zoneId)
              : ' no definido';
            service.endHour = service.serviceEndDate
              ? this.formatTime(
                  service.serviceEndDate.seconds, service.zoneId
                )
              : ' no definido';
            service.zoneName = this.getZone(service.zoneId);
            service.formattedDate = this.getDate(
              service.serviceStartDate.seconds,
              service.zoneId
            );
            this.pendingServices.push(service);
          });
        }
      } catch (error) {
        this.hideLoader();
        console.error('Error fetching services', error);
      }

      this.clients = [
        ...new Set(this.pendingServices.map((item: any) => item.clientName)),
      ];
      this.products = [
        ...new Set(
          this.pendingServices.map((item: any) =>
            this.hs.translate(item.mainProductName)
          )
        ),
      ];
    }
    this.paginatedData = this.pendingServices;

    const applyFilters = () => {
      if (this.selectedClient.length > 0) {
        this.paginatedData = this.paginatedData.filter((item: any) =>
          this.selectedClient.includes(item.clientId)
        );
      }

      if (this.selectedProduct.length > 0) {
        this.paginatedData = this.paginatedData.filter((item: any) =>
          this.selectedProduct.includes(this.hs.translate(item.mainProductName))
        );
      }
    };

    applyFilters();

    if (this.searchTerm) {
      const search = this.searchTerm.toLowerCase();
      this.paginatedData = this.paginatedData.filter((item: any) => {
        const license = item.licensePlate.toLowerCase();
        const orderHash = item.orderHash.toLowerCase();
        return license.includes(search) || orderHash.includes(search);
      });
    }

    this.length = Math.ceil(this.paginatedData.length / this.pageSize);

    const start = this.index * this.pageSize;
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);
    this.filterDisabled = false;
    this.hideLoader();
  }
}

import {
  Component,
  EventEmitter,
  Inject,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TuiHostedDropdownComponent, TuiAlertService } from '@taiga-ui/core';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiCountryIsoCode } from '@taiga-ui/i18n';
import { AssignerService } from 'src/app/services/assigner/assigner.service';
import { firstValueFrom } from 'rxjs';
import { ServicesService } from 'src/app/services/service/services.service';
import { TranslateService } from '@ngx-translate/core';

declare var moment: any;
@Component({
  selector: 'app-assigner-table',
  templateUrl: './assigner-table.component.html',
  styleUrls: ['./assigner-table.component.css'],
})
export class AssignerTableComponent {
  //modal reference cesar-gutierrez
  bsModalRef?: BsModalRef;
  @ViewChild('warning_assigner_modal')
  warning_assigner_modal!: TemplateRef<any>;
  operativesZones = ['Madrid', 'Barcelona', 'Valencia'];

  operatorsSelect = ['Interno', 'Externo'];

  rolesSelect = ['Gerente', 'Supervisor de Mantenimiento', 'Coordinador'];

  services = ['Servicio 1', 'Servicio 2', 'Servicio 3'];

  hours = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);
  hours2 = tuiCreateTimePeriods(10, 20, [0, 15, 30, 45]);

  readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];

  readonly countriesPhoneExtra: ReadonlyArray<TuiCountryIsoCode> = [
    TuiCountryIsoCode.ES,
    TuiCountryIsoCode.GB,
    TuiCountryIsoCode.FR,
  ];
  uuidRegex: any = /^[0-9a-fA-F\-]+$/;
  countryIsoCode = TuiCountryIsoCode.ES;
  countryIsoCodeExtra = TuiCountryIsoCode.ES;
  exportCount: number = 0;
  isXlsxSelected: boolean = false;
  isCsvSelected: boolean = false;
  searchTerm: string = '';
  div_loder = document.getElementById('loader');
  assignerData: any[] = [];
  executorData: any[] = [];
  sortedColumn: string = '';
  sortDirection!: any;
  toApplyAssignamentId!: any;
  toApplyAssignamentDate!: any;
  filterCount: number = 0;
  paginatedData: any[] = [];
  readonly sizes = ['l', 'm', 's'] as const;
  size = this.sizes[0];
  zones!: any;
  countrys!: any[];
  operators!: string[];
  roles!: string[];
  selectedZone!: any;
  selectedStatus: string = '#';
  selectedCountry: string = '#';
  selectedRole: string = '#';
  @Output() assignamentId = new EventEmitter<string>();
  index: number = 0;
  length: number = 0;
  pageSize: number = 50;

  test = {
    executionId: 'e32aa701-3b46-4ed5-c352-08dce4ab6d6c',
    executionDate: {
      seconds: '1728070410',
      nanos: 0,
    },
    executorId: 'df29dab1-ac1b-4aff-8579-f4d4f130df40',
    zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
    executionTargetDate: {
      seconds: '1728259200',
      nanos: 0,
    },
    status: 'InProgress',
    doWeHaveSweepers: false,
  };

  readonly columns = [
    'date',
    'time',
    'executedBy',
    'status',
    'countryAssignment',
    'zoneAssignment',
    'assignmentDate',
    'actions',
  ];

  /*     readonly data = DATA;

readonly columns = ['name', 'dob', 'age'];

readonly getAge = getAge;

readonly ageSorter: TuiComparator<User> = (a: User, b: User) => getAge(a) - getAge(b); */

  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  readonly items = ['Edit', 'Download', 'Rename', 'Delete'];

  readonly selectItems = ['Item 1', 'Item 2'];

  open = false;

  selected = null;

  onClick(): void {
    this.open = false;
    this.component?.nativeFocusableElement?.focus();
  }

  constructor(
    @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
    private servicesSvc: ServicesService,
    private renderer: Renderer2,
    private assignerService: AssignerService,
    private translate: TranslateService,
    private modalService: BsModalService
  ) {}

  async ngOnInit() {
    /*     this.assignerService.getAssignments().subscribe((res: any) => {
      console.log(res);
    }); */
    this.showLoader();
    try {
      const assignaments: any = await firstValueFrom(
        this.assignerService.getAssignments()
      );

      this.zones = await firstValueFrom(this.servicesSvc.getZones());
      this.zones = this.zones.zones;
      const promises = assignaments.executionModels.map(
        async (execution: any) => {
          execution.executionDateFormatted = this.getDate(
            execution.executionDate.seconds,
            execution.zoneId
          );
          execution.hour = this.formatTime(execution.executionDate.seconds);
          execution.assignmentDateFormatted = this.getDate(
            execution.executionTargetDate.seconds,
            execution.zoneId
          );
          execution.zoneName = this.getZone(execution.zoneId);

          if (this.uuidRegex.test(execution.executorId)) {
            const executor = await this.servicesSvc.acquireMSClient(
              execution.executorId
            );
            execution.executorName = executor
              ? executor.displayName
              : 'Nombre no disponible';
          } else {
            execution.executorName = execution.executorId;
          }

          // const executor = await this.servicesSvc.acquireMSClient(
          //   execution.executorId
          // );

          // execution.executorName =
          //   executor?.displayName || 'Nombre no disponible';

          return execution;
        }
      );

      try {
        this.assignerData = await Promise.all(promises);
        console.log(this.assignerData, 'DATA');
      } catch (error) {
        console.error('Error occurred:', error);
      }
      // const test = {
      //   executionId: 'e32aa701-3b46-4ed5-c352-08dce4ab6d6c',
      //   executionDate: {
      //     seconds: '1735757449',
      //     nanos: 0,
      //   },
      //   executorId: 'df29dab1-ac1b-4aff-8579-f4d4f130df40',
      //   zoneId: '990fdc23-543d-4840-90f6-3757cea5790d',
      //   executionTargetDate: {
      //     seconds: '1735757449',
      //     nanos: 0,
      //   },
      //   status: 'InProgress',
      //   doWeHaveSweepers: false,
      //   executionDateFormatted: '01/01/2025',
      //   hour: '15:33',
      //   assignmentDateFormatted: '01/01/2025',
      //   zoneName: 'Venezuiela',
      //   executorName: 'Cesar Farfan',
      // };
      // this.assignerData.push(test);
      this.assignerData.reverse();
      this.length = Math.ceil(this.assignerData.length / this.pageSize);
      this.paginatedDataOperation();
      this.hideLoader();
    } catch (error) {
      this.hideLoader();
    }
  }

  showDepositAlert(): void {
    this.alerts
      .open('El usuario se ha creado correctamente', {
        label: 'Usuario creado',
        status: 'success',
        autoClose: true,
      })
      .subscribe();
  }

  paginatedDataOperation() {
    this.filterCount = this.assignerData.length;
    const start = this.index * this.pageSize;
    this.paginatedData = this.assignerData.slice(start, start + this.pageSize);
  }

  goToPage(pageIndex: number) {
    this.index = pageIndex;
    this.filterDataBySelect(pageIndex);
  }

  filterData(searchText: string) {
    this.index = 0;
    if (!searchText) {
      this.searchTerm = '';
      this.paginatedData = this.assignerData;
      if (this.selectedZone) {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.zoneAssignment === this.selectedZone
        );
      }
      this.filterCount = this.paginatedData.length;
      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    } else {
      this.searchTerm = searchText;
      this.paginatedData = this.assignerData;

      if (this.selectedZone) {
        this.paginatedData = this.paginatedData.filter(
          (item: any) => item.zoneAssignment === this.selectedZone
        );
      }
      this.filterCount = this.paginatedData.length;
      this.paginatedData = this.paginatedData.filter((item: any) => {
        const fullName = item.executorName.toLowerCase();
        const search = searchText.toLowerCase();
        return fullName.includes(search);
      });
      this.filterCount = this.paginatedData.length;
      this.length = Math.ceil(this.paginatedData.length / this.pageSize);
      const start = this.index * this.pageSize;
      this.paginatedData = this.paginatedData.slice(
        start,
        start + this.pageSize
      );
    }
  }

  filterDataBySelect(index?: any) {
    this.paginatedData = this.assignerData;
    this.index = index ?? 0;

    const search = this.searchTerm ? this.searchTerm.toLowerCase() : '';

    this.paginatedData = this.assignerData.filter((item: any) => {
      const fullName = item.executorId.toLowerCase();
      const matchesSearch = !this.searchTerm || fullName.includes(search);

      const matchesZone =
        !this.selectedZone || item.zoneId === this.selectedZone;

      return matchesSearch && matchesZone;
    });
    this.sortByExec();
    this.applyPagination();
  }

  applyPagination() {
    this.filterCount = this.paginatedData.length;
    const start = this.index * this.pageSize;
    this.length = Math.ceil(this.paginatedData.length / this.pageSize);
    this.paginatedData = this.paginatedData.slice(start, start + this.pageSize);

    if (this.length == 1) {
      this.index = 0;
    }
  }

  getZone(zoneId: string) {
    const selectedZone = this.zones.find((zone: any) => zone.zoneId === zoneId);

    return selectedZone.zoneName;
  }

  getDate(seconds: any, zoneId: any) {
    const zone = this.zones.find((zone: any) => zone.zoneId === zoneId);

    const dateInClientZone = moment.unix(seconds).utc();

    return dateInClientZone.format('DD/MM/YYYY');
  }

  formatTime(date: any) {
    const dateCovert = new Date(parseInt(date) * 1000);
    const hours = String(dateCovert.getHours()).padStart(2, '0');
    const minutes = String(dateCovert.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  formatTime2(date: any, zoneId: any) {
    const zone = this.zones.find((zone: any) => zone.zoneId === zoneId);

    return moment.unix(date).tz(zone.timeZone).format('HH:mm');
  }
  async refresh() {
    this.showLoader();
    try {
      const assignaments: any = await firstValueFrom(
        this.assignerService.getAssignments()
      );

      this.zones = await firstValueFrom(this.servicesSvc.getZones());
      this.zones = this.zones.zones;
      const promises = assignaments.executionModels.map(
        async (execution: any) => {
          execution.executionDateFormatted = this.getDate(
            execution.executionDate.seconds,
            execution.zoneId
          );
          execution.hour = this.formatTime(execution.executionDate.seconds);
          execution.assignmentDateFormatted = this.getDate(
            execution.executionTargetDate.seconds,
            execution.zoneId
          );
          execution.zoneName = this.getZone(execution.zoneId);

          const executor = await this.servicesSvc.acquireMSClient(
            execution.executorId
          );

          execution.executorName =
            executor?.displayName || 'Nombre no disponible';

          return execution;
        }
      );

      try {
        this.assignerData = await Promise.all(promises);
        console.log(this.assignerData, 'DATA');
      } catch (error) {
        console.error('Error occurred:', error);
      }
      this.length = Math.ceil(this.assignerData.length / this.pageSize);
      this.paginatedDataOperation();
      this.hideLoader();
    } catch (error) {
      this.hideLoader();
    }
  }

  showLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'flex');
    }
  }

  hideLoader() {
    if (this.div_loder) {
      this.renderer.setStyle(this.div_loder, 'display', 'none');
    }

    setTimeout(() => {
      const closeDiv = document.querySelector('.t-close');
      if (closeDiv) {
        (closeDiv as HTMLElement).click();
      }
    }, 5000);
  }

  updateExportCount() {
    this.exportCount = 0;
    if (this.isXlsxSelected) this.exportCount++;
    if (this.isCsvSelected) this.exportCount++;
  }

  exportTo() {
    if (this.isXlsxSelected) {
      this.exportToXLSX();
    }
    if (this.isCsvSelected) {
      this.exportToCSV();
    }
  }

  exportToXLSX() {
    let assignerData = [...this.assignerData];

    if (this.selectedZone) {
      assignerData = assignerData.filter(
        (item: any) => item.zoneId === this.selectedZone
      );
    }

    if (this.searchTerm) {
      assignerData = assignerData.filter((item: any) => {
        const fullName = item.executorName.toLowerCase();
        const search = this.searchTerm.toLowerCase();
        return fullName.includes(search);
      });
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      assignerData.map((item) => ({
        Fecha: this.getDate(item.executionTargetDate.seconds, item.zoneId),
        Hora: this.formatTime(item.executionTargetDate.seconds),
        'Ejecutado por': item.executorId,
        Estado: item?.status,
        'Zona asignacion': this.getZone(item.zoneId),
        'Fecha asignacion': this.getDate(
          item.executionDate.seconds,
          item.zoneId
        ),
      })),
      {
        header: [
          'Fecha',
          'Hora',
          'Ejecutado por',
          'Estado',
          'Zona asignacion',
          'Fecha asignacion',
        ],
      }
    );

    // Ajustar automáticamente el ancho de las columnas
    const wscols = [
      { wch: 15 }, // Mátricula
      { wch: 10 }, // Hora
      { wch: 15 }, // Order Hash
      { wch: 20 }, // Zona
      { wch: 10 }, // Fecha
      { wch: 20 }, // Producto
    ];

    ws['!cols'] = wscols;

    // Aplicar ajuste de texto en celdas específicas
    for (const cell in ws) {
      if (cell.startsWith('J') || cell.startsWith('K')) {
        // Columnas de dirección
        if (ws[cell] && typeof ws[cell] === 'object') {
          ws[cell].s = { alignment: { wrapText: true } };
        }
      }
    }

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout: ArrayBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'data.xlsx'
    );
  }

  exportToCSV() {
    const csvRows = [];

    csvRows.push(
      [
        'Fecha',
        'Hora',
        'Ejecutado por',
        'Estado',
        'Zona asignacion',
        'Fecha asignacion',
      ]
        .map((header) => this.escapeCSV(header))
        .join(',')
    );

    let assignerData = [...this.assignerData];

    if (this.selectedZone) {
      assignerData = assignerData.filter(
        (item: any) => item.zoneId === this.selectedZone
      );
    }
    if (this.searchTerm) {
      assignerData = assignerData.filter((item: any) => {
        const fullName = item.executorName.toLowerCase();
        const search = this.searchTerm.toLowerCase();
        return fullName.includes(search);
      });
    }

    assignerData.forEach((item: any) => {
      csvRows.push(
        [
          this.escapeCSV(
            this.getDate(item.executionTargetDate.seconds, item.zoneId)
          ),
          this.escapeCSV(this.formatTime(item.executionTargetDate.seconds)),
          this.escapeCSV(item.executorId),
          this.escapeCSV(item?.status),
          this.escapeCSV(this.getZone(item.zoneId)),

          this.escapeCSV(this.getDate(item.executionDate.seconds, item.zoneId)),
        ].join(',')
      );
    });

    const csvFile = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvFile);

    const a = document.createElement('a');
    a.href = csvUrl;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  escapeCSV(value: string): string {
    if (value.includes('"')) {
      value = value.replace(/"/g, '""');
    }
    if (value.includes(',') || value.includes('\n') || value.includes('"')) {
      value = `"${value}"`;
    }
    return value;
  }

  async applyAssignament(executionId: string) {
    const assignament = {
      executionId: executionId,
      forcefulOverride: true,
    };

    this.closeModal();
    this.toApplyAssignamentId = '';
    this.toApplyAssignamentDate = '';

    try {
      this.showLoader();
      const result = await firstValueFrom(
        this.assignerService.applyAssignment(assignament)
      );

      await this.refresh();
      this.hideLoader();
      console.log(result);
    } catch (error) {
      this.hideLoader();
      this.assignerService.publish('errorApplyAssign', assignament);
      console.error('Error al agregar la asignación:', error);
    }
  }

  tryApplyAssignament(assignament: any) {
    // const foundAssignament = this.assignerData.find((obj: any) => {
    //   obj.status === 'Completed' &&
    //     obj.assignmentDateFormatted === assignament.assignmentDateFormatted &&
    //     obj.zoneName === assignament.zoneName;
    // });

    const foundAssignament = this.assignerData.find((obj: any) => {
      const assignmentDate = moment(
        assignament.assignmentDateFormatted,
        'DD/MM/YYYY'
      );
      const objDate = moment(obj.assignmentDateFormatted, 'DD/MM/YYYY');

      if (
        (obj.status === 'InProgress' || obj.status === 'Applied') &&
        assignmentDate.isSame(objDate, 'day') &&
        obj.zoneName == assignament.zoneName
      ) {
        return true;
      }
      return false;
    });

    if (foundAssignament) {
      this.toApplyAssignamentId = assignament.executionId;
      this.toApplyAssignamentDate = `${
        assignament.zoneName + ',' + assignament.assignmentDateFormatted
      }`;
      this.openModal(this.warning_assigner_modal);
    } else {
      this.applyAssignament(assignament.executionId);
    }
  }

  onSort(event: any): void {
    this.sortDirection = event;
  }

  sortData(): void {
    this.filterDataBySelect(this.index);
  }

  sortByExec(): void {
    this.paginatedData = [...this.paginatedData].sort((a, b) => {
      const valueA = a[this.sortedColumn];
      const valueB = b[this.sortedColumn];

      // Verificar si la columna es una de las que contiene fechas
      const isDateColumn =
        this.sortedColumn === 'executionDateFormatted' ||
        this.sortedColumn === 'assignmentDateFormatted';

      if (isDateColumn) {
        // Función para convertir el formato dd/MM/yyyy a yyyy-MM-dd
        const convertToDate = (dateStr: string): Date => {
          const [day, month, year] = dateStr
            .split('/')
            .map((num) => parseInt(num, 10));
          return new Date(year, month - 1, day); // Mes en JavaScript es 0-indexado
        };

        // Convertir las fechas a objetos Date
        const dateA = convertToDate(valueA);
        const dateB = convertToDate(valueB);

        // Verificar si las fechas son válidas
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          // Si alguna fecha no es válida, devolver 0 para no cambiar el orden
          return 0;
        }

        // Comparar fechas
        if (dateA < dateB) {
          return -this.sortDirection;
        }
        if (dateA > dateB) {
          return this.sortDirection;
        }
        return 0;
      }

      // Comparación de valores no fecha (por defecto)
      if (valueA < valueB) {
        return -this.sortDirection;
      }
      if (valueA > valueB) {
        return this.sortDirection;
      }
      return 0;
    });
  }

  setSortColumn(column: string): void {
    if (this.sortedColumn === column) {
      this.sortDirection = this.sortDirection == -1 ? 1 : -1;
    } else {
      this.sortedColumn = column;
      this.sortDirection = -1;
    }
    this.sortData();
  }

  viewAssignamentDetails(id: string) {
    this.assignamentId.emit(id);
  }

  openModal(warningModal: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(warningModal);
  }
  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
}

<div class="table-body mt-5">
  <div class="table-body-head mb-3">
    <div>
      <label class="table-title">{{ 'assigner.title_list' | translate }}</label>
    </div>
    <tui-hosted-dropdown
      tuiDropdownLimitWidth="auto"
      [content]="download_options"
      [(open)]="open"
    >
      <label class="table-title-filter">Filter ({{ filterCount }})</label>
      <button tuiHostedDropdownHost class="export-button">
        <img
          class="img-icon"
          src="../../../assets/icons/download-Icon.svg"
          alt=""
        />
        {{ 'assigner.export_text' | translate }} (.csv, xls..)
      </button>
    </tui-hosted-dropdown>
  </div>
  <div class="table-body-options mb-3">
    <div class="search-bar">
      <img
        src="../../../assets/icons/search- icon.svg"
        alt="Search Icon"
        class="search-icon"
      />
      <input
        #searchInput
        [(ngModel)]="searchTerm"
        type="text"
        placeholder="Buscar personal por nombre y apellidos..."
        class="search-bar-input"
      />
    </div>
    <button
      (click)="filterData(searchTerm)"
      appearance="primary-button"
      size="s"
      tuiButton
    >
      <tui-svg src="tuiIconSearch"></tui-svg>
      {{ 'assigner.search_list_text' | translate }}
    </button>
    <!-- <select class="form-select table-option-select" aria-label="zone" [(ngModel)]="selectedZone" (ngModelChange)="filterDataBySelect()">
         <option  selected value="#">Zona</option>
         <option *ngFor="let zone of zones" [value]="zone.zoneId">{{ zone.zoneName }}</option>
       </select> -->

    <ng-select
      [items]="zones"
      bindLabel="zoneName"
      bindValue="zoneId"
      [multiple]="false"
      [(ngModel)]="selectedZone"
      class="custom_select"
      (ngModelChange)="filterDataBySelect()"
      placeholder="Zona"
      [dropdownPosition]="'bottom'"
    >
    </ng-select>
  </div>
  <div class="overflow-auto">
    <table
      tuiTable
      size="l"
      style="width: 100%"
      [columns]="columns"
      (directionChange)="onSort($event)"
    >
      <thead>
        <tr tuiThGroup>
          <th
            class="th-style"
            *tuiHead="'date'"
            tuiTh
            (click)="setSortColumn('executionDateFormatted')"
            [sticky]="true"
          >
            {{ 'assigner.date_excute_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'time'"
            tuiTh
            (click)="setSortColumn('hour')"
          >
            {{ 'assigner.hour_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'executedBy'"
            tuiTh
            (click)="setSortColumn('executorId')"
          >
            {{ 'assigner.executor_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'status'"
            tuiTh
            (click)="setSortColumn('status')"
          >
            {{ 'assigner.status_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'zoneAssignment'"
            (click)="setSortColumn('zoneName')"
            tuiTh
          >
            {{ 'assigner.zone_input_table' | translate }}
          </th>
          <th
            [sticky]="true"
            *tuiHead="'assignmentDate'"
            (click)="setSortColumn('assignmentDateFormatted')"
            tuiTh
          >
            {{ 'assigner.date_assigned_input_table' | translate }}
          </th>
          <th [sticky]="true" *tuiHead="'actions'" tuiTh></th>
        </tr>
      </thead>
      <tbody tuiTbody>
        <tr tuiTr *ngFor="let item of paginatedData">
          <td *tuiCell="'date'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.executionDateFormatted }}
              </span>
            </div>
          </td>
          <td *tuiCell="'time'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.hour }}
                <!--  {{formatTime2(item.executionTargetDate.seconds, item.zoneId)}}   -->
              </span>
            </div>
          </td>
          <td *tuiCell="'executedBy'" tuiTd>
            <div [tuiCell]="size">
              <tui-avatar
                [src]="item.executorName | tuiInitials"
                [style.background]="item.executorName | tuiAutoColor"
              ></tui-avatar>
              <span tuiTitle>
                {{ item.executorName }}
                <!--    <span tuiSubtitle>silly@walk.uk</span> -->
              </span>
            </div>
          </td>

          <td *tuiCell="'status'" tuiTd>
            <div>
              <div *ngIf="item?.status == 'InProgress'">
                <tui-badge
                  status="success"
                  value="En proceso"
                  class="custom_picking_up tui-space_right-2 tui-space_bottom-2"
                ></tui-badge>
              </div>
            </div>
            <div *ngIf="item?.status == 'Completed'">
              <tui-badge
                status="success"
                value="Completado"
                class="custom_finished tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
            <div *ngIf="item?.status == 'Failed'">
              <tui-badge
                status="error"
                value="Fallido"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
            <div *ngIf="item?.status == 'Applied'">
              <tui-badge
                status="info"
                value="Aplicado"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
            <div *ngIf="item?.status == 'Initialized'">
              <tui-badge
                status="warning"
                value="Inicializado"
                class="tui-space_right-2 tui-space_bottom-2"
              ></tui-badge>
            </div>
          </td>

          <td *tuiCell="'zoneAssignment'" tuiTd>
            <div [tuiCell]="size">
              <span tuiTitle>
                {{ item.zoneName }}
              </span>
            </div>
          </td>
          <td *tuiCell="'assignmentDate'" tuiTd>
            <span tuiTitle>
              {{ item.assignmentDateFormatted }}
            </span>
          </td>
          <td *tuiCell="'actions'" tuiTd>
            <span class="seeAllIcon" tuiStatus>
              <button
                (click)="tryApplyAssignament(item)"
                [disabled]="item.status != 'Completed' ? true : false"
                appearance="primary-button"
                size="s"
                tuiButton
              >
                {{ 'assigner.apply_text' | translate }}
              </button>
              <!-- <button
                (click)="viewAssignamentDetails('id')"
                class="seeAllIcon"
                appearance="link"
                icon="tuiIconEye"
                size="xs"
                tuiAppearance="link"
                tuiIconButton
              >
                More
              </button> -->
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="table-pagination mt-5">
    <tui-pagination
      [index]="index"
      [length]="length"
      (indexChange)="goToPage($event)"
    ></tui-pagination>
  </div>
</div>
<ng-template #download_options let-activeZone>
  <div class="download-menu">
    <label class="mb-2 export-title">{{ 'assigner.complement_export_text' | translate }}</label>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isXlsxSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.xlsx)</label>
    </div>

    <div class="d-flex mb-2 export-body-label">
      <tui-checkbox
        [(ngModel)]="isCsvSelected"
        (ngModelChange)="updateExportCount()"
        class="tui-space_bottom-3"
      ></tui-checkbox>
      <label>Excel(.csv)</label>
    </div>

    <button (click)="exportTo()" class="export-button-menu mb-2 mt-1">
      <img
        class="img-icon"
        src="../../../assets/icons/download-Icon.svg"
        alt=""
      />
      {{ 'assigner.export_text' | translate }} ({{ exportCount }})
    </button>
  </div>
</ng-template>

<ng-template #warning_assigner_modal>
  <!-- Modal -->
  <div id="myModal" class="modal-content">
    <div class="modal-header-custom">
      <div>Sobreescribir asignación</div>
    </div>
    <div class="modal-body-custom mt-2">
      Ya existe una asignación previa para
      <span class="fw-bold"> {{ toApplyAssignamentDate }} </span> . Si continúa,
      se sobreescribirá por la nueva asignación que se va a lanzar. Esta acción
      es irreversible.
    </div>
    <div class="modal-footer-custom">
      <div (click)="closeModal()" class="cancel-over-write-button">Volver</div>
      <div
        (click)="applyAssignament(toApplyAssignamentId)"
        class="over-write-button"
      >
        Sobreescribir
      </div>
    </div>
  </div>
</ng-template>

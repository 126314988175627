import { Injectable } from '@angular/core';
import { LocalStorageKeys } from 'src/app/enum/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Guardar un valor en localStorage con una clave específica.
   */
  set<T>(key: LocalStorageKeys, value: T): void {
    try {
      if (typeof value === 'undefined') {
        console.warn(
          `LocalStorageService: Trying to set undefined value for key "${key}"`
        );
        return;
      }

      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`LocalStorageService: Failed to set key "${key}"`, error);
    }
  }

  /**
   * Obtener un valor desde localStorage.
   */
  // get<T>(key: LocalStorageKeys): T | null {
  //   try {
  //     const value = localStorage.getItem(key);
  //     if (value === null) {
  //       return null;
  //     }
  //
  //     return JSON.parse(value) as T;
  //   } catch (error) {
  //     console.error(`LocalStorageService: Failed to get key "${key}"`, error);
  //     return null;
  //   }
  // }

  get<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      // Intenta parsear el valor
      return JSON.parse(item) as T;
    } catch (error) {
      // Si falla el parseo, asumimos que es una cadena ya
      return item as unknown as T;
    }
  }

  /**
   * Eliminar un valor desde localStorage.
   */
  remove(key: LocalStorageKeys): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(
        `LocalStorageService: Failed to remove key "${key}"`,
        error
      );
    }
  }

  /**
   * Limpiar todos los valores de localStorage.
   */
  clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('LocalStorageService: Failed to clear storage', error);
    }
  }

  /**
   * Verifica si existe una clave en localStorage.
   */
  exists(key: LocalStorageKeys): boolean {
    return localStorage.getItem(key) !== null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateEnum'
})
export class TranslateEnumPipe implements PipeTransform {

  private readonly translationMap: { [key: string]: string } = {
    'NOT_SPECIFIED': 'No Especificado',
    'REGULAR': 'Regular',
    'LONG_DISTANCE': 'Larga Distancia',
    'BROOM_CAR': 'Coche Escoba',
    'MOBILE_WASHER': 'Lavador Móvil',
    'IMPLANT_WASHER': 'Lavador Implantado',
    'IMPLANT_OFFICE': 'Oficina Implantada',
    'NOT_ALLOWED': 'No Permitido',
    'UNDER_125': 'Menor de 125cc',
    'OVER_125': 'Mayor de 125cc'
  };

  transform(value: string): string {
    return this.translationMap[value] || value;
  }
}
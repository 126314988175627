import { Component, AfterViewInit, ViewChild, ElementRef, ChangeDetectionStrategy, Input, TemplateRef, Inject, Renderer2, NgZone } from '@angular/core';
import {} from 'googlemaps';
import {Subject} from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl } from '@angular/forms';
import { MapaServiceService } from 'src/app/services/mapa/mapa-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
declare var MarkerClusterer: any;
declare var OverlappingMarkerSpiderfier: any;
declare var moment: any;
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css'],
})
export class MapaComponent {
    @ViewChild('gmapContainer', {static: false}) gmap: ElementRef| undefined;
    map: any = google.maps.Map;
    lat = 40.4165; 
    lng = -3.70256;
    zoom_level = 6;
    coordinates = new google.maps.LatLng(this.lat, this.lng);
    mapOptions: google.maps.MapOptions = { 
        center: this.coordinates,
        zoom: 6,
        disableDefaultUI: true 
    };
    open = false;
    selectedItemFilter = 'services';
    count_unsigned_services = 0;
    count_drivers:any = 0;
    date:any = Math.floor(new Date('2024-08-09').getTime() / 1000);
    show_loader = false;

    active_inside_service_div = 'closets';
    mapInitializer() {
        this.map = new google.maps.Map(this.gmap?.nativeElement, this.mapOptions);
    }
    modalRef?: BsModalRef;
    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private ms: MapaServiceService,
        private modalService: BsModalService,
        private renderer: Renderer2,
        public hs:HistoryServicesService,
        private ngZone: NgZone,
        private translate: TranslateService,
    ) {}

    filterForm = this.fb.group({
        zoneFilter: ['#'],
        mapfilter: [''],
        dateFilter:''
    });

    filterDriverServiceForm = this.fb.group({
        driverservicefilter:''
    });

    //data-dummy para lista de servicios
        elements_services:any = [];
        filtered_services:any = [];
    //

    //data-dummy para lista de conductores
        elements_drivers:any = [];
        filtered_drivers:any = [];

    leftDivStyles = { 
        x:440
    };
    expandDivLeft = true;
    show_left_div = true;
    zones:any;
    service_data:any;
    count_drivers_closets = 0;

    active_detail_service:any;
    active_driver:any;

    drivers_for_service:any = [];
    selectedZone:any = '#';

    PointsMarkers: any = [];
    dateFilter:any;
    show_assign_services = 0;
    active_desasign_driver:any;

    VehicleChassisType = [
        {
            value:1,
            name:'SEDAN',
            real_name:'Turismo'
        },
        {
            value:2,
            name:'MINIVAN',
            real_name:'Monovolumen'
        },
        {
            value:3,
            name:'ATV',
            real_name:'Todoterreno'
        },
        {
            value:4,
            name:'LIGHT_VAN',
            real_name:'Furgoneta ligera'
        },
        {
            value:5,
            name:'VAN',
            real_name:'Furgoneta'
        },
        {
            value:6,
            name:'BOX_TRUCK',
            real_name:'Furgón'
        },
        {
            value:20,
            name:'SCOOTER',
            real_name:'Moto (hasta 125cc)'
        },
        {
            value:21,
            name:'MOTORBIKE',
            real_name:'Moto +125cc'
        },
    ];

    markerCluster:any;
    directionsRenderer:any;
    provider_address:any = '';

    getTypeChassisByType(vehicleName:any){
        const selectedVehicle = this.VehicleChassisType.find((type:any) => type.name === vehicleName);
        return selectedVehicle?.real_name;
    }

    ngOnInit(){
        let divmap = document.getElementsByClassName('custom-container');
        if(divmap.length > 0){
            let mapdiv = divmap[0] as HTMLElement;
            mapdiv.style.padding = '0px';
        };

        this.ms.getZones().subscribe((zones:any)=>{
            this.zones = zones.zones;
        });
    }

    changeInsideServiceactive(type:any){
        this.active_inside_service_div = type;
    }

    div_loder = document.getElementById('loader');
    showLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'flex');
        }
    }

    hideLoader(){
        if (this.div_loder) {
            this.renderer.setStyle(this.div_loder, 'display', 'none');
        }

        setTimeout(()=>{
            const closeDiv = document.querySelector('.t-close');
            if (closeDiv) {
              (closeDiv as HTMLElement).click();
            }
        },5000);
    }

    ngOnDestroy(){
        let divmap = document.getElementsByClassName('custom-container');
        if(divmap.length > 0){
            let mapdiv = divmap[0] as HTMLElement;
            mapdiv.style.padding = '30px';
        };
    }

    getRandomOffset() {
        return (Math.random() - 0.9) / 10; // Ajusta este valor según sea necesario
    }

    rolesGeneral: any[] = [
        { name: "No especificado",role: "NOT_SPECIFIED" },
        { name: "Regular", role: "REGULAR" },
        { name: "Larga distancia", role: "LONG_DISTANCE" },
        { name: "Coche escoba", role: "BROOM_CAR" },
        { name: "Lavador móvil", role: "MOBILE_WASHER" },
        { name: "Lavador de implante", role: "IMPLANT_WASHER" },
        { name: "Oficina de implante", role: "IMPLANT_OFFICE" }
    ];

    selectedDate:any;

    onDateChange(){
        console.log(this.selectedDate);
        this.filterForm.patchValue({
            dateFilter: this.selectedDate,
        });
    }

    resetDate(){
        this.selectedDate = null;
    }

    ngAfterViewInit(){ 
        this.mapInitializer();
        this.filterForm.get('zoneFilter')?.valueChanges.subscribe((value) => {
            if(this.filterForm.value.dateFilter){
                // this.show_loader = !this.show_loader;
                this.showLoader();
                var date_send = Math.floor(new Date(this.filterForm.value.dateFilter).getTime() / 1000);
                const serviceData = {
                    date: {seconds: date_send}, 
                    zoneId: value 
                };
                this.elements_services = [];
                this.filtered_services = [];

                this.elements_drivers = [];
                this.filtered_drivers = [];

                this.count_unsigned_services = 0;
                this.count_drivers = 0;
                this.clearMarkers();
                this.PointsMarkers = [];

                if(this.directionsRenderer){
                    this.directionsRenderer.setDirections({ routes: [] });
                    this.directionsRenderer.setMap(null);
                }

                this.directionsRenderers.forEach(renderer => {
                    renderer.setMap(null);
                });
                this.directionsRenderers = [];

                this.customMarkers.forEach(marker => {
                  marker.setMap(null);
                });
                this.customMarkers = [];
                
                this.ms.getServices(serviceData).subscribe((services:any)=>{
                    services.forEach((obj:any, index:any) => {
                        obj.unassignedServices.forEach((obj2:any, index2:any) => {
                            console.log(obj2);
                            this.elements_services.push(obj2);
                        });

                        obj.activeDrivers.forEach((obj:any, index:any) => {
                            this.elements_drivers.push(obj);
                        });
                    });

                    this.elements_services.forEach((obj:any, index:any) => {
                        let data = {
                            type:'pickup',
                            obj:obj.pickupPoint,
                            gi:obj
                        }
                        this.PointsMarkers.push(data);
                        
                        let data2 = {
                            type:'dest',
                            obj:obj.destinationPoint,
                            gi:obj
                        }
                        this.PointsMarkers.push(data2);
                    });
                    this.filtered_services = [...this.elements_services];

                    const mergedDrivers = this.elements_drivers.reduce((acc:any, current:any) => {
                        const existingDriver = acc.find((driver:any) => driver.driverId === current.driverId);
                        if (existingDriver) {
                            existingDriver.assignedServices.push(...current.assignedServices);
                        } else {
                            acc.push(current);
                        }
                        return acc;
                    }, []);
                    let driverPromises = mergedDrivers.map((obj: any, index: any) => {
                        return this.ms.getDriver(obj.driverId).toPromise().then((data: any) => {
                            let general_info = data.drivers[0]!;
                            obj.general_info = general_info;
                            if (obj.lastLocation) {
                                let data = {
                                    type: 'driver',
                                    obj: obj.lastLocation,
                                    gi:general_info
                                }
                                this.PointsMarkers.push(data);
                            }

                            if (obj.assignedServices.length > 0) {
                                // if(obj.assignedServices.filter((x: any) => 
                                //     x.orderStatus != 'FINISHED' && 
                                //     x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                                //     x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                                //     x.orderStatus != 'FAILED'
                                // ).length > 0){
                                //     if(!obj.lastLocation){
                                //         obj.lastLocation = {
                                //             Latitude:40.407290,
                                //             Longitude:-3.706050
                                //         }
                                //         let data = {
                                //             type: 'driver',
                                //             obj: obj.lastLocation,
                                //             gi:general_info
                                //         }
                                //         this.PointsMarkers.push(data);
                                //     }
                                // }
                                if(general_info && general_info.isActive){
                                    obj.assignedServices.filter((x: any) => 
                                        x.orderStatus != 'FINISHED' && 
                                        x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                                        x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                                        x.orderStatus != 'FAILED'
                                    ).forEach((service: any) => {
                                        if (service.pickupPoint) {
                                            let data = {
                                                type: 'pickup',
                                                obj: service.pickupPoint,
                                                gi:service
                                            }
                                            this.PointsMarkers.push(data);
                                        }
                                        if (service.destinationPoint) {
                                            let data2 = {
                                                type: 'dest',
                                                obj: service.destinationPoint,
                                                gi:service
                                            }
                                            this.PointsMarkers.push(data2);
                                        }
                                    });

                                    obj.assignedServices = obj.assignedServices.sort(
                                        (a:any, b:any) => {
                                            const hourA = this.getDateHour(a.pickupDate.seconds, a.zoneId);
                                            const hourB = this.getDateHour(b.pickupDate.seconds, b.zoneId);

                                              // Comparar las horas
                                            if (hourA < hourB) return -1;
                                            if (hourA > hourB) return 1;
                                            return 0;
                                            // this.getDateHour(a.pickupDate.seconds) - this.getDateHour(b.pickupDate.seconds)
                                        }
                                    );
                                }
                            }
                        });
                    });

                    Promise.all(driverPromises).then(() => {
                        this.filtered_drivers = [...this.elements_drivers.filter((x: any) => 
                            x.general_info !== undefined && x.general_info.isActive === true
                        )];
                        this.count_unsigned_services = this.elements_services?.length;
                        this.count_drivers = this.elements_drivers.filter((x: any) => 
                            x.general_info !== undefined && x.general_info.isActive === true
                        )?.length;
                        this.drawMapPoints();
                        this.hideLoader();
                    });
                });
            }
        });

        this.filterForm.get('dateFilter')?.valueChanges.subscribe(value => {
            this.dateFilter = value;
            if(this.filterForm.value.zoneFilter != '#' && value){
                // this.show_loader = !this.show_loader;
                this.showLoader();
                var date_send = Math.floor(new Date(this.dateFilter).getTime() / 1000);
                const serviceData = {
                    date: {seconds: date_send}, 
                    zoneId: this.filterForm.value.zoneFilter 
                };
                this.elements_services = [];
                this.filtered_services = [];

                this.elements_drivers = [];
                this.filtered_drivers = [];

                this.count_unsigned_services = 0;
                this.count_drivers = 0;

                this.selectedItemFilter = 'services';
                this.clearMarkers();
                this.PointsMarkers = [];

                if(this.directionsRenderer){
                    this.directionsRenderer.setDirections({ routes: [] });
                    this.directionsRenderer.setMap(null);
                }

                this.directionsRenderers.forEach(renderer => {
                    renderer.setMap(null);
                });
                this.directionsRenderers = [];

                this.customMarkers.forEach(marker => {
                  marker.setMap(null);
                });
                this.customMarkers = [];

                this.ms.getServices(serviceData).subscribe((services:any)=>{

                    services.forEach((obj:any, index:any) => {
                        obj.unassignedServices.forEach((obj:any, index:any) => {
                            this.elements_services.push(obj);
                        });

                        obj.activeDrivers.forEach((obj:any, index:any) => {
                            this.elements_drivers.push(obj);
                        });
                    });

                    this.elements_services.forEach((obj:any, index:any) => {
                        let data = {
                            type:'pickup',
                            obj:obj.pickupPoint,
                            gi:obj
                        }
                        this.PointsMarkers.push(data);
                        
                        let data2 = {
                            type:'dest',
                            obj:obj.destinationPoint,
                            gi:obj
                        }
                        this.PointsMarkers.push(data2);
                    });
                    this.filtered_services = [...this.elements_services];

                    const mergedDrivers = this.elements_drivers.reduce((acc:any, current:any) => {
                        const existingDriver = acc.find((driver:any) => driver.driverId === current.driverId);
                        if (existingDriver) {
                            existingDriver.assignedServices.push(...current.assignedServices);
                        } else {
                            acc.push(current);
                        }
                        return acc;
                    }, []);
                    let driverPromises = mergedDrivers.map((obj: any, index: any) => {
                        return this.ms.getDriver(obj.driverId).toPromise().then((data: any) => {
                            let general_info = data.drivers[0]!;
                            obj.general_info = general_info;
                            if (obj.lastLocation) {
                                let data = {
                                    type: 'driver',
                                    obj: obj.lastLocation,
                                    gi:general_info
                                }
                                this.PointsMarkers.push(data);
                            }

                            if (obj.assignedServices.length > 0) {
                                // if(obj.assignedServices.filter((x: any) => 
                                //     x.orderStatus != 'FINISHED' && 
                                //     x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                                //     x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                                //     x.orderStatus != 'FAILED'
                                // ).length > 0){
                                //     if(!obj.lastLocation){
                                //         obj.lastLocation = {
                                //             Latitude:40.407290,
                                //             Longitude:-3.706050
                                //         }
                                //         let data = {
                                //             type: 'driver',
                                //             obj: obj.lastLocation,
                                //             gi:general_info
                                //         }
                                //         this.PointsMarkers.push(data);
                                //     }
                                // }
                                if(general_info && general_info.isActive){
                                    obj.assignedServices.filter((x: any) => 
                                        x.orderStatus != 'FINISHED' && 
                                        x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                                        x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                                        x.orderStatus != 'FAILED'
                                    ).forEach((service: any) => {
                                        if (service.pickupPoint) {
                                            let data = {
                                                type: 'pickup',
                                                obj: service.pickupPoint,
                                                gi:service
                                            }
                                            this.PointsMarkers.push(data);
                                        }
                                        if (service.destinationPoint) {
                                            let data2 = {
                                                type: 'dest',
                                                obj: service.destinationPoint,
                                                gi:service
                                            }
                                            this.PointsMarkers.push(data2);
                                        }
                                    });

                                    obj.assignedServices = obj.assignedServices.sort(
                                        (a:any, b:any) => {
                                            const hourA = this.getDateHour(a.pickupDate.seconds, a.zoneId);
                                            const hourB = this.getDateHour(b.pickupDate.seconds, b.zoneId);

                                              // Comparar las horas
                                            if (hourA < hourB) return -1;
                                            if (hourA > hourB) return 1;
                                            return 0;
                                            // this.getDateHour(a.pickupDate.seconds) - this.getDateHour(b.pickupDate.seconds)
                                        }
                                    );
                                }
                            }
                        });
                    });
                    
                    Promise.all(driverPromises).then(() => {
                        this.filtered_drivers = [...this.elements_drivers.filter((x: any) => 
                            x.general_info !== undefined && x.general_info.isActive === true
                        )];
                        this.count_unsigned_services = this.elements_services?.length;
                        this.count_drivers = this.elements_drivers.filter((x: any) => 
                            x.general_info !== undefined && x.general_info.isActive === true
                        )?.length;
                        this.drawMapPoints();
                        this.hideLoader();
                    });
                });
            }
        });
    }

    filterData(event: Event): void {
        const query = (event.target as HTMLInputElement).value.toLowerCase();

        if(this.selectedItemFilter == 'services'){
            this.filtered_services = this.elements_services.filter((item:any) =>
                item.licensePlate.toLowerCase().includes(query)
            );
            this.count_unsigned_services = this.filtered_services?.length;
        }

        if(this.selectedItemFilter == 'drivers'){
            this.filtered_drivers = this.elements_drivers.filter((item:any) =>
                item.general_info?.fullName.toLowerCase().includes(query)
            );
            this.count_drivers = this.filtered_drivers?.length;
        }
    }

    filterDataDriverService(event: Event): void {
        const query = (event.target as HTMLInputElement).value.toLowerCase();

        if(this.active_inside_service_div == 'closets'){
            this.drivers_for_service = this.elements_drivers_services.filter((item:any) =>
                item.driverName.toLowerCase().includes(query)
            );
            this.count_drivers_closets = this.drivers_for_service?.length;
        }

        if(this.active_inside_service_div == 'actives'){
            this.filtered_drivers = this.elements_drivers.filter((item:any) =>
                item.general_info?.fullName.toLowerCase().includes(query)
            );
        }
    }

    getInitial(nombre:any){
        nombre = nombre.split(" ");
        return  `
            <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="15" fill="#59F89C"/>
                <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="17" font-family="Arial">${nombre[0][0]+nombre[1][0]}</text>
            </svg>`;
    }

    markers: google.maps.Marker[] = [];
    isInfoWindowOpen = false;
    currentCluster: any = null;
    hoverTimeout: any = null;
    isMouseOverCluster: any = false;
    customMarkers: google.maps.Marker[] = [];

    async drawRoutes(service:any){
        this.ngZone.run(() => {
            if(this.directionsRenderer){
                this.directionsRenderer.setDirections({ routes: [] });
                this.directionsRenderer.setMap(null);
            }

            this.directionsRenderers.forEach(renderer => {
                renderer.setMap(null);
            });
            this.directionsRenderers = [];

            this.customMarkers.forEach(marker => {
              marker.setMap(null);
            });

            this.customMarkers = [];
            this.clearMarkers();
        });


        const directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        let waypts: google.maps.DirectionsWaypoint[] = [];
        let start = {};
        let end = {};

        if(service.nodes){
            if(!service.nodes.find((nod:any) => nod.nodeVerticalType === 'INTERNAL_RETURN')){
                if(service.destinationPoint){
                    if(service.destinationPoint.Latitude != service.destinationPoint.Longitude){
                        end = {
                            lat:service.destinationPoint.Latitude,
                            lng:service.destinationPoint.Longitude,
                        }  
                    }
                }
            }
            if(!service.nodes.find((nod:any) => nod.nodeVerticalType === 'INTERNAL_PICKUP')){
                if(service.pickupPoint){
                    if(service.pickupPoint.Latitude != service.pickupPoint.Longitude){
                        start = {
                            lat:service.pickupPoint.Latitude,
                            lng:service.pickupPoint.Longitude,
                        }  
                    }
                }
            }
            service.nodes.forEach((obj:any, index:any) => {
                if(obj.nodeVerticalType == 'INTERNAL_PICKUP'){
                    if(obj.location.Latitude != obj.location.Longitude){
                        start = {
                            lat:obj.location.Latitude,
                            lng:obj.location.Longitude,
                        }
                    }
                }

                if(obj.nodeVerticalType == 'INTERNAL_RETURN'){
                    if(obj.location.Latitude != obj.location.Longitude){
                        end = {
                            lat:obj.location.Latitude,
                            lng:obj.location.Longitude,
                        }   
                    }
                }

                if(obj.nodeVerticalType != 'INTERNAL_RETURN' && obj.nodeVerticalType != 'INTERNAL_PICKUP'){
                    if(obj.location){
                        if(obj.location.Latitude != obj.location.Longitude){
                            let wlo = new google.maps.LatLng(obj.location.Latitude, obj.location.Longitude);
                            waypts.push({
                                location: wlo,
                                stopover: true,
                            });
                        }else{
                            // let wlo = new google.maps.LatLng(obj.location.Latitude, obj.location.Longitude - 0.10);
                            // let wlo = new google.maps.LatLng(39.8628, -4.0273);
                            // waypts.push({
                            //     location: wlo,
                            //     stopover: true,
                            // });
                        }
                    }
                }
            });

            this.directionsRenderer.setMap(this.map);
            if(waypts.length > 0){    
                directionsService.route({
                    origin: start,
                    destination: end,
                    waypoints: waypts,
                    optimizeWaypoints: true,
                    travelMode: google.maps.TravelMode.DRIVING,
                }, (response)=>{
                    this.directionsRenderer.setDirections(response);
                    this.directionsRenderers.push(this.directionsRenderer);
                    this.addCustomMarkers(response);
                });
            }else{
                directionsService.route({
                    origin: start,
                    destination: end,
                    travelMode: google.maps.TravelMode.DRIVING,
                }, (response)=>{
                    this.directionsRenderer.setDirections(response);
                    this.directionsRenderers.push(this.directionsRenderer);
                    this.addCustomMarkers(response);
                });
            }
        }
    }

    mt:any;
    drivers_markers:any = [];
    drawMapPoints(){
        this.PointsMarkers.forEach((obj:any, index:any) => {
            const offset = 0.00005;
            var latOffset = this.getRandomOffset();
            var lngOffset = this.getRandomOffset();
            var latOffset2 = this.getRandomOffset();
            var lngOffset2 = this.getRandomOffset();

            // console.log(obj);
            if(obj.type == 'pickup'){
                if (obj.gi) {
                  var dataService = JSON.stringify(obj.gi);
                } else {
                  var dataService = '{}';
                }

                let mrkc = new google.maps.LatLng(obj.obj.Latitude, obj.obj.Longitude);
                let mrk = new google.maps.Marker({ 
                    position: mrkc,
                    // map: this.map,
                    title:`
                          <span style="cursor:pointer" class="span_service_map" 
                                data-service='${dataService.replace(/'/g, "&apos;")}'>
                            <img src="/assets/img/icon_service.png"/> 
                            ${obj.gi.pickupAddress}, 
                            <b>${this.hs.translate(obj.gi.mainProductName)} - ${obj.gi.licensePlate} ${this.getTypeChassisByType(obj.gi.chassis)},</b> 
                            ${this.getDateHour(obj.gi.pickupDate.seconds,obj.gi.zoneId)}
                          </span>
                        `,
                    icon:'/assets/img/icon_service.png'
                });
                obj.marker = mrk;
                // mrk.setMap(this.map);
            }

            if(obj.type == 'dest'){
                if (obj.gi) {
                  var dataService = JSON.stringify(obj.gi);
                } else {
                  var dataService = '{}'; // Un valor JSON válido por defecto
                }
                let mrkc2 = new google.maps.LatLng(obj.obj.Latitude, obj.obj.Longitude);
                let mrk2 = new google.maps.Marker({ 
                    position: mrkc2,
                    // map: this.map,
                    title:`
                          <span style="cursor:pointer" class="span_service_map" 
                                data-service='${dataService.replace(/'/g, "&apos;")}'>
                            <img src="/assets/img/icon_service2.png"/> 
                            ${obj.gi.pickupAddress}, 
                            <b>${this.hs.translate(obj.gi.mainProductName)} - ${obj.gi.licensePlate} ${this.getTypeChassisByType(obj.gi.chassis)},</b> 
                            ${this.getDateHour(obj.gi.pickupDate.seconds, obj.gi.zoneId)}
                          </span>
                        `,
                    icon:'/assets/img/icon_service2.png'
                });
                obj.marker = mrk2;
                // mrk2.setMap(this.map);
            }

            if(obj.type == 'driver'){
                if (obj.gi) {
                    var dataDriver = JSON.stringify(obj.gi);
                    let mrkc3 = new google.maps.LatLng(obj.obj.Latitude, obj.obj.Longitude);
                    let mrk3 = new google.maps.Marker({ 
                        position: mrkc3,
                        // map: this.map,
                        // title:this.getInitial(obj.gi.fullName)+' '+obj.gi.fullName,
                        title:this.getInitial(obj.gi.fullName)+`
                              <span style="cursor:pointer" class="span_driver_map" 
                                    data-driver='${dataDriver.replace(/'/g, "&apos;")}'>
                                ${obj.gi.fullName}
                              </span>
                            `,
                        icon: {
                            url:'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.getInitial(obj.gi.fullName)), 
                            scaledSize: new google.maps.Size(30,30)
                        },
                    });

                    obj.marker = mrk3;
                    mrk3.addListener("mouseover", () => {
                        // Guardar el title
                        const originalTitleDriver = mrk3.getTitle();
                        this.mt = originalTitleDriver;

                        var spdriver = mrk3.getTitle();
                        const regex = /data-driver='(.*?)'/;

                        // Ejecutar la expresión regular en el texto
                        const matchdriver = regex.exec(spdriver!);

                        if (matchdriver && matchdriver[1]) {
                            const dataDriverJson = JSON.parse(matchdriver[1]);
                                mrk3.setTitle(dataDriverJson.fullName);
                        } else {
                            console.log("No se encontró el atributo data-service en el texto.");
                            mrk3.setTitle('Titulo');
                        }
                    });

                    // Evento para cerrar el InfoWindow
                    mrk3.addListener("mouseout", () => {
                        mrk3.setTitle(this.mt);
                    });
                    mrk3.setMap(this.map);
                    this.drivers_markers.push(mrk3);
                }
            }
        });

        const map = this.map;
        const markers = this.PointsMarkers.filter((item:any) => item.type != 'driver').map((position:any, i:any) => {
            const marker = position.marker;
            marker.addListener("mouseover", () => {
                // Guardar el title
                const originalTitle = marker.getTitle();
                this.mt = originalTitle;

                var sp = marker.getTitle();
                const regex = /data-service='(.*?)'/;

                // Ejecutar la expresión regular en el texto
                const match = regex.exec(sp);

                if (match && match[1]) {
                    const dataServiceJson = JSON.parse(match[1]);
                    if(position.type == 'pickup'){
                        marker.setTitle(dataServiceJson.pickupAddress);
                    }else{
                        marker.setTitle(dataServiceJson.destinationAddress);
                    }
                } else {
                    console.log("No se encontró el atributo data-service en el texto.");
                    marker.setTitle('Titulo');
                }
            });

            // Evento para cerrar el InfoWindow
            marker.addListener("mouseout", () => {
                marker.setTitle(this.mt);
            });
            return marker;
        });

        const markerCluster2 = new MarkerClusterer(map,markers, {
            styles: [
              {
                width: 30,
                height: 30,
                className: "custom-clustericon-1",
              },
              {
                width: 30,
                height: 30,
                className: "custom-clustericon-2",
              },
              {
                width: 30,
                height: 30,
                className: "custom-clustericon-3",
              },
            ],
            clusterClass: "custom-clustericon",
            zoomOnClick:false,
        });
        this.markerCluster = markerCluster2;
        const infoWindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, 0)
        });

        google.maps.event.addListener(markerCluster2, 'click', (cluster)=> {
            const markers = cluster.getMarkers();
            let content = '\
                <table class="table table-bordered" style="width:700px">\
                    <thead>\
                    </thead>\
                    <tbody>';
            markers.forEach((marker:any) => {
                if (marker.getTitle() != "") {
                    content += `<tr><td>${marker.getTitle()}</td><tr>`;
                }
            });
            content += '</tbody></table>';

            infoWindow.setContent(content);
            infoWindow.setPosition(cluster.getCenter());
            infoWindow.open(map);

            setTimeout(()=>{
                var elementos = document.querySelectorAll('.span_service_map');
                elementos.forEach((elemento:any)=> {
                    elemento.addEventListener('click', (event:any)=> {
                        var dataService = elemento.getAttribute('data-service');
                        try {
                            var parsedData = JSON.parse(dataService);
                            if(parsedData.driverId === ""){
                                this.setActiveService(parsedData, undefined);
                                infoWindow.close();
                            }else{
                                let driver = this.filtered_drivers.find((driver:any) => driver.driverId === parsedData.driverId);
                                if(driver){
                                    this.setActiveService(parsedData, driver);
                                    infoWindow.close();
                                }
                            }
                        } catch (error) {
                            console.error('Error al parsear data-service:', error);
                        }
                    });
                });

                var elementosdrivers = document.querySelectorAll('.span_driver_map');
                elementosdrivers.forEach((elemento:any)=> {
                    elemento.addEventListener('click', (event:any)=> {
                        var dataService = elemento.getAttribute('data-driver');
                        try {
                            var parsedData = JSON.parse(dataService);

                            if(parsedData){
                                console.log('dd');
                            }
                        } catch (error) {
                            console.error('Error al parsear data-driver:', error);
                        }
                    });
                });
            }, 500)
        });
    }

    hideLeftDiv(){
        this.expandDivLeft = false;
        this.leftDivStyles.x = 155;
    }

    backToMap(){
        this.show_left_div = true;
        this.active_detail_service = null;
        this.drivers_for_service = [];
        this.selectedItemFilter = 'services';

        if(this.selectedItemFilter == 'services'){
            this.ngZone.run(() => {
                if(this.directionsRenderer){
                    this.directionsRenderer.setDirections({ routes: [] });
                    this.directionsRenderer.setMap(null);
                }

                this.directionsRenderers.forEach(renderer => {
                    renderer.setMap(null);
                });
                this.directionsRenderers = [];

                this.customMarkers.forEach(marker => {
                  marker.setMap(null);
                });
                this.customMarkers = [];
                this.clearMarkers();
                this.drawMapPoints();
            });
        }
    }

    clients_list:any = [];
    client_info:any;
    correct_hour:any;
    elements_drivers_services:any = [];
    setActiveService(service:any, driver:undefined){
        let data = {
            orderHash:service.orderHash,
            filterType:'PROXIMITY'
        }
        // this.show_loader = !this.show_loader;
        this.ngZone.run(() => {
            this.active_detail_service = service;
            if(driver){
                this.active_desasign_driver = driver;
            }else{
                this.active_desasign_driver = false;
            }
            this.show_left_div = false;
            this.type_driver_start = 'service';
        });
        this.showLoader();
        this.drawRoutes(service);

        this.hs.getServiceDetail(service.orderHash).subscribe((res: any) => {
            if(res.serviceDates.serviceAssignedPickupDate !== undefined){
                this.correct_hour = this.getDateHour(res.serviceDates.serviceAssignedPickupDate.seconds, res.zoneId);
            }
            this.ngZone.run(() => {
                this.provider_address = res.routeNodes.find(
                    (item:any) => item.mainProductVertical === "TECHNICAL_INSPECTION" || item.mainProductVertical === "TRANSFER" || 
                    item.mainProductVertical === "MECHANICAL_INSPECTION" || item.mainProductVertical === "VEHICLE_WASH" || 
                    item.mainProductVertical === "REFUELING" || item.mainProductVertical === "PRE_TECHNICAL_INSPECTION" || 
                    item.mainProductVertical === "LONG_DISTANCE_TRANSFER" || item.mainProductVertical === "VEHICLE_INSURANCE" || 
                    item.mainProductVertical === "ACCESSORY" || item.mainProductVertical === "VALET" || item.mainProductVertical === "TYRES" || 
                    item.mainProductVertical === "FORMALITIES" || item.mainProductVertical === "PARKING" || 
                    item.mainProductVertical === "REPLACEMENT_VEHICLE" || item.mainProductVertical === "TOW_TRUCK" || 
                    item.mainProductVertical === "WHATEVER_YOU_WANT"
                )?.startAddress;
                console.log(this.provider_address, 443);
            });
            let service_in = res;
            this.hs.getClients().subscribe((res: any) => {
                this.clients_list = res.businessUserData;

                this.client_info = this.clients_list.find((item:any) => item.userId == service_in.clientId);
            });
        });
        this.ms.getMapDrivers(data).subscribe((data:any)=>{
            data.forEach((obj:any, index:any) => {
                obj.driversForService.forEach((obj:any, index:any) => {
                    this.elements_drivers_services.push(obj);
                    // this.drivers_for_service.push(obj);
                });
            });

            // let dat = {
            //     driverName:'Pedro pes'
            // }
            // this.elements_drivers_services.push(dat);
            this.drivers_for_service = [...this.elements_drivers_services];
            this.drivers_for_service.sort((a:any, b:any) => a.distanceToService - b.distanceToService);
            this.count_drivers_closets = this.drivers_for_service?.length;
            this.hideLoader();
        });
    }

    openModalAssignDriver(templateModalLAssignDriver: TemplateRef<void>, driver:any) { 
        this.active_driver = driver;
        this.modalRef = this.modalService.show(templateModalLAssignDriver, Object.assign({
            ignoreBackdropClick: false
        }, { class: 'gray modal-md mt_10p' }));
    }

    openModalDesAssignDriver(templateModalDesAssignDriver: TemplateRef<void>, driver:any) { 
        this.active_driver = driver;
        this.active_desasign_driver = driver;
        this.modalRef = this.modalService.show(templateModalDesAssignDriver, Object.assign({
            ignoreBackdropClick: false
        }, { class: 'gray modal-md mt_10p', id:'mt_10p2' }));
    }

    hideLoaderModal(search:any){
        const div = document.querySelector('.'+search) as HTMLElement;
        if (div) {
            div.style.display = 'none'; // Agrega display: flex al div
        }
    }

    showLoaderModal(search:any){
        const div = document.querySelector('.'+search) as HTMLElement;
        if (div) {
            div.style.display = 'flex'; // Agrega display: flex al div
        }
    }

    showErrorMsj(){
        setTimeout(()=>{
            const sourceElement = document.querySelector('tui-alert');
            const targetElement = document.getElementById('loader');
            if (sourceElement && targetElement) {
                console.log('replicar')
                const clonedNode = sourceElement.cloneNode(true);
                // targetElement!.innerHTML = '';
                targetElement.appendChild(sourceElement);
            }
        },300);
    }

    confirmAssign(){ 
        let data = {
            driverId:this.active_driver.driverId,
            orderHash:this.active_detail_service.orderHash
        }
        this.showLoaderModal('loader_inside_modal_confirm_assignation');
        this.ms.assignDriver(data).subscribe((data:any)=>{
            if (!data.wasSuccessful && data?.statusCode) {
                this.hideLoaderModal('loader_inside_modal_confirm_assignation');
                this.modalRef?.hide();
                this.hs.showErrorCode(data.statusCode);
            }else{
                this.hideLoaderModal('loader_inside_modal_confirm_assignation');
                this.modalRef?.hide();
                this.reloadData();
                this.show_left_div = true;
                let name = this.active_driver.general_info ? this.active_driver.general_info.fullName : this.active_driver.driverName;
                this.showDepositAlert(
                    'El servicio de '+this.active_detail_service.mainProductName+'('+this.active_detail_service.orderHash+') se ha asignado a '+name,
                    'Se ha asignado el servicio de forma satisfactoria'
                );
            }
        },(error) => {
            this.hideLoaderModal('loader_inside_modal_confirm_assignation');
            if (error.status === 500) {
                this.alerts.open('Problema en el servidor', {
                    label: 'Error 500',
                    status: 'error',
                    autoClose: false,
                }).subscribe();
            } else {
                this.alerts.open(error, {
                    label: 'Otro error',
                    status: 'error',
                    autoClose: false,
                }).subscribe();
            }
        });
    }

    confirmDesAssign(){
        let data = {
            driverId:"00000000-0000-0000-0000-000000000000",
            orderHash:this.active_detail_service.orderHash
        }
        this.showLoaderModal('loader_inside_modal_confirm_desassignation');
        this.ms.assignDriver(data).subscribe((data:any)=>{
            if (!data.wasSuccessful && data?.statusCode) {
                this.hideLoaderModal('loader_inside_modal_confirm_desassignation');
                this.modalRef?.hide();
                this.hs.showErrorCode(data.statusCode);
            }else{
                this.hideLoaderModal('loader_inside_modal_confirm_desassignation');
                this.modalRef?.hide();
                this.reloadData();
                this.show_left_div = true;
                this.showDepositAlert(
                    'El servicio de '+this.active_detail_service.mainProductName+'('+this.active_detail_service.orderHash+') se ha desasignado a '+this.active_driver.general_info?.fullName,
                    'Se ha desasignado el servicio de forma satisfactoria'
                );
            }
        },(error) => {
            this.hideLoaderModal('loader_inside_modal_confirm_desassignation');
            if (error.status === 500) {
                this.alerts.open('Problema en el servidor', {
                    label: 'Error 500',
                    status: 'error',
                    autoClose: false,
                }).subscribe();
            } else {
                this.alerts.open(error, {
                    label: 'Otro error',
                    status: 'error',
                    autoClose: false,
                }).subscribe();
            }
        });
    }

    checkAssignedServices(driver: any): boolean {
        return driver.assignedServices?.some((service:any) =>
            service.orderStatus !== 'FINISHED' &&
            service.orderStatus !== 'CANCELLED_BY_CAFLER' &&
            service.orderStatus !== 'CANCELLED_BY_CLIENT' &&
            service.orderStatus !== 'FAILED'
        );
    }

    reloadData(){
        var date_send = Math.floor(new Date(this.dateFilter).getTime() / 1000);
        const serviceData = {
            date: {seconds: date_send}, 
            zoneId: this.filterForm.value.zoneFilter 
        };
        this.ngZone.run(() => {
            this.elements_services = [];
            this.filtered_services = [];

            this.elements_drivers = [];
            this.filtered_drivers = [];

            this.drivers_for_service = [];
            this.active_desasign_driver = false;

            this.count_unsigned_services = 0;
            this.count_drivers = 0;

            this.selectedItemFilter = 'services';
            this.clearMarkers();
            this.PointsMarkers = [];
            if(this.directionsRenderer){
                this.directionsRenderer.setDirections({ routes: [] });
                this.directionsRenderer.setMap(null);
            }

            this.directionsRenderers.forEach(renderer => {
                renderer.setMap(null);
            });
            this.directionsRenderers = [];

            this.customMarkers.forEach(marker => {
              marker.setMap(null); // Elimina el marcador del mapa
            });
            this.customMarkers = [];
            this.clearMarkers();
        });

        this.showLoader();
        this.ms.getServices(serviceData).subscribe((services:any)=>{

            services.forEach((obj:any, index:any) => {
                obj.unassignedServices.forEach((obj:any, index:any) => {
                    this.elements_services.push(obj);
                });

                obj.activeDrivers.forEach((obj:any, index:any) => {
                    this.elements_drivers.push(obj);
                });
            });

            this.elements_services.forEach((obj:any, index:any) => {
                let data = {
                    type:'pickup',
                    obj:obj.pickupPoint,
                    gi:obj
                }
                this.PointsMarkers.push(data);
                
                let data2 = {
                    type:'dest',
                    obj:obj.destinationPoint,
                    gi:obj
                }
                this.PointsMarkers.push(data2);
            });
            this.filtered_services = [...this.elements_services];

            const mergedDrivers = this.elements_drivers.reduce((acc:any, current:any) => {
                const existingDriver = acc.find((driver:any) => driver.driverId === current.driverId);
                if (existingDriver) {
                    existingDriver.assignedServices.push(...current.assignedServices);
                } else {
                    acc.push(current);
                }
                return acc;
            }, []);
            let driverPromises = mergedDrivers.map((obj: any, index: any) => {
                return this.ms.getDriver(obj.driverId).toPromise().then((data: any) => {
                    let general_info = data.drivers[0]!;
                    obj.general_info = general_info;

                    if (obj.lastLocation) {
                        let data = {
                            type: 'driver',
                            obj: obj.lastLocation,
                            gi:general_info
                        }
                        this.PointsMarkers.push(data);
                    }

                    if (obj.assignedServices.length > 0) {
                        // if(obj.assignedServices.filter((x: any) => 
                        //     x.orderStatus != 'FINISHED' && 
                        //     x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                        //     x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                        //     x.orderStatus != 'FAILED'
                        // ).length > 0){
                        //     if(!obj.lastLocation){
                        //         obj.lastLocation = {
                        //             Latitude:40.407290,
                        //             Longitude:-3.706050
                        //         }
                        //         let data = {
                        //             type: 'driver',
                        //             obj: obj.lastLocation,
                        //             gi:general_info
                        //         }
                        //         this.PointsMarkers.push(data);
                        //     }
                        // }
                        if(general_info && general_info.isActive){
                            obj.assignedServices.filter((x: any) => 
                                x.orderStatus != 'FINISHED' && 
                                x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                                x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                                x.orderStatus != 'FAILED'
                            ).forEach((service: any) => {
                                if (service.pickupPoint) {
                                    let data = {
                                        type: 'pickup',
                                        obj: service.pickupPoint,
                                        gi:service
                                    }
                                    this.PointsMarkers.push(data);
                                }
                                if (service.destinationPoint) {
                                    let data2 = {
                                        type: 'dest',
                                        obj: service.destinationPoint,
                                        gi:service
                                    }
                                    this.PointsMarkers.push(data2);
                                }
                            });

                            obj.assignedServices = obj.assignedServices.sort(
                                (a:any, b:any) => {
                                    const hourA = this.getDateHour(a.pickupDate.seconds, a.zoneId);
                                    const hourB = this.getDateHour(b.pickupDate.seconds, b.zoneId);

                                      // Comparar las horas
                                    if (hourA < hourB) return -1;
                                    if (hourA > hourB) return 1;
                                    return 0;
                                    // this.getDateHour(a.pickupDate.seconds) - this.getDateHour(b.pickupDate.seconds)
                                }
                            );
                        }
                    }
                });
            });
            
            Promise.all(driverPromises).then(() => {
                this.filtered_drivers = [...this.elements_drivers.filter((x: any) => 
                    x.general_info !== undefined && x.general_info.isActive === true
                )];
                this.count_unsigned_services = this.elements_services?.length;
                this.count_drivers = this.elements_drivers.filter((x: any) => 
                    x.general_info !== undefined && x.general_info.isActive === true
                )?.length;

                this.drawMapPoints();
                this.hideLoader();
            });
        });

        setTimeout(() => {
          const closeDiv = document.querySelector('.t-close');
          if (closeDiv) {
            (closeDiv as HTMLElement).click();
          }
        }, 5000);
    }

    directionsRenderers: google.maps.DirectionsRenderer[] = [];
    type_driver_start:any = 'service';
    icon_driver_start:any = '';

    drawRoutesDriver(driver:any){
        const directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true
        });
        let waypts: google.maps.DirectionsWaypoint[] = [];
        let start = {};
        let end = {};

        this.directionsRenderer.setMap(this.map);

        const registros = driver.assignedServices.filter((x: any) => 
            x.orderStatus != 'FINISHED' && 
            x.orderStatus != 'CANCELLED_BY_CAFLER' &&
            x.orderStatus != 'CANCELLED_BY_CLIENT' &&
            x.orderStatus != 'FAILED'
        );
        if(registros.length > 0){
            let startPoint = {
                lat:registros[0].pickupPoint.Latitude,
                lng:registros[0].pickupPoint.Longitude,
            };
            if(driver.lastLocation){
                startPoint = {
                    lat:driver.lastLocation.Latitude,
                    lng:driver.lastLocation.Longitude,
                }

                waypts.push({
                   location: new google.maps.LatLng(registros[0].pickupPoint.Latitude, registros[0].pickupPoint.Longitude),
                   stopover: true
                });

                this.type_driver_start = 'driver';
                this.icon_driver_start = this.getInitial(driver.general_info.fullName);
            }
            const endPoint = {
                lat:registros[registros.length - 1].destinationPoint.Latitude,
                lng:registros[registros.length - 1].destinationPoint.Longitude,
            }

            for (let i = 0; i < registros.length; i++) {
                if (i < registros.length - 1) {
                    waypts.push({
                       location: new google.maps.LatLng(registros[i].destinationPoint.Latitude, registros[i].destinationPoint.Longitude),
                       stopover: true
                    });
                }

                if(registros[i].nodes){
                    registros[i].nodes.forEach((obj:any, index:any) => {
                        if(obj.nodeVerticalType != 'INTERNAL_RETURN' && obj.nodeVerticalType != 'INTERNAL_PICKUP'){
                            if(obj.location){
                                if(obj.location.Latitude != obj.location.Longitude){
                                    let wlo = new google.maps.LatLng(obj.location.Latitude, obj.location.Longitude);
                                    waypts.push({
                                        location: wlo,
                                        stopover: true,
                                    });
                                }else{
                                    // let wlo = new google.maps.LatLng(obj.location.Latitude, obj.location.Longitude - 0.10);
                                    // let wlo = new google.maps.LatLng(39.8628, -4.0273);
                                    // waypts.push({
                                    //     location: wlo,
                                    //     stopover: true,
                                    // });
                                }
                            }
                        }
                    });
                }
            }

            directionsService.route({
                origin:startPoint,
                destination: endPoint,
                waypoints: waypts,
                travelMode: google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true
            }, (result:any, status:any) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.directionsRenderer.setDirections(result);
                    const route = result.routes[0];
                    const legs = route.legs;
                    this.addCustomMarkers(result);
                }
            })    
        }
    }

    addCustomMarkers(response: any): void {
        const route = response.routes[0];
        const legs = route.legs;

        console.log(this.type_driver_start, this.icon_driver_start);
        for (let i = 0; i < legs.length; i++) {
            const leg = legs[i];

            if (leg.via_waypoints && leg.via_waypoints.length > 0) {
                leg.via_waypoints.forEach((waypoint:any, index:any) => {
                    let cc = new google.maps.Marker({
                        position: waypoint, // Cada via_waypoint
                        map: this.map,
                        icon: {
                            url: 'assets/img/icon_service3.png', // Ruta a tu ícono personalizado
                            scaledSize: new google.maps.Size(30, 30) // Ajusta el tamaño del icono
                        },
                        title: ''
                    });

                    this.customMarkers.push(cc);
                });
            }
            if (i === 0) {
                if(this.type_driver_start == 'service'){
                    let pi = new google.maps.Marker({
                        position: leg.start_location, // Punto de inicio del primer leg
                        map: this.map,
                        icon: {
                            url: 'assets/img/icon_service.png', // Ruta a tu ícono personalizado
                            scaledSize: new google.maps.Size(30, 30) // Ajusta el tamaño del icono
                        },
                        title: leg.start_address
                    });
                    this.customMarkers.push(pi);
                }else{
                    let pi = new google.maps.Marker({
                        position: leg.start_location, // Punto de inicio del primer leg
                        map: this.map,
                        icon: {
                            url:`data:image/svg+xml;charset=UTF-8,${encodeURIComponent(this.icon_driver_start)}`, 
                            scaledSize: new google.maps.Size(30,30)
                        },
                        title:''
                    });
                    this.customMarkers.push(pi);
                }
            }

          // Marcar el punto final del leg
            if (i === legs.length - 1) {
                let pf = new google.maps.Marker({
                    position: leg.end_location, // Punto final del último leg
                    map: this.map,
                    icon: {
                        url: 'assets/img/icon_service2.png', // Ruta a tu ícono personalizado
                        scaledSize: new google.maps.Size(30, 30) // Ajusta el tamaño del icono
                    },
                    title: leg.end_address
                });
                this.customMarkers.push(pf);
            } else {
                let pw = new google.maps.Marker({
                    position: leg.end_location,
                    map: this.map,
                    icon: {
                        url: 'assets/img/icon_service3.png', // Ruta a tu ícono personalizado
                        scaledSize: new google.maps.Size(30, 30) // Ajusta el tamaño del icono
                    },
                    title: leg.end_address
                });
                this.customMarkers.push(pw);
            }
        }

        let cmarkers = this.customMarkers;
        let map = this.map;
        const markerClusterNew = new MarkerClusterer(map,cmarkers, {
            styles: [
              {
                width: 30,
                height: 30,
                className: "custom-clustericon-1",
              },
              {
                width: 30,
                height: 30,
                className: "custom-clustericon-2",
              },
              {
                width: 30,
                height: 30,
                className: "custom-clustericon-3",
              },
            ],
            clusterClass: "custom-clustericon",
            zoomOnClick:false,
        });
        this.markerCluster = markerClusterNew;
        let infoWindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, 0)
        });

        google.maps.event.addListener(markerClusterNew, 'click', (cluster)=> {
            const markers = cluster.getMarkers();
            let content = '\
                <table class="table table-bordered" style="width:700px">\
                    <thead>\
                    </thead>\
                    <tbody>';
            markers.forEach((marker:any) => {
                let icon = marker.getIcon();
                if (marker.getTitle() != "") {
                    content += `<tr><td><img src="${icon.url}" width="30" /> ${marker.getTitle()}</td><tr>`;
                }
            });
            content += '</tbody></table>';

            infoWindow.setContent(content);
            infoWindow.setPosition(cluster.getCenter());
            infoWindow.open(map);
        });
    }

    setShowServices(driver:any){
        if(this.show_assign_services == driver.driverId){
            this.show_assign_services = 0;
            this.ngZone.run(() => {
                if(this.directionsRenderer){
                    this.directionsRenderer.setDirections({ routes: [] });
                    this.directionsRenderer.setMap(null);
                }

                this.directionsRenderers.forEach(renderer => {
                    renderer.setMap(null);
                });
                this.directionsRenderers = [];

                this.customMarkers.forEach(marker => {
                  marker.setMap(null);
                });
                this.customMarkers = [];
                this.clearMarkers();
                this.drawMapPoints();
            });
        }else{
            if(driver.assignedServices && driver.assignedServices.filter((x: any) => 
                x.orderStatus != 'FINISHED' && 
                x.orderStatus != 'CANCELLED_BY_CAFLER' &&
                x.orderStatus != 'CANCELLED_BY_CLIENT' &&
                x.orderStatus != 'FAILED'
            ).length > 0){
                this.ngZone.run(() => {
                    if(this.directionsRenderer){
                        this.directionsRenderer.setDirections({ routes: [] });
                        this.directionsRenderer.setMap(null);
                    }

                    this.directionsRenderers.forEach(renderer => {
                        renderer.setMap(null);
                    });
                    this.directionsRenderers = [];

                    this.customMarkers.forEach(marker => {
                      marker.setMap(null);
                    });
                    this.customMarkers = [];
                    this.clearMarkers();
                    this.show_assign_services = driver.driverId;
                });
                this.drawRoutesDriver(driver);
            }
        }
    }

    showDepositAlert(title:any,msj:any): void {
        this.alerts.open(msj, {
            label: title,
            status: 'success',
            autoClose: false,
        }).subscribe();
    }

    showLeftDiv(){
        this.expandDivLeft = true;
        this.leftDivStyles.x = 440;
    }

    changeSelectedItemFilter(item:any){
        this.selectedItemFilter = item;
        if(item == 'services'){
            this.ngZone.run(() => {
                this.show_assign_services = 0;
                if(this.directionsRenderer){
                    this.directionsRenderer.setDirections({ routes: [] });
                    this.directionsRenderer.setMap(null);
                }

                this.directionsRenderers.forEach(renderer => {
                    renderer.setMap(null);
                });
                this.directionsRenderers = [];

                this.customMarkers.forEach(marker => {
                  marker.setMap(null);
                });
                this.customMarkers = [];
                this.clearMarkers();
                this.drawMapPoints();
            });
        }
    }

    zoomInMap(){
        this.zoom_level = Number(this.zoom_level) + Number('1');
        this.map.setZoom(this.zoom_level);
    }

    zoomOutMap(){
        if(this.zoom_level >= 6){
            this.zoom_level = Number(this.zoom_level) - Number('1');
            this.map.setZoom(this.zoom_level);
        }
    }

    formatTimeUnit(unit:any) {
        return unit < 10 ? `0${unit}` : unit;
    }

    getDateHour(seconds:any, zoneId:any){
        const date = new Date(seconds * 1000); 

        const hour = this.formatTimeUnit(date.getHours());
        const min = this.formatTimeUnit(date.getMinutes());

        // return `${hour}:${min}`;

        const zone = this.zones.find((zone:any) => zone.zoneId === this.filterForm.value.zoneFilter);

        const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);

        return dateInClientZone.format('HH:mm');
    }

    clearMarkers(): void {
        if(this.markerCluster){
            this.markerCluster.clearMarkers();
            this.markerCluster = null;
        }
        for (let marker of this.PointsMarkers) {
            marker.marker.setMap(null); // Borra el marcador del mapa
        }
        for (let markerd of this.drivers_markers) {
            markerd.setMap(null);
        }
    }

    getRoleInfo(roleName:string){
        const selecteRole = this.rolesGeneral.find((rol:any) => rol.role === roleName);
        return selecteRole?.name
    } 

    calculateJourneyTime(distance:any) {
        let speed = 70;
        let journeyTime = '';
        if (distance > 0 && speed > 0) {
            const timeInHours = distance / speed;
            const hours = Math.floor(timeInHours);
            const minutes = Math.round((timeInHours - hours) * 60);

            journeyTime = `${hours}h ${minutes}m`;
        } else {
          journeyTime = 'La distancia y la velocidad deben ser mayores que 0';
        }

        return journeyTime;
    }
}

import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild, TemplateRef, Input, NgZone, ElementRef, AfterViewInit } from '@angular/core';

import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, NgControl } from '@angular/forms';
import {tuiCreateTimePeriods} from '@taiga-ui/kit';
import {TuiCountryIsoCode} from '@taiga-ui/i18n';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, Subject, Subscription } from 'rxjs';
import { TuiAlertService, TuiHostedDropdownComponent } from '@taiga-ui/core';
import { ServicesService } from 'src/app/services/services.service';
import { PersonalServiceService } from 'src/app/services/personal/personal-service.service';
import { HistoryServicesService } from 'src/app/services/history-services/history-services.service';
import {TranslateService} from '@ngx-translate/core';
declare var  moment: any
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-modal-add-personal',
  templateUrl: './modal-add-personal.component.html',
  styleUrls: ['./modal-add-personal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class   ModalAddPersonalComponent implements AfterViewInit  {
    @ViewChild('startAddress', { static: true }) startAddressInput!: ElementRef;
    @ViewChild('domicilioInput', { static: true }) domicilioInput!: ElementRef;
    @Input() driver_info: any;

    constructor(
        @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
        private fb: FormBuilder,
        private offcanvasService: NgbOffcanvas, private servicesSvc: ServicesService,
        private ngZone: NgZone,
        private ps:PersonalServiceService,
        private hs:HistoryServicesService,
        private translate: TranslateService,
    ) {}

    countries = [
        {
            'iso':'es',
            'country':'España'
        },
        {
            'iso':'uk',
            'country':'UK'
        },
        {
            'iso':'fr',
            'country':'Francia'
        }
    ];

    operatives:any = [];

    operators = [
        'Interno',
        'Externo',
    ]

    roles = [
        'Conductor',
        'Supervisor',
        'Operador',
    ]

    services = []

    active_options = [
        'Si',
        'No'
    ]

    driverMotorcycleLicenseType:any = [
      { type: "NOT_ALLOWED", name: "No" },
      { type: "UNDER_125", name: "-125cv" },
      { type: "OVER_125", name: "+125cv" }
    ]

    zones:any;

    rolesGeneral: any[] = [
        { name: "No especificado",role: "NOT_SPECIFIED" },
        { name: "Regular", role: "REGULAR" },
        { name: "Larga distancia", role: "LONG_DISTANCE" },
        { name: "Coche escoba", role: "BROOM_CAR" },
        { name: "Lavador móvil", role: "MOBILE_WASHER" },
        { name: "Lavador de implante", role: "IMPLANT_WASHER" },
        { name: "Oficina de implante", role: "IMPLANT_OFFICE" }
      ];

    hours = tuiCreateTimePeriods(undefined, undefined, [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 
        46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59
    ]);
    hours2:any = [];

    readonly countriesPhone: ReadonlyArray<TuiCountryIsoCode> = [
        TuiCountryIsoCode.ES,
        TuiCountryIsoCode.GB,
        TuiCountryIsoCode.FR,
    ];

    readonly countriesPhoneExtra: ReadonlyArray<TuiCountryIsoCode> = [
        TuiCountryIsoCode.ES,
        TuiCountryIsoCode.GB,
        TuiCountryIsoCode.FR,
    ];

    countryIsoCode = TuiCountryIsoCode.ES;
    countryIsoCodeExtra = TuiCountryIsoCode.ES;

    hourStart:any;
 
    personalForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        zoneId: [null, Validators.required],
        operatorType: ['', Validators.required],
        role: [null ],
        startAddress: ['', Validators.required],
        startAddressDetail: '',
        workdayStartTime: ['', Validators.required],
        workdayEndTime: ['', Validators.required],
        allowedMotorcycleServiceType: ['', Validators.required],
        domicilioAddress: ['', Validators.required],
        domicilioAddressExtraInfo: '',
        mainEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        alternativeEmail: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        phoneNumber: ['', Validators.required],
        alternativePhoneNumber: '',
        startLatitude: [0, Validators.required],
        startLongitude: [0, Validators.required],
        sameSchedule: true,
        isActiveUser: '',
    });

    openElement = '';
    selectedCountry = 'Selecciona el país';

    openOperative = false;
    selectedOperative = 'Selecciona previamente País';

    zoneId:any;
    operatorType:any = '';
    show_load_modal = false;
    ngOnInit(){
        this.servicesSvc.getZones().subscribe((res:any) => {
            this.operatives  = res.zones.map((zone:any) => zone.zoneName);
            this.zones = res.zones;

            if(this.driver_info){
                this.personalForm.patchValue({
                    zoneId: this.getZoneName(this.driver_info.zoneId),
                });
            }

            this.personalForm.get('operatorType')?.valueChanges.subscribe(value => {
                if(value == 'Interno'){
                    this.personalForm.get('role')?.setValidators([Validators.required]);
                }else{
                    this.personalForm.get('role')?.setValidators([]);
                }
            });
        })

        this.roles = this.rolesGeneral.map((roles:any) => roles.name)
        this.services = this.driverMotorcycleLicenseType.map((type:any) => type.name)

        this.personalForm.get('workdayStartTime')?.valueChanges.subscribe(value => {
            if(value){
                console.log(value, 453);
                this.hourStart = value;
                this.hours2 = tuiCreateTimePeriods(this.hourStart.hours < 23 ? this.hourStart.hours + 1 : 0, undefined, [
                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 
                    24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 
                    46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59
                ]);
            }
        });

        this.personalForm.get('zoneId')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('operatorType')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('role')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('allowedMotorcycleServiceType')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('isActiveUser')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('phoneNumber')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('alternativePhoneNumber')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('workdayStartTime')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });
        this.personalForm.get('workdayEndTime')?.valueChanges.subscribe(value => {
            if(value){
                this.resetFormSelects();
            }
        });

        if(this.driver_info){
            this.personalForm.patchValue({
                firstName: this.driver_info.firstName,
                lastName: this.driver_info.lastName,
                operatorType: this.driver_info.isInternal ? 'Interno' : 'Externo',
                role: this.getRoleInfo(this.driver_info.operatorType),
                startAddress: this.driver_info.startAddress,
                startAddressDetail: this.driver_info.startAddressDetail,
                // workdayStartTime: this.formatTime(this.driver_info.workdayStartTime.seconds, this.driver_info.zoneId),
                // workdayEndTime: this.formatTime(this.driver_info.workdayEndTime.seconds, this.driver_info.zoneId),
                allowedMotorcycleServiceType: this.driverMotorcycleLicenseType.find((item:any) => item.type === this.driver_info.allowedMotorcycleServiceType)?.name,
                domicilioAddress: this.driver_info.startAddress,
                domicilioAddressExtraInfo: this.driver_info.startAddressDetail,
                mainEmail: this.driver_info.emailAddress,
                alternativeEmail: this.driver_info.additionalEmailAddress,
                phoneNumber: this.driver_info.contactPhoneNumber,
                alternativePhoneNumber: this.driver_info.additionalContactPhoneNumber,
                startLatitude: this.driver_info.startAddressPoint?.Latitude,
                startLongitude: this.driver_info.startAddressPoint?.Longitude,
                sameSchedule: true,
                isActiveUser: this.driver_info.isActive ? 'Si' : 'No',
            });

            let sh = this.formatTime(this.driver_info.workdayStartTime.seconds, this.driver_info.zoneId).split(':');
            let eh = this.formatTime(this.driver_info.workdayEndTime.seconds, this.driver_info.zoneId).split(':');

            console.log(sh, eh)

            this.personalForm.patchValue({ 
                workdayStartTime:this.findTimeObject(parseInt(sh[0]), parseInt(sh[1])),
                workdayEndTime:this.findTimeObject(parseInt(eh[0]), parseInt(eh[1])),
            });
        }
    }

    onTimeInput(input:any): void {
        let timeControl = this.personalForm.value.workdayStartTime;
        if(input == 'end'){
            timeControl = this.personalForm.value.workdayEndTime;
        }
        if (timeControl !== null && timeControl !== undefined) {
            let value = timeControl.replace(/[^0-9]/g, ''); // Eliminar caracteres no numéricos

            // Formatear el valor en HH:MM
            if (value.length >= 3) {
                value = `${value.slice(0, 2)}:${value.slice(2, 4)}`;
            }

            if(value.length >= 5){
                if (value && /^([01]\d|2[0-3]):([0-5]\d)$/.test(value)) {
                    const [hours, minutes] = value.split(':').map(Number);
                    if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
                        if(input == 'start'){
                            this.personalForm.patchValue({
                                workdayStartTime: value
                            });
                        }
                        if(input == 'end'){
                            this.personalForm.patchValue({
                                workdayEndTime: value
                            });
                        }
                    }else{
                        if(input == 'start'){
                            this.personalForm.patchValue({
                                workdayStartTime: null
                            });
                        }
                        if(input == 'end'){
                            this.personalForm.patchValue({
                                workdayEndTime: null
                            });
                        }
                    }
                }else{
                    if(input == 'start'){
                        this.personalForm.patchValue({
                            workdayStartTime: null
                        });
                    }
                    if(input == 'end'){
                        this.personalForm.patchValue({
                            workdayEndTime: null
                        });
                    }
                }
            }
        }
      }

    ngAfterViewInit(): void {
        if (!google || !google.maps || !google.maps.places) {
            console.error('Google Maps API no está cargada correctamente.');
            return;
        }
        const input = this.domicilioInput.nativeElement;
        const input2 = this.startAddressInput.nativeElement;
        
        const autocomplete2 = new google.maps.places.Autocomplete(input2, {
            types: ['address']
        });
          
        const autocomplete = new google.maps.places.Autocomplete(input, {
            types: ['address']
        });

        autocomplete2.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete2.getPlace();

                if (!place.geometry) {
                    console.error('No se encontró la geometría del lugar.');
                    return;
                }
                const formattedAddress = place.formatted_address;
      
                if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    
                    // console.log('Latitud:', lat);
                    // console.log('Longitud:', lng);
          
                    // Actualizar el formulario con la dirección y las coordenadas
                    this.personalForm.patchValue({
                        startLatitude: lat,
                        startLongitude: lng,
                        startAddress: formattedAddress, 
                    });
                }
            });
        });

        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                if (!place.geometry) {
                    console.error('No se encontró la geometría del lugar.');
                    return;
                }
                const formattedAddress = place.formatted_address;
      
                if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    // console.log('Latitud:', lat);
                    // console.log('Longitud:', lng);
                }

                this.personalForm.patchValue({
                    domicilioAddress: formattedAddress, 
                });
            });
        });
    }

    private clickCounts: { [key: string]: number } = {};
    onSelectClick(event: MouseEvent){
        const selectElement = event.target as HTMLSelectElement;
        const selectId = selectElement.id;

        if (!this.clickCounts[selectId]) {
          this.clickCounts[selectId] = 0;
        }
        this.clickCounts[selectId]++;

        if (this.clickCounts[selectId] === 1) {
            setTimeout(()=>{
                const sourceElement = document.querySelector('tui-dropdown');
                const targetElement = document.getElementById('modal_add_personal');
                if (sourceElement && targetElement) {
                    const clonedNode = sourceElement.cloneNode(true);
                    // targetElement!.innerHTML = '';
                    targetElement.appendChild(sourceElement);
                }
            },300);
        }else{
            if (this.clickCounts[selectId] === 2) {
                if (document.activeElement === selectElement) {
                    selectElement.blur();
                    console.log(`Focus quitado del select ${selectId} por segundo clic`);
                }
                this.clickCounts[selectId] = 0;
            }
        }
    }

    resetFormSelects(){
        const entries = Object.entries(this.clickCounts);
        for (const [selectId, count] of entries) {
            this.clickCounts[selectId] = 0;
        }
    }

    onSelectBlur(type:any) {
        if(!type){
            this.resetFormSelects();
        }
    }    

    showErrorMsj(){
        setTimeout(()=>{
            const sourceElement = document.querySelector('tui-alert');
            const targetElement = document.getElementById('modal_add_personal_alert');
            if (sourceElement && targetElement) {
                const clonedNode = sourceElement.cloneNode(true);
                // targetElement!.innerHTML = '';
                targetElement.appendChild(sourceElement);
            }
        },300);
    }
    
    hideLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'none'; // Agrega display: flex al div
        }
    }

    showLoaderModal(){
        const div = document.querySelector('.loader_inside_modal_button') as HTMLElement;
        if (div) {
            div.style.display = 'flex'; // Agrega display: flex al div
        }
    }

    findTimeObject(hours: any, minutes: any): any {
        const hoursComplete = tuiCreateTimePeriods(
          0,
          24,
          [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
            38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
            56, 57, 58, 59,
          ]
        );
        return hoursComplete.find(
          (time) => time.hours === hours && time.minutes === minutes
        );
      }

    savePersonal(){
        const formValue:any = this.personalForm.value;
        formValue.zoneId = this.getZone(formValue.zoneId || '')
        formValue.isInternal = formValue.operatorType == 'Interno' ? true : false 
        formValue.operatorType = formValue.role ? this.getRole(formValue.role || '') : 'NOT_SPECIFIED';
        formValue.workdayEndTime = {seconds : this.timeToUnix(formValue.workdayEndTime) }
        formValue.workdayStartTime = {seconds : this.timeToUnix(formValue.workdayStartTime) }
        formValue.allowedMotorcycleServiceType = this.getServices(formValue.allowedMotorcycleServiceType)
        formValue.alternativeEmail = this.personalForm.value.alternativeEmail ? 
                                    this.personalForm.value.alternativeEmail : this.personalForm.value.mainEmail;
        formValue.alternativePhoneNumber = this.personalForm.value.alternativePhoneNumber ? 
                                    this.personalForm.value.alternativePhoneNumber : this.personalForm.value.phoneNumber;        
        formValue.isActiveUser = formValue.isActiveUser == 'Si' ? true : false
        console.log(formValue, "VALUE");

        this.showLoaderModal();
        if(this.driver_info){
            // this.closeCanvas();
            // this.showDepositAlert();
            formValue['driverId'] = this.driver_info.driverId;
            this.ps.updatePersonal(formValue).subscribe((res:any) => {
                this.hideLoaderModal();
                if (!res.wasSuccessful && res?.statusCode) {
                    this.hs.showErrorCode(res.statusCode);
                    this.showErrorMsj();
                }else{
                    this.closeCanvas();
                    this.hs.publish('updateListPersonalstaff');
                    this.showDepositAlert();
                }
            },(error) => {
                this.hideLoaderModal();
                if (error.status === 500) {
                    this.alerts.open('Problema en el servidor', {
                        label: 'Error 500',
                        status: 'error',
                        autoClose: true,
                    }).subscribe();
                    this.showErrorMsj()
                } else {
                    this.alerts.open(error, {
                        label: 'Otro error',
                        status: 'error',
                        autoClose: true,
                    }).subscribe();
                    this.showErrorMsj()
                }
            });
        }else{ 
            this.ps.addpersonal(formValue).subscribe((res:any) => {
                this.hideLoaderModal();
                if (!res.wasSuccessful && res?.statusCode) {
                    this.hs.showErrorCode(res.statusCode);
                    this.showErrorMsj();
                }else{
                    this.closeCanvas();
                    this.showDepositAlert();
                }
            },(error) => {
                this.hideLoaderModal();
                if (error.status === 500) {
                    this.alerts.open('Problema en el servidor', {
                        label: 'Error 500',
                        status: 'error',
                        autoClose: true,
                    }).subscribe();
                    this.showErrorMsj()
                } else {
                    this.alerts.open(error, {
                        label: 'Otro error',
                        status: 'error',
                        autoClose: true,
                    }).subscribe();
                    this.showErrorMsj()
                }
            });
        }
    }

    closeCanvas(){
        this.offcanvasService.dismiss();
    }

    showDepositAlert(): void {
        if(this.driver_info){
            this.alerts.open('Los datos del usuario se han guardado correctamente', {
                label: 'Cambios guardados',
                status: 'success',
                autoClose: true,
            }).subscribe();
        }else{
            this.alerts.open('El usuario se ha creado correctamente', {
                label: 'Usuario creado',
                status: 'success',
                autoClose: true,
            }).subscribe();
        }
    }

    getZone(zoneName:string){
        const selectedZone = this.zones.find((zone:any) => zone.zoneName === zoneName);
        // console.log(this.zones, zoneName);
        return selectedZone.zoneId
    }

    getZoneName(zoneId:any){
        const selectedZone = this.zones.find((zone:any) => zone.zoneId === zoneId);
        return selectedZone.zoneName
    }

    getRole(roleName:string){
        const selecteRole = this.rolesGeneral.find((rol:any) => rol.name === roleName);
        return selecteRole.role
    } 

    getRoleInfo(roleName:string){
        const selecteRole = this.rolesGeneral.find((rol:any) => rol.role === roleName);
        return selecteRole.name
    } 

    getServices(serviceName:any){
        const selecteService = this.driverMotorcycleLicenseType.find((service:any) => service.name === serviceName);
        return selecteService.type
    }

    timeToUnix(timeObject:any) {
        // Obtener la fecha actual en formato 'DD/MM/YYYY'
        const today = moment().format('DD/MM/YYYY');
        // Construir la cadena de fecha y hora usando los valores del objeto
        const dateTimeString = `${today} ${timeObject}`;
        // const dateTimeString = `${today} ${timeObject.hours}:${timeObject.minutes}:${timeObject.seconds}.${timeObject.ms}`;
        // Convertir a Unix timestamp
        return moment.utc(dateTimeString, 'DD/MM/YYYY HH:mm:ss.SSS').unix();
    }

    formatTime(date: any, zoneId:any) {
      const seconds = new Date(parseInt(date) * 1000);
      const hours = String(seconds.getUTCHours()).padStart(2, '0');
      const minutes = String(seconds.getUTCMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
        // let zoneId = this.driver_info.zoneId;
        // if(this.zones){
        //     const zone = this.zones.find((zone:any) => zone.zoneId === zoneId);
        //     const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
        //     return dateInClientZone.format('HH:mm');
        // }else{
        //     this.hs.getZones().subscribe((zones: any) => {
        //         let zon = zones.zones;
        //         const zone = zon.find((zone:any) => zone.zoneId === zoneId);
        //         const dateInClientZone = moment.unix(seconds).tz(zone.timeZone);
        //         return dateInClientZone.format('HH:mm');
        //     });
        // }
    }
}
